import { type ChangeEvent, type ReactNode } from 'react'

import { has } from './data'
import { hasValue } from './validations'
import { type Filter } from '@/types/filters'

export const FilterTypes = {
  FILTER_TYPE_SELECT        : 'FILTER_TYPE_SELECT',
  FILTER_TYPE_CHECK         : 'FILTER_TYPE_CHECK',
  FILTER_TYPE_CHECKLIST     : 'FILTER_TYPE_CHECKLIST',
  FILTER_TYPE_MAKEMODELYEAR : 'FILTER_TYPE_MAKEMODELYEAR',
  FILTER_TYPE_DATERANGE     : 'FILTER_TYPE_DATERANGE',
  FILTER_TYPE_TIME          : 'FILTER_TYPE_TIME',
  FILTER_TYPE_SLIDER        : 'FILTER_TYPE_SLIDER',
  FILTER_TYPE_SWITCH        : 'FILTER_TYPE_SWITCH',
}

export const filterDefintions = {
  [FilterTypes.FILTER_TYPE_CHECK]: {
    component : 'KitCheck',
    val       : (e: ChangeEvent<HTMLInputElement>) => e.target.checked,
    fn        : (
      checked: boolean,
      record: Record<string, string>,
      { fieldName }: { fieldName: string },
    ) => {
      if (!checked) {
        return true
      }
      return checked && record[fieldName] && record[fieldName].length
    },
  },
  [FilterTypes.FILTER_TYPE_CHECKLIST]: {
    component : 'SearchableCheckedList',
    fn        : (
      val: unknown[],
      record: Record<string, unknown>,
      { fieldName }: { fieldName: string },
    ) => (val.length ? val.includes(record[fieldName]) : true),
  },
  [FilterTypes.FILTER_TYPE_MAKEMODELYEAR]: {
    component : 'MakeModelYear',
    fn        : (
      val: string,
      record: Record<string, string>,
      { fieldName }: { fieldName: string },
    ) => val === '*' || record[fieldName].includes(val),
  },
  [FilterTypes.FILTER_TYPE_DATERANGE]: {
    component : 'DateRange',
    fn        : (
      val: string,
      record: Record<string, string>,
      { fieldName }: { fieldName: string },
    ) => val === '*' || record[fieldName].includes(val),
  },
  [FilterTypes.FILTER_TYPE_TIME]: {
    component : 'TimeInput',
    fn        : (
      val: string,
      record: Record<string, string>,
      { fieldName }: { fieldName: string },
    ) => val === '*' || record[fieldName].includes(val),
  },
}

// return value for control
export function getControlValue(
  filtDef: Filter,
  args: Record<string, unknown> | unknown,
) {
  if (filtDef && filtDef.val) {
    return filtDef.val(args)
  }
  return has(args, 'value') ? (args as Record<string, unknown>).value : args
}

/**
 * Adds filter objects to a list; prevents duplicates
 * @param {Filter[]} currentFilters
 * @param {Filter} newFilter
 * @param {boolean} removeFilter
 */
export const buildFilters = (
  currentFilters: Filter[],
  newFilter: Filter,
  removeFilter?: boolean | string[],
): Filter[] => {
  const result = currentFilters.concat()

  if (removeFilter && !Array.isArray(removeFilter)) {
    return result.filter((filt) => filt.name !== newFilter.name)
  }

  const match = result.find((filt) => filt.name === newFilter.name)

  if (match) {
    Object.keys(newFilter).forEach((key) => {
      (match[key as keyof Filter] as unknown) = newFilter[key as keyof Filter]
    })
  } else {
    result.push(newFilter)
  }

  if (Array.isArray(removeFilter) && removeFilter.length) {
    return result.filter(
      (filt: Filter) => !removeFilter.includes(filt.name as string),
    )
  }

  return result
}

export const renderExtraMenu = (condition: boolean, component: ReactNode) => {
  if (condition) {
    return component
  }
  return null
}

export const isValidFilter = (value: string | boolean | unknown): boolean => {
  if (!hasValue(value)) return false
  return typeof value === 'boolean' ? value : value !== '' && value !== '*'
}

export const getFilterCount = (filters: Filter[]): number => (Array.isArray(filters)
  ? filters.filter((f) => f.noCount !== true).length
  : Object.keys(filters).length)

/**
 * After upgrading several dependencies, focus-trap started throwing an exception when it could not immediately
 * find a focusable element after a menu or filter panel was opened.
 * Note: Adding a delay of 0 (zero) appears to often work, as it looks like focus-trap just needs to wait until the browser
 * is done with it's call stack before trying to focus an element
 *
 * @param delay (number)
 *
 * USAGE:
 * <FocusTrap focusTrapOptions={
 *     checkCanFocusTrap: focusTrapDelay(1000)
 * }>
 */
export const focusTrapDelay = (delay = 0) => (containers: (HTMLElement | SVGElement)[]) => new Promise<void>((resolve, reject) => {
  // if containers are not found, we can reject immediately
  if (!containers) {
    reject()
  } else {
    setTimeout(() => {
      resolve()
    }, delay)
  }
})
