/* eslint-disable class-methods-use-this */
import mixpanel, { type Dict } from 'mixpanel-browser'

import { type IEventProperties, validateEventProperties } from './AnalyticEvents'
import { type AnalyticsEventProps, getConsolidatedPathID, skipEvent } from './analyticsHelpers'
import { getConstant, isDebug } from '@/common/utils'
import { getCurrentUser, getOrgId } from '@/common/utils/user'

class AnalyticsService {
  private static instance: AnalyticsService

  isInitialized = false
  previousEvent: AnalyticsEventProps | null = null

  // properties sent with every event
  defaultProperties = {}

  constructor() {
    const user = getCurrentUser()
    if (user) {
      this.defaultProperties = {
        deployment_name : getConstant('DEPLOYMENT_NAME'),
        org_external_id : getOrgId(),
        user_id         : user.id,
        role            : user.role,
      }
    } else {
      console.warn('User not loaded yet. MixPanel default props not set. ')
    }
  }

  init(): void {
    const identifyID = String(getCurrentUser()?.id)
    const token      = getConstant('MIXPANEL_PROJECT_TOKEN') as string
    if (token) {
      mixpanel.init(token, { debug: isDebug() })

      mixpanel.register(this.defaultProperties)

      if (identifyID) {
        mixpanel.identify(identifyID as string)
      }

      this.isInitialized = true
    } else {
      console.warn('AnalyticsService not initialized. No token found')
    }
  }

  reset(): void {
    mixpanel.reset()
  }

  /**
   * Send analytics event to MixPanel
   * @param name
   * @param properties
   * @returns
   */
  trackEvent(name: string, properties?: IEventProperties): void {
    const time = new Date().getTime()

    // send current path with all events
    const path             = window.location.pathname
    const customProperties = {
      path,
      path_consolidated: getConsolidatedPathID(path),
    }
    const allProperties    = {
      ...customProperties,
      ...(properties || {}),
    }
    if (this.isInitialized) {
      if (properties) {
        if (!validateEventProperties(name, allProperties)) {
          return
        }
      }

      if (
        !skipEvent(this.previousEvent, {
          time,
          name,
          properties,
        })
      ) {
        mixpanel.track(name, allProperties as Dict)
      }

      this.previousEvent = {
        name,
        time,
        properties,
      }
    }
  }

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService()
    }
    return AnalyticsService.instance
  }
}

export default AnalyticsService
