import { KitTable } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import { type VehicleTripHistoryRow } from '../types'
import { KitTableWrapper } from '@/components/Styled'
import { TableBody, TableHead } from '@/components/Table'
import { type ColumnDef } from '@/types/index.d'

export interface TableHeaderProps {
  columns: ColumnDef[];
  onSortClicked?: (sortKey: string, sortDir: number) => void;
  sortKey?: string;
}

export interface TripHistoryTableProps {
  columns: ColumnDef[];
  data?: VehicleTripHistoryRow[];
  onSortClicked: (sortKey: string, sortDir: number) => void;
  sortKey: string;
  t: TFunction;
}

const TripHistoryTable: FC<TripHistoryTableProps> = ({
  columns = [],
  data = [],
  onSortClicked,
  sortKey,
  t,
}) => {
  const columnsLength = columns.length
  const hasTableData  = data && data.length
  const columnsData   = hasTableData
    ? columns
    : columns.map((column) => {
      column.sortFn = undefined
      return column
    })
  return (
    <KitTableWrapper>
      <KitTable>
        <TableHead
          onSortClicked={onSortClicked}
          columns={columnsData}
          sortKey={sortKey}
        />
        { hasTableData ? (
          <TableBody
            columns={columnsData}
            data={data as unknown as Record<string, unknown>[]}
          />
        ) : (
          <tbody>
            <tr>
              <td colSpan={columnsLength} style={{ padding: '20px' }}>
                { t('no_data') }
              </td>
            </tr>
          </tbody>
        ) }
      </KitTable>
    </KitTableWrapper>
  )
}

export default TripHistoryTable
