import { KitUtilData } from '@chargepoint/cp-toolkit'
import { type ReactComponentLike } from 'prop-types'
import { type ReactNode } from 'react'
import { Trans } from 'react-i18next'

import { has } from '@/common/utils/data'
import { type OptionListItem } from '@/models/formModel'
import { type ComponentConfig } from '@/types/index'

const { getProp } = KitUtilData

export const renderOptions = (options: OptionListItem[]): ReactNode => (options
  ? options.map(({ label, value }) => (
    <option key={`${label}-${value}`} value={value as string}>
      { label }
    </option>
  ))
  : [])

export interface StateQueryProps<State> {
  selector?: (state: State) => unknown;
  state?: string;
  [key: string]: unknown;
}

export interface BuildProps<State = unknown> {
  [key: string]: StateQueryProps<State> | unknown;
}
/**
 * helper function to build props object for components
 * helpers to automatically resolve properties that require data from reducer state
 * @param props
 * @param state
 */
export function buildProps<S = Record<string, unknown>>(
  props: BuildProps<Record<string, unknown>>,
  state?: S,
): Record<string, unknown> {
  const result = Object.keys(props).reduce(
    (acc: Record<string, unknown>, key: string) => {
      if (state) {
        if (has(props[key], 'selector')) {
          const val = (props[key] as StateQueryProps<unknown>).selector?.(
            state,
          )
          acc[key]  = val
        } else if (has(props[key], 'state')) {
          acc[key] = getProp(
            state,
            (props[key] as StateQueryProps<unknown>).state as string,
          )
        } else {
          acc[key] = props[key]
        }
      } else {
        acc[key] = props[key]
      }
      return acc
    },
    {},
  )

  return { ...result }
}

export function renderComponent<
  State extends Record<string, string | unknown> | unknown | undefined,
  Config extends ComponentConfig,
>(
  cfg: Config,
  componentMap: Record<string, unknown>,
  {
    otherProps = {},
    state,
  }: { otherProps: Record<string, unknown>; state?: State },
): ReactNode {
  if (!cfg.component || !has(componentMap, cfg.component as string)) {
    console.warn('component not defined in cfg', cfg)
  }
  const Component = componentMap[cfg.component as string] as ReactComponentLike
  const props     = buildProps((cfg.props ?? {}) as BuildProps, state)
  return Component ? <Component {...props} {...otherProps} /> : null
}

export const getTransComponent = (
  key: string,
  values?: Record<string, string>,
  components: Record<string, JSX.Element> = { bold: <b /> },
  defaults?: string,
) => (
  <Trans
    i18nKey={key}
    components={components}
    values={values}
    defaults={defaults}
  />
)
