import { KitForm, KitSwitch, KitUtilData } from '@chargepoint/cp-toolkit'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditMode } from '@/common/constants'
import { ReadOnly } from '@/components/common/preconditioning/ReadOnlySettings'
import EditPanel from '@/components/EditPanel'
import Notification, { NotificationLevel } from '@/components/Notification'
import { CardErrorContainer } from '@/components/Styled'
import type { PreconditioningState } from '@/models/preconditioningModel'
import type { BaseServiceResponse, ServiceError } from '@/models/serviceModel'
import FleetService from '@/services/FleetService'

const PreconditioningPreferencesCard = ({ fleet_id }: { fleet_id: string }) => {
  // determine preconditioning payload
  const [initialPreconditioningState, setInitialPreconditioningState] = useState<PreconditioningState>()
  const [preconditioningState, setPreconditioningState] = useState<PreconditioningState>()
  const [serviceError, setServiceError] = useState<ServiceError | undefined>()
  const [inEditMode, setInEditMode] = useState(false)
  const [isLoading, setIsLoading] = useState(KitUtilData.isEmpty({ testFoo: 'remove me' }))
  const { t } = useTranslation()

  const reset = () => {
    setServiceError(undefined)
    setPreconditioningState(initialPreconditioningState)
  }

  const updateSettings = async (payload: PreconditioningState) => {
    const response = await FleetService.updatePreconditioningSettings(fleet_id, payload) as BaseServiceResponse
    if (response.error) {
      setServiceError(response.error)
    } else {
      setInitialPreconditioningState(preconditioningState)
      setServiceError(undefined)
      setInEditMode(false)
    }

    setIsLoading(false)
  }

  const handleCancel = () => {
    setInEditMode(false)
    reset()
  }

  const handleSubmit = () => {
    if (preconditioningState) {
      setIsLoading(true)
      updateSettings(preconditioningState)
    }
  }

  const onFieldChange = (fieldName: string, fieldValue: string | boolean) => {
    setPreconditioningState({ ...preconditioningState, [fieldName]: fieldValue })
  }

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await FleetService.getPreconditioningSettings(fleet_id)
      if (response.error) {
        setServiceError(response.error)
      } else {
        setInitialPreconditioningState(response);
        setPreconditioningState(response)
      }
    }

    fetchSettings()
  }, [fleet_id])

  return <EditPanel
    id="preconditioning"
    t={t}
    isLoading={isLoading}
    title={t('fleets.settings.preconditioning.title')}
    mode={inEditMode ? EditMode.EDIT : EditMode.READ_ONLY}
    onSubmit={handleSubmit}
    onCancel={handleCancel}
    onToggle={() => {
      setInEditMode(!inEditMode)
    }}
  >
    {inEditMode ? <>
      {serviceError && <CardErrorContainer><Notification message={serviceError.message} type={NotificationLevel.ERROR} /></CardErrorContainer>}
      <KitForm>
        <KitForm.Group>
          <KitSwitch
            checked={preconditioningState?.is_preconditioning_enabled}
            label={t('vehicles.settings.preconditioning.label')}
            onChange={(e) => onFieldChange('is_preconditioning_enabled', KitUtilData.toBoolean(e.target?.checked))}>
          </KitSwitch>
        </KitForm.Group>
      </KitForm>
    </> : preconditioningState
    && <ReadOnly
      enabled={preconditioningState.is_preconditioning_enabled}
      strings={
        {
          on: t('on'),
          off: t('off'),
          label: t('fleets.settings.preconditioning.label'),
        }
      }
    />}
  </EditPanel>
}

export default PreconditioningPreferencesCard
