import { type TFunction } from 'i18next'

import { VehicleStatusOptions } from '@/common/constants'
import { type Vehicle } from '@/models/vehicleModel'

// Returns the text that should be displayed on the vehicle card and vehicle prop sheet
// When there is no depot row status, then the vehicle status text is displayed
export const getVehicleStatusText = (
  vehicle: Partial<Vehicle>,
  t: TFunction,
): string => {
  if (vehicle.vehicle_status === VehicleStatusOptions.NOT_READY) {
    return t('vehicles.status.not_set_up')
  }

  // todo - backend is inconsistent between different APIs as to whether it returns `status` or `status_code`
  const statusCode = vehicle['status_code' as keyof Vehicle] ?? vehicle.status
  return vehicle.status
    ? t(`status_pill.${statusCode}`)
    : t('status_pill.unknown')
}

// vehicles with no mac address will NOT return a depot row status, so we have to inspect the vehicle_status property
export const getNormalizedVehicleStatusCode = (vehicle: Partial<Vehicle>) => {
  if (vehicle.vehicle_status === VehicleStatusOptions.NOT_READY) {
    return VehicleStatusOptions.NOT_READY
  }

  return vehicle['status_code' as keyof Vehicle]
    ? vehicle['status_code' as keyof Vehicle]
    : vehicle['status' as keyof Vehicle]
}
