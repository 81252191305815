import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import {
  type BaseServiceResponse,
  type ServicePayload,
  type ServiceResponse,
} from '@/models/serviceModel'
import {
  type AddOrUpdateTelematicsAccountsRequest,
  type RequiredProviderFields,
  type TelematicsAccount,
  type TelematicsProvider,
  type TelematicsZone,
} from '@/models/telematicsModel'

const createService = (api: ServiceInterface) => ({
  getTelematicsAccounts() {
    return wrapRequest<ServiceResponse<TelematicsAccount[]>>(
      api.get('/api/telematics-account/'),
    )
  },
  addTelematicsAccounts(
    payload: ServicePayload<AddOrUpdateTelematicsAccountsRequest>,
  ) {
    return wrapRequest<BaseServiceResponse>(
      api.post('/api/telematics-account/', payload),
    )
  },
  updateTelematicsAccounts(
    id: number,
    payload: ServicePayload<AddOrUpdateTelematicsAccountsRequest>,
  ) {
    return wrapRequest<BaseServiceResponse>(
      api.patch(`/api/telematics-account/${id}`, payload),
    )
  },
  getTelematicsZones(id: number) {
    return wrapRequest<TelematicsZone[]>(
      api.get(`/api/zones/?telematics_account_id=${id}`),
    )
  },
  getAllTelematicsZones() {
    return wrapRequest<TelematicsZone[]>(api.get('/api/zones/'))
  },
  deleteTelematicsAccounts(id: number) {
    return wrapRequest<BaseServiceResponse>(
      api.delete(`/api/telematics-account/${id}`),
    )
  },
  getRequiredFieldsFromProvider(providerName: string) {
    return wrapRequest<BaseServiceResponse & RequiredProviderFields>(
      api.get(`/api/telematics-provider-cred-components/${providerName}`),
    )
  },
  getTelematicsProviders() {
    return wrapRequest<ServiceResponse<TelematicsProvider[]>>(
      api.get('/api/telematics-providers/'),
    )
  },
})

export default createService(AbstractService())
