import { ThemeConstants } from '@chargepoint/cp-toolkit'
import { useEffect, useState } from 'react'

const { breakpoints } = ThemeConstants

export enum LayoutSizes {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
  LARGE_DESKTOP = 'LARGE_DESKTOP',
}

export interface LayoutProps {
  lastUpdated: number;
  type: string;
  isTabletOrMobile: boolean;
  isMobile: boolean;
  width: number;
  height: number;
}

export const getDeviceSize = (width: number): LayoutSizes => {
  if (width >= 1601) {
    return LayoutSizes.LARGE_DESKTOP
  }
  if (width > breakpoints.xl) {
    return LayoutSizes.DESKTOP
  }
  if (width > breakpoints.md && width <= breakpoints.xl) {
    return LayoutSizes.TABLET
  }
  if (width <= breakpoints.md) {
    return LayoutSizes.MOBILE
  }

  return LayoutSizes.DESKTOP
}

export const getCurrentLayout = (): LayoutProps => {
  const width: number     = window.document.body.clientWidth
  const height: number    = window.document.body.clientHeight
  const type: LayoutSizes = getDeviceSize(width)
  return {
    lastUpdated      : Date.now(),
    type,
    isMobile         : [LayoutSizes.MOBILE].includes(type),
    isTabletOrMobile : [LayoutSizes.TABLET, LayoutSizes.MOBILE].includes(type),
    width,
    height,
  }
}

export const useLayout = () => {
  const [layout, setLayout] = useState(getCurrentLayout())

  useEffect(() => {
    const handleWindowResize = () => setLayout(getCurrentLayout())
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  // Return the width so we can use it in our components
  return layout
}
