import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import DepotCard from './DepotCard'
import DepotListHeader from './DepotListHeader'
import alertUtils from '@/common/utils/alerts'
import {
  ALERT_EVENT_GROUP,
  EVENT_TYPE_DELETE,
  useWebSocket,
} from '@/common/websocket'
import NoResults from '@/components/NoResults'
import Notification, { NotificationLevel } from '@/components/Notification'
import Page from '@/components/Page'
import { CardLayout, PageContainer } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import { type Alert, type MappedAlert } from '@/models/alertModel'
import { type Depot } from '@/models/depotModel'
import AlertService from '@/services/AlertService'
import appStore from '@/store/AppStore'

export type DepotListRecord = Depot & { alerts: Alert[] };

function DepotListView() {
  const { t }               = useTranslation()
  const [alerts, setAlerts] = useState<Alert[]>()

  const [serviceError, setServiceError] = useState<string | null>(null)
  const [depots, setDepots]             = useState<MappedAlert<Depot>[]>(
    useLoaderData() as Depot[],
  )
  const [isLoaded, setIsLoaded]         = useState(false)

  const depotStateRef = useRef<MappedAlert<Depot>[]>()
  const alertsRef     = useRef<Alert[]>()

  depotStateRef.current = depots
  alertsRef.current     = alerts

  const handleAlertsUpdate = (eventType: string, content: Partial<Alert>) => {
    const newAlerts = alertUtils.updateAlertsAfterWebsocketUpdate(
      content as Alert,
      eventType,
      alertsRef.current as Alert[],
    )
    setAlerts(newAlerts)
  }

  const handleDepotsUpdate = (
    eventType: string,
    group: string,
    content: Partial<Omit<DepotListRecord, 'alerts'>>,
  ) => {
    const changedDepotData = content
    const newDepots        = JSON.parse(
      JSON.stringify(depotStateRef.current),
    ) as DepotListRecord[]

    if (eventType === EVENT_TYPE_DELETE) {
      const depotToDelete = newDepots.findIndex(
        (el) => el.id === changedDepotData.id,
      )
      if (depotToDelete) {
        newDepots.splice(depotToDelete, 1)
      }
    } else {
      const indexOfDepotToChange = newDepots.findIndex(
        (el) => el.id === changedDepotData.id,
      )
      if (indexOfDepotToChange < 0) {
        return
      }
      const changedDepot = newDepots[indexOfDepotToChange]
      // The backend is only sending over changed fields, so
      // update the relevant fields, not the entire object
      Object.entries(changedDepotData).forEach(([key, value]) => {
        // TODO: Try to fix the type of 'key' sometime in the future
        // I spent 10 minutes trying to figure out how to type 'key' and nothing worked.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        changedDepot[key as keyof typeof changedDepotData] = value
      })

      newDepots.splice(indexOfDepotToChange, 1, changedDepot)
    }
    setDepots(newDepots)
  }

  const onReceiveDataFromWebSocket = (
    event_type: string,
    group: string,
    content: Record<string, unknown>,
  ) => {
    if (group === ALERT_EVENT_GROUP) {
      handleAlertsUpdate(event_type, content)
    } else {
      handleDepotsUpdate(event_type, group, content)
    }
  }

  useWebSocket('/ws/depot-list/', {
    onMessage: ({ content, event_type, group }) => {
      onReceiveDataFromWebSocket(event_type, group, content)
    },
  })

  async function fetchAlerts() {
    setIsLoaded(true)
    const { error: alertsError, results: alertResults } =      await AlertService.getAlertList({ event_type: 'active' })

    if (alertsError) {
      setServiceError(t('list_request_error', { itemType: t('alert_plural') }))
    }

    setAlerts(alertResults)
  }

  useEffect(() => {
    if (appStore.initialized) {
      fetchAlerts()
    }
  }, [appStore.initialized])

  useEffect(() => {
    if (alertsRef.current && depotStateRef.current) {
      const mapped = alertUtils.mapAlertsToDepots(
        alertsRef.current,
        depotStateRef.current,
      )
      setDepots(mapped)
    }
  }, [alerts])

  function depotIsActive(d: Depot) {
    return d.status === 'ACTIVE'
  }

  function getOrderedDepotList(): MappedAlert<Depot>[] {
    // Sort depots by 'active'ness, then by name, to display depot cards in correct order
    const depotList = depots ?? []

    depotList.sort((a, b) => (depotIsActive(a as Depot) > depotIsActive(b as Depot)
      ? -1
      : depotIsActive(a as Depot) === depotIsActive(b as Depot)
        ? (a as Depot).name > (b as Depot).name
          ? 1
          : -1
        : 1))
    return depotList
  }

  const sortedDepotList = getOrderedDepotList()

  return (
    <Page title={t('page_titles.depot_list')}>
      <DepotListHeader t={t} />

      { isLoaded && sortedDepotList?.length === 0 && (
        <NoResults
          message={
            serviceError ? t('errors.depots_request_error') : t('no_results')
          }
        />
      ) }

      <PageContainer data-qa-id="depot_list_depot_cards_container">
        { !isLoaded && !serviceError && <StyledSpinner /> }
        { serviceError && (
          <>
            <Notification
              className="error-list"
              type={NotificationLevel.WARNING}
              message={t('errors.list_request_error', { itemType: t('alert_plural') })}
            />
            <br />
          </>
        ) }

        { depots && (
          <CardLayout>
            { sortedDepotList.map((depot) => (
              <DepotCard
                key={depot.id}
                t={t}
                depot={depot}
                alerts={
                  depot.alerts
                    ? alertUtils.numberOfHighSeverity(depot.alerts)
                    : 0
                }
              />
            )) }
          </CardLayout>
        ) }
      </PageContainer>
    </Page>
  )
}

export default DepotListView
