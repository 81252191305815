import { useEffect, useRef } from 'react'

import log from './log'
import utils from './utils'
import { type GenericCallBack } from '@/models/serviceModel'

export interface FleetWebSocketCallbackPayload {
  event_type: string;
  group: string;
  content: Record<string, unknown>;
}

class FleetWebSocket {
  public connectTimeout: ReturnType<typeof setTimeout> | null
  maxTimeoutInterval: number
  timeoutInterval: number

  onCloseCallback: () => void
  onConnectCallback: () => void
  onMessageCallback: (payload: FleetWebSocketCallbackPayload) => void
  onOpenCallback: () => void
  uri: string

  ws: WebSocket | null

  constructor(
    websocketsURI: string,
    onConnect: GenericCallBack,
    onClose: GenericCallBack,
    onMessage: (payload: FleetWebSocketCallbackPayload) => void,
    onOpen: GenericCallBack,
  ) {
    this.uri                = websocketsURI
    this.onConnectCallback  = onConnect
    this.onCloseCallback    = onClose
    this.onMessageCallback  = onMessage
    this.onOpenCallback     = onOpen
    this.timeoutInterval    = 250
    this.maxTimeoutInterval = 30 * 1000 // 30 seconds
    this.connectTimeout     = null

    this.ws = null
  }

  close() {
    clearTimeout(this.connectTimeout as NodeJS.Timeout)
    this.ws?.removeEventListener('close', this.handleClose)
    this.ws?.close()
  }

  checkConnection = (): void => {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
      this.connect()
    }
  }

  handleOpen = (): void => {
    log.info('websocket opened')
    this.timeoutInterval = 250
    if (this.onOpenCallback) {
      this.onOpenCallback()
    }
  }

  handleClose = (e: CloseEvent): void => {
    log.info(
      `Socket is closed. Reconnect will be attempted in ${Math.min(
        10000 / 1000,
        (this.timeoutInterval + this.timeoutInterval) / 1000,
      )} seconds.`,
      e.reason,
    )
    this.timeoutInterval =      this.timeoutInterval * 2 <= this.maxTimeoutInterval
      ? this.timeoutInterval * 2
      : this.timeoutInterval //  increment retry interval
    log.info('resetting timeout')
    this.connectTimeout = setTimeout(
      this.checkConnection,
      Math.min(10000, this.timeoutInterval),
    ) // call check function after timeout
    if (this.onCloseCallback) {
      this.onCloseCallback()
    }
  }

  connect = (): void => {
    this.ws = new WebSocket(this.uri)
    this.ws.addEventListener('open', this.handleOpen)
    this.ws.addEventListener('close', this.handleClose)

    this.ws.onmessage = (event: MessageEvent<string>) => {
      // log.info("data received from web socket");
      // log.info(JSON.parse(event.data));
      this.onMessageCallback(JSON.parse(event.data))
    }
  }
}

export const EVENT_TYPE_CREATE = 'create'
export const EVENT_TYPE_UPDATE = 'update'
export const EVENT_TYPE_DELETE = 'delete'
export const EVENT_TYPE_REFRESH = 'refresh'
export const EVENT_TYPE_CLEAR = 'clear'

export const ALERT_EVENT_GROUP = 'alerts_events'
export const DEPOT_EVENT_GROUP = 'depot'
export const VEHICLE_EVENT_GROUP = 'vehicle'
export const VEHICLE_MODEL_EVENT_GROUP = 'vehicle_model'
export const VEHICLE_STATE_EVENT_GROUP = 'vehicle_state'
export const DEPOT_DETAILS_EVENT_GROUP = 'depot_details'

/**
 * Establishes connection to webservice
 * @param path: string
 * @param opts.onMessage: (WebsocketMessage)=>void
 */
export const useWebSocket = (
  path: string,
  {
    immediate = true,
    onClose,
    onConnect,
    onMessage,
    onOpen,
  }: {
    onClose?: GenericCallBack;
    onConnect?: GenericCallBack;
    onMessage: (payload: FleetWebSocketCallbackPayload) => void;
    onOpen?: GenericCallBack;
    immediate?: boolean;
  },
  dependencies?: unknown[],
): {
    ws: FleetWebSocket;
    connect: () => void;
  } => {
  const wsURI     = utils.constructWebSocketURI(path)
  const socketRef = useRef<FleetWebSocket>()

  const connect = () => {
    if (socketRef.current) {
      socketRef.current.connect()
    }
  }

  useEffect(() => {
    socketRef.current = new FleetWebSocket(
      wsURI,
      onConnect,
      onClose,
      onMessage,
      onOpen,
    )
    if (immediate) {
      connect()
    }
    return () => {
      // Don't try to re-open websocket if we're intentionally closing

      if (socketRef.current) {
        clearTimeout(socketRef.current.connectTimeout as NodeJS.Timeout)
        socketRef.current.ws?.removeEventListener(
          'close',
          socketRef.current.handleClose,
        )
        socketRef.current.ws?.close()
      }
    }
  }, dependencies ?? [])

  return {
    ws: socketRef.current as FleetWebSocket,
    connect,
  }
}

export default FleetWebSocket
