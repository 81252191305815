const Bus = (props = {}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M6 2C4.89543 2 4 2.89543 4 4V8H2.5C2.22386 8 2 8.22386 2 8.5V13.5C2 13.7761 2.22386 14 2.5 14H4V24C4 25.1046 4.89543 26 6 26L6 29.5C6 29.7761 6.22386 30 6.5 30H7.5C7.77614 30 8 29.7761 8 29.5V26H24V29.5C24 29.7761 24.2239 30 24.5 30H25.5C25.7761 30 26 29.7761 26 29.5V26C27.1046 26 28 25.1046 28 24V14H29.5C29.7761 14 30 13.7761 30 13.5V8.5C30 8.22386 29.7761 8 29.5 8H28V4C28 2.89543 27.1046 2 26 2H6ZM6 4H26V6H6V4ZM26 8H6V20H26V8ZM6 22H12V24H7C6.44772 24 6 23.5523 6 23V22ZM26 22H20V24H25C25.5523 24 26 23.5523 26 23V22Z"
      fill="currentColor"
    />
  </svg>
)

export default Bus
