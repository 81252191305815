/* eslint-disable no-shadow */
import {
  KitButton,
  KitLink,
  KitModal,
  KitModalSize,
  KitSpinner,
  KitUtilCommon,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import classNames from 'classnames'
import { type TFunction } from 'i18next'
import { type FC, type ReactNode, useEffect, useState } from 'react'

import {
  NavItem,
  NavList,
  StyledFooter,
  WizardContent,
  WizardControlsContainer,
} from './index.styles'
import { hasValue } from '@/common/utils/validations'
import { type AddFleetReducerState } from '@/components/AddFleetWizard/config'

const { breakpoints, spacing } = ThemeConstants

enum StepDirection {
  NEXT = 'next',
  BACK = 'back',
  DONE = 'done',
}

export interface WizardProps {
  disabledSteps?: number[];
  title: string;
  show: boolean;
  children: ReactNode;
  config: {
    steps: WizardStep[];
  };
  hideFooter?: boolean;
  modalStyles?: string;
  modalSize: KitModalSize;
  onStep: (step: WizardStep) => void;
  onHide: () => void;
  onComplete: () => Promise<unknown>;
  resetNav?: boolean;
  t: TFunction;
}

export interface WizardStep {
  id: string;
  label: string;
  active?: boolean;
  showBreadCrumb?: boolean;
  showNav?: boolean;
  steps?: { id: string }[];
  stepIndex?: number;
  // eslint-disable-next-line -- we really don't know what the user will pass here, so any is appropriate
  validate: (
    data: Record<string, string> | any,
    state?: AddFleetReducerState
  ) => boolean;
}

interface WizardNavProps {
  disabledSteps: number[];
  steps: WizardStep[];
  currentStepIndex: number;
  onChange: (stepIndex: number) => void;
  onFocus: (e: React.FocusEvent<HTMLAnchorElement, Element>) => void;
  t: TFunction;
}
const WizardNav: FC<WizardNavProps> = ({
  currentStepIndex,
  disabledSteps,
  onChange,
  onFocus,
  steps,
  t,
}: WizardNavProps) => (
  <nav
    aria-label={t('a11y.wizard.current_step_message', {
      message     : t('fleets.add_vehicle'),
      currentStep : currentStepIndex + 1,
      totalSteps  : steps.length,
    })}
  >
    <NavList data-steps={steps.length}>
      { steps
        .filter((s) => s.showBreadCrumb !== false)
        .map((step, idx) => (
          <NavItem
            key={step.id}
            disabled={disabledSteps.includes(idx)}
            className={classNames({ selected: idx === currentStepIndex })}
          >
            <KitLink
              data-index={idx}
              tabIndex={disabledSteps.includes(idx) ? -1 : 0}
              aria-disabled={!!disabledSteps.includes(idx)}
              onFocus={onFocus}
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
              onClick={() => onChange(idx, step.id)}
            >
              { step.label }
            </KitLink>
          </NavItem>
        )) }
    </NavList>
  </nav>
)

interface WizardControlsProps {
  disableNext: boolean;
  step: number;
  steps: WizardStep[];
  onStep: (direction: StepDirection) => void;
  strings: Record<string, string>;
}

const WizardControls: FC<WizardControlsProps> = ({
  disableNext = true,
  onStep,
  step,
  steps,
  strings,
}: WizardControlsProps) => {
  const buttons = (() => {
    let value = []
    if (step === 0) {
      value = [
        {
          action  : 'next',
          label   : strings.next,
          onClick : () => onStep(StepDirection.NEXT),
        },
      ]
    } else if (step > 0 && step < steps.length - 1) {
      value = [
        {
          action  : 'back',
          label   : strings.back,
          onClick : () => onStep(StepDirection.BACK),
        },
        {
          action  : 'next',
          label   : strings.next,
          onClick : () => onStep(StepDirection.NEXT),
        },
      ]
    } else {
      value = [
        {
          action  : 'back',
          label   : strings.back,
          onClick : () => onStep(StepDirection.BACK),
        },
        {
          action  : 'next',
          label   : strings.done,
          onClick : () => onStep(StepDirection.DONE),
        },
      ]
    }
    return value
  })()
  return (
    <WizardControlsContainer className={classNames({ start: step === 0 })}>
      { buttons.map((btn) => (
        <KitButton
          disabled={btn.action !== 'back' && disableNext}
          variant={btn.action === 'back' ? 'secondary' : 'primary'}
          key={`${btn.label}`}
          onClick={btn.onClick}
          style={{ minWidth: '120px' }}
        >
          { btn.label }
        </KitButton>
      )) }
    </WizardControlsContainer>
  )
}

const Wizard: FC<WizardProps> = ({
  children,
  config,
  disabledSteps = [],
  hideFooter = false,
  modalSize = KitModalSize.md,
  modalStyles = '',
  onComplete,
  onHide,
  onStep,
  resetNav,
  show,
  t,
  title,
}) => {
  const [currentStepIndex, setCurrentStepIndex]           = useState<number>(0)
  const [currentChildStepIndex, setCurrentChildStepIndex] = useState<number>(0)
  const [navVisible, setNavVisible]                       = useState<boolean>(true)
  const [showLoader, setShowLoader]                       = useState<boolean>(false)
  const minWizardHeight                                   = 150

  useEffect(() => {
    if (resetNav) setCurrentStepIndex(0)
  }, [resetNav])

  if (!config.steps) {
    return null
  }

  function isValidStep(stepIndex: number) {
    return stepIndex >= 0 && stepIndex < config.steps.length
  }

  const handleComplete = () => {
    onComplete()
      .then(() => {
        setTimeout(() => {
          setShowLoader(false)
        }, 200)
      })
      .catch(() => {
        setShowLoader(false)
      })
    setShowLoader(true)
  }

  function getStep(stepIndex: number, childStepIndex?: number): WizardStep {
    const step = config.steps[stepIndex]
    // return child step, if relevant
    if (step.steps && hasValue(childStepIndex)) {
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      return step.steps[childStepIndex]
    }

    return step
  }

  function setStep(index: number, childIndex?: number) {
    if (!isValidStep(index)) {
      console.warn(' invalid step. exiting')
      return
    }

    const hasChildren  = !!config.steps[index].steps
    let childStepIndex = childIndex

    if (hasChildren && index !== currentStepIndex) {
      childStepIndex =        index > currentStepIndex
        ? 0
        : (config.steps[index].steps?.length as number) - 1
    }

    setCurrentStepIndex(index)
    setCurrentChildStepIndex(childStepIndex as number)

    const step: WizardStep = getStep(index, childStepIndex)

    if (onStep) {
      onStep({
        ...step,
        stepIndex: index,
      })
    }
  }

  function nextStep() {
    let newIndex   = currentStepIndex
    let childIndex = currentChildStepIndex

    const { steps } = config.steps[currentStepIndex]

    if (steps && childIndex + 1 < steps.length) {
      childIndex += 1
    } else {
      newIndex += 1
    }

    setStep(newIndex, childIndex)
  }

  function prevStep() {
    let newIndex    = currentStepIndex
    let childIndex  = currentChildStepIndex
    const { steps } = config.steps[currentStepIndex]

    if (steps && childIndex - 1 >= 0) {
      childIndex -= 1
    } else {
      newIndex -= 1
    }

    setStep(newIndex, childIndex)
  }

  const onStepChanged = (direction: StepDirection) => {
    switch (direction) {
      case StepDirection.NEXT:
        nextStep()
        break
      case StepDirection.BACK:
        prevStep()
        break
      case StepDirection.DONE:
        handleComplete()
        break
      default:
    }
  }

  const handleNavFocus = (e: React.FocusEvent<HTMLAnchorElement, Element>) => {
    const targ  = e.target as HTMLAnchorElement
    const index = parseInt(targ.getAttribute('data-index') as string)
    if (!Number.isNaN(index)) {
      setCurrentStepIndex(index)
    }
    setCurrentStepIndex(0)
  }

  useEffect(() => {
    const step = config.steps[currentStepIndex]
    if (step?.showNav !== undefined) {
      setNavVisible(step.showNav)
    }
  }, [currentStepIndex, config.steps])

  const wizardStyles = `
    display: grid;
    grid-template-rows: auto 1fr auto;
  
    div[data-id="modal-header"]{
      background: ${ThemeColors.gray_20};
    }
    ${modalStyles};

    @media all and (max-width: ${breakpoints.sm}px) {
      top: 0 !important;
     margin:${spacing.absolute.xs}px !important;
     max-width: calc(100vw - ${spacing.absolute.s}px);
     height: calc(100vh - ${spacing.absolute.s}px);
     min-height: -webkit-fill-available;
     max-height: calc(100vh - ${spacing.absolute.s}px);
    }
  `

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [currentStepIndex])

  return (
    <KitModal
      animation={false}
      show={show}
      onHide={onHide}
      size={modalSize}
      position={'center'}
      closeOnClickOutside={false}
      customStyles={wizardStyles}
      checkCanFocusTrap={KitUtilCommon.focusTrapDelay(250)}
    >
      <KitModal.Header closeButton t={t}>
        <KitModal.Title>{ title }</KitModal.Title>
      </KitModal.Header>
      { config && (
        <>
          <KitModal.Body
            className="cp-modal-body"
            style={{ minHeight: `${minWizardHeight}px` }}
          >
            { navVisible && (
              <WizardNav
                currentStepIndex={currentStepIndex}
                disabledSteps={disabledSteps}
                onFocus={handleNavFocus}
                onChange={(stepIndex: number) => {
                  setStep(stepIndex)
                }}
                steps={config.steps}
                t={t}
              />
            ) }

            <WizardContent>
              { showLoader && <KitSpinner size="s" align="middle" withOverlay /> }
              { !showLoader && children }
            </WizardContent>
          </KitModal.Body>
          { !hideFooter && (
            <StyledFooter>
              <WizardControls
                disableNext={disabledSteps.includes(currentStepIndex)}
                steps={config.steps}
                step={currentStepIndex}
                onStep={onStepChanged}
                strings={{
                  back : t('back'),
                  done : t('done'),
                  next : t('next'),
                }}
              />
            </StyledFooter>
          ) }
        </>
      ) }
    </KitModal>
  )
}

export default Wizard
