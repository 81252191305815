/* eslint-disable camelcase */
import {
  KitForm,
  KitLink,
  KitSelect,
  KitTextSpacing,
  KitUtilData,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, useEffect, useState } from 'react'

import styled from 'styled-components'

import { EditMode } from '@/common/constants'
import { formatTelephone } from '@/common/lang'
import { FLEET_FLEET_UPDATE, hasPermission } from '@/common/permissions'
import { getOptionNameLabel } from '@/common/utils/contact'
import EditPanel from '@/components/EditPanel'
import StyledSpinner from '@/components/StyledSpinner'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { type Contact, type NOSContact } from '@/models/commonModel'
import { type SelectChangeEvent } from '@/models/formModel'
import AdminService from '@/services/AdminService'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import FleetService from '@/services/FleetService'

const Container = styled.div`
  min-height: 100px;
`

const ContactDetails = styled(KitForm.Value)`
  color: ${ThemeColors.gray_50};
`

const ReadOnlyContainer = styled.div`
  > div {
    margin-bottom: ${ThemeConstants.spacing.absolute.xxs}px;
  }
`

const { isEmpty } = KitUtilData

interface FleetContactProps extends NOSContact {
  t: TFunction;
}

interface EditProps {
  contact: NOSContact;
  options: NOSContact[];
  t: TFunction;
  setContact: (data: NOSContact) => void;
}

const ReadOnlyContact: FC<FleetContactProps> = ({
  email,
  first_name,
  hyperlink,
  last_name,
  phone_number,
  t,
}) => {
  if (!email && !first_name) {
    return (
      <KitTextSpacing.Group>
        <KitTextSpacing.PrimaryText>
          {t('fleets.settings.contact_no_contact_set')}
        </KitTextSpacing.PrimaryText>
      </KitTextSpacing.Group>
    )
  }
  return (
    <ReadOnlyContainer>
      <KitTextSpacing.SecondaryText>
        <KitLink
          data-qa-id="contact_email_link"
          onClick={() => window.open(hyperlink, '_blank')}
        >
          {first_name} {last_name}
        </KitLink>
      </KitTextSpacing.SecondaryText>
      <KitTextSpacing.SecondaryText data-qa-id="email_value">
        {email}
      </KitTextSpacing.SecondaryText>
      <KitTextSpacing.SecondaryText data-qa-id="phone_number_value">
        {formatTelephone(phone_number)}
      </KitTextSpacing.SecondaryText>
    </ReadOnlyContainer>
  )
}

const EditContact: FC<EditProps> = ({
  contact = {} as Partial<NOSContact>,
  options,
  setContact,
  t,
}) => {
  const { email, first_name, last_name, nos_user_id, phone_number } = contact

  const selectContactList = options?.map((opt) => ({
    value: opt.nos_user_id,
    label: getOptionNameLabel(opt),
  }))

  const handleContactChange = (e: unknown) => {
    const { value } = e as SelectChangeEvent
    if (value !== null) {
      const newContact = options.filter(
        (opt) => opt.nos_user_id === value,
      )[0]
      setContact(newContact as Contact)
    }
  }

  const getSelectedContactValue = () => {
    if (!email && !first_name && !last_name) {
      return null
    }
    return { value: nos_user_id, label: getOptionNameLabel(contact) }
  }

  return (
    <>
      <KitForm.Group>
        <KitForm.Label
          htmlFor="select_contact"
          text={t('fleets.settings.contact_title')}
        />
        <KitSelect
          id="select_contact"
          name="new-contact"
          onChange={handleContactChange}
          value={getSelectedContactValue()}
          options={selectContactList}
        />
      </KitForm.Group>

      {!isEmpty(contact) && (
        <KitForm.Group>
          <ContactDetails>{email}</ContactDetails>
          <ContactDetails>{phone_number}</ContactDetails>
        </KitForm.Group>
      )}

      {!options && <StyledSpinner />}
    </>
  )
}

export interface FleetDetailsContactPanelProps {
  contact: Contact;
  fleetExternalID: string;
  t: TFunction;
  refreshSettings: (sectionId: string, resonse: Partial<Contact>) => void;
}
const FleetDetailsContactPanel = ({
  contact,
  fleetExternalID,
  refreshSettings,
  t,
}: FleetDetailsContactPanelProps) => {
  const [mode, setMode] = useState(EditMode.READ_ONLY)
  const [adminContacts, setAdminContacts] = useState<NOSContact[]>()
  const [selectedContact, setSelectedContact] = useState<NOSContact>(contact)
  const [isLoading, setIsLoading] = useState(false)
  const hasEditPermission = hasPermission(FLEET_FLEET_UPDATE)
  const { analyticsService } = useAnalyticsService()

  const handleSubmit = async () => {
    setIsLoading(true)
    const request = { contact_id: selectedContact.contact_id ?? selectedContact.nos_user_id }
    const updateResponse = await FleetService.updateFleetSettings(
      'contact',
      fleetExternalID,
      request,
    )
    refreshSettings('contact', updateResponse as Partial<Contact>)
    setMode(EditMode.READ_ONLY)
    setIsLoading(false)

    analyticsService.trackEvent(analyticEvents.fleetUpdated, { setting: 'contact' })
  }
  const handleCancel = () => {
    setMode(EditMode.READ_ONLY)
    setSelectedContact(contact)
  }

  useEffect(() => {
    setSelectedContact(contact)
  }, [contact])

  useEffect(() => {
    const fetchAdminContacts = async () => {
      const response = await AdminService.getAdminContacts()
      if (response) {
        setAdminContacts(response)
      }
    }
    if (mode === EditMode.EDIT && !adminContacts) {
      fetchAdminContacts()
    }
  }, [mode, adminContacts])

  const content = (() => {
    if (mode === EditMode.READ_ONLY) {
      return <ReadOnlyContact t={t} {...selectedContact} />
    }
    return (
      <EditContact
        contact={selectedContact}
        options={adminContacts as NOSContact[]}
        t={t}
        setContact={setSelectedContact}
      />
    )
  })()

  return (
    <EditPanel
      id="contact"
      t={t}
      title={t('contact')}
      mode={mode}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onToggle={() => {
        setMode(
          mode === EditMode.READ_ONLY ? EditMode.EDIT : EditMode.READ_ONLY,
        )
      }}
      readOnly={!hasEditPermission}
      isLoading={isLoading}
    >
      <Container>
        <KitForm noValidate>{content}</KitForm>
      </Container>
    </EditPanel>
  )
}

export default FleetDetailsContactPanel
