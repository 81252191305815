import {
  KitConstants,
  KitFlexColCentered,
  KitFlexRowCentered,
  KitLink,
  KitUtilCommon,
  KitUtilData,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'
import { type Dispatch, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import fleetStore from '../../../store/fleetStore'
import FleetCard from '../FleetCard'
import { AppRoutes } from '@/common/constants'
import { getLastURLPath } from '@/common/utils'
import {
  ALERT_EVENT_GROUP,
  EVENT_TYPE_CREATE,
  EVENT_TYPE_DELETE,
  EVENT_TYPE_REFRESH,
  EVENT_TYPE_UPDATE,
  type FleetWebSocketCallbackPayload,
  useWebSocket,
} from '@/common/websocket'
import NoResults from '@/components/NoResults'
import Notification, { NotificationLevel } from '@/components/Notification'
import {
  CardContainer,
  CardLayout,
  Container,
} from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import { type Alert } from '@/models/alertModel'
import { type Fleet } from '@/models/fleetModel'

const { spacing } = ThemeConstants

const FleetCards = (): JSX.Element => {
  const { t } = useTranslation()
  const loc   = useLocation()
  const query = KitUtilCommon.getQueryObject(loc.search)

  const navigate = useNavigate()
  const context  = useOutletContext<{ dispatch: Dispatch<unknown> }>()
  const dispatch = context?.dispatch

  const { filteredData } = fleetStore

  const hasData = !KitUtilData.isEmpty(fleetStore.results)

  const handleCreate = (payload: Fleet) => fleetStore.addRecord(payload)
  const handleUpdate = (payload: Fleet) => fleetStore.updateRecord(payload)
  const handleDelete = (payload: Partial<Fleet>) => fleetStore.deleteRecord(payload)

  const selectFleet = (fleet: Partial<Fleet>) => {
    const currentPath = getLastURLPath(window.location.pathname)
    const basePath    = AppRoutes.Fleets.path
    const path        = `${basePath}/${fleet.external_id}`
    navigate(path, { state: { path: currentPath } })
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const targ = e.target as HTMLDivElement
    if (
      KitUtilCommon.isKey(
        [KitConstants.KeyConstants.ENTER, KitConstants.KeyConstants.SPACE],
        e as unknown as KeyboardEvent,
      )
    ) {
      selectFleet({ id: targ?.dataset?.id } as Partial<Fleet>)
    }
  }

  const eventHandlerMap = {
    [EVENT_TYPE_UPDATE]  : handleUpdate,
    [EVENT_TYPE_DELETE]  : handleDelete,
    [EVENT_TYPE_CREATE]  : handleCreate,
    [EVENT_TYPE_REFRESH] : () => fleetStore.init(),
  }

  const handleAlertsUpdate = (eventType: string) => {
    if ([EVENT_TYPE_UPDATE, EVENT_TYPE_REFRESH, EVENT_TYPE_DELETE, EVENT_TYPE_REFRESH].includes(eventType)) {
      fleetStore.fetchAlerts()
    }
  }

  useWebSocket('/ws/fleetCard/', {
    onMessage: ({
      content,
      event_type,
      group,
    }: FleetWebSocketCallbackPayload) => {
      if (group === ALERT_EVENT_GROUP) {
        handleAlertsUpdate(event_type, content as unknown as Alert)
      } else {
        eventHandlerMap[event_type as keyof typeof eventHandlerMap](
          content as unknown as Fleet,
        )
      }
    },
  })

  useEffect(() => {
    if (!fleetStore.fleets) {
      fleetStore.init()
    }
  }, [fleetStore.fleets])

  useEffect(() => {
    if (query.refresh) {
      fleetStore.reset()
      navigate(`${AppRoutes.Fleets.path}`)
    }
  }, [query.refresh])

  if (!fleetStore.isFetching) {
    if (!hasData && !fleetStore.serviceError) {
      return (
        <Container className="fade-in">
          <Trans
            i18nKey="fleets.get_started_fleet"
            components={{
              btn: (
                <KitLink
                  onClick={() => dispatch({ type: 'SHOW_WIZARD', open: true })}
                />
              ),
            }}
          >
            { /* @ts-expect-error -- unsure how to type this */ }
            Get started by adding a <btn>fleet</btn>.
          </Trans>
        </Container>
      )
    }

    if (!hasData && fleetStore.serviceError) {
      return (
        <KitFlexRowCentered style={{ width: '100%' }}>
          <KitFlexColCentered>
            <NoResults message={t('errors.fleets_request_error')} />
          </KitFlexColCentered>
        </KitFlexRowCentered>
      )
    }

    if (filteredData?.length === 0) {
      return (

        <CardContainer data-qa-id="fleet_cards_container">
          <NoResults message={t('no_results')} />
        </CardContainer>
      )
    }

    if (hasData) {
      return (
        <CardContainer data-qa-id="fleet_cards_container">
          { fleetStore.serviceError && (
            <Notification
              style={{ marginBottom: `${spacing.absolute.m}px` }}
              type={NotificationLevel.WARNING}
              message={t(fleetStore.serviceError?.message as string, { itemType: t('alert_plural') })}
            />
          ) }

          <CardLayout>
            { (filteredData as Fleet[]).map((item: Fleet) => (
              <FleetCard
                key={item.id}
                {...item}
                t={t}
                onClick={() => selectFleet(item)}
                onKeyPress={handleKeyPress}
              />
            )) }
          </CardLayout>
        </CardContainer>
      )
    }
  }

  return <StyledSpinner />
}

export default observer(FleetCards)
