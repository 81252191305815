import {
  KitTab,
  KitTabs,
  KitUtilCommon,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import utils from '../../common/utils'
import { HiddenKitTabPanels, StyledKitTabList } from '../Styled'

const { isKey }                     = KitUtilCommon
const { border, fontSize, spacing } = ThemeConstants

const Tab = styled(KitTab)`
  cursor: pointer;
  color: ${({ selected }) => (selected ? ThemeColors.blue_50 : ThemeColors.gray_50)};
  font-size: ${fontSize.text_14}rem;
  outline: 0;
  padding: ${spacing.s}rem;
  border-bottom: ${border.width}px solid
    ${({ selected }) => (selected ? ThemeColors.blue_50 : 'transparent')};

  &:hover {
    border-bottom: ${border.width}px solid ${ThemeColors.gray_20};
  }
`

export interface TabItemProps {
  id: string;
  path: string;
  label: string;
  qaId: string;
  isEnabled?: boolean;
}

export interface TabNavProps {
  tabs: TabItemProps[];
  onChange: (tab: TabItemProps) => void;
  baseURL?: string | null;
}

const TabNav: FC<TabNavProps> = ({ baseURL, onChange, tabs = [] }) => {
  const currentPath                   = utils.getLastURLPath(window.location.pathname)
  const defaultIndex                  = tabs.findIndex(
    (tab) => tab.path && currentPath.includes(tab.path),
  )
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const navigate                      = useNavigate()

  const handleTabSwitch = (idx: number) => {
    const selectedItem = tabs[idx]
    setActiveIndex(idx)
    if (onChange) {
      onChange(tabs[idx])
    } else {
      navigate(`${baseURL}/${selectedItem.path}`)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    // TODO: Change KeyBoard event in isKey to use a generic React.KeyboardEvent not the regular KeyboardEvent
    if (isKey('LEFT', e as unknown as KeyboardEvent)) {
      if (activeIndex > 0) {
        handleTabSwitch(activeIndex - 1)
      }
    }
    if (isKey('RIGHT', e as unknown as KeyboardEvent)) {
      if (activeIndex < tabs.length - 1) {
        handleTabSwitch(activeIndex + 1)
      }
    }
  }

  if (!tabs.length) {
    return null
  }

  const getKey = (item: TabItemProps) => item.id ?? item.label ?? item.path

  useEffect(() => {
    setActiveIndex(defaultIndex > -1 ? defaultIndex : 0)
  }, [tabs])

  return (
    <KitTabs selectedIndex={activeIndex}>
      <StyledKitTabList role="tablist">
        { tabs.map((item, i: number) => (
          <Tab
            key={getKey(item)}
            aria-selected={activeIndex === i}
            selected={activeIndex === i}
            onClick={() => handleTabSwitch(i)}
            onKeyDown={handleKeyDown}
            tabIndex={'0'}
            role="tab"
            data-qa-id={item.qaId}
          >
            { item.label }
          </Tab>
        )) }
      </StyledKitTabList>
      { tabs.map((tab, idx) => (
        <HiddenKitTabPanels key={`hidden-tab-panel-${idx}`} />
      )) }
    </KitTabs>
  )
}

export default TabNav
