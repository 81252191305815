/* eslint-disable camelcase */
import {
  KitButton,
  KitFlexColCentered,
  KitFlexRow,
  KitForm,
  KitIcon,
  KitLink,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, useEffect, useState } from 'react'

import styled from 'styled-components'

import { EditMode } from '@/common/constants'
import { FLEET_FLEET_UPDATE } from '@/common/permissions'
import { groupBy, toOptionsArray } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import EditPanel from '@/components/EditPanel'
import {
  List,
  ListItem,
  ListItemSmall,
  SectionItem,
  Spacer,
} from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import { type TelematicsAccount } from '@/models/telematicsModel'
import TelematicsService from '@/services/TelematicsService'

const { fontSize, spacing } = ThemeConstants

const Container = styled.div`
  height: 200px;
`

const FieldLabel = styled.div`
  color: ${ThemeColors.gray_70};
  margin-bottom: ${spacing.absolute.s}px;
`

const ZonesContainer = styled.div`
  li {
    padding-left: 0;
    > div {
      line-height: ${fontSize.base}rem;
      color: ${ThemeColors.gray_90};
    }
  }
  .removezone-button {
    margin: 0 ${spacing.absolute.s}px;
  }

  margin-bottom: ${spacing.absolute.s}px;
  margin-left: ${spacing.absolute.s}px;
`

const StyledKitLink = styled(KitLink)`
  margin-left: ${spacing.absolute.s}px;
`

const formatZoneAlert = (
  { min_alerted_soc, time_before_alerting_minutes, zone_name }: ZoneAlert,
  t: TFunction,
) => {
  const socText = hasValue(min_alerted_soc)
    ? `- ${
      t('telematicAlert.min_alerted_soc', { percentage: min_alerted_soc })}`
    : ''
  return `${zone_name} - ${time_before_alerting_minutes} ${t(
    'units.minutes_short',
  )} ${socText}`
}

interface ZoneAlert {
  fleet_external_id: number;
  id: number;
  min_alerted_soc: number;
  time_before_alerting_minutes: number;
  zone_id: number;
  zone_name: string;
}

interface ZoneAlertsProps {
  editZoneAlert: (zoneAlert: unknown) => void;
  removeZoneAlert: (id: number, zoneId: number, zoneName: string) => void;
  mode: EditMode;
  t: TFunction;
  zoneAlerts: ZoneAlert[];
}

interface GetContentProps extends ZoneAlertsProps {
  accounts: TelematicsAccount[];
  accountOptions: Record<string, unknown>;
  zoneAlerts: ZoneAlert[];
  addZoneAlert: () => void;
}

const ZoneAlerts: FC<ZoneAlertsProps> = ({
  editZoneAlert,
  mode,
  removeZoneAlert,
  t,
  zoneAlerts,
}) => (
  <ZonesContainer>
    <KitForm.Group>
      <KitForm.Label
        htmlFor="org_name"
        text={t('vehicles.settings.vehicle_zone_alerts')}
      />

      <List>
        { zoneAlerts.map((zoneAlert) => (
          <ListItemSmall key={zoneAlert.zone_name}>
            { mode === EditMode.READ_ONLY ? (
              <KitForm.Value>{ formatZoneAlert(zoneAlert, t) } </KitForm.Value>
            ) : (
              <KitForm.Value>
                <KitFlexRow>
                  <KitFlexColCentered>
                    <KitLink onClick={() => editZoneAlert(zoneAlert)}>
                      { formatZoneAlert(zoneAlert, t) }
                    </KitLink>
                  </KitFlexColCentered>
                  <KitFlexColCentered>
                    <KitButton
                      className="removezone-button"
                      data-id="close"
                      variant="circle"
                      onClick={() => removeZoneAlert(
                        zoneAlert.id,
                        zoneAlert.zone_id,
                        zoneAlert.zone_name,
                      )
                      }
                    >
                      <KitIcon
                        icon="close"
                        size={`${ThemeConstants.iconSize.xs}rem`}
                        fill={ThemeColors.gray_40}
                      />
                    </KitButton>
                  </KitFlexColCentered>
                </KitFlexRow>
              </KitForm.Value>
            ) }
          </ListItemSmall>
        )) }
      </List>
    </KitForm.Group>
  </ZonesContainer>
)

function processTelematicsResults(
  telematics: TelematicsAccount[],
  t: TFunction,
) {
  const byAccount = groupBy(telematics, 'telematics_account_id')
  return {
    accounts: Object.entries(byAccount).map(([key, value]) => {
      const vehicles                    = value
      const matchedVehicles             = vehicles.filter((v) => hasValue(v.telematics_id)).length
      const totalVehicles               = vehicles.length
      const { telematics_account_name } = vehicles[0]

      return {
        id     : key,
        name   : telematics_account_name ?? t('unknown'),
        matchedVehicles,
        totalVehicles,
        status : telematics_account_name ? 'online' : 'offline',
      }
    }),
  }
}

const getContent = ({
  accountOptions,
  accounts,
  addZoneAlert,
  editZoneAlert,
  mode,
  removeZoneAlert,
  t,
  zoneAlerts,
}: GetContentProps) => {
  const hasZoneAlerts    = zoneAlerts?.length > 0
  const isEditMode       = mode === EditMode.EDIT
  const checkIfHasAlerts = () => (
    !isEditMode
      && !hasZoneAlerts && (
      <List listType="none">
        <ListItemSmall data-qa-id="no_alerts_message">
          { t('telematicAlert.noAlertsForFleet') }
        </ListItemSmall>
      </List>
    )
  )
  return !accounts.length ? (
    <>
      { !isEditMode && (
        <KitForm>
          <KitForm.Group>
            <KitForm.Value dataQaId="no_alerts_message">
              { t('telematicAlert.noAlertsForFleet') }
            </KitForm.Value>
          </KitForm.Group>
          <KitForm.Group>
            <KitForm.Value dataQaId="no_alerts_detail">
              { t('telematicAlert.noAlertsForFleetDetail') }
            </KitForm.Value>
          </KitForm.Group>
        </KitForm>
      ) }
      { isEditMode && (
        <>
          { checkIfHasAlerts() }
          { accountOptions?.length > 0 ? (
            <>
              <SectionItem>
                { hasZoneAlerts && (
                  <ZoneAlerts
                    zoneAlerts={zoneAlerts}
                    t={t}
                    editZoneAlert={editZoneAlert}
                    removeZoneAlert={removeZoneAlert}
                    mode={mode}
                  />
                ) }
              </SectionItem>
              { !hasZoneAlerts && (
                <>
                  <SectionItem
                    style={{ marginLeft: `${spacing.absolute.s}px` }}
                  >
                    <FieldLabel>
                      { t('vehicles.settings.vehicle_zone_alerts') }
                    </FieldLabel>
                    { t('telematics_accounts.no_zones_found') } <br />
                    { t('telematics_accounts.zones_required') } <br />
                  </SectionItem>
                  { !isEditMode && t('telematicAlert.off') }
                </>
              ) }
              <SectionItem>
                { isEditMode && hasZoneAlerts && (
                  <StyledKitLink onClick={addZoneAlert}>
                    + { t('telematicAlert.addAlert') }
                  </StyledKitLink>
                ) }
              </SectionItem>
            </>
          ) : (
            <StyledSpinner />
          ) }
        </>
      ) }
      <Spacer orientation="vertical" size={spacing.absolute.xxl} />
    </>
  ) : (
    <Container>
      { checkIfHasAlerts() }
      { hasZoneAlerts && (
        <List>
          <ListItem>
            <SectionItem>
              <ZoneAlerts
                zoneAlerts={zoneAlerts}
                t={t}
                editZoneAlert={editZoneAlert}
                removeZoneAlert={removeZoneAlert}
                mode={mode}
              />
            </SectionItem>
          </ListItem>
        </List>
      ) }
      { isEditMode && (
        <StyledKitLink onClick={addZoneAlert}>
          + { t('telematicAlert.addAlert') }
        </StyledKitLink>
      ) }
    </Container>
  )
}

export interface FleetTelematicsSettingsProps {
  t: TFunction;
  telematics: unknown[];
  zoneAlerts: unknown[];
  onAddZoneAlert: (zoneAlert: unknown) => void;
  onEditZoneAlert: (zoneAlert: unknown) => void;
  onRemoveZoneAlert: (id: number, zoneId: number, zoneName: string) => void;
  onSubmit: () => void;
  telematicsAlertsMode: EditMode;
  onTelematicsAlertsModeChange: (mode: EditMode) => void;
}

const FleetTelematicsSettings: FC<FleetTelematicsSettingsProps> = ({
  onAddZoneAlert,
  onEditZoneAlert,
  onRemoveZoneAlert,
  onSubmit,
  onTelematicsAlertsModeChange,
  t,
  telematics = [],
  telematicsAlertsMode,
  zoneAlerts,
}) => {
  const [allTelematicsAccounts, setAllTelematicsAccounts] =    useState<TelematicsAccount[]>()
  const [disableSubmit, setDisableSubmit]                 = useState(true)

  function handleSubmit() {
    onSubmit()
  }

  function handleCancel() {
    onTelematicsAlertsModeChange(EditMode.READ_ONLY)
  }

  const addZoneAlert = () => {
    onAddZoneAlert()
    setDisableSubmit(false)
  }

  const editZoneAlert = (alert: ZoneAlert) => {
    onEditZoneAlert(alert)
    setDisableSubmit(false)
  }

  const removeZoneAlert = (id: number, zoneId: number, zoneName: string) => {
    onRemoveZoneAlert(id, zoneId, zoneName)
    setDisableSubmit(false)
  }

  const onTelematicsAccountChange = () => {
    setDisableSubmit(false)
  }

  useEffect(() => {
    async function fetchTelematicsAccounts() {
      const response = await TelematicsService.getTelematicsAccounts()
      if (!response.error) {
        setAllTelematicsAccounts(response.results)
      }
    }

    if (telematicsAlertsMode === EditMode.EDIT) {
      fetchTelematicsAccounts()
      setDisableSubmit(true)
    }
  }, [telematicsAlertsMode])

  if (!telematics) {
    return <StyledSpinner />
  }

  const { accounts } = processTelematicsResults(telematics, t)

  return (
    <EditPanel
      id="alerts"
      readOnly={accounts?.length === 0}
      disableSubmit={disableSubmit}
      t={t}
      title={t('alert_plural')}
      mode={telematicsAlertsMode}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onToggle={() => {
        onTelematicsAlertsModeChange(
          telematicsAlertsMode === EditMode.READ_ONLY
            ? EditMode.EDIT
            : EditMode.READ_ONLY,
        )
      }}
      permissions={FLEET_FLEET_UPDATE}
    >
      { getContent({
        accounts,
        accountOptions: toOptionsArray(allTelematicsAccounts, {
          labelField : 'name',
          valueField : 'id',
        }),
        t,
        zoneAlerts,
        mode: telematicsAlertsMode,
        addZoneAlert,
        editZoneAlert,
        removeZoneAlert,
        onTelematicsAccountChange,
      }) }
    </EditPanel>
  )
}

export default FleetTelematicsSettings
