/* eslint-disable @typescript-eslint/no-use-before-define */
import { KitUtilData } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'

import { type SimpleType } from '../../types'
import * as data from './data'
import { getCurrentUser, getOrgId } from './user'
import { hasValue } from './validations'
import {
  HELP_PATH,
  SUPPORT_PAGE_URL,
  VCT_SUPPORT_PAGE_URL,
  chargingStatusMap,
} from '@/common/constants'
import { type BaseServiceResponse, type ServiceResponse } from '@/models/serviceModel'
import { type UserApp } from '@/models/userModel'
import FeatureService, { Features } from '@/services/FeatureService'
import { type AppInfoProps, type NavItem } from '@/types/models'

/**
* Checks to see if the client was referrered from a different domain
* This is useful when performing checks for in-app back buttons (like on master/detail ) pages where
  navigate(-1) or history.back() would make the user leave the app,
  when it would be preferred for them to instead be sent to the master or list page
 */
export const clientNavigatedFromOtherDomain = () => {
  const { referrer }   = document
  const referrerDomain = new URL(referrer).hostname
  const currentDomain  = document.location.hostname
  return referrerDomain !== currentDomain
}

export const constructWebSocketURI = (path: string): string => {
  // used during testing
  if (path.includes('ws://')) {
    return path
  }
  const protocol = getConstant('BACKEND_WEBSOCKETS_PROTOCOL')
  const host     = getConstant('BACKEND_WEBSOCKETS_HOST')
  return `${protocol}://${host}${path}`
}

export const debounce = <T>(
  func: (arg: T) => void,
  delay: number,
): (() => void) => {
  let debounceTimer: ReturnType<typeof setTimeout>
  return function innerCallback(this: unknown, ...args: unknown[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(
      () => func.apply(context, args as [arg: T]),
      delay,
    )
  }
}

export const difference = (arr: unknown[], values: unknown[]): unknown[] => {
  const arrays = [arr, values]
  return arrays.reduce((a, b) => a.filter((c) => !b.includes(c)))
}

export const findById = (
  searchId: string | number,
  items: any[],
  idField?: string,
): unknown => {
  const idToUse = idField ?? 'id'
  return items.find(
    ({ [idToUse]: id }) => id.toString() === searchId.toString(),
  )
}

export function getAppName(): string {
  return 'fleet'
}

interface CreateNavItemProps {
  url: string;
  title: string;
  org_external_id: string;
  count: number;
  appKey: string;
}
const createNavItem = (
  { appKey, count = 1, org_external_id, title, url }: CreateNavItemProps,
  t: TFunction,
) => ({
  name:
      // eslint-disable-next-line no-nested-ternary
      count > 1
        ? t(`kit:app_switcher.${appKey}_plus_title`, { title })
        : count === 1
          ? t(`kit:app_switcher.${appKey}`)
          : title,
  url: `https://${url}`,
  org_external_id,
})

// app version is used in the Sentry.init config, and also to used to clear old localStorage caches after a deployment
export function getAppVersion(): string {
  return window.runtimeVersion?.VERSION
}

/**
 * Returns code needed for KitBadge to render pill color
 * @param {*} status
 * @returns
 */
export const getChargingStatusCode = (status: string): number => {
  const code = chargingStatusMap[status]
  if (!hasValue(code)) {
    if (status) {
      console.warn('No charging status code found for', status)
    }
  }
  return code
}

export const getCleanURL = (url: string, removeQuery?: boolean): string => {
  const isFullyQualified = url.match(/^(http|https):\/\//)
  const cleanURL         = removeQuery ? url.substr(0, url.indexOf('?')) : url
  if (isFullyQualified) {
    const partial = cleanURL.substring(isFullyQualified[0].length)
    return `${isFullyQualified[0]}${partial.replace(/\/\//g, '/')}`
  }

  return cleanURL.replace('//', '/')
}

export function getConstant(
  name: string,
): SimpleType | Record<string, unknown> {
  return window.runtime?.[name]
}

// Note: This function should eventually just go away! The backend should return proper error codes.
// At the moment, the error messages from the backend (although imperfect and not localized) are often
// more helpful than generic ui error messages.
export const getError = (
  response: ServiceResponse<unknown>,
): string => {
  if (response && typeof response === 'string') {
    return response
  }
  if (response.error) {
    if (typeof response.error === 'string') {
      return response.error
    }
    if (typeof response.error.json === 'object') {
      return response.error.json.detail
    }
  }

  return response
    && typeof (response as ServiceResponse<unknown>).error === 'string'
    ? ((response as BaseServiceResponse).error as string)
    : ''
}

export function getFeatureContext(): Record<string, unknown> {
  const user    = getCurrentUser()
  const context = {
    orgID        : getOrgId(),
    userName     : user?.username,
    email        : user?.email,
    host         : window.location.host,
    instanceName : getConstant('DEPLOYMENT_NAME') ?? 'localhost',
  }
  return context
}

export const getHelpURL = (): string => {
  if (FeatureService.isEnabled(Features.vctHelpEnabled)) {
    return VCT_SUPPORT_PAGE_URL
  }
  return getPrimaryAppURL(HELP_PATH)
}

// grabs the final path from url -- useful for checking which view browser is currently looking at
// TODO: Currently will not work if url has trailing slash, should be fixed
export const getLastURLPath = (url: string): string => url.substring(url.lastIndexOf('/') + 1)

export function getLogoutUrl(): string {
  if (getCurrentUser()?.source === 'vct') {
    return `${getConstant('VCT_APP_BASE_URL')}/logout`
  }

  return `${getConstant('SSO_HOST')}/logout?${getRedirectQueryParameter()}`
}

// NOTE: Not sure if we still need this.
export function getNOSUrl(path: string): string {
  const { apps } = getCurrentUser()
  const nosItem  = KitUtilData.getProp(apps, 'station[0]') as UserApp
  return `https://${nosItem?.url}${path}`
}

export function getNavBarProps(
  userApps: Record<string, unknown>,
  t: TFunction,
): {
    activeAppIndex: number;
    apps: NavItem[];
  } {
  const currentHost: string = window.location.host
  let apps: NavItem[]       = [
    {
      name : '...',
      url  : '',
      path : '',
    },
  ]

  let activeAppIndex = 0
  let index          = 0
  if (hasValue(userApps)) {
    apps = []
    Object.entries(userApps).forEach(([appKey, appInfo]) => {
      (appInfo as AppInfoProps[]).forEach(({ org_id, title, url }) => {
        if (url === currentHost) activeAppIndex = index
        index += 1
        apps.push(
          createNavItem(
            {
              url,
              title,
              org_id,
              count: (appInfo as AppInfoProps[]).length,
              appKey,
            },
            t,
          ),
        )
      })
    })
  }

  return {
    activeAppIndex,
    apps,
  }
}

// we can hopefully remove all references to NOS soon... this is still needed until that happens
export const getPrimaryAppURL = (path: string): string => getNOSUrl(path) as string

export const getProfileURL = (): string => {
  const { user_profile_url } = getCurrentUser()
  return user_profile_url
}

export function getRedirectQueryParameter(): string {
  return `redirect=${encodeURIComponent(window.location as unknown as string)}`
}

export function getSSORedirectURL() {
  let baseSSOURL = getConstant('SSO_HOST') as string
  if (baseSSOURL?.substring(0, 8) !== 'https://') {
    baseSSOURL = `https://${baseSSOURL}`
  }
  return `${baseSSOURL}?${getRedirectQueryParameter()}`
}

export function isDebug() {
  const val = localStorage.getItem('debug')
  return KitUtilData.toBoolean(val)
}

export const isDevMode = () => [
  'fleet-dev.ev-chargepoint.com',
  'fleet-local.ev-chargepoint.com',
  'fleet-staging.ev-chargepoint.com',
].includes(window.location.host)

export const isExternalId = (id: string): boolean => !!String(id).match(/[a-z]/)

export const isProd = () => {
  const env = getConstant('ENVIRONMENT') as string
  return ['cp-prod'].includes(env)
}

// Only VCT users will be able to see the settings page at this time
export function isSettingsPageEnabled() {
  const user = getCurrentUser()
  return user?.source === 'vct'
}

export function isSettingsTabEnabled() {
  const user = getCurrentUser()
  return user?.source === 'vct'
}

export const navigateTo = (where: string) => {
  switch (where) {
    case 'home':
      document.location = `${document.location.protocol}//${document.location.host}`
      break
    case 'support':
      document.location = SUPPORT_PAGE_URL
      break
    default:
      document.location = `${document.location.protocol}//${document.location.host}`
  }
}

/**
 * Formats hour and minute values with a leading '0' when value is less than 10
 * @example  padNumber(5) returns '05'
 * @param time
 * @returns string
 */
export const padNumber = (time: number): string | number => (time < 10 ? `0${time}` : time)

export const paramsToQueryString = (
  params: Record<string, string | number | boolean | unknown>,
  multipleValues = false,
): string => {
  if (!params) {
    return ''
  }
  const entries = Object.entries<unknown>(params)
  const query   = entries
    .reduce((acc, [k, v]) => {
      if (multipleValues) {
        acc.push(
          String(v)
            .split(',')
            .map((val) => `${encodeURIComponent(k)}=${encodeURIComponent(val)}`)
            .join('&'),
        )
      } else {
        acc.push(`${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`)
      }
      return acc
    }, [] as string[])
    .join('&')

  return entries.length ? `?${query}` : ''
}

export const round = (num: number, precision = 1) => Math.round(num / precision) * precision

export function toTitleCase(text: string): string {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export default {
  constructWebSocketURI,
  data,
  debounce,
  getAppName,
  getChargingStatusCode,
  getCleanURL,
  getConstant,
  getLastURLPath,
  getNavBarProps,
  getRedirectQueryParameter,
  getLogoutUrl,
  paramsToQueryString,
  toTitleCase,
  padNumber,
}
