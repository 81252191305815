import {
  KitFlexColCentered,
  KitFlexRowCentered,
} from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

import sadCar from '../../images/sad-car.png'
import { fadeIn } from '@/common/animations'

const Container = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.8s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  mix-blend-mode: multiply;
`

const SadCar = styled.img<{ size: string }>`
  width: ${({ size }) => (size === 's' ? '100px' : '120px')};
`

const NoResults = ({
  height,
  message,
  size = 'm',
}: {
  message?: string | null;
  size?: 's' | 'm';
  height?: string;
}) => {
  const h = height || (size === 's' ? '150px' : '200px')

  return (
    <Container>
      <KitFlexRowCentered style={{ width: '100%', height: h }}>
        <KitFlexColCentered style={{ alignItems: 'center' }} aria-live="polite">
          <SadCar src={sadCar} size={size} alt="" />
          { message && <h5>{ message }</h5> }
        </KitFlexColCentered>
      </KitFlexRowCentered>
    </Container>
  )
}

export default NoResults
