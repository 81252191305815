import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { getAppVersion, getConstant } from '@/common/utils'
import { omit } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'

export enum ErrorMonoringConstants {
  RELEASE_VERSION = 'RELEASE_VERSION',
  SENTRY_DSN = 'SENTRY_DSN',
  ERROR_SAMPLE_RATE = 'ERROR_SAMPLE_RATE',
}

function getConfigParam(paramName: string): string | number | null {
  switch (paramName) {
    case ErrorMonoringConstants.RELEASE_VERSION:
      return `frontend@${getAppVersion()}`
    case ErrorMonoringConstants.SENTRY_DSN:
      return getConstant(paramName) as string
    case ErrorMonoringConstants.ERROR_SAMPLE_RATE:
      return (() => {
        const sampleRate = parseInt(getConstant(paramName) as string) ?? 0.2
        if (!Number.isNaN(sampleRate)) {
          return sampleRate
        }
        return 1
      })()
    default:
      return null
  }
}

// TODO: Discuss with Fleet team about using LogRocket for error logging
export default class ErrorReportingService {
  static intialized = false

  static initialize(): void {
    const environment      = getConstant('ENVIRONMENT')
    const release: string  = getConfigParam(
      ErrorMonoringConstants.RELEASE_VERSION,
    ) as string
    const tracesSampleRate = getConfigParam(
      ErrorMonoringConstants.ERROR_SAMPLE_RATE,
    ) as number
    const dsn: string      = getConfigParam(
      ErrorMonoringConstants.SENTRY_DSN,
    ) as string

    const sentryInitProps = omit(
      {
        dsn,
        environment,
        release,
        integrations : [new BrowserTracing({})],
        tracesSampleRate,
        // "TypeError: Failed to fetch" usually occurs when a fetch is interrupted as a user is navigating.
        // We could reduce these by specifically aborting fetch calls, but these errors
        // are also just noise in the Sentry log that we are ignoring anyway.
        ignoreErrors : ['TypeError: Failed to fetch'],
      },
      ((val: string | number) => !hasValue(val)) as unknown as string[],
    )

    if (!ErrorReportingService.intialized && dsn) {
      Sentry.setTag('service', 'frontend')
      Sentry.setTag('deployment', getConstant('DEPLOYMENT_NAME') as string)
      Sentry.setTag('region', getConstant('AWS_REGION') as string)

      Sentry.init(sentryInitProps)
      ErrorReportingService.intialized = true
    } else {
      console.warn('Error Monitoring NOT enabled')
    }
  }

  static captureException(
    err: Error,
    captureContext?: Record<string, string | unknown>,
  ) {
    if (ErrorReportingService.intialized) {
      Sentry.captureException(err, captureContext)
    }
  }

  /* TODO:  enable custom error reporting */
}
