import {
  KitForm,
  KitInput,
  KitLink,
  KitSelect,
  KitToastTypeOptions,
  KitUtilData,
  ThemeConstants,
  useToast,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import DeleteFleetModal from './DeleteFleetModal'
import { AppRoutes, EditMode } from '@/common/constants'
import { FLEET_FLEET_UPDATE } from '@/common/permissions'
import EditPanel from '@/components/EditPanel'
import ReadOnlySettings from '@/components/Settings/ReadOnlySettings'
import { PaddedKitTextArea } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import { type Depot } from '@/models/depotModel'
import { type Fleet, type FleetSettingsAbout } from '@/models/fleetModel'
import { type SelectChangeEvent } from '@/models/formModel'
import { type ServiceRequest } from '@/models/serviceModel'
import DepotService from '@/services/DepotService'
import FleetService from '@/services/FleetService'

const { spacing } = ThemeConstants

const DeleteFleetButtonContainer = styled.div`
  position: absolute;
  left: ${spacing.m}rem;
  bottom: ${spacing.m + spacing.xs}rem;
  a.delete {
    align-items: center;
    color: ${({ theme }) => theme.error};
    display: flex;
  }
`

const DeleteFleetButton = ({
  onClick,
  t,
}: {
  onClick: () => void;
  t: TFunction;
}) => (
  <DeleteFleetButtonContainer>
    <KitLink className="delete" onClick={onClick}>
      { t('btn_delete') }
    </KitLink>
  </DeleteFleetButtonContainer>
)

export interface FleetAboutPanelProps {
  about: FleetSettingsAbout;
  fleetExternalID: string;
  refreshSettings: (
    settingName: string,
    response: Record<string, unknown>
  ) => void;
}

const FleetAboutPanel: FC<FleetAboutPanelProps> = ({
  about,
  fleetExternalID,
  refreshSettings,
}) => {
  const { t }                                 = useTranslation()
  const [depots, setDepots]                   = useState<Depot[]>()
  const [fleet, setFleet]                     = useState<Fleet>()
  const [inEditMode, setInEditMode]           = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [errors, setErrors]                   = useState<Record<string, string>>({})
  const [serviceError, setServiceError]       = useState<string>()
  const navigate                              = useNavigate()

  const { handleSubmit, register, reset, setValue } = useForm({ mode: 'onBlur' })

  const defaultValues = {
    name                   : about.name,
    description            : about.description,
    home_depot_external_id : about.home_depot_external_id,
  }

  const defaultDepotValue = about.home_depot_external_id ? {
    value : about.home_depot_external_id,
    label : about.home_depot_name,
  } : {
    label : t('select'),
    value : undefined,
  }

  const depotList = depots?.map((depot: Depot) => ({
    value : depot.external_id,
    label : depot.name,
  }))

  async function fetchFleet() {
    const result = await FleetService.getFleet(fleetExternalID)
    setFleet(result as Fleet)
  }

  async function fetchDepots() {
    const response = await DepotService.getDepots()
    setDepots(response.results)
  }

  async function deleteFleet() {
    try {
      await FleetService.deleteFleet(fleetExternalID)
      setShowDeleteModal(false)
      useToast({
        t,
        toastType : KitToastTypeOptions.SUCCESS,
        message   : t('toast_messages.delete_success', { item: fleet?.name }),
      })

      setTimeout(() => {
        navigate(`${AppRoutes.Fleets.path}?refresh=true`)
      }, 500)
    } catch (err) {
      setServiceError(t('fleets.settings.error_deleting_fleet'))
    }
  }

  const submitForm = async (data: ServiceRequest) => {
    const updatedData   = { ...data }
    const aboutResponse = await FleetService.updateFleetSettings(
      'about',
      fleetExternalID,
      updatedData,
    )
    refreshSettings('about', aboutResponse as Partial<FleetSettingsAbout>)
    setInEditMode(false)
  }

  const handleCancel = () => {
    setInEditMode(false)
  }

  const handleDeleteClicked = () => {
    setShowDeleteModal(true)
  }

  function handleErrors<T = unknown>(err: T): void {
    setErrors(err as Record<string, string>)
  }

  useEffect(() => {
    if (!depots) {
      fetchDepots()
    }

    if (!fleet) {
      fetchFleet()
    }
  }, [depots, fleet])

  useEffect(() => {
    if (inEditMode) {
      reset(defaultValues)
    }
  }, [inEditMode])

  let editableContent
  if (!about) {
    editableContent = <StyledSpinner />
  } else if (inEditMode) {
    editableContent = (
      <>
        <KitForm.Group>
          <KitForm.Label htmlFor="fleet_name" text={t('fleet_name')} required />
          <KitInput
            id="fleet_name"
            defaultValue={about.name}
            required
            isError={errors.name !== undefined}
            infoMessage={errors.name ? t('errors.required') : undefined}
            {...register('name', { required: true })}
          />
        </KitForm.Group>
        <KitForm.Group>
          <KitForm.Label htmlFor="fleet_description" text={t('description')} />
          <PaddedKitTextArea
            id="fleet_description"
            defaultValue={about.description}
            {...register('description')}
          />
        </KitForm.Group>
        <KitForm.Group>
          <KitForm.Label htmlFor="home_depot" text={t('depot')} />
          <KitSelect
            options={depotList}
            {...register('home_depot_external_id', { required: true })}
            defaultValue={defaultDepotValue}
            required
            onChange={(e) => {
              setValue('home_depot_external_id', (e as SelectChangeEvent).value)
              setErrors({})
            }}
            isError={errors?.home_depot_external_id}
            infoMessage={errors?.home_depot_external_id ? t('required') : undefined}
          />
        </KitForm.Group>
        <KitForm.Group>
          <KitForm.Label htmlFor="org_name" text={t('organization')} />
          <KitForm.Value>{ about.org_name } </KitForm.Value>
        </KitForm.Group>
        { !KitUtilData.isEmpty(fleet) && (
          <>
            <DeleteFleetButton onClick={handleDeleteClicked} t={t} />
            <DeleteFleetModal
              serviceError={serviceError}
              fleet={fleet as Fleet}
              onDelete={deleteFleet}
              onClose={() => setShowDeleteModal(false)}
              show={showDeleteModal}
              t={t}
            />
          </>
        ) }
      </>
    )
  } else {
    editableContent = (
      <ReadOnlySettings
        fields={[
          {
            label  : t('fleet_name'),
            value  : about.name,
            testId : 'fleet_name',
          },
          {
            label  : t('description'),
            value  : about.description,
            testId : 'description',
          },
          {
            label  : t('depot'),
            value  : about.home_depot_name,
            testId : 'fleet_name',
          },
        ]}
      >
        <KitForm.Group>
          <KitForm.Label htmlFor="org_name" text={t('organization')} />
          <KitForm.Value dataQaId="org_name_value">
            { about.org_name }{ ' ' }
          </KitForm.Value>
        </KitForm.Group>
      </ReadOnlySettings>
    )
  }

  return (
    <EditPanel
      id="about"
      isLoading={!about}
      t={t}
      title={t('about')}
      mode={inEditMode ? EditMode.EDIT : EditMode.READ_ONLY}
      onSubmit={handleSubmit(submitForm, handleErrors)}
      onCancel={handleCancel}
      onToggle={() => {
        setInEditMode(!inEditMode)
      }}
      permissions={FLEET_FLEET_UPDATE}
    >
      <KitForm noValidate>{ editableContent }</KitForm>
    </EditPanel>
  )
}

export default FleetAboutPanel
