import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

import StyledFleetCard from '@/components/StyledFleetCard'

const { breakpoints, fontSize, fontWeight, spacing } = ThemeConstants

export const AlertsContainer = styled.div`
  ul {
    width: 100%;
  }
  .minimal {
    padding: ${spacing.sm}rem ${spacing.m}rem;
  }
  margin-bottom: ${spacing.xl}rem;
`

export const CardContainer = styled(StyledFleetCard)`
  height: fit-content;
  min-width: 420px;

  &:last-child {
    margin-right: 0;
  }

  &.vehicle-status {
    margin-right: ${spacing.absolute.xl}px;
    justify-content: start;
  }

  .vehicle-info {
    margin-top: ${spacing.absolute.s}px;
  }

  @media all and (max-width: ${breakpoints.lg}px) {
    margin-right: 0;
    margin-bottom: ${spacing.absolute.l}px;
    min-width: auto;
  }
`

export const Container = styled.div`
  width: 100%;
  .error,
  info,
  .warning,
  .info {
    margin-bottom: ${spacing.absolute.xl}px;
    ul {
      width: 100%;
    }
  }
`

export const Content = styled.div`
align-items: start;
  display: flex;
  margin-top: ${spacing.l}rem;
  width: 100%;
  @media all and (max-width: ${breakpoints.lg}px) {
    flex-direction: column;
  }
`

export const FlexItem = styled.div`
  flex: 1;
`

export const GraphContainer = styled.div`
  flex: 2;
`

export const GraphStyledCardBody = styled(StyledFleetCard.Body)`
  padding: ${ThemeConstants.spacing.l}rem 0 0;
`

export const KitDisplayNumbersWrapper = styled.div`
  /* TODO: If nobody else is using KitDisplayNumber, consider removing padding 
   from KitDisplayNumber in cp-toolkit instead of this ugly override  */
  > div > div {
    padding: 0;
    > div { 
      &:hover {
        background ${ThemeColors.gray_05};
      }
    }
  }
`

export const StatusContainer = styled.div`
  display: flex;
  .ready.isUnplugged {
    font-weight: ${fontWeight.bold};
    background: transparent;
    color: ${ThemeColors.green_50};
  }
`

export const TimeStampLabel = styled.span`
  color: ${ThemeColors.gray_70};
  font-size: ${fontSize.text_14}rem;
  font-weight: ${fontWeight.normal};
`

export const UpcomingBlocksContainer = styled(StyledFleetCard)`
  height: fit-content !important;
  margin-top: ${spacing.absolute.xl}px;
  div:nth-child(2) {
    padding: ${spacing.absolute.xxs}px;
  }
`

export const VehicleImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing.absolute.m}px;
`
