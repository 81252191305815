import { type RequestType } from '@/common/constants'

export type GenericCallBack = (args?: unknown) => void;

export interface BaseServiceResponse {
  error?: ServiceError | Record<string, string>;
  status?: number;
}

export interface ServiceResponse<T> extends BaseServiceResponse {
  results?: T;
  success?: boolean;
}

export interface PagedServiceResponse<T> {
  error?: ServiceError;
  status?: number;
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

type R = Record<string, unknown>;
export type ServicePayload<T = unknown> = T extends unknown ? R : T;

/**
 * @deprecated -- use ServicePayload instead
 */
export interface ServiceRequest {
  [key: string]: unknown;
}

export interface ServicePayloadWithAction extends ServicePayload {
  action: RequestType;
}

export enum ErrorDisplayType {
  Banner,
  FullPage,
  Toast,
}

export enum ErrorDisplayComponent {
  SadCar = 'sad-car',
  MapPins = 'map-pins',
}

export interface ServiceError {
  code?: number;
  message?: string;
  status?: number;
  text?: string;
  displayAs?: ErrorDisplayType;
  json?: Record<string, string>
}
