import {
  KitIcon,
  KitSpinner,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import styled, { keyframes } from 'styled-components'

import { spin } from '@/common/animations'

const { iconSize, spacing } = ThemeConstants

const bounce = keyframes`
0%{
   transform: scale(0) rotate(-45deg);
}
50% {
  transform: scale(1.2) rotate(10deg);
} 
100% {
   transform: scale(1) rotate(0deg);
}
`

const fadeIn = keyframes`
 0%{
    opacity: 0
 }
 100% {
    opacity: 1;
 }
`

export interface StyledSpinnerProps {
  size?: 's' | 'm' | 'l';
  align?: 'center' | 'top';
}

const SpinnerContainer = styled.div<{ noDelay: boolean; align?: string }>`
  opacity: 0;
  animation: ${fadeIn} 1s forwards;
  animation-delay: ${({ noDelay }) => (noDelay ? '0' : '0.5s')};
  position: ${({ align }) => (align === 'center' ? 'absolute' : 'relative')};
  top: ${({ align }) => (align === 'center' ? '50%' : `${ThemeConstants.spacing.absolute.l}px`)};
  left: 50%;
  transform: translate(-50%, -50%);
`

const SuccessContainer = styled.div`
  animation: ${bounce} 0.6s forwards;
  border: 2px solid ${ThemeColors.green_50};
  border-radius: 100%;
  margin: auto;
  margin-top: ${spacing.absolute.m}px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${spacing.absolute.s}px;
`

const MiniInlineSpinner = styled.div`
  display: inline-block;
  border: 3px solid ${ThemeColors.gray_20};
  border-top: 3px solid ${ThemeColors.gray_40};
  border-right: 3px solid ${ThemeColors.gray_40};
  border-bottom: 3px solid ${ThemeColors.gray_40};
  border-radius: 50%;
  min-height: 12px;
  min-width: 12px;
  width: 12px;
  height: 12px;
  margin: auto;
  animation: ${spin} 0.6s linear infinite;
`

export const InlineSpinner = ({ className }: { className: string }) => <MiniInlineSpinner className={className} />

const StyledSpinner: FC<StyledSpinnerProps> = ({
  align = 'center',
  size = 's',
}: StyledSpinnerProps) => (
  <SpinnerContainer noDelay align={align}>
    <KitSpinner align="middle" size={size} />
  </SpinnerContainer>
)

type OwnSuccessProps = {
  message?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'SuccessProps' circularly references it... Remove this comment to see the full error message
type SuccessProps = OwnSuccessProps & typeof Success.defaultProps;

export const MinHeightSpinnerContainer = styled.div`
  padding: ${spacing.absolute.xxl}px;
`

type OwnSuccessLoaderProps = {
  loading?: boolean;
  size?: string;
  successMessage?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'SuccessLoaderProps' circularly referen... Remove this comment to see the full error message
type SuccessLoaderProps = OwnSuccessLoaderProps &
  typeof SuccessLoader.defaultProps;

// @ts-expect-error ts-migrate(7022) FIXME: 'Success' implicitly has type 'any' because it doe... Remove this comment to see the full error message
export const Success = ({ message }: SuccessProps) => (
  <>
    <SuccessContainer>
      <KitIcon
        icon="check-bold"
        fill={`${ThemeColors.green_50}`}
        size={`${iconSize.m}rem`}
      />
    </SuccessContainer>

    { message && <Message>{ message }</Message> }
  </>
)

// @ts-expect-error ts-migrate(7022) FIXME: 'SuccessLoader' implicitly has type 'any' because ... Remove this comment to see the full error message
export const SuccessLoader = ({
  loading,
  size = 'm',
  successMessage = null,
}: SuccessLoaderProps) => (
  <SpinnerContainer noDelay>
    { !successMessage && loading && <KitSpinner align="middle" size={size} /> }
    { successMessage && <Success message={successMessage} /> }
  </SpinnerContainer>
)

SuccessLoader.defaultProps = {
  loading        : null,
  size           : 's',
  successMessage : null,
}

export default StyledSpinner
