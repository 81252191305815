import {
  KitButton,
  type KitButtonProps,
  KitIcon,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import styled from 'styled-components'

const { fontSize, iconSize, spacing } = ThemeConstants

const StyledButton = styled(KitButton)`
  && {
    background-color: transparent;
    border: none;
    &:hover {
      border: none;
      outline: none;
    }
  }
  border: 0;
  font-size: ${fontSize.text_14}rem;
  padding: 0 ${spacing.absolute.xs}px;
  margin: 0 ${spacing.absolute.s}px;

  > div {
    transform: translateY(2px);
  }
`

const IconWrap = styled.div`
  display: inline-block;
  margin-right: ${spacing.absolute.xs}px;
  vertical-align: middle;
`

const getIcon = (icon: string, size?: string, fillColor?: string) => (
  <IconWrap>
    <KitIcon
      fill={fillColor ?? ThemeColors.gray_90}
      icon={icon}
      size={size ?? `${iconSize.xxs}rem`}
    />
  </IconWrap>
)

export interface IconButtonProps extends Omit<KitButtonProps, 'size'> {
  ariaLabel?: string;
  children?: React.ReactNode;
  className?: string;
  icon: string;
  id?: string;
  size?: string;
  onClick: (...args: unknown[]) => void;
  style?: {
    [key: string]: string;
  };
  qaTag?: string;
}

export const IconButton: FC<IconButtonProps> = ({
  ariaLabel,
  children,
  className,
  icon,
  id = '',
  onClick,
  qaTag,
  size,
  style,
  ...passthru
}: IconButtonProps) => (
  <StyledButton
    {...passthru}
    data-qa-id={qaTag}
    onClick={onClick}
    className={className}
    style={style}
    variant="secondary"
    id={id}
    tabIndex={0}
    aria-label={ariaLabel}
  >
    { getIcon(icon, size, style?.color) }
    { children }
  </StyledButton>
)
