import {
  KitLink,
  KitTable,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import styled from 'styled-components'

import { formatNumber, numberFormatOptions } from '@/common/lang'
import { FLEET_ENERGY_MGMT_UPDATE, hasPermission } from '@/common/permissions'
import { KitTableWrapper } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import { TableHead } from '@/components/Table'
import { type Site, type StationGroup } from '@/models/energyModel'
import { type ColumnDef } from '@/types/index'

const { spacing }             = ThemeConstants
const StyledKitTableContainer = styled(KitTableWrapper)`
  padding: 0 0 ${spacing.l}rem 0;
  table {
    table-layout: auto;
    width: fit-content;
  }

  thead {
    th {
      text-align: left;
      button {
        width: fit-content;
        padding-left: 0;
      }
    }
    .th-site {
      width: 250px;
    }
    .th-power {
      width: 100px;
    }
  }

  tbody {
    tr td {
      padding: ${spacing.absolute.sm}px;
    }
  }
`

const StationGroupTd = styled.td`
  color: ${ThemeColors.gray_50};
  &:nth-child(1) {
    padding-left: ${spacing.absolute.xl + spacing.absolute.xs}px !important;
  }
`

export interface SiteGroupSettingsTableProps {
  isLoading: boolean;
  openSiteSettings: () => void;
  site: Site;
  stationGroups: StationGroup[];
  t: TFunction;
}
const SiteGroupSettingsTable: FC<SiteGroupSettingsTableProps> = ({
  isLoading,
  openSiteSettings,
  site,
  stationGroups,
  t,
}) => {
  const labelMap = {
    none                     : t('energy_management.none'),
    power_sharing            : t('energy_management.powerSharing'),
    power_sharing_simulation : t('energy_management.powerSharingSimulation'),
  }

  const columns: ColumnDef[] = [
    {
      field : 'site_group',
      label : t('energy_management.columns.site_group'),
      align : 'left',
      props : { className: 'th-site' },
    },
    {
      field : 'power_kw',
      label : t('power_kw'),
      align : 'left',
      props : { className: 'th-power', width: '20%' },
    },
    {
      field : 'power_mgmt',
      label : t('energy_management.columns.power_management'),
      align : 'left',
      props : { width: '100px' },
    },
  ]

  return (
    <StyledKitTableContainer data-qa-id="energy-table">
      { isLoading && <StyledSpinner /> }
      { !isLoading && (
        <KitTable>
          <TableHead columns={columns} />
          <tbody>
            <tr data-qa-id="energy-table-row">
              <td>
                { site.external_link
                  ? <KitLink data-qa-id="energy-table-site-group-btn" href={site.external_link}>
                    { site.name }
                  </KitLink>
                  : site.name }
              </td>
              <td data-qa-id="energy-table-power">
                { formatNumber(site.aggregate_power, numberFormatOptions.power) }
              </td>
              <td>
                <KitLink
                  data-qa-id="energy-table-power-management-btn"
                  disabled={!hasPermission(FLEET_ENERGY_MGMT_UPDATE)}
                  onClick={openSiteSettings}
                >
                  {
                    labelMap[
                      site.power_management_type as keyof typeof labelMap
                    ]
                  }
                </KitLink>
              </td>
            </tr>
            { stationGroups && (
              <>
                { Object.values(stationGroups).map((stationGroup) => (
                  <tr key={stationGroup.name}>
                    <StationGroupTd>
                      { stationGroup.external_link ? <KitLink
                        target="_blank"
                        href={stationGroup.external_link}
                      >
                        { stationGroup.name }
                      </KitLink> : stationGroup.name }
                    </StationGroupTd>
                    <StationGroupTd>
                      { formatNumber(
                        stationGroup.aggregate_power,
                        numberFormatOptions.power,
                      ) }
                    </StationGroupTd>
                    <StationGroupTd>
                      {
                        labelMap[
                          stationGroup.power_management_type as keyof typeof labelMap
                        ]
                      }
                    </StationGroupTd>
                  </tr>
                )) }
              </>
            ) }
          </tbody>
        </KitTable>
      ) }
    </StyledKitTableContainer>
  )
}

export default SiteGroupSettingsTable
