import { KitFlexRow, KitModal, ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

const { spacing } = ThemeConstants

export const ModalFooter = styled(KitModal.Footer)`
  padding-left: 0;
  padding-right: 0;
  margin: 0 ${spacing.absolute.l}px;
  border-top: 0;
  .buttonBar {
    margin-bottom: ${ThemeConstants.spacing.absolute.s}px;
    margin-top: ${ThemeConstants.spacing.absolute.s}px;
  }
`

export const RowSpaced = styled(KitFlexRow)`
  border-top: 1px solid ${ThemeColors.gray_20};
  gap: ${spacing.absolute.m}px;
  margin-top: ${-spacing.absolute.m}px;
  padding: ${spacing.absolute.m}px 0 0 ${spacing.absolute.m}px;
  .default-value {
    padding: ${spacing.s}rem 0;
  }
`

export const SOCWrapper = styled.div``

export const SpinnerWrapper = styled.div`
  .inline-spinner {
    margin-top: ${spacing.absolute.m}px;
    margin-bottom: ${spacing.absolute.s}px;
  }
`
