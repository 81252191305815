import { type FC } from 'react'

import { type ErrorPageProps, renderLinks } from './helpers'
import { ErrorPageHeader } from './styles'
import { List, ListItemSmall } from '@/components/Styled'

const Http403: FC<ErrorPageProps> = ({ children, links, t }) => (
  <>
    <ErrorPageHeader>{ t('errors.403.title') }</ErrorPageHeader>
    <List>
      <ListItemSmall>{ links?.length && renderLinks(links, t) }</ListItemSmall>
    </List>
    { children }
  </>
)

export default Http403
