import {
  KitModalSize,
  KitToastTypeOptions,
  KitUtilData,
  useToast,
} from '@chargepoint/cp-toolkit'
import { type FC, useEffect, useReducer, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import { type ReducerAction, type ReducerPayload } from '../../pages/FleetList/reducers'
import About from './About'
import {
  type AddFleetReducerState,
  type Sections,
  getConfig,
  getDataForStep,
  submitForm,
} from './config'
import Contact from './Contact'
import Scheduling from './Scheduling'
import Summary from './Summary'
import { renderComponent } from '@/common/utils/componentHelpers'
import { omit } from '@/common/utils/data'
import { getPayload } from '@/common/utils/scheduling'
import Dialog from '@/components/Dialog'
import ErrorBoundary from '@/components/ErrorBoundary'
import Wizard, { type WizardStep } from '@/components/Wizard'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { type Depot } from '@/models/depotModel'
import { ScheduleContext, ScheduleType } from '@/models/scheduleModel'
import { type ServiceResponse } from '@/models/serviceModel'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import { getAnalyticsScheduleType } from '@/services/AnalyticsService/analyticsHelpers'
import { type ComponentConfig } from '@/types/index'
import { type Org } from '@/types/models'

const initialState: Partial<AddFleetReducerState> = {
  sections: {
    about    : {},
    contact  : {},
    schedule : {},
  },
}

export enum AddFleetActionTypes {
  DATA_LOADED = 'DATA_LOADED',
  REMOVE_SECTION = 'REMOVE_SECTION',
  RESET_STATE = 'RESET_STATE',
  UPDATE_SECTION = 'UPDATE_SECTION',
  VALIDATE_SECTION = 'VALIDATE_SECTION',
}

const addFleetReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AddFleetReducerState = initialState as AddFleetReducerState,
  action: ReducerAction<
  | {
    section?: string;
    id?: string;
    field?: string;
    results?: unknown;
    value?: unknown;
  }
  | boolean,
  AddFleetActionTypes
  >,
): AddFleetReducerState => {
  const actions = {
    VALIDATE_SECTION : (payload:ReducerPayload) => ({ ...state, fieldErrors: payload }),
    RESET_STATE      : () => ({ ...initialState, data: state.data }),
    SERVICE_ERROR    : (payload: ReducerPayload) => ({ ...state, serviceError: payload }),
    DATA_LOADED      : (payload: ReducerPayload<string>) => ({
      ...state,
      data: {
        ...state.data,
        [payload.id]: payload.results,
      },
    }),
    REMOVE_SECTION: (payload: ReducerPayload<string>) => ({
      ...state,
      sections: omit(state.sections, [payload.section]),
    }),
    UPDATE_SECTION: (payload: ReducerPayload<string | { field: string, value: unknown }[]>) => {
      const updates: Record<string, unknown> = { ...(state.sections[payload.section as keyof Sections] ?? {}) }
      if (Array.isArray(payload.fields)) {
        payload.fields.forEach(({ field, value }) => {
          if (value === 'unset') {
            delete updates[field]
          } else {
            updates[field] = value
          }
        })
      } else if (typeof payload.field === 'string') {
        updates[payload.field] = payload.value
      }
      return ({
        ...state,
        sections: {
          ...state.sections,
          [payload.section as string]: { ...updates },
        },
      })
    },

  }

  if (actions[action.type as keyof typeof actions]) {
    const mappedAction = actions[action.type as keyof typeof actions]
    const result       = mappedAction(
      action.payload as ReducerPayload<keyof Sections>,
    )
    return result as unknown as AddFleetReducerState
  }

  return state
}

const componentMap: Record<string, unknown> = {
  about    : About,
  contact  : Contact,
  schedule : Scheduling,
  summary  : Summary,
}

type AddFleetProps = {
  show: boolean;
  openWizard: () => void;
  closeWizard: () => void;
};

const modalOverrideStyles = `
  transition: all 0.1s !important;
`

const AddFleet: FC<AddFleetProps> = ({
  closeWizard,
  openWizard,
  show,
}: AddFleetProps) => {
  const [state, dispatch]                       = useReducer(
    addFleetReducer,
    initialState as AddFleetReducerState,
  )
  const [isAddingFleet, setIsAddingFleet]       = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [resetNavSteps, setResetNavSteps]       = useState(false)
  const { analyticsService }                    = useAnalyticsService()

  const { t }                 = useTranslation()
  const config                = getConfig(state, t)
  const [visited, setVisited] = useState([0])
  const [stepId, setStep]     = useState(config.steps[0].id)
  const cfg                   = config.components[stepId]

  const showConfirmationDialogOrClose = () => {
    if (isAddingFleet) {
      setShowConfirmation(true)
      setResetNavSteps(false)
      closeWizard()
    } else {
      closeWizard()
    }
  }

  const handleStep = (step: WizardStep) => {
    if (componentMap[step.id as string]) {
      setStep(step.id)
    }
    const changed = [...visited, step.stepIndex as number]
    setVisited([...new Set(changed)])
  }

  const resetAll = () => {
    setIsAddingFleet(false)
    dispatch({ type: AddFleetActionTypes.RESET_STATE })

    ReactDOM.unstable_batchedUpdates(() => {
      setStep(config.steps[0].id)
      setResetNavSteps(true)
      closeWizard()
      setShowConfirmation(false)
    })
  }

  const confirmClose = (action: string) => {
    if (action === 'cancel') {
      openWizard()
      setShowConfirmation(false)
    } else {
      resetAll()
    }
  }

  const getDisabledSteps = () => {
    const disabled: number[] = []
    config.steps.forEach((step, i) => {
      const valid = step.validate(
        state.sections[step.id as keyof Sections],
        state,
      )
      if (!valid) {
        disabled.push(i)
      }
    })
    return resetNavSteps ? config.steps.map((step, i) => i) : disabled
  }

  const handleComplete = () => new Promise((resolve, reject) => {
    const addFleet = async (request: Sections) => {
      const response = await submitForm(request)
      if (response && !response.error) {
        useToast({
          t,
          toastType : KitToastTypeOptions.SUCCESS,
          message   : t('toast_messages.add_success', { item: request.about.name }),
        })

        analyticsService.trackEvent(analyticEvents.fleetAdded, {
          scheduleType: getAnalyticsScheduleType(
            request.schedule as Record<string, unknown>,
          ),
        })

        resolve(undefined)
        resetAll()
      } else {
        analyticsService.trackEvent(analyticEvents.fleetAdded, { failed: true })
        useToast({
          t,
          toastType : KitToastTypeOptions.ERROR,
          message   : t('toast_messages.add_error', { item: request.about.name }),
        })
        reject(response.error)
      }
    }

    const { data, sections } = state

    const schedule = (() => {
      if (sections.schedule) {
        return getPayload(sections.schedule, sections.schedule.scheduleType, ScheduleContext.FLEET)
      }
      return null
    })()

    const request = omit(
      {
        ...sections,
        about: {
          ...sections.about,
          org_external_id: data.about.org_external_id,
        },
        ...(schedule ? { schedule } : {}),
      },
      (val) => KitUtilData.isEmpty(val),
    )
    addFleet(request as unknown as Sections)
  })

  const onChange = (
    section: string,
    fieldName: string,
    fieldValue: unknown,
  ) => {
    if (section === 'contact' && fieldValue === -1) {
      dispatch({
        type    : AddFleetActionTypes.REMOVE_SECTION,
        payload : { section },
      })
    } else if (section === 'schedule' && fieldName === 'scheduleType') {
      const fields = [{ field: fieldName, value: fieldValue }].concat(fieldValue === ScheduleType.BY_TIME ? [
        { field: 'arrival_time_downtime_secs', value: 0 },
        { field: 'departure_time_downtime_secs', value: 0 },
      ] : [
        { field: 'arrival_time', value: 'unset' },
        { field: 'departure_time', value: 'unset' },
        { field: 'arrival_time_downtime_secs', value: 'unset' },
        { field: 'departure_time_downtime_secs', value: 'unset' },
      ])

      dispatch({
        type    : AddFleetActionTypes.UPDATE_SECTION,
        payload : {
          section,
          fields,
        },
      })
    } else {
      dispatch({
        type    : AddFleetActionTypes.UPDATE_SECTION,
        payload : {
          section,
          field : fieldName,
          value : fieldValue,
        },
      })
    }

    if (!isAddingFleet) {
      setIsAddingFleet(true)
      setResetNavSteps(false)
    }
  }

  const parseResults = (
    stepId: string,
    response: [ServiceResponse<Depot[]>, Org] | ServiceResponse<unknown> | void,
  ) => {
    if (stepId === 'about') {
      const [depots, org] = response as [ServiceResponse<Depot[]>, Org]
      return {
        depots          : depots.results,
        org_name        : org.name,
        org_external_id : org.external_id,
      }
    }

    return (response as ServiceResponse<unknown>).results ?? response
  }

  useEffect(() => {
    async function getData() {
      try {
        const response = await getDataForStep(stepId)
        if (Array.isArray(response) ? response : response?.results) {
          const results = parseResults(
            stepId,
            response as ServiceResponse<unknown>,
          )
          dispatch({
            type    : AddFleetActionTypes.DATA_LOADED,
            payload : {
              id: stepId,
              results,
            },
          })
        }
      } catch (err) {
        console.warn('failed getting data for step:', err)
      }
    }

    getData()
  }, [stepId])

  return (
    <>
      <Dialog
        title={t('confirm_cancel_fleet_title')}
        t={t}
        type="confirm"
        message={t('confirm_cancel_fleet_message')}
        show={showConfirmation}
        onAction={() => confirmClose('confirm')}
        onClose={() => confirmClose('cancel')}
        cancelText={t('confirm_cancel_keep_editing_btn')}
        confirmText={t('confirm_cancel_discard_btn')}
      />

      <Wizard
        modalStyles={modalOverrideStyles}
        modalSize={KitModalSize.md}
        show={show}
        title={t('fleets.add_fleet')}
        config={config}
        onComplete={handleComplete}
        onStep={handleStep}
        onHide={() => {
          showConfirmationDialogOrClose()
        }}
        t={t}
        disabledSteps={getDisabledSteps()}
        resetNav={resetNavSteps}
      >
        <ErrorBoundary>
          { renderComponent<AddFleetReducerState, ComponentConfig>(
            cfg,
            componentMap,
            {
              otherProps: {
                onChange,
                t,
                getDisabledSteps,
                state,
              },
              state,
            },
          ) }
        </ErrorBoundary>
      </Wizard>
    </>
  )
}

export default AddFleet
