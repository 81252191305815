import i18n from '../../i18n'
import {
  ErrorDisplayComponent,
  ErrorDisplayType,
} from '../../models/serviceModel'
import Component from './Component'
import RouteError from '@/common/errors/RouteError'
import DepotService from '@/services/DepotService'

const depotListLoader = async () => {
  const { error, results } = await DepotService.getDepots()

  if (error) {
    throw new RouteError({
      message          : i18n.t('errors.list_request_error', { itemType: i18n.t('depot_plural') }),
      code             : error.status,
      displayComponent : ErrorDisplayComponent.SadCar,
      displayAs        : ErrorDisplayType.FullPage,
    })
  } else {
    return results
  }
}

export { Component, depotListLoader }

export default Component
