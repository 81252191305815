import { KitTextSpacing } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'

import { ReadOnlyFieldContainer } from './index.styles'
import { getReadOnlyFields } from '@/common/utils/scheduling'
import type { Schedule, ScheduleContext, ScheduleType } from '@/models/scheduleModel'

export interface ReadOnlyField {
  detail?: string;
  label: string;
  value: string;
}

export interface ReadOnlyScheduleProps {
  schedule: Schedule;
  scheduleContext: ScheduleContext;
  scheduleType: ScheduleType;
  t: TFunction;
  meta?: { showContext: boolean }
}

const ReadOnlyFormField = ({ detail, label, value }: ReadOnlyField) => (
  <ReadOnlyFieldContainer>
    <KitTextSpacing.Group>
      <KitTextSpacing.PrimaryText>{ label }</KitTextSpacing.PrimaryText>
      <KitTextSpacing.SecondaryText className="value">{ value }</KitTextSpacing.SecondaryText>
      { detail && <KitTextSpacing.SecondaryText>{ detail }</KitTextSpacing.SecondaryText> }
    </KitTextSpacing.Group>
  </ReadOnlyFieldContainer>
)

const ReadOnlySchedule = ({ meta, schedule, scheduleContext, scheduleType, t }: ReadOnlyScheduleProps) => {
  const fields = getReadOnlyFields(schedule, scheduleType, scheduleContext, t, meta) as ReadOnlyField[]

  return (
    <>
      { fields?.map((f) => (
        <ReadOnlyFormField key={f.value} {...f} />
      )) }
    </>
  )
}

export default ReadOnlySchedule
