import {
  KitButton,
  KitButtonBar,
  KitButtonBarType,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import FocusTrap from 'focus-trap-react'
import { type TFunction } from 'i18next'
import { type FC, type MouseEvent } from 'react'

import styled from 'styled-components'

import { hasPermission } from '@/common/permissions'
import StyledFleetCard from '@/components/StyledFleetCard'

const StyledKitButtonBar = styled(KitButtonBar)`
  padding-top: ${ThemeConstants.spacing.absolute.m}px;
  button {
    min-width: 96px;
  }
`

const CustomCard = styled(StyledFleetCard)`
  button.link {
    min-width: auto;
    width: fit-content;
    padding: ${ThemeConstants.spacing.xxs}rem;
  }
  &.two-column {
    max-height: 400px;
    overflow-y: auto;
    box-shadow: ${({ theme }) => theme.panel.shadow};
    flex: 2;
    max-width: 722px;
    width: 722px;

    @media all and (max-width: ${ThemeConstants.breakpoints.sm}px) {
      max-width: 350px;
      width: auto;
    }
  }
`

type EditPanelProps = {
  className?: string;
  enableFocusTrap: boolean,
  disableSubmit?: boolean;
  id?: string;
  mode: string;
  readOnly?: boolean;
  title: string;
  children: React.ReactNode;
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onToggle: () => void;
  t: TFunction;
  permissions?: string;
};

const EditPanel: FC<EditPanelProps> = ({
  children,
  className,
  disableSubmit,
  enableFocusTrap = false,
  id,
  isLoading,
  mode,
  onCancel,
  onSubmit,
  onToggle,
  permissions = '',
  readOnly,
  t,
  title,
}) => {
  const isEditMode        = mode === 'edit'
  const isFocusTrapActive = enableFocusTrap && isEditMode
  const handleCancel      = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onCancel()
  }

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onSubmit()
  }

  const handleToggle = (e?: MouseEvent) => {
    e?.preventDefault()
    e?.stopPropagation()
    onToggle()
  }

  return (
    <CustomCard data-qa-id={`${id}_panel`} className={className}>
      <FocusTrap active={isFocusTrapActive}><div>
        <StyledFleetCard.Header>
          <h3>{ title }</h3>
          { !readOnly && !isEditMode && hasPermission(permissions) && (
            <KitButton
              variant="link"
              data-qa-id={`${id}_edit`}
              aria-label={t('aria_edit_panel_link', { title })}
              onClick={handleToggle}
            >
              { t('edit') }
            </KitButton>
          ) }
        </StyledFleetCard.Header>

        <StyledFleetCard.Body>

          { children }
          { isEditMode && !isLoading && (
            <StyledKitButtonBar
              type={KitButtonBarType.default}
              primary={
                <KitButton
                  data-qa-id={`${id}_save`}
                  disabled={disableSubmit}
                  variant="primary"
                  onClick={handleSubmit}
                >
                  { t('btn_save') }
                </KitButton>
              }
              secondary={
                <KitButton
                  data-qa-id={`${id}_cancel`}
                  variant="secondary"
                  onClick={handleCancel}
                >
                  { t('btn_cancel') }
                </KitButton>
              }
            />

          ) }
        </StyledFleetCard.Body>
      </div>
      </FocusTrap>
    </CustomCard>
  )
}

EditPanel.defaultProps = { readOnly: false }

export default EditPanel
