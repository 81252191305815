import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  0% {
      opacity: 0
  }
  100% {
    opacity: 1
  }
`

export const fadeOut = keyframes`
  0% {
      opacity: 1
  }
  100% {
    opacity: 0
  }
`

export const bounceIn = keyframes`
  0% {
      transform: scale(0.5);
  }
  6% {
    transform: scale(1.2));
  }  
  100% {
    transform: scale(1);
  }
`

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
