import {
  KitToastTypeOptions,
  KitUtilData,
  useToast,
} from '@chargepoint/cp-toolkit'
import { type FC, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddVehicleServiceError from './AddVehicleServiceError'
import {
  AddVehicleFlow,
  LocationCategory,
  type UnknownVehicleSession,
  addVehicle,
  getConfig,
  getDataForStep,
  mergeVehicle,
} from './config'
import addVehicleReducer, { WizardActions } from './reducer'
import Charging from './screens/Charging'
import ExistingVehicle from './screens/ExistingVehicle'
import GettingStarted from './screens/GettingStarted'
import Identification from './screens/Identification'
import MergeVehicleConfirm from './screens/MergeVehicleConfirm'
import Summary from './screens/Summary'
import VehicleConfirm from './screens/Vehicle/UnknownVehicleSelector'
import Vehicle from './screens/Vehicle/VehicleFields'
import { renderComponent } from '@/common/utils/componentHelpers'
import { hasValue } from '@/common/utils/validations'
import Dialog from '@/components/Dialog'
import ErrorBoundary from '@/components/ErrorBoundary'
import Wizard, { type WizardStep } from '@/components/Wizard'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { type DepotDetailsRow } from '@/models/depotModel'
import { type BaseServiceResponse, type ServiceResponse } from '@/models/serviceModel'
import { type TelematicsAccount } from '@/models/telematicsModel'
import {
  type AddVehicleRequest,
  type VehicleMake,
  type VehicleModel,
} from '@/models/vehicleModel'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import { getAnalyticsScheduleType } from '@/services/AnalyticsService/analyticsHelpers'
import { type ComponentMap, type DynamicObject } from '@/types/index'

const { getProp, isEmpty } = KitUtilData

const componentMap: ComponentMap = {
  getting_started       : GettingStarted,
  charging              : Charging,
  vehicle               : Vehicle,
  vehicle_confirm       : VehicleConfirm,
  existing_vehicle      : ExistingVehicle,
  merge_vehicle_confirm : MergeVehicleConfirm,
  identification        : Identification,
  summary               : Summary,
}

export interface AddVehicleProps {
  onHide: () => void;
  show: boolean;
  fleetExternalID?: string;
  unknownVehicleProps?: Partial<DepotDetailsRow>;
  unknownVehicleSession?: UnknownVehicleSession;
  addUnknownVehicleFlowType?: string;
  fromHistoricalSession?: boolean;
  closeWizard: () => void;
  openWizard: () => void;
  externalAction?: (actionName: string, args: unknown) => void;
  onSuccess?: () => void;
}

const getInitialState = ({
  addUnknownVehicleFlowType,
  fleetExternalID,
  fromHistoricalSession,
  unknownVehicleProps,
  unknownVehicleSession,
}: {
  fleetExternalID?: string;
  fromHistoricalSession?: boolean;
  unknownVehicleProps?: DepotDetailsRow;
  unknownVehicleSession?: UnknownVehicleSession;
  addUnknownVehicleFlowType?: string;
}) => ({
  sections: {
    getting_started  : { instructionsType: LocationCategory.OnPremise },
    existing_vehicle : {},

    charging       : { rf_id: unknownVehicleSession?.rf_id },
    vehicle        : { fleet_name: fleetExternalID },
    identification : unknownVehicleSession
      ? {
        mac_address : unknownVehicleSession?.vehicle_mac_address,
        rf_id       : unknownVehicleSession?.rf_id,
      }
      : {},
  },
  data: {
    getting_started : {},
    charging        : {},
    vehicle         : {},
    identification  : {},
    unknownVehicle  : unknownVehicleProps,
    unknownVehicleSession,
  },
  context: {
    fromHistoricalSession,
    vehicleAddFlow : addUnknownVehicleFlowType,
    isFleetContext : hasValue(fleetExternalID),
  },
  wizard: {
    reset : false,
    step  : {},
  },
})

const AddVehicle: FC<AddVehicleProps> = ({
  addUnknownVehicleFlowType,
  closeWizard,
  externalAction,
  fleetExternalID,
  fromHistoricalSession,
  onHide,
  onSuccess,
  openWizard,
  show,
  unknownVehicleProps,
  unknownVehicleSession,
}) => {
  const isUnknownVehicle = unknownVehicleProps && !isEmpty(unknownVehicleProps)
  const initialStepId    = (() => {
    if (isUnknownVehicle) {
      if (addUnknownVehicleFlowType === AddVehicleFlow.ExistingVehicle) {
        return AddVehicleFlow.ExistingVehicle
      }

      return fromHistoricalSession
        ? AddVehicleFlow.Vehicle
        : AddVehicleFlow.Charging
    }
    return 'getting_started'
  })()

  const initialState                            = getInitialState({
    fleetExternalID,
    unknownVehicleProps,
    unknownVehicleSession,
    addUnknownVehicleFlowType,
    fromHistoricalSession,
  })
  const { t }                                   = useTranslation()
  const [state, dispatch]                       = useReducer(addVehicleReducer, initialState)
  const [step, setStep]                         = useState<Partial<WizardStep>>({ id: initialStepId })
  const [isAddingVehicle, setIsAddingVehicle]   = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const config                                  = getConfig(state, t, dispatch)
  const existingVehicleFlow                     = addUnknownVehicleFlowType === AddVehicleFlow.ExistingVehicle
  const { serviceError }                        = state
  const { analyticsService }                    = useAnalyticsService()

  const showConfirmationDialogOrClose = () => {
    if (isAddingVehicle) {
      closeWizard()
      setShowConfirmation(true)
    } else {
      closeWizard()
    }
  }

  const resetAll = () => {
    onHide()
    setIsAddingVehicle(false)
    setShowConfirmation(false)
    setStep({ id: initialStepId })
    dispatch({
      type    : WizardActions.RESET_STATE,
      payload : {
        ...initialState,
        data: {
          ...state.data,
          unknownVehicleSession : undefined,
          unknownVehicle        : undefined,
        },
      },
    })
  }

  const confirmClose = (action: string) => {
    if (action !== Dialog.CANCEL) {
      openWizard()
      setShowConfirmation(false)
    } else {
      resetAll()
    }
  }

  const getDisabledSteps = () => {
    const disabled: number[] = []
    let isValid              = false
    config.steps.forEach((stepItem, i) => {
      if (stepItem.steps) {
        const currentStep = stepItem.steps.find((s) => s.id === step.id)
        isValid           = currentStep?.validate(state.sections[currentStep.id])
        if (!isValid) {
          disabled.push(i)
        }
      } else if (!stepItem.validate(state.sections[stepItem.id])) {
        disabled.push(i)
      }
    })

    return disabled
  }

  // dynamic component
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'unknown' cannot be used as an index type.
  const cfg = config.components[step.id]

  const handleStep = (step: WizardStep) => {
    if (componentMap[step.id]) {
      setStep(step)
      dispatch({ type: WizardActions.STEP, payload: step })
    }
  }

  // updates a single field
  function onChange(section: string, fieldName: string, fieldValue: unknown) {
    if (!section) return
    let payload = {
      section,
      field : fieldName,
      value : fieldValue,
    }

    if (typeof fieldName === 'object') {
      payload = {
        section,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ section: any; results: any; }' is not assi... Remove this comment to see the full error message
        results: fieldName,
      }
    }

    if (!isAddingVehicle) {
      setIsAddingVehicle(true)
    }

    dispatch({
      type: WizardActions.UPDATE_SECTION,
      payload,
    })
  }

  function updateSectionState(
    section: string,
    payload: Record<string, unknown>,
  ) {
    dispatch({
      type    : WizardActions.UPDATE_SECTION,
      payload : {
        section,
        results: payload,
      },
    })
  }

  // merges in new section object with existing
  function updateSectionData(section: string, results: unknown) {
    if (!section) return
    dispatch({
      type    : WizardActions.UPDATE_DATA,
      payload : {
        section,
        results,
      },
    })
  }

  function resetFields(section: string, fields: Record<string, null>) {
    dispatch({
      type    : WizardActions.RESET_FIELDS,
      payload : { section, fields },
    })
  }

  const buildIDTypes = (identification: Record<string, unknown>) => {
    const result = Object.keys(identification).reduce((acc, key) => {
      if (['name', 'using_telematics', 'telematics_account_id'].includes(key)) {
        return acc
      }
      // don't send null values
      if (hasValue(identification[key])) {
        acc.push({
          id_type  : key,
          id_value : identification[key],
        })
      }
      return acc
    }, [])
    return result
  }

  const buildAddVehicleRequest = (): AddVehicleRequest => {
    const { identification, vehicle } = state.sections
    const request: AddVehicleRequest  = {
      name                  : identification.name,
      model_year_id         : vehicle.model_year_id,
      fleet_external_id     : vehicle.fleet_name,
      telematics_account_id : identification.telematics_account_id,
      identification        : buildIDTypes(identification),
      schedule              : {
        // for now, this is hardcoded to true
        inherit_from_fleet: true,
      },
    }

    if (isUnknownVehicle) {
      request.unknown_vehicle_external_id = unknownVehicleProps?.vehicle_external_id
    }

    return request
  }

  const getVehicleAnalyticsProps = (addVehicleRequest: AddVehicleRequest) => {
    const identifiedBy = (() => {
      const idItem = addVehicleRequest.identification.find((idObj) => ['rf_id', 'vehicle_mac_address'].includes(idObj.id_type))
      return idItem ? idItem.id_type : 'none'
    })()
    const { vehicle } = state.sections

    const vehicleMake = (
      getProp(state.data, 'vehicle.makes', []) as VehicleMake[]
    ).find((make: { id: number }) => make.id === vehicle.model_make)?.name

    const vehicleModel = (
      getProp(state.data, 'vehicle.models', []) as VehicleModel[]
    ).find((model) => model.id === vehicle.model_name)?.name

    const telematicsProvider = addVehicleRequest.telematics_account_id
      ? (() => {
        const accounts = getProp(
          state.data,
          'identification.telematics_accounts',
          [],
        ) as TelematicsAccount[]
        const match    = accounts.find(
          (account) => account.id === addVehicleRequest.telematics_account_id,
        )
        return match ? match.provider : ''
      })()
      : 'none'
    const scheduleType = getAnalyticsScheduleType(addVehicleRequest.schedule)

    return {
      identifiedBy,
      scheduleType,
      telematicsProvider,
      vehicleMake,
      vehicleModel,
    }
  }

  const handleComplete = () => new Promise((resolve, reject) => {
    if (existingVehicleFlow) {
      const {
        data: { unknownVehicleSession },
        sections: { existing_vehicle: { selectedVehicle } },
      } = state
      const mergeVehicleRequest = {
        source_vehicle_external_id : unknownVehicleSession.vehicle_id,
        target_vehicle_external_id : selectedVehicle.external_id,
      }
      mergeVehicle(mergeVehicleRequest, (response: BaseServiceResponse) => {
        if (response?.error) {
          useToast({
            t,
            toastType : KitToastTypeOptions.ERROR,
            message   : t('toast_messages.update_error', { item: t('vehicle', { count: 1 }).toLowerCase() }),
          })
          // TODO, handle error
          resolve({ message: 'OK' })
        } else {
          useToast({
            t,
            toastType : KitToastTypeOptions.SUCCESS,
            message   : t('toast_messages.update_success', { item: t('vehicle', { count: 1 }).toLowerCase() }),
          })

          resetAll()
          resolve({ message: 'OK' })
          if (onSuccess) {
            onSuccess()
          }
        }
      })
    } else {
      addVehicle(
        buildAddVehicleRequest(),
        (response: BaseServiceResponse, request) => {
          if (!response.error) {
            dispatch({
              type    : WizardActions.RESET_STATE,
              payload : initialState,
            })
            useToast({
              t,
              toastType : KitToastTypeOptions.SUCCESS,
              message   : t('toast_messages.add_success', { item: t('vehicle', { count: 1 }).toLowerCase() }),
            })

            analyticsService.trackEvent(
              analyticEvents.vehicleAdded,
              getVehicleAnalyticsProps(request),
            )

            resetAll()
            resolve({ message: 'OK' })
            if (onSuccess) {
              onSuccess()
            }
          } else {
            analyticsService.trackEvent(analyticEvents.vehicleAdded, { failed: true, request })

            useToast({
              t,
              toastType : KitToastTypeOptions.ERROR,
              message   : t('toast_messages.add_error', { item: t('vehicle', { count: 1 }).toLowerCase() }),
            })
            const msg = response.error.status === '403'
              ? 'token is invalid or expired'
              : t('errors.add_vehicle_common_error')
            // 403 errors really should not be occurring here so hopefully both
            // these error messages can go away once we can diagnose issues that are occurring.
            dispatch({
              type    : WizardActions.SERVICE_ERROR,
              payload : {
                message : msg,
                error   : response.error,
              },
            })
            reject({ message: 'error trying to add vehicle' })
          }
        },
      )
    }
  })

  useEffect(() => {
    const resultsMapper: DynamicObject<unknown> = {
      getting_started: (response: ServiceResponse<unknown>) => {
        const { results: fleets } = response
        return { id: 'getting_started', results: { fleets } }
      },
      vehicle: (response: ServiceResponse<unknown>[]) => {
        const [fleetsResponse, makesResponse] = response
        const { results: fleets }             = fleetsResponse
        const { results: makes }              = makesResponse as ServiceResponse<
        VehicleMake[]
        >
        return { id: 'vehicle', results: { fleets, makes } }
      },
      identification: (response: ServiceResponse<unknown[]>) => ({
        id      : 'identification',
        results : { telematics_accounts: response.results },
      }),
    }
    async function getData() {
      dispatch({ type: WizardActions.IS_LOADING })
      const response = (await getDataForStep(
        step.id as string,
      )) as ServiceResponse<unknown>
      let payload    = {}
      if (resultsMapper[step.id]) {
        payload = resultsMapper[step.id](response)
      } else if (response?.results) {
        payload = {
          id      : step.id,
          results : response?.results,
        }
      }

      if (!isEmpty(payload)) {
        dispatch({
          type: WizardActions.DATA_LOADED,
          payload,
        })
      }
    }

    getData()
  }, [show, step.id])

  useEffect(() => {
    if (unknownVehicleProps && show) {
      dispatch({
        type    : WizardActions.RESET_STATE,
        payload : getInitialState({
          fleetExternalID,
          fromHistoricalSession,
          unknownVehicleProps,
          unknownVehicleSession,
          addUnknownVehicleFlowType,
        }),
      })

      setStep({ id: initialStepId })
    }
  }, [unknownVehicleProps, unknownVehicleSession, initialStepId])

  return (
    <>
      <Dialog
        title={t('confirm_cancel_vehicle_title')}
        t={t}
        type="confirm"
        message={t('confirm_cancel_vehicle_message')}
        show={showConfirmation}
        onAction={() => confirmClose('cancel')}
        onClose={() => confirmClose('confirm')}
        cancelText={t('confirm_cancel_keep_editing_btn')}
        confirmText={t('confirm_cancel_discard_btn')}
      />

      <Wizard
        modalStyles={`
         margin: auto;
         max-width: 1100px !important;
         min-height: calc(50vh);
         max-height: calc(85vh) !important;
      `}
        modalSize="full"
        show={show}
        title={config.title}
        config={config}
        disabledSteps={getDisabledSteps()}
        onComplete={handleComplete}
        onStep={handleStep}
        resetNav={state.wizard.reset}
        onHide={() => {
          showConfirmationDialogOrClose()
        }}
        t={t}
      >
        <ErrorBoundary key={`${step.id}`}>
          { serviceError && (
            <AddVehicleServiceError message={serviceError.message} />
          ) }
          { !serviceError
            && renderComponent(cfg, componentMap, {
              otherProps: {
                dispatch,
                t,
                onChange,
                resetFields,
                updateSectionData,
                updateSectionState,
                state,
                sections : state.sections,
                values   : state.sections[step.id],
                unknownVehicleProps,
                externalAction,
              },
            }) }
        </ErrorBoundary>
      </Wizard>
    </>
  )
}

export default AddVehicle
