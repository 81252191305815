import { KitUtilFormat } from '@chargepoint/cp-toolkit'
import { parseISO } from 'date-fns'
import { type TFunction } from 'i18next'

import {
  formatNumber,
  formatPercent,
  getLocaleCode,
  numberFormatOptions,
} from '@/common/lang'
import { cleanKey, numericSort } from '@/common/utils/data'
import { ChartColors, Palette } from '@/components/Charting/common/constants'
import { type TimeSeriesData, type TimeSeriesResponse } from '@/models/chartModel'
import { type DepotDetailsRow } from '@/models/depotModel'
import { type ActiveChargingSession, type ChargingHistory } from '@/models/vehicleModel'

export const prepTimeStreamData = (
  response: TimeSeriesResponse,
  detailRow: (ActiveChargingSession & DepotDetailsRow) | ChargingHistory,
  isHistorical = false,
): { results: TimeSeriesData[]; error?: unknown } => {
  if (response.error) {
    return { results: [], error: response.error }
  }

  if (!response || !response?.length) {
    return { results: [] }
  }

  const locale                           = getLocaleCode()
  const labelMap: Record<string, string> = {}
  const timeStamps                       = []

  const goalSOCTimeStamp = parseISO(
    detailRow?.charge_completion_time,
  ).getTime()
  const pullOutTimeStamp = parseISO(
    (detailRow as DepotDetailsRow)?.pull_out as string,
  ).getTime()

  const startSessionTime = parseISO(response?.[0].time).getTime()
  const results          = response.map(({ data, time }) => {
    const ts      = parseISO(time).getTime()
    const elapsed = KitUtilFormat.formatDurationFromSeconds(
      (ts - startSessionTime) / 1000,
      locale,
    )

    const remapped: Record<string, unknown> = { timestamp: ts }

    data.forEach((item) => {
      const dataKey     = cleanKey(item.name)
      labelMap[dataKey] = item.name
      remapped[dataKey] = item.value
    })

    if (!isHistorical) {
      const isPlanned = !!data.find((d) => d.name === 'planned_setpoint')
      // only show elapsed time for power that has already been delivered
      if (!isPlanned) {
        remapped.elapsed = elapsed
      }
    }

    return remapped
  })

  // Currently, we do not store pull out or goal data for historical sessions, so there is no reason to process it here
  if (!isHistorical) {
    if (
      goalSOCTimeStamp
      > (results[results.length - 1] as TimeSeriesData).timestamp
    ) {
      timeStamps.push({ timestamp: goalSOCTimeStamp })
    }

    if (
      pullOutTimeStamp
      > (results[results.length - 1] as TimeSeriesData).timestamp
    ) {
      timeStamps.push({ timestamp: pullOutTimeStamp })
    }
  }

  return {
    results: results
      .concat(timeStamps)
      .sort(numericSort('timestamp')) as TimeSeriesData[],
  }
}

// TODO: Normalize DDR statuses - there are mix of normalized and non-normalized status codes being
// passed around in various places in the app.
// --
// Ideally, the backend would pass already normalized
// status codes instead of strings that they do currently
// --
// In the meantime - if the frontend continues to need to normalize them,
// it should be done in the same place -- so statuses can be evaluated the same every place in the app
// This would be a good foundation sprint task.
export function getChargingStatusFillColor(
  chargingStatus: string,
  isHistorical = false,
) {
  if (isHistorical) {
    return ChartColors.lightGray
  }
  return (
    {
      ready         : ChartColors.lightGreen,
      charging      : ChartColors.lightBlue,
      station_fault : ChartColors.lightRed,
      vehicle_fault : ChartColors.lightRed,
    }[chargingStatus] ?? Palette.power
  )
}

export function getChargingStatusStrokeColor(
  chargingStatus: string,
  isHistorical = false,
) {
  if (isHistorical) {
    return ChartColors.gray
  }
  return (
    {
      ready         : ChartColors.green,
      charging      : ChartColors.blue,
      station_fault : ChartColors.red,
      vehicle_fault : ChartColors.red,
    }[chargingStatus] ?? Palette.power
  )
}

export function tickFormatter(value: unknown): string {
  return formatNumber(value as number, numberFormatOptions.power) as string
}

export const tooltipItemFormatter = (
  key: string,
  value: number,
  t: TFunction,
) => {
  if (
    [
      'power',
      'planned_setpoint',
      'planned_soc',
      'setpoint',
      'setpoint_simulation',
    ].includes(key)
  ) {
    return t('charting.tooltip_value', {
      value : formatNumber(value, numberFormatOptions.power),
      units : t('units.kilowatt.short'),
    })
  } if (key === 'energy') {
    return t('charting.tooltip_value', {
      value : formatNumber(value, numberFormatOptions.power),
      units : t('units.kilowatt_hour.short'),
    })
  } if (key === 'elapsed') {
    return value
  }

  return formatPercent(value)
}
