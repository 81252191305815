import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'

import VehicleSettingsAboutPanel from './VehicleSettingsAboutPanel'
import VehicleSettingsIdentificationPanel from './VehicleSettingsIdentificationPanel'
import VehicleSettingsPreconditioningPanel from './VehicleSettingsPreconditioningPanel'
import VehicleSettingsSchedulePanel from './VehicleSettingsSchedulePanel'
import { has } from '@/common/utils/data'
import Page from '@/components/Page'
import { SettingsCardContainer } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import {
  type RefreshDataFunc,
  type VehicleSchedule,
  type VehicleSettings,
  type VehicleSettingsAbout,
} from '@/models/vehicleModel'
import vehicleStore from '@/pages/VehiclePropSheet/vehicleStore'
import FeatureService, { Features } from '@/services/FeatureService'
import VehicleService from '@/services/VehicleService'
import fleetStore from '@/store/fleetStore'

const VehicleSettingsContainer = (): JSX.Element => {
  const [isLoaded, setIsLoaded]               = useState(false)
  const [vehicleSettings, setVehicleSettings] = useState<VehicleSettings>()
  const { t }                                 = useTranslation()
  const vehicleExternalID                     = vehicleStore.id

  async function fetchVehicleSettings() {
    const response = await VehicleService.getVehicleSettings(
      vehicleStore.vehicle?.external_id as string,
    )
    ReactDOM.unstable_batchedUpdates(() => {
      setIsLoaded(true)
      setVehicleSettings(response)
    })
  }

  const refreshData: RefreshDataFunc = (settingName, updates, props) => {
    if (settingName && has(vehicleSettings, settingName)) {
      const key            = settingName as keyof VehicleSettings
      const existingValues = (vehicleSettings as VehicleSettings)[key]
      setVehicleSettings({
        ...vehicleSettings,
        [key]: {
          ...existingValues,
          ...updates,
        },
      } as VehicleSettings)
    }
    fetchVehicleSettings()

    if (props?.noRefresh !== true) {
      // refresh vehicle data --
      vehicleStore.reload()
      fleetStore.refresh()
    }
  }

  useEffect(() => {
    if (!isLoaded && vehicleStore.initialized) {
      fetchVehicleSettings()
    }
  }, [vehicleStore.initialized, isLoaded])

  return (
    <Page
      title={t('page_titles.vehicle_settings', { name: vehicleStore.vehicleName })}
    >
      <SettingsCardContainer>
        { !isLoaded && <StyledSpinner /> }
        { isLoaded && vehicleExternalID && (
          <>
            <VehicleSettingsAboutPanel
              id={vehicleExternalID}
              {...(vehicleSettings?.about as Omit<VehicleSettingsAbout, 'id'>)}
              refreshData={refreshData}
              t={t}
            />

            <VehicleSettingsIdentificationPanel
              id={vehicleExternalID}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              data={vehicleSettings.identification}
              refreshData={refreshData}
              t={t}
            />

            <VehicleSettingsSchedulePanel
              fleetId={vehicleSettings?.about.fleet_external_id as unknown as string}
              vehicleId={vehicleExternalID}
              vehicleName={vehicleSettings?.about.name as string}
              schedule={vehicleSettings?.schedule as VehicleSchedule}
              refreshData={refreshData}
              t={t}
            />
            { FeatureService.isEnabled(Features.vehiclePreconditioningEnabled)
              && <VehicleSettingsPreconditioningPanel id={vehicleExternalID} />
            }
          </>
        ) }
      </SettingsCardContainer>
    </Page>
  )
}

export default observer(VehicleSettingsContainer)
