// TODO: This should be updated after any backend api changes

export enum ScheduleType {
  BY_TIME = 'BY_TIME',
  ASAP = 'ASAP',
}

export enum ScheduleContext {
  FLEET = 'fleet',
  VEHICLE = 'vehicle',
}

export interface ScheduleResponse {
  content: Schedule
  schedule_type: ScheduleType;
  inherit_from_fleet?: boolean;
}

export interface FleetScheduleResponse extends ScheduleResponse {
  fleet_external_id: string;
}

export interface VehicleScheduleResponse extends ScheduleResponse {
  vehicle_external_id: string;
}

export interface Schedule {
  arrival_time?: string;
  departure_time?: string;
  arrival_time_downtime_secs?: number;
  departure_time_downtime_secs?: number;
  required_soc?: number;
}

export interface SheetUploadValidationError {
  status?: number;
  text?: string;
}

export interface ParsedSheetUploadValidationError {
  error: string;
  details: SheetValidationError;
}

export interface SheetUploadResponse {
  error: SheetUploadValidationError;
}

export interface SheetValidationError {
  row: string;
  fields: { field: string | null; errors: string[] }[];
}
