import {
  KitFlexRow,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, useEffect, useState } from 'react'

import styled from 'styled-components'

import { VehicleIdTypes } from '@/common/constants'
import { hasValue } from '@/common/utils/validations'
import { type WizardScreenProps } from '@/components/AddVehicleWizard/config'
import {
  List,
  ListItem,
  SubHeading,
  SubHeadingBold,
} from '@/components/Styled'
import StyledFleetCard from '@/components/StyledFleetCard'
import ExistingVehicles from '@/components/Vehicle/ExistingVehicles'
import { type Vehicle } from '@/models/vehicleModel'
import { type Field, type WizardInputChangeFunc } from '@/types/index'

const { fontSize, fontWeight } = ThemeConstants

const Container     = styled.div``
const DetailsColumn = styled.div`
  flex: 0.25;
  padding-top: ${ThemeConstants.spacing.absolute.m}px;
`

const ListItemStyled = styled(ListItem)`
  padding-left: 0;
`

const StyledLabel = styled(StyledFleetCard.Label)`
  font-size: ${fontSize.text_14}rem;
  color: ${ThemeColors.gray_50};
`

const StyledValue = styled(StyledFleetCard.Value)`
  font-weight: ${fontWeight.bold};
  color: ${ThemeColors.gray_70};
`

const getValue = (key: string, value: unknown, t: TFunction) => {
  if (key === 'vehicle_mac_address') {
    return t('fields.vehicle_mac_id_label', { value })
  }
  return value
}

const VehicleDetails = ({ t, vehicle }: { vehicle: Vehicle; t: TFunction }) => {
  const displayFields: string[] = [
    'depot_name',
    'port_name',
    'vehicle_mac_address',
  ]
  const labels                  = {
    depot_name          : t('depot'),
    station_name        : t('station_name'),
    port_name           : t('station_name'),
    vehicle_mac_address : t('vehicle_identifier'),
  }

  const fields = Object.entries(vehicle ?? {}).reduce((acc, [key, val]) => {
    if (displayFields.includes(key) && hasValue(val)) {
      const displayValue = getValue(key, val, t)
      acc.push({
        label : labels[key as keyof typeof labels],
        value : displayValue,
      })
    }

    return acc
  }, [] as Partial<Field>[])

  return (
    <DetailsColumn>
      <SubHeadingBold>{ t('unknown_vehicle') }</SubHeadingBold>
      <List>
        { fields.map((field) => (
          <ListItemStyled key={field.value as string | number}>
            <StyledLabel>{ field.label }</StyledLabel>
            <StyledValue>{ field.value as string }</StyledValue>
          </ListItemStyled>
        )) }
      </List>
    </DetailsColumn>
  )
}

export interface ExistingVehicleProps extends WizardScreenProps {
  onChange: WizardInputChangeFunc;
}

const ExistingVehicle: FC<ExistingVehicleProps> = ({
  onChange,
  state,
  t,
  unknownVehicleProps,
}) => {
  const sectionId                                 = 'existing_vehicle'
  const { unknownVehicleSession }                 = state.data
  const { existing_vehicle }                      = state.sections
  const [vehicleIdentifier, setVehicleIdentifier] = useState<string>()
  const selectedVehicleId                         = parseInt(
    existing_vehicle.selectedVehicle?.id as unknown as string,
  )
  const vehicleDetails                            = {
    ...(unknownVehicleProps ?? {}),
    ...unknownVehicleSession,
  }

  const mergedVehicle = {
    ...(existing_vehicle || {}),
    ...unknownVehicleSession,
  }

  const idType = (() => {
    if (mergedVehicle.vehicle_mac_address) {
      return VehicleIdTypes.ID_TYPE_MAC_ADDRESS
    }
    return VehicleIdTypes.ID_TYPE_RF_ID
  })()

  const onSelected = (existingVehicle: Vehicle) => {
    onChange(sectionId, 'selectedVehicle', existingVehicle)
    setVehicleIdentifier(t(`vehicles.identified_by.${idType}`))
  }

  useEffect(() => {
    if (idType) {
      setVehicleIdentifier(t(`vehicles.identified_by.${idType}`))
    }
  }, [idType])

  return (
    <Container>
      <SubHeading>
        { t('vehicles.add_vehicle.add_unknown_vehicle_identifier_caption') }
      </SubHeading>
      <KitFlexRow>
        <VehicleDetails
          vehicle={{ ...vehicleDetails } as unknown as Vehicle}
          t={t}
        />
        <div style={{ flex: '.75' }}>
          <ExistingVehicles
            selectedVehicleId={selectedVehicleId}
            vehicleIdentifier={vehicleIdentifier}
            onSelected={onSelected}
          />
        </div>
      </KitFlexRow>
    </Container>
  )
}

export default ExistingVehicle
