import { type FilterAction, type FilterReducerState } from '@/common/reducers'
import { has } from '@/common/utils/data'
import { type Filter } from '@/types/filters'

export interface FilterState {
  // TODO: typing should be improved here (re: unknown), but some additonal refactoring and consolidation is required first
  activeFilters: Filter[];
  filters: Filter[];
  filtersPanelOpen?: boolean;
  extraMenuOpen?: boolean;
  showWizard?: boolean;
}

export interface FleetFiltersReducerState {
  filters: string[];
  activeFilters: string[];
  filtersPanelOpen: boolean;
}

export type ReducerPayload<T = unknown> = Record<string, T>;

export const filterActions = {
  CANCEL_CHANGES         : 'CANCEL_CHANGES',
  RESET_FILTERS          : 'RESET_FILTERS',
  SET_ACTIVE_FILTERS     : 'SET_ACTIVE_FILTERS',
  SET_FILTERS            : 'SET_FILTERS',
  SHOW_WIZARD            : 'SHOW_WIZARD',
  HIDE_WIZARD            : 'HIDE_WIZARD',
  TOGGLE_FILTERS_OPEN    : 'TOGGLE_FILTERS_OPEN',
  TOGGLE_EXTRA_MENU_OPEN : 'TOGGLE_EXTRA_MENU_OPEN',
}

export type ReducerAction<Payload, Type = keyof typeof filterActions> = {
  type: Type;
  payload?: Payload;
  open?: boolean;
  forceOpen?: boolean;
};

// TODO: consolidate this with filters reducer in @/common/reducers
export function fleetReducer(
  state = {} as FilterState,
  action: FilterAction,
): FilterState {
  switch (action.type) {
    case 'RESET_FILTERS':
      return { ...state, filters: [], activeFilters: [] }
    case 'CANCEL_CHANGES':
      return {
        ...state,
        filters          : state.activeFilters.concat().map((f) => ({ ...f })),
        filtersPanelOpen : false,
      }
    case 'SET_ACTIVE_FILTERS':
      return {
        ...state,
        activeFilters    : action.payload as Filter[],
        filtersPanelOpen : false,
      }
    case 'SET_FILTERS':
      return { ...state, filters: action.payload as Filter[] }
    case 'TOGGLE_FILTERS_OPEN':
      return {
        ...state,
        filtersPanelOpen: (has(action, 'forceOpen')
          ? action.forceOpen
          : !state.filtersPanelOpen) as boolean,
      }
    case 'TOGGLE_EXTRA_MENU_OPEN':
      return { ...state, extraMenuOpen: !state.extraMenuOpen }
    case 'SHOW_WIZARD':
      return { ...state, showWizard: !state.showWizard }

    case 'HIDE_WIZARD':
      return { ...state, showWizard: false }
    default:
      return state
  }
}

export const initialState: FilterReducerState = {
  filters          : [],
  activeFilters    : [],
  filtersPanelOpen : false,
  extraMenuOpen    : false,
  showWizard       : false,
}
