import { ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

const { fontSize, fontWeight, spacing } = ThemeConstants

export const FormWrapper = styled.fieldset`
   input[type='number'] {
    max-width: 100px
   }

   h5 {
    font-size: ${fontSize.text_14}rem;
    margin:${spacing.m}rem 0;
  }
`

export const ReadOnlyFieldContainer = styled.div`
  margin-bottom: ${spacing.absolute.m}px;
  line-height: ${fontSize.base}rem;
  > div {
    margin-bottom: ${spacing.absolute.xxs}px;
  }
  .value {
    font-weight: ${fontWeight.bold};
  }
`
