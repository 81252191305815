import { KitUtilCommon } from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'
import {
  type Context,
  type Dispatch,
  type MouseEvent,
  type ReactNode,
  useEffect,
  useReducer,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import fleetStore from '../../store/fleetStore'
import FiltersPanel, { getFilterConfig } from './Filter'
import FleetHeader from './FleetHeader'
import { filterActions, fleetReducer, initialState } from './reducers'
import {
  FLEET_FLEET_UPDATE,
  FLEET_VEHICLE_UPDATE,
  hasPermission,
} from '@/common/permissions'
import { type FilterAction } from '@/common/reducers'
import utils, { getCleanURL } from '@/common/utils'
import { stringSearch } from '@/common/utils/data'
import { buildFilters } from '@/common/utils/filters'
import { toggleBodyScroll } from '@/common/utils/ui'
import AddFleetWizard from '@/components/AddFleetWizard'
import AddVehicleWizard from '@/components/AddVehicleWizard'
import Page from '@/components/Page'
import { PageContainer } from '@/components/Styled'
import FleetContext from '@/contexts/FleetContext'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import FeatureService, { Features } from '@/services/FeatureService'
import { type Filter } from '@/types/filters'

const Content = styled.div`
  display: flex;
`

function getModalComponents({
  dispatch,
  externalAction,
  showWizard,
  view,
}: {
  dispatch: Dispatch<FilterAction>;
  externalAction: (actionName: string, args?: unknown) => void;
  showWizard: boolean;
  view: string;
}) {
  const openWizard  = () => dispatch({ type: 'SHOW_WIZARD', open: true })
  const closeWizard = () => dispatch({ type: 'HIDE_WIZARD' })
  return {
    fleets: (
      <AddFleetWizard
        show={showWizard}
        openWizard={openWizard}
        closeWizard={closeWizard}
      />
    ),
    vehicles: (
      <AddVehicleWizard
        show={showWizard}
        onHide={closeWizard}
        openWizard={openWizard}
        closeWizard={closeWizard}
        externalAction={externalAction}
      />
    ),
  }[view]
}

export interface FleetsViewProps {
  children?: ReactNode;
  context?: Context<unknown>;
}

function FleetsView({ }): JSX.Element {
  const { analyticsService } = useAnalyticsService()
  const { t }                = useTranslation()
  const navigate             = useNavigate()

  const [state, dispatch]                                        = useReducer(fleetReducer, initialState)
  const { activeFilters, filters, filtersPanelOpen, showWizard } = state
  const view                                                     = (() => {
    const path = utils
      .getLastURLPath(window.location.pathname)
      .replace('/', '')
    return path === '' ? 'fleets' : path
  })()

  const showActionButton = view === 'fleets'
    ? hasPermission(FLEET_FLEET_UPDATE)
    : hasPermission(FLEET_VEHICLE_UPDATE)

  const filterValues = filters.reduce((acc, filt: Filter) => {
    acc[filt.name as keyof typeof acc] = filt.value
    return acc
  }, {} as Record<string, unknown>)
  const params = KitUtilCommon.getQueryObject(window.location.search)

  const filterMap = {
    search: (searchText: string, record: Record<string, unknown>) => {
      if (!searchText || !searchText.length || !record) {
        return true
      }
      const searchFields = view === 'fleets' ? ['name'] : ['name', 'license_plate', 'port']
      return stringSearch(searchText, record, searchFields)
    },
  }

  const { filterConfig, filtersChanged } = getFilterConfig(
    fleetStore.results,
    dispatch,
    {
      currentFilters : filters,
      filterMap,
      view           : fleetStore.view,
      values         : filterValues,
      skipFilters    : FeatureService.isEnabled(Features.devUIFeaturesEnabled)
        ? []
        : ['vehicles_count', 'vehicle_plugged_in'],
      t,
    },
  )

  function externalAction(actionType: string) {
    if (actionType === 'ADD_FLEET') {
      dispatch({ type: filterActions.HIDE_WIZARD })
      navigate('/fleets?action=ADD_FLEET')
    }
  }

  function applyFilters() {
    const newFilters = filters.concat().map((f) => ({ ...f }))
    const searchFilt = activeFilters.find((f) => f.name === 'search')
    if (searchFilt) {
      newFilters.push(searchFilt)
    }
    dispatch({
      type    : filterActions.SET_ACTIVE_FILTERS,
      payload : newFilters,
    })
    toggleBodyScroll()

    analyticsService.trackEvent(analyticEvents.filtersApplied)
  }

  function cancelFilterChange(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    e.stopPropagation()
    dispatch({ type: filterActions.CANCEL_CHANGES })
    toggleBodyScroll()
  }

  function onSearch(searchText: string) {
    let newFilters
    if (!searchText || !searchText.length) {
      newFilters = buildFilters(activeFilters, { name: 'search' }, true)
    } else {
      newFilters = buildFilters(activeFilters, {
        name    : 'search',
        value   : searchText,
        noCount : true,
        fn      : filterMap.search,
      })
    }

    dispatch({ type: filterActions.SET_ACTIVE_FILTERS, payload: newFilters })
    // analyticsService.trackEvent(analyticEvents.filtersApplied, { search: true });
  }

  function onPageChange() {
    dispatch({ type: filterActions.RESET_FILTERS })
  }

  const filterComponents = (
    <FiltersPanel
      config={filterConfig}
      onApplyFilters={applyFilters}
      onCancel={cancelFilterChange}
      onFiltersChanged={filtersChanged}
      t={t}
    />
  )

  useEffect(() => {
    if (params?.action === 'ADD_FLEET') {
      const url = getCleanURL(window.location.pathname)
      navigate(url)
      dispatch({ type: filterActions.SHOW_WIZARD, open: true })
    }
  }, [params?.action])

  useEffect(() => {
    fleetStore.setView(view)
  }, [view])

  useEffect(() => {
    fleetStore.setActiveFilters(activeFilters)
  }, [activeFilters])

  return (
    <Page title={t('page_titles.fleet_list')}>
      <FleetContext.Provider value={{ state, dispatch }}>
        <FleetHeader
          filters={activeFilters}
          dispatch={dispatch}
          showSearchFilterBar={fleetStore.hasResults}
          filterComponents={filterComponents}
          onSearch={onSearch}
          onPageChange={onPageChange}
          numMatches={fleetStore.filteredData?.length}
          filtersPanelOpen={filtersPanelOpen as boolean}
          showActionButton={showActionButton}
          t={t}
          view={view}
        />
        <PageContainer>
          { getModalComponents({
            dispatch,
            externalAction,
            showWizard: showWizard as boolean,
            view,
          }) }

          <Content>
            <Outlet context={{ dispatch }} />
          </Content>
        </PageContainer>
      </FleetContext.Provider>
    </Page>
  )
}

export default observer(FleetsView)
