import { KitModal, ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

const { spacing } = ThemeConstants

export const StyledTitle = styled.h5`
  & {
    display: block;
    align-items: center;
    margin: ${spacing.absolute.m}px 0 ${spacing.absolute.m}px 0;
  }
`

export const KitModalFooter = styled(KitModal.Footer)`
  border-top: 0;
  padding-left: 0;
  padding-right: 0;
  flex-grow: 0;
`
