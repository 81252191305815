import { KitSpinner, KitToastTypeOptions, useToast } from '@chargepoint/cp-toolkit'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  SettingsPageContainer,
  SettingsPageHeader,
  SettingsPageTitle,
} from './index.styles'
import NotificationContactBar from './NotificationContactBar'
import NotificationOptionList from './NotificationList'
import { createNotificationsPayload, mapNotifications } from './utils'
import { debounce } from '@/common/utils'
import { pick } from '@/common/utils/data'
import NoResults from '@/components/NoResults'
import { PageContent } from '@/components/Styled'
import {
  type FleetNotification,
  type FleetNotificationSettingsResponse,
  type NotifyPreferenceValue,
  type NotifyPreferences,
} from '@/models/settingsModel'
import SettingsService from '@/services/SettingsService'

const notificationSettingsLoader = async () => SettingsService.getNotificationSettings()

const NotificationSettings = () => {
  const [response, setResponse]           = useState<FleetNotificationSettingsResponse>()
  const [notifications, setNotifications] = useState<FleetNotification[]>([])
  const [error, setError]                 = useState<string>()
  const { t }                             = useTranslation()

  const notificationsRef = useRef<FleetNotification[]>(notifications)

  notificationsRef.current = notifications

  const isLoading = !error && !response

  const saveNotifications = useCallback(async () => {
    const items   = notificationsRef.current
    const payload = {
      notifications: items.reduce((acc, item) => {
        acc[item.key] = pick(item as unknown as Record<string, unknown>, [
          'email',
          'sms',
        ]) as unknown as NotifyPreferenceValue
        return acc
      }, {} as NotifyPreferences),
    }

    const { error: serviceError, status } = await SettingsService.updateNotificationSettings(
      payload,
    )

    if (serviceError) {
      const reason = status === 403
        ? t('errors.unauthorized')
        : serviceError.message ?? t('unknown')
      useToast({
        t,
        toastType : KitToastTypeOptions.ERROR,
        message   : t(
          'fleet_settings.notifications.unable_to_update_notification_settings',
          { reason },
        ),
      })

      // reset form elements to initial
      if (response) {
        setNotifications(mapNotifications(response, t))
      }
    } else {
      useToast({
        t,
        toastType : KitToastTypeOptions.SUCCESS,
        message   : t('fleet_settings.notifications.settings_updated'),
      })
    }
  }, [response])

  const debouncedSaveNotifications = useCallback(
    debounce(saveNotifications, 1500),
    [],
  )

  const onChange = (changes: NotifyPreferences) => {
    const payload = createNotificationsPayload(changes, notifications)

    setNotifications(
      mapNotifications(payload as FleetNotificationSettingsResponse, t),
    )
    debouncedSaveNotifications()
  }

  useEffect(() => {
    const loadSettings = async () => {
      const res = await notificationSettingsLoader()
      if (res.error) {
        setError(res.error)
      } else {
        setResponse(res)
        setNotifications(mapNotifications(res, t))
      }
    }

    if (!response && !error) {
      loadSettings()
    }
  }, [])

  return (
    <PageContent>
      <SettingsPageContainer>
        { isLoading && <KitSpinner size="s" align="middle" /> }
        { error && (
          <NoResults message={'unable to load notification settings'} />
        ) }
        { !isLoading && response && (
          <>
            <SettingsPageHeader>
              <SettingsPageTitle tabIndex={-1}>
                { t('fleet_settings.notifications.notifications_title') }
              </SettingsPageTitle>
            </SettingsPageHeader>
            <NotificationOptionList items={notifications} onChange={onChange} t={t} />
            <NotificationContactBar
              email={response.email}
              phone={response.phone_number}
              t={t}
            />
          </>
        ) }
      </SettingsPageContainer>
    </PageContent>
  )
}

export { NotificationSettings }

export default NotificationSettings
