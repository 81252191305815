import { KitPage, KitUtilData } from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'

import { type ReactElement, type ReactNode } from 'react'

import ErrorBoundary from './ErrorBoundary'
import ErrorHandler from './RouteErrorHandler'
import { getLocaleCode } from '@/common/lang'
import appStore from '@/store/AppStore'

export interface PageProps {
  title: string;
  children: ReactNode;
}

function Page({ children, title }: PageProps): ReactElement {
  const locale  = getLocaleCode()
  const content = KitUtilData.isEmpty(appStore.errors) ? (
    <ErrorBoundary>{ children }</ErrorBoundary>
  ) : (
    <ErrorHandler errors={appStore.errors} />
  )

  return (
    <KitPage
      title={title ?? 'Fleet Depot'}
      titleTemplate={'%s - ChargePoint Fleet'}
      locale={locale}
    >
      { content }
    </KitPage>
  )
}

export default observer(Page)
