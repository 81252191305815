import { type WretchError, type WretchOptions, type WretchResponseChain } from 'wretch'

import ErrorMonitoringService from './ErrorMonitoringService'
import fetch, { getCSRFTokenHeader } from '@/common/fetch_client'
import { isProd, paramsToQueryString } from '@/common/utils'
import { hasValue } from '@/common/utils/validations'
import {
  type ServiceError,
  type ServicePayload,
  type ServiceResponse,
} from '@/models/serviceModel'

// TODO: Data fetching needs to be re-evaluated.
// We should look at ReactQuery or SWR by to see if it makes sense for us
// That said, Wretch is a decent lib as is, and we are not leveraging nearly all it's capabilities.
// We need to evalute for the following:
// - ease of use,
// - caching
// - refetching, etc.

const memoryCache: {
  [key: string]: {
    cachedAt?: number;
    value: unknown;
  };
} = {}

export type ResponseChain = WretchResponseChain<unknown, unknown, undefined>;
export interface ServiceInterface {
  get: ServiceMethod;
  delete: ServiceMethod;
  patch: ServiceMethod;
  post: ServiceMethod;
  put: ServiceMethod;
}

export type ServiceMethod = (
  url: string,
  query?: ServicePayload | ServicePayload[],
  opts?: Record<string, unknown> & WretchOptions
) => ResponseChain;

export type ServiceMethodResponseType<T> = Promise<ServiceResponse<T>>;
function AbstractService(): ServiceInterface {
  return {
    get: (url, query, opts = { cache: false, expires: 1000 * 60 }) => {
      if (!opts.cache) {
        return fetch.url(url).get()
      }

      const now = new Date().getTime()
      // simple memory cache for get queries
      // could be extended to use localStorage if it makes sense...
      const cacheKey = `${url}${paramsToQueryString(query as ServicePayload)}`

      // opts.cache === true
      // cache expires in 60 secs, unless explicitly set
      const cache   = memoryCache[cacheKey]
      const expired = cache
        ? now - Number(cache.cachedAt) > (opts.expires ?? 1000 * 60)
        : true

      if (cache && !expired) {
        return new Promise((resolve) => {
          resolve(cache.value as ServiceResponse<unknown>)
        }) as unknown as ResponseChain
      }

      try {
        return new Promise<ServiceResponse<unknown>>((resolve, reject) => {
          fetch
            .url(url)
            .get()
            .json((response) => {
              memoryCache[cacheKey] = {
                value    : response,
                cachedAt : new Date().getTime(),
              }
              resolve(response as ServiceResponse<unknown>)
            })
            .catch((err: ServiceError) => {
              reject(err)
            })
        }) as unknown as ResponseChain
      } catch (err) {
        console.error(err)
        return new Promise((_, reject) => {
          reject(err)
        }) as unknown as ResponseChain
      }
    },
    post: (url, query) => fetch
      .headers({ ...getCSRFTokenHeader() })
      .url(url)
      .post(query),
    delete: (url) => fetch
      .headers({ ...getCSRFTokenHeader() })
      .url(url)
      .delete(),
    put: (url, query) => fetch
      .headers({ ...getCSRFTokenHeader() })
      .url(url)
      .put(query),
    patch: (url, query) => fetch
      .headers({ ...getCSRFTokenHeader() })
      .url(url)
      .patch(query),
  }
}

// TODO: Investigate Using useSWR or ReactQuery to handle rest calls.
// convenience method for wrapping get requests that handles errors and response in same response
export function wrapRequest<T = unknown>(req: ResponseChain): Promise<T> {
  // catches first error in chain
  if (typeof (req as ResponseChain).json === 'function') {
    return (req as ResponseChain).json().catch(async (err: WretchError) => {
      let status = err?.status
      // if status was not found in the json err, then, try and get it from the response object
      if (!hasValue(status)) {
        const res = await (req as ResponseChain)?.res()
        status    = res.status
      }

      if ([200, 204].includes(status)) {
        // this is not a server error, but instead an error while trying
        // to parse a json response. Most likely, this is a POST request with an empty response
        return { status }
      }
      // Only capture failed api calls in production environment. Capturing these in QA was making
      // too much noise due to frequent deploy-related instability
      if (isProd()) {
        // suppress logging fetch errors that were intentionally thown by the backend (suppress 4xx errors)
        if (![400, 401, 403, 404].includes(status)) {
          ErrorMonitoringService.captureException(err)
        }
      }
      return {
        error: err ?? {},
        status,
      }
    }) as Promise<T>
  }

  return req as unknown as Promise<T>
}

export default AbstractService
