/* eslint-disable @typescript-eslint/no-use-before-define */
import { computed, flow, makeObservable, observable, runInAction } from 'mobx'

import { type VehicleInfo } from './types'
import utils from '@/common/utils'
import { has, omit } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { isVehicleReady } from '@/common/utils/vehicles'
import FleetWebSocket, {
  ALERT_EVENT_GROUP,
  DEPOT_DETAILS_EVENT_GROUP,
  EVENT_TYPE_DELETE,
  EVENT_TYPE_REFRESH,
  EVENT_TYPE_UPDATE,
  VEHICLE_EVENT_GROUP,
  VEHICLE_MODEL_EVENT_GROUP,
  VEHICLE_STATE_EVENT_GROUP,
} from '@/common/websocket'
import { type Alert } from '@/models/alertModel'
import { type DepotDetailsRow } from '@/models/depotModel'
import { type PagedServiceResponse } from '@/models/serviceModel'
import { type Vehicle, type VehiclePropSheet } from '@/models/vehicleModel'
import AlertService from '@/services/AlertService'
import ErrorMonitoringService from '@/services/ErrorMonitoringService'
import VehicleService from '@/services/VehicleService'
import appStore from '@/store/AppStore'
import { type Filter } from '@/types/filters'

class VehicleStore {
  id?: string
  isDeleted = false
  alerts?: Alert[]
  // filters are used in charging & trip history pages
  filters: Filter[] = []
  vehicle?: Vehicle
  props?: VehiclePropSheet
  errors?: {
    vehicleDeletedError?: false;
    propSheetError?: false;
  }
  initialized = false
  ws?: FleetWebSocket

  constructor() {
    makeObservable(this, {
      alerts            : observable,
      initialized       : observable,
      isDeleted         : observable,
      filters           : observable,
      props             : observable,
      vehicle           : observable,
      chargingSessionID : computed,
      status            : computed,
      vehicleProps      : computed,
      vehicleName       : computed,
    })
  }

  cleanup() {
    if (this.ws) {
      this.ws.close()
    }
  }

  connect() {
    this.ws = new FleetWebSocket(
      utils.constructWebSocketURI(`/ws/vehicle-status/${this.id}/`),
      () => undefined,
      () => undefined,
      ({ content, event_type, group }) => {
        const eventHandlerKey =  `${group}.${event_type}`
        if (has(eventHandlerMap, eventHandlerKey)) {
          eventHandlerMap[group][event_type](content)
        } else {
          ErrorMonitoringService.captureException({ name: 'onMessage handler exception', message: `missing ${eventHandlerKey} ` })
        }
      },
      () => undefined,
    )

    this.ws.connect()
  }

  init = flow(function* init(this: VehicleStore, vehicleId: string) {
    if (vehicleId !== this.id) {
      this.id          = vehicleId
      this.initialized = false
      this.connect()
    }

    const vehicleResult = yield VehicleService.getVehicle(vehicleId)

    if (!vehicleResult.error) {
      this.vehicle = vehicleResult

      if (isVehicleReady(vehicleResult)) {
        const propsResult = yield VehicleService.getVehiclePropSheetDetails(
          vehicleId,
        )
        if (propsResult.error) {
          this.errors = { ...this.errors, propSheetError: propsResult.error }
        } else {
          this.props = propsResult
        }
      } else {
        // if vehicle is not ready, then be sure to clean out old props from previous vehicles
        this.props = {}
      }

      this.initialized = true
    } else {
      this.vehicle = {}
      appStore.setErrors(vehicleResult.error)
    }
  })

  fetchAlerts = flow(function* fetchAlerts(this: VehicleStore) {
    // fetch alerts for vehicle
    if (this.vehicle) {
      const vehicleAlertsResponse: PagedServiceResponse<Alert> =        yield AlertService.getAlertList({
        event_type          : 'active',
        vehicle_external_id : this.vehicle?.external_id,
      })
      let alerts: Alert[]                                      = []
      if (Array.isArray(vehicleAlertsResponse.results)) {
        alerts = alerts.concat(vehicleAlertsResponse.results)
      }
      // if the vehicle is plugged into a port, fetch alerts for that port.
      if (this.props?.port_external_id) {
        const chargerAlertsResponse: PagedServiceResponse<Alert> =          yield AlertService.getAlertList({
          event_type       : 'active',
          port_external_id : this.props.port_external_id,
        })
        if (Array.isArray(chargerAlertsResponse.results)) {
          this.alerts = alerts.concat(chargerAlertsResponse.results)
        }
      }
    }
  })

  reload() {
    if (this.vehicle) {
      this.init(this.vehicle?.external_id)
    }
  }

  updateVehicle(updatedProps: Partial<VehiclePropSheet>) {
    runInAction(() => {
      const merged = {
        ...(this.props || {}),
        ...omit(updatedProps, (v) => !hasValue(v)),
      } as VehiclePropSheet
      this.props   = merged
    })
  }

  updateAlerts(payload: Partial<Alert>) {
    if (!Array.isArray(this.alerts)) {
      this.alerts = []
    }
    const result = this.alerts.reduce((acc, alert) => {
      if (payload.id === alert.id) {
        acc.push({ ...alert, ...(payload as Alert) })
      } else {
        acc.push(alert)
      }
      return acc
    }, [] as Alert[])

    this.alerts = result
  }

  setFilters = (filters: Filter[]) => {
    this.filters = filters
  }

  get chargingSessionID() {
    return this.props?.session_id
  }

  get vehicleProps() {
    // Note: work with backend team to normalize status lookup so the
    // UI only has to look in a single place
    const status_code = this.props?.status_code ?? this.vehicle?.status
    return {
      ...this.vehicle,
      ...this.props,
      status_code,
    }
  }

  get status() {
    return this.props?.status
  }

  get vehicleName() {
    return this.vehicle?.name
  }
}

const vehicleStore = new VehicleStore()
export default vehicleStore

type EventHandlerPayload = Partial<VehicleInfo> | Partial<Alert>;
interface EventHandlerMap {
  [groupKey: string]: {
    [eventType: string]: (payload: EventHandlerPayload) => void;
  };
}

export const eventHandlerMap: EventHandlerMap = {
  [DEPOT_DETAILS_EVENT_GROUP]: {
    [EVENT_TYPE_UPDATE]  : (payload: EventHandlerPayload) => vehicleStore.updateVehicle(payload as Partial<DepotDetailsRow>),
    [EVENT_TYPE_REFRESH] : vehicleStore.reload,
  },
  [VEHICLE_EVENT_GROUP]: {
    [EVENT_TYPE_UPDATE] : (payload: EventHandlerPayload) => vehicleStore.updateAlerts(payload as Partial<Alert>),
    [EVENT_TYPE_DELETE] : () => {
      vehicleStore.isDeleted = true
    },
  },
  [VEHICLE_MODEL_EVENT_GROUP] : { [EVENT_TYPE_REFRESH]: vehicleStore.reload },
  [ALERT_EVENT_GROUP]         : {
    [EVENT_TYPE_UPDATE]: (payload: EventHandlerPayload) => {
      vehicleStore.updateAlerts(payload as Partial<Alert>)
    },
  },
  [VEHICLE_STATE_EVENT_GROUP]: {
    [EVENT_TYPE_UPDATE]: (payload: EventHandlerPayload) => {
      vehicleStore.updateVehicle(payload as Partial<Vehicle>)
    },
  },
}
