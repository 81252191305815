import { KitFlexCol, KitRadio, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type FC, type ReactNode } from 'react'

import styled from 'styled-components'

import { FormItem } from '@/components/Styled'
import { type SimpleType } from '@/types/index'

const { spacing } = ThemeConstants

const StyledFormItem = styled(FormItem)`
  display: flex;
  justify-content: space-between;
`

const Description = styled.div`
  margin-left: ${spacing.absolute.xl + spacing.absolute.xs}px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spacing.absolute.m}px;
`

export interface EnhancedRadioProps {
  name: string;
  checked: boolean;
  children?: ReactNode;
  description?: string;
  disabled?: boolean;
  label: string;
  icon?: string | ReactNode;
  value: SimpleType;
  onChange: (e: unknown) => void;
}

const EnhancedRadio: FC<EnhancedRadioProps> = ({
  children,
  description,
  icon,
  ...props
}) => (
  <StyledFormItem>
    <KitFlexCol>
      <KitRadio {...props} />

      <Description>
        { description } { children }
      </Description>
    </KitFlexCol>
    { icon && <IconWrapper>{ icon }</IconWrapper> }
  </StyledFormItem>
)

export default EnhancedRadio
