/* eslint-disable camelcase */
import {
  KitChargingBadge,
  KitUtilData,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import classNames from 'classnames'
import { format } from 'date-fns'
import { type TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type VehicleInfo } from '../../types'
import vehicleStore from '../../vehicleStore'
import {
  AlertsContainer,
  CardContainer,
  Content,
  FlexItem,
  GraphContainer,
  GraphStyledCardBody,
  KitDisplayNumbersWrapper,
  StatusContainer,
  TimeStampLabel,
  UpcomingBlocksContainer,
  VehicleImageContainer,
} from './index.styles'
import {
  type ChargingSessionStatus,
  DistanceUnits,
  EMPTY_VALUE_PLACEHOLDER,
  ISO_DATE_TIME,
} from '@/common/constants'
import {
  formatNumber,
  milesToKilometers,
  numberFormatOptions,
} from '@/common/lang'
import utils from '@/common/utils'
import { getNotificationLevelForAlert } from '@/common/utils/alerts'
import { has } from '@/common/utils/data'
import { getUserPreferences } from '@/common/utils/user'
import { hasValue } from '@/common/utils/validations'
import {
  getNormalizedVehicleStatusCode,
  getVehicleStatusText,
} from '@/common/utils/vehicle'
import AlertRenderer from '@/components/AlertRenderer'
import VehiclePowerGraph from '@/components/Charting/ChartComponents/ChargingSessionGraph'
import FleetDisplayNumbers, { type DisplayNumber } from '@/components/data/FleetDisplayNumbers'
import MiniMap from '@/components/MiniMap'
import Notification, { NotificationLevel } from '@/components/Notification'
import Page from '@/components/Page'
import {
  Container,
  FieldName,
  FieldValue,
  List,
  ListItemSmall,
  SectionItem,
} from '@/components/Styled'
import StyledFleetCard from '@/components/StyledFleetCard'
import StyledSpinner from '@/components/StyledSpinner'
import { getTimeStampAwareTooltipContent, getTimeStampAwareTooltipProps } from '@/components/tooltipHelpers'
import UpcomingVehicleBlocks from '@/components/UpcomingVehicleBlocks'
import VehicleImage from '@/components/VehicleImage'
import { type Alert } from '@/models/alertModel'
import { type TimeSeriesData } from '@/models/chartModel'
import { type DepotDetailsRow } from '@/models/depotModel'
import { type ActiveChargingSession, type Vehicle, type VehiclePropSheet } from '@/models/vehicleModel'

const { spacing } = ThemeConstants

const userUnitPreference =  getUserPreferences().length_units ?? DistanceUnits.MILES

const getVehicleRange = ({ rangeInMiles }: { rangeInMiles: number }) => {
  if (Number.isNaN(Number(rangeInMiles))) {
    return EMPTY_VALUE_PLACEHOLDER
  }
  if (userUnitPreference !== DistanceUnits.MILES) {
    return formatNumber(
      milesToKilometers(rangeInMiles),
      numberFormatOptions.distance,
    )
  }
  return formatNumber(rangeInMiles, numberFormatOptions.distance)
}

const formatOdometerReading = (vehicle: Vehicle | VehiclePropSheet) => {
  if (userUnitPreference !== DistanceUnits.MILES) {
    return formatNumber(
      milesToKilometers(vehicle.odometer_reading as number),
      numberFormatOptions.distance,
    )
  }
  return formatNumber(
    vehicle.odometer_reading as number,
    numberFormatOptions.distance,
  )
}

const renderAlerts = (
  alerts: Alert[],
  otherProps: VehiclePropSheet,
  t: TFunction,
) => {
  if (KitUtilData.isEmpty(alerts)) {
    return null
  }
  return (
    <AlertsContainer>
      { alerts?.map((alert) => (
        <SectionItem key={alert.alert_id}>
          <Notification
            className={alerts.length > 1 ? 'minimal' : 'default'}
            type={getNotificationLevelForAlert(alert)}
          >
            <AlertRenderer
              timeZone={otherProps.depot_timezone}
              alert={{
                ...alert,
                alert_subject_metadata: {
                  ...(alert as unknown as Record<string, string>),
                  ...alert.alert_subject_metadata,
                  vehicle_name: otherProps.vehicle_name as string,
                },
              }}
              type="text"
              t={t}
            />
          </Notification>
        </SectionItem>
      )) }
    </AlertsContainer>
  )
}

const prepareVehicleInfo = (session: VehiclePropSheet, t: TFunction) => {
  const isCharging = hasValue(
    session.session_id ?? session.charging_session_id,
  )

  const ttipProps = getTimeStampAwareTooltipProps(session.soc_data, session.depot_timezone, t)

  const vars = [
    {
      title       : t('soc'),
      // Using formatNumber instead of formatPercent as KitDisplayNumbers handles units formatting
      value       : formatNumber(session.soc_data?.value as number, { maximumFractionDigits: 1 }),
      unit        : '%',
      qaId        : 'soc_value',
      isStale     : ttipProps?.isStale,
      ttipContent : session ? getTimeStampAwareTooltipContent(ttipProps, t) : null,
    },
    {
      title       : t('range'),
      value       : getVehicleRange({ rangeInMiles: session.estimated_range as number }),
      unit        : t(`units.distance.${userUnitPreference}.short`),
      qaId        : 'range_value',
      isStale     : ttipProps?.isStale,
      ttipContent : session ? getTimeStampAwareTooltipContent(ttipProps, t) : null,
    },
    {
      title : t('odometer'),
      value : has(session, 'odometer_reading')
        ? formatOdometerReading(session)
        : '--',
      unit : t(`units.distance.${userUnitPreference}.short`),
      qaId : 'odometer_value',
    },
    {
      title : t('energy_added'),
      value : session.energy
        ? formatNumber(session.energy, numberFormatOptions.energy)
        : '--',
      unit   : t('units.kilowatt_hour.short'),
      active : isCharging,
      qaId   : 'energy_added',
    },
    {
      title : t('power_setpoint'),
      value : `${formatNumber(
        session.power as number,
        numberFormatOptions.power,
      )} / ${formatNumber(
        session.setpoint as number,
        numberFormatOptions.energy,
      )}`,
      unit   : t('units.kilowatt.short'),
      active : isCharging,
      qaId   : 'power_setpoint',
    },
  ]

  return vars.filter((v) => v.active !== false) as DisplayNumber[]
}

const VehicleStatus = observer(({ t }: { t: TFunction }) => {
  const vehicle                                                     = vehicleStore.vehicleProps
  const { latitude, longitude, model_make, model_name, model_year } = vehicle
  const showMap                                                     = latitude && longitude
  const lastUpdatedLabel                                            = t('last_updated', {
    num   : '?',
    units : 'hours',
  }).toUpperCase()
  const chargingStatus                                              = utils.getChargingStatusCode(
    getNormalizedVehicleStatusCode(vehicleStore.vehicleProps) as string,
  )
  const connected                                                   = !!vehicle.port
  // TODO: Timestamp display logic to be figured out for later milestone
  const showTimeStamp = false
  const vehicleInfo   = `${vehicle.model_make} ${vehicle.model_name} ${vehicle.model_year}`
  const statusLabel   = getVehicleStatusText(vehicle, t)

  const badgeProps = {
    connected,
    connectedText    : statusLabel,
    disconnectedText : statusLabel,
    chargingStatus,
    'data-qa-id'     : 'vehicle_status',
  }

  const vehicleName     = `${model_make} ${model_name} ${model_year}`
  const vehicleLocation = vehicle.location ?? t('unknown')

  return (
    <CardContainer
      className="vehicle-status"
      style={{ maxHeight: '390px', maxWidth: '425px' }}
    >
      <StyledFleetCard.Header>
        <header>{ t('status') }</header>
        { showTimeStamp && <TimeStampLabel>{ lastUpdatedLabel }</TimeStampLabel> }
      </StyledFleetCard.Header>
      <StyledFleetCard.Body>
        <>
          <StatusContainer>
            <FlexItem>
              <VehicleImageContainer>
                <VehicleImage
                  altText={vehicleInfo}
                  url={vehicle.image_url as string}
                  t={t}
                />
              </VehicleImageContainer>
            </FlexItem>
            <FlexItem>
              { hasValue(chargingStatus) && (
                <KitChargingBadge
                  {...badgeProps}
                  className={classNames(vehicle.status, { isUnplugged: !hasValue(vehicle.port) })}
                />
              ) }
              <List className="vehicle-info">
                <ListItemSmall>
                  <FieldName>{ t('location') }</FieldName>
                  <FieldValue data-qa-id="vehicle_location">
                    { vehicleLocation }
                  </FieldValue>
                </ListItemSmall>
              </List>
            </FlexItem>
          </StatusContainer>
          <span data-qa-id="vehicle_make_model_year">{ vehicleName }</span>
        </>
        { showMap && (
          <MiniMap
            markers={[
              {
                position: {
                  lat : vehicle.latitude as number,
                  lng : vehicle.longitude as number,
                },
              },
            ]}
            opts={{
              mapCenter      : { lat: vehicle.latitude, lng: vehicle.longitude },
              markerProps    : {},
              containerStyle : {
                marginTop : `${spacing.absolute.s}px`,
                height    : '150px',
                width     : '100%',
              },
            }}
          />
        ) }
      </StyledFleetCard.Body>
    </CardContainer>
  )
})

const VehicleInfoBlock = ({
  t,
  vehicle,
}: {
  t: TFunction;
  vehicle: VehicleInfo;
}) => {
  // TODO: Timestamp display logic to be figured out for later milestone
  const showTimeStamp    = false
  const lastUpdatedLabel = t('last_updated', {
    num   : '?',
    units : 'hours',
  }).toUpperCase()
  const vars             = prepareVehicleInfo(vehicle, t)
  return (
    <CardContainer>
      <StyledFleetCard.Header>
        <header>{ t('info') }</header>
        { showTimeStamp && <TimeStampLabel>{ lastUpdatedLabel }</TimeStampLabel> }
      </StyledFleetCard.Header>
      <StyledFleetCard.Body style={{ padding: 0 }}>
        <FleetDisplayNumbers displayItems={vars} />
      </StyledFleetCard.Body>
    </CardContainer>
  )
}

const UpcomingVehicleBlocksContainer = ({ name }: { name: string }) => (
  <UpcomingBlocksContainer>
    <UpcomingVehicleBlocks vehicleName={name} />
  </UpcomingBlocksContainer>
)

const VehicleStatusPage = (): JSX.Element => {
  const { t } = useTranslation()

  const [chartDateRange, setChartDateRange] = useState<string>()
  const showPowerGraph                      = hasValue(vehicleStore.chargingSessionID)

  // TODO: Discuss timestamp scenarios with Bala/UX before turning this on
  // 1. Only historical data is shown in chart
  // 2. Both historical and future data is shown in chart
  // 3. Last datapoint in chart is as close to 'Now' as possible ( < 1m )
  const showTimeStamp = false

  const handleChartLoad = (chartData: TimeSeriesData[]) => {
    if (chartData?.length > 1) {
      const rangeStart = format(chartData[0].timestamp, ISO_DATE_TIME)
      const rangeEnd   = t('now')
      setChartDateRange(`${rangeStart} - ${rangeEnd}`)
    }
  }

  useEffect(() => {
    if (vehicleStore.initialized) {
      vehicleStore.fetchAlerts()
    }
  }, [vehicleStore.initialized])

  return (
    <Page
      title={t('page_titles.vehicle_status', { name: vehicleStore.vehicleName })}
    >
      { !vehicleStore.initialized && <StyledSpinner /> }
      { vehicleStore.initialized && (
        <Container>
          { renderAlerts(
            vehicleStore.alerts as Alert[],
            { ...vehicleStore.vehicleProps },
            t,
          ) }

          { vehicleStore.errors?.propSheetError && (
            <Notification
              type={NotificationLevel.ERROR}
              message={t(
                'vehicles.status.unable_to_load_session_error',
              )}
            />
          ) }
          <Content>
            <VehicleStatus t={t} />
            <GraphContainer>
              { showPowerGraph && vehicleStore.initialized && (
                <StyledFleetCard>
                  <StyledFleetCard.Header>
                    <span> { t('vehicles.power_graph_caption') }</span>
                    { showTimeStamp && (
                      <TimeStampLabel>{ chartDateRange }</TimeStampLabel>
                    ) }
                  </StyledFleetCard.Header>
                  <GraphStyledCardBody>
                    <VehiclePowerGraph
                      isAnimationActive={false}
                      chargingStatus={
                        vehicleStore.vehicleProps
                          .status_code as ChargingSessionStatus
                      }
                      sessionid={vehicleStore.chargingSessionID}
                      session={
                        vehicleStore.vehicleProps as unknown as DepotDetailsRow &
                        ActiveChargingSession
                      }
                      onLoad={handleChartLoad}
                    >
                      <KitDisplayNumbersWrapper>
                        <FleetDisplayNumbers
                          displayItems={prepareVehicleInfo(
                            vehicleStore.vehicleProps as VehicleInfo,
                            t,
                          )}
                        />
                      </KitDisplayNumbersWrapper>
                    </VehiclePowerGraph>
                  </GraphStyledCardBody>
                </StyledFleetCard>
              ) }
              { !showPowerGraph && (
                <VehicleInfoBlock
                  vehicle={vehicleStore.vehicleProps as VehicleInfo}
                  t={t}
                />
              ) }
              <UpcomingVehicleBlocksContainer
                name={vehicleStore.vehicle?.name as string}
              />
            </GraphContainer>
          </Content>
        </Container>
      ) }
    </Page>
  )
}

export default observer(VehicleStatusPage)
