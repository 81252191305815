import {
  KitButton,
  KitButtonBar,
  KitSpinner,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import FocusTrap from 'focus-trap-react'
import { type TFunction } from 'i18next'
import {
  type CSSProperties,
  type ElementType,
  type MouseEvent,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react'

import styled from 'styled-components'

import { focusTrapDelay } from '../../../common/utils/filters'
import { useLayout } from '../../../hooks/useLayout'
import { type FilterReducerState } from '@/common/reducers'
import { renderComponent } from '@/common/utils/componentHelpers'
import ErrorBoundary from '@/components/ErrorBoundary'
import { StyledFiltersPanel } from '@/components/Styled/filterBarStyles'
import { type Filter } from '@/types/filters'
import { type ComponentConfig } from '@/types/index'

const { breakpoints, spacing } = ThemeConstants

const FILTER_PANEL_BOTTOM_MARGIN = 50
export interface FiltersPanelProps extends PropsWithChildren {
  config: {
    filters?: Filter[];
    focusTrapDelay?: () => Promise<void>;
    componentMap: Record<string, ElementType>;
    state?: FilterReducerState;
  };
  t: TFunction;
  height?: number;
  width?: number;
  onCancel: () => void;
  onApply: () => void;
  ready: boolean;
}

export const FILTER_TOGGLE_BUTTON_ID = 'toggle-filter-panel'

const FilterItem = styled.div`
  padding: ${spacing.absolute.xs}px 0;
`

export const FilterItems = styled.div`
  @media all and (max-width: ${breakpoints.md}px) {
    overflow-y: auto;
    padding: ${spacing.absolute.m}px;
  }
  margin-bottom: ${spacing.absolute.m}px;
`

export const getFilterPanelStyle = (
  pageHeight: number,
  height?: number,
  width?: number,
  isMobile?: boolean,
) => {
  const style: CSSProperties = {}

  if (isMobile) {
    return style
  }
  const toggleFiltersButton = document.getElementById(FILTER_TOGGLE_BUTTON_ID)
  if (toggleFiltersButton) {
    style.maxHeight = `${
      pageHeight
      - (toggleFiltersButton.getBoundingClientRect().top
        + FILTER_PANEL_BOTTOM_MARGIN)
    }px`
    style.overflowY = 'scroll'
  }

  if (width) {
    style.width = width
  }
  if (height) {
    style.height = height
  }

  return style
}

const FiltersPanel = ({
  children,
  config,
  height,
  onApply,
  onCancel,
  ready = true,
  t,
  width,
}: FiltersPanelProps) => {
  const { componentMap, filters, state } = config
  const [pageHeight, setPageHeight]      = useState(0)
  const { isMobile }                     = useLayout()

  const resizeListener = () => {
    setPageHeight(window.innerHeight)
  }

  const handleClickCapture = (e: MouseEvent<HTMLDivElement>): void => {
    const targ = e.target as Element
    if (targ.getAttribute('data-testid') === 'modal-close') {
      onCancel()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeListener)
    resizeListener()
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return (
    <ErrorBoundary>
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick : true,
          checkCanFocusTrap : config.focusTrapDelay ?? focusTrapDelay(100),
        }}
      >
        <StyledFiltersPanel
          onClickCapture={handleClickCapture}
          style={getFilterPanelStyle(pageHeight, height, width, isMobile)}
        >
          { !ready && <KitSpinner align="middle" size="s" /> }
          { children }

          { ready && (
            <>
              <FilterItems>
                <div className="filter-components">
                  { Array.isArray(filters)
                    && filters.map((filt) => (
                      <FilterItem
                        style={filt.style as CSSProperties}
                        key={filt.props?.name}
                      >
                        { renderComponent<FilterReducerState, ComponentConfig>(
                          filt as ComponentConfig,
                          componentMap,
                          { state, otherProps: { t } },
                        ) }
                      </FilterItem>
                    )) }
                </div>
              </FilterItems>
              <KitButtonBar
                className="filterButtons"
                primary={<KitButton onClick={onApply}>{ t('apply') }</KitButton>}
                secondary={
                  <KitButton variant="secondary" onClick={onCancel}>
                    { t('btn_cancel') }
                  </KitButton>
                }
              />
            </>
          ) }
        </StyledFiltersPanel>
      </FocusTrap>
    </ErrorBoundary>
  )
}

export default FiltersPanel
