import { KitLink } from '@chargepoint/cp-toolkit'
import { type FC } from 'react'
import { Trans } from 'react-i18next'

import { type ErrorPageProps } from './helpers'
import { ErrorPageHeader, ErrorPageSubHeader } from './styles'
import { navigateTo } from '@/common/utils'

const Http500: FC<ErrorPageProps> = ({ children, t }) => (
  <>
    <ErrorPageHeader>{ t('errors.500.title') }</ErrorPageHeader>
    { children }
    <ErrorPageSubHeader>
      <Trans
        i18nKey="errors.500.subtitle"
        components={{ a: <KitLink onClick={() => navigateTo('support')} /> }}
      >
        If problem persists, please contact <a>Chargepoint Support</a>
      </Trans>
    </ErrorPageSubHeader>
  </>
)

export default Http500
