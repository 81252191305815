import {
  KitButton,
  KitButtonBar,
  KitButtonBarType,
  KitFlexCol,
  KitFlexColCentered,
  KitFlexRow,
  KitFlexRowSpaced,
  KitForm,
  KitIcon,
  KitInput,
  KitLink,
  KitSelect,
  type KitSelectOption,
  KitSpinner,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { toOptionsArray } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import MiniMap from '@/components/MiniMap'
import { List, ListItem } from '@/components/Styled'
import { type ZoneAlert } from '@/models/alertModel'
import { type DepotZone, type GeoFence } from '@/models/depotModel'
import { type TelematicsAccount } from '@/models/telematicsModel'
import { type TelematicZoneAlert } from '@/models/zoneAlertModel'
import TelematicsService from '@/services/TelematicsService'

const Spinner = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
`

const { fontSize } = ThemeConstants

interface TelematicAlertProps {
  onSave: (zoneAlert: Partial<ZoneAlert>) => void;
  onAdd: (zoneAlert: Partial<ZoneAlert>) => void;
  onCancel: () => void;
  existingAlerts: TelematicZoneAlert[];
  alert: TelematicZoneAlert | null;
}

const StyledForm = styled(KitForm)`
  font-size: ${fontSize.text_14}rem;
`

const StyledGroup = styled(KitForm.Group)`
  padding-bottom: ${ThemeConstants.spacing.absolute.m}px;
`

const MiniMapContainer = styled.div`
  padding: 0 0 0 ${ThemeConstants.spacing.absolute.m}px;
`

const Mins = styled(KitFlexColCentered)`
  padding-left: ${ThemeConstants.spacing.absolute.m}px;
  padding-right: ${ThemeConstants.spacing.absolute.m}px;
`

const AddAlertText = styled.div`
  padding-bottom: ${ThemeConstants.spacing.absolute.m}px;
`

const StyledKitButtonBar = styled(KitButtonBar)`
  padding-top: ${ThemeConstants.spacing.absolute.xxl}px;
  button {
    min-width: 90px;
  }
`

const ShortField = styled(KitFlexColCentered)`
  max-width: 140px;
`

const StyledAlert = styled.div`
  padding: ${ThemeConstants.spacing.absolute.sm}px 0;
`

const StyledHint = styled.div`
  color: ${ThemeColors.gray_50};
  padding-top: ${ThemeConstants.spacing.absolute.sm}px;
`

const Percentage = styled(KitFlexColCentered)`
  padding-left: ${ThemeConstants.spacing.absolute.m}px;
  padding-right: ${ThemeConstants.spacing.absolute.m}px;
`

const FlexColStyled = styled(KitFlexCol)`
  width: 50%;
`

const TelematicsZoneAlert = ({
  alert,
  existingAlerts,
  onAdd,
  onCancel,
  onSave,
}: TelematicAlertProps) => {
  const { t }                                                     = useTranslation()
  const [zones, setZones]                                         = useState<DepotZone[]>([])
  const emptyOption: KitSelectOption                              = { value: null, label: t('select') }
  const [selectedZone, setSelectedZone]                           = useState<KitSelectOption>(emptyOption)
  const [selectedAccount, setSelectedAccount]                     = useState<KitSelectOption>(emptyOption)
  const [isNew, setIsNew]                                         = useState(true)
  const [geofence, setGeofence]                                   = useState<GeoFence>([])
  const [hasZones, setHasZones]                                   = useState(!!alert)
  const [zoneName, setZoneName]                                   = useState('')
  const [timeBeforeAlertingMinutes, setTimeBeforeAlertingMinutes] = useState<
  number | null
  >()
  const [minAlertedSoc, setMinAlertedSoc]                         = useState<number | null>()
  const [showMinAlertedSOC, setShowMinAlertedSOC]                 = useState(
    hasValue(alert?.min_alerted_soc),
  )
  const [showSpinner, setShowSpinner]                             = useState(false)
  const [allTelematicsAccounts, setAllTelematicsAccounts]         = useState<
  TelematicsAccount[]
  >([])

  const getZoneAlert = () => ({
    zone_id   : selectedZone.value,
    zone_name : zoneName,
    time_before_alerting_minutes:
        timeBeforeAlertingMinutes
        && parseInt(timeBeforeAlertingMinutes as unknown as string),
    min_alerted_soc:
        minAlertedSoc && parseInt(minAlertedSoc as unknown as string),
  } as Partial<ZoneAlert>)

  const save = async () => {
    onSave({
      id: alert?.id as number,
      ...getZoneAlert(),
    })
  }

  const add = async () => {
    onAdd(getZoneAlert())
  }

  const reset = () => {
    setTimeBeforeAlertingMinutes(null)
    setMinAlertedSoc(null)
    setZoneName('')
    setSelectedZone(emptyOption)
  }

  const handleZoneChange = (zone: unknown) => {
    setSelectedZone(zone as KitSelectOption)
  }

  const handleAccountChange = (account: unknown) => {
    setSelectedAccount(account as KitSelectOption)
  }

  const cancel = () => {
    reset()
    onCancel()
  }

  const clear = () => {
    setMinAlertedSoc(null)
    setShowMinAlertedSOC(false)
  }

  const handleFieldChange = (
    field: string,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    if (field === 'notPluggedInFor') {
      setTimeBeforeAlertingMinutes(parseFloat(e.target.value))
    }
    if (field === 'stateOfCharge') {
      setMinAlertedSoc(parseFloat(e.target.value) || null)
    }
  }

  useEffect(() => {
    (() => {
      if (selectedAccount.value) {
        TelematicsService.getTelematicsZones(selectedAccount.value).then(
          (zones) => {
            const existingAlertsIds: number[] = existingAlerts.map(
              ({ zone_id }) => zone_id,
            )
            const filteredZones               = zones.filter(
              ({ id }: { id: number }) => !existingAlertsIds.includes(id),
            )
            setZones(filteredZones as unknown[] as DepotZone[])
            setHasZones(!!filteredZones.length)
          },
        )
      }
    })()
    return () => reset()
  }, [selectedAccount.value])

  useEffect(() => {
    if (zones.length && selectedZone.value) {
      const zone = (zones || []).find(
        ({ id }: { id: number }) => selectedZone.value === id,
      ) as DepotZone
      setGeofence(zone?.geofence ?? [])
      setSelectedZone({ label: zone.name, value: zone.id })
      setZoneName(zone?.name)
    }
  }, [selectedZone.value, zones])

  useEffect(() => {
    const getZones = async () => {
      if (alert) {
        const response         = await TelematicsService.getAllTelematicsZones()
        const selectedGeofence =          response.find((zone) => zone.id === alert.zone_id)?.geofence ?? []
        const {
          min_alerted_soc,
          time_before_alerting_minutes,
          zone_id,
          zone_name,
        } = alert
        setTimeBeforeAlertingMinutes(time_before_alerting_minutes)
        setMinAlertedSoc(min_alerted_soc)
        setZoneName(zone_name)
        setSelectedZone({
          value : zone_id,
          label : zone_name,
        })
        setGeofence(selectedGeofence)
        setIsNew(false)
      } else {
        setShowSpinner(true)
        const response = await TelematicsService.getTelematicsAccounts()
        if (!response.error) {
          setAllTelematicsAccounts(response.results as TelematicsAccount[])
        }
        setShowSpinner(false)
      }
    }

    getZones()
  }, [])

  const timeBeforeAlertingIsNegative =    !!timeBeforeAlertingMinutes && timeBeforeAlertingMinutes < 1
  const saveIsDisabled               = !selectedZone.value
    || !timeBeforeAlertingMinutes
    || timeBeforeAlertingIsNegative

  return (
    <StyledForm>
      { showSpinner && (
        <Spinner>
          <KitSpinner size="s" />
        </Spinner>
      ) }
      <AddAlertText>{ t('telematicAlert.addAlertText') }</AddAlertText>
      <KitFlexRowSpaced>
        <FlexColStyled>
          { !alert && (
            <StyledGroup>
              <KitForm.Label
                htmlFor="telematics_account"
                text={t('telematicAlert.telematicAccount')}
                required
              />
              <KitSelect
                name="telematics_account"
                id="telematics_account"
                value={selectedAccount}
                defaultValue={selectedAccount}
                onChange={handleAccountChange}
                isDisabled={!isNew || !allTelematicsAccounts.length}
                options={toOptionsArray(allTelematicsAccounts, {
                  labelField : 'name',
                  valueField : 'id',
                })}
              />
            </StyledGroup>
          ) }
          { hasZones && (
            <>
              <StyledGroup>
                <KitForm.Label
                  htmlFor="telematics_zone"
                  text={t('telematicAlert.locatedInZone')}
                  required
                />
                <KitSelect
                  name="telematics_zone"
                  id="telematics_zone"
                  value={selectedZone}
                  defaultValue={selectedZone}
                  onChange={handleZoneChange}
                  isDisabled={!isNew}
                  options={toOptionsArray(zones, {
                    labelField : 'name',
                    valueField : 'id',
                  })}
                />
              </StyledGroup>
              <KitFlexRow>
                <StyledAlert>{ t('telematicAlert.alertTriggers') }</StyledAlert>
              </KitFlexRow>
              <StyledGroup>
                <KitForm.Label
                  htmlFor="notPluggedInFor"
                  text={t('telematicAlert.notPluggedInFor')}
                  required
                />
                <KitFlexRow>
                  <ShortField>
                    <KitInput
                      id="notPluggedInFor"
                      name="notPluggedInFor"
                      required
                      type="number"
                      value={timeBeforeAlertingMinutes ?? ''}
                      isError={timeBeforeAlertingIsNegative}
                      infoMessage={
                        timeBeforeAlertingIsNegative
                          ? t('telematicAlert.numberGreaterThanError', { number: 0 })
                          : ''
                      }
                      onChange={(e) => handleFieldChange(
                        'notPluggedInFor',
                        e as ChangeEvent<HTMLInputElement>,
                      )
                      }
                    />
                  </ShortField>
                  <Mins>{ t('telematicAlert.minutes') }</Mins>
                </KitFlexRow>
              </StyledGroup>
            </>
          ) }
        </FlexColStyled>
        <FlexColStyled>
          <KitFlexRow>
            { zones && geofence.length ? (
              <MiniMapContainer>
                <MiniMap
                  polygons={[
                    (geofence || []).map((coords: [number, number]) => ({
                      lat : coords[1],
                      lng : coords[0],
                    })),
                  ]}
                  zoom={15}
                  opts={{
                    markerProps    : {},
                    containerStyle : {
                      height       : '110px',
                      width        : '165px',
                      marginBottom : `${ThemeConstants.spacing.absolute.s}px`,
                    },
                  }}
                />
              </MiniMapContainer>
            ) : null }
          </KitFlexRow>
        </FlexColStyled>
      </KitFlexRowSpaced>
      { hasZones && showMinAlertedSOC && (
        <>
          <StyledGroup>
            <KitForm.Label
              htmlFor="stateOfCharge"
              text={t('telematicAlert.batteryLevel')}
            />
            <KitFlexRow>
              <ShortField>
                <KitInput
                  name="stateOfCharge"
                  id="stateOfCharge"
                  type="number"
                  value={minAlertedSoc ?? ''}
                  onChange={(e) => handleFieldChange(
                    'stateOfCharge',
                    e as ChangeEvent<HTMLInputElement>,
                  )
                  }
                />
              </ShortField>
              <Percentage> % </Percentage>
              <KitFlexColCentered>
                <KitButton data-id="close" variant="circle" onClick={clear}>
                  <KitIcon
                    icon="close"
                    size={`${ThemeConstants.iconSize.xs}rem`}
                    fill={ThemeColors.gray_50}
                  />
                </KitButton>
              </KitFlexColCentered>
            </KitFlexRow>
            <KitFlexRow>
              <StyledHint>{ t('telematicAlert.hint') }</StyledHint>
            </KitFlexRow>
          </StyledGroup>
        </>
      ) }
      { hasZones && !showMinAlertedSOC && (
        <KitLink onClick={() => setShowMinAlertedSOC(true)}>
          { t('telematicAlert.addStateOfCharge') }
        </KitLink>
      ) }
      { !hasZones && !!selectedAccount.value && (
        <List>
          <ListItem>
            { t('telematicAlert.noZonesFoundForAccount', { telematicsAccount: selectedAccount?.label }) }
          </ListItem>
          <ListItem>
            { t('telematicAlert.noZonesFoundForAccountDetail') }
          </ListItem>
        </List>
      ) }
      <StyledKitButtonBar
        type={KitButtonBarType.default}
        primary={
          <KitButton disabled={saveIsDisabled} onClick={isNew ? add : save}>
            { t('btn_save') }
          </KitButton>
        }
        secondary={
          <KitButton variant="secondary" onClick={cancel}>
            { t('btn_cancel') }
          </KitButton>
        }
      />
    </StyledForm>
  )
}

export default TelematicsZoneAlert
