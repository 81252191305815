import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import utils, { paramsToQueryString } from '@/common/utils'
import { type TimeSeriesResponse } from '@/models/chartModel'
import type { PreconditioningState } from '@/models/preconditioningModel'
import {
  type BaseServiceResponse,
  type PagedServiceResponse,
  type ServicePayload,
  type ServiceResponse,
} from '@/models/serviceModel'
import {
  type ActiveChargingSession,
  type ChargingHistory,
  type MergeVehicleRequest,
  type Vehicle,
  type VehicleMake,
  type VehicleModel,
  type VehiclePropSheet,
  type VehicleSettings,
  type VehicleYear,
} from '@/models/vehicleModel'
import { type VehicleTripHistoryRow } from '@/pages/VehiclePropSheet/routes/TripHistory/types'

export type VehicleServiceResponse = Vehicle & BaseServiceResponse;

const createService = (api: ServiceInterface) => ({
  addVehicle(payload: ServicePayload) {
    return wrapRequest<ServiceResponse<Vehicle>>(
      api.post('/api/add-vehicle/', payload),
    )
  },
  getVehicle(vehicleId: number | string) {
    return wrapRequest<ServiceResponse<Vehicle>>(
      api.get(`/api/vehicle/${vehicleId}/`),
    )
  },

  setManagedCharging(vehicleId: number) {
    return wrapRequest<BaseServiceResponse>(
      api.get(`/api/vehicle/${vehicleId}/managed-charging`),
    )
  },
  setUnmanagedCharging(vehicleId: number) {
    return wrapRequest<BaseServiceResponse>(
      api.get(`/api/vehicle/${vehicleId}/unmanaged-charging`),
    )
  },
  getMakes() {
    const qs    = paramsToQueryString({ limit: 1000 })
    const cache = 'default'
    return wrapRequest<PagedServiceResponse<VehicleMake>>(
      api.get(`/api/vehicle-make${qs}`, {}, { cache }),
    )
  },
  getModels(makeId: number) {
    return wrapRequest<PagedServiceResponse<VehicleModel>>(
      api.get(
        `/api/vehicle-make/${makeId}/model/${paramsToQueryString({ limit: 150 })}`,
      ),
    )
  },
  getYears(modelId: number) {
    return wrapRequest<PagedServiceResponse<VehicleYear>>(
      api.get(
        `/api/vehicle-model/${modelId}/model-year/${paramsToQueryString({ limit: 100 })}`,
      ),
    )
  },

  getVehicleSettings(vehicleId: string) {
    return wrapRequest<BaseServiceResponse & VehicleSettings>(
      api.get(`/api/vehicle/${vehicleId}/settings/`),
    )
  },
  getVehiclePropSheetDetails(vehicleId: string) {
    return wrapRequest<ServiceResponse<VehiclePropSheet>>(
      api.get(`/api/vehicle/${vehicleId}/prop-sheet-details/`),
    )
  },
  getChargingHistory(
    vehicleId: string,
    query = {},
    cache?: boolean | { cache: string },
  ): Promise<PagedServiceResponse<ChargingHistory>> {
    const qString = utils.paramsToQueryString(query)
    return wrapRequest(
      api.get(
        `/api/vehicle/${vehicleId}/charging-history/${qString}`,
        undefined,
        cache ? { cache: 'default' } : {},
      ),
    ) as Promise<PagedServiceResponse<ChargingHistory>>
  },
  getTripHistory(vehicleId: number, query = {}) {
    const qString = utils.paramsToQueryString(query)
    return wrapRequest<PagedServiceResponse<VehicleTripHistoryRow>>(
      api.get(`/api/vehicle/${vehicleId}/trip/${qString}`),
    )
  },
  updateVehicleSettings(
    vehicleId: string,
    setting: string,
    payload: ServicePayload,
  ) {
    const endpoint = `/api/vehicle/${vehicleId}/settings/${setting}`
    if (['identification'].includes(setting)) {
      return wrapRequest<BaseServiceResponse>(
        api.post(`${endpoint}/`, payload),
      )
    }
    return wrapRequest<BaseServiceResponse>(
      api.patch(`${endpoint}/edit/`, payload),
    )
  },
  deleteVehicleSchedule(vehicleId: string) {
    return wrapRequest(
      api.delete(`/api/vehicle/${vehicleId}/settings/schedule/delete/`),
    )
  },
  listActiveSessionsByPort(portId: string) {
    const endpoint = `/api/port/${portId}/active-session`
    return wrapRequest<ServiceResponse<ActiveChargingSession[]>>(
      api.get(endpoint),
    )
  },
  listActiveUnknownVehicleSessions() {
    return wrapRequest<ServiceResponse<ActiveChargingSession>>(
      api.get('/api/active-session-unknown-vehicles/'),
    )
  },
  mergeVehicle(request: MergeVehicleRequest) {
    return wrapRequest(
      api.post('/api/merge-vehicle/', request as ServicePayload),
    )
  },
  getChargingSessionPower(
    sessionID: string,
    depot_external_id?: string,
    cache = 'default',
  ) {
    const qs = paramsToQueryString({ depot_id: depot_external_id })
    return wrapRequest<TimeSeriesResponse>(
      api.get(`/api/charging-session-data/${sessionID}/${qs}`, undefined, { cache }),
    )
  },
  getPreconditioningSettings(vehicle_id: string) {
    return wrapRequest<PreconditioningState & BaseServiceResponse>(api.get(`/api/vehicle/${vehicle_id}/settings/preconditioning/`))
  },
  updatePreconditioningSettings(vehicle_id: string, payload: ServicePayload) {
    return wrapRequest<PreconditioningState>(api.put(`/api/vehicle/${vehicle_id}/settings/preconditioning/edit/`, payload))
  },
})

export default createService(AbstractService())
