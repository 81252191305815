import { KitUtilData } from '@chargepoint/cp-toolkit'

import { ErrorCodes } from '@/common/constants'
import { getConstant } from '@/common/utils'
import { hasValue } from '@/common/utils/validations'
import { type ServiceError } from '@/models/serviceModel'

export async function envCheck(): Promise<{
  success: boolean;
  error?: ServiceError;
}> {
  return new Promise((resolve) => {
    // check that environment values were injected by build process
    const environmentVariablesLoaded = (() => {
      const varsLoaded = !KitUtilData.isEmpty(window.runtime)
      if (varsLoaded) {
        // spot check: make sure sso host var exists
        return hasValue(getConstant('SSO_HOST'))
      }
      return false
    })()

    if (!environmentVariablesLoaded) {
      resolve({
        success : false,
        error   : {
          code    : ErrorCodes.EnvironmentNotInitialized,
          message : 'environment not initialized properly',
        },
      })
    } else {
      resolve({ success: true })
    }
  })
}
