import { KitFlexRow, KitFlexRowSpaced, KitLink } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { LinkIcon, SettingsContainer } from './index.styles'
import { getProfileURL } from '@/common/utils'
import { hasValue, isValid } from '@/common/utils/validations'
import externalLinkIcon from '@/components/FleetIcon/assets/external_link.svg'
import Notification, { NotificationLevel } from '@/components/Notification'

const NotificationContactBar = ({
  email,
  phone,
  t,
}: {
  phone: string;
  email: string;
  t: TFunction;
}) => {
  const validPhone          = isValid('phone', phone)
  const invalidPhoneMessage = (() => {
    if (!validPhone) {
      return !hasValue(phone)
        ? t('fleet_settings.notifications.no_phone_number_found')
        : t('fleet_settings.notifications.invalid_phone_number')
    }
    return ''
  })()

  return (
    <>
      <SettingsContainer>
        <KitFlexRowSpaced className="contact-info-wrapper">
          <KitFlexRow className="contact-info">
            <span>
              <span className="contact-title">
                { t('fleet_settings.notifications.sent_to') }
              </span>
            </span>
            <address className="content">
              <span data-qa-id="notification-email">{ email }</span>
              { hasValue(phone) && (
                <span data-qa-id="notification-phone">{ phone }</span>
              ) }
            </address>
          </KitFlexRow>
          <span className="profile-link">
            <Trans
              i18nKey="fleet_settings.notifications.contact_settings_changed_in_profile"
              components={{ btn: <KitLink href={getProfileURL()} /> }}
            >
              Contact settings can be modified in
              { /* @ts-expect-error ---*/ }
              <btn>your profile</btn>
            </Trans>
            <LinkIcon src={externalLinkIcon} alt="" />
          </span>
        </KitFlexRowSpaced>
      </SettingsContainer>

      { !validPhone && (
        <Notification type={NotificationLevel.WARNING}>
          <div className="invalid-phone-block" aria-live={'polite'}>
            <span className="warning">{ invalidPhoneMessage }</span>
            <span>
              { t('fleet_settings.notifications.valid_phone_required') }
            </span>
          </div>
        </Notification>
      ) }
    </>
  )
}

export default NotificationContactBar
