import {
  KitExpandableRow,
  KitTable,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import ChargingSessionDetails from './ChargingSessionDetails'
import { buildChargingHistoryQuery, getColumns } from './utils'
import {
  CACHE_FETCH_OPTIONS,
  ChargingSessionStatus,
  DistanceUnits,
  EMPTY_VALUE_PLACEHOLDER,
  defaultPaginationProps,
} from '@/common/constants'

import { diff } from '@/common/utils/data'
import { getUserPreferences } from '@/common/utils/user'
import ExtendedPagination from '@/components/ExtendedPagination'
import NoResults from '@/components/NoResults'
import Page from '@/components/Page'
import {
  FullWidthDiv,
  KitTableWrapper,
  PaginationWrapper,
  TableCell,
  TableHeaderCell,
} from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import { type ServiceError } from '@/models/serviceModel'
import { type ChargingHistory as ChargingHistoryModel } from '@/models/vehicleModel'
import vehicleStore from '@/pages/VehiclePropSheet/vehicleStore'
import DepotService from '@/services/DepotService'
import VehicleService from '@/services/VehicleService'
import { type ColumnDef } from '@/types/index'

export interface ChargingHistoryProps {
  vehicleId: number;
  filters: FilterProps | { period: ''; start: ''; end: ''; station_name: '' };
  enableFilters: (enable: boolean) => void;
  setMatches: (numMatches: number) => void;
}

export interface FilterProps {
  start: string;
  end: string;
  period: string;
  station_name: string;
}

const CustomTable = styled(KitTableWrapper)`
  table {
    tbody {
      tr {
        td {
          &:first-child[colspan='9'] {
            padding-left: ${ThemeConstants.spacing.absolute.xxs}px;
          }
          &:last-child {
            min-width: 60px;
            button {
              margin-left: ${ThemeConstants.spacing.absolute.xs}px;
              margin-right: 0;
            }
          }
          strong {
            font-size: ${ThemeConstants.fontSize.base}em;
            color: ${ThemeColors.gray_70};
          }
        }
      }
    }
  }
`

const userPreferredUnit =  getUserPreferences().length_units ?? DistanceUnits.MILES

const renderTableHeading = (header: string, t: TFunction) => {
  if (header === 'range_added_miles') {
    return t(`charging_history.${header}`, { units: t(`units.distance.${userPreferredUnit}.short`) })
  }
  return t(`charging_history.${header}`)
}

// if the session is for a currently running session, then use the current vehicle status code
export const getChargingStatus = (item: ChargingHistoryModel): ChargingSessionStatus => {
  if (vehicleStore.vehicleProps?.session_id === item.session_id) {
    return vehicleStore.vehicleProps.status_code as ChargingSessionStatus
  }
  return ChargingSessionStatus.Charging
}

const renderCells = (
  chargingHistory: ChargingHistoryModel[],
  columns: ColumnDef[],
  t: TFunction,
  onToggle: (session_id: string, expanded: boolean) => void,
  expandedRows: string[],
) => (
  <>
    { chargingHistory.length !== 0
        && chargingHistory.map((item, idx) => (
          <KitExpandableRow
            ariaProps={{ 'aria-label': t('charging_session_details') }}
            id={item.session_id}
            hasToggle={true}
            key={`expandable-${idx}`}
            colCount={columns.length}
            onToggle={(expanded) => onToggle(item.session_id, expanded)}
            additionalInfo={
              expandedRows.includes(item.session_id) ? (
                <ChargingSessionDetails chargingSession={item} chargingStatus={getChargingStatus(item)} />
              ) : null
            }
          >
            { columns.map((col) => (
              <TableCell key={`${col.field}-${idx}`} align={col.align}>
                { col.formatFn
                  ? col?.formatFn(item[col.field as keyof typeof item], item)
                  : EMPTY_VALUE_PLACEHOLDER }
              </TableCell>
            )) }
          </KitExpandableRow>
        )) }
    { chargingHistory.length === 0 && (
      <tr>
        <td colSpan={columns.length + 1} style={{ padding: '20px' }}>
          { t('no_results') }
        </td>
      </tr>
    ) }
  </>
)

const ChargingHistory = () => {
  const { t }                                 = useTranslation()
  const columns: ColumnDef[]                  = getColumns(t)
  const [sortKey, setSortKey]                 = useState('')
  const [sortBy, setSortBy]                   = useState({})
  const [isLoaded, setIsLoaded]               = useState<boolean>(false)
  const [chargingHistory, setChargingHistory] = useState<
  ChargingHistoryModel[]
  >([])

  const [paginationProps, setPaginationProps] = useState(
    defaultPaginationProps,
  )
  const [expandedRows, setExpandedRows]       = useState<string[]>([])
  const [serviceError, setServiceError]       = useState<ServiceError>()

  const { currentPage, pageSize, totalPages, totalRecords } = paginationProps

  const chargingHistoryTimeoutID = useRef<ReturnType<typeof setTimeout>>()

  const showPagination = totalRecords > 10

  const onPageChanged = (page: number) => {
    setPaginationProps({ ...paginationProps, currentPage: page })
  }

  const onPageSizeChanged = (size: number) => {
    setPaginationProps({ ...paginationProps, pageSize: size })
  }

  const onToggle = (sessionId: string, expanded: boolean) => {
    if (sessionId) {
      const expandedRowChanges = expanded
        ? expandedRows.concat([sessionId])
        : expandedRows.filter((id) => id !== sessionId)

      if (diff(expandedRowChanges, expandedRows).length > 0) {
        setExpandedRows(expandedRowChanges)
      }
      setIsLoaded(true)
    }
  }

  const onSortClicked = (key: keyof ChargingHistoryModel, dir: number) => {
    if (dir !== null) {
      setSortBy({ ordering: `${dir === 0 ? `-${key}` : key}` })
    }
    setSortKey(key)
  }

  const fetchData = async () => {
    const offset = pageSize * (Number(currentPage) - 1)

    const queryFilters = buildChargingHistoryQuery(vehicleStore.filters)
    const response     = await VehicleService.getChargingHistory(
      vehicleStore.id as string,
      {
        ...queryFilters,
        limit: pageSize,
        offset,
        ...sortBy,
      },
      CACHE_FETCH_OPTIONS,
    )

    if (!response.error) {
      const depotResponse       = await DepotService.getDepots(CACHE_FETCH_OPTIONS)
      const chargingHistoryRows = response?.results.map((row) => {
        const matchingDepot = depotResponse.results.find(
          (depot) => depot.external_id === row.depot_id,
        )
        return { ...row, timeZone: matchingDepot?.timezone, depot_external_id: matchingDepot?.external_id }
      })

      setPaginationProps({
        ...paginationProps,
        totalPages   : Math.ceil(response.count / pageSize),
        totalRecords : response.count,
      })
      setChargingHistory(chargingHistoryRows)
      // setMatches(response.count);
      // enableFilters(true);
    } else {
      setServiceError(response.error)
    }

    setIsLoaded(true)
  }

  // TODO: update filtering to be consistent with other filtering patterns
  useEffect(() => {
    if (vehicleStore.initialized) {
      clearTimeout(chargingHistoryTimeoutID.current)
      chargingHistoryTimeoutID.current = setTimeout(() => {
        fetchData()
      }, 500)
    } else {
      setChargingHistory([])
    }

    return () => clearTimeout(chargingHistoryTimeoutID.current)
  }, [currentPage, pageSize, sortBy, sortKey, vehicleStore.initialized])

  useEffect(() => {
    if (vehicleStore.initialized) {
      setPaginationProps({ ...paginationProps, currentPage: 1 })
      fetchData()
    }
  }, [vehicleStore.filters, pageSize])

  return (
    <Page
      title={t('page_titles.vehicle_charging_history', { name: vehicleStore.vehicleName })}
    >
      <FullWidthDiv>
        { !isLoaded && <StyledSpinner /> }
        { serviceError && (
          <NoResults
            message={t('charging_history.charging_history_request_error')}
          />
        ) }
        { isLoaded && !serviceError && (
          <>
            <CustomTable>
              <KitTable>
                <thead>
                  <tr>
                    { columns.map((col) => (
                      <TableHeaderCell
                        align={col.align}
                        sortable
                        key={col.field}
                        active={sortKey === col.field}
                        text={renderTableHeading(col.field, t)}
                        onSortClicked={(sortDir) => onSortClicked(
                          col.field as keyof ChargingHistoryModel,
                          sortDir,
                        )
                        }
                      />
                    )) }
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { renderCells(
                    chargingHistory,
                    columns,
                    t,
                    onToggle,
                    expandedRows,
                  ) }
                </tbody>
              </KitTable>
            </CustomTable>
            { showPagination && (
              <PaginationWrapper>
                <ExtendedPagination
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalPages={totalPages}
                  totalRecords={totalRecords}
                  onPageChanged={onPageChanged}
                  onPageSizeChange={onPageSizeChanged}
                  t={t}
                />
              </PaginationWrapper>
            ) }
          </>
        ) }
      </FullWidthDiv>
    </Page>
  )
}

export default observer(ChargingHistory)
