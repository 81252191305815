import { KitForm, KitSwitch, KitUtilData } from '@chargepoint/cp-toolkit'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditMode } from '@/common/constants'
import { ReadOnly } from '@/components/common/preconditioning/ReadOnlySettings'
import EditPanel from '@/components/EditPanel'
import Notification, { NotificationLevel } from '@/components/Notification'
import { CardErrorContainer } from '@/components/Styled'
import type { PreconditioningState } from '@/models/preconditioningModel'
import type { BaseServiceResponse, ServiceError } from '@/models/serviceModel'
import VehicleService from '@/services/VehicleService'

const PreconditioningPreferencesCard = ({ id }: { id: string }) => {
  // determine preconditioning payload
  const [preconditioningState, setPreconditioningState] = useState<PreconditioningState>()
  const [serviceError, setServiceError]                 = useState<ServiceError | undefined>()
  const [inEditMode, setInEditMode]                     = useState(false)
  const [isLoading, setIsLoading]                       = useState(false)
  const { t }                                           = useTranslation()

  const reset = () => {
    setServiceError(undefined)
    setPreconditioningState(preconditioningState)
  }

  const updateSettings = async (payload: PreconditioningState) => {
    const response = await VehicleService.updatePreconditioningSettings(id, payload) as BaseServiceResponse
    if (response.error) {
      setServiceError(response.error)
    } else {
      handleCancel()
    }

    setIsLoading(false)
  }

  const handleCancel = () => {
    setInEditMode(false)
    reset()
  }

  const handleSubmit = () => {
    if (preconditioningState) {
      setIsLoading(true)
      updateSettings(preconditioningState)
    }
  }

  const onFieldChange = (fieldName: string, fieldValue: string | boolean) => {
    setPreconditioningState({ ...preconditioningState, [fieldName]: fieldValue } as PreconditioningState)
  }

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true)
      const response = await VehicleService.getPreconditioningSettings(id)
      if (response.error) {
        setServiceError(response.error)
      } else {
        setPreconditioningState(response)
      }
      setIsLoading(false)
    }

    fetchSettings()
  }, [id])

  return <EditPanel
    id="preconditioning"
    t={t}
    isLoading={isLoading}
    readOnly={true}
    title={t('vehicles.settings.preconditioning.title')}
    mode={EditMode.READ_ONLY}
    onSubmit={handleSubmit}
    onCancel={handleCancel}
    onToggle={() => {
      setInEditMode(!inEditMode)
    }}
  >
    { inEditMode ? <>
      { serviceError && <CardErrorContainer><Notification message={serviceError.message} type={NotificationLevel.ERROR} /></CardErrorContainer> }
      <KitForm>
        <KitForm.Group>
          <KitSwitch
            checked={preconditioningState?.is_preconditioning_enabled}
            label={t('vehicles.settings.preconditioning.label')}
            onChange={(e) => onFieldChange('is_preconditioning_enabled', KitUtilData.toBoolean(e.target?.checked))}>
          </KitSwitch>
        </KitForm.Group>
      </KitForm>
    </> : preconditioningState
    && (
      <ReadOnly
        enabled={preconditioningState.is_preconditioning_enabled}
        strings={
          {
            on    : t('on'),
            off   : t('off'),
            label : t('fleets.settings.preconditioning.label'),
          }
        }
      />)
    }
  </EditPanel>
}

export default PreconditioningPreferencesCard
