const ChargingStation = (props = {}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M30 6C30 3.79086 28.3145 2 26.2353 2H17.7647C15.6855 2 14 3.79086 14 6V21.3031C11.2056 22.2788 9.24632 22.2108 8.05793 21.229C7.10638 20.443 6.92135 18.59 7.66396 15.648L7.8309 15.0237C7.92357 14.6948 8.02686 14.3536 8.14096 14H9C10.6569 14 12 12.6569 12 11V8V6H10V3H8V6H6V3H4V6H2V8V11C2 12.6569 3.34315 14 5 14H6.04612C4.75417 18.2992 4.92295 21.2334 6.78417 22.771C8.4916 24.1814 10.9147 24.3576 14 23.4077V26C14 28.2091 15.6855 30 17.7647 30H26.2353C28.3145 30 30 28.2091 30 26V6ZM4 8H10V11L9.99327 11.1166C9.93551 11.614 9.51284 12 9 12H5L4.88338 11.9933C4.38604 11.9355 4 11.5128 4 11V8ZM17.8462 4H26.1538L26.2916 4.00549C27.2469 4.08183 28 4.94564 28 6V26L27.9949 26.1493C27.9245 27.1841 27.1271 28 26.1538 28H17.8462L17.7084 27.9945C16.7531 27.9182 16 27.0544 16 26V6L16.0051 5.85074C16.0755 4.81588 16.8729 4 17.8462 4ZM21.391 10.6063L21.3802 10.6263L19.2869 14.0053L19.2085 14.1439C18.7285 15.0783 19.1003 16.2119 20.0867 16.7352C20.414 16.9089 20.7848 17.0003 21.1622 17.0003H22.8382L20.674 20.5041L20.6178 20.6087C20.4053 21.0692 20.5949 21.6141 21.0762 21.8685C21.5945 22.1425 22.2548 21.9759 22.551 21.4964L24.7152 17.9925L24.8011 17.8383C24.9318 17.5762 25 17.2903 25 17.0003C25 15.8957 24.0321 15.0003 22.8382 15.0003H21.1622L23.2892 11.5691L23.345 11.4655L23.3781 11.3942L23.421 11.2843C23.5754 10.8045 23.3198 10.2831 22.8103 10.0811C22.2616 9.86356 21.6262 10.0987 21.391 10.6063Z"
    />
  </svg>
)

export default ChargingStation
