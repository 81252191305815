import { format } from 'date-fns'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import set from 'lodash/set'
import { initReactI18next } from 'react-i18next'

import utils, { getAppVersion, isProd } from './common/utils'
import { ISO_DATE, ISO_DATE_TIME } from '@/common/constants'
import { getLocaleCode, parseLocale } from '@/common/lang'
import { has } from '@/common/utils/data'

const dateFnsLocaleMap = {
  'en-US' : 'en-US',
  'en-GB' : 'en-GB',
  'fr-CA' : 'fr-CA',
}

// NOTES:
// Setup to fetch locally via public locales folder using HttpBackend and then cache in local storage.
// Subsequent loads will look in local storage cache first with a fallback to HttpBackend.

// Setting a smaller expiration time for non production which will force a fetch from HttpBackend and update local storage.
const localStorageExpirationTime = !isProd() ? 5 * 1000 : 2 * 60 * 60 * 1000 // hot reload or 2 hr cache

const ts = format(new Date(), 'yyyyMMdd_HHmm')

let showDebugLogs = false
if (utils.getConstant('DEBUG_I18N') === 'true' && !isProd()) {
  showDebugLogs = true
}

export async function getVersion() {
  const d  = new Date()
  const ds = format(d, ISO_DATE_TIME)
  return new Promise(async (resolve) => {
    try {
      resolve(`${getAppVersion()}-${format(d, ISO_DATE)}`)
    } catch (err) {
      resolve(ds)
    }
  })
}

(async () => {
  const defaultLanguageVersion = await getVersion()
  if (!i18n.isInitialized) {
    i18n
      .use(ChainedBackend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(
        {
          debug       : showDebugLogs,
          load        : 'currentOnly',
          ns          : ['kit', 'translation'],
          fallbackLng : 'en-US',
          backend     : {
            backends: [
              LocalStorageBackend, // primary
              HttpBackend, // fallback
            ],
            backendOptions: [
              {
                // prefix used to cache translations in localstorage.
                prefix         : `i18next_fleet:${getAppVersion()}:`,
                expirationTime : localStorageExpirationTime,
                defaultVersion : defaultLanguageVersion,
              },
              { loadPath: `/locales/{{lng}}/{{ns}}.json?v=${ts}` },
            ],
          },
        },
        (err) => {
          if (showDebugLogs && err) {
            console.error('error initializing i18n', err)
          } else {
            const loc = getLocaleCode()
            try {
              const dateFNSLocale = (() => {
                if (has(dateFnsLocaleMap, loc)) {
                  return dateFnsLocaleMap[loc]
                }
                return parseLocale(loc).langCode?.toLowerCase()
              })()
              // dynamically load date-fns locale file based on user locale
              const importLocale = async () => {
                const dateFNSLocaleObj = await import(
                  `date-fns/esm/locale/${dateFNSLocale}/index.js`
                )
                set(window, 'dateFNSLocale', dateFNSLocaleObj.default)
              }
              importLocale()
            } catch (err) {
              console.log('error loading date-fns locale for ', loc)
            }
          }
        },
      )
  }
})()

export default i18n
