import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit/'
import styled from 'styled-components'

import { UILayout } from '@/common/constants'
import { List } from '@/components/Styled'

const { breakpoints, fontSize, fontWeight, spacing } = ThemeConstants

export const LinkIcon = styled.img`
  margin: 0 ${spacing.absolute.xs}px;
  height: 16px;
  width: 16px;
  opacity: 0.5;
`

export const ListItemContent = styled.div`
  max-width: 650px;
  padding: ${spacing.s}rem;
`

export const NotificationFieldSet = styled.fieldset`
  padding: ${spacing.absolute.s}px;

  &:not(:last-child) {
    border-bottom: 1px solid ${ThemeColors.gray_10};
  }

  @media all and (max-width: ${breakpoints.md}px) {
    padding: 0;
  }
  .desc {
    max-width: 550px;
  }
  .switch {
    margin-bottom: 20px;
  }
  .kit-form-group {
    margin-bottom: ${spacing.s}rem;
  }
`

export const NotificationListContainer = styled.div``

export const NotificationTitle = styled.legend`
  margin: 0 0 ${spacing.xs}rem;
  padding: 0;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.base}rem !important;
`

export const SettingsContainer = styled.div`
  background-color: ${ThemeColors.white};
  padding: ${spacing.absolute.l}px;

  @media all and (max-width: ${breakpoints.md}px) {
    padding: ${spacing.absolute.m}px;
  }

  .warning {
    color: ${({ theme }) => theme.error};
    margin-inline-end: 4px;
  }

  .contact-info {
    align-items: center;
    .contact-title {
      font-weight: ${fontWeight.bold};
      margin-bottom: ${spacing.xxs}rem;
    }

    @media all and (max-width: ${breakpoints.md}px) {
      align-items: start;
      flex-direction: column;
      line-height: ${fontSize.text_20}rem;
      strong {
        margin-bottom: ${spacing.absolute.xs}px;
      }
      address {
        margin: 0;
      }
      address span:first-child {
        margin: 0 0;
      }

      .contact-title {
        display: inline-block;
        margin-bottom: ${spacing.s}rem;
      }
    }
  }

  .profile-link {
    font-size: ${fontSize.text_14}rem;
  }

  .contact-info-wrapper {
    align-items: center;
    @media all and (max-width: ${breakpoints.md}px) {
      flex-direction: column;
      align-items: start;
    }
  }

  address {
    font-size: ${fontSize.text_14}rem;
    display: flex;
    justify-content: space-between;
    margin: 0 ${spacing.s}rem;

    span:not(:first-child) {
      margin: 0 ${spacing.s}rem;
      white-space: nowrap;
    }
  }

  .invalid-phone-block {
    display: inline-flex;
    align-items: center;
    font-size: ${fontSize.text_14}rem;
    margin-top: ${spacing.absolute.s}px;
  }

  .warning {
    font-weight: ${fontWeight.bold};
    color: ${({ theme }) => theme.error};
    margin-inline: ${spacing.absolute.s}px;
  }
`

export const SettingsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.absolute.l}px;
  @media all and (max-width: ${breakpoints.md}px) {
    gap: ${spacing.absolute.m}px;
  }
`

export const SettingsPageHeader = styled.div`
  margin: 0 ${UILayout.hPageMargin}px;

  @media all and (max-width: ${breakpoints.md}px) {
    margin: 0 ${UILayout.mobile.hPageMargin}px;
  }
`

export const SettingsPageTitle = styled.h2`
  font-size: ${fontSize.h4}rem;
  &:focus {
    outline: none;
  }
`

export const StyledNotificationList = styled(List)``
