export enum MessageEventType {
  CLEAR = 'clear',
  CREATE = 'create',
  DELETE = 'delete',
  REFRESH = 'refresh',
  UPDATE = 'update',
}

export interface MessagePayload<E = MessageEventType, T = string> {
  event_type: E;
  content: T;
  group?: string;
}

export type MessageEventTypeHandler<T> = (payload: T) => void;
