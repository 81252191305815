import { KitUtilData } from '@chargepoint/cp-toolkit'
import type { TFunction } from 'i18next'
import { EVENT_TYPE_CREATE, EVENT_TYPE_UPDATE } from '../websocket'
import { hasValue } from './validations'
import { NotificationLevel } from '@/components/Notification'
import { type Alert, type MappedAlert } from '@/models/alertModel'
import { type Depot } from '@/models/depotModel'
import { type Fleet } from '@/models/fleetModel'
import { type ServiceResponse } from '@/models/serviceModel'

export const isHighSeverity = (alert: Partial<Alert>): boolean => alert.severity === 'Error'

export const numberOfHighSeverity = (
  arrayOfAlerts: Alert[] | undefined,
): number => {
  const result = Array.isArray(arrayOfAlerts)
    ? arrayOfAlerts.filter((alert) => isHighSeverity(alert))
    : []
  return result?.length ?? 0
}

/**
 * Function to map alerts to records in a dataset based on a matching key
 * @param {*} alerts
 * @param {*} records
 * @param {*} opts
 * @returns
 */
export const mapAlerts = <T = Depot | Fleet>(
  alerts: Partial<Alert>[],
  records: MappedAlert<T>[],
  {
    alertField,
    compareFn,
    dataField,
  }: {
    alertField: string;
    dataField: string;
    compareFn?: (a: Partial<Alert>, b: T) => boolean;
  },
) => {
  if (!records) {
    return []
  }
  if (!alerts || (alerts as ServiceResponse<Alert[]>).error) {
    return records
  }
  return records.reduce((acc: MappedAlert<T>[], record) => {
    const o      = { ...record }
    const result = alerts?.filter((alert: Partial<Alert>) => (compareFn
      ? compareFn(alert, o)
      : alert[alertField as keyof Alert] === o[dataField as keyof T]))
    if (result.length) {
      o.alerts = result
    }
    acc.push(o)
    return acc
  }, [])
}

/**
 * Returns alerts that are not associated with a vehicle or port
 *
 * @param {*} alerts
 * @returns
 */
export const getDepotLevelAlerts = (alerts: Alert[]): Alert[] => alerts?.filter((alert) => {
  let unnassociated = true;
  ['vehicle_external_id', 'port_external_id'].forEach((key) => {
    if (unnassociated && alert[key as keyof Alert] !== null) {
      unnassociated = false
    }
  })
  if (unnassociated) {
    return true
  }
  return false
})

export const mapAlertsToDepots = (
  alerts: Partial<Alert>[],
  depots: MappedAlert<Depot>[],
) => {
  // eslint-disable-next-line no-param-reassign
  depots?.forEach((d) => delete d.alerts)
  const mapped = mapAlerts(alerts, depots, {
    dataField  : 'external_id',
    alertField : 'depot_external_id',
  })
  return mapped
}

export const mapAlertsToFleets = (
  alerts: Partial<Alert>[],
  fleets: Fleet[],
  highSeverityOnly: boolean,
) => {
  const mapped = mapAlerts(alerts, fleets, {
    dataField  : 'external_id',
    alertField : 'fleet_external_id',
    compareFn  : highSeverityOnly
      ? (alert: Partial<Alert>, fleet: Fleet) => isHighSeverity(alert) && alert.fleet_external_id === fleet.external_id
      : undefined,
  })
  return mapped
}

export const updateAlertsAfterWebsocketUpdate = (
  alertContent: Alert,
  wsEventType: string,
  existingAlerts: Alert[],
) => {
  const newAlerts            = [...(existingAlerts || [])]
  const indexOfExistingAlert = newAlerts.findIndex(
    (alert: Alert) => alert.alert_id === alertContent.alert_id,
  )
  const alertEventType       = alertContent.event_type
  if (wsEventType === EVENT_TYPE_CREATE) {
    if (alertEventType === 'create') {
      newAlerts.push(alertContent)
    }
  } else if (wsEventType === EVENT_TYPE_UPDATE) {
    if (alertEventType === 'clear') {
      if (indexOfExistingAlert > -1) {
        newAlerts.splice(indexOfExistingAlert, 1)
      }
    } else if (alertEventType === 'create') {
      if (indexOfExistingAlert > -1) {
        newAlerts[indexOfExistingAlert] = alertContent
      }
    }
  }
  return newAlerts
}

export const getNotificationLevelForAlert = (
  alerts: Alert | Alert[],
): NotificationLevel => {
  let notificationLevel = NotificationLevel.INFO
  if (!Array.isArray(alerts)) {
    // eslint-disable-next-line no-param-reassign
    alerts = [alerts]
  }
  // Use level of the highest severity alert
  // we aren't using any banner notifications except for Info and Error at the moment (No Warning, Success, etc)
  if (alerts) {
    alerts.forEach((alert) => {
      if (alert.severity === 'Error') {
        notificationLevel = NotificationLevel.ERROR
      }
    })
  }

  return notificationLevel
}

// See ES-5919
// Returns override translation for specific station fault codes (if custom override exists)
export const mapAlertTranslation = (t:TFunction, alertCode: string, vars: Record<string, unknown>) => {
  const obj     = vars || {}
  const codeNum = obj.code_number
  if (hasValue(codeNum)) {
    const alertMessages = t('alerts.code', { returnObjects: true })
    const override      = KitUtilData.getProp(alertMessages as Record<string, unknown>, `code_number_overrides.${codeNum}.${alertCode}`)
    if (override) {
      return t(`alerts.code.code_number_overrides.${codeNum}.${alertCode}`, obj)
    }
  }

  return t(`alerts.code.${alertCode}`, vars)
}

export default {
  isHighSeverity,
  numberOfHighSeverity,
  getDepotLevelAlerts,
  mapAlerts,
  mapAlertsToDepots,
  mapAlertsToFleets,
  updateAlertsAfterWebsocketUpdate,
}
