import {
  KitButton,
  KitCheck,
  KitSpinner,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import type { TFunction } from 'i18next'
import { type RefObject, useRef, useState } from 'react'

import styled from 'styled-components'

import SearchBox from '../SearchBox'
import {
  CloseButton,
  List,
  ListItem,
  SubHeading,
  autoWidth,
} from '../Styled'
import {
  FilterFieldSet,
  FilterLegend,
} from '@/components/Styled/filterBarStyles'
import { type OptionListItem } from '@/models/formModel'

const { breakpoints, duration, spacing } = ThemeConstants
interface CheckListItem {
  checked?: boolean;
  value: unknown;
  label: string;
}
function getChecked(items: CheckListItem[]) {
  return Array.isArray(items)
    ? items.filter((item) => item.checked).map((item) => item.value)
    : items
}

const Container = styled.fieldset`
  border: 0;
  max-width: 350px;
  margin-bottom: ${spacing.absolute.s}px;
  label {
    margin: 0;
  }
  ul {
    margin-top: ${spacing.absolute.s}px;
  }

  @media all and (max-width: ${breakpoints.sm}px) {
    ${autoWidth}
  }
`

const TagContainer = styled.div`
  border-bottom: 1px solid ${ThemeColors.gray_30};
  display: flex;
  margin-bottom: ${spacing.absolute.sm}px;
  flex-flow: wrap;
`
const Tag = styled.div`
  background: ${ThemeColors.gray_50};
  color: ${ThemeColors.white};
  display: flex;
  align-items: center;
  padding: 0 0 0 ${spacing.absolute.s}px;
  cursor: pointer;
  margin-right: ${spacing.absolute.xs}px;
  margin-bottom: ${spacing.absolute.xs}px;
  margin-top: ${spacing.absolute.xs}px;
  transition: all ${duration.short}s;

  button {
    color: ${ThemeColors.gray_30};
  }

  &:dir(rtl) {
    margin-left: ${spacing.absolute.s}px;
    span {
      margin-left: ${spacing.absolute.s}px;
    }
  }
  &:hover {
    color: ${ThemeColors.gray_90};
    background: ${ThemeColors.white};
    outline: 1px solid ${ThemeColors.gray_50};
    button:after {
      color: ${ThemeColors.gray_50}};
    }
  }
`

const BorderBox = styled.div`
  padding: ${spacing.absolute.sm}px;
  padding-top: 0;
  border: 1px solid ${ThemeColors.gray_30};
`

const ItemList = styled(List)`
  max-height: 127px;
  overflow-y: scroll;
`

const StyledListItem = styled(ListItem)`
  & {
    padding: ${spacing.xs}rem;
  }
`

type TagListProps = {
  tags: OptionListItem[];
  onRemoveTag: (val: string | number) => void;
};

const TagList = ({ onRemoveTag, tags }: TagListProps) => {
  if (tags.length === 0) {
    return null
  }

  return (
    <TagContainer>
      { tags.map((tag) => (
        <Tag key={tag.value as string}>
          <span>{ tag.label }</span>

          <CloseButton
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              e.stopPropagation()
              onRemoveTag(tag.value as string)
            }}
          />
        </Tag>
      )) }
    </TagContainer>
  )
}

type SearchableCheckedListProps = {
  title?: string;
  items: CheckListItem[];
  name: string;
  onChange: (fieldName: string, fieldValue: unknown[]) => void;
  withSearch?: boolean;
  withTags?: boolean;
  errorWhenEmptyMessage?: string;
  showLoader?: boolean;
  qaTag?: string;
  t: TFunction
};

const SearchableCheckedList = ({
  errorWhenEmptyMessage,
  items,
  name,
  onChange,
  qaTag,
  showLoader,
  t,
  title,
  withSearch,
  withTags,
}: SearchableCheckedListProps) => {
  const searchRef                       = useRef<HTMLInputElement>()
  const [selected, setSelected]         = useState(
    getChecked(items as CheckListItem[]),
  )
  const [searchFilter, setSearchFilter] = useState()

  const valuesChanged = (newValues: unknown[]) => {
    setSelected(newValues)
    if (onChange) {
      onChange(name, newValues)
    }
  }

  const clearSelected = (
    e: React.MouseEvent<Element, MouseEvent> | undefined,
  ) => {
    e?.stopPropagation()
    setSelected([])
    onChange(name, [])
  }

  const onRemoveTag = (val: unknown) => {
    valuesChanged(selected.filter((v) => v !== val))
  }

  const updateSelection = (val: unknown) => {
    if (selected?.find((v: unknown) => v === val)) {
      valuesChanged(selected.filter((v) => v !== val))
    } else {
      const newState = (selected ?? []).concat(val)
      valuesChanged(newState)
    }
  }

  const handleSearch = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    setSearchFilter(searchRef.current.value)
  }

  if (!Array.isArray(items) || items?.length === 0) {
    if (showLoader) {
      return (
        <Container>
          <KitSpinner size="s" align="middle" />
        </Container>
      )
    }
    if (errorWhenEmptyMessage) {
      return (
        <Container>
          <div>{ title }</div>
          <SubHeading style={{ marginLeft: 0 }}>
            { errorWhenEmptyMessage }
          </SubHeading>
        </Container>
      )
    }
    return null
  }

  const filteredItems = searchFilter
    ? items.filter((item) => [item.label, item.value].some((s) => (s as string).includes(searchFilter)))
    : items

  const tags = filteredItems?.reduce((acc, item) => {
    if (selected?.includes(item.value)) {
      acc.push(item as OptionListItem)
    }
    return acc
  }, [] as OptionListItem[])

  return (
    <FilterFieldSet>
      <FilterLegend>
        <span>{ title }</span>
        { selected?.length > 0 && (
          <KitButton variant="link" onClick={clearSelected}>{ t('btn_clear') }</KitButton>
        ) }
      </FilterLegend>

      <BorderBox data-qa-id={qaTag && `${qaTag}_container`}>
        { withTags && <TagList tags={tags} onRemoveTag={onRemoveTag} /> }

        { withSearch && (
          <SearchBox
            className="search"
            ref={searchRef as RefObject<HTMLInputElement>}
            onChange={utils.debounce(handleSearch, 300)}
          />
        ) }

        <ItemList>
          { filteredItems.map((item, index) => (
            <StyledListItem key={item.value as string}>
              <KitCheck
                data-qa-id={qaTag && `${qaTag}_${index}`}
                label={item.label}
                onChange={() => updateSelection(item.value)}
                checked={selected?.includes(item.value)}
              />
            </StyledListItem>
          )) }
        </ItemList>
      </BorderBox>
    </FilterFieldSet>
  )
}

SearchableCheckedList.defaultProps = {
  title                 : null,
  withSearch            : true,
  withTags              : true,
  errorWhenEmptyMessage : null,
}

export default SearchableCheckedList
