import { useTranslation } from 'react-i18next'

import { NotificationSettings } from './NotificationSettings'
import SettingsHeader from './SettingsHeader'
import Page from '@/components/Page'

const Component = () => {
  const { t } = useTranslation()
  return (
    <Page title={t('page_titles.user_settings')}>
      <SettingsHeader />
      <NotificationSettings />
    </Page>
  )
}

export { Component }
