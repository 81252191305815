import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import { type Contact } from '@/models/commonModel'
import {
  type AddZoneRequest,
  type Depot,
  type DepotAboutSettings,
  type DepotDetailsRow,
  type DepotFilterParams,
  type DepotStationGroup,
  type DepotZone,
} from '@/models/depotModel'
import {
  type BaseServiceResponse,
  type PagedServiceResponse,
  type ServicePayload,
} from '@/models/serviceModel'
import { type DepotTelematicsZone } from '@/models/telematicsModel'
import { type QueryParams } from '@/types/index'

const createService = (api: ServiceInterface) => {
  const baseURL = '/api/depot'

  return {
    async getDepots(opts?: QueryParams): Promise<PagedServiceResponse<Depot>> {
      return wrapRequest(
        api.get('/api/depot-list/', undefined, opts),
      ) as Promise<PagedServiceResponse<Depot>>
    },
    async getDepotInfo(depotId: string) {
      return wrapRequest<Depot & BaseServiceResponse>(
        api.get(`/api/depot/${depotId}/`),
      )
    },
    async getDepotDetailsTable(depotId: string): Promise<DepotDetailsRow[]> {
      return wrapRequest(api.get(`${baseURL}/${depotId}/details/`)) as Promise<
      DepotDetailsRow[]
      >
    },
    async getDepotMapCoordinates<T = Record<string, string>>(
      depotId: string,
    ): Promise<T> {
      return wrapRequest(
        api.get(`${baseURL}/${depotId}/map-view-coordinates/`),
      ) as Promise<T>
    },
    async getDepotAboutSettings(depotId: string) {
      return wrapRequest<BaseServiceResponse & DepotAboutSettings>(
        api.get(`${baseURL}/${depotId}/settings/about/`),
      )
    },
    async getDepotZones(depotId: string) {
      return wrapRequest<BaseServiceResponse & DepotZone[]>(
        api.get(`${baseURL}/${depotId}/settings/zones/`),
      )
    },
    async getDepotStationGroups(depotId: string) {
      return wrapRequest<BaseServiceResponse & DepotStationGroup[]>(
        api.get(`${baseURL}/${depotId}/settings/station-groups/`),
      )
    },
    // depot contacts
    async getDepotContacts(depotId: string) {
      return wrapRequest<BaseServiceResponse & Contact[]>(
        api.get(`${baseURL}/${depotId}/settings/contacts/`),
      )
    },

    async getDepotDetailsFilterParameters(): Promise<DepotFilterParams> {
      return wrapRequest(
        api.get('/api/depot-filter-parameters/'),
      ) as Promise<DepotFilterParams>
    },

    async batchContactOperation(
      depotExternalID: string,
      payload: Record<string, unknown>[],
    ) {
      return wrapRequest<BaseServiceResponse>(
        api.post(`/api/depot/${depotExternalID}/settings/contacts/`, payload),
      )
    },
    // telematic zones
    async getUnmappedZones(depotExternalID: string) {
      return wrapRequest<DepotTelematicsZone[] & BaseServiceResponse>(
        api.get(`/api/depot/${depotExternalID}/settings/unmapped-zones/`),
      )
    },
    async addZone(
      {
        geofence,
        is_hidden,
        name,
        telematics_account,
        telematics_zone,
      }: AddZoneRequest,
      depotId: number,
    ) {
      return wrapRequest(
        api.post('/api/zone/', {
          depot_external_id: depotId,
          name,
          geofence,
          is_hidden,
          telematics_zone,
          telematics_account,
        }),
      )
    },
    async updateZone({
      id,
      is_hidden,
      name,
      priority,
    }: {
      id: number;
      name: string;
      priority: number;
      is_hidden: boolean;
    }) {
      return wrapRequest(
        api.put(`/api/zone/${id}/`, { name, priority, is_hidden }),
      )
    },
    async deleteZone({ id }: { id: number }) {
      return wrapRequest(api.delete(`/api/zone/${id}/`))
    },

    async batchZoneOperation(depotExternalID: string, payload: ServicePayload[]) {
      return wrapRequest(
        api.post(`/api/depot/${depotExternalID}/settings/zones/`, payload),
      )
    },
  }
}

export default createService(AbstractService())
