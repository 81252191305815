import { KitForm } from '@chargepoint/cp-toolkit'
import { type ChangeEvent, type FC } from 'react'

import DeletableField from './DeletableField'

interface DeletableTextFieldProps {
  name: string;
  label: string;
  id: unknown;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDelete: (fieldId: string) => void;
}

const DeletableTextField: FC<DeletableTextFieldProps> = ({
  id,
  name,
  onChange,
  onDelete,
  value,
  ...otherProps
}) => (
  <DeletableField name={name} id={id} onDelete={onDelete}>
    <KitForm.Input
      id={id as string}
      name={name}
      value={value ?? ''}
      onChange={onChange}
      {...otherProps}
    />
  </DeletableField>
)

export default DeletableTextField
