import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

import { FormItem } from '@/components/Styled'

const { fontSize, fontWeight, spacing } = ThemeConstants

export const WizardContent = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  min-height: 200px;
`

export const WizardHeading = styled.div`
  font-size: ${fontSize.base}rem;
  font-weight: ${fontWeight.normal};
  margin-bottom: ${spacing.absolute.m}px;
`

export const SummarySectionHeading = styled.h3`
  font-size: ${fontSize.base}rem;
`

export const ReadOnlyFormItem = styled(FormItem)`
  margin-bottom: ${spacing.absolute.m}px;
  label {
    margin-bottom: 0px;
    color: ${ThemeColors.gray_50};
  }
`
