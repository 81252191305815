import { Outlet } from 'react-router-dom'

import AccountHeader from './AccountHeader'

const Account = (): JSX.Element => (
  <>
    <AccountHeader />
    <Outlet />
  </>
)

export default Account
