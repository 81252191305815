import { KitButton, KitFlexRowSpaced, KitSelect, KitTips, ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import {
  addDays,
  differenceInDays,
  endOfDay,
  min,
  startOfDay,
  subDays,
} from 'date-fns'
import { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import downloadIcon from '../../../../images/svgs/download_icon.svg'
import { getRange, parseRange } from './config'
import { Period } from '@/common/constants'
import { paramsToQueryString } from '@/common/utils'
import { DateRangeInput } from '@/components/DateInput'
import { type Site, type StationGroup } from '@/models/energyModel'
import { type Fleet } from '@/models/fleetModel'
import { type OptionListItem, type SelectChangeEvent } from '@/models/formModel'
import FeatureService, { Features } from '@/services/FeatureService'
import FleetService from '@/services/FleetService'
import { type DateRange } from '@/types/index'

const { spacing } = ThemeConstants

const Wrapper = styled.div`
   button {
    padding: ${spacing.absolute.s}px 4px 0px;
    min-width: 48px;
    margin: 0 ${spacing.sm}rem;
   }
`

const CSVIcon = styled.img`
   width: 22px;
   opacity: .6;
`

const Container = styled.div`
  display: flex;
  gap: ${spacing.absolute.m}px;
  padding: 0 ${ThemeConstants.spacing.absolute.s}px;

  > div:nth-child(1) {
    width: 190px;
  }

  > div:nth-child(2) {
    width: 150px;
  }

  > div:nth-child(3) {
    min-width: 100px;
  }
`

// { filterName: string, filterValue: unknown, range?: DateRange }

export interface EnergyChartControlsProps {
  onFilterChange: ({
    filterName,
    filterValue,
    range,
  }: {
    filterName: string;
    filterValue: unknown;
    range?: DateRange;
  }) => void;
  stationGroups?: StationGroup[];
  site: Site;
  selectedDuration: Period;
  selectedRange?: DateRange;
}

const maxRangeDays = 7

// Note: Until the backend can support dynamic bin sizes, we want to limit the maximum number
// of days that the user can chart to 7 days (even then, the chart gets bogged down)
function getMinMaxRange(range: DateRange) {
  const now         = new Date()
  const parsedRange = parseRange(range)
  let minDate       = subDays(now, maxRangeDays)
  let maxDate       = now

  if (range) {
    minDate = startOfDay(subDays(parsedRange[1], maxRangeDays - 1))
    maxDate = endOfDay(addDays(parsedRange[0], maxRangeDays - 1))
    return [min([minDate, now]), min([maxDate, now])]
  }

  return [minDate, addDays(minDate, maxRangeDays)]
}

const isValidRange = (range: DateRange) => {
  const diff = Math.abs(differenceInDays(range[0], range[1]))
  return diff <= maxRangeDays
}

const EnergyChartControls: FC<EnergyChartControlsProps> = ({
  onFilterChange,
  selectedDuration,
  selectedRange,
  site,
  stationGroups,
}) => {
  const { t }                                         = useTranslation()
  const [fleets, setFleets]                           = useState<Fleet[]>([])
  const [minDate, maxDate]                            = getMinMaxRange(selectedRange as DateRange)
  const [invalidFilterErrors, setInvalidFilterErrors] = useState<{
    dateRange: string | null;
  }>({ dateRange: null })

  const stationGroupOptions =    stationGroups && Object.keys(stationGroups)?.length
    ? [
      {
        label : site?.name,
        value : site?.external_id,
      },
    ].concat(
      Object.entries(stationGroups).map(([, grp]) => ({
        label : grp.name,
        value : grp.external_id,
      })),
    )
    : null

  const fleetOptions = [
    { label: t('energy_management.allFleets') },
  ].concat(
    fleets?.map((fleet) => ({
      label : fleet.name,
      value : fleet.external_id,
    })),
  )

  const handleDownloadClick = () => {
    if (selectedRange) {
      const query       = {
        site_ids   : site.external_id,
        start_date : selectedRange[0],
        end_date   : selectedRange[1],
      }
      document.location = `/energy/api/energy-management-data/raw-combined${paramsToQueryString(query)}`
    }
  }

  const handleChange = (
    filterName: string,
    opt: Omit<OptionListItem, 'label'>,
  ) => {
    if (filterName === 'duration' && opt.value === Period.CUSTOM) {
      onFilterChange({
        filterName,
        filterValue : opt.value,
        range       : getRange(Period.LAST_24_HOURS, true) as DateRange,
      })
      setInvalidFilterErrors({ dateRange: null })
    } else if (filterName === 'dateRange') {
      const parsedRange = parseRange(opt.value as string[])
      if (isValidRange(parsedRange)) {
        onFilterChange({ filterName, filterValue: opt.value })
        setInvalidFilterErrors({ [filterName]: null })
      } else {
        setInvalidFilterErrors({ [filterName]: t('charting.errors.maximum_allowed_date_range', { numDays: maxRangeDays }) })
      }
    } else {
      onFilterChange({ filterName, filterValue: opt.value })
    }
  }

  const durations = [
    {
      label : t('charting.durations.1H.long'),
      value : Period.LAST_HOUR,
    },
    {
      label : t('charting.durations.12H.long'),
      value : Period.LAST_12_HOURS,
    },
    {
      label : t('charting.durations.24H.long'),
      value : Period.LAST_24_HOURS,
    },
    {
      label : t('charting.durations.7D.long'),
      value : Period.LAST_7_DAYS,
    },
    {
      label : t('charting.custom_range'),
      value : Period.CUSTOM,
    },
  ]

  const selectedDuratonItem  = durations.find((d) => d.value === selectedDuration)
  const defaultDurationLabel =    selectedDuratonItem?.label
    ?? t('charting.durations.24H.long')

  const downloadMessage = t('common.download_as_csv')

  useEffect(() => {
    async function fetchData() {
      const { error, results } = await FleetService.getFleets()
      if (!error) {
        setFleets(results as Fleet[])
      }
    }

    fetchData()
  }, [])

  return (
    <Wrapper>
      <KitFlexRowSpaced>
        <Container>
          { stationGroupOptions && (
            <KitSelect
              name="stationGroup"
              defaultValue={stationGroupOptions[0]}
              options={stationGroupOptions}
              onChange={(e) => handleChange('stationGroup', e as SelectChangeEvent)}
            />
          ) }
          { fleetOptions && (
            <KitSelect
              name="fleet"
              aria-label={t('fleet_one')}
              defaultValue={{ label: t('energy_management.allFleets') }}
              options={fleetOptions}
              onChange={(e) => handleChange('fleet', e as SelectChangeEvent)}
            />
          ) }
          <KitSelect
            name="duration"
            aria-label={t('charting.duration')}
            defaultValue={{ label: defaultDurationLabel }}
            options={durations}
            onChange={(e) => handleChange('duration', e as SelectChangeEvent)}
          />

          { selectedDuration === Period.CUSTOM && (
            <DateRangeInput
              error={invalidFilterErrors?.dateRange as unknown as string}
              name="dateRange"
              onChange={(fieldName, fieldValue) => handleChange(fieldName as string, { value: fieldValue })
              }
              min={minDate}
              max={maxDate}
              range={selectedRange as DateRange}
              t={t}
            />
          ) }
        </Container>

        { FeatureService.isEnabled(Features.csvDownloadButtonEnabled) && <KitTips content={downloadMessage}>
          <KitButton
            aria-label={downloadMessage}
            className="btn-download"
            onClick={handleDownloadClick}
            variant="link">
            <CSVIcon src={downloadIcon} />
          </KitButton>
        </KitTips> }

      </KitFlexRowSpaced>
    </Wrapper>
  )
}

export default EnergyChartControls
