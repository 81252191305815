// TODO: Port useful things here back to cp-toolkit, but let's get them fully tested first
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { KitUtilLocale } from '@chargepoint/cp-toolkit'

import { DEFAULT_DISTANCE_UNITS, LOCAL_STORAGE_LOCALE_KEY } from './constants'
import { getUserPreferences } from './utils/user'
import { hasValue } from './utils/validations'
import { type UserPreferences } from '@/models/userModel'

export interface UnitValue {
  unit: string;
  value: number | string;
}

export enum UnitTypes {
  Kilometer = 'kilometer',
  Mile = 'mile',
  Celsius = 'celsius',
  Fahrenheit = 'fahrenheit',
}

export enum DistanceUnits {
  Kilometer = 'kilometer',
  Mile = 'mile',
}

export enum TemperatureUnits {
  Celsius = 'celsius',
  Fahrenheit = 'fahrenheit',
}

export interface NumberFormatOptions {
  maximumSignificantDigits?: number;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  style?: 'unit' | 'currency' | 'percent';
  unit?: string;
}

function getDefaultLocale(): string {
  const pref = getUserPreferences()
  const loc  = (pref as UserPreferences)?.localeCode
    ?? KitUtilLocale.getSupportedLocale(
      localStorage.getItem('i18nextLng') ?? 'en-US',
    )
  return loc
}

let locale = getDefaultLocale()

// @see ES-2714 for discussion about recent changes
export const numberFormatOptions: Record<
'distance' | 'soc' | 'power' | 'energy',
NumberFormatOptions
> = {
  distance : { minimumFractionDigits: 1, maximumFractionDigits: 1 }, // distance = miles || kilometers
  soc      : { minimumFractionDigits: 1, maximumFractionDigits: 1, style: 'percent' },
  power    : { minimumFractionDigits: 1, maximumFractionDigits: 1 },
  energy   : { minimumFractionDigits: 1, maximumFractionDigits: 1 },
}

/**
 * Note: This should really only be used as a fallback if we cannot get a proper locale;
 * @param lang -- lang code en, fr, de, ...etc
 */
export function getDefaultCountryCodeFromLanguageCode(lang: string): string {
  const langToLocaleMap: Record<string, string> = {
    de : 'DE',
    en : 'US',
    es : 'ES',
    fr : 'FR',
    it : 'IT',
    nl : 'NL',
    sv : 'SE',
  }

  return langToLocaleMap[lang] ?? locale
}

export const parseLocale = (loc: string): Record<string, string> => {
  const parts                   = loc.split('-')
  const [langCode, countryCode] = parts

  return {
    langCode,
    countryCode : countryCode ?? getDefaultCountryCodeFromLanguageCode(langCode),
    locale      : `${langCode}-${countryCode}`,
  }
}

export function normalizeLocaleFormat(loc: string): string {
  if (loc) {
    const parsedLocale = parseLocale(loc)
    return parsedLocale.locale
  }
  return loc
}

export function getCountryCodeFromLocale(loc: string): string {
  const supportedLocale = KitUtilLocale.getSupportedLocale(loc ?? locale)
  // eslint-disable-next-line no-unused-vars
  return supportedLocale.split('-')[1]
}

// -- formatter functions --

export const formatNumber = (
  num: number | undefined,
  opts?: NumberFormatOptions,
): number | string => {
  const fmt = new Intl.NumberFormat(locale, opts ?? {})
  if (hasValue(num) && !Number.isNaN(num)) {
    const result = fmt.format(num as number)
    return result
  }
  return num ?? '--'
}

export const formatPercent = (
  value: number,
  opts?: NumberFormatOptions,
): string => {
  const formatOptions       = {
    style                 : 'percent',
    maximumFractionDigits : 2,
  }
  const formattedPercentage = Intl.NumberFormat(
    [locale],
    opts ?? formatOptions,
  )
  return hasValue(value)
    ? formattedPercentage.format(value * 0.01)
    : ('-- %' as string)
}

export const formatCurrency = (
  num: number,
  currency?: string,
): string | unknown => {
  const fmt = Intl.NumberFormat([locale], {
    style                 : 'currency',
    currency              : currency ?? 'USD',
    maximumFractionDigits : 0,
  })
  if (!Number.isNaN(num)) {
    return fmt.format(num)
  }
  return num ?? '--'
}

export const formatTelephone = (str: string): string => {
  const patterns: { [countryCode: string]: Record<string, string | RegExp> } = {
    // (XXX) XXX-XXXX
    US: { regex: /(\d{3})(\d{3})(\d{4})/, format: '($1) $2-$3' },
  }
  const pattern                                                              = patterns[getCountryCodeFromLocale(locale)]

  if (str) {
    return pattern ? str.replace(pattern.regex, pattern.format as string) : str
  }

  return str
}

/**
 * @returns Returns browser locale
 * Note: We really should be getting this from a service, but since we don't have it in a service yet,
 * we will get from the browser, then try to fix it if it does not give us a proer locale code
 */
export const getBrowserLocale = (): string => {
  // Note: navigator.language appears to be returning the correct locale for Chrome, Firefox, & Safari
  // TODO: test results for different devices: i0S, Android, etc
  const { language }     = window.navigator as Navigator
  const fallbackLanguage = 'en-US'
  return normalizeLocaleFormat(language ?? fallbackLanguage)
}

export const setLocale = (loc: string): void => {
  locale = KitUtilLocale.getSupportedLocale(loc)
  localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale)
}

export const getLocaleCode = () => localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) ?? getBrowserLocale()

// -- unit conversion functions --

export const milesToKilometers = (miles: number): number => miles * 1.60934

export const kilometersToMiles = (km: number): number => km * 0.621371

export const fahrenheitToCelsius = (tempF: number): number => ((tempF - 32) * 5) / 9

export const gallonsToLiters = (gallons: number): number => gallons * 3.78541

/**
 * Converts distance to kilometers (when needed),
 * and formats the return value according to user's locale
 *
 * TODO: break formatting and metric conversion of miles into separate functions
 * @param value
 * @param valueOnly
 * @returns
 */
export const getLocalizedDistance = (
  value: number,
  valueOnly = true,
  format = true,
): number | string | { unit: string; value: number | string } => {
  if (!hasValue(value)) {
    return value
  }
  const pref = getUserPreferences()
  let result = {
    unit  : UnitTypes.Mile,
    value : format ? formatNumber(value, numberFormatOptions.distance) : value,
  }
  if (pref?.length_units === 'kilometers') {
    const dist = milesToKilometers(value)
    result     = {
      unit  : UnitTypes.Kilometer,
      value : format ? formatNumber(dist, numberFormatOptions.distance) : value,
    }
  }
  return valueOnly ? result.value : result
}

export const getLocalizedUnits = (): {
  distance: string;
  temperature: string;
  volume: string;
} => {
  const pref = getUserPreferences()
  return {
    distance    : pref.length_units ?? DEFAULT_DISTANCE_UNITS,
    temperature : pref.temperature_units,
    volume      : pref.volume_units,
  }
}
