import {
  KitBadge,
  KitButton,
  KitButtonBar,
  KitForm,
  KitIcon,
  KitInput,
  KitModal,
  KitSelect,
  KitSpinner,
  KitTips,
  KitUtilData,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { yupResolver } from '@hookform/resolvers/yup'
import type { TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import type { MouseEvent, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  type DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import ReactDOM from 'react-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SiteDetailsStore from '../siteStore'
import DeleteButton from './DeleteButton'
import {
  AllOthersItem,
  Column,
  DragDropContainer,
  DraggableItem,
  DraggableItemName,
  EnergyModalWrapper,
  Heading,
  ItemWrapper,
  LinkStyled,
  SharingAlgorithmLabel,
  SubHeading,
  TTipContent,
  TextLabel,
  Value,
} from './styles'
// eslint-disable-next-line max-len
import { buildEnergySettingsPayload, createOptimization, getDefaultOptimization, getOptimizationListFromSettingsResponse, hasDefaultFleetOptimization, updateFleetOptimizations } from './utils'
import { getValidationSchema } from './validationScheme'
import type { SchemaError } from './validationScheme'
import { ISO_DATE_TIME, MIN_SITE_DEMAND_LIMIT } from '@/common/constants'
import { formatNumber, numberFormatOptions } from '@/common/lang'
import { KitModalFooter } from '@/common/styledElements'
import { getTransComponent } from '@/common/utils/componentHelpers'
import { has } from '@/common/utils/data'
import { formatDate } from '@/common/utils/date'
import { hasValue } from '@/common/utils/validations'
import Dialog from '@/components/Dialog'
import Notification, { NotificationLevel } from '@/components/Notification'
import { List, ListItem, ResponsiveKitFlexRow, TooltipTrigger } from '@/components/Styled'
import type { EnergySettings, PowerManagementType, Tariff } from '@/models/energyModel'

import type { FleetOptimization } from '@/pages/Energy/interfaces'
import AddFleetOptimization from '@/pages/Energy/routes/SitePropSheet/AddFleetOptimization'
import type { NormalizedChangeEvent } from '@/types/index'

// Portal
const portal = document.createElement('div')
document.body.appendChild(portal)

const TariffTooltipContent = ({ t, tariff }: { t: TFunction, tariff: Tariff }) => <div>
  <KitBadge
    backgroundColor="gray_50"
    fontColor="white"
    className="tariff-active-badge"
    badgeText={t('energy_management.active_tariff_tooltip.active_badge')}
  />
  <List>
    <ListItem>
      <TextLabel>{ t('energy_management.active_tariff_tooltip.effective_start') }:</TextLabel>
      <Value>{ formatDate(tariff?.effective_start) }</Value>
    </ListItem>
    <ListItem>
      <TextLabel>{ t('energy_management.active_tariff_tooltip.last_updated') }:</TextLabel>
      <Value>{ formatDate(tariff?.last_updated_time, ISO_DATE_TIME) }</Value>
    </ListItem>
    <ListItem>
      <TextLabel>{ t('energy_management.active_tariff_tooltip.utility') }:</TextLabel>
      <Value>{ tariff?.utility_name }</Value>
    </ListItem>
  </List>
</div>

// to handle positioning issues with react-beautiful-dnd due to being inside a modal that has css transforms
// @see https://codepen.io/ux-powered/pen/rNWGjrY
function PortalAwareItem({
  children,
  provided,
  snapshot,
}: {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  children: ReactNode;
}) {
  const usePortal = snapshot.isDragging

  const child = (
    <ItemWrapper
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      { children }
    </ItemWrapper>
  )

  if (!usePortal) {
    return child
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal)
}

export interface EnergySettingsProps {
  settings: Partial<EnergySettings>; // for now it is not clear
  onApply: (siteID: string, settings: EnergySettings) => Promise<unknown>;
  onCancel: () => void;
  siteID: string;
}

const EnergySettingsModal = ({ onApply, onCancel, siteID }: EnergySettingsProps) => {
  const { t } = useTranslation()

  const [showOptimizationModal, setShowOptimizationModal]         = useState(false)
  const [selectedFleetOptimization, setSelectedFleetOptimization] = useState<FleetOptimization>()
  const [fleetOptimizations, setFleetOptimizations]               = useState<FleetOptimization[]>([])
  const [hasPowerManagementType, setHasPowerManagementType]       = useState(false)
  const [isTouched, setIsTouched]                                 = useState(false)
  const [showConfirmDelete, setShowConfirmDelete]                 = useState(false)
  const allOthersElement                                          = fleetOptimizations?.find(({ fleet_external_id }) => fleet_external_id === '-1')
  const validationSchema                                          = getValidationSchema(t)

  const {
    clearErrors,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues : SiteDetailsStore.siteSettings,
    mode          : 'onChange',
    resolver      : yupResolver(validationSchema),
  })

  const settings            = SiteDetailsStore.siteSettings
  const isLoading           = SiteDetailsStore.isFetching
  const powerSharingOptions = [
    {
      value : 'none',
      label : t('energy_management.none'),
    },
    {
      value : 'power_sharing',
      label : t('energy_management.powerSharing'),
    },
    {
      value : 'power_sharing_simulation',
      label : t('energy_management.powerSharingSimulation'),
    },
  ]

  const checkIfValid = (validationSettings = {}): boolean => {
    clearErrors()
    try {
      validationSchema.validateSync(validationSettings, { abortEarly: false })
      return true
      // eslint-disable-next-line
    } catch (err: any) {
      // has to be any
      err.errors?.forEach((er: SchemaError) => {
        er.field && setError(er.field, { message: er.message })
      })
      return false
    }
  }

  const onDragEnd = (result: DropResult) => {
    const index                 = !result.destination || Number.isNaN(result.destination.index) ? 0 : result.destination.index
    const newfleetOptimizations = fleetOptimizations.filter((opt) => opt.fleet_external_id !== '-1')
    const allOthers             = fleetOptimizations.find((opt) => opt.fleet_external_id === '-1')
    const [removed]             = newfleetOptimizations.splice(result.source.index, 1)
    newfleetOptimizations.splice(index, 0, removed)
    if (allOthers) {
      newfleetOptimizations.push(allOthers)
    }
    setFleetOptimizations(newfleetOptimizations)
  }

  const handleFieldChange = (
    field: string,
    e: NormalizedChangeEvent,
  ) => {
    const value: string | number = field === 'powerManagement' ? e.value : e?.target.value

    const handlers: { [key: string]: () => void } = {
      powerManagement : () => SiteDetailsStore.updateSiteSettings({ power_management_type: value as PowerManagementType }),
      targetDemand    : () => SiteDetailsStore.updateSiteSettings({ demand_target_kw: Number.isNaN(parseInt(value)) ? undefined : Number(value) }),
      hardDemandLimit : () => {
        SiteDetailsStore.updateSiteSettings({ hard_demand_limit_kw: Number.isNaN(parseInt(value)) ? undefined : Number(value) })
      },
    }

    if (field !== 'powerManagement') {
      setIsTouched(true)
    }

    if (has(handlers, field)) {
      handlers[field]()
    }
  }

  const handleSubmit = () => {
    const payload = buildEnergySettingsPayload(fleetOptimizations, settings as EnergySettings) as EnergySettings
    if (settings) {
      if (settings.power_management_type === 'none' || !settings.power_management_type) {
        onApply(siteID, payload)
      } else if (checkIfValid({ settings: payload })) {
        onApply(siteID, payload)
      }
    }
  }

  const handleCancel = () => onCancel()

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { currentTarget } = e
    const isMatch           = (opt: FleetOptimization, el: HTMLButtonElement) => opt.fleet_external_id === el.getAttribute('data-id')
    const fleetElement      = fleetOptimizations.find((opt) => isMatch(opt, currentTarget))
    if (fleetElement) {
      setSelectedFleetOptimization({ ...fleetElement })
      setShowConfirmDelete(true)
    }
  }

  const addOptimization = () => {
    setSelectedFleetOptimization(undefined)
    setShowOptimizationModal(true)
  }

  const editOptimization = (id: string) => {
    const selectedOptimization = fleetOptimizations.find((opt) => opt.fleet_external_id === id)
    if (selectedOptimization) {
      setSelectedFleetOptimization(selectedOptimization)
      setShowOptimizationModal(true)
    }
  }

  const handleOptimizationSave = (newValue: FleetOptimization, previousValue?: FleetOptimization) => {
    setShowOptimizationModal(false)
    const updatedOptimizations = updateFleetOptimizations(fleetOptimizations, newValue, previousValue, t)
    // were were editing an optimization
    if (selectedFleetOptimization) {
      setFleetOptimizations(updatedOptimizations)
    } else {
      // we are adding a new optimization or something
      if (!hasDefaultFleetOptimization(updatedOptimizations)) {
        setFleetOptimizations([
          ...updatedOptimizations,
          createOptimization(getDefaultOptimization(t('energy_management.allOthers')), t),
        ])
      }
      setFleetOptimizations(updatedOptimizations)
    }
  }

  const handleOptimizationCancel = () => setShowOptimizationModal(false)

  const handleOptimizationDelete = (fleetId: string) => {
    setShowOptimizationModal(false)
    const configList                = SiteDetailsStore.siteSettings?.fleet_optimization_config_list
    const existingOptimizationIDs   = configList?.map((opt) => opt.fleet_external_id)
    const updatedfleetOptimizations = fleetOptimizations.reduce((acc, opt) => {
      if (opt.fleet_external_id === fleetId) {
        if (existingOptimizationIDs?.includes(fleetId)) {
          acc.push({
            fleet_external_id : opt.fleet_external_id,
            delete            : true,
          })
        }
      } else {
        acc.push(opt)
      }

      return acc
    }, [])

    setFleetOptimizations(updatedfleetOptimizations)
    setShowConfirmDelete(false)
  }

  const getItemStyle = (isDragging: boolean) => ({
    userSelect : 'none',
    // change background colour if dragging
    background : isDragging ? ThemeColors.gray_20 : ThemeColors.gray_05,

    color: isDragging ? ThemeColors.gray_50 : ThemeColors.gray_70,
  })

  useEffect(() => {
    const { siteSettings } = SiteDetailsStore
    if (siteSettings) {
      const { optimizations } = getOptimizationListFromSettingsResponse(siteSettings, t)
      setFleetOptimizations(optimizations)
    } else {
      SiteDetailsStore.loadSiteSettings()
    }
  }, [SiteDetailsStore.siteID, SiteDetailsStore.siteSettings])

  useEffect(() => {
    setHasPowerManagementType(settings?.power_management_type !== 'none')
    setIsTouched(false)
  }, [settings?.power_management_type])

  useEffect(() => {
    if (settings?.power_management_type !== 'none' && isTouched) {
      checkIfValid(settings)
    }
  }, [settings])

  return (
    <EnergyModalWrapper className="energy-modal-wrapper">
      <KitModal.Body className="energy-modal-body" data-qa-id="energy-modal-content">
        { isLoading && <KitSpinner size="s" align="middle" /> }
        { !isLoading && SiteDetailsStore.loadSettingsError && (
          <Notification
            style={{ marginBottom: `${ThemeConstants.spacing.absolute.m}px` }}
            type={NotificationLevel.WARNING}
            message={t('energy_management.notifications.unable_to_load_settings')}
          />
        ) }
        { !SiteDetailsStore.loadSettingsError && settings && (
          <>
            <Heading id="cp-modal-title" data-qa-id="energy-modal-heading">
              { settings?.station_group_name }
            </Heading>
            { /* Discuss with UX: hiding this for now because we actually do not have rated_power available to us */ }
            { false && hasValue(SiteDetailsStore.aggregatePower) && (
              <SubHeading>
                { t('energy_management.ratedPower', { power: formatNumber(SiteDetailsStore.aggregatePower, numberFormatOptions.power) }) }
              </SubHeading>
            ) }

            <KitForm>
              <ResponsiveKitFlexRow>
                <Column>
                  <KitForm.Group>
                    <KitForm.Label
                      htmlFor="powerManagement"
                      text={t('energy_management.powerManagement')}
                    />
                    <KitSelect
                      inputId="powerManagement"
                      name="powerManagement"
                      onChange={(e) => handleFieldChange('powerManagement', e)}
                      options={powerSharingOptions}
                      value={{
                        label: powerSharingOptions.filter(
                          (option) => option.value === settings.power_management_type,
                        )[0]?.label,
                      }}
                      selected={{
                        value : settings.power_management_type,
                        label : powerSharingOptions.filter(
                          (option) => option.value === settings.power_management_type,
                        )[0]?.label,
                      }}
                    />
                  </KitForm.Group>

                  { hasPowerManagementType && (
                    <>
                      <KitForm.Group className="field-group">
                        <KitForm.Label
                          htmlFor="tariff"
                          text={t('energy_management.tariff')}
                        />
                        <KitForm.Input
                          aria-disabled={true}
                          className="disabled"
                          data-qa-id="tarrif-input"
                          id="tariff"
                          name="tariff"
                          value={settings?.active_tariff?.name ?? t('not_set')}
                        />
                        { !KitUtilData.isEmpty(settings?.active_tariff)
                        && <KitTips
                          className="data-ttip light tariff-tttip"
                          content={<TTipContent>
                            <TariffTooltipContent tariff={settings.active_tariff} t={t} />
                          </TTipContent>}
                          placement="bottom"
                          arrow={false}
                        >
                          <TooltipTrigger
                            className="ttip-trigger"
                            aria-label={t('depots.overview.info_tooltip_aria')}
                            tabIndex={0}
                          >
                            <KitIcon aria-hidden icon="information-outline" />
                          </TooltipTrigger>
                        </KitTips>
                        }

                      </KitForm.Group>
                      <KitForm.Group>
                        <KitForm.Label
                          htmlFor="hardDemandLimit"
                          text={t(
                            'energy_management.hard_demand_limit',
                          )}
                        />
                        <KitInput
                          data-qa-id="hard-demand-limit-input"
                          id="hardDemandLimit"
                          name="hard_demand_limit_kw"
                          onChange={(value) => handleFieldChange('hardDemandLimit', value)
                          }
                          type="number"
                          min={MIN_SITE_DEMAND_LIMIT}
                          value={settings?.hard_demand_limit_kw}
                          isError={Boolean(errors.hard_demand_limit_kw)}
                          infoMessage={errors.hard_demand_limit_kw?.message}
                        />
                      </KitForm.Group>
                    </>
                  ) }
                </Column>

                <Column>
                  { hasPowerManagementType && (
                    <>
                      <KitForm.Group>
                        <KitForm.Label
                          htmlFor="targetDemand"
                          text={t('energy_management.targetDemand')}
                        />
                        <KitInput
                          data-qa-id="target-demand-input"
                          name="demand_target_kw"
                          id="targetDemand"
                          type="number"
                          isError={!!errors.demand_target_kw}
                          infoMessage={errors?.demand_target_kw?.message}
                          onChange={(e) => handleFieldChange('targetDemand', e)}
                          value={settings.demand_target_kw}
                        />
                      </KitForm.Group>
                      <KitForm.Group>
                        <KitForm.Label
                          htmlFor="sharingAlgorithm"
                          text={t('energy_management.sharingAlgorithm')}
                        />
                        <KitInput
                          aria-disabled={true}
                          className="disabled"
                          data-qa-id="sharing-algorithm-input"
                          id="sharingAlgorithm"
                          name="sharingAlgorithm"
                          value={t('energy_management.optimizeByFleet')}
                        />
                      </KitForm.Group>

                      <SharingAlgorithmLabel>
                        { t('energy_management.fleetOptimization') }
                      </SharingAlgorithmLabel>
                      <DragDropContainer data-qa-id="draggable-item-container">
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            { (provided) => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                { fleetOptimizations
                                  .filter((op) => !op.delete && op.fleet_external_id !== '-1')
                                  .map(({ fleet_external_id: id, name }, index) => (
                                    <Draggable
                                      key={id}
                                      draggableId={id}
                                      index={index}
                                    >
                                      { (provided, snapshot) => (
                                        <PortalAwareItem
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          provided={provided}
                                          snapshot={snapshot}
                                        >

                                          <DraggableItem
                                            data-qa-id="fleet-optimization-draggable-item"
                                            key={`div${id}`}
                                            data-optimization={id}
                                            onClick={() => editOptimization(id)}
                                            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                                            style={getItemStyle(snapshot.isDragging)}
                                          >
                                            <DraggableItemName title={name}>
                                              { name }
                                            </DraggableItemName>
                                            <DeleteButton id={id} onClick={handleDelete} />
                                          </DraggableItem>
                                        </PortalAwareItem>
                                      ) }
                                    </Draggable>
                                  )) }
                                { provided.placeholder }
                              </div>
                            ) }
                          </Droppable>
                        </DragDropContext>
                        <AllOthersItem
                          key="optimization:-1"
                          data-optimization={-1}
                        >
                          <DraggableItemName title={allOthersElement?.name}>
                            { allOthersElement?.name }
                          </DraggableItemName>
                        </AllOthersItem>
                        <LinkStyled onClick={addOptimization} data-qa-id="add-fleet-btn">
                          <KitIcon icon="plus" size={'12px'} fill={ThemeColors.blue_50} />
                          <span> { t('energy_management.addFleetLink') }</span>
                        </LinkStyled>
                      </DragDropContainer>

                      {
                        hasValue(SiteDetailsStore.siteSettings) && (<AddFleetOptimization
                          omittedFleetIDs = {fleetOptimizations.map((opt) => opt.fleet_external_id)}
                          fleetOptimization={selectedFleetOptimization}
                          onSave={handleOptimizationSave}
                          onCancel={handleOptimizationCancel}
                          show={showOptimizationModal}
                        />)
                      }

                      <Dialog
                        title={t(
                          'energy_management.confirmFleetOptimizationDelete',
                        )}
                        t={t}
                        type="confirm"
                        payload={selectedFleetOptimization?.fleet_external_id}
                        message={getTransComponent('energy_management.removeFleetOptimization', { name: selectedFleetOptimization?.name as string })}
                        show={showConfirmDelete}
                        onAction={(action: string, fleetId: unknown) => handleOptimizationDelete(fleetId as string)
                        }
                        onClose={() => setShowConfirmDelete(false)}
                        cancelText={t('no')}
                        confirmText={t('yes')}
                      />
                    </>
                  ) }
                </Column>
              </ResponsiveKitFlexRow>
            </KitForm>
          </>
        ) }
      </KitModal.Body>

      <KitModalFooter>
        <KitButtonBar
          primary={
            <KitButton
              variant="primary"
              disabled={!KitUtilData.isEmpty(errors) && hasPowerManagementType}
              onClick={handleSubmit}
              data-qa-id="energy-modal-apply-btn"
            >
              { t('apply') }
            </KitButton>
          }
          secondary={
            <KitButton
              variant="secondary"
              onClick={handleCancel}
              data-qa-id="energy-modal-cancel-btn"
            >
              { t('btn_cancel') }
            </KitButton>
          }
        />
      </KitModalFooter>
    </EnergyModalWrapper>
  )
}

export default observer(EnergySettingsModal)
