import { KitUtilValidation } from '@chargepoint/cp-toolkit'

import { type Schedule } from '@/models/scheduleModel'
import { type SimpleType } from '@/types/index'

// basic test for required fields
export const hasValue = (val: SimpleType | SimpleType[]): boolean => (Array.isArray(val)
  ? Boolean(val.length)
      && val.filter((v) => hasValue(v)).length === val.length
  : val !== undefined && val !== null && val !== '')

// check for schedule values
// TODO: VehicleSchedule interface will change in future sprints as fleet scheduling matures
export const hasSchedule = (
  schedule?: Schedule,
  required: { field: string }[] = [],
): boolean => {
  if (!schedule) {
    return false
  }
  let result
  const keys   = Object.keys(schedule)
  const fields = required?.map((r) => r.field)
  if (schedule) {
    if (required?.length > keys.length) {
      return false
    }

    result = Object.entries(schedule).every(([key, val]) => {
      const isSet = hasValue(val)
      if (fields.includes(key)) {
        return isSet
      }
      return true
    })
  }

  // all required fields have values, but validate that the values are correct
  if (result) {
    ['arrival_time', 'departure_time'].forEach((f) => {
      if (fields?.includes(f)) {
        result =          result
          && typeof schedule[f] === 'string'
          && (schedule[f] as string).split(':').length >= 2
      }
    })
  }

  return result
}

export const isValid = (
  valueType: 'email' | 'phone',
  value: string,
): boolean => {
  switch (valueType) {
    case 'email':
      return KitUtilValidation.validateEmail(value)
    case 'phone': {
      /* TODO: define what a 'valid' phone number means.
         We don't require accurate phone number validation yet (at least in Fleet Platform), so
        for now we'll only test for min and max length based on ITU-T E.164.
       - Max length = 15 digits (does not include non-numeric formatting marks).
       - Min length = 5 digits (5 digit number used in the Solomon islands)
      */
      const minPhoneDigits = 5
      const maxPhoneDigits = 15
      // remove non-numeric chars
      const cleaned = value.replace(/[^\d.-]+/g, '')
      return (
        hasValue(cleaned)
        && cleaned.length >= minPhoneDigits
        && cleaned.length <= maxPhoneDigits
      )
    }
    default:
      return hasValue(value)
  }

  return false
}
