import {
  KitFlexRowSpaced,
  KitForm,
  KitModal,
  KitPageTitle,
  KitTabList,
  KitTabPanel,
  KitTable,
  KitTextArea,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import styled, { css } from 'styled-components'

import staleDataBGFill from '../../images/fills/12px-fw-angle-white.png'
import { fadeIn } from '@/common/animations'
import { UILayout } from '@/common/constants'

const { breakpoints, duration, fontSize, fontWeight, spacing } = ThemeConstants

export const CardContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const CardErrorContainer = styled.div`
  margin: ${spacing.absolute.m}px
`

export const CardLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  grid-gap: ${spacing.absolute.l}px;
  && {
    > div {
      margin: ${spacing.absolute.xs}px;
      max-height: 202px;
    }
  }

  @media all and (max-width: ${breakpoints.md}px) {
    grid-gap: ${spacing.absolute.m}px;
    justify-content: space-around;
  }
`

export const CenteredMessage = styled.div`
  text-align: center;
  display: inline-block;
  margin: auto;
`

export const ChartContainer = styled.div`
  position: relative;
  background: ${ThemeColors.white};
  height: fit-content;
  min-height: 380px;
  margin-top: ${spacing.absolute.s}px;
  padding: 0;

  // recharts y-axis
  .recharts-cartesian-axis-ticks {
    .recharts-cartesian-axis-tick-value {
      tspan {
        font-size: ${({ theme }) => theme.charts.axis.ticks.fontSize}rem;
        color: ${({ theme }) => theme.charts.axis.ticks.color};
      }
    }
  }

  // recharts x-axis
  .recharts-cartesian-axis-ticks {
    text {
      font-size: ${({ theme }) => theme.charts.axis.ticks.fontSize}rem;
      fill: ${({ theme }) => theme.charts.axis.ticks.color};
    }
  }
`

export const CloseButton = styled.button<{ size?: number }>`
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 ${spacing.xxs}rem;
  width: ${({ size }) => (size ? `${size}px` : '20px')};
  height: ${({ size }) => (size ? `${size}px` : '20px')};
  line-height: ${({ size }) => (size ? `${size}px` : '20px')};
  position: relative;
  color: ${ThemeColors.gray_50};
  font-size: ${({ size }) => (size ? `${size}px` : '30px')};
  top: 0;

  &:focus {
    &:after {
      box-shadow: 0 0 0 2px ${ThemeColors.blue_50};
    }
  }

  &:after {
    content: '×';
    position: relative;
  }

  &:hover {
    color: ${ThemeColors.red_50};
  }
`

export const Container = styled.div<{ margin?: number }>`
  font-size: ${fontSize.text_14}rem;
  padding: 0;
  &.default {
    padding: ${spacing.absolute.l}px;
  }
  margin-left: ${({ margin }) => margin ?? 0}px;

  &.fade-in {
    animation: ${fadeIn} 0.8s;
  }
`

export const Description = styled.div`
  color: ${ThemeColors.gray_50};
  font-size: ${fontSize.text_14}rem;
  font-weight: ${fontWeight.normal};
  margin-bottom: ${spacing.absolute.s}px;
  line-height: ${fontSize.text_20}rem;
`

export const EllipsisText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const FadeInContainer = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-in forwards;
`

export const FieldName = styled.div`
  color: ${ThemeColors.gray_50};
  font-size: ${fontSize.text_14}rem;
`

export const FieldSet = styled.fieldset`
  border: 0;
  legend {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.absolute.sm}px;
  }
`

export const FieldValue = styled.div<{ small?: boolean }>`
  color: ${ThemeColors.gray_90};
  font-size: ${({ small }) => (small ? fontSize.text_14 : fontSize.base)}rem;
  margin-top: ${spacing.absolute.xs}px;
`

export const FilterGroupLabel = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.text_14}rem;
  margin-bottom: ${spacing.absolute.s}px;
`

export const FormError = styled.div`
  color: ${ThemeColors.red_50};
  margin: ${spacing.absolute.xs}px 0;
  font-size: ${fontSize.text_14}rem;
`

export const FormGroupWithReset = styled(KitForm.Group)`
  display: flex;
  justify-content: space-between;
`

export const FormItem = styled.div`
  margin-bottom: ${spacing.absolute.s}px;
`

export const FormItemWrapperMaxWidth = styled.div`
  max-width: 350px;
`

export const FullWidthDiv = styled.div`
  width: 100%;
`

export const GetStartedMessage = styled.div`
  font-size: ${fontSize.text_14}rem;
`

export const HeaderContainer = styled.div<{ bottomMargin?: string }>`
  background-color: ${ThemeColors.white};
  border-bottom: 1px solid ${ThemeColors.gray_10};
  font-size: ${fontSize.text_14}rem;
  padding: ${spacing.xs}rem 0 0;
  && {
    h1 {
      margin-bottom: ${({ bottomMargin }) => bottomMargin || ''};
    }
  }
`

export const HiddenKitTabPanels = styled(KitTabPanel)`
  && {
    display: none;
  }
`

export const InputGroup = styled.div`
  display: flex;
  > div {
    margin-right: ${spacing.l}rem;
  }
  margin-bottom: ${spacing.absolute.l}px;
`

// Fixes styles for KitTable
export const KitTableWrapper = styled.div<{ xOverflow?: boolean }>`
  overscroll-behavior: auto;
  && {
    table {
      box-shadow: ${({ theme }) => theme.table.shadow};
      thead {
        inset-block-start: 0px;
        position: sticky;
        z-index: 99;
        svg {
          vertical-align: top;
        }
        td {
          background-color: ${ThemeColors.gray_10};
        }
        tr {
          th {
            height: ${spacing.absolute.xl + spacing.absolute.sm}px;
            line-height: ${spacing.absolute.xl + spacing.absolute.sm}px;
            padding: 0;
            button {
              color: ${ThemeColors.gray_70};
              align-items: center;
              margin-right: ${spacing.absolute.sm}px;

              margin: ${spacing.absolute.xxs}px;
              width: 96%;
            }
            &:first-child {
              padding: 0 0 0 ${spacing.l}rem;
              button {
                margin-left: 0;
                padding-left: 0;
              }
            }
            &:last-child {
              padding-right: ${spacing.absolute.l}px;
            }
          }
        }
      }
      tbody {
        background-color: ${ThemeColors.white};
        tr {
          transition: ${duration.short};
          &:hover {
            background-color: ${ThemeColors.gray_05}AA;
          }
          td {
            height: ${spacing.absolute.xl + spacing.absolute.sm}px;
            vertical-align: middle;
            padding-left: ${spacing.absolute.s}px;
            &:first-child {
              padding-left: ${spacing.absolute.l}px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: ${breakpoints.md}px) {
    overflow: auto;
    max-height: ${({ xOverflow }) => (xOverflow ? 'calc(100vh - 80px)' : 'auto')};
  }
`

export const Label = styled.label<{ block?: boolean }>`
  font-size: ${fontSize.text_14}rem;
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  margin-bottom: ${spacing.absolute.sm}px;
  &.required {
    &:after {
      content: '*';
      margin-left: ${spacing.absolute.xs}px;
      font-size: ${fontSize.text_14}rem;
    }
  }
`

export const List = styled.ul<{ listType?: string }>`
  margin: 0;
  padding: 0;
  list-style-type: ${({ listType }) => listType || 'none'};
`

export const ListItem = styled.li`
  padding: ${spacing.absolute.s}px;
`

export const ListItemSmall = styled.li`
  padding: ${spacing.absolute.xxs}px;
  color: ${ThemeColors.gray_50};
`

export const ModalWrapper = styled.div`
  padding: ${spacing.absolute.xl}px ${spacing.absolute.xl}px 0
    ${spacing.absolute.xl}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  @media all and (max-width: ${breakpoints.sm}px) {
    padding: ${spacing.absolute.m}px ${spacing.absolute.m}px 0
      ${spacing.absolute.m}px;
  }
`

export const NotificationWrapper = styled.div`
  margin: ${spacing.absolute.xl}px ${UILayout.hPageMargin}px 0;
  @media all and (max-width: ${breakpoints.md}px) {
    margin: ${spacing.absolute.m}px ${UILayout.mobile.hPageMargin}px 0;
  }
`

export const PaddedKitTextArea = styled(KitTextArea)`
  padding-top: ${spacing.absolute.s}px;
  height: 50px;
`

export const PaddedMessage = styled.div`
    font-size: ${fontSize.base}rem;
    padding ${spacing.absolute.m}px;
`

export const PageContainer = styled.div`
  flex-grow: 1;
  margin: ${ThemeConstants.spacing.xl}rem ${UILayout.hPageMargin}px;
  padding-bottom: ${ThemeConstants.spacing.s}px;
  @media all and (max-width: ${breakpoints.md}px) {
    padding: ${spacing.absolute.m}px;
    margin: 0;
  }

  .error-list {
    margin-bottom: ${spacing.m}rem;
  }
`

export const PageContent = styled.div`
  padding: ${spacing.l}rem;
  @media all and (max-width: ${breakpoints.md}px) {
    margin: ${spacing.absolute.m}px 0;
    padding: 0 ${spacing.absolute.m}px;
  }
`

export const PaginationText = styled.div`
  margin-top: ${ThemeConstants.spacing.absolute.m}px;
  text-align: center;
  color: ${ThemeColors.gray_50};
  font-size: ${ThemeConstants.fontSize.text_14};
`

export const PaginationWrapper = styled.div`
  margin-top: ${ThemeConstants.spacing.absolute.l}px;
`

export const ResponsiveKitFlexRow = styled(KitFlexRowSpaced)`
  @media all and (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    max-width: inherit;
  }
`

export const ResponsiveKitFormGroup = styled(KitForm.Group)`
  max-width: 300px;
  @media all and (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    max-width: inherit;
  }
`

export const SROnly = styled.span`
  position: absolute;
  left: -2000px;
  clip: rect(0px, 0px, 0px, 0px);
`

/* Add Vehicle Summary components */
export const Section = styled.section`
  min-width: 200px;
  max-width: 300px;
  margin-bottom: ${spacing.absolute.m}px;

  &:not(:first-child) {
    margin-left: ${spacing.absolute.m}px;
  }
`

export const SectionItem = styled(FormItem)`
  margin-bottom: ${spacing.absolute.s}px;
  line-height: ${fontSize.base}rem;
`

export const SettingsCardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${spacing.absolute.l}px;

  > div {
    background: ${ThemeColors.white};
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 350px;
    max-width: 350px;
    margin: ${spacing.absolute.xxs}px 0;
  }

  @media all and (max-width: ${breakpoints.sm}px) {
    justify-content: center;
    > div {
      max-width: 100%;
    }
  }
`

export const Spacer = styled.div<{ orientation?: string; size: number }>`
  ${({ orientation, size }) => (orientation === 'vertical'
    ? css`
          height: ${size}px;
        `
    : css`
          width: ${size}px;
          display: inline-block;
        `)}
`

export const Strong = styled.strong`
  font-size: ${fontSize.text_14}rem;
`

export const StyledErrorMessage = styled.div<{
  padding?: string;
  textSize?: number;
}>`
  color: ${ThemeColors.red_50};
  font-size: ${({ textSize }) => textSize ?? fontSize.base}rem;
  font-weight: ${fontWeight.bold};
  padding: ${({ padding }) => padding ?? spacing.absolute.s}px;
`

// TODO: migrate this back to KitPageTitle
export const StyledKitPageTitle = styled(KitPageTitle)`
  margin-left: ${spacing.absolute.m + spacing.absolute.s}px;
  > div:first-child {
    margin-top: ${spacing.absolute.m + 1}px;
    display: inline-block;
    max-width: 30px;
  }
  > div:nth-child(2) > div {
    color: ${ThemeColors.gray_50};
  }
`

export const StyledKitTabList = styled(KitTabList)`
  && {
    padding-left: ${UILayout.hPageMargin}px;
  }
  > li.is-selected {
    color: ${ThemeColors.blue_50};
  }

  @media all and (max-width: ${breakpoints.md}px) {
    && {
      padding-left: ${UILayout.mobile.hPageMargin}px;
    }
  }
`

export const StyledModalFooter = styled(KitModal.Footer)`
  box-shadow: 0px -1px 4px 2px rgb(0 0 0 / 8%);
  border-top: none;
  padding-left: ${spacing.absolute.xl}px;
  padding-top: ${spacing.absolute.sm}px;
  height: 58px;
  line-height: 58px;
`

export const SubHeaderContainer = styled(HeaderContainer)`
  padding: ${ThemeConstants.spacing.l}rem 0 0;
`

export const SubHeading = styled.div`
  font-size: ${fontSize.h5}rem;
  margin-bottom: ${spacing.absolute.xs}px;
`

export const SubHeadingBold = styled.h4`
  font-size: ${fontSize.h4}rem;
  font-weight: ${fontWeight.bold};
  margin-bottom: ${spacing.absolute.s}px;
`

export const SummaryContainer = styled.div`
  display: flex;
  flex-flow: wrap;
`

export const SummaryField = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => (
  <SectionItem>
    <FieldName>{ label }</FieldName>
    <FieldValue>{ value ?? '--' }</FieldValue>
  </SectionItem>
)

export const TableCell = styled.td<{
  align?: string;
  maxWidth?: number;
  minWidth?: number;
  verticalAlign?: string;
  backgroundColor?: string;
}>`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  position: relative;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'inherit')};
  font-size: ${fontSize.text_14}rem;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : null)};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : null)};
  && {
    padding: ${(props) => (props.align === 'right' ? `${spacing.absolute.sm}px` : '0')};
  }

  .is-stale {
    font-style: italic;
    background: url(${staleDataBGFill});
  }
`

export const TableHeaderCell = styled(KitTable.Th)<{ align?: string }>`
  && {
    padding: ${spacing.sm}rem;
  }

  button {
    justify-content: ${({ align }) => (align === 'right' ? 'end' : 'space-between')};
    > span {
      margin-right: ${({ align }) => (align === 'right' ? `${spacing.absolute.xs}px` : '0')};
    }
    > div {
      margin-right: -${spacing.xxs}rem;
    }
  }
`

export const TooltipTrigger = styled.button<{ align?: 'start' | 'end' }>`
  text-align: ${({ align }) => align};
  background: transparent;
  border: 0;
  display: inline-block;
  width: fill-available;
  width: -webkit-fill-available
  padding: ${spacing.absolute.s}px;
  &:focus {
    outline: 2px solid ${({ theme }) => theme.focus};
  }
`

export const autoWidth = css`
  width: auto;
  max-width: none;
`

export const responsiveBottomMargin = css`
  @media all and (max-width: ${breakpoints.sm}px) {
    margin-bottom: ${spacing.absolute.l}px;
  }
`
