import {
  KitFlexCol,
  KitFlexRowSpaced,
  KitForm,
  KitSpinner,
  KitSwitch,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import set from 'lodash/set'

import { useEffect, useRef } from 'react'

import {
  ListItemContent,
  NotificationFieldSet,
  NotificationListContainer,
  NotificationTitle,
  SettingsContainer,
} from './index.styles'
import { Description } from '@/components/Styled'
import { type FleetNotification, type NotifyPreferences } from '@/models/settingsModel'

export const NotificationItem = ({
  item,
  onChange,
  t,
}: {
  item: FleetNotification;
  onChange: (key: string, fieldName: string, fieldValue: boolean) => void;
  t: TFunction;
}) => (
  <NotificationFieldSet tabIndex={0}>
    <ListItemContent>
      <KitFlexRowSpaced>
        <div>
          <NotificationTitle>{ item.name }</NotificationTitle>
          <Description className="desc" id={`${item.key}-description`}>
            { item.description }
          </Description>
        </div>
        <KitFlexCol>
          <KitForm.Group>
            <KitSwitch
              className="toggle"
              id={`${item.name}-email`}
              aria-describedby={`${item.key}-description`}
              label={t('fleet_settings.notifications.email_label')}
              checked={item.email}
              onChange={(e) => onChange(item.key, 'email', e.target.checked)}
            />
          </KitForm.Group>
          <KitForm.Group>
            <KitSwitch
              className="toggle"
              id={`${item.name}-sms`}
              aria-describedby={`${item.key}-description`}
              label={t('fleet_settings.notifications.sms_label')}
              checked={item.sms}
              onChange={(e) => onChange(item.key, 'sms', e.target.checked)}
            />
          </KitForm.Group>
        </KitFlexCol>
      </KitFlexRowSpaced>
    </ListItemContent>
  </NotificationFieldSet>
)

export const NotificationList = ({
  items,
  onChange,
  t,
}: {
  items: FleetNotification[];
  onChange: (payload: NotifyPreferences) => void;
  t: TFunction;
}) => {
  const changedItems = useRef<{ [key: string]: FleetNotification }>({})

  const handleOnChange = (
    key: string,
    fieldName: string,
    fieldValue: boolean,
  ) => {
    const updates = { ...changedItems.current }
    set(updates, `${key}.${fieldName}`, fieldValue)
    changedItems.current = { ...updates }
    onChange({ ...updates })
  }

  useEffect(() => {
    changedItems.current = {}
  }, [items])

  const content = Array.isArray(items) ? (
    <KitForm>
      { items.map((item) => (
        <NotificationItem
          key={item.key}
          item={item}
          onChange={handleOnChange}
          t={t}
        />
      )) }
    </KitForm>
  ) : (
    <KitSpinner />
  )

  return (
    <SettingsContainer>
      <NotificationListContainer>{ content }</NotificationListContainer>
    </SettingsContainer>
  )
}

export default NotificationList
