import { KitFlexRowSpaced, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import styled from 'styled-components'

import { ISO_DATE_TIME } from '@/common/constants'
import { formatDate } from '@/common/utils/date'
import { type Alert } from '@/models/alertModel'

export interface AlertRendererProps {
  alert: Pick<Alert, 'alert_code' | 'updated_time' | 'alert_subject_metadata'> &
  Record<string, unknown>;
  type: 'text';
  t: TFunction;
  timeZone?: string;
}

const Container = styled(KitFlexRowSpaced)`
  margin-top: ${ThemeConstants.spacing.xs}rem;
  width: 100%;
`

const AlertRenderer: FC<AlertRendererProps> = ({ alert, t, timeZone, type }) => {
  const fmt = ISO_DATE_TIME
  if (type === 'text') {
    return (
      <Container>
        { /* alerts sometimes have html entitites that do not render properly without dangerouslySetInnerHTML  */ }
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              `alerts.code.${alert.alert_code}`,
              alert.alert_subject_metadata,
            ),
          }}
        />
        <div> { formatDate(alert.updated_time, fmt, { timeZone }) }</div>
      </Container>
    )
  }
  return null
}

export default AlertRenderer
