import { KitForm, KitInput, KitRadio, KitTextSpacing } from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { Block } from './index.styles'
import { getScheduleTypeSOCLabel, scheduleToUI } from '@/common/utils/scheduling'
import { hasValue } from '@/common/utils/validations'
import ScheduleInputs from '@/components/scheduling/ScheduleInputs/index'
import { FormWrapper } from '@/components/scheduling/settings/index.styles'
import { Description, FormItem } from '@/components/Styled'
import type { Schedule } from '@/models/scheduleModel'
import { ScheduleType } from '@/models/scheduleModel'
import scheduleStore from '@/store/fleetVehicleScheduleStore'

export interface ScheduleFormProps extends Schedule {
  scheduleType: ScheduleType;
  setScheduleType: (scheduleType: ScheduleType) => void;
  handleFieldChange: (fieldName: string, fieldValue: string | number | unknown) => void;
  errors: Record<string, string>
}

export const FleetScheduleForm = (props: ScheduleFormProps) => {
  const {
    arrival_time,
    arrival_time_downtime_secs,
    departure_time,
    departure_time_downtime_secs,
    errors,
    handleFieldChange,
    required_soc,
    scheduleType,
    setScheduleType,

  } = props
  const { t } = useTranslation()
  return (
    <FormWrapper>
      <legend>
        <FormItem>
          <h5>
            { t('fleets.settings.scheduling_fleet_default_settings_label') }
          </h5>
          <KitTextSpacing.Group>
            <KitTextSpacing.SecondaryText>
              { t('fleets.settings.scheduling_fleet_ready_label') }
            </KitTextSpacing.SecondaryText>
          </KitTextSpacing.Group>
        </FormItem>
      </legend>
      <Block>
        <FormItem>
          <KitRadio
            name="scheduleType"
            value={ScheduleType.BY_TIME}
            label={t('fleets.settings.scheduling_fleet_ready_scheduled_time_option_label')}
            checked={scheduleType === ScheduleType.BY_TIME}
            onChange={() => setScheduleType(ScheduleType.BY_TIME)}
          />
        </FormItem>
        <FormItem>
          <KitRadio
            name="asap"
            value={ScheduleType.ASAP}
            label={t('fleets.settings.scheduling_fleet_ready_asap_option_label')}
            checked={props.scheduleType === ScheduleType.ASAP}
            onChange={() => setScheduleType(ScheduleType.ASAP)}
          />
        </FormItem>
      </Block>

      { scheduleType === ScheduleType.BY_TIME && (
        <Block>
          <FormItem>
            <ScheduleInputs
              name="arrival"
              labels={{
                time          : t('arrival_time'),
                downtime      : t('arrival_time_downtime_label'),
                downtimeUnits : t('units.minutes_short'),
              }}
              onChange={handleFieldChange}
              errors={errors}
              required
              values={{ arrival_time, arrival_time_downtime_secs }}
            />
          </FormItem>
          <FormItem>
            <ScheduleInputs
              name="departure"
              labels={{
                time          : t('departure_time'),
                downtime      : t('departure_time_downtime_label'),
                downtimeUnits : t('units.minutes_short'),
              }}
              onChange={handleFieldChange}
              errors={errors}
              required
              values={{ departure_time, departure_time_downtime_secs }}
            />
          </FormItem>
        </Block>
      ) }

      <Block>
        <FormItem>
          <KitForm.Label text={getScheduleTypeSOCLabel(scheduleStore.scheduleType, t)} htmlFor="required_soc" required />
          <KitInput
            id="required_soc"
            type="number"
            value={required_soc}
            onChange={(e) => handleFieldChange('required_soc', e.target.value)}
            min={0}
            max={100}
            maxLength={3}
            isError={hasValue(errors?.required_soc)}
            infoMessage={errors?.required_soc}
          />

          <span>%</span>
        </FormItem>
      </Block>

      <Description>{ t('fleets.settings.scheduling_blocks_message') }</Description>
    </FormWrapper>
  )
}

export const ScheduleWrapper = () => (<FleetScheduleForm
  {...scheduleToUI(scheduleStore.schedule, scheduleStore.scheduleType)}
  scheduleType={scheduleStore.scheduleType}
  setScheduleType={scheduleStore.setScheduleType}
  handleFieldChange={scheduleStore.setScheduleField}
  errors={scheduleStore.fieldErrors ?? {}}
/>)

export default observer(ScheduleWrapper)
