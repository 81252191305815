import { KitNotification } from '@chargepoint/cp-toolkit'
import { startOfDay, subDays } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TripHistoryTable from './Table'
import { getColumns, getSortedTripHistory, tripsMap } from './trips'
import { type VehicleTripHistoryRow } from './types'
import { DistanceUnits, Period } from '@/common/constants'
import { getUserPreferences } from '@/common/utils/user'
import { hasValue } from '@/common/utils/validations'
import Page from '@/components/Page'
import StyledSpinner from '@/components/StyledSpinner'
import { type Vehicle } from '@/models/vehicleModel'
import depotStore from '@/pages/DepotPropSheet/depotStore'
import vehicleStore from '@/pages/VehiclePropSheet/vehicleStore'
import VehicleService from '@/services/VehicleService'
import { type SortFunc } from '@/types/index'

// NOTE: This page is currently not being used anywhere, but it WILL soon be enabled again (see ES-4908)
// Prior to enabling this page, it will have to be refactored to use the vehicleStore,
// and to use common filtering logic used on ChargingHistory. THis page will BREAK if enabled as-is

export interface TripHistoryProps {
  vehicle?: Vehicle;
  dateFilters: { start?: string; end?: string; period?: string };
  onLoad: (enabled: boolean) => void;
}

const TripHistory = (): JSX.Element => {
  const [isLoaded, setIsLoaded]             = useState(false)
  const [hasTripHistory, setHasTripHistory] = useState(false)
  const [tripHistory, setTripHistory]       = useState<VehicleTripHistoryRow[]>()
  const { end, period, start }              = { start: null, end: null, period: null }

  const [sortKey, setSortKey] = useState('')
  const [sortDir, setSortDir] = useState<number>()
  const { t }                 = useTranslation()

  const userPreferredUnit =    getUserPreferences().length_units ?? DistanceUnits.MILES
  const columns           = getColumns(sortDir as number, t, userPreferredUnit)

  const { vehicle } = vehicleStore

  const onSortClicked = (field: string, dir: number) => {
    setSortKey(field)
    setSortDir(dir)
    setTripHistory(
      getSortedTripHistory(
        tripHistory as VehicleTripHistoryRow[],
        field,
        dir,
        columns,
      ),
    )
  }

  const sortColumn = columns.find((col) => col.field === sortKey)
  const sortedData = tripHistory?.concat()
  if (sortColumn && sortColumn.sortFn && hasValue(sortDir)) {
    sortedData?.sort(sortColumn.sortFn as SortFunc)
  }

  useEffect(() => {
    async function fetchData() {
      const today                         = startOfDay(new Date())
      const query: Record<string, string> = {}
      if (period === Period.CUSTOM) {
        if (start) {
          query.created_after = start
        }
        if (end) {
          query.created_before = end
        }
      }
      if (period === Period.TODAY) {
        query.created_after = today.toISOString()
      }
      if (period === Period.LAST_7_DAYS) {
        query.created_after = subDays(today, 8).toISOString()
      }
      if (period === Period.LAST_30_DAYS) {
        query.created_after = subDays(today, 31).toISOString()
      }

      const response = await VehicleService.getTripHistory(
        vehicle?.id as number,
        query,
      )
      if (response.error || response.status === 404) {
        setHasTripHistory(false)
      } else if (response.status === 204) {
        setHasTripHistory(false)
      } else {
        setHasTripHistory(true)
        setTripHistory(
          response.results.map((trip, idx) => tripsMap(trip, idx)),
        )
      }

      setIsLoaded(true)
    }
    if (vehicle) {
      fetchData()
    }
  }, [vehicle?.id, start, end, period])

  return (
    <Page
      title={t('page_titles.vehicle_trip_history', { name: depotStore.depotName })}
    >
      { !isLoaded && <StyledSpinner /> }
      { isLoaded && (
        <>
          { hasTripHistory ? (
            <TripHistoryTable
              t={t}
              sortKey={sortKey}
              onSortClicked={onSortClicked}
              columns={columns}
              data={tripHistory}
            />
          ) : (
            <KitNotification type="info">
              { t('trip_history.not_enabled') }
            </KitNotification>
          ) }
        </>
      ) }
    </Page>
  )
}

export default observer(TripHistory)
