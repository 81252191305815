import { KitLink } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import { type WizardScreenProps } from '@/components/AddVehicleWizard/config'
import { List, ListItem } from '@/components/Styled'

export interface NoUnknownVehiclesFoundProps
  extends Partial<WizardScreenProps> {
  t: TFunction;
  fetchActiveSessions: () => void;
}

const NoUnknownVehiclesFound: FC<NoUnknownVehiclesFoundProps> = ({
  fetchActiveSessions,
  t,
}) => (
  <>
    <List>
      <ListItem>
        { t('vehicles.add_vehicle.charging.vehicle_not_detected') as string }
      </ListItem>
      <ListItem>
        { t('vehicles.add_vehicle.charging.plug_in_message') as string }
      </ListItem>
      <ListItem>
        <KitLink onClick={fetchActiveSessions}>{ t('refresh') }</KitLink>
      </ListItem>
    </List>
  </>
)

export default NoUnknownVehiclesFound
