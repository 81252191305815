import {
  KitFlexRow,
  KitFlexRowSpaced,
  KitFlexWrapRow,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import styled, { css } from 'styled-components'

import { UILayout } from '@/common/constants'

const { breakpoints, fontSize, fontWeight, spacing } = ThemeConstants

export const filterLabelStyles = css`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.text_14}rem;
`

export const FilterFieldSet = styled.fieldset`
  border: 0;
  max-width: 350px;
  margin-bottom: ${spacing.absolute.s}px;
  a {
    max-height: ${spacing.absolute.m}px;
  }
  label {
    margin: 0;
  }
  ul {
    margin-top: ${spacing.absolute.s}px;
  }

  button.link {
    min-width: initial;
    padding: 0;
  }

  @media all and (max-width: ${breakpoints.sm}px) {
    width: auto;
    max-width: none;
  }
`

export const FilterLegend = styled.legend`
  color: ${({ theme }) => theme.form.fieldset.text};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.absolute.s}px;
  width: 100%;
  span {
    ${filterLabelStyles}
  }
`

export const StyledFiltersPanel = styled.div`
  position: relative;
  width: 300px;
  padding: ${spacing.absolute.m}px;

  .filter-components {
    margin-bottom: ${spacing.absolute.m}px;
  }

  @media all and (max-width: ${breakpoints.md}px) {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    height: -webkit-fill-available;
    padding: 0;
    width: auto;
  }
`

export const FilterBarContainer = styled.div`
  padding: ${spacing.absolute.m}px ${UILayout.hPageMargin}px;
  display: flex;

  button.action {
    min-width: 50px;
  }

  @media all and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    padding: ${spacing.absolute.m}px ${UILayout.mobile.hPageMargin}px;
  }
`

export const FilterBarCol = styled(KitFlexWrapRow)`
  display: flex;
  margin: 0 ${spacing.m}rem;
  @media all and (max-width: ${breakpoints.md}px) {
    margin: 0;
  }
`

export const FilterControlsWrapper = styled(KitFlexRowSpaced)`
  margin-top: ${spacing.absolute.s}px;
  width: 100%;
`

export const SearchCol = styled.div`
  min-width: 400px;

  @media all and (max-width: ${breakpoints.md}px) {
    min-width: 250px;
  }
`

export const NumMatchesSpan = styled.span`
  font-size: ${fontSize.text_14}rem;
  white-space: nowrap;
  color: ${ThemeColors.gray_50};

  @media all and (max-width: ${breakpoints.sm}px) {
    display: none;
  }
`

export const FiltersBlock = styled(KitFlexRow)`
  gap: ${spacing.absolute.l}px;
  align-items: center;
`

export const MarginTopCol = styled(FilterBarCol)`
  margin-top: ${spacing.absolute.s}px;
  display: flex;
`

export const RightAlignMarginTopCol = styled.div`
  text-align: right;
  margin-top: ${ThemeConstants.spacing.absolute.s}px;
  white-space: nowrap;
`
