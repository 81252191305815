import { observer } from 'mobx-react-lite'
import { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { buildFilters } from '../../common/utils/filters'
import AddFleetWizard from '../../components/AddFleetWizard'
import FiltersPanel, { getFilterConfig } from '../FleetList/Filter'
import {
  filterActions,
  fleetReducer,
  initialState,
} from '../FleetList/reducers'
import FleetDetailsHeader from './FleetDetailsHeader'
import { stringSearch } from '@/common/utils/data'
import AddVehicleWizard from '@/components/AddVehicleWizard'
import Page from '@/components/Page'
import { type Fleet } from '@/models/fleetModel'
import { type ModalComponentProps } from '@/pages/FleetList/interfaces'
import fleetStore from '@/store/fleetStore'

function getModalComponents({
  dispatch,
  numResults,
  params,
  showWizard,
  view,
}: ModalComponentProps): JSX.Element {
  const openWizard  = () => dispatch({ type: 'SHOW_WIZARD', open: true })
  const closeWizard = () => dispatch({ type: 'HIDE_WIZARD' })
  return {
    fleets: (
      <AddFleetWizard
        key={`add-fleet-flow-${showWizard}`}
        show={showWizard}
        openWizard={openWizard}
        closeWizard={() => dispatch({ type: 'SHOW_WIZARD', open: false })}
      />
    ),
    vehicles: (
      <AddVehicleWizard
        key={`add-vehicle-flow-${numResults}`}
        fleetExternalID={params.fleet_external_id}
        show={showWizard}
        onHide={closeWizard}
        openWizard={openWizard}
        closeWizard={closeWizard}
      />
    ),
  }[view] as JSX.Element
}

export interface FleetDetailsProps {
  match?: string;
}

const FleetDetails = (props: FleetDetailsProps): JSX.Element => {
  const [state, dispatch] = useReducer(fleetReducer, initialState)

  const { activeFilters, filters, filtersPanelOpen, showWizard } = state

  const params   = useParams()
  const navigate = useNavigate()
  const { t }    = useTranslation()
  const view     = 'vehicles'

  const filterValues = filters.reduce((acc, filt) => {
    acc[filt.name as string] = filt.value
    return acc
  }, {} as { [key: string]: unknown })

  const filterMap = {
    search: (searchText: string, record: Fleet[]) => {
      if (!searchText || !searchText.length || !record) {
        return true
      }
      const searchFields = ['name', 'license_plate', 'port']
      return stringSearch(
        searchText,
        record as unknown as Record<string, unknown>,
        searchFields,
      )
    },
  }

  const navigateBack = () => {
    navigate('/fleets')
  }

  function onSearch(searchText: string) {
    let newFilters
    if (!searchText || !searchText.length) {
      newFilters = buildFilters(activeFilters, { name: 'search' }, true)
    } else {
      newFilters = buildFilters(activeFilters, {
        name    : 'search',
        value   : searchText,
        noCount : true,
        fn      : filterMap.search,
      })
    }

    dispatch({ type: filterActions.SET_ACTIVE_FILTERS, payload: newFilters })
  }

  function applyFilters(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    e.stopPropagation()
    dispatch({
      type    : filterActions.SET_ACTIVE_FILTERS,
      payload : filters.concat().map((f) => ({ ...f })),
    })
  }

  function cancelFilterChange(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    e.stopPropagation()
    dispatch({ type: filterActions.CANCEL_CHANGES })
  }

  useEffect(() => {
    fleetStore.setView('vehicles', params.fleet_external_id)
  }, [params.fleet_external_id])

  useEffect(() => {
    fleetStore.setActiveFilters(activeFilters)
  }, [activeFilters])

  const { filterConfig, filtersChanged } = getFilterConfig(
    fleetStore.vehicles,
    dispatch,
    {
      currentFilters : filters,
      filterMap,
      view,
      values         : filterValues,
      t,
      skipFilters    : ['fleet_name'],
    },
  )

  const filterComponents = fleetStore.hasResults ? (
    <FiltersPanel
      config={filterConfig}
      onApplyFilters={applyFilters}
      onCancel={cancelFilterChange}
      onFiltersChanged={filtersChanged}
      t={t}
    />
  ) : null

  return (
    <>
      { getModalComponents({
        params,
        view,
        dispatch,
        showWizard,
      }) }
      <FleetDetailsHeader
        filters={filters}
        dispatch={dispatch}
        filterComponents={filterComponents}
        filtersPanelOpen={filtersPanelOpen}
        showAddVehicleWizard={fleetStore.hasResults}
        numMatches={fleetStore.filteredData.length}
        match={props.match}
        fleet={fleetStore.selectedFleet}
        t={t}
        navigateBack={navigateBack}
        onSearch={onSearch}
        showFiltersBar={fleetStore.results?.length > 0}
      />

      <Page title={t('page_titles.fleet_settings', { name: fleetStore.selectedFleet?.name })}>
        <Outlet context={{ dispatch }} />
      </Page>
    </>
  )
}

export default observer(FleetDetails)
