/*
 * TODO: This whole filters panel was written a long time ago and
 * should be refactored to use existing filter logic, but it's probably a couple days of work to do that.
 */
import {
  KitButton,
  KitButtonBar,
  KitCheck,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import FocusTrap from 'focus-trap-react'
import { type TFunction } from 'i18next'
import { type ChangeEvent, type Dispatch, type FC, type MouseEvent, useEffect, useState } from 'react'

import styled, { type CSSProperties } from 'styled-components'

import { filterActions } from './reducers'
import { VehicleStatusOptions } from '@/common/constants'
import { type FilterAction } from '@/common/reducers'
import { groupBy } from '@/common/utils/data'
import { buildFilters, focusTrapDelay } from '@/common/utils/filters'
import { getNormalizedVehicleStatusCode } from '@/common/utils/vehicle'
import { FilterItems } from '@/components/FilterComponents/FiltersPanel'
import { MobileFiltersHeader } from '@/components/FilterComponents/FiltersPanel/wrapperComponents'
import MakeModelYear from '@/components/FilterComponents/MakeModelYear'
import useMakeModelYear from '@/components/FilterComponents/MakeModelYear/useMakeModelYear'
import SearchableCheckedList from '@/components/FilterComponents/SearchableCheckedList'
import { FilterGroupLabel } from '@/components/Styled'
import { StyledFiltersPanel } from '@/components/Styled/filterBarStyles'
import { useLayout } from '@/hooks/useLayout'
import { type Fleet } from '@/models/fleetModel'
import { type Vehicle } from '@/models/vehicleModel'
import { type Filter, type FilterFunc } from '@/types/filters'
import { type ComponentConfig } from '@/types/index'

const { spacing } = ThemeConstants

const FILTER_TYPE_CHECK         = 'FILTER_TYPE_CHECK'
const FILTER_TYPE_CHECKLIST     = 'FILTER_TYPE_CHECKLIST'
const FILTER_TYPE_MAKEMODELYEAR = 'FILTER_TYPE_MAKEMODELYEAR'

const FilterItem = styled.div`
  margin-bottom: ${spacing.absolute.m}px;
`

const uniqueList = (
  data: Record<string, unknown>[],
  groupByField: string,
  fn: (result: {
    field: string;
    label: string;
    value: string;
  }) => boolean, /* item iterator function to massage filtered results */
) => {
  const fleetGroups = groupBy(data, groupByField)

  return Object.keys(fleetGroups).map((key) => {
    const result = {
      field : groupByField,
      label : key,
      value : key,
    }
    return fn ? fn(result) : result
  })
}

const componentMap = {
  FILTER_TYPE_CHECK: {
    component : KitCheck,
    val       : (e: ChangeEvent<HTMLInputElement>) => e.target.checked,
    fn        : (
      checked: boolean,
      record: Record<string, unknown>,
      { fieldName }: { fieldName: string },
    ): boolean => {
      if (!checked) {
        return true
      }
      return checked && record[fieldName] && record[fieldName].length
    },
  },
  [FILTER_TYPE_CHECKLIST]: {
    component : SearchableCheckedList,
    fn        : (
      val: unknown,
      record: Record<string, unknown>,
      { fieldName }: { fieldName: string },
    ): boolean => (val.length ? val.includes(record[fieldName]) : true),
  },
  [FILTER_TYPE_MAKEMODELYEAR]: {
    component : MakeModelYear,
    fn        : (
      val: unknown,
      record: Record<string, unknown>,
      { fieldName }: { fieldName: string },
    ): boolean => val === '*' || (record[fieldName] as unknown[]).includes(val),
  },
}

// return value for control
function getControlValue(filtDef: Filter, args: { val: unknown }) {
  if (filtDef && filtDef.val) {
    return filtDef.val(args)
  }
  return args.value ?? args
}

export interface FilterConfigOpts {
  currentFilters: Filter[];
  filterMap: { [key: string]: (val: unknown) => boolean };
  view: string;
  values: Record<string, unknown>;
  t: TFunction;
  skipFilters: string[];
}

interface FilterOpts {
  componentType: string;
  remove?: boolean;
  fn?: FilterFunc;
}

export function getFilterConfig(
  data: Fleet[] | Vehicle[],
  dispatch: Dispatch<FilterAction>,
  {
    currentFilters,
    filterMap,
    skipFilters = [],
    t,
    values,
    view,
  }: FilterConfigOpts,
) {
  function filtersChanged(filterName: string, args: unknown, opts: FilterOpts) {
    const { componentType, remove } = opts
    const filtDef                   = componentMap[componentType as keyof typeof componentMap]
    const value                     = getControlValue(filtDef, args)
    const fn                        = (() => {
      if (opts.fn ?? filtDef?.fn) {
        const func = opts.fn ?? filtDef?.fn
        return (val: unknown, record: Record<string, unknown>) => func(val, record, { fieldName: filterName })
      }

      return filterMap[filterName]
    })()

    const newFilters = buildFilters(
      currentFilters,
      {
        name: filterName,
        value,
        fn,
      },
      remove,
    )
    dispatch({ type: filterActions.SET_FILTERS, payload: newFilters })
  }

  const {
    makes,
    models,
    onChange: makeModelYearChange,
    selectedMake,
    selectedModel,
    selectedYear,
    years,
  } = useMakeModelYear(data, filtersChanged, { values, t })

  if (!data) {
    return { filtersChanged, filterConfig: {} }
  }

  const filterConfig = {
    fleets: [
      {
        component : FILTER_TYPE_CHECK,
        props     : {
          'data-qa-id' : 'alert_chk',
          name         : 'alerts',
          onChange     : (e: MouseEvent<HTMLInputElement>) => filtersChanged('alerts', e, { componentType: FILTER_TYPE_CHECK }),
          label        : t('common.filters.has_alerts'),
          checked      : values.alerts,
        },
      },
      {
        component : FILTER_TYPE_CHECK,
        field     : 'vehicles_count',
        props     : {
          'data-qa-id' : 'vehicles_count',
          name         : 'vehicles_count',
          onChange     : (e: MouseEvent<HTMLInputElement>) => filtersChanged('vehicles_count', e, {
            componentType : FILTER_TYPE_CHECK,
            remove        : values.vehicles_count,
            fn(v, record) {
              return record.vehicles_count > 0
            },
          }),
          label   : t('common.filters.has_vehicles'),
          checked : values.vehicles_count,
        },
      },
      {
        component : FILTER_TYPE_CHECK,
        field     : 'vehicle_plugged_in',
        props     : {
          'data-qa-id' : 'vehicle_plugged_in',
          name         : 'vehicle_plugged_in',
          onChange     : (e: MouseEvent<HTMLInputElement>) => filtersChanged('vehicle_plugged_in', e, {
            componentType : FILTER_TYPE_CHECK,
            remove        : values.vehicle_plugged_in,
            fn(v, record) {
              return record.vehicle_plugged_in > 0
            },
          }),
          label   : t('common.filters.vehicle_plugged_in'),
          checked : values.vehicle_plugged_in,
        },
      },
    ],
    vehicles: [
      {
        component : FILTER_TYPE_CHECKLIST,
        field     : 'fleet_name',
        props     : {
          name  : 'fleet_name',
          title : t('fleet', { count: 1 }),
          qaTag : 'fleet_name_filter_option',
          items : uniqueList(data, 'fleet_name', (item) => ({
            ...item,
            checked: values?.fleet_name?.includes(item.value),
          })).filter((item) => item.value !== 'null'),
          onChange   : (filterName: string, filterValue: unknown) => filtersChanged('fleet_name', filterValue, { componentType: FILTER_TYPE_CHECKLIST }),
          t,
          withSearch : false,
          withTags   : false,
        },
      },
      {
        component : FILTER_TYPE_CHECKLIST,
        field     : 'location',
        props     : {
          name  : 'location',
          title : t('depot', { count: 0 }),
          qaTag : 'location_filter_option',
          items : uniqueList(data, 'location', (item) => ({
            ...item,
            checked: values?.location?.includes(item.value),
          })).filter((opt) => opt.label !== 'null'),
          onChange   : (filterName: string, filterValue: unknown) => filtersChanged('location', filterValue, { componentType: FILTER_TYPE_CHECKLIST }),
          t,
          withSearch : false,
          withTags   : false,
        },
      },
      {
        component : FILTER_TYPE_CHECKLIST,
        field     : 'status',
        props     : {
          name  : 'status',
          title : t('status'),
          qaTag : 'status_filter_option',
          items : (() => {
            const uniq: string[] = []
            const results        = []
            data?.forEach((item) => {
              if (!uniq.includes(item.status)) {
                uniq.push(item.status)
                if (item.vehicle_status === VehicleStatusOptions.NOT_READY) {
                  results.push({
                    field   : 'vehicle_status',
                    label   : t('vehicles.status.not_set_up'),
                    value   : VehicleStatusOptions.NOT_READY,
                    checked : values.status?.includes(
                      VehicleStatusOptions.NOT_READY,
                    ),
                  })
                } else {
                  results.push({
                    field   : 'status',
                    label   : t(`status_pill.${item.status}`),
                    value   : item.status,
                    checked : values.status?.includes(item.status),
                  })
                }
              }
            })
            return results
          })(),
          onChange: (filterName: string, filterValue: unknown) => filtersChanged('status', filterValue, {
            componentType: FILTER_TYPE_CHECKLIST,
            fn(fv, record) {
              const val = getNormalizedVehicleStatusCode(record)
              return fv.length ? fv.includes(val) : true
            },
          }),
          t,
          withSearch : false,
          withTags   : false,
        },
      },

      {
        component : FILTER_TYPE_MAKEMODELYEAR,
        field     : 'make/model/year',
        style     : { marginTop: '16px' },
        props     : {
          name   : 'make/model/year',
          label  : `${t('make')}/${t('model')}/${t('year')}`,
          labels : {
            make  : t('make'),
            model : t('model'),
            year  : t('year'),
          },
          selectedMake,
          selectedModel,
          selectedYear,
          makes,
          models,
          years,
          onChange: makeModelYearChange,
        },
      },
    ],
  }

  const filtersResult = filterConfig[view as keyof typeof filterConfig]?.filter((filt: Filter) => !skipFilters.includes(filt.field as string))

  return {
    filtersChanged,
    filterConfig: { filters: filtersResult },
  }
}

const renderFilter = (cfg: ComponentConfig, t: TFunction) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Component        = componentMap[cfg.component].component
  const { group, props } = cfg
  return (
    <FilterItem style={cfg.style} key={props.name}>
      { group?.label && <FilterGroupLabel>{ t(group.label) }</FilterGroupLabel> }

      <Component {...props} />
    </FilterItem>
  )
}

type FiltersPanelProps = {
  config: {
    filters: Filter[];
  };
  t: TFunction;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  onApplyFilters: () => void;
};

const FiltersPanel: FC<FiltersPanelProps> = ({
  config,
  onApplyFilters,
  onCancel,
  t,
}: FiltersPanelProps) => {
  const { filters }                   = config ?? {}
  const { isMobile }                  = useLayout()
  const [panelStyles, setPanelStyles] = useState({})

  function applyStyles() {
    const filtersButton                      = document.querySelector('button[data-qa-id="filter_btn"]')
    const style:Partial<CSSStyleDeclaration> = {}

    if (filtersButton) {
      const filterButtonY         = filtersButton?.getBoundingClientRect().top ?? 0
      const filterPanelOffsetY    = 100
      const filtersPanelMaxHeight = window.innerHeight - (filterButtonY + filterPanelOffsetY)
      if (!isMobile) {
        style.maxHeight = `${filtersPanelMaxHeight}px`
      }
      style.overflowY = 'auto'
    }

    setPanelStyles(style)
  }

  function handleClickCapture(e: MouseEvent<HTMLDivElement>) {
    const targ = e.target as Element
    if (targ.getAttribute('data-testid') === 'modal-close') {
      onCancel(e)
    }
  }

  useEffect(() => {
    applyStyles()
  }, [])

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick : true,
        checkCanFocusTrap : focusTrapDelay(),
      }}
    >
      <StyledFiltersPanel
        onClickCapture={handleClickCapture}
        style={panelStyles}
        data-qa-id="filters_container"
      >
        { isMobile && <MobileFiltersHeader t={t} /> }
        <FilterItems>
          { filters
            && filters.map((filt, i) => (
              <FilterItem
                style={filt.style as CSSProperties}
                key={`${filt.name}-${i}`}
              >
                { renderFilter(filt, t) }
              </FilterItem>
            )) }
        </FilterItems>
        <KitButtonBar
          className="filterButtons"
          primary={
            <KitButton data-qa-id="apply_filter_btn" onClick={onApplyFilters}>
              { t('apply') }
            </KitButton>
          }
          secondary={
            <KitButton
              data-qa-id="cancel_filter_btn"
              variant="secondary"
              onClick={onCancel}
            >
              { t('btn_cancel') }
            </KitButton>
          }
        />
      </StyledFiltersPanel>
    </FocusTrap>
  )
}

export default FiltersPanel
