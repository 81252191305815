import {
  KitNavBar,
  type KitNavBarProps,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import { type MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import { AppRoutes } from '@/common/constants'
import { FLEET_ENERGY_MGMT_VIEW } from '@/common/permissions'
import utils, {
  getHelpURL,
  getProfileURL,
  isSettingsPageEnabled,
} from '@/common/utils'
import { omit } from '@/common/utils/data'
import {
  checkUserPermission,
  deleteCurrentUser,
  getCurrentUser,
  getFullName,
} from '@/common/utils/user'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { useLayout } from '@/hooks/useLayout'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import UserService from '@/services/UserService'
import appStore from '@/store/AppStore'
import { type NavItem } from '@/types/models'
import FeatureService, { Features } from '@/services/FeatureService'

const HeaderWrapper = styled.div`
  button span {
    font-size: ${ThemeConstants.fontSize.base}rem;
    min-width: 180px;
  }
`

export interface HeaderProps {
  t: TFunction;
}

export const getActiveIndex = (
  navLinks: NavItem[],
  pathName: string,
): number => {
  const index = navLinks.findIndex((navLink) => navLink.routes?.some((route: string) => {
    if (route === '/') {
      return pathName === route
    }
    // matches top-level routes
    if (pathName.includes(route)) {
      return true
    }
    // matches sub routes
    return pathName.startsWith(route)
  }))

  return index
}

const Header: React.FC<HeaderProps> = ({ t }: HeaderProps) => {
  const { cobrandInfo, errors } = appStore
  const showNav = appStore.initialized
  const navigate = useNavigate()
  const { isMobile } = useLayout()
  const { analyticsService } = useAnalyticsService()

  const handleMenuItemClick = (e: MouseEvent, path: string) => {
    e.preventDefault()
    if (errors) {
      appStore.clearErrors()
    }
    navigate(path)
  }

  const menuItems: NavItem[] = showNav && appStore?.user
    ? [
      {
        name: t('depot_plural'),
        path: AppRoutes.Root.path,
        routes: ['/', '/depots', '/depot'],
        onClick: (e) => handleMenuItemClick(e, '/'),
      },
      {
        name: t('fleet', { count: 0 }),
        path: AppRoutes.Fleets.path,
        routes: ['/fleet', '/vehicle'],
        onClick: (e) => handleMenuItemClick(e, AppRoutes.Fleets.path),
      },
      {
        name: t('energy'),
        path: AppRoutes.Energy.path,
        routes: [AppRoutes.Energy.path],
        disabled: !checkUserPermission(FLEET_ENERGY_MGMT_VIEW),
        onClick: (e) => handleMenuItemClick(e, AppRoutes.Energy.path),
      },
      {
        name: t('account'),
        path: AppRoutes.Account.path,
        routes: ['/account'],
        onClick: (e) => handleMenuItemClick(e, AppRoutes.Account.path),
      },
    ]
    : []

  const navLinks = menuItems.filter(({ disabled }) => disabled !== true)

  const currentUser = getCurrentUser()
  let { apps } = utils.getNavBarProps(currentUser?.apps, t)
  const activeNavLinkIndex = getActiveIndex(navLinks, window.location.pathname)
  const displayUserInfo = (currentUser && showNav) || isMobile

  const onLogout = () => {
    UserService.logOut().then(() => {
      analyticsService.trackEvent(analyticEvents.logout)
      // https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
      // resets mixpanel distinct_id on logout.
      analyticsService.reset()

      deleteCurrentUser()
      window.location.href = utils.getLogoutUrl()
    })
  }

  const onHelp = () => {
    const helpUrl: string = getHelpURL()
    window.open(helpUrl, '_blank')
  }

  const onAccount = () => {
    const accountUrl: string = getProfileURL()
    window.open(accountUrl, '_blank')
  }

  const onSettings = () => {
    navigate(AppRoutes.Settings.path)
    // this will force the user menu to close
    document.body.click()
  }

  const userMenuItems = [
    {
      label: t('profile'),
      onClick: onAccount,
    },
    {
      enabled: isSettingsPageEnabled(),
      label: t('page_titles.user_settings'),
      onClick: onSettings,
    },
    {
      label: t('logout'),
      onClick: onLogout,
    },
  ].filter((item) => item.enabled !== false)

  const omittedPropList: string[] = []
  if (!showNav) {
    omittedPropList.push('apps')
    omittedPropList.push('navLinks')
    apps = []
  }

  if (!FeatureService.isEnabled(Features.vctHelpEnabled)) {
    omittedPropList.push('onHelpFunc')
  }

  const conditionalNavBarProps: Partial<KitNavBarProps> = omit(
    {
      apps,
      navLinks,
      onHelpFunc: onHelp,
    },
    omittedPropList,
  )

  return (
    <HeaderWrapper>
      <KitNavBar
        username={getFullName(currentUser)}
        displayUserInfo={displayUserInfo}
        useReactRouter={false}
        userMenuItems={userMenuItems}
        t={t}
        displayPoweredBy={cobrandInfo?.displayPoweredBy}
        logo={cobrandInfo?.logo}
        openAppInNewTab={false}
        activeNavLinkIndex={activeNavLinkIndex}
        onLogoutFunc={onLogout}
        onAccountFunc={onAccount}
        sortApps={true}
        {...conditionalNavBarProps}
      />
    </HeaderWrapper>
  )
}

export default observer(Header)
