import { KitAppLayout, ThemeColors } from '@chargepoint/cp-toolkit'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import styled from 'styled-components'

import Footer from './Footer'
import Header from './Header'

export const BackgroundColorContainer = styled.div<{
  background_color?: string;
  with_bottom_border?: boolean;
}>`
  background-color: ${({ background_color, theme }) => background_color ?? theme.page.body.bg};
  border-bottom: ${(props) => (props.with_bottom_border ? `1px solid ${ThemeColors.gray_10}` : '')};
  flex-grow: 1;
`

const SharedRootLayout = () => {
  const { t } = useTranslation()
  return (
    <KitAppLayout>
      <KitAppLayout.Header>
        <Header t={t} />
      </KitAppLayout.Header>
      <KitAppLayout.Body>
        <Outlet />
      </KitAppLayout.Body>
      <KitAppLayout.Footer>
        <Footer t={t} />
      </KitAppLayout.Footer>
    </KitAppLayout>
  )
}

export default SharedRootLayout
