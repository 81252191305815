import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import classnames from 'classnames'
import styled from 'styled-components'

import { fadeIn } from '@/common/animations'

const { fontSize, fontWeight } = ThemeConstants

const StyledBadge = styled.div<{ size: number }>`
  align-items: center;
  animation: ${fadeIn} 0.8s;
  animation-fill-mode: forwards;
  background-color: ${ThemeColors.gray_30};
  color: ${ThemeColors.white};
  display: flex;
  font-size: ${fontSize.text_12}rem;
  font-weight: ${fontWeight.bold};
  justify-content: center;
  border: 0;
  opacity: 0;

  &.circle {
    border-radius: 50%;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }

  &.error {
    background-color: ${ThemeColors.red_50};
  }
`

export interface Props {
  children?: React.ReactNode;
  className?: string;
  variant?: 'default' | 'error';
  shape?: string;
  size?: number;
  tabIndex?: number
}

const Badge = ({
  children,
  className,
  shape = 'circle',
  size = 24,
  variant = 'default',
  ...otherProps
}: Props): JSX.Element => {
  const styles = {
    error   : 'error',
    default : 'default',
  }
  return (
    <StyledBadge
      size={size}
      className={classnames(
        className,
        styles[variant as keyof typeof styles],
        shape,
      )}
      {...otherProps}
    >
      { children }
    </StyledBadge>
  )
}

export default Badge
