import { KitIcon, ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import styled from 'styled-components'

const { breakpoints, duration, iconSize, spacing } = ThemeConstants

const TransparentButton = styled.button`
  background: transparent;
  border: 0;
  border-radius: 50%;
  display: flex;
  padding: ${spacing.absolute.s}px;
  position: absolute;
  right: ${spacing.absolute.m}px;
  top: ${spacing.absolute.s}px;
  transition: all ${duration.short} ease;

  &:focus {
    outline: 1px solid ${ThemeColors.blue_50};
  }
  &:hover {
    background: ${ThemeColors.gray_10};
    transform: scale(1.2);
    svg {
      fill: ${ThemeColors.gray_70};
    }
  }

  @media all and (max-width: ${breakpoints.sm}px) {
    display: none;
  }
`

export interface ChartSizeToggleButtonProps {
  onClick: () => void;
  expanded: boolean;
  label: string;
  className?: string;
}

const ChartSizeToggleButton: FC<ChartSizeToggleButtonProps> = ({
  className = '',
  expanded,
  label,
  onClick,
}) => (
  <TransparentButton
    className={className}
    tabIndex={0}
    aria-label={label}
    onClick={onClick}
  >
    { expanded && <KitIcon icon="collapse" size={`${iconSize.s}rem`} /> }
    { !expanded && <KitIcon icon="expand" size={`${iconSize.s}rem`} /> }
  </TransparentButton>
)

export default ChartSizeToggleButton
