/* eslint-disable no-underscore-dangle */
import { ErrorDisplayType, type ServiceError } from '../../models/serviceModel'
import { hasValue } from './validations'

export const parseServerError = (err?: ServiceError) => (err ? JSON.parse(err?.text ?? '') : '')

export const hasFullPageError = (errors: ServiceError[]) => hasValue(
  errors.find((err) => err.displayAs === ErrorDisplayType.FullPage),
)

// NOTE: Remove this after fleet backend returns proper JSON error codes for failed CRUD operations.
// This will primarily used to report to analytics for statistical purposes.
export const getDjangoErrorMessage = (serviceError: ServiceError, fallbackErrorMessage: string) => {
  // Attempt to hackily parse improperly formed Django error
  if (serviceError?.message) {
    try {
      const obj = JSON.parse(serviceError.message)
      if (obj._all_) {
        return obj._all_[0]
      }
    } catch (err) {
      return fallbackErrorMessage
    }
  }
}
