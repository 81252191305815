import { KitTab, KitTabPanel, KitTabs } from '@chargepoint/cp-toolkit'
import { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import utils from '@/common/utils'
import CommonHeader from '@/components/CommonHeader'
import { HeaderContainer, StyledKitTabList } from '@/components/Styled'

const HiddenKitPanel = styled(KitTabPanel)`
  && {
    display: none;
  }
`

const AccountHeader: FC = () => {
  const { t }      = useTranslation()
  const navigate   = useNavigate()
  const match      = useMatch('/account/*')
  const defaultKey = utils.getLastURLPath(match?.pathname as string)
  const tabs       = [
    {
      id    : '',
      label : t('telematics'),
    },
    {
      id       : 'schedule-blocks',
      label    : t('block_templates.blocks_other'),
      disabled : false,
    },
  ].filter((tab) => tab.disabled !== true)

  const getSelectedIndex = () => {
    const result = tabs.findIndex((tab) => tab.id === defaultKey)
    return result === -1 ? 0 : result
  }

  const [selectedIndex, setIndex] = useState<number>(getSelectedIndex())

  const onTabChange = (tabIndex: number) => {
    setIndex(tabIndex)
    const tabKey = tabs[tabIndex].id
    navigate(`${match?.pathnameBase}/${tabKey}`)
  }

  return (
    <HeaderContainer>
      <CommonHeader.Title>{ t('account') }</CommonHeader.Title>
      <KitTabs
        id="fleets-page-subnav"
        selectedIndex={selectedIndex}
        onSelect={(k: number) => onTabChange(k)}
      >
        <StyledKitTabList>
          { tabs.map((tab) => (
            <KitTab key={tab.id}>{ tab.label }</KitTab>
          )) }
        </StyledKitTabList>
        { tabs.map((tab) => (
          <HiddenKitPanel key={tab.id} />
        )) }
      </KitTabs>
    </HeaderContainer>
  )
}

export default AccountHeader
