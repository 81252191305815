import { KitTextSpacing, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import styled from 'styled-components'

import ReadOnlySchedule from '../scheduling/settings/ReadOnlySchedule'
import { type AddFleetReducerState } from './config'
import { SummarySectionHeading } from './styled'
import { EMPTY_VALUE_PLACEHOLDER } from '@/common/constants'
import { hasValue } from '@/common/utils/validations'
import { FieldName, FieldValue, SectionItem } from '@/components/Styled'
import { type Depot } from '@/models/depotModel'
import type { ScheduleType } from '@/models/scheduleModel'
import { ScheduleContext } from '@/models/scheduleModel'

const { spacing }                           = ThemeConstants
const { Group, PrimaryText, SecondaryText } = KitTextSpacing

const SummaryContainer = styled.div`
  .contact > div {
    margin-bottom: ${spacing.xs}rem;
  }

  .schedule-block {
    h4 {
      margin-bottom: ${spacing.xs}rem !important;
    }
  }
`

const Section = styled.section`
  margin-bottom: ${spacing.xl + spacing.s}rem;
`

type i18nProps = { key: string; vars: Record<string, unknown> };
const getLabel = (
  key: string,
  section: Record<string, unknown> | null,
  t: TFunction,
) => {
  const remappedKeys: Record<string, string | i18nProps> = {
    home_depot_external_id     : 'depot',
    fleet_description          : 'description',
    arrival_time_downtime_secs : {
      key  : 'arrival_time_downtime_readonly',
      vars : { numMinutes: section?.arrival_time_downtime_secs },
    },
    departure_time_downtime_secs: {
      key  : 'departure_time_downtime_readonly',
      vars : { numMinutes: section?.departure_time_downtime_secs },
    },
    schedule: 'scheduling',
  }

  if (remappedKeys[key]) {
    if (typeof remappedKeys[key] === 'string') {
      return t(remappedKeys[key] as string)
    }
    return t(
      (remappedKeys[key] as i18nProps).key,
      (remappedKeys[key] as i18nProps).vars,
    )
  }

  return t(key)
}

type SectionProps = {
  fields: { label: string; value: string | number | boolean }[];
  fieldMap: Record<string, unknown>;
  key: string;
  title?: string;
};

const renderSectionContent = (
  key: string,
  section: SectionProps,
  state: AddFleetReducerState,
  t: TFunction,
) => {
  const customRenderSections = {
    about: ({ description, home_depot_external_id, name }: Record<string, string>) => {
      const org_name = state.data.about?.org_name
      const depot    = state.data.about?.depots?.find(
        (d: Depot) => d.external_id === home_depot_external_id,
      )
      return (
        <KitTextSpacing>
          <Group>
            <PrimaryText>{ getLabel('fleet_name', null, t) }</PrimaryText>
            <SecondaryText>{ name }</SecondaryText>
          </Group>
          <Group>
            <PrimaryText>{ getLabel('description', null, t) }</PrimaryText>
            <SecondaryText>
              { hasValue(description) ? description : EMPTY_VALUE_PLACEHOLDER }
            </SecondaryText>
          </Group>
          <Group>
            <PrimaryText>
              { getLabel('fleets.depot_base_label', null, t) }
            </PrimaryText>
            <SecondaryText>{ depot?.name }</SecondaryText>
          </Group>
          <Group>
            <PrimaryText>{ getLabel('organization', null, t) }</PrimaryText>
            <SecondaryText>{ org_name }</SecondaryText>
          </Group>
        </KitTextSpacing>
      )
    },
    contact: ({ contact_id }: Record<string, number>) => {
      const contact = state.data?.contact?.find(
        (c) => c.nos_user_id === contact_id,
      )
      if (!contact) {
        return null
      }
      const fullName = `${contact.first_name} ${contact.last_name}`
      return (
        <KitTextSpacing>
          <Group className="contact">
            <SecondaryText>{ fullName }</SecondaryText>
            <SecondaryText>{ contact.email }</SecondaryText>
            <SecondaryText>{ contact.phone_number }</SecondaryText>
          </Group>
        </KitTextSpacing>
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    schedule: (_props: Record<string, unknown>) => (
      <ReadOnlySchedule
        meta={{ showContext: false }}
        schedule={section.fieldMap}
        scheduleType={section.fieldMap.scheduleType as ScheduleType}
        scheduleContext={ScheduleContext.FLEET}
        t={t}
      />
    ),
  }

  if (customRenderSections[section.key as keyof typeof customRenderSections]) {
    return customRenderSections[
      section.key as keyof typeof customRenderSections
    ](section.fieldMap)
  }

  return section.fields.map((field) => (
    <SummaryField
      key={field.value as string}
      {...field}
      section={section}
      t={t}
    />
  ))
}

const SummaryField: FC<{
  label: string;
  section: SectionProps;
  t: TFunction;
  value: string | number | boolean;
}> = ({ label, section, t, value }) => (
  <SectionItem>
    <FieldName>{ getLabel(label, section, t) }</FieldName>
    <FieldValue>{ value }</FieldValue>
  </SectionItem>
)

interface SummaryProps {
  state: AddFleetReducerState;
  sections: {
    [key: string]: Record<string, unknown>;
  };
  t: TFunction;
}

const Summary: FC<SummaryProps> = ({ sections, state, t }: SummaryProps) => {
  const summary = Object.keys(sections)
    .map((sectionKey) => ({
      key    : sectionKey,
      title  : getLabel(sectionKey, null, t),
      fields : Object.keys(sections[sectionKey]).map((fieldKey) => ({
        label : t(fieldKey),
        value : sections[sectionKey][fieldKey],
      })),
      fieldMap: sections[sectionKey],
    }))
    .filter((section) => section.fields.length) as SectionProps[]

  return (
    <SummaryContainer>
      { summary?.map((section) => (
        <Section key={section.title}>
          <SummarySectionHeading>{ section.title }</SummarySectionHeading>
          { renderSectionContent(section.key, section, state, t) }
        </Section>
      )) }
    </SummaryContainer>
  )
}

export default Summary
