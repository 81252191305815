import {
  KitForm,
  KitInput,
  KitRadio,
  KitRadioGroup,
  KitSelect,
  KitUtilData,
} from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import styled from 'styled-components'

import {
  type IdentificationSectionProps,
  type WizardScreenProps,
  isSerialNumberAlreadyPresent,
  isSerialNumberFromSession,
} from '../config'
import { VehicleIdTypes } from '@/common/constants'
import { has, toOptionsArray } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { WizardContent } from '@/components/AddFleetWizard/styled'
import { FieldSet } from '@/components/Styled'
import { type SelectChangeAction } from '@/models/formModel'
import { type TelematicsAccount } from '@/models/telematicsModel'
import {
  type NormalizedChangeEvent,
  type SimpleType,
  type WizardInputChangeFunc,
} from '@/types/index'

const Container = styled(WizardContent)`
  max-width: 350px;
`

export interface IdentificationScreenProps extends WizardScreenProps {
  onChange: WizardInputChangeFunc;
  values: Partial<IdentificationSectionProps>;
}

const IdentificationScreen: FC<IdentificationScreenProps> = (props) => {
  const dataKey = 'identification'
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'resetFields' does not exist on type 'Pro... Remove this comment to see the full error message
  const { onChange, resetFields, state, t, values } = props
  const { charging }                                = state.sections
  const usingTelematics                             = has(values, 'using_telematics')
    ? values?.using_telematics
    : null
  const normalizedRFID: string                      = (values?.rf_id ?? charging?.rf_id) as string
  const skipVehicleID                               = charging.id_type === 'none'
  const disabledFields                              = {
    mac_address:
      hasValue(state.data.unknownVehicleSession?.vehicle_mac_address)
      || state.sections.charging.id_type === VehicleIdTypes.ID_TYPE_RF_ID,
  }

  const showMACAddressField = (() => !isSerialNumberFromSession(state))()

  const telematicsAccountOptions = toOptionsArray(
    state.data.identification?.telematics_accounts as TelematicsAccount[],
    {
      labelField : 'name',
      valueField : 'id',
    },
  )

  function handleFieldChange(
    e: NormalizedChangeEvent,
    action?: SelectChangeAction,
  ) {
    let name
    let value

    // if event is from a KitSelect, an action object is passed instead of an onChange event object
    if (action) {
      name  = action.name
      value = e.value
    } else {
      name  = e.target.name
      value = e.target.value
    }

    let normalizedValue: SimpleType = value

    if (name === 'using_telematics') {
      normalizedValue = KitUtilData.toBoolean(value)
      if (!normalizedValue) {
        resetFields(dataKey, [
          'telematics_account_id',
          'vin',
          'telematics_device_id',
        ])
      }
    }

    onChange(dataKey, name, normalizedValue)
  }

  return (
    <Container>
      <FieldSet>
        <legend>
          { t('vehicles.add_vehicle.identification.vehicle_info_caption') }
        </legend>
        <KitForm>
          <KitForm.Group>
            <KitForm.Label
              htmlFor="name"
              text={t('fields.vehicle_name_label')}
              required
            />
            <KitInput
              name="name"
              id="name"
              onChange={handleFieldChange}
              required
              value={values?.name as string}
            />
          </KitForm.Group>
          <KitForm.Group>
            <KitForm.Label
              htmlFor="license_plate"
              text={t('license_plate')}
            />
            <KitInput
              name="license_plate"
              id="license_plate"
              onChange={handleFieldChange}
              value={values?.license_plate as string}
            />
          </KitForm.Group>
          <KitForm.Group>
            <KitForm.Label htmlFor="bin" text={t('fields.vin')} />
            <KitInput
              name={VehicleIdTypes.ID_TYPE_VIN}
              id="vin"
              onChange={handleFieldChange}
              value={values[VehicleIdTypes.ID_TYPE_VIN] as string}
            />
          </KitForm.Group>
          { !skipVehicleID && (
            <KitForm.Group>
              <KitForm.Label
                htmlFor="rf_id"
                text={t('fields.chargepoint_rfid_card_label')}
              />
              <KitInput
                name="rf_id"
                id="rf_id"
                onChange={handleFieldChange}
                value={normalizedRFID}
                disabled={isSerialNumberAlreadyPresent(state)}
              />
            </KitForm.Group>
          ) }
          { !skipVehicleID && showMACAddressField && (
            <KitForm.Group>
              <KitForm.Label
                htmlFor="mac_address"
                text={t('mac_address')}
              />
              <KitInput
                name="mac_address"
                id="mac_address"
                onChange={handleFieldChange}
                value={values?.mac_address as string}
                disabled={disabledFields.mac_address}
              />
            </KitForm.Group>
          ) }
          <KitForm.Group>
            <KitRadioGroup
              label={t(
                'vehicles.add_vehicle.identification.using_telematics_caption',
              )}
            >
              <KitRadio
                checked={usingTelematics as boolean}
                name="using_telematics"
                label={t('yes')}
                value={true}
                onChange={handleFieldChange}
              />
              <KitRadio
                checked={
                  (usingTelematics === null
                    ? null
                    : !usingTelematics) as boolean
                }
                name="using_telematics"
                label={t('no')}
                value={false}
                onChange={handleFieldChange}
              />
            </KitRadioGroup>
          </KitForm.Group>
          { usingTelematics && (
            <KitForm.Group>
              <KitForm.Label
                htmlFor="telematics_account_id"
                text={t('fields.telematics_account_name_label')}
              />
              <KitSelect
                id="telematics_account_id"
                name="telematics_account_id"
                aria-label={t('fields.telematics_account_name_label')}
                onChange={(e, action) => handleFieldChange(e, action as SelectChangeAction)
                }
                value={{
                  value : values?.telematics_account_id,
                  label : telematicsAccountOptions.filter(
                    (account) => account.value === values?.telematics_account_id,
                  )[0]?.label,
                }}
                defaultValue={{
                  value : values?.telematics_account_id,
                  label : telematicsAccountOptions.filter(
                    (account) => account.value === values?.telematics_account_id,
                  )[0]?.label,
                }}
                options={telematicsAccountOptions}
              />
            </KitForm.Group>
          ) }
          { values?.telematics_account_id && (
            <KitForm.Group>
              <KitForm.Label
                htmlFor="telematics_vehicle_search"
                text={t('fields.device_id')}
              />
              <KitInput
                id="telematics_vehicle_search"
                name="telematics_device_id"
                value={values?.telematics_device_id as string}
                onChange={handleFieldChange}
              />
            </KitForm.Group>
          ) }
        </KitForm>
      </FieldSet>
    </Container>
  )
}

export default IdentificationScreen
