/* eslint-disable camelcase */
import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import { paramsToQueryString } from '@/common/utils'
import { hasValue } from '@/common/utils/validations'
import {
  type Fleet,
  type FleetSettingsResponse,
} from '@/models/fleetModel'
import type { PreconditioningState } from '@/models/preconditioningModel'
import type { ScheduleResponse } from '@/models/scheduleModel'
import {
  type BaseServiceResponse,
  type PagedServiceResponse,
  type ServicePayload,
  type ServiceResponse,
} from '@/models/serviceModel'
import { type Vehicle } from '@/models/vehicleModel'
import { type DynamicObject } from '@/types/index'

const createService = (api: ServiceInterface) => ({
  deleteFleet(fleetId: number | string) {
    return api.delete(`/api/fleet/${fleetId}/`)
  },
  getVehicles(
    fleetId?: string,
    opts?: Record<string, unknown>,
  ): Promise<PagedServiceResponse<Vehicle>> {
    let url = '/api/vehicle/'
    if (hasValue(fleetId)) {
      url = `/api/fleet/${fleetId}/vehicles/`
    }
    const qs = paramsToQueryString({ limit: opts?.limit ?? 1000 })
    return wrapRequest(api.get(`${url}${qs}`, {}, opts)) as Promise<
    PagedServiceResponse<Vehicle>
    >
  },
  getFleets(
    query = { limit: 400 },
    opts?: Record<string, unknown>,
  ): Promise<ServiceResponse<Fleet[]>> {
    return wrapRequest(
      api.get(`/api/fleet/${paramsToQueryString(query)}`, undefined, opts),
    ) as Promise<ServiceResponse<Fleet[]>>
  },
  getFleetsByUrl(url: string) {
    return wrapRequest(
      api.get(`/api/fleet/${url}`, {}, { cache: false } as DynamicObject<boolean>),
    )
  },
  getFleet(fleetExternalID: string) {
    return wrapRequest<Fleet>(api.get(`/api/fleet/${fleetExternalID}/`))
  },
  getPreconditioningSettings(fleetExternalID: string) {
    return wrapRequest<PreconditioningState & BaseServiceResponse>(api.get(`/api/fleet/${fleetExternalID}/settings/preconditioning/`))
  },
  updatePreconditioningSettings(fleetExternalID: string, payload: ServicePayload<PreconditioningState>) {
    return wrapRequest(api.put(`/api/fleet/${fleetExternalID}/settings/preconditioning/edit/`, payload))
  },
  getFleetSettings(fleetExternalID: string) {
    return wrapRequest<FleetSettingsResponse>(
      api.get(`/api/fleet/${fleetExternalID}/settings/`),
    ) as unknown as Promise<FleetSettingsResponse>
  },
  getFleetSchedule(fleetExternalID: string): Promise<ScheduleResponse> {
    return wrapRequest<ScheduleResponse>(
      api.get(`/api/fleet/${fleetExternalID}/settings/schedule`),
    ) as Promise<ScheduleResponse>
  },
  addFleet(payload: ServicePayload) {
    return wrapRequest<BaseServiceResponse>(
      api.post('/api/add-fleet/', payload),
    )
  },
  updateFleetSettings(
    setting: string,
    fleetExternalID: string,
    payload: ServicePayload,
  ) {
    const endpoint = `/api/fleet/${fleetExternalID}/settings/${setting}/edit/`
    return wrapRequest<BaseServiceResponse & ScheduleResponse>(api.patch(endpoint, payload))
  },
  setFleetSettings(
    setting: string,
    fleetId: number,
    payload: ServicePayload,
  ) {
    const endpoint = `/api/fleet/${fleetId}/settings/${setting}/create/`
    return wrapRequest(api.post(endpoint, payload))
  },
  deleteFleetSchedule(fleetExternalID: string) {
    const endpoint = `/api/fleet/${fleetExternalID}/settings/schedule/delete/`
    return wrapRequest(api.delete(endpoint))
  },
  updateZoneAlerts(fleetExternalID: string, payload: ServicePayload) {
    const endpoint = `/api/fleet/${fleetExternalID}/zone-alerts/`
    return wrapRequest(api.post(endpoint, payload))
  },
  getZoneAlerts(fleetExternalID: string) {
    const endpoint = `/api/fleet/${fleetExternalID}/zone-alerts/`
    return wrapRequest(api.get(endpoint))
  },
})

export default createService(AbstractService())
