import { KitUtilData } from '@chargepoint/cp-toolkit'

import { safeJSONParse } from './json'
import {
  CURRENT_USER_KEY,
  LOCAL_STORAGE_USER_PREFERENCES_KEY,
  ORG_ID_KEY,
} from '@/common/constants'
import { type TemperatureUnits } from '@/common/lang'
import Storage from '@/common/utils/Storage'
import {
  type UserPreferences,
  type UserProfile,
  type UserProfileResponse,
} from '@/models/userModel'

export const setCurrentUser = (payload: Record<string, unknown>): void => {
  Storage.set(CURRENT_USER_KEY, JSON.stringify(payload))
}

export const setUserPreferences = (prefs: UserPreferences): void => {
  Storage.set(
    LOCAL_STORAGE_USER_PREFERENCES_KEY,
    JSON.stringify(prefs),
  )
}

export const getUserPreferences = (): UserPreferences => safeJSONParse(
  Storage.get(LOCAL_STORAGE_USER_PREFERENCES_KEY) as string ?? '{}',
) as unknown as UserPreferences

export const getCurrentUser = (): UserProfile => {
  const user   = Storage.get(CURRENT_USER_KEY)
  const result =  user ? JSON.parse(user) : null
  return !KitUtilData.isEmpty(result) ? result : null
}

export const getFullName = (user: UserProfile): string => {
  if (!KitUtilData.isEmpty(user)) {
    return user.first_name && user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username.toUpperCase()
  }
  return ''
}

export const deleteCurrentUser = (): void => {
  Storage.delete(CURRENT_USER_KEY)
}

export const setOrgId = (orgId: string): void => {
  if (orgId) {
    Storage.set(ORG_ID_KEY, orgId)
  } else {
    console.error('ERROR: setOrgId:: org_external_id cannot be null')
  }
}

export const getOrgId = (): string => Storage.get(ORG_ID_KEY) as string

export const checkUserPermission = (permissionName: string): boolean => {
  const currentUser = safeJSONParse(
    Storage.get(CURRENT_USER_KEY) as string,
  ) as unknown as UserProfile
  if (currentUser?.permissions) {
    return currentUser.permissions.includes(permissionName)
  }
  return false
}

// maps `/me` response into UserProfile
export const buildUserProfileObject = ({
  apps,
  permission_names,
  role,
  source,
  user,
  user_profile_url,
}: UserProfileResponse): UserProfile => ({
  ...user,
  permissions : permission_names,
  apps        : apps?.apps,
  role,
  source,
  user_profile_url,
} as UserProfile)

// parses user profile response into objects that the are used in React app
export const parseProfileResponse = (
  response: UserProfileResponse,
  localeCode: string,
) => {
  const {
    cobrand_info,
    date_format,
    language,
    length_units,
    temperature_units,
    volume_units,
  } = response
  return {
    userProfile     : buildUserProfileObject(response),
    userPreferences : {
      date_format,
      language,
      localeCode,
      length_units,
      temperature_units: temperature_units as TemperatureUnits,
      volume_units,
    },
    cobrandInfo:
      cobrand_info && !KitUtilData.isEmpty(cobrand_info)
        ? {
          displayPoweredBy : cobrand_info.cobrand_show_powered_by,
          logo             : {
            mobileLogoUrl   : cobrand_info.cobrand_logo_mobile,
            desktopLogoUrl  : cobrand_info.cobrand_logo_desktop,
            altText         : cobrand_info.name,
            ariaDescription : cobrand_info.description,
          },
        }
        : undefined,
  }
}
