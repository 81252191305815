import AbstractService, { type ServiceInterface, wrapRequest } from '.'
import { paramsToQueryString } from '@/common/utils'
import { type TimeSeriesResponse } from '@/models/chartModel'
import {
  type EnergyChartResponse,
  type EnergySettings,
  type Site,
  type StationGroup,
} from '@/models/energyModel'
import {
  type BaseServiceResponse,
  type ServicePayload,
  type ServiceResponse,
} from '@/models/serviceModel'
import { SitePowerFields } from '@/pages/Energy/types'

const createService = (api: ServiceInterface) => {
  const baseURL = '/energy/api'
  return {
    getSites() {
      return wrapRequest<ServiceResponse<Site[]>>(api.get(`${baseURL}/site/`))
    },
    // get single site
    getSite(siteID: string) {
      return wrapRequest<ServiceResponse<Site>>(
        api.get(`${baseURL}/site/${siteID}/`),
      )
    },
    getSiteSettings(siteID: string) {
      return wrapRequest<ServiceResponse<EnergySettings>>(
        api.get(`${baseURL}/site/${siteID}/energy-settings/`),
      )
    },
    getStationGroups(siteID: string) {
      return wrapRequest<ServiceResponse<StationGroup>>(
        api.get(`${baseURL}/site/${siteID}/station-groups/`),
      )
    },
    updateSettings(siteId: string, settings: ServicePayload<EnergySettings>) {
      return wrapRequest<BaseServiceResponse>(
        api.post(`${baseURL}/site/${siteId}/energy-settings/`, settings),
      )
    },
    getSitePowerData({
      groupBy,
      query,
    }: {
      query: ServicePayload;
      groupBy: string;
    }): Promise<TimeSeriesResponse> {
      const pathMap: { [key: string]: string | Record<string, string> } = {
        [SitePowerFields.STATION_GROUP_NAME as string]: {
          byGroup  : 'by-station-group',
          setpoint : 'setpoint',
          target   : 'target-power',
          limit    : 'power-limit',
        },
        [SitePowerFields.FLEET_NAME as string]   : 'by-fleet',
        [SitePowerFields.PORT_NAME as string]    : 'by-port',
        [SitePowerFields.VEHICLE_NAME as string] : 'by-vehicle',
      }

      const qs            = paramsToQueryString(query)
      const createRequest = () => {
        if (typeof pathMap[groupBy] === 'string') {
          return wrapRequest(
            api.get(
              `${baseURL}/energy-management-data/${pathMap[groupBy]}/${qs}`,
            ),
          )
        }

        return Promise.all(
          Object.keys(pathMap[groupBy]).map((key) => wrapRequest(
            api.get(
              `${baseURL}/energy-management-data/${
                (pathMap as Record<string, Record<string, string>>)[groupBy][
                  key
                ]
              }/${qs}`,
            ),
          )),
        )
      }

      return createRequest() as Promise<EnergyChartResponse[]>
    },
  }
}

export default createService(AbstractService())
