import {
  KitFlexRowSpaced,
  KitForm,
  KitRadio,
  KitRadioGroup,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import { getScheduleTypeLabel, getScheduleTypeSOCLabel } from '@/common/utils/scheduling'
import TimeInput from '@/components/DateInput/ScheduleTimeInput'
import { FormWrapper } from '@/components/scheduling/settings/index.styles'
import ReadOnlySchedule from '@/components/scheduling/settings/ReadOnlySchedule'
import { Description } from '@/components/Styled'
import type { OptionListItem } from '@/models/formModel'
import type { Schedule } from '@/models/scheduleModel'
import { ScheduleContext, ScheduleType } from '@/models/scheduleModel'
import { type NormalizedChangeEvent } from '@/types/index'

export interface VehicleScheduleFormProps {
  t: TFunction;
  errors?: { [key: string]: string };
  values: { schedule: Schedule, scheduleType: ScheduleType, scheduleContext: ScheduleContext };
  onChange: (fieldName: string, value: unknown) => void;
  showInitialSelectOption: boolean;
}

const VehicleScheduleForm: FC<VehicleScheduleFormProps> = (props) => {
  const { errors = {}, onChange, showInitialSelectOption, t, values } = props
  const selectedScheduleContext                                       = props.values.scheduleContext
  const scheduleTypeLabel                                             = getScheduleTypeLabel(values.scheduleContext, t)
  const targetSOCLabel                                                = getScheduleTypeSOCLabel(values.scheduleType, t)
  const {
    arrival_time,
    arrival_time_downtime_secs,
    departure_time,
    departure_time_downtime_secs,
    required_soc,
  }                                                                   = values.schedule

  const scheduleContextOptions: OptionListItem[] = [
    {
      label : t('vehicles.settings.scheduling.schedule_context_option_fleet'),
      value : ScheduleContext.FLEET,
    },
    {
      label : t('vehicles.settings.scheduling.schedule_context_option_vehicle'),
      value : ScheduleContext.VEHICLE,
    },
  ];

  [showInitialSelectOption ? [{
    label : t('select'),
    value : null,
  }] : [] as OptionListItem[]].concat(scheduleContextOptions)

  const handleFieldChange = (fieldName: string, evt: NormalizedChangeEvent) => {
    const { target } = evt
    const value      = typeof evt === 'string' ? evt : evt.value ?? target?.value
    onChange(fieldName, value)
  }

  return (
    <FormWrapper>
      <KitForm>
        <KitForm.Group>
          <KitForm.Select
            id="scheduleContext"
            onChange={(e: NormalizedChangeEvent) => handleFieldChange('scheduleContext', e)}
            label={t('vehicles.settings.scheduling.schedule_context_label')}
            value={{
              label : t(`vehicles.settings.scheduling.schedule_context_option_${selectedScheduleContext}`),
              value : selectedScheduleContext,
            }}
            options={scheduleContextOptions}
          />
        </KitForm.Group>

        { selectedScheduleContext === ScheduleContext.FLEET && (
          <ReadOnlySchedule
            meta={{ showContext: false }}
            {...values}
            t={t}
            scheduleContext={ScheduleContext.FLEET}
          />
        ) }
        { selectedScheduleContext === ScheduleContext.VEHICLE && (
          <>
            <h5>
              { t('fleets.settings.scheduling_fleet_default_settings_label') }
            </h5>
            <KitForm.Group>
              <KitRadioGroup
                label={scheduleTypeLabel}
              >
                <KitRadio
                  id="scheduleType-asap"
                  checked={values.scheduleType === ScheduleType.BY_TIME}
                  name="scheduleType"
                  label={t('vehicles.settings.scheduling.schedule_type_option_scheduled')}
                  value={ScheduleType.BY_TIME}
                  onChange={(e) => handleFieldChange('scheduleType', e)}
                />
                <KitRadio
                  id="scheduleType-schedule"
                  checked={values.scheduleType === ScheduleType.ASAP}
                  name="scheduleType"
                  label={t('vehicles.settings.scheduling.schedule_type_option_asap')}
                  value={ScheduleType.ASAP}
                  onChange={(e) => handleFieldChange('scheduleType', e)}
                />
              </KitRadioGroup>
            </KitForm.Group>
            { values.scheduleType === ScheduleType.BY_TIME && (
              <KitForm.Group>
                <KitFlexRowSpaced>
                  <TimeInput
                    name="arrival"
                    errors={{
                      arrival_downtime : errors.arrival_time_downtime_secs,
                      arrival_time     : errors.arrival_time,
                    }}
                    labels={{
                      time     : t('vehicles.settings.scheduling.arrival_time_label'),
                      downtime : t(
                        'vehicles.settings.scheduling.arrival_delay_label',
                      ),
                      downtimeUnits: t('units.minutes_short'),
                    }}
                    onChange={(fieldName, fieldValue) => handleFieldChange(fieldName, fieldValue)
                    }
                    values={{
                      arrival_time,
                      arrival_time_downtime_secs,
                    }}
                  />
                </KitFlexRowSpaced>
                <KitFlexRowSpaced>
                  <TimeInput
                    name="departure"
                    errors={{
                      departure_downtime : errors.departure_time_downtime_secs,
                      departure_time     : errors.departure_time,
                    }}
                    labels={{
                      time: t(
                        'vehicles.settings.scheduling.departure_time_label',
                      ),
                      downtime: t(
                        'vehicles.settings.scheduling.departure_delay_label',
                      ),
                      downtimeUnits: t('units.minutes_short'),
                    }}
                    onChange={(fieldName, fieldValue) => handleFieldChange(fieldName, fieldValue)
                    }
                    values={{
                      departure_time,
                      departure_time_downtime_secs,
                    }}
                  />
                </KitFlexRowSpaced>
              </KitForm.Group>
            ) }
            <KitForm.Group>
              <KitForm.Input
                id="required_soc"
                name="required_soc"
                label={targetSOCLabel}
                onChange={(e) => handleFieldChange('required_soc', e)}
                min={0}
                value={required_soc}
                type="number"
                isError={!!errors.required_soc}
                infoMessage={errors.required_soc}
              />
              <span>%</span>
            </KitForm.Group>
            <Description>{ t('fleets.settings.scheduling_blocks_message') }</Description>
          </>
        ) }

      </KitForm>
    </FormWrapper>
  )
}

export default VehicleScheduleForm
