import {
  HelmetProvider,
  KitFontUrl,
  KitHelmet,
  KitSpinner,
  KitToast,
} from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'
import { type FC, Suspense, useEffect, useState } from 'react'

import { ThemeProvider } from 'styled-components'

import { getLocaleCode } from './common/lang'
import { getAppVersion } from './common/utils'
import { envCheck } from './init'
import { RouteContainer } from './routes'
import fleetTheme from '@/common/fleet-theme'
import Storage, { StorageType } from '@/common/utils/Storage'
import FleetGlobalCss from '@/components/FleetGlobalCss'
import ErrorMonitoringService from '@/services/ErrorMonitoringService'
import FeatureService from '@/services/FeatureService'
import appStore from '@/store/AppStore'

const App: FC<unknown> = () => {
  const helmetContext                           = {}
  const [envCheckComplete, setEnvCheckComplete] = useState(false)
  const locale                                  = getLocaleCode()

  useEffect(() => {
    const startup = async () => {
      Storage.version   = getAppVersion()
      Storage.storeType = StorageType.sessionStorage
      const { error }   = await envCheck()
      setEnvCheckComplete(true)
      if (error) {
        appStore.setErrors(error)
      }
    }

    if (!envCheckComplete) {
      startup()
    }
  }, [])

  useEffect(() => {
    if (appStore.initialized) {
      FeatureService.initialize().then(() => {
        ErrorMonitoringService.initialize()
      })
    }
  }, [appStore.initialized])

  const content = envCheckComplete ? (
    <RouteContainer />
  ) : (
    <KitSpinner
      size="s"
      align="middle"
      withOverlay={true}
      ariaLabel=".."
    />
  )

  return (
    <ThemeProvider theme={fleetTheme}>
      <HelmetProvider context={helmetContext}>
        <KitToast position="top-right" duration={2000} reverseOrder />
        <KitHelmet locale={locale}>
          <link href={KitFontUrl} rel="stylesheet" />
        </KitHelmet>
        <FleetGlobalCss />
        <Suspense
          fallback={
            <KitSpinner
              size="s"
              align="middle"
              withOverlay={true}
              ariaLabel=".."
            />
          }
        >
          { content }
        </Suspense>
      </HelmetProvider>
    </ThemeProvider>
  )
}

export default observer(App)
