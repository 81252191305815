import {
  type ErrorDisplayComponent,
  ErrorDisplayType,
} from '../../models/serviceModel'

export interface RouteErrorProps {
  code?: number | string;
  displayAs: ErrorDisplayType;
  displayComponent?: ErrorDisplayComponent;
  message?: string;
  i18nKey?: string;
}

export default class RouteError extends Error {
  i18nKey?: string
  code?: number | string
  displayAs?: ErrorDisplayType = ErrorDisplayType.FullPage
  displayComponent?: ErrorDisplayComponent

  constructor(props: RouteErrorProps) {
    super(props.message)
    this.code    = props.code
    this.i18nKey = props.i18nKey
    if (props.message) {
      this.message = props.message
    }
    if (props.displayAs) {
      this.displayAs = props.displayAs
    }

    if (props.displayComponent) {
      this.displayComponent = props.displayComponent
    }
  }
}
