import { KitModal } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'
import { css } from 'styled-components'

export const MobileFiltersHeader: FC<{ t: TFunction }> = ({ t }) => (
  <KitModal.Header t={t} closeButton>
    <KitModal.Title>{ t('filters') }</KitModal.Title>
  </KitModal.Header>
)

export const mobileFilterPanelStyles = css`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`
