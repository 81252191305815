/* eslint-disable camelcase */
import { KitForm, KitSelect, type KitSelectOption } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, useEffect, useState } from 'react'

import { type AddFleetReducerState, type FleetContact } from './config'
import { WizardContent, WizardHeading } from './styled'
import { has } from '@/common/utils/data'
import { getCurrentUser } from '@/common/utils/user'
import { FormItem, List, ListItemSmall } from '@/components/Styled'
import {
  type CustomChangeEventHandler,
  type OptionListItem,
  type SelectChangeEvent,
} from '@/models/formModel'

export type AddFleetContact = FleetContact & OptionListItem;

export interface ContactFormProps {
  fields: {
    contact: {
      options: AddFleetContact[];
    };
  };
  onChange: CustomChangeEventHandler;
  state: AddFleetReducerState;
  t: TFunction;
}

const ContactForm: FC<ContactFormProps> = ({ fields, onChange, state, t }) => {
  const { contact }         = fields
  const currentUser         = getCurrentUser()
  const [values, setValues] = useState<Partial<AddFleetContact> | undefined>(
    state.sections.contact,
  )

  const noContactOption = { label: t('none'), value: -1 } as KitSelectOption

  const selectedContactOption =    contact?.options?.find((opt) => opt.value === values?.value)
    ?? noContactOption

  const handleContactChange = (e: unknown) => {
    const { value } = e as SelectChangeEvent
    onChange('contact', 'contact_id', value)
  }

  useEffect(() => {
    let match
    if (!has(state.sections.contact, 'contact_id')) {
      setValues(undefined)
    } else if (has(state.sections.contact, 'contact_id')) {
      match = contact?.options.find(
        (c) => c.value === state.sections.contact?.contact_id,
      )
      setValues(match as AddFleetContact)
    } else if (contact.options) {
      const match_id = contact?.options?.find(
        (opt) => opt.email === currentUser.email,
      )?.nos_user_id
      if (match_id) {
        onChange('contact', 'contact_id', match_id)
      }
    }
  }, [contact?.options, state.sections.contact?.contact_id])

  const optionList = [noContactOption].concat(
    contact.options as KitSelectOption[],
  )

  return (
    <WizardContent>
      <WizardHeading>{ t('fleets.settings.contact_title') }</WizardHeading>
      <FormItem>
        <KitForm.Label htmlFor="contactName">{ t('name') }</KitForm.Label>
        <KitSelect
          id="contactName"
          onChange={handleContactChange}
          options={optionList}
          value={selectedContactOption}
        />
      </FormItem>
      { values?.email && (
        <List>
          <ListItemSmall>{ values.email }</ListItemSmall>
          <ListItemSmall>{ values.phone_number }</ListItemSmall>
        </List>
      ) }
    </WizardContent>
  )
}

export default ContactForm
