import { KitTab, KitTabs } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type Dispatch, type FC, useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { type FilterAction, FilterActions } from '@/common/reducers'
import CommonHeader from '@/components/CommonHeader'
import FilterSearchBar from '@/components/FilterSearchBar'
import { IconButton } from '@/components/IconButton'
import { HeaderContainer, HiddenKitTabPanels, StyledKitTabList } from '@/components/Styled'
import { type Filter } from '@/types/filters'

export interface FleetFilterBarProps {
  dispatch: Dispatch<FilterAction>;
  filters: Filter[];
  filterComponents?: React.ReactElement;
  filtersPanelOpen: boolean;
  numMatches: number;
  onSearch: (searchText: string) => void;
  showActionButton?: boolean;
  view: string;
  t: TFunction;
}

const FleetFilterBar = ({
  dispatch,
  filterComponents,
  filters,
  filtersPanelOpen,
  numMatches = 0,
  onSearch,
  showActionButton = true,
  t,
  view,
}: FleetFilterBarProps): JSX.Element => {
  const { addItemLabel, searchPlaceHolder } = (() => ({
    addItemLabel:
        view === 'fleets' ? t('fleets.add_fleet') : t('fleets.add_vehicle'),
    searchPlaceHolder:
        view === 'fleets'
          ? t('fleets.fleet_search_placeholder')
          : t('fleets.vehicle_search_placeholder'),
  }))()

  return (
    <FilterSearchBar
      key={view}
      filters={filters}
      onSearch={onSearch}
      numMatches={numMatches}
      searchPlaceHolder={searchPlaceHolder}
      filtersPanelOpen={filtersPanelOpen}
      setFiltersPanelOpen={() => FilterActions.toggleFiltersOpen(dispatch)}
      actionButton={
        showActionButton && (
          <IconButton
            qaTag={view === 'vehicles' ? 'add_vehicle_btn' : 'add_fleet_btn'}
            icon="plus"
            size={'14px'}
            onClick={() => dispatch({ type: 'SHOW_WIZARD', open: true })}
          >
            { addItemLabel }
          </IconButton>
        )
      }
      filterComponent={filterComponents}
      t={t}
    />
  )
}

FleetFilterBar.defaultProps = { filterComponents: null }

export interface FleetHeaderProps {
  filters?: Filter[];
  dispatch: Dispatch<FilterAction>;
  filterComponents?: React.ReactElement;
  numMatches: number;
  t: TFunction;
  onSearch: (searchText: string) => void;
  onPageChange: () => void;
  filtersPanelOpen: boolean;
  showSearchFilterBar: boolean;
  showActionButton?: boolean;
  view: string;
}

const FleetHeader: FC<FleetHeaderProps> = ({
  dispatch,
  filterComponents,
  filters = [],
  filtersPanelOpen,
  numMatches,
  onPageChange,
  onSearch,
  showActionButton,
  showSearchFilterBar,
  t,
  view,
}: FleetHeaderProps) => {
  const match = useMatch('/fleets/*')
  const tabs  = [
    {
      id    : 'fleets',
      path  : '',
      label : t('fleet', { count: 0 }),
      qaTag : 'fleet_cards_tab',
    },
    {
      id    : 'vehicles',
      path  : 'vehicles',
      label : t('vehicle', { count: 0 }),
      qaTag : 'vehicle_cards_tab',
    },
  ]

  const navigate                  = useNavigate()
  const [selectedIndex, setIndex] = useState<number>(0)

  const filterProps = {
    filters,
    dispatch,
    filtersPanelOpen,
    filterComponents,
    onSearch,
    numMatches,
    showActionButton,
    view,
    t,
  }

  function onTabChange(tabIndex: number) {
    setIndex(tabIndex)
    const tabPath = tabs[tabIndex].path
    onPageChange()
    navigate(`${match?.pathnameBase}/${tabPath}`)
  }

  useEffect(() => {
    const tabIndex = tabs.findIndex((tab) => view === tab.id)
    setIndex(tabIndex)
  }, [window.location.href])

  return (
    <HeaderContainer>
      <CommonHeader.Title>{ t('fleet', { count: 0 }) }</CommonHeader.Title>
      <KitTabs
        data-qa-id="fleet_tabs_container"
        selectedIndex={selectedIndex}
        onSelect={(k: number) => onTabChange(k)}
      >
        <StyledKitTabList>
          { tabs.map((tab) => (
            <KitTab data-qa-id={tab.qaTag} key={tab.id}>
              { tab.label }
            </KitTab>
          )) }
        </StyledKitTabList>

        <HiddenKitTabPanels />
        <HiddenKitTabPanels />
      </KitTabs>

      { showSearchFilterBar && filterComponents && (
        <FleetFilterBar {...filterProps} />
      ) }
    </HeaderContainer>
  )
}

export default FleetHeader
