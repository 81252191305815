import { KitUtilLocale } from '@chargepoint/cp-toolkit'

import RouteError from './common/errors/RouteError'
import i18n from './i18n'
import { ErrorCodes } from '@/common/constants'
import { getBrowserLocale, setLocale } from '@/common/lang'
import {
  parseProfileResponse,
  setCurrentUser,
  setOrgId,
  setUserPreferences,
} from '@/common/utils/user'
import { type BaseServiceResponse, ErrorDisplayComponent, ErrorDisplayType } from '@/models/serviceModel'
import { type UserProfileResponse } from '@/models/userModel'
import UserService from '@/services/UserService'
import appStore from '@/store/AppStore'

// async loader for UserProfile => used by react-router-dom for loading the root
export const rootLoader = async () => {
  const response = (await UserService.getProfile()) as UserProfileResponse &
  BaseServiceResponse
  if (response.error) {
    const errorCode = [403].includes(response.error.status as number)
      ? ErrorCodes.NotAuthorized
      : response.error.status ?? ErrorCodes.ProfileNotLoaded

    const errors = [{ code: errorCode }]
    appStore.setErrors(errors)

    throw new RouteError({
      code             : errorCode,
      displayComponent : ErrorDisplayComponent.SadCar,
      displayAs        : ErrorDisplayType.FullPage,
    })

    return { errors }
  }

  const localeCode                                    = response.language_country_code_preference ?? getBrowserLocale()
  const { cobrandInfo, userPreferences, userProfile } = parseProfileResponse(
    response,
    localeCode,
  )

  if (i18n.resolvedLanguage !== localeCode) {
    console.info(`Laguage change detected! Loading translations for ${localeCode}`)
    i18n.changeLanguage(localeCode)
  }

  setLocale(KitUtilLocale.getSupportedLocale(localeCode))
  setOrgId(response.session_org_id)
  setCurrentUser({ ...userProfile } as unknown as Record<string, unknown>)
  setUserPreferences(userPreferences)

  if (cobrandInfo) {
    appStore.setCobrandInfo(cobrandInfo)
  }

  appStore.setUser(userProfile)
  appStore.init()

  return {
    userProfile,
    cobrandInfo,
  }
}
