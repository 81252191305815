import { useTranslation } from 'react-i18next'

import Page from '@/components/Page'
import { PageContainer } from '@/components/Styled'
import VehicleCards from '@/pages/FleetList/routes/VehicleCards'

const FleetVehicleList = () => {
  const { t } = useTranslation()
  return (
    <Page title={t('page_titles.fleet_vehicle_list')}>
      <PageContainer>
        <VehicleCards />
      </PageContainer>
    </Page>
  )
}

export default FleetVehicleList
