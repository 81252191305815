/* Helper methods Vehicle Identification Settings */
import { type KitSelectOption, KitUtilData } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type ChangeEvent } from 'react'

import {
  type VehicleIdentificationFieldDef,
  type VehicleIdentificationFieldErrors,
  type VehicleIdentificationFieldProps,
  type VehicleIdentificationFormState,
} from './types'
import { RequestType, VehicleIdTypes } from '@/common/constants'
import { groupBy, omit, toOptionsArray } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { type SelectChangeAction } from '@/models/formModel'
import { type TelematicsAccount } from '@/models/telematicsModel'
import {
  type VehicleIdentificationResponse,
  type VehicleIdentificationResponseField,
} from '@/models/vehicleModel'
import { type Field } from '@/types/index'

// enforce this field order
const fieldOrder: string[] = [
  'vin',
  'telematics_account_id',
  'telematics_device_id',
  'mac_address',
  'license_plate',
  'rf_id',
]

export enum ComponentTypes {
  Deletable = 'deletable',
  Select = 'select',
  Text = 'text',
}

export interface IdentificationSettings {
  telematicsAccounts: TelematicsAccount[];
}

// generates id to keep track of dynamically generated fields
export const createID = () => {
  const d = new Date()
  return parseInt(
    `${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}${Math.round(
      Math.random() * 999,
    )}`,
  )
}

export const getFieldID = (
  props: VehicleIdentificationFieldProps,
): number | undefined => {
  const id = props
    ? props['data-id' as keyof typeof props] ?? props.id
    : undefined
  return hasValue(id) ? parseInt(id as string) : undefined
}

/**
 * Returns value from textfields and react-select fields
 * */
export const getVal = (val: KitSelectOption | unknown) => (typeof val === 'object' ? (val as KitSelectOption).value : val)

// tests two fields for equality
export const equalTo = (
  initialField: Field | unknown,
  secondField: Field | unknown,
) => {
  const v1 = getVal(initialField)
  const v2 = getVal(secondField)
  return v1 === v2
}

/**
 * Returns KitSelectOption when fieldType === 'react-select', or passed value otherwise
 */
export const getNormalizedFieldValue = (
  value: unknown,
  label?: string,
  fieldType?: string,
) => (fieldType === 'react-select'
  ? { value: getVal(value), label }
  : getVal(value))

/**
 * Evaluates events from regular input fields or react-select fields and returns values
 * @param e
 * @param action
 * @returns
 */
export const getNormalizedChangeEventValue = (
  e: ChangeEvent<HTMLInputElement> | { value: unknown },
  action?: SelectChangeAction,
) => {
  if (action?.name) {
    return {
      id    : action.id,
      name  : action.name,
      value : (e as { value: unknown }).value,
    }
  }
  const attr = (e as ChangeEvent<HTMLInputElement>).target?.attributes
  // @ts-expect-error -- TODO revisit this and type this properly.
  const id = attr['data-id']?.value ?? attr.id?.value
  return {
    id    : hasValue(id) ? parseInt(id) : null,
    name  : (e as ChangeEvent<HTMLInputElement>).target.name,
    value : (e as ChangeEvent<HTMLInputElement>).target.value,
  }
}

/**
 * Normalizes value from different varations of VehicleIdentificationResponseField
 * */
export const getFieldValue = (
  val: VehicleIdentificationResponseField | unknown,
): string | number => {
  if (hasValue(val)) {
    // this is only for non-deletable fields like license plate that are returned
    // in an array can have only one value
    if (Array.isArray(val)) {
      return val[0].id_value
    } if (typeof val === 'object') {
      return (val as VehicleIdentificationResponseField).id_value
    }
  }
  return val as string | number
}

const createField = (
  field: VehicleIdentificationResponseField,
  componentType: string,
  t: TFunction,
) => {
  const { id, id_type, id_value } = field
  return {
    component : componentType,
    props     : {
      'data-id' : id,
      id,
      name      : id_type,
      value     : id_value,
      label     : t(`vehicles.settings.identification.${id_type}`),
    },
  }
}

const getTelematicsAccounOptions = (t: TFunction) => {
  const initialOption = {
    label : t('vehicles.settings.no_telematics_option'),
    value : 'none',
  }

  return {
    selector: (state: IdentificationSettings) => toOptionsArray(state?.telematicsAccounts, {
      labelField : 'name',
      valueField : 'id',
      initialOption,
    }),
  }
}

/**
 * Normalizes the deserialization of fields within a VehicleIdentificationSettingsResponse
 * @param key
 * @param val
 * @param t
 * @returns
 */
export const getCommonProps = (
  key: string,
  val: VehicleIdentificationResponseField | unknown,
  t: TFunction,
) => {
  const label = t(`vehicles.settings.identification.${key}`)
  if (Array.isArray(val) && val.length) {
    // this is only used for license_plate which is returned as an array, but can only have a single value
    const { id, id_type, id_value } =      val[0] as VehicleIdentificationResponseField
    return { 'data-id': id, id, label, name: id_type, value: id_value }
  } if (!KitUtilData.isEmpty(val) && typeof val === 'object') {
    const { id, id_type, id_value } = val as VehicleIdentificationResponseField
    return { 'data-id': id, id, label, name: id_type, value: id_value }
  }
  return { 'data-id': createID(), label, name: key, value: val }
}

const getFieldProps = (
  key: string,
  value: unknown,
  t: TFunction,
  response: VehicleIdentificationResponse,
) => {
  const commonProps  = getCommonProps(key, value, t)
  const commonFields = {
    text: {
      component : 'text',
      props     : {
        ...commonProps,
        value: getFieldValue(value),
      },
    },
  }
  const fieldPropMap = {
    telematics_account_id: () => {
      const options     = getTelematicsAccounOptions(t)
      const selectedVal = value
        ? {
          label : response.telematics_account_name,
          value : getFieldValue(value),
        }
        : {
          label : t('vehicles.settings.no_telematics_option'),
          value : 'none',
        }
      return {
        component : 'select',
        props     : {
          ...commonProps,
          label : t('vehicles.settings.identification.telematics_account_name'),
          value : selectedVal,
          options,
        },
      }
    },
  }

  type fieldKey = keyof typeof fieldPropMap;
  const field = fieldPropMap[key as fieldKey]
  if (Array.isArray(value)) {
    const initalComponentType = [
      VehicleIdTypes.ID_TYPE_MAC_ADDRESS,
      VehicleIdTypes.ID_TYPE_RF_ID,
    ].includes(key as VehicleIdTypes)
      ? ComponentTypes.Deletable
      : ComponentTypes.Text
    return value.length
      ? value.map((val, idx) => createField(
        val,
        initalComponentType === ComponentTypes.Deletable && idx > 0
          ? initalComponentType
          : ComponentTypes.Text,
        t,
      ))
      : commonFields.text
  }

  if (hasValue(field)) {
    return typeof field === 'function'
      ? field()
      : commonFields[field as keyof typeof commonFields]
  }

  return commonFields.text
}

const dependencies: { [key: string]: string } = {
  // telematics_account_id is a required value before we display telematics_device_id field
  telematics_device_id: 'telematics_account_id',
}

/**
 * Builds field definitions that are used to build out the form.
 * For each required field, it grabs a value from the api response when present,
 * or creates an empty field when not.
 * @param obj
 * @param t
 * @returns
 */
export const processIdentificationSettingsResponse = (
  obj: VehicleIdentificationResponse,
  t: TFunction,
): VehicleIdentificationFieldDef[] => {
  try {
    const fields: VehicleIdentificationFieldDef[] = fieldOrder.reduce(
      (acc, key) => {
        const field:
        | VehicleIdentificationResponseField
        | VehicleIdentificationResponseField[]
        | unknown = obj[key as keyof VehicleIdentificationResponse]

        if (!KitUtilData.isEmpty(dependencies[key])) {
          // if a value is required within a dependency before we
          // display the current field, then exit
          if (!obj[dependencies[key] as keyof VehicleIdentificationResponse]) {
            return acc
          }
        }

        const fieldDef = getFieldProps(key, field, t, obj)
        if (Array.isArray(fieldDef)) {
          fieldDef.forEach((f) => acc.push(f))
        } else {
          acc.push(fieldDef)
        }

        return acc
      },
      [] as VehicleIdentificationFieldDef[],
    )

    return fields
  } catch (err) {
    console.error(err)
  }

  return []
}

/**
 * Deserializes response into an array of read-only fields
 * (consumed by components/Setings/ReadOnlyFields)
 * */
export const getReadOnlyFields = (
  response: VehicleIdentificationResponse,
  t: TFunction,
): Field[] => {
  const fieldRemapping = { telematics_account_id: 'telematics_account_name' }
  const mappedResponse = fieldOrder.map((key) => ({
    key,
    value:
      response[
        (fieldRemapping[key as keyof typeof fieldRemapping]
          ?? key) as keyof VehicleIdentificationResponse
      ],
  }))
  return mappedResponse.reduce((acc: Field[], field) => {
    const { key, value } = field
    if (Array.isArray(value)) {
      value.forEach(({ id_type, id_value }, i) => {
        if (hasValue(id_value)) {
          const count = value.length > 1 ? i + 1 : ''
          acc.push({
            name  : id_type,
            label : `${t(
              `vehicles.settings.identification.${id_type}`,
            )} ${count}`,
            value: id_value,
          })
        }
      })
    } else if (hasValue(getFieldValue(value)) && !KitUtilData.isEmpty(value)) {
      acc.push({
        name  : key,
        value : getFieldValue(value),
        label : t(`vehicles.settings.identification.${key}`),
      })
    }
    return acc
  }, [])
}

export const getRequestFields = (
  state: VehicleIdentificationFormState,
): (VehicleIdentificationFieldDef & VehicleIdentificationFieldProps)[] => {
  const { fields } = state
  const result     = fields.reduce(
    (
      acc: (VehicleIdentificationFieldDef & VehicleIdentificationFieldProps)[],
      item,
    ) => {
      // @ts-expect-error -- The VehicleIdentificationSettings
      // types need to be cleaned up first, and I'm too busy at the moment
      acc.push({
        requestType: item.requestType,
        ...item.props,
      })
      return acc
    },
    [],
  )

  return result
}

export function mapIdRequest(
  requestType: RequestType,
  field: Partial<VehicleIdentificationResponseField>,
) {
  const methods = {
    [RequestType.CREATE]: ({
      name,
      value,
    }: Partial<VehicleIdentificationFieldProps>) => ({
      id_type  : field.id_type ?? name,
      id_value : value,
    }),
    [RequestType.UPDATE]: ({
      id,
      value,
    }: Partial<VehicleIdentificationFieldProps>) => ({
      id,
      id_value: value,
    }),
    [RequestType.DELETE]: ({ id }: Partial<VehicleIdentificationFieldProps>) => id,
  }

  return methods[requestType](field)
}

export function buildRequest(state: VehicleIdentificationFormState) {
  const fields          = getRequestFields(state)
  const groupedByMethod = omit(
    groupBy(
      fields.filter((f) => !['telematics_account_id'].includes(f.name)),
      'requestType',
    ),
    ['undefined'],
  )

  // const initialTelematicsAccountID = getInitialValue('telematics_account_id', state);
  const telematicsAccountIDField = fields.find(
    (f) => f.name === 'telematics_account_id',
  )
  const telematics_account_id    = (() => {
    const val = getVal(telematicsAccountIDField?.value)
    return ['none', null].includes(val as string | null) ? null : val
  })()

  const request = Object.entries({
    telematics_account_id,
    identification: Object.keys(groupedByMethod).map((key) => ({
      method : key,
      data   : (
        groupedByMethod[key as keyof typeof groupedByMethod] as unknown[]
      ).map((field) => mapIdRequest(
        key as RequestType,
        field as VehicleIdentificationResponseField,
      )),
    })),
  }).reduce((acc, [key, value]) => {
    // allow telematics account id to be set to null, but ignore other empty fields
    if (key === 'telematics_account_id' || value) {
      acc[key] = value
    }
    return acc
  }, {} as Record<string, unknown>)

  return request
}

/**
 * Returns original value from server response
 * @param key
 * @param data
 * @param id
 * @returns
 */
export const getInitialValue = (
  key: string,
  data: VehicleIdentificationResponse,
  id?: number | string,
): string | number | undefined => {
  if (!data) {
    console.error('getInitialValue missing data')
    return undefined
  }
  const prop = data[key as keyof VehicleIdentificationResponse]
  if (prop) {
    if (Array.isArray(prop)) {
      const record = prop.find((d) => d.id === id)
      return record?.id_value
    }
    return getFieldValue(prop)
  }
}

const validateField = (field: VehicleIdentificationFieldDef) => {
  // only fields with a requestType need to be validated
  if (field.requestType) {
    // we might eventually need to have more sophisticaed validation here, but for now, we can just implement
    return hasValue(field.value)
  }
  return true
}

export const validate = (
  formState: VehicleIdentificationFormState,
  t: TFunction,
): Partial<VehicleIdentificationFieldErrors>[] => {
  // check if any fields are empty
  const fields = getRequestFields(formState)
  const errors = fields.reduce((acc, field) => {
    if (!validateField(field)) {
      acc.push({
        ...field,
        infoMessage : t('errors.required'),
        error       : true,
      })
    }
    return acc
  }, [] as Partial<VehicleIdentificationFieldErrors>[]) as VehicleIdentificationFieldErrors[]

  return errors
}
