import {
  KitFlexColCentered,
  KitIcon,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

import { fadeIn } from '@/common/animations'
import NoResults from '@/components/NoResults'

const { iconSize, spacing } = ThemeConstants

const Container = styled.div<{ isError?: boolean }>`
  padding-top: ${spacing.absolute.xl}px;
  display: flex;
  justify-content: center;
  color: ${({ isError }) => (isError ? ThemeColors.red_50 : ThemeColors.gray_50)};
  animation: ${fadeIn} 1s;
  min-height: 300px;
`

const Centered = styled(KitFlexColCentered)`
  align-items: center;
`

const Message = styled.h5`
  color: ${ThemeColors.gray_50};
`

const EmptyChartMessage = ({
  isError,
  label,
}: {
  label: string;
  isError?: boolean;
}) => (
  <Container isError>
    <Centered>
      { isError && <NoResults /> }
      { !isError && (
        <KitIcon
          icon="trending-up"
          fill={ThemeColors.gray_40}
          size={`${iconSize.xxl}rem`}
        />
      ) }
      <Message>{ label }</Message>
    </Centered>
  </Container>
)

export default EmptyChartMessage
