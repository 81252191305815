/* eslint-disable camelcase */
import { KitChargingBadge } from '@chargepoint/cp-toolkit'
import classNames from 'classnames'
import { type TFunction } from 'i18next'
import { type KeyboardEvent } from 'react'

import {
  ContentContainer,
  HeaderWithBadge,
  IdentificationListItem,
  IdentificationListLabel,
  IdentificationListValue,
  StyledVehicleCard,
  VehicleCardFooter,
} from './index.styles'
import utils from '@/common/utils'
import { normalizeStringValue } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import {
  getNormalizedVehicleStatusCode,
  getVehicleStatusText,
} from '@/common/utils/vehicle'
import { EllipsisText } from '@/components/Styled'
import StyledFleetCard from '@/components/StyledFleetCard'
import VehicleImage from '@/components/VehicleImage'
import { type Vehicle } from '@/models/vehicleModel'

export interface VehicleBadgeProps {
  status: string | null;
  port: string | null;
}

export interface VehicleCardProps extends Vehicle {
  selected?: boolean;
  t: TFunction;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent<HTMLDivElement>) => void;
}

export interface VehiclePropListProps {
  location: string | null;
  status: string | null;
  port: string | null;
  license_plate: string | null;
}

const getVehiclePropList = (
  pluggedIn: boolean,
  vehicle: Partial<VehiclePropListProps>,
) => {
  const locationLabel = pluggedIn ? 'depot' : 'location'
  const propList      = [
    {
      label : locationLabel,
      value : vehicle.location,
    },
    {
      label : 'charger',
      value : vehicle.port,
    },
    {
      label : 'license_plate',
      value : vehicle.license_plate,
    },
  ]

  return propList
}

const getKitBadgeProps = (vehicle: Partial<Vehicle>, t: TFunction) => {
  const normalizedStatus = getNormalizedVehicleStatusCode(vehicle) as string
  const chargingStatus   = utils.getChargingStatusCode(normalizedStatus)
  const connected        = !!vehicle.port

  const connectedText    = getVehicleStatusText(vehicle, t)
  const disconnectedText = connectedText

  return {
    chargingStatus,
    connected,
    connectedText,
    disconnectedText,
  }
}

const VehicleCard: React.FC<VehicleCardProps> = ({
  id,
  image_url,
  license_plate,
  location,
  model_make,
  model_name,
  model_trim,
  model_year,
  name,
  onClick,
  onKeyPress,
  port,
  selected,
  status,
  t,
  vehicle_status,
}) => {
  const vehiclePropList = getVehiclePropList(!!port, {
    location,
    status,
    port,
    license_plate,
  }).filter((prop) => hasValue(prop.value))

  const badgeProps         = getKitBadgeProps({ status, vehicle_status, port }, t)
  const vehicleImageWidth  = 'auto'
  const vehicleImageHeight = '100px'
  return (
    <StyledVehicleCard
      className="vehicle-card"
      selected={selected}
      data-id={id?.toString()}
      onClick={() => onClick()}
      tabIndex={0}
      onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => onKeyPress(e)}
    >
      <HeaderWithBadge>
        <EllipsisText
          data-qa-id="card_title"
          title={name}
          style={{ maxWidth: '275px' }}
        >
          { name }
        </EllipsisText>
        { badgeProps && (
          <KitChargingBadge
            className={classNames('charging-badge', status, { isUnplugged: !hasValue(port) })}
            {...badgeProps}
          />
        ) }
      </HeaderWithBadge>

      <StyledFleetCard.Body className="card-body">
        <ContentContainer>
          <VehicleImage
            url={image_url as string}
            altText={t('vehicle_image_alt_text', { vehicle_type: name })}
            t={t}
            width={vehicleImageWidth}
            height={vehicleImageHeight}
          />
          <StyledFleetCard.List>
            { vehiclePropList.map((prop) => (
              <IdentificationListItem key={prop.label}>
                { prop.value && (
                  <>
                    <IdentificationListLabel>
                      { t(prop.label) }
                    </IdentificationListLabel>
                    <IdentificationListValue
                      bold
                      data-qa-id="vehicle_license_plate"
                    >
                      { normalizeStringValue(prop.value) }
                    </IdentificationListValue>
                  </>
                ) }
              </IdentificationListItem>
            )) }
          </StyledFleetCard.List>
        </ContentContainer>

        <VehicleCardFooter data-qa-id="vehicle_details_label">
          <b>{ model_name }</b> { model_make } { model_trim } { model_year }
        </VehicleCardFooter>
      </StyledFleetCard.Body>
    </StyledVehicleCard>
  )
}

export default VehicleCard
