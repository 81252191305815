/* eslint-disable no-unused-vars */
export enum PollEvents {
  POLL = 'refresh',
  START = 'start',
  STOP = 'stop',
}

interface PollProps {
  pollInterval: number; // milliseonds to wait between polling
}
export default class Poll {
  events: Record<string, ((args?: unknown) => void)[]> = {}

  startTime: number | undefined

  intervalId: number | undefined

  pollInterval: number // milliseconds

  lastPollTime: number

  constructor(props: PollProps) {
    if (!props.pollInterval) {
      // eslint-disable-next-line no-console
      console.error('Poll requires props.pollInterval to be passed to the constructor')
    }
    this.pollInterval = props.pollInterval
    this.lastPollTime = Date.now()
  }

  poll = () => {
    this.trigger(PollEvents.POLL)
  }

  start() {
    this.startTime  = Date.now()
    this.intervalId = window.setInterval(this.poll, this.pollInterval)
    return this
  }

  stop() {
    window.clearInterval(this.intervalId)
    return this
  }

  on(eventName: string, fn: () => void): Poll {
    if (!this.events[eventName]) {
      this.events[eventName] = <never>[]
    }
    this.events[eventName].push(fn)
    return this
  }

  private trigger(eventName: string, args?: unknown): void {
    if (this.events[eventName]) {
      this.events[eventName].forEach((fn) => fn(args))
    }
  }
}
