/* eslint-disable camelcase */
import { cpChartsTheme } from '@chargepoint/cp-charts'
import {
  ThemeColors,
  ThemeConstants,
  cpLightTheme,
} from '@chargepoint/cp-toolkit'
import { ChargingStatusOptions } from './constants'

const { fontSize } = ThemeConstants

const colors = {
  // temporarily adding these here until alert designs are formalized
  // and Notifications component is put into cp-toolkit
  // Most of these colors are not currently in the approved pallette, but I believe that we are missing proper color schemes for these

  alert_info_text          : ThemeColors.gray_90,
  alert_info_bg            : 'rgb(240, 246, 255)',
  alert_info_border        : 'rgb(199, 218, 228)',
  alert_error_text         : ThemeColors.gray_90,
  alert_error_bg           : 'rgb(255, 242, 240)',
  alert_error_border       : 'rgb(228, 203, 199)',
  alert_success_background : ThemeColors.green_10,
  // Polaris color token
  alert_success_border     : '#008F6C',
  alert_warning_text       : ThemeColors.gray_90,
  alert_warning_bg         : 'rgb(255, 242, 240)',
  alert_warning_border     : 'rgb(228, 203, 199)',
}

export const chargingStatusColors = {
  [ChargingStatusOptions.Ready]        : ThemeColors.green_50,
  [ChargingStatusOptions.Charging]     : ThemeColors.blue_50,
  [ChargingStatusOptions.ChargingLate] : ThemeColors.red_50,
  [ChargingStatusOptions.NotCharging]  : ThemeColors.red_50,
  [ChargingStatusOptions.StationFault] : ThemeColors.red_50,
  [ChargingStatusOptions.VehicleFault] : ThemeColors.red_50,
  [ChargingStatusOptions.PlugIn]       : ThemeColors.gray_50,
  [ChargingStatusOptions.PlugInLate]   : ThemeColors.red_50,
  [ChargingStatusOptions.ReducedPower] : ThemeColors.yellow_50,
  [ChargingStatusOptions.Unknown]      : ThemeColors.gray_50,
  [ChargingStatusOptions.LatePlugIn]   : ThemeColors.red_50,
}

export default {
  // cp charts theme includes cp-toolkit theme
  ...cpChartsTheme.light,
  charts: {
    color: {
      fault          : '#cc0000',
      power          : '#A7C5E5',
      setpoint       : '#D12A11',
      referenceLine  : '#BFBFBF',
      referenceLine2 : '#4C4C4C',
    },
    axis: {
      ticks: {
        fontSize : fontSize.text_14,
        color    : ThemeColors.gray_50,
      },
    },
  },
  colors,
  page: {
    ...cpLightTheme.page,
    body: {
      bg         : ThemeColors.gray_05,
      headerText : ThemeColors.gray_90,
      text       : ThemeColors.gray_70,
    },
  },
  panel: {
    border : `1px solid ${ThemeColors.gray_10}`,
    shadow : '0px 2px 4px 0px rgb(51 50 49 / 30%)',
  },
  table  : { shadow: '0px 2px 4px 0px rgb(51 50 49 / 30%)' },
  search : { width: '350px' },
  card   : {
    ...cpLightTheme.card,
    header: {
      ...cpLightTheme.card.header,
      bg     : ThemeColors.blue_20,
      border : ThemeColors.gray_30,
    },
  },
  selector: {
    item: {
      bg   : ThemeColors.gray_20,
      text : ThemeColors.gray_70,
    },
    selectedItem: {
      bg   : ThemeColors.orange_55,
      text : ThemeColors.white,
    },
  },
}
