import { KitCard, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type FC, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { getConstant } from '@/common/utils'
import ErrorBoundary from '@/components/ErrorBoundary'
import StaticMap from '@/components/MiniMap/StaticMap'
import NoResults from '@/components/NoResults'
import { ChartContainer, Container, Spacer } from '@/components/Styled'
import { type Site } from '@/models/energyModel'

const { spacing } = ThemeConstants

function getCardContent({
  showChart,
  site,
}: {
  showChart: boolean;
  site: Site;
}): ReactNode {
  const { t } = useTranslation()

  if (showChart) {
    return <ChartContainer style={{ height: '240px' }}></ChartContainer>
  } if (site.latitude && site.longitude) {
    return (
      <KitCard.Body data-qa-id="energy_card_map_container">
        <StaticMap
          altText={t('energy_management.cards.thumbnail_map_alt_text', { siteName: site.name })}
          markers={[{ lat: site.latitude, lng: site.longitude }]}
          size="320x130"
          apiKey={getConstant('GOOGLE_MAPS_API_KEY') as string}
        />
      </KitCard.Body>
    )
  }
  return (
    <KitCard.Body>
      <Container>
        <NoResults message={''} height="100px" size="s" />
        <Spacer orientation="vertical" size={spacing.absolute.xl} />
      </Container>
    </KitCard.Body>
  )
}

export interface EnergySiteCardProps {
  onClick: (id: string | number) => void;
  site: Site;
  showChart: boolean;
}

const EnergySiteCard: FC<EnergySiteCardProps> = ({
  onClick,
  showChart,
  site,
}) => (
  <KitCard onClick={() => onClick(site.external_id)} key={site.external_id}>
    <KitCard.Header data-qa-id="card_title">{ site.name }</KitCard.Header>
    <ErrorBoundary>{ getCardContent({ showChart, site }) }</ErrorBoundary>
  </KitCard>
)

export default EnergySiteCard
