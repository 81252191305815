/* eslint-disable camelcase */
import {
  KitSpinner,
  KitUtilData,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import { type FC, useEffect, useState } from 'react'

import styled from 'styled-components'

import VehicleScheduleForm from './VehicleScheduleForm'
import { EditMode } from '@/common/constants'
import { FLEET_VEHICLE_UPDATE } from '@/common/permissions'
import { scheduleToUI } from '@/common/utils/scheduling'
import EditPanel from '@/components/EditPanel'
import Notification, { NotificationLevel } from '@/components/Notification'
import ReadOnlySchedule from '@/components/scheduling/settings/ReadOnlySchedule'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import type { Schedule, ScheduleResponse, ScheduleType } from '@/models/scheduleModel'
import { ScheduleContext } from '@/models/scheduleModel'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import scheduleStore from '@/store/fleetVehicleScheduleStore'

const { spacing } = ThemeConstants

const EditContainer = styled.div`
  margin: ${spacing.m}px;
  min-height: 250px;
`

export interface SchedulingEditViewProps {
  errors: { [key: string]: string };
  values: VehicleScheduleProps;
  t: TFunction;
  onChange: (fieldName: string, value: unknown) => void;
}

export interface SchedulingReadOnlyViewProps extends VehicleScheduleProps {
  vehicleName: string;
  t: TFunction;
}

const SchedulingEditView: FC<SchedulingEditViewProps> = (props) => {
  const { errors, onChange, t, values } = props
  const isLoading                       = KitUtilData.isEmpty(values)
  const showInitialSelectOption         = false
  return (
    <EditContainer>
      { isLoading && <KitSpinner size="s" /> }
      { !isLoading && (
        <VehicleScheduleForm
          showInitialSelectOption={showInitialSelectOption}
          errors={errors}
          values={values}
          onChange={onChange}
          t={t}
        />
      ) }
    </EditContainer>
  )
}

export interface VehicleScheduleProps extends Schedule {

}

const VehicleSettingsSchedulePanel: FC<{
  schedule: ScheduleResponse;
  vehicleId: string;
  fleetId: string;
  t: TFunction;
  vehicleName: string;
}> = (props) => {
  const { fleetId, schedule, t, vehicleId } =    props

  const [mode, setMode]       = useState(EditMode.READ_ONLY)
  const [isReady, setIsReady] = useState(false)
  const { isLoading }         = scheduleStore

  const { analyticsService } = useAnalyticsService()

  const handleOnChange = (fieldName: string, value: ScheduleType | ScheduleContext | string) => {
    if (fieldName === 'scheduleContext') {
      scheduleStore.setScheduleContext(value as ScheduleContext)
    } else if (fieldName === 'scheduleType') {
      scheduleStore.setScheduleType(value as ScheduleType)
    } else {
      scheduleStore.setScheduleField(fieldName, value)
    }
  }

  const handleCancel = () => {
    scheduleStore.resetSchedule()
    setMode(EditMode.READ_ONLY)
  }

  // this is stubbed out for now, but still empty because this panel is still read-only
  const handleSubmit = async () => {
    if (scheduleStore.validate(t)) {
      scheduleStore.saveSchedule()
      setMode(EditMode.READ_ONLY)
      analyticsService.trackEvent(
        analyticEvents.vehicleScheduleUpdated,
        scheduleStore.scheduleAnalyticsProps,
      )
    }
  }

  useEffect(() => {
    if (schedule) {
      scheduleStore.vehicleID = vehicleId
      const scheduleContext   = schedule.inherit_from_fleet ? ScheduleContext.FLEET : ScheduleContext.VEHICLE
      scheduleStore.setFleetID(fleetId)
      scheduleStore.setScheduleContext(scheduleContext)
      scheduleStore.setScheduleResponse(schedule)
    }
  }, [schedule])

  useEffect(() => {
    if (scheduleStore.schedule) {
      setIsReady(true)
    }
  }, [scheduleStore.schedule])

  return (
    <EditPanel
      id="scheduling"
      title={t('scheduling')}
      t={t}
      mode={mode}
      onCancel={handleCancel}
      isLoading={isLoading}
      onToggle={() => {
        setMode(mode === EditMode.READ_ONLY ? EditMode.EDIT : EditMode.READ_ONLY)
      }}
      onSubmit={handleSubmit}
      permissions={FLEET_VEHICLE_UPDATE}
    >

      { isReady && mode === EditMode.READ_ONLY && (
        <ReadOnlySchedule
          schedule={scheduleToUI(scheduleStore.schedule, scheduleStore.scheduleType)}
          scheduleContext={scheduleStore.scheduleContext}
          scheduleType={scheduleStore.scheduleType}
          t={t}
        />
      ) }

      { isReady && mode === EditMode.EDIT && (
        <SchedulingEditView
          errors={scheduleStore.fieldErrors}
          values={{
            schedule        : scheduleToUI(scheduleStore.schedule, scheduleStore.scheduleType),
            scheduleContext : scheduleStore.scheduleContext,
            scheduleType    : scheduleStore.scheduleType,
          }}
          t={t}
          onChange={handleOnChange}
        />
      ) }
      { !isLoading && scheduleStore.serviceError && (
        <Notification type={NotificationLevel.ERROR} message={scheduleStore.serviceError} />
      ) }
    </EditPanel>
  )
}

export default observer(VehicleSettingsSchedulePanel)
