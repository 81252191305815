import {
  KitFlexRowSpaced,
  KitModal,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

const { breakpoints, fontSize, spacing } = ThemeConstants

export const NavItem = styled.li<{ disabled: boolean }>`

  &:first-child {
    margin: 0 8px 0 0;
  }

  a {
    color: ${({ disabled, theme }) => (disabled ? ThemeColors.gray_40 : theme.link.text)}}; 
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
    margin: 0 ${spacing.absolute.s}px;
    text-decoration: none;
  }

  position: relative;
  &:first-child {
     flex
  }

  &:not(:last-child):after {
    position: absolute;
    content: ' ';
    border-radius: 50%;
    background: ${ThemeColors.gray_40};
    height: 4px;
    width: 4px;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
  }  

  &.selected {
    color: ${ThemeColors.gray_90};
    &:before {
      content: ' ';
      position: absolute;
      display: block;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-bottom: 2px solid ${ThemeColors.gray_30};
    }

    button {
      color: ${ThemeColors.gray_90};
    }
  }

  @media all and (max-width: ${breakpoints.md}px) {
    margin: 0;
    &.selected {
      &:before {
        content: '›';
        position: relative;
        display: inline;
        font-size: ${fontSize.text_24};
        margin-right: ${spacing.absolute.xs}px;
        border-bottom: none;
      }
    }

    &:after {
      display: none;
      content: '';
    }    
   }

`

export const NavList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-bottom: ${spacing.absolute.l}px;

  @media all and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`

export const StyledFooter = styled(KitModal.Footer)`
  border-top: 0;
  margin: ${ThemeConstants.spacing.absolute.s}px 0;
`
export const WizardContent = styled.div`
  position: relative;
`

export const WizardControlsContainer = styled(KitFlexRowSpaced)`
  width: 100%;
  &.start {
    justify-content: flex-end;
  }
`
