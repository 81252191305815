import { type ReactElement, type ReactNode } from 'react'

import { omit } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { TableCell, TableHeaderCell } from '@/components/Styled'
import { type CellAlignType, type ColumnDef, type SortFunc } from '@/types/index'

export interface TableBodyProps<TableData> {
  actionsColumnRenderer?: (row: TableData) => ReactElement;
  columns: ColumnDef[];
  data: TableData[];
  cellFormatter?: (col: ColumnDef, row: TableData) => ReactNode;
  defaultCellProps?: TableCellProps;
}

function getCellProps(
  col: ColumnDef,
  defaultCellProps: TableCellProps,
): TableCellProps {
  const cellProps = omit(
    { align: col.align, 'data-qa-id': `cell-${col.field}`, ...col.props },
    (val) => !hasValue(val),
  )
  if (col.props) {
    return { ...defaultCellProps, ...cellProps }
  }
  return { ...cellProps, ...defaultCellProps }
}

type HeaderCellType<T = 'th'> = T extends 'th'
  ? typeof TableHeaderCell
  : typeof TableCell;
function getHeaderCell(
  col: ColumnDef,
  index: number,
  onSortClicked: SortFunc,
  active: boolean,
) {
  const Td: HeaderCellType<'td'> = TableCell
  const Th: HeaderCellType<'th'> = TableHeaderCell
  const sortKey                  = col.props?.sortKey ?? (col.field as string)
  const align                    = (col.props?.align ?? col.align) as CellAlignType

  if (col.field) {
    if (col?.tag === 'td') {
      <Td align={align} key={`${col.label}-${index}`} {...col.props}>
        { col.label }
      </Td>
    }

    return (
      <Th
        align={align}
        active={active}
        key={`${col.label}-${index}`}
        {...col.props}
        sortable={!!col.sortFn}
        onSortClicked={(dir: number) => onSortClicked(sortKey, dir)}
        text={col.label}
      ></Th>
    )
  }
  return <th key={`empty-field-${index}`} />
}

export interface TableCellProps extends React.PropsWithChildren {
  align?: 'left' | 'right';
  backgroundColor?: string;
  maxWidth?: number;
  minWidth?: number;
  text?: string | number;
  verticalAlign?: string;
}

export function TableBody<TableData = Record<string, unknown>>({
  actionsColumnRenderer,
  cellFormatter,
  columns,
  data,
  defaultCellProps = {},
}: TableBodyProps<TableData>): JSX.Element {
  return (
    <tbody>
      { data.map((row, rowIdx) => (
        <tr key={`${rowIdx}`}>
          { columns.map((col) => (
            <TableCell
              key={col.field}
              {...getCellProps(col, defaultCellProps)}
              maxWidth={col.maxWidth}
            >
              { cellFormatter
                ? cellFormatter(col, row)
                : (row[col.field as keyof TableData] as ReactNode) }
            </TableCell>
          )) }
          { actionsColumnRenderer ? actionsColumnRenderer(row) : null }
        </tr>
      )) }
    </tbody>
  )
}

export const TableHead = ({
  columns,
  onSortClicked,
  sortKey,
}: {
  columns: ColumnDef[];
  onSortClicked?: (field: string, dir: number) => void;
  sortKey?: string;
}) => (
  <thead data-qa-id="table-headings">
    <tr>
      { columns.map((col, i) => getHeaderCell(col, i, onSortClicked as SortFunc, sortKey === col.field)) }
    </tr>
  </thead>
)
