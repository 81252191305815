import {
  KitFlexColEnd,
  KitUtilFormat,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import classNames from 'classnames'

import {
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistanceToNow,
  isBefore,
  startOfDay,
} from 'date-fns/esm'
import { utcToZonedTime } from 'date-fns-tz'
import { type TFunction } from 'i18next'
import { type ComponentType } from 'react'
import styled, { type CSSProperties } from 'styled-components'

import BusIcon from '../FleetIcon/Bus'
import ChargingStationIcon from '../FleetIcon/ChargingStation'
import { ISO_DATE_TIME, ISO_TIME } from '@/common/constants'
import { getLocaleCode } from '@/common/lang'
import { hasValue } from '@/common/utils/validations'
import { type DDVDataSource, type TimeStampedDDVField } from '@/models/depotModel'
import { parseJSON, parseISO } from 'date-fns'

const { spacing } = ThemeConstants

export interface TimeStampAwareTooltipProps {
  ts: Date;
  age: string;
  isStale: boolean;
  source: DDVDataSource;
  value: number;
}

export const IconWrapper = styled.div`
  display: inline-block;
  fill: ${ThemeColors.gray_20};
  height: 20px;
  width: 20px;
  vetical-align: center;
  max-width: 20px;
`

export const TextContentWrapper = styled.div`
  max-width: 250px;
  &.is-stale {
    margin: 0 ${spacing.s}rem;
  }
`

export const TimeStamp = styled.span`
  display: flex;
  padding: ${spacing.absolute.xs}px 0;
`

export const TooltipContentWrapper = styled.div`
  align-items: middle;
  display: flex;
  line-height: ${ThemeConstants.fontSize.base}rem;
  justify-content: space-between;
  padding: ${ThemeConstants.spacing.absolute.xs}px;
  position: relative;
  width: 100%;

  ul { 
    li {
      display: flex;
      align-items: center;
    }
  }
`

export const TooltipHeading = styled.div`
  color: ${ThemeColors.gray_20};
  font-size: ${ThemeConstants.fontSize.text_12}rem;
  font-weight: ${ThemeConstants.fontWeight.bold};
  text-transform: uppercase;
`

// eslint-disable-next-line sort-exports/sort-exports
export const ClockIcon = ({ alt = '' }: { alt?: string }) => (
  <IconWrapper aria-hidden={!hasValue(alt)}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      {alt && <title>{alt}</title>}
      { /* eslint-disable-next-line max-len */}
      <path d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
    </svg>
  </IconWrapper>
)

export const getTimeStampAwareTooltipContent = (
  props: TimeStampAwareTooltipProps | undefined,
  t: TFunction,
) => {
  if (!props) {
    return null
  }

  const { age, isStale, source, ts } = props

  const SourceIcon = (
    source === 'telematics' ? BusIcon : ChargingStationIcon
  ) as ComponentType<{
    style?: CSSProperties;
  }>

  if (!ts) {
    return null
  }

  const todayStart = startOfDay(new Date())
  const isPriorDay = isBefore(new Date(ts), todayStart)
  const fmt = isPriorDay ? ISO_DATE_TIME : ISO_TIME
  const timeStamp = format(ts, fmt)

  return (
    <TooltipContentWrapper role="tooltip" aria-live="polite">
      <TextContentWrapper className={classNames({ 'is-stale': isStale })}>
        <TooltipHeading>{t('common.stale_data.last_read')}</TooltipHeading>
        <TimeStamp>
          <span>
            {timeStamp}
            {<> ({age})</>}
          </span>
        </TimeStamp>
        {source && <div>
          {t('common.stale_data.data_source', { dataSource: t(`${source}`).toLocaleLowerCase() })}
        </div>}
      </TextContentWrapper>
      {source && <KitFlexColEnd>
        <SourceIcon
          data-qa-id={`icon-${source}`}
          aria-hidden="true"
          style={{
            animationFillMode: 'forwards',
            fill: '#ffffff',
            height: '24px',
            width: '24px',
            margin: '0',
            verticalAlign: 'middle',
            maxWidth: '24px',
          }}
        />
      </KitFlexColEnd>}
    </TooltipContentWrapper>
  )
}

export const getTimeStampAwareTooltipProps = (
  data: Partial<TimeStampedDDVField>,
  timeZone: string,
  t: TFunction,
): TimeStampAwareTooltipProps | undefined => {
  if (!data) {
    return
  }

  const { source, time, value } = data
  if (data.time) {
    const now = new Date()
    const parsedTS = parseJSON(time as string)
    const localizedTS = utcToZonedTime(parsedTS, timeZone)
    const locale = window.dateFNSLocale as Locale
    const diff = differenceInSeconds(now, parsedTS)

    const elapsedTime = locale
      ? formatDistanceToNow(parsedTS, { locale })
      : KitUtilFormat.formatDurationFromSeconds(diff, getLocaleCode())

    const age = t('charting.elapsedTime', { elapsedTime })
    const isStaleThresholdMinutes = 10

    const isStale = differenceInMinutes(now, parsedTS) > isStaleThresholdMinutes
    const dataSource = source as DDVDataSource

    return {
      ts: localizedTS,
      age,
      isStale,
      source: dataSource,
      value: value as number,
    }
  }
}
