import {
  KitModal,
  KitModalSize,
  KitToast,
  KitToastTypeOptions,
  useToast,
} from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import FleetAboutPanel from './FleetSettingsAboutPanel'
import FleetContactPanel from './FleetSettingsContactPanel'
import FleetSchedule from './FleetSettingsSchedulingPanel'
import FleetSettingsZoneAlertsPanel from './FleetSettingsZoneAlertsPanel'

import { EditMode, type RequestType } from '@/common/constants'
import { getTransComponent } from '@/common/utils/componentHelpers'
import Dialog from '@/components/Dialog'
import Page from '@/components/Page'
import { PageContainer, SettingsCardContainer } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import TelematicsAlert from '@/components/Telematics/Alert'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { type ZoneAlert } from '@/models/alertModel'
import { type FleetSettingsResponse } from '@/models/fleetModel'
import PreconditioningPanel from '@/pages/FleetPropSheet/routes/FleetSettings/FleetSettingsPreconditioningPanel'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import FeatureService, { Features } from '@/services/FeatureService'
import FleetService from '@/services/FleetService'
import appStore from '@/store/AppStore'
import fleetStore from '@/store/fleetStore'

type REQUEST_METHOD = 'CREATE' | 'UPDATE' | 'DELETE';

interface MappedZoneAlert extends ZoneAlert {
  method?: RequestType;
}

const getAlerts = (
  method: REQUEST_METHOD,
  alerts: MappedZoneAlert[],
): MappedZoneAlert[] => {
  const existingAlerts = alerts.find((alert) => alert?.method === method)
  return (
    existingAlerts || {
      method,
      data: [],
    }
  )
}

const FleetSettings = () => {
  const [fleetSettings, setFleetSettings]               = useState<FleetSettingsResponse>()
  const [showAlertModal, setShowAlertModal]             = useState(false)
  const [showDelete, setShowDelete]                     = useState(false)
  const [updatedZoneAlerts, setUpdatedZoneAlerts]       = useState<ZoneAlert[]>([])
  const [selectedZoneAlert, setSelectedZoneAlert]       = useState(null)
  const [telematicsAlertsMode, setTelematicsAlertsMode] = useState(
    EditMode.READ_ONLY,
  )
  const [zoneAlertToRemove, setZoneAlertToRemove]       = useState<{
    id: number | null;
    zoneId: number | null;
    zoneName: string;
  }>({ id: null, zoneId: null, zoneName: '' })
  const { t }                                           = useTranslation()
  const { analyticsService }                            = useAnalyticsService()
  const { fleet_external_id: fleetExternalID }          = useParams()

  function refreshSettings(
    settingName: string,
    updates: Partial<FleetSettingsResponse>,
  ) {
    const updatedSettings = {
      ...fleetSettings,
      [settingName]: updates,
    } as FleetSettingsResponse
    setFleetSettings(updatedSettings)
    fleetStore.refresh()
  }

  const onCancelDelete = () => {
    setZoneAlertToRemove({
      id       : null,
      zoneId   : null,
      zoneName : '',
    })
    setShowDelete(false)
  }

  const confirmDelete = (id: number, zoneId: number, zoneName: string) => {
    setZoneAlertToRemove({ id, zoneId, zoneName })
    setShowDelete(true)
  }

  const addZoneAlert = async () => {
    setShowAlertModal(true)
  }

  const onHide = () => {
    setShowAlertModal(false)
    setSelectedZoneAlert(null)
  }

  const cancelAlert = () => {
    setShowAlertModal(false)
    setSelectedZoneAlert(null)
  }

  // we use zone_id to remove zone alert because alert id not presented in new zone alerts
  const removeZoneAlert = async (id: number, zoneId: number) => {
    const alertsToUpdate = getAlerts('UPDATE', updatedZoneAlerts)
    alertsToUpdate.data  = alertsToUpdate.data.filter(
      (item) => item.zone_id !== zoneId,
    )

    const alertsToCreate = getAlerts('CREATE', updatedZoneAlerts)
    alertsToCreate.data  = alertsToCreate.data.filter(
      (item) => item.zone_id !== zoneId,
    )

    let alertsToDelete = getAlerts('DELETE', updatedZoneAlerts)
    if (id) {
      if (alertsToDelete && alertsToDelete.data.length) {
        alertsToDelete.data = [...alertsToDelete.data, id]
      } else {
        alertsToDelete = {
          method : 'DELETE',
          data   : [id],
        }
      }
    }
    setFleetSettings({
      ...fleetSettings,
      zone_alerts: fleetSettings.zone_alerts.filter(
        (alert) => alert.zone_id !== zoneId,
      ),
    })
    setUpdatedZoneAlerts([alertsToCreate, alertsToUpdate, alertsToDelete])
    setShowDelete(false)
  }

  const renderConfirmDelete = () => {
    const { id, zoneId, zoneName } = zoneAlertToRemove
    return (
      <Dialog
        title={t('telematicAlert.delete_alert')}
        t={t}
        type="confirm"
        message={getTransComponent('telematicAlert.delete_alert_for_zone', { zoneName })}
        show={showDelete}
        onAction={() => removeZoneAlert(id as number, zoneId as number)}
        onClose={onCancelDelete}
        cancelText={t('no')}
        confirmText={t('yes')}
      />
    )
  }

  const editZoneAlert = (zoneAlert) => {
    setShowAlertModal(true)
    setSelectedZoneAlert(zoneAlert)
  }

  const handleSubmitZoneAlerts = async () => {
    const requestAlerts = updatedZoneAlerts
      .filter(Boolean)
      .filter((alert) => alert && alert?.data?.length)
    const response      = await FleetService.updateZoneAlerts(fleetExternalID as string, requestAlerts)
    if (response && !response.error) {
      useToast({
        t,
        toastType : KitToastTypeOptions.SUCCESS,
        message   : t('toast_messages.update_success', { item: t('alert_plural') }),
      })
      setUpdatedZoneAlerts([])
      setSelectedZoneAlert(null)
      setTelematicsAlertsMode(EditMode.READ_ONLY)

      const analyticsAction = requestAlerts.find(
        (alrt) => alrt.method === 'CREATE',
      )
        ? 'zone-alert-added'
        : 'zone-alert-updated'
      refreshSettings('zone_alerts', response)
      analyticsService.trackEvent(analyticEvents.fleetUpdated, {
        setting : 'alerts',
        action  : analyticsAction,
      })
    }
  }

  const fetchFleetSettingsData = async () => {
    const response = await FleetService.getFleetSettings(fleetExternalID as string)
    if (!response.error) {
      setFleetSettings(response)
    } else {
      appStore.setErrors(response.error)
    }
  }

  const handleCancelZoneAlerts = () => {
    fetchFleetSettingsData()
    setUpdatedZoneAlerts([])
  }

  const updateAlert = (zoneAlert) => {
    const zoneAlerts = fleetSettings.zone_alerts.map((alert) => {
      const { id, min_alerted_soc, time_before_alerting_minutes } = zoneAlert
      if (id === alert.id) {
        return {
          ...alert,
          min_alerted_soc,
          time_before_alerting_minutes,
        }
      }
      return alert
    })
    const alertsToCreate                                                 = getAlerts('CREATE', updatedZoneAlerts)
    const alertsToUpdate                                                 = getAlerts('UPDATE', updatedZoneAlerts)
    const alertsToDelete                                                 = getAlerts('DELETE', updatedZoneAlerts)
    const { id, min_alerted_soc, time_before_alerting_minutes, zone_id } = zoneAlert
    if (alertsToUpdate.data.find((alert) => alert.id === id)) {
      alertsToUpdate.data = alertsToUpdate.data.map((alert) => {
        if (id === alert.id) {
          return {
            id,
            min_alerted_soc,
            time_before_alerting_minutes,
          }
        }
        return alert
      })
    } else if (
      alertsToCreate.data.find((alert) => alert.zone_id === zoneAlert.zone_id)
    ) {
      alertsToCreate.data = alertsToCreate.data.map((alert) => {
        if (zone_id === alert.zone_id) {
          return { zone_id, min_alerted_soc, time_before_alerting_minutes }
        }
        return alert
      })
    } else {
      alertsToUpdate.data = [
        ...alertsToUpdate.data,
        { id, zone_id, min_alerted_soc, time_before_alerting_minutes },
      ]
    }
    setFleetSettings({ ...fleetSettings, zone_alerts: zoneAlerts })
    setUpdatedZoneAlerts([alertsToCreate, alertsToUpdate, alertsToDelete])
    setShowAlertModal(false)
  }

  const addAlert = (zoneAlert) => {
    const alertsToCreate = getAlerts('CREATE', updatedZoneAlerts)
    const alertsToUpdate = getAlerts('UPDATE', updatedZoneAlerts)
    const alertsToDelete = getAlerts('DELETE', updatedZoneAlerts)
    alertsToCreate.data  = [...alertsToCreate.data, zoneAlert]
    setUpdatedZoneAlerts([alertsToCreate, alertsToUpdate, alertsToDelete])
    setFleetSettings({
      ...fleetSettings,
      zone_alerts: [...fleetSettings.zone_alerts, zoneAlert],
    })
    setShowAlertModal(false)
  }

  useEffect(() => {
    if (!fleetSettings && fleetExternalID) {
      fetchFleetSettingsData()
    }
  }, [fleetExternalID])

  if (!fleetExternalID || !fleetSettings) {
    return <StyledSpinner />
  }

  return (
    <Page
      title={t('page_titles.fleet_settings', { name: fleetSettings?.about?.name })}
    >
      <KitToast position="top-right" duration={2000} reverseOrder />
      <PageContainer>
        <SettingsCardContainer>
          <FleetAboutPanel
            about={fleetSettings.about}
            fleetExternalID={fleetExternalID}
            refreshSettings={refreshSettings}
          />
          <FleetContactPanel
            contact={fleetSettings.contact}
            fleetExternalID={fleetExternalID}
            t={t}
            refreshSettings={refreshSettings}
          />
          <FleetSettingsZoneAlertsPanel
            telematics={fleetSettings.vehicle_telematics}
            zoneAlerts={fleetSettings.zone_alerts}
            refreshSettings={refreshSettings}
            onAddZoneAlert={addZoneAlert}
            onRemoveZoneAlert={confirmDelete}
            onEditZoneAlert={editZoneAlert}
            onSubmit={handleSubmitZoneAlerts}
            onCancel={handleCancelZoneAlerts}
            telematicsAlertsMode={telematicsAlertsMode}
            onTelematicsAlertsModeChange={setTelematicsAlertsMode}
            t={t}
          />

          <FleetSchedule
            fleetExternalID={fleetExternalID}
            schedule={fleetSettings.schedule}
            refreshSettings={refreshSettings}
          />

          { /** TODO:  Telematics specific code should be abstracted outside of the general Fleet settings */ }
          <KitModal show={showAlertModal} size={KitModalSize.md} onHide={onHide}>
            <KitModal.Header closeButton t={t}>
              <KitModal.Title>
                <KitModal.Title>{ t('telematicAlert.alert') }</KitModal.Title>
              </KitModal.Title>
            </KitModal.Header>
            <KitModal.Body>
              <TelematicsAlert
                existingAlerts={fleetSettings.zone_alerts}
                alert={selectedZoneAlert}
                depotId={fleetSettings?.about?.home_depot_external_id}
                onSave={updateAlert}
                onAdd={addAlert}
                onCancel={cancelAlert}
              />
            </KitModal.Body>
          </KitModal>
          { renderConfirmDelete() }
          { FeatureService.isEnabled(Features.vehiclePreconditioningEnabled)
            && <PreconditioningPanel fleet_id={fleetExternalID} /> }
        </SettingsCardContainer>
      </PageContainer>
    </Page>
  )
}

export default observer(FleetSettings)
