import { action, flow, makeObservable, observable } from 'mobx'

import { type EnergySettings, type Site, type StationGroup } from '@/models/energyModel'
import { type Fleet } from '@/models/fleetModel'
import EnergyManagementService from '@/services/EnergyManagementService'
import FleetService from '@/services/FleetService'
import appStore from '@/store/AppStore'

class SiteDetailsStore {
  initialized = false
  isFetching = false
  errors: Record<string, string> = {}
  siteID: string | undefined
  site: Site | undefined
  stationGroups: StationGroup[] | undefined
  siteSettings: Partial<EnergySettings> | undefined
  fleets: Fleet[] | undefined
  timezone: string | undefined
  loadSettingsError: boolean = false

  constructor() {
    makeObservable(this, {
      isFetching          : observable,
      site                : observable,
      fleets              : observable,
      stationGroups       : observable,
      siteSettings        : observable,
      loadInitialPageData : action.bound,
      updateStationGroup  : action.bound,
      updateSite          : action.bound,
    })
  }

  loadSiteSettings = flow(function* loadSiteSettings(this: SiteDetailsStore) {
    try {
      if (this.site) {
        this.isFetching = true
        const result    = yield EnergyManagementService.getSiteSettings(this.site.external_id)
        if (result.error) {
          this.loadSettingsError = true
        } else {
          this.siteSettings = result
        }
      } else {
        console.info('Site not loaded yet, cannot fetch settings')
      }
    } catch (err) {
      console.error('could not load site settings', err)
      this.loadSettingsError = true
    }

    this.isFetching = false
  })

  updateSite(changes: Partial<Site>) {
    this.site = { ...this.site, ...(changes as Site) }
  }

  updateStationGroup(id: string, payload: Partial<StationGroup>) {
    if (!this.stationGroups) {
      return
    }
    const updatedStationGroups = this.stationGroups.map((grp) => {
      if (grp.external_id === id) {
        return {
          ...grp,
          ...payload,
        }
      }
      return grp
    })
    this.stationGroups         = updatedStationGroups
  }

  updateSiteSettings(updatedSettings: Partial<EnergySettings>) {
    this.siteSettings = { ...this.siteSettings, ...(updatedSettings as EnergySettings) }
  }

  loadInitialPageData = flow(function* loadSettingsData(this: SiteDetailsStore, siteID: string) {
    if (siteID !== this.siteID) {
      try {
        this.isFetching                  = true
        const site                       = yield EnergyManagementService.getSite(siteID)
        const { results: stationGroups } = yield EnergyManagementService.getStationGroups(siteID)

        if (site.error) {
          appStore.setErrors(site.error)
        } else {
          this.siteID        = siteID
          this.site          = site
          this.timezone      = this.site?.timezone
          this.stationGroups = stationGroups
          this.siteSettings  = undefined

          this.initialized = true

          this.isFetching = false

          if (!this.fleets) {
            const { results: fleets } = yield FleetService.getFleets()
            this.fleets               = fleets
          }
        }
      } catch (err) {
        console.error(err)
        this.errors.required_page_data = 'could not required site data'
      }
    } else {
      console.info('SiteDetailsStore could not initialize, missing siteID')
    }
  })

  get aggregatePower() {
    return this.site?.aggregate_power
  }
}

const scheduleStore = new SiteDetailsStore()
export default scheduleStore
