import { KitTable, ThemeColors } from '@chargepoint/cp-toolkit'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { getColumns, processData } from './config'
import UpcomingVehicleTableRow from './UpcomingVehicleTableRow'
import { KitTableWrapper } from '@/components/Styled'
import StyledFleetCard from '@/components/StyledFleetCard'
import { TableHead } from '@/components/Table'
import { type BlockAssignment } from '@/models/dispatchModel'
import SchedulingService from '@/services/SchedulingService'

const StyledKitTable = styled(KitTable)`
  border: 0;
  tr {
    td.no-results {
      color: ${ThemeColors.gray_50};
    }

    &:last-child {
      td {
        border: 0;
      }
    }
    > th {
      background-color: ${ThemeColors.white};
      span {
        color: ${ThemeColors.gray_50};
      }
    }
  }
`

export interface UpcomingVehicleBlocksProps {
  vehicleName: string;
}

const UpcomingVehicleBlocks: FC<UpcomingVehicleBlocksProps> = ({ vehicleName }) => {
  const { t }                       = useTranslation()
  const [blocks, setBlocks]         = useState<BlockAssignment[]>([])
  const [showBlocks, setShowBlocks] = useState(false)
  const columns                     = getColumns(t)

  useEffect(() => {
    const fetchBlocks = async () => {
      // Fetch block assignments starting today through the next 7 days for a specific vehicle
      const startDate = startOfDay(new Date()).toISOString()
      const endDate   = addDays(endOfDay(new Date()), 6).toISOString()

      const { error, results } = await SchedulingService.getBlockAssignments({
        vehicle                   : vehicleName,
        departure_datetime_after  : startDate,
        departure_datetime_before : endDate,
        ordering                  : 'departure_datetime',
      })

      if (!error && Array.isArray(results) && results.length) {
        setBlocks(processData(results) as BlockAssignment[])
        setShowBlocks(true)
      } else {
        setBlocks([])
        setShowBlocks(false)
      }
    }
    fetchBlocks()
  }, [vehicleName])

  if (!showBlocks) {
    return null
  }

  return (
    <KitTableWrapper>
      <StyledFleetCard.Header>
        <header>{ t('fleets.upcoming_blocks') }</header>
      </StyledFleetCard.Header>
      <StyledFleetCard.Body>
        <StyledKitTable>
          <TableHead columns={[...columns]} />
          <tbody>
            { blocks.map((row, idx) => (
              <UpcomingVehicleTableRow columns={columns} row={row} key={idx} />
            )) }
          </tbody>
        </StyledKitTable>
      </StyledFleetCard.Body>
    </KitTableWrapper>
  )
}

export default UpcomingVehicleBlocks
