import { KitForm, KitInput, KitSelect } from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import { ReadOnlyFormItem, WizardContent } from './styled'
import { FieldValue, Label } from '@/components/Styled'
import { type Depot } from '@/models/depotModel'
import { type CustomChangeEventHandler, type OptionListItem } from '@/models/formModel'
import {
  type NormalizedChangeEvent,
  type SimpleType,
  type TranslateFunc,
} from '@/types/index'

interface ExtendedDepot extends Depot {
  options: OptionListItem[];
}

// Add Fleet screens
export interface AboutProps {
  onChange: CustomChangeEventHandler;
  t: TranslateFunc;
  values: {
    [key: string]: SimpleType;
  };
  fields: {
    [key: string]: unknown;
    depot: ExtendedDepot;
    org_name: string;
    fleet_name: string;
    fleet_description: string;
  };
}
const About: FC<AboutProps> = ({ fields, onChange, t, values }: AboutProps) => {
  const { depot, org_name } = fields ?? {}
  const selectedDepotOption = depot?.options?.filter(
    (opt) => opt.value === values?.home_depot_external_id,
  )
  const handleFieldChange   = (fieldName: string, e: NormalizedChangeEvent) => {
    const value = fieldName === 'home_depot_external_id' ? e.value : e.target?.value
    onChange('about', fieldName, value)
  }
  return (
    <WizardContent>
      <ReadOnlyFormItem>
        <Label block>{ t('organization') }</Label>
        <FieldValue small>{ org_name }</FieldValue>
      </ReadOnlyFormItem>
      <KitForm>
        <KitForm.Group>
          <KitForm.Label
            htmlFor="name"
            text={t('fields.fleet_name_label')}
            required
          />
          <KitInput
            id="name"
            name="name"
            onChange={(e) => handleFieldChange('name', e)}
            required
            value={values?.name as string}
          />
        </KitForm.Group>
        <KitForm.Group>
          <KitForm.Label
            htmlFor="description"
            text={t('fields.fleet_description_label')}
          />
          <KitInput
            id="description"
            name="description"
            value={values?.description as string}
            onChange={(e) => handleFieldChange('description', e)}
          />
        </KitForm.Group>
        <KitForm.Group>
          <KitForm.Label text={t('depot')} htmlFor="home_depot_external_id" />
          <KitSelect
            id="home_depot_external_id"
            name={'home_depot_external_id'}
            options={depot?.options}
            onChange={(e: NormalizedChangeEvent) => handleFieldChange('home_depot_external_id', e)
            }
            value={selectedDepotOption}
          />
        </KitForm.Group>
      </KitForm>
    </WizardContent>
  )
}

export default About
