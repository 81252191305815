import { KitLink, KitUtilFormat } from '@chargepoint/cp-toolkit'
import { parseISO } from 'date-fns'
import { type TFunction } from 'i18next'
import { type ReactNode } from 'react'

import { EMPTY_VALUE_PLACEHOLDER, ISO_DATE_TIME } from '@/common/constants'
import {
  formatNumber,
  formatPercent,
  getLocaleCode,
  getLocalizedDistance,
  numberFormatOptions,
} from '@/common/lang'
import { formatDate } from '@/common/utils/date'
import { hasValue } from '@/common/utils/validations'
import { type ChargingHistory } from '@/models/vehicleModel'
import { type Filter } from '@/types/filters'
import { type ColumnDef } from '@/types/index'

export function buildChargingHistoryQuery(filters: Filter[]) {
  const query = filters?.reduce((acc, item) => {
    switch (item.name) {
      case 'search':
        acc.station_name = item.value as string
        break
      case 'date_range':
        acc.start_time = item.value.start_time
        acc.end_time   = item.value.end_time
        break
      default:
        // nothing to change here...
    }

    return acc
  }, {} as Partial<ChargingHistory>)

  return query
}

export function getChargingHistoryFilterCount(filters: Filter[]) {
  return filters
    ? Object.entries(filters).filter(([key]) => !['period'].includes(key)).length
    : 0
}

export const getColumns = (t: TFunction): ColumnDef[] => {
  const localeCode = getLocaleCode()
  return [
    {
      field    : 'start_time',
      label    : t('charging_history.start_time'),
      sortFn   : true,
      formatFn : (value, record) => (value
        ? formatDate(parseISO(value as string), ISO_DATE_TIME, record)
        : EMPTY_VALUE_PLACEHOLDER),
    },
    {
      field    : 'end_time',
      label    : t('charging_history.start_time'),
      sortFn   : true,
      formatFn : (value, record) => (!hasValue(value) ? (
        <strong>{ t('charging_history.in_progress') as ReactNode }</strong>
      ) : (
        formatDate(parseISO(value as string), ISO_DATE_TIME, record)
      )),
    },
    {
      field    : 'duration',
      label    : t('charging_history.start_time'),
      sortFn   : true,
      formatFn : (value) => KitUtilFormat.formatDurationFromSeconds(value as number, localeCode),
    },

    {
      field    : 'soc_start',
      label    : t('charging_history.soc_start'),
      sortFn   : true,
      align    : 'right',
      formatFn : (value) => `${formatPercent(value as number, numberFormatOptions.soc)}`,
    },
    {
      field    : 'soc_end',
      label    : t('charging_history.soc_end'),
      sortFn   : true,
      align    : 'right',
      formatFn : (value) => `${formatPercent(value as number, numberFormatOptions.soc)}`,
    },
    {
      field    : 'range_added_miles',
      label    : t('charging_history.range_added_miles'),
      formatFn : (value) => getLocalizedDistance(value as number),
      sortFn   : true,
      align    : 'right',
    },
    {
      field    : 'cumulative_energy_kwh',
      label    : t('charging_history.cumulative_energy_kwh'),
      formatFn : (value) => formatNumber(value, numberFormatOptions.energy),
      sortFn   : true,
      align    : 'right',
    },
    {
      field    : 'station_name',
      label    : t('charging_history.station_name'),
      sortFn   : true,
      formatFn : (value, record) => (
        <KitLink target="_blank" href={record.station_hyperlink} t={t}>
          { value }
        </KitLink>
      ),
    },
  ]
}
