import {
  KitFilterButton,
  KitPopover,
  KitUtilData,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import classNames from 'classnames'
import { type TFunction } from 'i18next'
import { type FC, type ReactNode, type RefObject, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { useLayout } from '../../hooks/useLayout'
import { UILayout } from '@/common/constants'
import { getFilterCount, isValidFilter } from '@/common/utils/filters'
import SearchBox from '@/components/SearchBox'
import {
  FilterBarCol,
  FilterBarContainer,
  FilterControlsWrapper,
  FiltersBlock,
  NumMatchesSpan,
  SearchCol,
} from '@/components/Styled/filterBarStyles'
import { type Filter } from '@/types/filters'

const { breakpoints, fontSize, spacing } = ThemeConstants

const HeaderContainer = styled.div`
  background-color: ${ThemeColors.white};
  font-size: ${fontSize.text_14}rem;
`

const OverFlowWrapper = styled.div`
  display: inline-block;
  position: relative;
  button {
    min-width: ${spacing.absolute.m}px;
  }
`

export interface FilterBarProps {
  getCustomFilterCount?: (filters: Filter[]) => number;
  filters: Filter[];
  filterComponent: ReactNode;
  overFlowMenu?: ReactNode; // menu that holds download button
  searchPlaceHolder: string;
  actionButton?: ReactNode | boolean;
  numMatches?: number;
  filtersPanelOpen: boolean;
  setFiltersPanelOpen: (open: boolean) => void;
  onSearch: (s: string) => void;
  t: TFunction;
}

export const PaddedContainer = styled.div`
  padding: 0 ${UILayout.hPageMargin}px;
  flex-grow: 1;

  @media all and (max-width: ${breakpoints.sm}px) {
    padding: 0 ${UILayout.mobile.hPageMargin}px;
  }
`

const FilterBar = ({
  actionButton,
  filterComponent,
  filters,
  filtersPanelOpen,
  getCustomFilterCount,
  numMatches,
  onSearch,
  overFlowMenu,
  searchPlaceHolder,
  setFiltersPanelOpen,
  t,
}: FilterBarProps) => {
  const filtersPanelRef               = useRef()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { isMobile }                  = useLayout()

  const searchFilter  = Array.isArray(filters)
    ? filters.find((f) => f.name === 'search')
    : null
  const activeFilters = filters?.filter
    ? filters.filter(({ noCount, value }) => {
      if (!noCount && Array.isArray(value)) {
        return value.length > 0
      }
      return isValidFilter(value)
    })
    : filters

  const toggleFiltersPanelOpen = () => {
    setFiltersPanelOpen(!filtersPanelOpen)
  }

  useEffect(() => {
    const prevSize   = windowWidth
    const updateSize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateSize)
    if (windowWidth < breakpoints.md && windowWidth !== prevSize) updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [windowWidth])

  const renderFilters = () => (
    <>
      { filterComponent ? (
        <KitPopover
          containerStyle={{ zIndex: '101' }}
          reposition={!isMobile}
          contentLocation={isMobile ? { top: 0, left: 0 } : undefined}
          align="start"
          isOpen={filtersPanelOpen}
          content={
            <KitPopover.Container
              role="dialog"
              aria-label={t('filters')}
              className="popover-content"
              ref={filtersPanelRef as unknown as RefObject<HTMLDivElement>}
            >
              { filterComponent }
            </KitPopover.Container>
          }
        >
          <KitPopover.Trigger>
            <KitFilterButton
              t={t}
              className={classNames('filter', { active: filtersPanelOpen })}
              onClick={toggleFiltersPanelOpen}
              counter={
                getCustomFilterCount
                  ? getCustomFilterCount(activeFilters)
                  : getFilterCount(activeFilters)
              }
              showLabel={!isMobile}
              labelText={t('filters')}
              icon="filter"
              data-qa-id="filter_btn"
              aria-label={t('filters')}
            />
          </KitPopover.Trigger>
        </KitPopover>
      ) : null }
      { overFlowMenu && <OverFlowWrapper>{ overFlowMenu }</OverFlowWrapper> }
      { /* @ts-expect-error -- TODO: update type hasValue to accept generics  */ }
      { KitUtilData.hasValue(numMatches) && (
        <NumMatchesSpan data-qa-id="filter_results_count_label">
          { t('showing_num_matches_with_count', { count: numMatches }) }
        </NumMatchesSpan>
      ) }
    </>
  )

  return (
    <FilterBarContainer>
      { onSearch && (
        <SearchCol>
          <SearchBox
            data-qa-id="search_input"
            aria-label={t('depots.depot_search_filter_aria_label')}
            onChange={({ target }) => onSearch(target.value)}
            placeholder={searchPlaceHolder}
            defaultValue={searchFilter?.value as string}
          />
        </SearchCol>
      ) }

      <FilterControlsWrapper>
        <FilterBarCol>
          <FiltersBlock>{ renderFilters() }</FiltersBlock>
        </FilterBarCol>

        { actionButton && actionButton }
      </FilterControlsWrapper>
    </FilterBarContainer>
  )
}

FilterBar.defaultProps = {
  filters         : {},
  isFiltered      : false,
  filterComponent : undefined,
}

const SearchFilterBar: FC<FilterBarProps> = ({
  actionButton,
  filterComponent,
  filters,
  filtersPanelOpen,
  getCustomFilterCount,
  numMatches,
  onSearch,
  overFlowMenu,
  searchPlaceHolder,
  setFiltersPanelOpen,
  t,
}) => {
  const filterProps = {
    actionButton,
    filters,
    filterComponent,
    filtersPanelOpen,
    getCustomFilterCount,
    numMatches,
    onSearch,
    overFlowMenu,
    searchPlaceHolder,
    setFiltersPanelOpen,
    t,
  }

  return (
    <HeaderContainer>
      <FilterBar {...filterProps} />
    </HeaderContainer>
  )
}

export default SearchFilterBar
