/* eslint-disable no-unused-vars */

/* eslint-disable camelcase */
import { KitUtilData } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'

import { VehicleIdTypes } from '@/common/constants'
import { hasValue } from '@/common/utils/validations'
import { type WizardStep } from '@/components/Wizard'
import { type DepotDetailsRow } from '@/models/depotModel'
import { type Fleet } from '@/models/fleetModel'
import { type ServiceRequest } from '@/models/serviceModel'
import { type TelematicsAccount } from '@/models/telematicsModel'
import {
  type Vehicle,
  type VehicleMake,
  type VehicleModel,
  type VehicleYear,
} from '@/models/vehicleModel'
import FleetService from '@/services/FleetService'
import TelematicsService from '@/services/TelematicsService'
import VehicleService from '@/services/VehicleService'
import {
  type DynamicObject,
  type Field,
  type TranslateParams,
  type WizardInputChangeFunc,
} from '@/types/index'

const { getProp, isEmpty } = KitUtilData

export enum AddVehicleFlow {
  Charging = 'charging',
  ExistingVehicle = 'existing_vehicle',
  GettingStarted = 'getting_started',
  Vehicle = 'vehicle',
  Identification = 'identification',
}

export enum LocationCategory {
  OnPremise = 'on_premise',
  OnPremiseAndRoad = 'on_premise_and_road',
}

export interface ChargingData {
  activeUnknownVehicleSessions: Record<string, unknown>[];
}

export interface UnknownVehicleSession {
  address_1: string;
  address_2?: string;
  city?: string;
  country_iso_code?: string;
  cumulative_energy_kwh: number;
  depot_external_id: string;
  end_time: string | null;
  external_id: string;
  id: number;
  is_deleted: boolean;
  is_unknown_vehicle?: boolean;
  latitude: number;
  load: number;
  longitude: number;
  name: string; // depot name
  org_external_id: string;
  org_name: string;
  port: string;
  port_external_id: string;
  range_added_miles: number;
  rf_id: string;
  session_id: string;
  soc_end: number;
  soc_start: string | null;
  start_time: string | null;
  state: string;
  station_name: string;
  status: string;
  timezone: string;
  vehicle_external_id: number;
  vehicle_mac_address: string;
  zipcode: string;
}

export interface AddVehicleFlowData {
  getting_started: { fleets: Fleet[] } & Record<string, unknown>;
  charging: ChargingData;
  vehicle: {
    makes: VehicleMake[];
    models: VehicleModel[];
    years: VehicleYear[];
    activeUnknownVehicleSessions: UnknownVehicleSession[];
  } & Record<string, unknown>;
  identification: {
    telematics_accounts?: TelematicsAccount[];
  };
  unknownVehicle?: DepotDetailsRow;
  unknownVehicleSession?: UnknownVehicleSession;
}

export interface ExistingVehicleData {
  selectedVehicle: Vehicle;
}

export interface ChargingSectionProps {
  id_type: string;
  rf_id?: string;
}

export interface IdentificationSectionProps {
  license_plate?: string;
  mac_address?: string;
  name: string;
  telematics_account_id?: string;
  rf_id?: string;
  telematics_device_id: string;
  using_telematics?: boolean;
  vin?: string;
}

export interface AddVehicleFlowSections {
  existing_vehicle: { selectedVehicle: Vehicle };
  getting_started: Record<string, unknown>;
  charging: ChargingSectionProps;
  vehicle: Record<string, unknown>;
  identification: IdentificationSectionProps;
}

export interface AddVehicleFlowState {
  context: {
    fromHistoricalSession?: boolean;
    vehicleAddFlow?: string;
    isFleetContext: boolean;
  };
  data: AddVehicleFlowData;
  sections: AddVehicleFlowSections;
  wizard: { step: WizardStep };
}

export const hasUserInputValue = (
  state: AddVehicleFlowState,
  sectionID: string,
  key: string,
) => hasValue(state.sections[sectionID][key])

export const getUserInputValue = (
  state: AddVehicleFlowState,
  sectionID: string,
  key: string,
) => getProp(
  state.sections as unknown as Record<string, unknown>,
  `${sectionID}.${key}`,
)

// helper selector to check if the session has a serial number
export const isSerialNumberFromSession = (state: AddVehicleFlowState) => hasValue(state.data?.unknownVehicleSession?.rf_id)

// checks whether or not the serial number is already present from a plugged in vehicle
// OR if user has already entered it in a previous screen
export const isSerialNumberAlreadyPresent = (state: AddVehicleFlowState) => {
  const serialPresent = isSerialNumberFromSession(state)
  return (
    serialPresent
    || (state.sections.charging.id_type === VehicleIdTypes.ID_TYPE_RF_ID
      && hasValue(state.sections.identification.rf_id))
  )
}

// gets the telematics field used to identify a vehicle for a particular telematics provider
export const getTelematicsSearchField = (
  state: AddVehicleFlowState,
  accountId: string,
) => (
  state.data?.identification?.telematics_accounts
    ?.find((acct) => acct.id === parseInt(accountId))
    ?.vehicle_search_field?.toLowerCase()
    ?? VehicleIdTypes.ID_TYPE_VIN
)

export interface WizardScreenProps {
  dispatch?: React.Dispatch<unknown>;
  t: TFunction;
  onChange?: WizardInputChangeFunc;
  values?: DynamicObject<unknown>;
  unknownVehicleProps?: Partial<DepotDetailsRow>;
  updateSectionData: (
    sectionName: string,
    payload: DynamicObject<unknown>
  ) => void;
  state: AddVehicleFlowState;
  isTest?: boolean;
  externalAction: (actionName: string, args?: unknown) => void;
}

export const addVehicle = (
  request: ServiceRequest,
  callback: (res: unknown, req: unknown) => void,
) => VehicleService.addVehicle(request).then((response) => {
  callback(response, request)
})

export const mergeVehicle = (
  request: ServiceRequest,
  callback: (res: unknown, req: unknown) => void,
) => VehicleService.mergeVehicle(request).then((response) => {
  callback(response, request)
})

export const getDataForStep = (stepId: string): Promise<unknown> => {
  const requests: { [key: string]: () => Promise<unknown> } = {
    getting_started : () => FleetService.getFleets(),
    vehicle         : () => Promise.all([FleetService.getFleets(), VehicleService.getMakes()]),
    identification  : () => TelematicsService.getTelematicsAccounts(),
  }

  if (requests[stepId]) {
    return requests[stepId]()
  }

  return new Promise((resolve) => {
    resolve(null)
  })
}

const getVehicleScreenProps = (state: AddVehicleFlowState, t: TFunction) => {
  const { step }                         = state.wizard
  const existingVehicleFlow              = state.sections.getting_started.vehicleAddFlow
    === AddVehicleFlow.ExistingVehicle
  const isUnknownVehicle                 = hasValue(state.data.unknownVehicleSession)
  const fromPlugIn                       = state.sections?.charging?.id_type === 'plug_in'
  const { activeUnknownVehicleSessions } = state.data?.vehicle ?? {}
  const unknownVehicleSelected           = state.sections?.vehicle?.unknownVehicleSelected
  const hasMacAddr                       = hasValue(state.sections?.identification?.mac_address)
  const activeSessionsFound              = activeUnknownVehicleSessions?.length
  const showAddUnknownVehicleScreen      = fromPlugIn && activeSessionsFound && !unknownVehicleSelected
  const childVehicleSteps                = !isUnknownVehicle
    && fromPlugIn && [
    {
      id             : 'vehicle_confirm',
      label          : t('vehicle', { count: 1 }),
      showNav        : true,
      showBreadCrumb : !state.sections.vehicle?.vehicleSelectionConfirmed,
      active         : !existingVehicleFlow,
      validate       : () => hasValue(state.sections.identification.mac_address),
    },
    {
      id             : 'vehicle',
      label          : t('vehicle', { count: 1 }),
      showNav        : true,
      showBreadCrumb : !state.sections.vehicle?.vehicleSelectionConfirmed,
      active         : !existingVehicleFlow,
      validate       : ({
        fleet_name,
        model_make,
        model_name,
        model_year_id,
      }: Vehicle) => hasValue([fleet_name, model_make, model_name, model_year_id]),
    },
  ]

  const returnProps = {
    ...step,
    hasMacAddr,
    fromPlugIn,
    showAddUnknownVehicleScreen,
    steps: childVehicleSteps,
  }

  if (!isUnknownVehicle && fromPlugIn) {
    returnProps.unknownVehicleSelected = unknownVehicleSelected
  }

  return returnProps
}

export const getConfig = (state: AddVehicleFlowState, t: TFunction) => {
  const isUnknownVehicle      = !isEmpty(state.data.unknownVehicle)
  const vehicleScreenProps    = getVehicleScreenProps(state, t)
  const existingVehicleFlow   = state.context.vehicleAddFlow === AddVehicleFlow.ExistingVehicle
  const chargingScreenEnabled = (() => {
    if (state.context.fromHistoricalSession) {
      return false
    }

    return state.context.vehicleAddFlow !== AddVehicleFlow.ExistingVehicle
  })()
  const config                = {
    title      : t('fleets.add_vehicle'),
    components : {
      getting_started       : { component: 'getting_started' },
      charging              : { component: 'charging' },
      vehicle               : { component: 'vehicle' },
      vehicle_confirm       : { component: 'vehicle_confirm' },
      identification        : { component: 'identification' },
      existing_vehicle      : { component: 'existing_vehicle' },
      merge_vehicle_confirm : { component: 'merge_vehicle_confirm' },
      summary               : { component: 'summary' },
    },
    steps: [
      {
        id       : 'getting_started',
        label    : t('getting_started'),
        active   : !isUnknownVehicle,
        validate : () => state.data?.getting_started?.fleets?.length,
      },
      {
        id       : 'existing_vehicle',
        label    : t('choose_vehicle'),
        active   : existingVehicleFlow,
        showNav  : false,
        validate : (data: ExistingVehicleData) => !isEmpty(data?.selectedVehicle),
      },
      {
        id       : 'charging',
        active   : chargingScreenEnabled,
        label    : t('charging'),
        showNav  : true,
        validate : (data: ChargingSectionProps) => {
          if (data?.id_type) {
            return vehicleScreenProps.fromPlugIn
              ? true
              : hasValue(data.rf_id) || data.id_type === 'none'
          }
          return false
        },
      },
      {
        id       : 'vehicle',
        label    : t('vehicle', { count: 1 }),
        active   : !existingVehicleFlow,
        showNav  : true,
        steps    : vehicleScreenProps.steps,
        validate : ({
          fleet_name,
          model_make,
          model_name,
          model_year_id,
        }: Partial<Vehicle>) => hasValue([fleet_name, model_make, model_name, model_year_id]),
      },
      {
        id       : 'identification',
        active   : !existingVehicleFlow,
        label    : t('identification'),
        showNav  : true,
        validate : ({
          name,
          telematics_account_id,
          telematics_device_id,
          using_telematics,
          vin,
        }: IdentificationSectionProps) => {
          if (using_telematics) {
            return (
              hasValue([name, telematics_account_id])
              && (hasValue(vin) || hasValue(telematics_device_id))
            )
          } if (!hasValue(using_telematics)) {
            return false
          }
          return hasValue(name)
        },
      },
      {
        id       : 'summary',
        active   : !existingVehicleFlow,
        label    : t('summary'),
        showNav  : true,
        validate : () => {
          const {
            name,
            telematics_account_id,
            telematics_device_id,
            using_telematics,
          } = state.sections.identification
          if (using_telematics) {
            return hasValue([
              name,
              telematics_device_id,
              telematics_account_id,
            ])
          }
          return hasValue(name)
        },
      },
      {
        id       : 'merge_vehicle_confirm',
        active   : existingVehicleFlow,
        label    : t('summary'),
        showNav  : false,
        validate : () => true,
      },
    ].filter((screen) => screen.active),
  }

  return config
}

export const getSummaryLabel = (
  key: string,
  section: DynamicObject<unknown> | undefined,
  t: TFunction,
) => {
  const remappedFieldKeys: Record<string, TranslateParams | string | null> = {
    arrival_time_downtime_secs: {
      key  : 'arrival_time_downtime_readonly',
      vars : { numMinutes: section?.arrival_time_downtime_secs },
    },
    departure_time_downtime_secs: {
      key  : 'departure_time_downtime_readonly',
      vars : { numMinutes: section?.departure_time_downtime_secs },
    },
    schedule              : 'scheduling',
    using_telematics      : null,
    chargepoint_rfid      : 'fields.chargepoint_rfid_card_label',
    model_make            : 'make',
    model_name            : 'model',
    model_year_id         : 'year',
    model_year            : 'year',
    telematics_account_id : 'fields.telematics_account_name_label',
    rf_id                 : 'fields.chargepoint_rfid_card_label',
    vehicle_name          : 'fields.vehicle_name_label',
    fleet_name            : 'fields.fleet_name_label',
    vehicle               : 'vehicle_one',
    vehicle_mac_address   : 'fields.vehicle_mac_id_label',
  }

  if (remappedFieldKeys[key]) {
    if (typeof remappedFieldKeys[key] === 'string') {
      return t(remappedFieldKeys[key] as string)
    }
    return t(remappedFieldKeys[key].key, remappedFieldKeys[key].vars)
  }

  let label = t(key)
  // TODO: determine better strategy for label lookups, as this should not be necessary
  // maybe put all field labels in the fields section? this would require duplication of translations though...
  if (label === key) {
    label = t(`fields.${key}`) !== label ? t(`fields.${key}`) : label
  }
  return label
}

export const getMergeFlowValue = (
  state: AddVehicleFlowState,
  fieldName: string,
) => {
  // eslint-disable-next-line camelcase
  const { unknownVehicle, unknownVehicleSession } = state.data
  const { existing_vehicle: { selectedVehicle } } = state.sections

  const remapped = {
    vehicle_name : 'name',
    mac_address  : 'vehicle_mac_address',
    ...selectedVehicle,
  }

  const unknownVehicleDisplayFields  = ['station_name', 'mac_address']
  const existingVehicleDisplayFields = [
    'vehicle_name',
    'fleet_name',
    'license_plate',
    'model_make',
    'model_name',
    'model_year',
  ]

  if (unknownVehicleDisplayFields.includes(fieldName)) {
    const normalizedFieldName = remapped[fieldName] ?? fieldName
    if (!normalizedFieldName) {
      return
    }
    return (
      getProp(
        unknownVehicle as unknown as Record<string, unknown>,
        normalizedFieldName,
      )
      ?? getProp(
        unknownVehicleSession as unknown as Record<string, unknown>,
        normalizedFieldName,
      )
    )
  } if (existingVehicleDisplayFields.includes(fieldName)) {
    return (
      (remapped[fieldName] && getProp(selectedVehicle, remapped[fieldName]))
      || selectedVehicle[fieldName]
    )
  }
}

interface SummarySection {
  key: string;
  title: string;
  fields: Field[];
  fieldMap: Record<string, unknown>;
}
// NOTE: This is in progress
export const getSummaryContent = (
  state: AddVehicleFlowState,
  mergeVehicleFlow: boolean,
  t: TFunction,
): SummarySection[] => {
  const { sections }              = state
  const summarySections: string[] = ['vehicle', 'identification']
  // fields that UX wanted remapped to flow more logically in the summary view

  const sectionDisplayFields = {
    vehicle: [
      'vehicle_name',
      'fleet_name',
      'model_make',
      'model_name',
      'model_year',
    ],
    identification: [
      'telematics_account_id',
      'mac_address',
      'license_plate',
      'rf_id',
      'vin',
      'telematics_device_id',
    ],
  }

  if (mergeVehicleFlow) {
    return summarySections.map((sectionKey) => {
      const section = {
        key    : sectionKey,
        title  : getSummaryLabel(sectionKey, undefined, t),
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        fields : sectionDisplayFields[sectionKey]
          .map((fieldKey: string) => ({
            key   : fieldKey,
            label : fieldKey.toLowerCase(),
            value : getMergeFlowValue(state, fieldKey),
          }))
          .filter((f: Field) => hasValue(f.value)),
        fieldMap: sections[sectionKey as keyof typeof sections],
      }
      return section as SummarySection
    })
  }
  return summarySections.map((sectionKey) => {
    type sectionKeyType = keyof typeof sections;

    const section = {
      key   : sectionKey,
      title : getSummaryLabel(sectionKey, undefined, t),

      fields: sectionDisplayFields[
        sectionKey as keyof typeof sectionDisplayFields
      ].map((fieldKey: string) => ({
        key   : fieldKey,
        label : fieldKey.toLowerCase(),
        value : sections[sectionKey as sectionKeyType][fieldKey],
      })),
      fieldMap: sections[sectionKey as sectionKeyType],
    }
    return section as unknown as SummarySection
  }, [])
}

export const unknownVehicleDetected = (state: AddVehicleFlowState): boolean => hasValue(state.data.unknownVehicleSession)
