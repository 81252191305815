import {
  KitPagination,
  KitSelect,
  type KitSelectOption,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type DropDownItemProps } from '@chargepoint/cp-toolkit/dist/types/src/components/Dropdowns/KitDropDownItem'
import { type TFunction } from 'i18next'
import styled from 'styled-components'

import { type PaginationProps } from '@/types/index'

const { fontSize, spacing } = ThemeConstants

// TODO: discuss page sizes with UX
const pageSizes = [10, 25, 50, 75, 100, 150]

const ExtendedPaginationWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  position: display;
`

const PageSizeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spacing.xl}rem;
  min-width: 75px;
  z-index: 999;
  div {
    color: ${({ theme }) => theme.page.body.text};
    font-size: ${fontSize.base}rem;
  }
`

const PaginationText = styled.div`
  color: ${({ theme }) => theme.page.body.text};
  display: inline-block;
  margin-left: ${spacing.xl}rem;
`

function getPaginationRange(
  currentPage: number,
  pageSize: number,
  totalRecords: number,
) {
  const first = (currentPage - 1) * pageSize + 1
  const last  = first + pageSize - 1 < totalRecords ? first + pageSize - 1 : totalRecords
  return [first, last]
}

interface ExtendedPaginationProps extends Omit<PaginationProps, 'updatePage'> {
  pageSize: number;
  onPageSizeChange?: (size: number) => void;
  onPageChanged?: (page: number) => void;
  showPaginationText?: boolean;
  scrollTop?: number;
  t: TFunction;
}

export const PageSizeSwitcher = ({
  onPageSizeChange,
  pageSize,
  t,
  totalRecords,
}: {
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  totalRecords: number;
  t: TFunction;
}) => {
  const items = pageSizes
    .map((size) => ({
      label : t('pagination.page_size', { pageSize: size }),
      value : size,
    }))
    .filter(
      (item) => item.value <= totalRecords,
    ) as unknown as DropDownItemProps[]

  return (
    <PageSizeWrapper>
      <KitSelect
        id="page-size"
        options={items}
        menuPlacement="top"
        value={{
          label : t('pagination.page_size', { pageSize }),
          value : pageSize,
        }}
        onChange={(opt: unknown) => onPageSizeChange((opt as KitSelectOption).value as number)
        }
      />
    </PageSizeWrapper>
  )
}

const ExtendedPagination = (props: ExtendedPaginationProps) => {
  const {
    currentPage,
    onPageChanged,
    onPageSizeChange,
    pageSize = 50,
    scrollTop = 255,
    showPaginationText = true,
    t,
    totalPages,
    totalRecords,
  } = props

  const [firstResult, lastResult] = getPaginationRange(
    currentPage,
    pageSize,
    totalRecords,
  )

  const resetScroll = () => {
    if (scrollTop) {
      window.scrollTo(0, scrollTop)
    }
  }

  const handlePageChange = (page: number) => {
    if (onPageChanged) {
      onPageChanged(page)
      resetScroll()
    }
  }

  const handlePageSizeChange = (val: number) => {
    if (onPageSizeChange) {
      onPageSizeChange(val)
      resetScroll()
    }
  }

  return (
    <ExtendedPaginationWrapper>
      <KitPagination
        count={totalPages}
        page={currentPage}
        t={t}
        onChange={handlePageChange}
      />
      { showPaginationText && (
        <PaginationText>
          { t('pagination.recordsView', {
            firstResult,
            lastResult,
            totalResults: totalRecords,
            currentPage,
          }) }
        </PaginationText>
      ) }
      { onPageSizeChange && (
        <PageSizeSwitcher
          pageSize={pageSize}
          totalRecords={totalRecords}
          onPageSizeChange={handlePageSizeChange}
          t={t}
        />
      ) }
    </ExtendedPaginationWrapper>
  )
}

export default ExtendedPagination
