import { KitForm, KitInput, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type ChangeEvent, type FC, type Ref, useEffect, useLayoutEffect, useRef } from 'react'

import styled from 'styled-components'

import {
  AddVehicleFlow,
  type AddVehicleFlowState,
  LocationCategory,
  type WizardScreenProps,
  getUserInputValue,
  isSerialNumberFromSession,
  unknownVehicleDetected,
} from '../config'
import { VehicleIdTypes } from '@/common/constants'
import { hasValue } from '@/common/utils/validations'
import {
  WizardContent,
  WizardHeading,
} from '@/components/AddFleetWizard/styled'
import EnhancedRadio from '@/components/forms/EnhancedRadio'
import { List, ListItem } from '@/components/Styled'

const RFIDWrapper = styled.div`
  margin-top: ${ThemeConstants.spacing.absolute.s}px;
  max-width: 250px;
`

interface ChargingScreenProps extends WizardScreenProps {
  state: AddVehicleFlowState;
  onChange: (sectionId: string, name: string, value: unknown) => void;
}

const ChargingScreen: FC<ChargingScreenProps> = (props) => {
  const cardNumberRef                  = useRef<HTMLInputElement>()
  const { onChange, state, t, values } = props

  const sendChangeEvent   = (name: string, value: unknown) => onChange(AddVehicleFlow.Charging, name, value)
  const handleFieldChange = (e: unknown) => {
    const { target }      = e as ChangeEvent
    const { name, value } = target as HTMLInputElement
    sendChangeEvent(name, value)
    if (name === VehicleIdTypes.ID_TYPE_RF_ID) {
      // also store it in idenfification
      onChange(AddVehicleFlow.Identification, name, value)
    }
  }
  const instructionsType = getUserInputValue(
    state,
    AddVehicleFlow.GettingStarted,
    'instructionsType',
  )

  const isUnknownVehicle    = unknownVehicleDetected(state)
  const selectedIDType      = (() => {
    if (hasValue(values?.id_type)) {
      return values?.id_type
    }
    if (isUnknownVehicle) {
      return hasValue(state.data.unknownVehicleSession?.vehicle_mac_address)
        ? 'plug_in'
        : VehicleIdTypes.ID_TYPE_RF_ID
    }

    if (hasValue(instructionsType)) {
      return instructionsType === LocationCategory.OnPremise
        ? 'plug_in'
        : 'rf_id'
    }
    return values?.id_type
  })()
  const showIDInput         = selectedIDType === VehicleIdTypes.ID_TYPE_RF_ID
  const hidePlugIn: boolean = isUnknownVehicle
    ? !hasValue(state.data.unknownVehicleSession?.vehicle_mac_address)
    : false

  useEffect(() => {
    if (isUnknownVehicle || !hasValue(values?.id_type)) {
      sendChangeEvent('id_type', selectedIDType)
    }
  }, [isUnknownVehicle, state.data.unknownVehicleSession?.session_id])

  useLayoutEffect(() => {
    if (selectedIDType === VehicleIdTypes.ID_TYPE_RF_ID) {
      if (cardNumberRef.current) {
        cardNumberRef.current.focus()
      }
    }
  }, [])

  return (
    <WizardContent>
      <WizardHeading>
        { t('vehicles.add_vehicle.identify_vehicle_caption') }
      </WizardHeading>
      <List>
        { !hidePlugIn && (
          <ListItem>
            <EnhancedRadio
              name="id_type"
              checked={selectedIDType === 'plug_in'}
              label={t('vehicles.add_vehicle.charging.id_method_plug_in_label')}
              description={t(
                'vehicles.add_vehicle.charging.id_method_plug_in_description',
              )}
              value="plug_in"
              onChange={handleFieldChange}
            />
          </ListItem>
        ) }

        <ListItem>
          <EnhancedRadio
            name="id_type"
            checked={selectedIDType === VehicleIdTypes.ID_TYPE_RF_ID}
            label={t('vehicles.add_vehicle.charging.id_method_user_id_label')}
            description={t(
              'vehicles.add_vehicle.charging.id_method_user_id_description',
            )}
            value={VehicleIdTypes.ID_TYPE_RF_ID}
            onChange={handleFieldChange}
          >
            { showIDInput && (
              <RFIDWrapper>
                <KitForm.Group>
                  <KitForm.Label
                    text={t('rfid_card')}
                    htmlFor="rf_id"
                    required
                  />
                  <KitInput
                    ref={cardNumberRef as Ref<HTMLInputElement>}
                    id="rf_id"
                    name="rf_id"
                    required
                    value={(values?.rf_id as string) ?? ''}
                    disabled={isSerialNumberFromSession(state)}
                    onChange={handleFieldChange}
                    hideInfoErrorMessage
                  />
                </KitForm.Group>
              </RFIDWrapper>
            ) }
          </EnhancedRadio>
        </ListItem>
        { !isUnknownVehicle && (
          <ListItem>
            <EnhancedRadio
              name="id_type"
              checked={selectedIDType === 'none'}
              label={t('vehicles.add_vehicle.charging.id_method_none_label')}
              description={t(
                'vehicles.add_vehicle.charging.id_method_none_description',
              )}
              value="none"
              onChange={handleFieldChange}
            />
          </ListItem>
        ) }
      </List>
    </WizardContent>
  )
}

export default ChargingScreen
