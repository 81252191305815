import { type TFunction } from 'i18next'
import * as Yup from 'yup'

import {
  MIN_SITE_DEMAND_LIMIT,
  MIN_TARGET_DEMAND_LIMIT,
} from '@/common/constants'
import { hasValue } from '@/common/utils/validations'

export interface SchemaError {
  message: string;
  field: string;
}

export const getValidationSchema = (t: TFunction) => Yup.object({
  demand_target_kw: Yup.mixed().test(
    'number',
    {
      field   : 'demand_target_kw',
      message : t('errors.value_must_be_greater_than', { number: MIN_TARGET_DEMAND_LIMIT - 1 }),
    },
    (value) => {
      if (hasValue(value)) {
        if (parseFloat(value) <= MIN_TARGET_DEMAND_LIMIT - 1) {
          return false
        }
      }
      return true
    },
  ),
  hard_demand_limit_kw: Yup.mixed().test(
    'number',
    {
      field   : 'hard_demand_limit_kw',
      message : t('errors.value_must_be_greater_than', { number: MIN_SITE_DEMAND_LIMIT - 1 }),
    },
    (value) => {
      if (hasValue(value)) {
        if (parseFloat(value) <= MIN_SITE_DEMAND_LIMIT - 1) {
          return false
        }
      }
      return true
    },
  ),
})
