import { differenceInSeconds, intervalToDuration, parseISO } from 'date-fns'
import { type TFunction } from 'i18next'

import { type VehicleTripHistoryRow } from './types'
import {
  EMPTY_VALUE_PLACEHOLDER,
  ISO_DATE_TIME_WITH_SECONDS,
} from '@/common/constants'
import {
  formatNumber,
  formatPercent,
  getLocalizedDistance,
  numberFormatOptions,
} from '@/common/lang'
import { dateSort, getSign, numericSort } from '@/common/utils/data'
import { formatDate, formatDurationAsHours } from '@/common/utils/date'
import { hasValue } from '@/common/utils/validations'
import { type ColumnDef, type SortFunc } from '@/types/index.d'

export const getColumns = (
  sortDir: number,
  t: TFunction,
  units: string,
): ColumnDef<VehicleTripHistoryRow>[] => [
  {
    field  : 'pull_out_formatted',
    label  : t('trip_history.pull_out'),
    sortFn : dateSort('pull_out', sortDir),
  },
  {
    field  : 'pull_in_formatted',
    label  : t('trip_history.pull_in'),
    sortFn : dateSort('pull_in', sortDir),
  },
  {
    field  : 'duration',
    label  : t('trip_history.duration'),
    sortFn : numericSort('interval_in_seconds', sortDir),
  },
  {
    field  : 'soc_out_pct_formatted',
    label  : t('trip_history.soc_out'),
    sortFn : numericSort('soc_out_pct', sortDir),
    align  : 'right',
  },
  {
    field  : 'soc_in_pct_formatted',
    label  : t('trip_history.soc_in'),
    sortFn : numericSort('soc_in_pct', sortDir),
    align  : 'right',
  },
  {
    field  : 'odometer_out_miles_formatted',
    label  : t('trip_history.odometer_out', { units }),
    sortFn : numericSort('odometer_out_miles', sortDir),
    align  : 'right',
  },
  {
    field  : 'odometer_in_miles_formatted',
    label  : t('trip_history.odometer_in', { units }),
    sortFn : numericSort('odometer_in_miles', sortDir),
    align  : 'right',
  },
]

export const getSortedTripHistory = (
  tripHistory: VehicleTripHistoryRow[],
  sortField: string,
  sortDirection: unknown,
  columns: ColumnDef[],
) => {
  if (sortDirection === null) {
    return tripHistory.sort(
      (a: VehicleTripHistoryRow, b: VehicleTripHistoryRow) => (a.index > b.index ? 1 : -1),
    )
  }
  const col = columns.find((column: ColumnDef) => column.field === sortField)
  return tripHistory.sort(col?.sortFn as SortFunc)
}

export const tripsMap = (trip: VehicleTripHistoryRow, index: number) => {
  const emptyCell = EMPTY_VALUE_PLACEHOLDER
  const result    = { ...trip, index }

  result.pull_in_formatted   = trip.pull_in
    ? formatDate(trip.pull_in, ISO_DATE_TIME_WITH_SECONDS)
    : emptyCell
  result.pull_out_formatted  = trip.pull_out
    ? formatDate(trip.pull_out, ISO_DATE_TIME_WITH_SECONDS)
    : emptyCell
  const duration             = trip.pull_in && trip.pull_out
    ? intervalToDuration({
      start : parseISO(trip.pull_out),
      end   : parseISO(trip.pull_in),
    })
    : ''
  result.interval_in_seconds = duration
    ? Math.abs(
      differenceInSeconds(parseISO(trip.pull_out), parseISO(trip.pull_in)),
    )
    : 0
  result.duration            = duration ? formatDurationAsHours(duration) : emptyCell

  result.odometer_out_miles_formatted =    trip.odometer_out_miles !== null
    ? (getLocalizedDistance(trip.odometer_out_miles, true) as string)
    : emptyCell

  const odometerDiff = (() => {
    const plusMinus = getSign(trip.odometer_out_miles, trip.odometer_in_miles)
    return ` (${plusMinus}${formatNumber(
      Math.abs(trip.odometer_out_miles - trip.odometer_in_miles),
      { maximumFractionDigits: 0 },
    )})`
  })()

  result.odometer_in_miles_formatted =    trip.odometer_in_miles !== null
    ? `${getLocalizedDistance(trip.odometer_in_miles, true)}${odometerDiff}`
    : emptyCell

  const socDiff = (() => {
    if (hasValue([trip.soc_in_pct, trip.soc_out_pct])) {
      const plusMinus = getSign(trip.soc_in_pct, trip.soc_out_pct)
      return `(${plusMinus}${formatPercent(
        Math.abs(trip.soc_in_pct - trip.soc_out_pct),
        numberFormatOptions.soc,
      )})`
    }
    return ''
  })()
  result.soc_in_pct_formatted  = trip.soc_in_pct
    ? `${trip.soc_in_pct}%${socDiff}`
    : emptyCell
  result.soc_out_pct_formatted = trip.soc_out_pct
    ? `${trip.soc_out_pct}%`
    : emptyCell
  return result
}
