import { has } from '@/common/utils/data'

export interface IEventProperties {
  [key: string]: any;
}

// TODO:
// - Sync with product team to see what sorts of events we want to track
// - Sync with eng team to ensure we are collecting the correct
//   metadata to slice and dice data on in the future
export const analyticEvents = {
  logout                      : 'log-out',
  fleetAdded                  : 'fleet-added',
  fleetUpdated                : 'fleet-updated',
  vehicleAdded                : 'vehicle-added',
  vehicleAddError             : 'vehicle-add-error',
  vehicleSettingsUpdated      : 'vehicle-settings-updated',
  vehicleScheduleUpdated      : 'vehicle-schedule-updated',
  blockAssignmentsUploaded    : 'block-assignments-uploaded',
  blockTemplatesUploaded      : 'block-templates-uploaded',
  blockAssignmentsDownloaded  : 'block-assignments-downloaded',
  blockTemplatesDownloaded    : 'block-templates-downloaded',
  blockAssignmentAdded        : 'block-assignment-added',
  blockAssignmentUpdated      : 'block-assignment-updated',
  filtersApplied              : 'filters-applied',
  columnSorted                : 'column-sorted',
  pageView                    : 'page-view',
  siteEnergySettingsUpdated   : 'site-energy-settings-updated',
  siteEnergyChartQueryUpdated : 'site-energy-chart-query-updated',
  telematicsAccountAdded      : 'telematics-account-added',
  telematicsAccountDeleted    : 'telematics-account-deleted',
  telematicsAccountUpdated    : 'telematics-account-updated',
}

// required for all tracked events
const defaultRequiredEventProperties = ['path']

const vehicleUpdatedProps = [
  'identifiedBy',
  'scheduleType',
  'telematicsProvider',
  'vehicleMake',
  'vehicleModel',
]

const requiredEventProperties = {
  [analyticEvents.fleetAdded]             : ['scheduleType'],
  [analyticEvents.vehicleAdded]           : vehicleUpdatedProps,
  [analyticEvents.vehicleSettingsUpdated] : vehicleUpdatedProps,
}

const getRequiredProperties = (eventName: string): string[] => {
  const result = has(requiredEventProperties, eventName)
    ? [...defaultRequiredEventProperties, ...requiredEventProperties[eventName]]
    : defaultRequiredEventProperties
  return result
}

export const validateEventProperties = (
  eventName: string,
  eventProperties: IEventProperties,
): boolean => {
  const requiredProperties               = getRequiredProperties(eventName)
  const missingEventProperties: string[] = []
  const { hasOwnProperty }               = Object.prototype

  requiredProperties.forEach((prop) => {
    if (!hasOwnProperty.call(eventProperties, prop)) {
      missingEventProperties.push(prop)
    }
  })

  if (missingEventProperties.length > 0) {
    const missingProps = missingEventProperties.join(', ')
    console.warn(
      `[${eventName}] is missing event properties: [${missingProps}]`,
      'event not sent',
    )
    return false
  }

  return true
}
