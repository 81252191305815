// This component should be dumber.
// This component is trying to handle inputs that could either be a date or a datestring,
// making it overly complicated with a bunch of coversion logic
// That sort of logic should be handled by a utility or outside of the component itself
// Another TODO...
import {
  KitButton,
  KitFlexRowSpaced,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { endOfDay, startOfDay } from 'date-fns'
import { type TFunction } from 'i18next'
import { type ChangeEvent, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import styled from 'styled-components'

import { toDate, toISO, toISODateRange } from '../../common/utils/date'
import { FormError } from '../Styled'
import {
  FilterFieldSet,
  FilterLegend,
} from '@/components/Styled/filterBarStyles'
import { type DateRange, type TypedRange } from '@/types/index'

const { fontSize, spacing } = ThemeConstants

const StyledDateInput = styled.input<{
  error?: boolean | string;
  width?: string;
}>`
  border: 0;
  border-bottom: 1px solid ${ThemeColors.gray_30};
  color: ${ThemeColors.gray_90};
  padding: ${spacing.s}rem;
  width: ${({ width }) => width ?? '120px'};
  text-align: center;
  &:focus {
    background: ${ThemeColors.gray_10};
    border-bottom: 2px solid ${({ theme }) => theme.focus};
  }
`

const DateInputWrapper = styled.div`
  position: relative;
`

const DateRangeSeparator = styled.span`
  margin-left: ${spacing.absolute.xs}px;
  margin-right: ${spacing.absolute.xs}px;
`

const PlaceholderOverlay = styled.div`
  align-items: center;
  background: ${ThemeColors.white};
  bottom: 1px;
  cursor: pointer;
  display: flex;
  font-size: ${fontSize.text_14}rem;
  justify-content: center;
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
`

type DateInputRange = TypedRange<Date | string | undefined>;

type DateInputProps = {
  ariaLabel?: string;
  error?: string;
  id: string;
  inputWidth?: string;
  label?: string;
  min?: Date | string;
  max?: Date | string;
  value?: Date | string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  t: TFunction;
};

export interface DateRangeInputProps {
  error?: string;
  name: string;
  label?: string;
  min?: Date | string;
  max?: Date | string;
  range: DateRange;
  onChange: (...args: unknown[]) => void;
  onClear?: (fieldName: string) => void;
  validate?: (val: DateInputRange) => boolean;
  strings?: {
    [key: string]: string;
  };
  t: TFunction;
}

export const DateInput = ({
  ariaLabel,
  error,
  id,
  inputWidth,
  label,
  max,
  min,
  onChange,
  placeholder,
  required,
  value,
}: Omit<DateInputProps, 't'>) => {
  const [showPlaceHolder, setShowPlaceHolder] = useState(placeholder)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  return (
    <DateInputWrapper>
      { label && <label htmlFor={id}>{ label } </label> }
      { showPlaceHolder && placeholder && (
        <PlaceholderOverlay onClick={() => setShowPlaceHolder('')}>
          { placeholder }
        </PlaceholderOverlay>
      ) }

      <StyledDateInput
        error={error}
        id={id}
        type="date"
        aria-label={ariaLabel}
        onInput={handleChange}
        onChange={handleChange}
        min={toISO(min as string)}
        max={toISO(max as string)}
        required={required}
        value={toISO(value as string)}
        width={inputWidth}
      />
    </DateInputWrapper>
  )
}

const getDefaultRange = (
  min?: Date | string,
  max?: Date | string,
): DateInputRange => {
  if (!min || !max) {
    return [undefined, undefined]
  }
  return [min, max]
}

export const DateRangeInput = ({
  error,
  label,
  max,
  min,
  name,
  onChange,
  onClear,
  range,
  strings,
  t,
  validate,
}: DateRangeInputProps) => {
  const defaultRange          = getDefaultRange(min, max)
  const [userRange, setRange] = useState<DateInputRange>(range ?? defaultRange)

  const handleOnClear = () => {
    setRange(defaultRange)
    if (onClear) {
      onClear(name)
    }
  }

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { target: { id, value } } = e

    const resultRange: DateInputRange = [
      toDate(userRange[0] as string | Date),
      toDate(userRange[1] as string | Date),
    ]

    if (id === 'start') {
      resultRange[0] = normalizeRangeItemValue(value, id)
    } else {
      resultRange[1] = normalizeRangeItemValue(value, id)
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setRange(resultRange)
    })

    if (onChange) {
      if (validate) {
        const valid = validate(resultRange)
        if (valid) {
          onChange(name, resultRange)
        }
      } else {
        onChange(name, resultRange)
      }
    }
  }

  useEffect(() => {
    if (range) {
      setRange(range)
    }
  }, [range])

  const showPlaceHolder =    toISO(userRange[0]) === toISO(min as Date)
    && toISO(userRange[1]) === toISO(max as Date)
  const minPlaceHolder  = showPlaceHolder ? strings?.default_placeholder : null
  const maxPlaceHolder  = showPlaceHolder ? strings?.default_placeholder : null

  const inputRange = toISODateRange(userRange)

  return (
    <>
      <FilterFieldSet>
        <KitFlexRowSpaced>
          { label && (
            <FilterLegend>
              <span>{ label }</span>
            </FilterLegend>
          ) }
          { onClear && (
            <KitButton variant="link" onClick={handleOnClear}>{ t('btn_clear') }</KitButton>
          ) }
        </KitFlexRowSpaced>
        <KitFlexRowSpaced>
          <DateInput
            ariaLabel={t('fields.date_range_start_label')}
            id="start"
            inputWidth={'150px'}
            min={min}
            max={inputRange[1] ?? max}
            value={inputRange[0]}
            onChange={handleOnChange}
            placeholder={minPlaceHolder ?? ''}
            required
          />
          <DateRangeSeparator>-</DateRangeSeparator>
          <DateInput
            ariaLabel={t('fields.date_range_end_label')}
            id="end"
            inputWidth={'150px'}
            min={inputRange[0]}
            max={max}
            value={inputRange[1]}
            onChange={handleOnChange}
            placeholder={maxPlaceHolder ?? ''}
            required
          />
        </KitFlexRowSpaced>

        { error && <FormError>{ error }</FormError> }
      </FilterFieldSet>
    </>
  )
}

export const normalizeRangeItemValue = (isoDate: string, position: string) => {
  if (isoDate) {
    return position === 'start'
      ? startOfDay(toDate(isoDate))
      : endOfDay(toDate(isoDate))
  }
}
