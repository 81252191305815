/* eslint-disable @typescript-eslint/naming-convention */
import { secondsToMinutes } from 'date-fns'
import type { TFunction } from 'i18next'
import { EMPTY_VALUE_PLACEHOLDER } from '@/common/constants'
import { formatPercent } from '@/common/lang'
import { formatTimeString } from '@/common/utils/date'
import { hasValue } from '@/common/utils/validations'
import type { Schedule } from '@/models/scheduleModel'
import { ScheduleContext, ScheduleType } from '@/models/scheduleModel'

// utils to assist with building ui's for Fleet and Vehicle schedules
export const getScheduleContextValueText = (scheduleContext: ScheduleContext, t: TFunction) => (
  scheduleContext === ScheduleContext.FLEET
    ? t('vehicles.settings.scheduling.schedule_context_option_fleet')
    : t('vehicles.settings.scheduling.schedule_context_option_vehicle'))

export const getScheduleTypeValueText = (scheduleType: ScheduleType, t: TFunction) => (
  scheduleType === ScheduleType.BY_TIME
    ? t('vehicles.settings.scheduling.schedule_type_option_scheduled')
    : t('vehicles.settings.scheduling.schedule_type_option_asap'))

export const getScheduleTypeLabel = (scheduleContext: ScheduleContext, t: TFunction) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  t('vehicles.settings.scheduling.schedule_type_label', { fleetOrVehicle: t(scheduleContext) })

export const getScheduleTypeSOCLabel = (scheduleType: ScheduleType, t: TFunction) => (
  scheduleType === ScheduleType.ASAP
    ? t('vehicles.settings.scheduling.target_soc_asap_label')
    : t('vehicles.settings.scheduling.target_soc_scheduled_label'))

export interface ReadOnlyField {
  detail?: string;
  label: string;
  value: string;
}

export const prepareScheduleTimeString = (timeString: string): string => {
  const matchResult =  timeString.match(/:/g)
  const hasSeconds  = matchResult && matchResult.length > 1
  return !hasSeconds ? `${timeString}:00` : timeString as string
}

export const getReadOnlyFields = (schedule: Schedule, scheduleType: ScheduleType, scheduleContext: ScheduleContext, t: TFunction, meta?: { showContext: boolean }) => {
  const {
    arrival_time,
    arrival_time_downtime_secs,
    departure_time,
    departure_time_downtime_secs,
    required_soc,
  } = schedule

  return [
    {
      label : t('vehicles.settings.scheduling.schedule_context_label'),
      value : meta?.showContext !== false ? getScheduleContextValueText(scheduleContext, t) : undefined,
    },
    {
      label : t('vehicles.settings.scheduling.schedule_type_label', { fleetOrVehicle: scheduleContext }),
      value : getScheduleTypeValueText(scheduleType, t),
    },
    {
      label  : t('arrival_time'),
      value  : arrival_time ? formatTimeString(arrival_time) : '',
      detail : t('arrival_time_downtime_readonly', { numMinutes: arrival_time_downtime_secs }),
    },
    {
      label  : t('departure_time'),
      value  : departure_time ? formatTimeString(departure_time) : '',
      detail : t('departure_time_downtime_readonly', { numMinutes: departure_time_downtime_secs }),
    },
    {
      label : getScheduleTypeSOCLabel(scheduleType, t),
      value : required_soc ? formatPercent(required_soc) : EMPTY_VALUE_PLACEHOLDER,
    },
  ].filter((item) => hasValue(item.value))
}

// This function takes the schedule api response and formats *_downtime_secs as minutes for UI presentation
export const scheduleToUI = (schedule: Partial<Schedule>): Schedule => {
  if (!schedule) {
    return {}
  }
  const {
    arrival_time_downtime_secs,
    departure_time_downtime_secs,
  } = schedule

  return {
    ...schedule,
    arrival_time_downtime_secs   : secondsToMinutes(arrival_time_downtime_secs ?? 0),
    departure_time_downtime_secs : secondsToMinutes(departure_time_downtime_secs ?? 0),
  }
}

export const hasSchedule = (
  schedule: Schedule,
  requiredFields = ['arrival_time', 'departure_time'],
) => {
  if (!schedule) {
    return false
  }
  return requiredFields.every((key) => hasValue(schedule[key as keyof Schedule]))
}

/**
 * Constructs a valid schedule payload for fleets or vehicles
 * @param schedule
 * @param scheduleType
 * @param scheduleContext
 * @param inheritFromFleet
 * @returns
 */
export const getPayload = (schedule: Schedule, scheduleType: ScheduleType, scheduleContext: ScheduleContext, inheritFromFleet?: boolean) => {
  const {
    arrival_time,
    arrival_time_downtime_secs = 0,
    departure_time,
    departure_time_downtime_secs = 0,
  } = schedule

  if (inheritFromFleet) {
    return { inherit_from_fleet: true }
  }

  const content = scheduleType === ScheduleType.ASAP ? { required_soc: Number(schedule.required_soc) } : {
    arrival_time                 : prepareScheduleTimeString(arrival_time as string),
    departure_time               : prepareScheduleTimeString(departure_time as string),
    arrival_time_downtime_secs   : Number(arrival_time_downtime_secs),
    departure_time_downtime_secs : Number(departure_time_downtime_secs),
    required_soc                 : Number(schedule.required_soc),
  }

  return { content, schedule_type: scheduleType, ...(scheduleContext === ScheduleContext.VEHICLE ? { inherit_from_fleet: false } : {}) }
}
