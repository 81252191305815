import {
  KitForm,
  KitInput,
  KitSelect,
  KitSpinner,
  KitUtilData,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { useEffect, useReducer, useState } from 'react'

import styled from 'styled-components'
import EditSettingsForm from './EditSettingsForm'
import { FormActions, formReducer } from './reducer'
import {
  type VehicleIdentificationFieldDef,
  type VehicleIdentificationFormState,
} from './types'
import {
  buildRequest,
  createID,
  getInitialValue,
  getNormalizedChangeEventValue,
  getNormalizedFieldValue,
  getReadOnlyFields,
  getRequestFields,
  processIdentificationSettingsResponse,
  validate,
} from './utils'
import { EditMode, RequestType } from '@/common/constants'
import { FLEET_VEHICLE_UPDATE } from '@/common/permissions'
import { getDjangoErrorMessage } from '@/common/utils/error'
import { hasValue } from '@/common/utils/validations'
import EditPanel from '@/components/EditPanel'
import DeletableTextField from '@/components/forms/DeletableTextField'
import Notification, { NotificationLevel } from '@/components/Notification'
import ReadOnlySettings from '@/components/Settings/ReadOnlySettings'
import { MinHeightSpinnerContainer } from '@/components/StyledSpinner'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { type SelectChangeAction } from '@/models/formModel'
import { type ServiceError, type ServicePayload } from '@/models/serviceModel'
import {
  type RefreshDataFunc,
  type VehicleIdentificationResponse,
} from '@/models/vehicleModel'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import TelematicsService from '@/services/TelematicsService'
import VehicleService from '@/services/VehicleService'
import { type NormalizedChangeEvent } from '@/types/index'

const FormWrapper = styled.div`
    .disabled {
      color: ${({ theme }) => theme.button.secondary.disabled.text};
      opacity: 0.8;
      pointer-events: none;
    }

    .add-field-buttons {
      button {
        text-align: left;
        &: first-child {
          margin-inline-end: ${ThemeConstants.spacing.s}rem;
        }

    }
`

const { isEmpty } = KitUtilData

const componentMap = {
  text      : KitInput,
  select    : KitSelect,
  deletable : DeletableTextField,
}

const initialState: VehicleIdentificationFormState = { fields: [] as VehicleIdentificationFieldDef[] }

export interface VehicleSettingsIdentificationPanelProps {
  id: string;
  data: VehicleIdentificationResponse;
  refreshData: RefreshDataFunc;
  t: TFunction;
}

const VehicleSettingsIdentificationPanel = (
  props: VehicleSettingsIdentificationPanelProps,
) => {
  const { data, id: vehicleId, refreshData, t } = props
  const [inEditMode, setInEditMode]             = useState(false)
  const [formState, dispatch]                   = useReducer(formReducer, initialState)

  const [isLoading, setIsLoading]       = useState(false)
  const [serviceError, setServiceError] = useState<string>()
  const { analyticsService }            = useAnalyticsService()

  const initializeForm = () => {
    if (!isEmpty(data)) {
      const processedFields = processIdentificationSettingsResponse(data, t)
      dispatch({
        type     : FormActions.INIT_FORM,
        fields   : processedFields,
        response : data,
      })
    }
  }

  // if we haven't already added a telematics_device_id field, add it right after the telematics_account_id field
  const checkIfTelematicsIDRequired = (value: string | number) => {
    const initialDeviceID = getInitialValue('telematics_device_id', data)
    const deviceIDField   = formState.fields.find(
      (f) => f.props.name === 'telematics_device_id',
    )

    if (value === 'none') {
      const fieldsToRemove = ['telematics_device_id']
      return dispatch({
        type      : FormActions.REMOVE_FIELD,
        fieldName : fieldsToRemove,
      })
    }

    // if the telematics_account_id field is set and telematics_device_id field does not exist, then add it
    if (!deviceIDField) {
      const telematicsIDSortIndex = formState.fields.findIndex(
        (f) => f.props.name === 'telematics_account_id',
      ) + 1

      dispatch({
        type  : FormActions.ADD_FIELD,
        field : {
          component : 'text',
          sortIndex : telematicsIDSortIndex,
          props     : {
            'data-id' : createID(),
            name      : 'telematics_device_id',
            label     : t('fields.telematics_device_id'),
            value     : initialDeviceID,
          },
        },
      })
    }
  }

  const handleCancel = () => {
    setInEditMode(false)
    setServiceError(undefined)
    initializeForm()
  }

  const handleFieldChange = (
    e: NormalizedChangeEvent,
    action?: SelectChangeAction,
  ) => {
    const { id, name, value } = getNormalizedChangeEventValue(e, action)
    const requestType         = hasValue(getInitialValue(name, data, id as string))
      ? RequestType.UPDATE
      : RequestType.CREATE
    const fieldType           = ['telematics_account_id'].includes(name)
      ? 'react-select'
      : 'text'

    dispatch({
      type       : FormActions.UPDATE_FIELD,
      requestType,
      fieldID    : hasValue(id) ? parseInt(id as string) : undefined,
      fieldName  : name,
      fieldValue : getNormalizedFieldValue(value, e.label, fieldType),
    })

    if (name === 'telematics_account_id') {
      checkIfTelematicsIDRequired(value as string)
    }
  }

  const handleFieldDelete = (fieldName: string, id: number | string) => {
    dispatch({
      type    : FormActions.DELETE_FIELD,
      fieldName,
      fieldID : id,
    })
  }

  const addField = (fieldName: string) => {
    const label     = t(`vehicles.settings.identification.${fieldName}`)
    const id        = createID()
    const sortIndex = formState.fields.findLastIndex((f) => f.props.name === fieldName) + 1

    dispatch({
      type  : FormActions.ADD_FIELD,
      field : {
        component   : 'deletable',
        requestType : RequestType.CREATE,
        sortIndex,
        props       : {
          'data-id' : id,
          id,
          label,
          name      : fieldName,
          value     : undefined,
        },
      },
    })
  }

  async function submitForm(
    vehicleID: string,
    request: ServicePayload,
    callback: (err?: ServiceError) => void,
  ) {
    const response = await VehicleService.updateVehicleSettings(
      vehicleID,
      'identification',
      request,
    )
    callback(response.error)
  }

  const handleSubmit = () => {
    const errorFields = validate(formState, t)
    if (errorFields.length) {
      return dispatch({ type: FormActions.VALIDATE, errors: errorFields })
    }

    const request = buildRequest(formState)

    setIsLoading(true)

    submitForm(vehicleId, request, (error) => {
      if (error) {
        setServiceError(t('errors.service_unavailable'))
        setIsLoading(false)
        analyticsService.trackEvent(
          analyticEvents.vehicleSettingsUpdated,
          { failed: true, request, error: getDjangoErrorMessage(error, 'failed to update vehicle settings') },
        )
        return
      }
      setServiceError(undefined)
      analyticsService.trackEvent(analyticEvents.vehicleSettingsUpdated, {
        fields: formState.fields
          .filter((field) => [RequestType.UPDATE, RequestType.CREATE].includes(
            field.requestType as RequestType,
          ))
          .map((field) => field.props.name),
      })

      setTimeout(() => {
        refreshData('none', {}, { noRefresh: true })
        setInEditMode(false)
        setIsLoading(false)
        // Adding a slight delay here before making an api call to refresh vehicle data because
        // there is a known issue on the backend which throws an unhandled exception when a vehicle api request
        // is made for the same vehicle too soon after updating it's IDs.
      }, 2000)
    })
  }

  const disableSubmit = (() => {
    // check to see if individual field values have been modified
    const flattened = getRequestFields(formState)
    const changed   = flattened.find((f) => hasValue(f.requestType))

    return !hasValue(changed)
  })()

  useEffect(() => {
    setIsLoading(true)
    async function fetchTelematics() {
      const { error, results } = await TelematicsService.getTelematicsAccounts()
      if (!error) {
        setIsLoading(false)
        dispatch({
          type  : FormActions.SET_PROP,
          prop  : 'telematicsAccounts',
          value : results,
        })
        initializeForm()
      }
    }
    if (!formState.telematicsAccounts) {
      fetchTelematics()
    } else {
      setIsLoading(false)
      initializeForm()
    }
  }, [vehicleId, data])

  useEffect(() => {
    if (data) {
      initializeForm()
    }
  }, [data])

  useEffect(() => {

  }, [])

  return (
    <EditPanel
      id="identification"
      title={t('identification')}
      t={t}
      disableSubmit={disableSubmit}
      mode={inEditMode ? EditMode.EDIT : EditMode.READ_ONLY}
      onCancel={handleCancel}
      onToggle={() => {
        setInEditMode(!inEditMode)
      }}
      onSubmit={handleSubmit}
      permissions={FLEET_VEHICLE_UPDATE}
      isLoading={isLoading}
    >
      { serviceError && (
        <>
          <Notification
            type={NotificationLevel.ERROR}
            message={t('vehicles.settings.update_settings_error')}
          />
          <br />
        </>
      ) }
      { isLoading && (
        <MinHeightSpinnerContainer>
          <KitSpinner withOverlay size="s" />
        </MinHeightSpinnerContainer>
      ) }
      <FormWrapper>
        <KitForm noValidate>
          { !isLoading && !inEditMode && (
            <ReadOnlySettings
              fields={getReadOnlyFields(data, t)}
              emptyMessage={t('vehicles.settings.info_no_ids_set')}
            />
          ) }
          { !isLoading && inEditMode && (
            <EditSettingsForm
              componentMap={componentMap}
              isLoading={isLoading}
              state={formState}
              fields={formState.fields}
              addField={addField}
              onDelete={handleFieldDelete}
              onChange={handleFieldChange}
              t={t}
            />
          ) }
        </KitForm>
      </FormWrapper>
    </EditPanel>
  )
}

export default VehicleSettingsIdentificationPanel
