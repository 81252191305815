// ADD VEHICLE FLOW, STEP 1
import { type FC } from 'react'

import { AddVehicleFlow, type WizardScreenProps, getUserInputValue } from '../../config'
import NoFleetFound from '../NoFleetFound'
import GettingStartedInstructions from './Instructions'
import { type DepotDetailsRow } from '@/models/depotModel'

interface GettingStartedProps extends Omit<WizardScreenProps, 'onChange'> {
  unknownVehicleProps?: Partial<DepotDetailsRow>;
  onChange: (
    step: AddVehicleFlow,
    fieldName: string,
    fieldValue: string
  ) => void;
}

const GettingStarted: FC<GettingStartedProps> = (props) => {
  const { onChange, state, t } = props
  const { getting_started }    = state.data
  const noFleetsFound          = !getting_started.fleets?.length && state.context?.isFleetContext !== true

  const setInstructionsType = (instructionsType: string) => {
    onChange(
      AddVehicleFlow.GettingStarted,
      'instructionsType',
      instructionsType,
    )
  }

  if (noFleetsFound) {
    return <NoFleetFound {...(props as Partial<WizardScreenProps>)} />
  }

  return (
    <GettingStartedInstructions
      t={t}
      instructionsType={
        getUserInputValue(
          state,
          AddVehicleFlow.GettingStarted,
          'instructionsType',
        ) as string
      }
      setInstructionsType={setInstructionsType}
    />
  )
}

export default GettingStarted
