import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import { Surface } from 'recharts'
import styled from 'styled-components'

import { ChartColors } from '../common/constants'
import { parseLegendPayload, renderSymbol } from '../common/helpers'
import {
  type CPLegendProps,
  type LegendPayload,
  type ReChartsLegendItemClickHandler,
} from '@/models/chartModel'

const { fontWeight, spacing } = ThemeConstants

const CPChartsLegendContainer = styled.div`
  display: flex;
  border-radius: ${spacing.absolute.xs}px;
  padding: ${spacing.absolute.s}px;
  justify-content: center;
`

const LegendDataSymbol = styled.span<{
  onClick?: ({ key, state }: { key: string; state: boolean }) => void;
}>`
  cursor: pointer;
  margin-right: ${spacing.absolute.s}px;
`

const LegendItemText = styled.span<{
  color?: string;
  toggled?: boolean;
  opacity?: number;
}>`
  padding-bottom: ${spacing.absolute.xs}px;
  color: ${({ color }) => color ?? ThemeColors.gray_40};
  border-bottom: 2px solid
    ${({ color, toggled }) => (toggled ? color : 'transparent')};
  font-weight: ${({ toggled }) => (toggled ? fontWeight.bold : fontWeight.normal)};
  margin: 0px ${spacing.absolute.xs}px;
  opacity: ${({ opacity }) => opacity ?? 1};
`

const LegendItem: FC<{
  data: LegendPayload;
  formatLabel: (row: LegendPayload) => string;
  onClick?: ({ key, state }: { key: string; state: boolean }) => void;
}> = ({ data, formatLabel, onClick }) => {
  const label = (() => {
    let result
    if (formatLabel) {
      result = formatLabel(data)
    }
    return result ?? data.label ?? data.dataKey
  })()

  return (
    <LegendDataSymbol
      key={`legend-${data.dataKey}`}
      onClick={onClick as ReChartsLegendItemClickHandler}
    >
      <Surface
        width={10}
        height={10}
        viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
      >
        { renderSymbol({
          color           : data.color ?? ChartColors.gray,
          shape           : data.shape ?? data.type,
          stackId         : data.stackId,
          strokeDasharray : data?.strokeDasharray,
        }) }
      </Surface>
      <LegendItemText color={data.color}>{ label }</LegendItemText>
    </LegendDataSymbol>
  )
}

const CPChartLegend: FC<CPLegendProps> = ({
  additionalItems,
  formatLabel,
  onClick,
  payload,
}) => {
  const legendPayload = [
    ...parseLegendPayload(payload),
    ...(additionalItems || []),
  ]

  return (
    <CPChartsLegendContainer>
      { legendPayload.map((item) => (
        <LegendItem
          key={`legend-key-${item.dataKey}`}
          data={item}
          onClick={() => onClick(item)}
          formatLabel={formatLabel}
        />
      )) }
    </CPChartsLegendContainer>
  )
}

export default CPChartLegend
