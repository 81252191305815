import {
  KitBodyStyles,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { createGlobalStyle } from 'styled-components'

import { UILayout } from '@/common/constants'

const { breakpoints, spacing } = ThemeConstants

const FleetGlobalCss = createGlobalStyle`

  html,
  body,
  #react-fleet-root {
    height: 100%;
  }
  .nav-item {
    font-size: ${ThemeConstants.fontSize.base}rem;
  }

  body {
    ${KitBodyStyles};
    a {
      font-size: ${ThemeConstants.fontSize.text_14}rem;
    }
  }

  .cp-form-label {
    // override to fix centering issue with KitRadio
    // I haven't yet figured out why the alignment is off of the KitRadio selected state while it is in Fleet
    &:after {
      left: 5px;
      top: 5px;
    }
  }

  // default maximum dimensions
  .cp-modal-content {
    max-width: calc(100vw - ${UILayout.mobile.modalMarginSize * 2}px);
    max-height:  calc(100vh - ${UILayout.mobile.modalMarginSize * 2}px);
  }

  .fleet-modal-dialog {
    .cp-modal-content {
       // center the Confirm Dialog almost in the middle of 
       // the screen so it only animates down a few pixels instead of from the top
       top: calc(100vh/2 - 90px) !important;
    }
  }

  .cp-modal-body {
    padding: ${spacing.absolute.l}px;
  }

  @media all and (min-width: ${breakpoints.md}px) {
    .filter.active {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 12px !important;
    }
    .react-tiny-popover-container{
      z-index: ${UILayout.filters.panelZIndex} !important;
      top: -3px !important;
      .popover-content {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 12px !important;
      } 
    }
  }


  // Hacking filter popovers to behave like responsive modals on mobile. 
  // Currently many of them are unusable on mobile, so this is an improvement.
  // TODO: identify and resolve any a11y issues with this approach
  @media all and (max-width: ${breakpoints.md}px) {

    .react-tiny-popover-container {
       height: 100%;
       height: -webkit-fill-available;
       z-index: 101;
    }
    .popover-content {
      height: calc(100vh - ${spacing.absolute.s}px);
      height: -webkit-fill-available;
      width: calc(100vw - ${spacing.absolute.s}px);
      margin: ${spacing.absolute.xs}px;
      box-shadow: 0 0 0 rgba(0,0,0, 0.6);
      max-height: inherit;
      position: relative;
      .filterButtons {
        margin: ${spacing.s}rem;
      }
    }  
  }

  @media all and (max-width: ${breakpoints.sm}px) {

     margin: auto;

    .cp-modal-content {
      margin: ${UILayout.mobile.modalMarginSize}px;
      height: ${window.innerHeight - UILayout.mobile.modalMarginSize * 2}px;
      max-height: ${window.innerHeight - UILayout.mobile.modalMarginSize * 2}px;
    }

    .fleet-modal-dialog {
      .cp-modal-content {
          max-height: 180px;
      }
    }

    .cp-modal-body {
      padding: ${spacing.absolute.l}px;
    }
  }

  /* 
  * TODO: Add variant to KitFilterButton to remove the blue link style
  */
  button.filter.link{
    color: ${ThemeColors.gray_70}
  }

 /*
 Custom tooltip styles
 */

 .data-ttip.light {

    color: ${ThemeColors.blue_gray_70};
    background: ${ThemeColors.gray_05};
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 15%);

    .tippy-arrow {
      border: ${ThemeColors.gray_10};
      background: ${ThemeColors.gray_05};
      color:  ${ThemeColors.gray_05};
      z-index: 1;
    }
 }
`

export default FleetGlobalCss
