import { KitDisplayNumbers } from '@chargepoint/cp-toolkit'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import {
  type ChargingSessionStatus,
  EMPTY_VALUE_PLACEHOLDER,
} from '@/common/constants'
import {
  formatNumber,
  milesToKilometers,
  numberFormatOptions,
} from '@/common/lang'
import { getUserPreferences } from '@/common/utils/user'
import { hasValue } from '@/common/utils/validations'
import ChargingSessionGraph from '@/components/Charting/ChartComponents/ChargingSessionGraph'
import type { Depot } from '@/models/depotModel'
import { type ChargingHistory } from '@/models/vehicleModel'

const Container = styled.div`
  display: flex;
`

const DisplayNumbersContainer = styled.div`
  flex: 0.4;
  min-width: 200px;
`

export interface ChargingSessionDetailsProps {
  chargingSession: ChargingHistory;
  chargingStatus: ChargingSessionStatus;
}

const ChargingSessionDetails: FC<ChargingSessionDetailsProps> = ({ chargingSession, chargingStatus, depot }) => {
  const { t }         = useTranslation()
  const distanceUnits = getUserPreferences()?.length_units
  const rangeAdded    = distanceUnits === 'miles'
    ? chargingSession.range_added_miles
    : milesToKilometers(chargingSession.range_added_miles as number)

  const sessionDetails = [
    {
      title : t('charging_history.soc_added'),
      // Using formatNumber instead of formatPercent as KitDisplayNumbers handles units formatting
      value : hasValue(chargingSession?.soc_end)
        ? formatNumber(
          (chargingSession?.soc_end as number)
              - (chargingSession?.soc_start as number),
          { maximumFractionDigits: 1 },
        )
        : EMPTY_VALUE_PLACEHOLDER,
      unit: '%',
    },
    {
      title : t('charging_history.range_added'),
      value : formatNumber(rangeAdded as number, numberFormatOptions.distance),
      unit  : t(`units.distance.${distanceUnits}.short`),
    },
    {
      title : t('energy_added'),
      value : chargingSession.cumulative_energy_kwh
        ? formatNumber(
          chargingSession.cumulative_energy_kwh,
          numberFormatOptions.energy,
        )
        : '--',
      unit: t('units.kilowatt_hour.short'),
    },
  ]

  return (
    <Container>
      <ChargingSessionGraph
        chargingStatus={chargingStatus}
        depotid={chargingSession.depot_external_id}
        sessionid={chargingSession.session_id}
        session={chargingSession}
        isHistorical={hasValue(chargingSession.end_time)}
      />
      <DisplayNumbersContainer>
        <KitDisplayNumbers vertical displayItems={sessionDetails} />
      </DisplayNumbersContainer>
    </Container>
  )
}

export default ChargingSessionDetails
