/* eslint-disable camelcase */
import {
  KitFlexRowCentered,
  KitForm,
  KitInput,
  KitSelect,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, type RefObject, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { renderComponent } from '@/common/utils/componentHelpers'
import { getPropIfMatched, toOptionsArray } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { WizardContent } from '@/components/AddFleetWizard/styled'
import {
  type AddVehicleFlowState,
  type WizardScreenProps,
} from '@/components/AddVehicleWizard/config'
import {
  ResponsiveKitFormGroup,
  responsiveBottomMargin,
} from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import VehicleImage from '@/components/VehicleImage'
import { useLayout } from '@/hooks/useLayout'
import { type Fleet } from '@/models/fleetModel'
import { type VehicleMake, type VehicleModel, type VehicleYear } from '@/models/vehicleModel'
import VehicleService from '@/services/VehicleService'
import { type DynamicObject, type NormalizedChangeEvent } from '@/types/index'

const FormWrapper = styled.div`
  flex: 0.5;
  ${responsiveBottomMargin};
`

interface VehicleScreenProps extends WizardScreenProps {
  resetFields: (sectionName: string, fields: string[]) => void;
  updateSectionData: (
    sectionName: string,
    payload: DynamicObject<unknown>
  ) => void;
  onChange: (dataKey: string, fieldName: string, value: unknown) => void;
  values: { model_make: string; model_name: string; model_year_id: number };
}

interface FormOptionsData {
  fleets: Fleet[];
  makes: VehicleMake[];
  models: VehicleModel[];
  years: VehicleYear[];
}

const componentMap = {
  text   : KitInput,
  select : KitSelect,
}

function getVehicleDescription(
  state: AddVehicleFlowState,
  {
    model_make,
    model_name,
    model_year_id,
    t,
  }: {
    model_make: string;
    model_name: string;
    model_year_id: number;
    t: TFunction;
  },
) {
  const make  = getPropIfMatched<VehicleMake>(
    state.data.vehicle.makes,
    'id',
    parseInt(model_make),
    'name',
  )
  const model = getPropIfMatched(
    state.data.vehicle.models,
    'id',
    parseInt(model_name),
    'name',
  )
  const year  = getPropIfMatched(
    state.data.vehicle.years,
    'id',
    parseInt(model_year_id as unknown as string),
    'year',
  )
  return t('vehicle_image_alt_text', { vehicle_type: `${make} ${model} ${year}` })
}

const VehicleScreen: FC<VehicleScreenProps> = (props) => {
  const { onChange, resetFields, state, t, updateSectionData, values } = props
  const [imageStyle, setImageStyle]                                    = useState<{ height?: string }>({})
  const dataKey                                                        = 'vehicle'
  const { isFleetContext }                                             = state.context
  const { isMobile }                                                   = useLayout()

  const flexDirection = isMobile ? 'column' : 'row'
  const contentRef    = useRef<HTMLDivElement>()
  const isLoading     = !hasValue(state.data.vehicle.fleets)

  // TODO: We shouldn't need parseInt on all the state values as they should br coerced to the correct
  // type before they are added to the state, but there must have been a reason initially... and I don't have time at the moment to clean this up

  const vehicleImageUrl: string = getPropIfMatched(
    state.data.vehicle.years,
    'id',
    parseInt(values?.model_year_id as unknown as string),
    'image_url',
  ) as string

  const vehicleImageAltText: string = getVehicleDescription(state, {
    ...values,
    t,
  })

  const updateLayout = () => {
    const el     = contentRef.current
    const height = (el?.getBoundingClientRect().height as number) * 0.75
    setImageStyle({ height: isMobile ? '160px' : `${height}px` })
  }

  const fetchMakeModelData = (field: string, value: number | string) => {
    const unsetFieldList: string[] = []
    let cacheKey                   = 'models'
    const requests                 = []

    if (field === 'model_make') {
      requests.push(VehicleService.getModels(value as number))
      unsetFieldList.push('model_year_id')
    }

    if (field === 'model_name') {
      cacheKey = 'years'
      requests.push(VehicleService.getYears(value as number))
    }

    resetFields(dataKey, unsetFieldList)

    if (requests.length) {
      Promise.all(requests).then((res) => {
        const result = res.reduce((acc, item) => {
          acc[cacheKey] = item.results
          return acc
        }, {} as { years: VehicleYear[]; [key: string]: unknown })
        if (['model_make'].includes(field)) {
          result.years = []
        }
        updateSectionData(dataKey, result)
      })
    }
  }

  const handleFieldChange = (
    e: NormalizedChangeEvent,
    action?: { name: string },
  ) => {
    let [fieldName, value] = ''
    if (action?.name) {
      fieldName = action.name
      value     = e.value
    } else {
      fieldName = e.target.name
      value     = e.target.value
    }

    if (['model_make', 'model_name'].includes(fieldName)) {
      fetchMakeModelData(fieldName, value)
    }

    onChange(dataKey, fieldName, value)
  }

  const getValue = (fieldName: string) => values?.[fieldName as keyof typeof values] ?? null

  const initialOption = {
    label : t('select'),
    value : null,
  }

  const fields = [
    {
      field     : 'fleet_name',
      component : 'select',
      props     : {
        hideInfoErrorMessage : true,
        label                : t('fields.fleet_name_label'),
        required             : true,
        select               : true,
        value                : {
          selector: (data: FormOptionsData) => ({
            value : getValue('fleet_name'),
            label : toOptionsArray(data?.fleets, {
              labelField : 'name',
              valueField : 'external_id',
              initialOption,
            }).filter((fleet) => fleet.value === getValue('fleet_name'))[0]
              ?.label,
          }),
        },
        disabled : isFleetContext,
        onChange : handleFieldChange,
        options  : {
          selector: (data: FormOptionsData) => toOptionsArray(data?.fleets, {
            labelField : 'name',
            valueField : 'external_id',
            initialOption,
          }),
        },
      },
    },
    {
      field     : 'model_make',
      component : 'select',
      props     : {
        hideInfoErrorMessage : true,
        label                : t('make'),
        onChange             : handleFieldChange,
        required             : true,
        value                : {
          selector: (data: FormOptionsData) => ({
            value : getValue('model_make'),
            label : toOptionsArray(data?.makes, {
              labelField : 'name',
              valueField : 'id',
              initialOption,
            }).filter((make) => make.value === getValue('model_make'))[0]
              ?.label,
          }),
        },
        options: {
          selector: (data: FormOptionsData) => toOptionsArray(data?.makes, {
            labelField : 'name',
            valueField : 'id',
            initialOption,
          }),
        },
        select: true,
      },
    },
    {
      field     : 'model_name',
      component : 'select',
      props     : {
        hideInfoErrorMessage : true,
        field                : 'model',
        label                : t('model'),
        required             : true,
        disabled             : !hasValue(values?.model_make),
        value                : {
          selector: (data: FormOptionsData) => ({
            value : getValue('model_name'),
            label : toOptionsArray(data?.models, {
              labelField : ['name', 'trim'],
              valueField : 'id',
              initialOption,
            }).filter((model) => model.value === getValue('model_name'))[0]
              ?.label,
          }),
        },
        onChange : handleFieldChange,
        options  : {
          selector: (data: FormOptionsData) => toOptionsArray(data?.models, {
            labelField : ['name', 'trim'],
            valueField : 'id',
            initialOption,
          }),
        },
        select: true,
      },
    },
    {
      field     : 'model_year_id',
      component : 'select',
      props     : {
        name  : 'model_year_id',
        value : {
          selector: (data: FormOptionsData) => ({
            value : getValue('model_year_id'),
            label : toOptionsArray(data?.years, {
              labelField : 'year',
              valueField : 'id',
              initialOption,
            }).filter((year) => year.value === getValue('model_year_id'))[0]
              ?.label,
          }),
        },
        label                : t('year'),
        disabled             : !hasValue(values?.model_name),
        onChange             : handleFieldChange,
        hideInfoErrorMessage : true,
        options              : {
          selector: (data: FormOptionsData) => toOptionsArray(data?.years, {
            labelField : 'year',
            valueField : 'id',
            initialOption,
          }),
        },
        select: true,
      },
    },
  ].map((f) => ({
    ...f,
    props: {
      ...f.props,
      name: f.field,
    },
  }))

  useEffect(() => {
    updateLayout()
    window.addEventListener('resize', updateLayout)
    return () => {
      window.removeEventListener('resize', updateLayout)
    }
  }, [isMobile])

  return (
    <WizardContent
      ref={contentRef as RefObject<HTMLDivElement>}
      flexDirection={flexDirection}
    >
      { isLoading && <StyledSpinner align="center" /> }
      { !isLoading && (
        <>
          <FormWrapper>
            <KitForm>
              { fields.map((field) => (
                <ResponsiveKitFormGroup key={`${field.props.label}`}>
                  <KitForm.Label
                    htmlFor={field.field}
                    text={field.props.label}
                    required={field.props.required}
                  />
                  { renderComponent(field, componentMap, {
                    state      : { values, ...state.data.vehicle },
                    otherProps : {},
                  }) }
                </ResponsiveKitFormGroup>
              )) }
            </KitForm>
          </FormWrapper>
          <KitFlexRowCentered style={{ alignItems: 'center', flex: 1 }}>
            <VehicleImage
              height={imageStyle.height}
              vehicleType={'deliveryTruck'}
              altText={vehicleImageAltText}
              url={vehicleImageUrl}
              t={t}
            />
          </KitFlexRowCentered>
        </>
      ) }
    </WizardContent>
  )
}

export default VehicleScreen
