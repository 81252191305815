import { KitLink, ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import styled from 'styled-components'

import cpLogoImage from '../images/cplogo.png'

const Container = styled.div`
  background: ${ThemeColors.gray_10};
  border-bottom: 2px solid ${ThemeColors.blue_30};
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
`

const LogoContainer = styled.div`
  display: inline-block;
  background: ${ThemeColors.white};
  padding: ${ThemeConstants.spacing.absolute.xs}px
    ${ThemeConstants.spacing.absolute.s}px;
`

const CPLogo = styled.img`
  width: 172px;
  margin-right: ${ThemeConstants.spacing.absolute.s}px;
  margin-top: 2px;
`

// Used on error pages or when we cannot show KitNavBar
// (when we do not have a user profile or any other customer info)
const AlternativePageHeader = ({ t }: { t: TFunction }) => (
  <Container>
    <LogoContainer>
      <KitLink aria-label={t('fleet_home')} href="/">
        <CPLogo
          src={cpLogoImage}
          aria-label={t('chargepoint_logo_altext')}
        />
      </KitLink>
    </LogoContainer>
  </Container>
)

export default AlternativePageHeader
