import {
  CPChartZoomOutButton,
  ChartConstants,
  type ChartEvent,
  ChartUtils,
} from '@chargepoint/cp-charts'
import { ThemeColors } from '@chargepoint/cp-toolkit'
import classnames from 'classnames'

import { extent } from 'd3-array'
import { scaleOrdinal } from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic'
import { format, isValid } from 'date-fns'
import { type FC, type ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { type CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'
import {
  type NameType,
  type ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { type TooltipProps } from 'recharts/types/component/Tooltip'
import { type AxisDomain } from 'recharts/types/util/types'
import styled from 'styled-components'

import {
  buildCache,
  formatXAxisLabel,
  getChartSeries,
  getSetPointKey,
  processChartData,
} from './config'
import { ISO_DATE_TIME, type Period } from '@/common/constants'
import { formatNumber, numberFormatOptions } from '@/common/lang'
import { diff } from '@/common/utils/data'
import Poll, { PollEvents } from '@/common/utils/Poll'
import Storage from '@/common/utils/Storage'
import { hasValue } from '@/common/utils/validations'
import CPChartTooltip, { type CPChartTooltipProps } from '@/components/Charting/ChartComponents/CPChartTooltip'
import CustomXAxisTick from '@/components/Charting/ChartComponents/CustomXAxisTick'
import EmptyChartMessage from '@/components/Charting/ChartComponents/EmptyChartMessage'
import { ChartWrapper } from '@/components/Charting/ChartComponents/styledChartElements'
import {
  DEFAULT_ZOOM,
  chartMargin,
  yAxisFormatOptions,
  yAxisWidth,
} from '@/components/Charting/common/constants'
import {
  getToolTipItems,
  renderSeries,
} from '@/components/Charting/common/helpers'
import { type ChartElementProps } from '@/components/Charting/common/types'
import { getSeriesProps } from '@/components/Charting/common/utils'
import { ChartContainer } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import {
  type EnergyChartQuery,
  type LegendPayload,
  type TimeSeriesData,
  type TimeSeriesResponse,
} from '@/models/chartModel'
import { type PowerManagementType } from '@/models/energyModel'
import { type ServicePayload } from '@/models/serviceModel'
import EnergyManagementService from '@/services/EnergyManagementService'

const EnergyChartWrapper = styled(ChartWrapper)`
  z-index: 1;
  .zoom-button {
    display: flex;
    right: 44px;
    top: -48px;
  }

  &.expanded {
    height: 75vh;
  }

  .energy-tooltip {
    z-index: 99;
  }
`

const Container = styled.div`
  min-height: 275px;
  min-width: 300px;
  width: 100%;
  height: auto;
`

export interface EnergyDetailsGraphProps {
  siteID: string;
  timezone?: string;
  groupByField: string;
  chartFilters?: EnergyChartQuery;
  duration: Period;
  powerManagementType: PowerManagementType;
  expanded: boolean;
  // seconds to delay the initial fetch
  delay: number;
}

const EnergyDetailsGraph: FC<EnergyDetailsGraphProps> = ({
  chartFilters,
  delay = 0,
  duration,
  expanded,
  groupByField,
  powerManagementType,
}) => {
  const axisFontSize = 11
  const cacheKey     = 'site_energy_chart'
  const xDataKey     = 'timestamp'

  const { t }                           = useTranslation()
  const [xDomain, setXDomain]           = useState<AxisDomain>()
  const [yDomain, setYDomain]           = useState<AxisDomain>([
    DEFAULT_ZOOM.y2,
    DEFAULT_ZOOM.y1,
  ])
  const [chartSeries, setChartSeries]   = useState<ChartElementProps[]>([])
  const [isLoading, setIsLoading]       = useState(true)
  const [serverError, setServerError]   = useState<unknown | undefined>()
  const toolTipItems                    = isLoading ? [] : getToolTipItems(chartSeries)
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([])
  const [chartData, setChartData]       = useState<TimeSeriesData[]>([])
  const [dataSetKeys, setDataSetKeys]   = useState<string[]>([])

  const [isZooming, setIsZooming]   = useState(false)
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const [highLight, setHighLight]   = useState(
    ChartConstants.DEFAULT_HIGHLIGHT_ZOOM,
  )
  const [toolTipY, setToolTipY]     = useState('0px')

  const groupByRef = useRef<string>(groupByField)
  const filtersRef = useRef<EnergyChartQuery>(chartFilters as EnergyChartQuery)
  const dataRef    = useRef<TimeSeriesData[]>()

  function handleMouseDown(e: ChartEvent): void {
    if (e?.activePayload?.length) {
      setHighLight(ChartUtils.ChartZoom.init(e, highLight, xDataKey))
      setIsZooming(true)
    }
  }

  function handleMouseMove(e: ChartEvent): void {
    const x2 = ChartUtils.getEventPayloadValue(e, xDataKey)
    if (isZooming) {
      setHighLight((prev) => ({ ...prev, x2 }))
    }
  }

  function handleMouseUp(): void {
    if (isZooming) {
      const { zoomBounds, zoomed } = ChartUtils.ChartZoom.getBounds(
        highLight,
        chartData as unknown as TimeSeriesData[],
        xDataKey,
        dataSetKeys,
        ChartConstants.MIN_ZOOM,
        {
          isStacked            : true,
          excludeFromStackKeys : [
            'setpoint',
            'setpoint_simulation',
            'target_power',
            'power_limit',
          ],
        },
      )

      if (zoomed) {
        setIsZoomedIn(true)
        setXDomain([zoomBounds?.x1 as number, zoomBounds?.x2 as number])
        setHighLight(ChartConstants.DEFAULT_HIGHLIGHT_ZOOM)
        // adjust bounding box so there is a little padding above the top-most zoomed value on the y-axis
        if (zoomBounds) {
          zoomBounds.y1 = (zoomBounds.y1 as number) + 1
        }

        setYDomain([0, zoomBounds?.y1 as number])
        setChartData(chartData.slice())
      }

      setIsZooming(false)
    }
  }
  function zoomOut(): void {
    setIsZoomedIn(false)
    setXDomain([DEFAULT_ZOOM.x1, DEFAULT_ZOOM.x2])
    setYDomain([DEFAULT_ZOOM.y2, DEFAULT_ZOOM.y1])
    setChartData(() => chartData.slice())
  }

  const tooltipItemFormatter = (key: string, value: number): string => t('charting.tooltip_value', {
    value : formatNumber(value, numberFormatOptions.power),
    units : t('units.kilowatt.short'),
  })

  function formatTimeStampValue(row: Record<string, number>): string {
    if (isValid(row.timestamp)) {
      return t('charting.tooltip_timestamp', { timestamp: format(row.timestamp, ISO_DATE_TIME) })
    }
    return ''
  }

  function getCustomToolTip(
    props: TooltipProps<ValueType, NameType>,
  ): ReactNode {
    return (
      <CPChartTooltip
        formatTimeStamp={formatTimeStampValue}
        formatter={tooltipItemFormatter}
        items={toolTipItems}
        {...(props as Omit<
        CPChartTooltipProps,
        'formatTimeStamp' | 'formatter' | 'items'
        >)}
      />
    ) as unknown as ReactNode
  }

  function toggleSeriesVisibility(dataKey: string) {
    let hiddenSeriesChanges: string[] = hiddenSeries.concat()
    if (hiddenSeries.includes(dataKey)) {
      hiddenSeriesChanges = hiddenSeries.filter((k) => k !== dataKey)
    } else {
      hiddenSeriesChanges.push(dataKey)
    }
    setHiddenSeries(hiddenSeriesChanges)
  }

  function handleLegendItemClick(props: LegendPayload) {
    toggleSeriesVisibility(props.dataKey)
  }

  function processChartSeriesData(data: TimeSeriesData[]): boolean {
    let changedData = data.concat()
    if (hiddenSeries.length) {
      changedData = changedData.map((o) => {
        const newRecord = { ...o }
        hiddenSeries.forEach((k: keyof typeof newRecord) => {
          if (hasValue(newRecord[k])) {
            newRecord[` ${k}`] = newRecord[k]
            delete newRecord[k]
          }
        })
        // visible series
        const vis = diff(dataSetKeys, hiddenSeries) as string[]
        type recordKey = keyof typeof newRecord;
        vis.forEach((k) => {
          if (hasValue(newRecord[` ${k}` as recordKey])) {
            newRecord[k] = newRecord[` ${k}` as recordKey]
            delete newRecord[` ${k}` as recordKey]
          }
        })
        return newRecord
      })
    } else if (dataRef.current) {
      changedData = dataRef.current.concat()
    }

    return changedData
  }

  const fetchSitePowerData = useCallback(
    async (fromPoll: boolean) => {
      if (!powerManagementType) {
        return
      }

      if (!fromPoll) {
        setIsLoading(true)
      }

      const response = await EnergyManagementService.getSitePowerData({
        query:
          filtersRef.current as unknown as ServicePayload<EnergyChartQuery>,
        groupBy: groupByRef.current,
      })

      const { additionalSeries, error, labelMap, results } = processChartData(
        response,
        groupByRef.current,
        Storage.get(cacheKey) as Record<string, TimeSeriesData[]>,
        powerManagementType,
        t,
      )

      const colorScale                   = scaleOrdinal(schemeCategory10)
      const ordinalRange                 = ChartUtils.getAllDataSetKeys(results, [
        'timestamp',
        'setpoint',
        'setpoint_simulation',
        'power_limit',
        'target_power',
      ])
      const colorDomain                  = colorScale.domain(ordinalRange)
      const cSeries: ChartElementProps[] = getChartSeries(
        ordinalRange,
        colorDomain,
        labelMap,
        additionalSeries,
        t,
      )

      dataRef.current = results

      unstable_batchedUpdates(() => {
        const dsKeys = ChartUtils.getAllDataSetKeys(results, ['timestamp'])
        setIsLoading(false)
        setServerError(error)

        setDataSetKeys(dsKeys)
        setChartSeries(cSeries)
        setXDomain(extent(results, (d) => d.timestamp) as AxisDomain)
        setYDomain([DEFAULT_ZOOM.y2, DEFAULT_ZOOM.y1])
        setToolTipY(dsKeys.length > 12 ? '-50%' : '0')

        setChartData(results)

        Storage.set(
          cacheKey,
          buildCache(
            groupByRef.current,
            response as unknown as TimeSeriesResponse[],
            Storage.get(cacheKey) as Record<string, TimeSeriesData[]>,
            getSetPointKey(powerManagementType),
          ),
        )
      })
    },
    [chartFilters, isZoomedIn, groupByField, powerManagementType],
  )

  // At present, we will poll the api every 1 min to refresh the chart data
  // in the future we may update the chart via websockets
  useEffect(() => {
    // refresh every 2 minute
    const timer = new Poll({ pollInterval: 1000 * 45 })
    timer.on(PollEvents.POLL, () => {
      if (!isZoomedIn) {
        fetchSitePowerData(true)
      }
    })
    setTimeout(() => {
      timer.start()
    }, delay * 1000)

    return () => {
      timer.stop()
    }
  }, [isZoomedIn])

  useEffect(() => {
    if (chartData?.length) {
      const changedData   = processChartSeriesData(chartData)
      const changedSeries = chartSeries.map((o) => ({
        ...o,
        ...getSeriesProps(o, hiddenSeries),
      }))

      setChartData(changedData)
      setChartSeries(changedSeries)
    }
  }, [dataSetKeys, groupByField, hiddenSeries])

  useEffect(() => {
    if (chartFilters) {
      filtersRef.current = chartFilters as EnergyChartQuery
    }
    setTimeout(fetchSitePowerData, 250)
  }, [chartFilters, groupByField])

  useEffect(() => {
    groupByRef.current = groupByField
    setIsZoomedIn(false)
  }, [groupByField])

  if (serverError) {
    return (
      <ChartContainer>
        <EmptyChartMessage isError={true} label={t('errors.500.title')} />
      </ChartContainer>
    )
  }

  if (!isLoading && dataSetKeys?.length === 0) {
    return (
      <ChartContainer>
        <EmptyChartMessage label={t('charting.no_data_message')} />
      </ChartContainer>
    )
  }

  return (
    <ChartContainer>
      { isLoading && <StyledSpinner align="center" /> }
      { !isLoading && (
        <Container>
          <EnergyChartWrapper
            className={classnames('energy-chart-wrapper', { expanded })}
          >
            { isZoomedIn && (
              <CPChartZoomOutButton
                className="zoom-button"
                onClick={zoomOut}
                ariaLabel={t('charting.zoom_out')}
              />
            ) }
            <ResponsiveContainer width="100%">
              <ComposedChart
                data={chartData}
                margin={{ ...chartMargin, right: 10 }}
                onMouseDown={handleMouseDown as CategoricalChartFunc}
                onMouseMove={handleMouseMove as CategoricalChartFunc}
                onMouseUp={handleMouseUp}
              >
                <XAxis
                  dataKey="timestamp"
                  allowDataOverflow
                  domain={xDomain}
                  name={t('time')}
                  type="number"
                  scale={'time'}
                  tickCount={8}
                  style={{ fontSize: `${8}px` }}
                  tick={
                    <CustomXAxisTick
                      domain={xDomain as [number, number]}
                      duration={duration}
                      formatter={formatXAxisLabel}
                    />
                  }
                  tickMargin={8}
                  padding="gap"
                />
                <YAxis
                  domain={yDomain}
                  allowDataOverflow
                  type="number"
                  style={{ fontSize: `${axisFontSize}px` }}
                  unit={` ${t('units.kilowatt.short')}`}
                  tickFormatter={(val) => formatNumber(val, yAxisFormatOptions) as string
                  }
                  width={yAxisWidth}
                />
                <Tooltip
                  offset={10}
                  allowEscapeViewBox={{ y: true }}
                  wrapperStyle={{ top: toolTipY }}
                  labelFormatter={(val) => format(val, ISO_DATE_TIME)}
                  content={getCustomToolTip}
                  wrapperClassName="energy-tooltip"
                />
                <Legend
                  wrapperStyle={{
                    marginBottom : '-16px',
                    overflowY    : 'auto',
                    minHeight    : '50px',
                    maxHeight    : '60px',
                  }}
                  // Recharts has some messy event handler types -- disabling for now
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onClick={handleLegendItemClick}
                />

                { chartSeries.map(renderSeries) }

                <CartesianGrid strokeDasharray="3 3" />

                { isZooming ? (
                  <ReferenceArea
                    x1={highLight.x1}
                    x2={highLight.x2}
                    strokeOpacity={0.6}
                    stroke={ThemeColors.gray_40}
                    fill={ThemeColors.gray_30}
                  />
                ) : null }
              </ComposedChart>
            </ResponsiveContainer>
          </EnergyChartWrapper>
        </Container>
      ) }
    </ChartContainer>
  )
}

export default EnergyDetailsGraph
