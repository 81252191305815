import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { UILayout } from '@/common/constants'
import appStore from '@/store/AppStore'

const { fontSize, spacing } = ThemeConstants

const Container = styled.div<{ className?: string }>`
  background-color: ${ThemeColors.gray_20};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.m}rem;
  min-height: ${UILayout.footerHeight}px;
  & div {
    font-size: ${fontSize.text_12}rem;
  }
`

export interface FooterProps {
  className?: string;
  t: TFunction;
}

function Footer({ className, t }: FooterProps) {
  const copyRightYear = new Date().getFullYear()
  return (
    <Container className={className}>
      { appStore.initialized && <div>{ t('copyright', { copyRightYear }) }</div> }
    </Container>
  )
}

export default observer(Footer)
