/* eslint-disable camelcase */
import { type TFunction } from 'i18next'

import {
  AddVehicleFlow,
  type AddVehicleFlowState,
  getSummaryContent,
  getSummaryLabel,
  getUserInputValue,
} from '../config'
import { getPropIfMatched } from '@/common/utils/data'
import {
  Section,
  SubHeadingBold,
  SummaryContainer,
  SummaryField,
} from '@/components/Styled'
import VehicleImage from '@/components/VehicleImage'
import { type Field } from '@/types/index'

const renderSectionContent = (
  key: string,
  section: Record<string, unknown>,
  state: AddVehicleFlowState,
  t: TFunction,
) => {
  const customRenderSections: Record<string, unknown> = {
    vehicle: (fieldMap: Record<string, string>) => {
      const remappedKeys: Record<string, unknown> = {
        vehicle_name: getUserInputValue(
          state,
          AddVehicleFlow.Identification,
          'name',
        ),
        fleet_name: getPropIfMatched(
          state.data.vehicle.fleets,
          'id',
          parseInt(fieldMap.fleet_name),
          'name',
        ) as string,
        model_make: getPropIfMatched(
          state.data.vehicle.makes,
          'id',
          parseInt(fieldMap.model_make),
          'name',
        ),
        model_name: getPropIfMatched(
          state.data.vehicle.models,
          'id',
          parseInt(fieldMap.model_name),
          'name',
        ),
        model_year_id: getPropIfMatched(
          state.data.vehicle.years,
          'id',
          parseInt(fieldMap.model_year_id),
          'year',
        ),
        model_year: getPropIfMatched(
          state.data.vehicle.years,
          'id',
          parseInt(fieldMap.model_year_id),
          'year',
        ),
      }
      const vehicleImageUrl: string               = getPropIfMatched(
        state.data.vehicle.years,
        'id',
        parseInt(fieldMap.model_year_id),
        'image_url',
      ) as string

      const vehicleAltText = t('vehicle_image_alt_text', { vehicle_type: `${remappedKeys.model_year_id} ${remappedKeys.model_make} ${remappedKeys.model_name}` })
      return (
        <>
          { section.fields.map((field: Field) => {
            const remappedField = remappedKeys[field.key]
              ? {
                ...field,
                value: remappedKeys[field.key],
              }
              : { ...field }
            return (
              <SummaryField
                key={field.value}
                {...remappedField}
                label={getSummaryLabel(field.label, section, t)}
                section={section}
                t={t}
              />
            )
          }) }

          <VehicleImage
            altText={vehicleAltText}
            url={vehicleImageUrl}
            t={t}
            height={'160px'}
            width={'fit-content'}
          />
        </>
      )
    },
    identification: (fieldMap: Record<string, Field>) => {
      const remappedKeys: Record<string, unknown> = {
        telematics_account_id: getPropIfMatched(
          state.data.identification.telematics_accounts,
          'id',
          parseInt(fieldMap.telematics_account_id, 10),
          'name',
        ),
      }
      return (
        <>
          { section.fields
            .filter((f: Field) => !['using_telematics'].includes(f.key))
            .map((field: Field) => {
              const remappedField = remappedKeys[field.key]
                ? {
                  ...field,
                  value: remappedKeys[field.key],
                }
                : { ...field }
              return (
                <SummaryField
                  key={field.value}
                  {...remappedField}
                  label={getSummaryLabel(field.label, section, t)}
                  section={section}
                  t={t}
                />
              )
            }) }
        </>
      )
    },
  }

  if (customRenderSections[section.key]) {
    return customRenderSections[section.key](section.fieldMap)
  }

  return section.fields.map((field) => (
    <SummaryField
      key={field.value}
      {...field}
      label={getSummaryLabel(field.label, section, t)}
      section={section}
      t={t}
    />
  ))
}

const Summary = ({
  state,
  t,
}: {
  state: AddVehicleFlowState;
  t: TFunction;
}) => {
  const summary = getSummaryContent(state, false, t)
  return (
    <SummaryContainer>
      { summary?.map((section) => (
        <Section key={section.title}>
          <SubHeadingBold>{ section.title }</SubHeadingBold>
          { renderSectionContent(section.key, section, state, t) }
        </Section>
      )) }
    </SummaryContainer>
  )
}

export default Summary
