/* eslint-disable no-underscore-dangle */
import { KitUtilData } from '@chargepoint/cp-toolkit'
import { action, computed, flow, makeObservable, observable } from 'mobx'

import { type DDVMapData } from './interfaces'

import {
  buildDDVFilterDefaults,
  isChargingAtCurrentDepot,
  mapAlertObject,
} from './routes/Overview/utils'
import alertUtils, { numberOfHighSeverity } from '@/common/utils/alerts'
import { type Alert } from '@/models/alertModel'
import { type Depot, type DepotDetailsRow, type DepotFilterParams } from '@/models/depotModel'
import { type EnergySettings, type Site } from '@/models/energyModel'
import AlertService from '@/services/AlertService'
import DepotService from '@/services/DepotService'
import EnergyManagementService from '@/services/EnergyManagementService'
import appStore from '@/store/AppStore'

export interface DDVTotals {
  aggregatePower?: number;
  hardDemandLimit?: number;
  targetDemand?: number;
  info?: {
    powerManagementType?: string;
  };
}

// mob-x store that backs DDV
class DepotStore {
  depotId?: string
  depot?: Depot
  alerts?: Alert[]
  _ddvRows?: DepotDetailsRow[]
  ddvFilterParams?: DepotFilterParams
  ddvMapData?: DDVMapData
  isLoading = true
  initialized = false
  serviceErrors: { key: string; service: string; status?: number }[] = []
  siteSettings?: EnergySettings & { siteName: string }

  constructor() {
    makeObservable(this, {
      initialized     : observable,
      isLoading       : observable,
      alerts          : observable,
      depot           : observable,
      depotId         : observable,
      ddvMapData      : observable,
      ddvFilterParams : observable,
      _ddvRows        : observable,
      siteSettings    : observable,
      serviceErrors   : observable,

      setDDVRows             : action.bound,
      setAlerts              : action.bound,
      updateDepot            : action.bound,
      fetchAlerts            : action.bound,
      fetchDDVRows           : action.bound,
      depotName              : computed,
      depotAlerts            : computed,
      ddvTotals              : computed,
      ddvRows                : computed,
      ddvFilterDefaults      : computed,
      highSeverityAlertCount : computed,
    })
  }

  reset() {
    this.siteSettings = undefined
    this.depot        = undefined
    this.alerts       = undefined
    this._ddvRows     = undefined
    this.initialized  = false
  }

  // call rest apis for depot and alert data
  init = flow(function* init(this: DepotStore, depotId: string) {
    this.reset()

    this.isLoading = true
    this.depotId   = depotId

    if (depotId) {
      const depot = yield DepotService.getDepotInfo(this.depotId)
      this.depot  = depot

      yield this.fetchDDVRows()

      this.fetchDDVSupplementaryData()

      this.initialized = true
    }
  })

  fetchDDVRows = flow(function* fetchDDVRows(this: DepotStore) {
    if (!this.depotId) {
      return
    }

    this.isLoading = true

    const ddvRowResponse = yield DepotService.getDepotDetailsTable(
      this.depotId,
    )
    const hasErrors      = ddvRowResponse.error
    const serviceErrors  = []

    if (hasErrors) {
      if (!this.initialized) {
        appStore.setErrors({ status: ddvRowResponse.error.status })
        serviceErrors.push({
          key     : 'errors.list_request_error',
          service : 'depots',
          status  : ddvRowResponse.error.status,
        })
      }
    } else if (Array.isArray(ddvRowResponse)) {
      this._ddvRows = (ddvRowResponse as DepotDetailsRow[]).map((ddvRow) => ({
        ...ddvRow,
        alerts: ddvRow.alerts?.map(
          (alert) => mapAlertObject(
            alert,
            ddvRow,
            this.depot?.timezone as string,
          ) as unknown as Alert,
        ),
      }))
    }

    if (!hasErrors) {
      appStore.clearErrors()
      this.serviceErrors = []
    } else {
      this.serviceErrors = serviceErrors
    }

    this.isLoading = false

    this.fetchAlerts()
  })

  fetchAlerts = flow(function* fetchAlerts(this: DepotStore) {
    if (this.depotId) {
      const alertsResponse = yield AlertService.getActiveAlertsForDepot(
        this.depotId,
      )
      if (alertsResponse.error) {
        this.serviceErrors.push({
          key     : 'errors.list_request_error',
          service : 'alerts',
        })
      } else {
        this.alerts = alertsResponse.results
      }
    }
  })

  // fetch map view and filters data
  fetchDDVSupplementaryData = flow(function* fetchDDVSupplementaryData(
    this: DepotStore,
  ) {
    try {
      this.ddvFilterParams =        yield DepotService.getDepotDetailsFilterParameters()

      const mapData = yield DepotService.getDepotMapCoordinates(
        this.depotId as string,
      )
      if (mapData) {
        this.ddvMapData = mapData.coordinates
      }

      // trigger requests to populate DDVTotals widget
      this.fetchSiteSettings()
    } catch (err) {
      // nothing really we can do here is there?
      console.error('failed to load supplementary DDV data', err)
    }
  })

  // fetchSiteSettings looks for a site that is linked to the current depot.
  // if a match is found, the settings for that site are fetched and used by the ddvTotals getter
  fetchSiteSettings = flow(function* loadSite(this: DepotStore) {
    if (this.depot) {
      try {
        const { results: sites } = yield EnergyManagementService.getSites()
        if (Array.isArray(sites)) {
          const match = (sites as Site[]).find(
            (s) => s.depot_id === this.depot?.external_id,
          ) as Site
          if (match) {
            const { results: groups } =              yield EnergyManagementService.getStationGroups(match.external_id)
            // only proceed if the site does NOT have station groups
            if (KitUtilData.isEmpty(groups)) {
              const settings = yield EnergyManagementService.getSiteSettings(
                match.external_id,
              )
              if (settings && !settings.error) this.siteSettings = { ...settings, siteName: match.name }
            }
          }
        }
      } catch (err) {
        console.warn('unable to fetch site settings', err)
      }
    }
  })

  get ddvFilterDefaults(): DepotFilterParams | undefined {
    if (this._ddvRows && this.ddvFilterParams) {
      const result = buildDDVFilterDefaults(
        this._ddvRows,
        this.ddvFilterParams,
      )
      return result
    }
    return undefined
  }

  get ddvTotals(): DDVTotals | undefined {
    if (!this.ddvRows) {
      return undefined
    }
    const result: DDVTotals = {
      aggregatePower: this.ddvRows?.reduce((acc, ddvRow) => {
        // only add charging power to depot totals when a vehicle is charging at the current depot
        if (isChargingAtCurrentDepot(ddvRow, this.depot as Depot) && ddvRow.power) {
          // eslint-disable-next-line no-param-reassign
          acc += ddvRow.power
        }
        return acc
      }, 0),
    }

    if (this.siteSettings) {
      result.hardDemandLimit = this.siteSettings.hard_demand_limit_kw
      result.targetDemand    = this.siteSettings.demand_target_kw

      result.info = { powerManagementType: this.siteSettings.power_management_type }
    }
    return result
  }

  get highSeverityAlertCount() {
    const depotAlertCount = numberOfHighSeverity(this.alerts) ?? 0
    if (this._ddvRows) {
      return this._ddvRows.reduce((acc, row) => {
        if (Array.isArray(row.alerts)) {
          const num = acc + row.alerts.length
          return num
        }
        return acc
      }, depotAlertCount)
    }

    return depotAlertCount
  }

  get depotName() {
    return this.depot?.name
  }

  get ddvRows() {
    return this._ddvRows
  }

  get depotAlerts() {
    if (this.alerts) {
      return alertUtils.getDepotLevelAlerts(this.alerts)
    }
    return []
  }

  setDepotId(id: string) {
    this.depotId     = id
    this.initialized = false
  }

  setDDVRows(ddvRows: DepotDetailsRow[]) {
    this._ddvRows = ddvRows
  }
  // these will primarily be called during socket updates
  setAlerts(alerts: Alert[]) {
    this.alerts = alerts
  }

  updateDepot(depotUpdates: Depot) {
    if (this.depot && depotUpdates) {
      this.depot = { ...this.depot, ...depotUpdates }
    }
  }
}

const depotStore = new DepotStore()
export default depotStore
