import { ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

const { fontSize, spacing } = ThemeConstants

export const Block = styled.div`
  margin-bottom: ${spacing.absolute.m}px;
`

export const ErrorMessageSpan = styled.p`
  color: red;
  position: relative;
  top: -${spacing.absolute.sm}px;
`

export const FieldContainer = styled.div`
  margin-bottom: ${spacing.absolute.m}px;
  line-height: ${fontSize.base}rem;
  > div {
    margin-bottom: ${spacing.absolute.xxs}px;
  }
`
