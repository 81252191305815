import { KitUtilCommon } from '@chargepoint/cp-toolkit'
import { useEffect } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { rootLoader } from './rootLoader'
import { AppRoutes } from '@/common/constants'
import {
  FLEET_DEPOT_DETAILS_VIEW,
  FLEET_DEPOT_SETTINGS_VIEW,
  FLEET_ENERGY_MGMT_VIEW,
  type FleetRouteObject,
  getEnabledRoutes,
} from '@/common/permissions'
import { isSettingsPageEnabled } from '@/common/utils'
import RouteErrorHandler from '@/components/RouteErrorHandler'
import SharedRootLayout from '@/components/SharedRootLayout'
import StyledSpinner from '@/components/StyledSpinner'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import Account from '@/pages/Account'
import DepotList, { depotListLoader } from '@/pages/DepotList'
import Energy from '@/pages/Energy'
import SitePropSheet from '@/pages/Energy/routes/SitePropSheet'
import SiteList, { siteListLoader } from '@/pages/Energy/routes/SitesList'
import FleetList from '@/pages/FleetList'
import FleetCards from '@/pages/FleetList/routes/FleetCards'
import VehicleCards from '@/pages/FleetList/routes/VehicleCards'
import FleetPropSheet from '@/pages/FleetPropSheet'
import FleetSettings from '@/pages/FleetPropSheet/routes/FleetSettings'
import FleetVehicleList from '@/pages/FleetPropSheet/routes/FleetVehicleList'
import Settings from '@/pages/Settings'
import ChargingHistory from '@/pages/VehiclePropSheet/routes/ChargingHistory'
import TripHistory from '@/pages/VehiclePropSheet/routes/TripHistory'
import VehicleSettings from '@/pages/VehiclePropSheet/routes/VehicleSettings'
import VehicleStatus from '@/pages/VehiclePropSheet/routes/VehicleStatus'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import appStore from '@/store/AppStore'

export const getRoutes = () => {
  const routes: FleetRouteObject[] = [
    {
      path         : AppRoutes.Root.path,
      element      : <SharedRootLayout />,
      loader       : rootLoader,
      errorElement : (
        <RouteErrorHandler
          showAlternativeHeader={true}
          errors={appStore.errors}
        />
      ),
      children: [
        {
          index        : true,
          element      : <DepotList />,
          loader       : depotListLoader,
          errorElement : <RouteErrorHandler />,
        },
        {
          path        : AppRoutes.DepotPropSheet.path,
          lazy        : () => import('@/pages/DepotPropSheet'),
          permissions : [FLEET_DEPOT_DETAILS_VIEW],
          children    : [
            {
              index : true,
              lazy  : () => import('@/pages/DepotPropSheet/routes/Overview'),
            },
            {
              path : 'map',
              lazy : () => import('@/pages/DepotPropSheet/routes/Overview'),
            },
            {
              path        : 'settings',
              lazy        : () => import('@/pages/DepotPropSheet/routes/Settings'),
              permissions : [FLEET_DEPOT_SETTINGS_VIEW],
            },
            {
              path : 'alert-history',
              lazy : () => import('@/pages/DepotPropSheet/routes/AlertHistory'),
            },
            {
              path : 'dispatch',
              lazy : () => import('@/pages/DepotPropSheet/routes/Dispatch'),
            },
            {
              path    : '*',
              element : <RouteErrorHandler errors={[{ code: 404 }]} />,
            },
          ],
        },
        {
          path     : `${AppRoutes.Fleets.path}`,
          element  : <FleetList />,
          children : [
            { index: true, element: <FleetCards /> },
            { path: 'vehicles', element: <VehicleCards /> },
          ],
        },
        {
          path         : AppRoutes.FleetPropSheet.path,
          element      : <FleetPropSheet />,
          errorElement : <RouteErrorHandler errors={appStore.errors} />,
          children     : [
            { index: true, element: <FleetVehicleList /> },
            { path: 'settings', element: <FleetSettings /> },
          ],
        },
        {
          path         : `${AppRoutes.VehiclePropSheet.path}`,
          lazy         : () => import('@/pages/VehiclePropSheet'),
          errorElement : <RouteErrorHandler errors={appStore.errors} />,
          children     : [
            { index: true, element: <VehicleStatus /> },
            { path: 'charging-history', element: <ChargingHistory /> },
            { path: 'trip-history', element: <TripHistory /> },
            { path: 'settings', element: <VehicleSettings /> },
          ],
        },
        {
          path         : `${AppRoutes.Account.path}`,
          element      : <Account />,
          errorElement : <RouteErrorHandler errors={appStore.errors} />,
          children     : [
            {
              index : true,
              lazy  : () => import('@/pages/Account/routes/TelematicsAccount'),
            },
            {
              path : 'schedule-blocks',
              lazy : () => import('@/pages/Account/routes/Blocks'),
            },
          ],
        },
        {
          path         : AppRoutes.Energy.path,
          element      : <Energy />,
          errorElement : <RouteErrorHandler errors={appStore.errors} />,
          permissions  : [FLEET_ENERGY_MGMT_VIEW],
          children     : [
            {
              index   : true,
              element : <SiteList />,
              loader  : siteListLoader,
            },
            {
              path    : ':site_id',
              element : <SitePropSheet />,
            },
          ],
        },
        {
          path    : AppRoutes.Settings.path,
          element : <Settings />,
          enabled : isSettingsPageEnabled(),
        },
        {
          path : 'about',
          lazy : () => import('@/pages/About'),
        },
        { path: '*', element: <RouteErrorHandler errors={[{ code: 404 }]} /> },
      ],
    },
  ]

  return getEnabledRoutes(routes)
}

export const RouteContainer = () => {
  const router = createBrowserRouter(getRoutes(), {})

  useEffect(() => {
    const { analyticsService } = useAnalyticsService()
    const queryParams          = KitUtilCommon.getQueryObject(window.location.search)
    analyticsService.trackEvent(analyticEvents.pageView, { ...queryParams })
  }, [window.location])

  return <RouterProvider router={router} fallbackElement={<StyledSpinner />} />
}
