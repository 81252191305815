import { KitButton, KitFlexCol, KitForm } from '@chargepoint/cp-toolkit'
import classNames from 'classnames'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import {
  type VehicleIdentificationFieldDef,
  type VehicleIdentificationFormState,
} from './types'
import { ComponentTypes } from './utils'
import { VehicleIdTypes } from '@/common/constants'
import { renderComponent } from '@/common/utils/componentHelpers'
import { groupBy, has, omit } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import ErrorBoundary from '@/components/ErrorBoundary'
import { type SelectChangeAction, type SelectChangeEvent } from '@/models/formModel'
import { type ComponentMap, type NormalizedChangeEvent } from '@/types/index'

export interface EditSettingsFormProps {
  componentMap: { [componentName: string]: React.FunctionComponent };
  fields: VehicleIdentificationFieldDef[];
  isLoading: boolean;
  addField: (fieldName: string) => void;
  onChange: (e: NormalizedChangeEvent, action?: SelectChangeAction) => void;
  onDelete: (fieldName: string, id: number | string) => void;
  state: VehicleIdentificationFormState;
  t: TFunction;
}

const renderFormItem = (
  field: VehicleIdentificationFieldDef,
  componentMap: ComponentMap,
  state: VehicleIdentificationFormState,
  {
    onChange,
    onDelete,
  }: Pick<EditSettingsFormProps, 'onChange' | 'onDelete' | 't'>,
) => {
  const { ...props } = field.props

  // intercept on change so we can handle a react-select on-change properly
  const handleOnChange = (e: SelectChangeEvent, action: SelectChangeAction) => {
    if (action) {
      const id = field.props['data-id'] as string
      return onChange(e, { ...action, id })
    }
    onChange(e)
  }

  const otherProps = {
    onChange: handleOnChange,
    ...(field.component === ComponentTypes.Deletable ? { onDelete } : {}),
  }
  const fieldDef   = field.component === ComponentTypes.Deletable
    ? { ...field, props: omit(props, ['label']) }
    : (field as VehicleIdentificationFieldDef)
  const fieldLabel = hasValue(field.index)
    ? `${field.props?.label} ${fieldDef.index}`
    : field.props?.label
  return (
    <KitForm.Group key={`${field.props?.name}-${field.props.id}`}>
      <>
        <KitForm.Label
          key={fieldDef.index}
          htmlFor={field.props['data-id'] as string}
          text={fieldLabel as string}
        >
          { renderComponent<VehicleIdentificationFormState>(
            fieldDef as unknown as Record<string, unknown>,
            componentMap,
            {
              state,
              otherProps,
            },
          ) }
        </KitForm.Label>
        { fieldDef.props.error && (
          <KitForm.InfoText text={fieldDef.props.infoText as string} />
        ) }
      </>
    </KitForm.Group>
  )
}

const EditSettingsForm: FC<EditSettingsFormProps> = ({
  addField,
  componentMap,
  fields,
  isLoading,
  onChange,
  onDelete,
  state,
  t,
}) => {
  // Add an index property to the field if there is more than
  // one of the same type so we can create the correct label
  const filtered                          = fields.filter((f) => f.visible !== false)
  const grouped                           = groupBy(filtered, 'props.name')
  const counts: { [key: string]: number } = {}

  const processedFields = filtered.reduce((acc, fld) => {
    const key = fld.props.name
    if (grouped[key].length > 1) {
      if (!has(counts, key)) {
        counts[key] = 1
      } else {
        counts[key] = counts[key] + 1
      }
      acc.push({ ...fld, index: counts[key] })
    } else {
      acc.push(fld)
    }

    return acc
  }, [] as VehicleIdentificationFieldDef[])

  const addFieldButtons = [
    {
      idType : VehicleIdTypes.ID_TYPE_MAC_ADDRESS,
      label  : `+ ${t('vehicles.settings.add_vehicle_mac_id')}`,
      qaId   : 'add_mac_link',
    },
    {
      idType   : VehicleIdTypes.ID_TYPE_RF_ID,
      label    : `+ ${t('vehicles.settings.add_card_serial_number')}`,
      qaId     : 'add_serial_link',
      disabled : false,
    },
  ]

  const applyFocus = (fieldName: string) => {
    setTimeout(() => {
      const elements = document.querySelectorAll(`input[name="${fieldName}"]`) as NodeListOf<HTMLInputElement>
      if (elements.length) {
        elements[elements.length - 1].focus()
      }
    }, 0)
  }

  return (
    <ErrorBoundary>
      { !isLoading
        && processedFields.map((field) => renderFormItem(field, componentMap as ComponentMap, state, {
          onChange,
          onDelete,
          t,
        })) }

      <KitFlexCol className="add-field-buttons">
        { addFieldButtons.map((f) => <KitButton
          className={classNames({ disabled: f.disabled })}
          aria-disabled={f.disabled}
          key={f.idType}
          variant="link"
          data-qa-id={f.qaId}
          onClick={(e) => {
            e.preventDefault()
            if (!f.disabled) {
              addField(f.idType)
              applyFocus(f.idType)
            }
          }}
        >{ f.label }</KitButton>) }
      </KitFlexCol>
    </ErrorBoundary>
  )
}

export default EditSettingsForm
