import {
  KitTab,
  KitTabs,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type FC, type PropsWithChildren } from 'react'

import styled from 'styled-components'

import { HiddenKitTabPanels, StyledKitTabList } from '@/components/Styled'

const { spacing } = ThemeConstants

const Container = styled.div`
  background: ${ThemeColors.white};
  padding: ${spacing.absolute.m}px ${spacing.absolute.m}px 0;
  position: relative;
  ul[role='tablist'] {
    padding-left: 0;
    display: flex;
    justify-content: center;
  }

  &.expanded {
    width: 100%;
    height: 85vh;
    flex: 1;
  }

  &.hidden {
    display: none;
    flex: 0;
  }
`

export interface Tab {
  label: string;
}

export interface TabbedChartContainerProps extends PropsWithChildren {
  tabs: Tab[];
  selectedIndex?: number;
  onTabChange: (index: number) => void;
  className?: string;
}

const TabbedChartContainer: FC<TabbedChartContainerProps> = ({
  children,
  className,
  onTabChange,
  selectedIndex,
  tabs,
}) => (
  <Container className={className ?? ''}>
    <KitTabs
      onSelect={(tabIndex: number) => onTabChange(tabIndex)}
      selectedIndex={selectedIndex}
    >
      <StyledKitTabList>
        { tabs.map((tab) => (
          <KitTab key={tab.label}> { tab.label } </KitTab>
        )) }
      </StyledKitTabList>
      { tabs.map((tab) => (
        <HiddenKitTabPanels key={tab.label} />
      )) }
    </KitTabs>

    { children }
  </Container>
)

export default TabbedChartContainer
