export type GetTextWidthFunc = {
  (text: string, font: string): number | void;
  canvas: HTMLCanvasElement;
};

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextWidth = <GetTextWidthFunc>((text: string, font: string): number | void => {
  // re-use canvas object for better performance
  const canvas: HTMLCanvasElement =    getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context                   = canvas.getContext('2d')
  if (context) {
    context.font  = font
    const metrics = context.measureText(text)
    return metrics.width
  }
})

export function obfuscate(s: string): string {
  return s.replace(/./g, '*')
}

// function to grab offset from default django next/previous paged response
export function getOffsetFromURL(
  url: string | null,
  pageSize: number,
): { currentPage: number; offset: number } {
  if (!url) {
    return { currentPage: 0, offset: 0 }
  }

  let parts = url?.split('offset')
  let pair  = parts?.[1].split('=')
  let currentPage

  if (parts.length < 2) {
    parts       = url?.split('page')
    pair        = parts[1].split('=')
    currentPage = Math.ceil(parseInt(pair[1]) * pageSize - 1)
    return {
      offset: currentPage - 1 * pageSize,
      currentPage,
    }
  }

  const offset = parseInt(pair[1])
  currentPage  = offset >= pageSize ? Math.ceil(offset / pageSize) : 1

  return {
    currentPage,
    offset,
  }
}

export function toggleBodyScroll() {
  if (document.body.classList.contains('noscroll')) {
    document.body.classList.remove('noscroll')
  } else {
    document.body.classList.add('noscroll')
  }
}
// will be used in the recurring dates dialog
export async function copyTextToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    console.error('Failed to copy: ', text, err)
  }
}
