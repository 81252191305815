import { ThemeConstants } from '@chargepoint/cp-toolkit'
import { type ReactNode } from 'react'

import styled from 'styled-components'

import { UILayout } from '@/common/constants'
import { HeaderContainer } from '@/components/Styled'

const { breakpoints, fontSize, spacing } = ThemeConstants

const StyledPageHeading = styled.h1`
  font-size: ${fontSize.text_20}rem;
  && {
    padding: ${spacing.m}rem 0 0 ${UILayout.hPageMargin + spacing.absolute.s}px;

    @media all and (max-width: ${breakpoints.md}px) {
      padding: ${spacing.m}rem 0 0 ${UILayout.mobile.hPageMargin}px;
    }
  }
`

const CommonHeader = ({ children }: { children: ReactNode }) => <HeaderContainer>{ children }</HeaderContainer>

const Title = ({ children }: { children: ReactNode }) => (
  <StyledPageHeading data-qa-id="page_title">{ children }</StyledPageHeading>
)

const Tabs = ({ children }: { children: ReactNode }) => <>{ children }</>

const Filters = ({ children }: { children: ReactNode }) => <>{ children }</>

CommonHeader.Title   = Title
CommonHeader.Tabs    = Tabs
CommonHeader.Filters = Filters

export default CommonHeader
