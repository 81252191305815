import { KitUtilData } from '@chargepoint/cp-toolkit'
import parseISO from 'date-fns/parseISO'
import { format, utcToZonedTime } from 'date-fns-tz'

import { t } from 'i18next'
import i18n from '../../../../i18n'
import { ISO_DATE, ISO_TIME } from '@/common/constants'
import { mapAlertTranslation } from '@/common/utils/alerts'
import { max } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { type Alert } from '@/models/alertModel'
import { type Depot, type DepotDetailsRow, type DepotFilterParams } from '@/models/depotModel'

export type DDVMatch = Pick<DepotDetailsRow, 'port_external_id' | 'vehicle_external_id'>;
export type DDVCompareKey = keyof DDVMatch;
export const rowExists = (
  params: DDVMatch,
  existingRows: DepotDetailsRow[],
) => {
  const fields: DDVCompareKey[] = ['port_external_id', 'vehicle_external_id']
  const match                   = existingRows.find((row) => (
    fields.filter((key) => {
      if (hasValue(row[key]) && hasValue(params[key])) {
        return row[key] === params[key]
      }
      return false
    }).length > 0
  ))

  return !!match
}

export const socketUpdateRequestMatchesRow = (
  updateRequest: Partial<DepotDetailsRow>,
  row: Partial<DepotDetailsRow>,
  keys: DDVCompareKey[],
) => keys.some((k) => {
  if (hasValue(updateRequest[k]) && hasValue(row[k])) {
    return updateRequest[k] === row[k]
  }
  return false
})

export const getMaxRangeValue = <
  T extends Record<string, number> = Record<string, number>,
>(
  arr: T[],
  fieldName: keyof T,
  defaultMax: number,
) => {
  if (!Array.isArray(arr)) {
    return defaultMax
  }
  const values: number[] = arr
    .map((row) => row[fieldName])
    .filter((val) => hasValue(val))

  if (KitUtilData.isEmpty(values)) {
    return defaultMax
  }

  return max(values)
}

export const getDuplicateRows = (
  dupRow: DepotDetailsRow,
  rows: DepotDetailsRow[],
) => {
  const result: DepotDetailsRow[] = []
  rows.forEach((row) => {
    if (
      dupRow.port_external_id === row.port_external_id
      || dupRow.vehicle_external_id === row.vehicle_external_id
    ) {
      result.push(row)
    }
  })

  return result
}

export const detectDuplicateDDVRows = (rows: DepotDetailsRow[]) => {
  const duplicateRows: DepotDetailsRow[]                                       = []
  const matches: { port_external_id: string[]; vehicle_external_id: string[] } = {
    port_external_id    : [],
    vehicle_external_id : [],
  }

  const hasDuplicates = rows.some((row) => {
    if (
      (row.port_external_id && matches.port_external_id.includes(row.port_external_id))
      || (row.vehicle_external_id
        && matches.vehicle_external_id.includes(row.vehicle_external_id))
    ) {
      // find duplicate:
      const rowResults = getDuplicateRows(row, rows)
      rowResults.forEach((r) => {
        duplicateRows.push(r)
      })

      return true
    }

    if (row.port_external_id) {
      matches.port_external_id.push(row.port_external_id)
    }

    if (row.vehicle_external_id) {
      matches.vehicle_external_id.push(row.vehicle_external_id)
    }

    return false
  })

  return {
    hasDuplicates,
    duplicateRows,
  }
}

export const mapAlertObject = (
  alert: Alert,
  ddvRow: DepotDetailsRow,
  timeZone: string,
) => {
  const alertProps = {
    alert_id            : alert.alert_id,
    alert_type          : alert.alert_type,
    charger_name        : ddvRow.port_name,
    vehicle_mac_address : ddvRow.vehicle_mac_address,
    vehicle_name        : ddvRow.vehicle_name,
    ...alert.alert_subject_metadata,
  }

  if (alert?.updated_time) {
    const parsedAlertTime    = parseISO(alert?.updated_time)
    const localizedAlertTime = utcToZonedTime(parsedAlertTime, timeZone)

    return {
      time: `${format(localizedAlertTime, ISO_DATE)} • ${format(
        localizedAlertTime,
        ISO_TIME,
      )}`,
      text: mapAlertTranslation(t, alert.alert_code, alertProps),
      ...alertProps,
    }
  }
  return {
    text: mapAlertTranslation(t, alert.alert_code, alertProps),
    ...alertProps,
  }
}

export const mapAlertsToDepotRows = (
  ddvRows: DepotDetailsRow[] | undefined,
  alerts: Alert[] | undefined,
  depot: Depot,
) => (Array.isArray(ddvRows)
  ? (ddvRows?.map((rowData) => {
    const matches: Alert[] = Array.isArray(alerts)
      ? alerts.filter((alert) => (
        (hasValue(alert.port_external_id)
                  && hasValue(rowData.port_external_id)
                  && alert.port_external_id === rowData.port_external_id)
                || (hasValue(rowData.vehicle_external_id)
                  && hasValue(alert.vehicle_external_id)
                  && rowData.vehicle_external_id === alert.vehicle_external_id)
      ))
      : []

    const alertData = matches.map((alert: Alert) => mapAlertObject(alert, rowData, depot?.timezone))

    return {
      ...rowData,
      alerts: alertData,
    }
  }) as unknown as DepotDetailsRow[])
  : [])

/**
 * Build out filter params
 * @param ddvRows
 * @param filterParams
 * @returns
 */
export const buildDDVFilterDefaults = (
  ddvRows: DepotDetailsRow[],
  filterParams: Partial<DepotFilterParams>,
): DepotFilterParams => {
  const uniqueFleets: string[] = [
    ...new Set(
      ddvRows
        .map((row) => row.fleet_name)
        .filter((fleetName) => fleetName !== null),
    ),
  ]

  const maxRange = getMaxRangeValue<Pick<DepotDetailsRow, 'estimated_range'>>(
    ddvRows,
    'estimated_range',
    100,
  )

  const statuses = filterParams?.statuses ?? []

  return {
    ...filterParams,
    statuses,
    fleets                        : uniqueFleets,
    estimated_range_default_value : [0, maxRange],
    soc_default_value             : [0, 100],
  } as DepotFilterParams
}

export const isChargingAtCurrentDepot = (
  ddvRow: DepotDetailsRow,
  currentDepot: Depot,
) => (
  hasValue(ddvRow.port_external_id)
    && hasValue(ddvRow.current_depot_name)
    && currentDepot?.name === ddvRow.current_depot_name
)
