/* eslint-disable camelcase */
import {
  KitFlexCol,
  KitFlexRow,
  KitFlexRowSpaced,
  KitRadio,
  KitUtilFormat,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { differenceInSeconds, parseISO } from 'date-fns/esm'
import { type TFunction } from 'i18next'
import { type FC, type FocusEvent, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import NoUnknownVehiclesFound from './NoUnknownVehiclesFound'
import { VehicleIdTypes } from '@/common/constants'
import { getLocaleCode } from '@/common/lang'
import { hasValue } from '@/common/utils/validations'
import {
  WizardContent,
  WizardHeading,
} from '@/components/AddFleetWizard/styled'
import {
  AddVehicleFlow,
  type UnknownVehicleSession,
  type WizardScreenProps,
  unknownVehicleDetected,
} from '@/components/AddVehicleWizard/config'
import { List } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import { type ServiceResponse } from '@/models/serviceModel'
import { type ActiveChargingSession } from '@/models/vehicleModel'
import VehicleService from '@/services/VehicleService'
import { type WizardInputChangeFunc } from '@/types/index'

const { spacing } = ThemeConstants

const Container = styled.div`
  min-height: 160px;
`

const UnknownVehicleRow = styled.li<{ selected: boolean; focused: boolean }>`
  background-color: ${({ selected }) => (selected ? ThemeColors.gray_05 : ThemeColors.white)};
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  padding: ${spacing.absolute.m}px;
  outline: ${({ focused }) => (focused ? `1px solid ${ThemeColors.orange_50}` : 0)};
  cursor: pointer;
  &:hover {
    background: ${ThemeColors.gray_05};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${ThemeColors.gray_20};
  }
`

const RadioContainer = styled(KitFlexRow)`
  align-items: flex-start;
  > div {
    min-width: 50px;
  }
`

const formatPluggedInSeconds = (startTime: string, endTime: string): string => {
  if (!startTime && !endTime) {
    return '--'
  }
  if (!endTime) {
    const seconds: number = differenceInSeconds(
      parseISO(new Date().toISOString()),
      parseISO(startTime),
    )
    return KitUtilFormat.formatDurationFromSeconds(seconds, getLocaleCode())
  }
  return '--'
}

export interface UnknownVehicleSelectorProps extends WizardScreenProps {
  t: TFunction;
  onChange: WizardInputChangeFunc;
}

const UnknownVehicleSelector: FC<UnknownVehicleSelectorProps> = ({
  isTest,
  onChange,
  state,
  t,
  updateSectionData,
}) => {
  const Identification              = state.sections.identification
  const [selected, setSelected]     = useState(Identification.mac_address)
  const [focused, setFocused]       = useState<string>()
  const isUnknownVehicle            = unknownVehicleDetected(state)
  const [isFetching, setIsFetching] = useState(!isTest)

  const { activeUnknownVehicleSessions } = state.data.vehicle ?? {}
  // filter out sessions without a vehicle_mac_address because they are for AC chargers.
  // This screen is for selecting vehicles that are connected to DC chargers only
  const noVehiclesDetected: boolean =    activeUnknownVehicleSessions?.filter((session: UnknownVehicleSession) => hasValue(session.vehicle_mac_address))?.length === 0

  const refs = useRef([])

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { target } = e
    setFocused(target.value)
  }

  const handleUnknownVehicleSelected = ({ mac_address }: ActiveChargingSession) => {
    onChange(
      AddVehicleFlow.Identification,
      VehicleIdTypes.ID_TYPE_MAC_ADDRESS,
      mac_address,
    )
    onChange(AddVehicleFlow.Vehicle, 'unknownVehicleSelected', true)
  }

  async function fetchActiveSessions() {
    setIsFetching(true)
    VehicleService.listActiveUnknownVehicleSessions().then(
      (response: ServiceResponse<ActiveChargingSession[]>) => {
        if (!response.error) {
          const payload = {
            activeUnknownVehicleSessions: response
              ?.filter((session) => hasValue(session.vehicle_mac_address))
              .sort((a, b) => {
                const v1 = new Date(a.start_time).getTime()
                const v2 = new Date(b.start_time).getTime()
                return v2 - v1
              }),
          }
          updateSectionData('vehicle', payload)
        }

        setTimeout(() => {
          // we want to show the loader for at least a moment here
          setIsFetching(false)
        }, 300)
      },
    )
  }

  useEffect(() => {
    const { charging } = state.sections
    if (!isUnknownVehicle && charging.id_type === 'plug_in' && !isTest) {
      fetchActiveSessions()
    }
  }, [isUnknownVehicle])

  useEffect(() => {
    if (!noVehiclesDetected && Array.isArray(activeUnknownVehicleSessions)) {
      const intervalID = (setInterval(() => {
        refs.current.forEach((ref, i) => {
          if (ref) {
            ref.innerHTML = formatPluggedInSeconds(activeUnknownVehicleSessions[i].start_time, activeUnknownVehicleSessions[i].end_time)
          }
        })
      }, 1000))

      return () => clearInterval(intervalID)
    }
  }, [noVehiclesDetected])

  if (!isFetching && !isUnknownVehicle && noVehiclesDetected) {
    return (
      <Container>
        <WizardContent>
          <NoUnknownVehiclesFound
            t={t}
            fetchActiveSessions={fetchActiveSessions}
          />
        </WizardContent>
      </Container>
    )
  }

  return (
    <Container>
      { isFetching && <StyledSpinner /> }
      { !isFetching && (
        <>
          <WizardHeading>
            { t('vehicles.add_vehicle.vehicle.charging_location') }
          </WizardHeading>

          <List>
            { activeUnknownVehicleSessions?.map(
              ({
                address_1,
                end_time,
                id,
                name,
                port,
                start_time,
                station_name,
                vehicle_mac_address: value,
              }: Partial<UnknownVehicleSession>, i) => (
                <UnknownVehicleRow
                  data-id={value}
                  key={`${name}-${value}`}
                  focused={focused === value}
                  selected={selected === value}
                  onClick={() => {
                    setSelected(value)
                    handleUnknownVehicleSelected({
                      name,
                      id,
                      port,
                      mac_address: value,
                    })
                  }}
                >
                  <RadioContainer>
                    <KitRadio
                      name={name as string}
                      checked={selected === value}
                      onFocus={handleFocus}
                      onChange={() => setSelected(value)}
                      value={value}
                    />

                    <KitFlexCol>
                      <b>{ station_name }</b>
                      <span>{ address_1 } mi</span>
                    </KitFlexCol>
                  </RadioContainer>

                  <KitFlexRowSpaced>
                    <KitFlexCol>
                      <b aria-live="polite" ref={(el) => refs.current[i] = el} >{ formatPluggedInSeconds(start_time, end_time) }</b>
                      <span>{ t('plug_in') }</span>
                    </KitFlexCol>
                  </KitFlexRowSpaced>
                </UnknownVehicleRow>
              ),
            ) }
          </List>
        </>
      ) }
    </Container>
  )
}

export default UnknownVehicleSelector
