import { type ComponentType, type ReactElement } from 'react'

import { Area, Bar, type LegendType, Line, ReferenceLine, Symbols } from 'recharts'
import { type SymbolType } from 'recharts/types/util/types'

import Shape from '../ChartComponents/CPChartShape'
import { type ChartElementProps, type ToolTipItem } from './types'
import { hasValue } from '@/common/utils/validations'
import { type LegendPayload, type SymbolProps } from '@/models/chartModel'

// tooltip symbols
export const SymbolMap = {
  area : 'line',
  bar  : 'square',
  line : 'line',
}

export const componentMap: {
  [key: string]: typeof Bar | typeof Line | typeof Area | typeof ReferenceLine;
} = {
  line          : Line,
  bar           : Bar,
  area          : Area,
  referenceLine : ReferenceLine,
}

export const getSymbolType = (symbolType: string): SymbolType | 'line' => {
  if (symbolType === 'line') {
    return 'line'
  }
  return (
    ['circle', 'diamond', 'square'].includes(symbolType)
      ? (symbolType as LegendType)
      : 'circle'
  ) as SymbolType
}

export const renderSymbol = ({
  color,
  shape,
  stackId,
  strokeDasharray,
  type,
}: SymbolProps) => {
  const symbol = shape ?? type
  if (!stackId && symbol === 'line') {
    return <Shape type="line" fill={color} strokeDasharray={strokeDasharray} />
  }
  return (
    <Symbols
      cx={5}
      cy={5}
      type={getSymbolType(symbol) as SymbolType}
      size={50}
      fill={color}
    />
  )
}

export const parseLegendPayload = (legendPayload?: LegendPayload[]) => (legendPayload
  ? legendPayload
    .map((series) => ({
      active          : series.active,
      color           : series.color,
      opacity         : series.payload?.strokeOpacity !== 1 ? 0.5 : 1,
      dataKey         : series.dataKey,
      type            : getSymbolType(series.type),
      strokeDasharray : series.payload?.strokeDasharray,
    } as LegendPayload))
    .filter(hasValue)
  : [])

export const getToolTipItems = (
  chartSeries: ChartElementProps[],
  additionalItems: ToolTipItem[] = [],
): ToolTipItem[] => chartSeries
  .map(
    ({
      dataKey,
      fill,
      label,
      name,
      seriesType,
      shape,
      stroke,
      strokeDasharray,
      unit,
    }) => ({
      color  : fill ?? stroke,
      key    : dataKey,
      name,
      label  : label ?? name,
      unit,
      active : true,
      seriesType,
      shape  : shape ?? SymbolMap[seriesType as keyof typeof SymbolMap],
      strokeDasharray,
    } as ToolTipItem),
  )
  .concat(additionalItems) as ToolTipItem[]

export function renderSeries(
  chartSeriesProps: ChartElementProps,
): ReactElement {
  type componentKey = keyof typeof componentMap;
  const ChartComponent = componentMap[
    chartSeriesProps.seriesType as componentKey
  ] as ComponentType<ChartElementProps>
  return <ChartComponent {...chartSeriesProps} />
}
