import { ThemeColors } from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import styled from 'styled-components'

import deliveryTruck from './assets/ic_box_truck.svg'
import bus from './assets/ic_bus.svg'
import car from './assets/ic_car.svg'
import infoIcon from './assets/info-icon.svg'
import { fadeIn } from '@/common/animations'

export interface FleetIconProps extends JSX.IntrinsicAttributes {
  altText: string;
  iconType: string;
  onClick?: () => void;
  useBg?: boolean;
  style?: Record<string, string>;
}

const iconMap: Record<string, string> = {
  deliveryTruck,
  bus,
  car,
  info: infoIcon,
}

export const SVGIconWrapper = styled.div<{ fill: string }>`
  svg {
    fill: ${({ fill = ThemeColors.gray_70 }) => fill};
  }
`

export const StyledIcon = styled.div`
  animation: ${fadeIn} 1s;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  width: 20px;
  height: 20px;
  opacity: 0;
`

const FleetIcon: FC<FleetIconProps> = (props) => {
  const { altText, iconType, style = {}, useBg = true, ...passThru } = props
  if (useBg) {
    return (
      <StyledIcon
        style={{ backgroundImage: `url(${iconMap[iconType]})`, ...style }}
        {...passThru}
      />
    )
  }

  return <img alt={altText} style={style} src={iconMap[iconType]} />
}

export default FleetIcon
