import { type FC } from 'react'

import { type ErrorPageProps, renderLinks } from './helpers'
import { ErrorPageHeader, ErrorPageSubHeader } from './styles'

const Http404: FC<ErrorPageProps> = ({ children, links, t }) => (
  <>
    <ErrorPageHeader>{ t('errors.404.title') }</ErrorPageHeader>
    <ErrorPageSubHeader>{ t('page_suggestions') }</ErrorPageSubHeader>
    { renderLinks(links, t) }
    { children }
  </>
)

export default Http404
