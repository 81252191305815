import { ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'

import { HeaderContainer, Spacer } from '../../components/Styled'
import CommonHeader from '@/components/CommonHeader'

export interface HeaderProps {
  t: TFunction;
}

const Header = ({ t }: HeaderProps) => (
  <HeaderContainer>
    <CommonHeader.Title>{ t('depot_plural') }</CommonHeader.Title>
    <Spacer orientation="vertical" size={ThemeConstants.spacing.absolute.m} />
  </HeaderContainer>
)

export default Header
