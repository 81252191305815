import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'

import { type ReactElement } from 'react'
import { type BaseAxisProps } from 'recharts/types/util/types'
import { type NumberFormatOptions } from '@/common/lang'

export interface YAxisProps extends BaseAxisProps {
  yAxisId? : string;
  orientation : 'left' | 'right';
  padding?: { bottom?: number, top?: number };
  style?: { fontSize?: string };
  label: string | ReactElement | Record<string, unknown>;
  stroke?: string;
  ticks?: number[]
}

export enum SeriesType {
  Area = 'area',
  Bar = 'bar',
  Line = 'line',
  Scatter = 'scatter',
  ReferenceLine = 'referenceLine',
}

export enum InterpolationType {
  basis = 'basis',
  basisClosed = 'basisClosed',
  basisOpen = 'basisOpen',
  linear = 'linear',
  linearClosed = 'linearClosed',
  natural = 'natural',
  monotoneX = 'monotoneX',
  monotoneY = 'monotoneY',
  monotone = 'monotone',
  step = 'step',
  stepBefore = 'stepBefore',
  stepAfter = 'stepAfter',
}

export const Palette = {
  fault               : '#cc0000',
  power               : '#A7C5E5',
  setpoint            : '#D12A11',
  setpoint_simulation : '#B61C05',
  referenceLine       : '#BFBFBF',
  referenceLine2      : '#4C4C4C',
  soc                 : '#568399',
}

export const ChartColors = {
  blue         : ThemeColors.blue_50,
  lightBlue    : '#A7C5E5',
  lightGreen   : '#C8E5AF',
  lightPurple  : '#C67FBD',
  lightRed     : '#F0BCB4',
  darkGray     : ThemeColors.gray_70,
  gray         : '#949494',
  mediumGray   : ThemeColors.gray_50,
  lightGray    : ThemeColors.gray_10,
  green        : ThemeColors.green_50,
  orange       : '#FF7A14',
  red          : ThemeColors.red_50,
  turquiseBlue : '#20D0C9',
  white        : '#fff',
}

// review these in the future to determine what chart attributes can be a common constant across various chart types
export const AXIS_FONT_SIZE = ThemeConstants.fontSize.text_14

export const DEFAULT_CHART_MARGIN = {
  top    : 10,
  right  : 10,
  bottom : 10,
  left   : 10,
}

export const chartMargin = {
  top    : 30,
  right  : 30,
  bottom : 0,
  left   : 0,
}

export const DEFAULT_ZOOM = {
  x1 : 'dataMax',
  y1 : 'dataMax + 20',
  x2 : 'dataMin',
  y2 : 0,
}

export const yAxisWidth = 75

export const yAxisFormatOptions: NumberFormatOptions = {
  minimumFractionDigits : 0,
  maximumFractionDigits : 1,
}
