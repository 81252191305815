import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import utils from '@/common/utils'
import { type Alert } from '@/models/alertModel'
import { type PagedServiceResponse } from '@/models/serviceModel'
import { type ClearAlertRequest } from '@/types/models'

const createService = (api: ServiceInterface) => {
  const baseURL = '/api/alerts'
  return {
    async getActiveAlertsForDepot(
      depotId: string,
    ): Promise<PagedServiceResponse<Alert>> {
      return wrapRequest(
        api.get(`/api/alerts/active-for-depot/${depotId}/`),
      ) as Promise<PagedServiceResponse<Alert>>
    },
    async getAlertList(query = {}): Promise<PagedServiceResponse<Alert>> {
      const qs = utils.paramsToQueryString(query)
      return wrapRequest(
        api.get(`${baseURL}/list/${qs}`),
      ) as unknown as Promise<PagedServiceResponse<Alert>>
    },
    async clearAlert(payload: ClearAlertRequest) {
      return api.post(`${baseURL}/clear/`, payload).res()
    },
  }
}

export default createService(AbstractService())
