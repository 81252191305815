import { KitUtilData } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'

import { getOptionNameLabel } from '@/common/utils/contact'
import { isBetween } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { type WizardStep } from '@/components/Wizard'
import { type NOSContact } from '@/models/commonModel'
import { type Depot } from '@/models/depotModel'

import { ScheduleType } from '@/models/scheduleModel'
import type { Schedule } from '@/models/scheduleModel'
import { type ServicePayload, type ServiceResponse } from '@/models/serviceModel'
import AdminService from '@/services/AdminService'
import DepotService from '@/services/DepotService'
import FleetService from '@/services/FleetService'
import { type ComponentConfig } from '@/types/index'

export interface FleetContact extends NOSContact {
  contact_id: string;
  values: Record<string, unknown>;
}

export interface Sections {
  about: { name?: string };
  contact?: Partial<FleetContact>;
  schedule?: Partial<Schedule & { scheduleType: ScheduleType }>;
}

export interface AddFleetReducerState {
  data: {
    about: {
      depots?: Depot[];
      org_external_id?: string;
      org_name: string;
      schedule: Schedule;
    };
    contact: FleetContact[];
  };
  sections: Sections;
  scheduleType: string;
}

export interface AddFleetConfig {
  components: Record<string, ComponentConfig>;
  steps: WizardStep[];
}

export const submitForm = (request: Sections) => FleetService.addFleet(request as unknown as ServicePayload<unknown>)

export const getDataForStep = (
  stepId: string,
): Promise<ServiceResponse<unknown> | ServiceResponse<unknown>[] | void> => {
  if (stepId === 'about') {
    return Promise.all([
      DepotService.getDepots(),
      AdminService.getOrganization(),
    ])
  } if (stepId === 'contact') {
    return AdminService.getAdminContacts()
  }

  return new Promise((resolve) => {
    resolve()
  })
}

export const getConfig = (
  state: AddFleetReducerState,
  t: TFunction,
): AddFleetConfig => {
  const config = {
    components: {
      about: {
        component : 'about',
        props     : {
          fields: {
            org_name : state.data?.about?.org_name,
            depot    : {
              options: state.data?.about?.depots?.map((depot: Depot) => ({
                label : depot.name,
                value : depot.external_id,
              })),
            },
          },
          values: state.sections.about,
        },
      },
      contact: {
        component : 'contact',
        props     : {
          state: state.data?.contact?.find(
            (c) => c.nos_user_id === state.sections.contact?.contact_id,
          ),
          values : state.sections.contact,
          fields : {
            contact: {
              options: state.data?.contact?.map((contact) => ({
                label : getOptionNameLabel(contact),
                value : contact.nos_user_id,
                ...contact,
              })),
            },
          },
        },
      },
      schedule: {
        component : 'schedule',
        props     : {
          fields: {
            arrival_time_downtime_seconds   : 0,
            departure_time_downtime_seconds : 0,
          },
          values: state.sections.schedule,
        },
      },
      summary: { component: 'summary', props: { sections: state.sections } },
    },
    steps: [
      {
        id       : 'about',
        active   : true,
        label    : t('about'),
        validate : (about: Record<string, string>) => {
          if (!about) {
            return false
          }
          const required = ['name', 'home_depot_external_id']
          return (
            required.filter(
              (key) => ![undefined, null, ''].includes(about[key]),
            ).length >= required.length
          )
        },
      },
      {
        id       : 'contact',
        active   : true,
        label    : t('contact'),
        validate : () => true,
      },
      {
        id       : 'schedule',
        active   : true,
        label    : t('scheduling'),
        validate : (schedule: Schedule & { scheduleType: ScheduleType }) => {
          const fieldErrors: Record<string, string> = {}
          if (!hasValue(schedule?.scheduleType)) {
            fieldErrors.scheduleType = 'required'
          }
          if (!hasValue(schedule?.required_soc)) {
            fieldErrors.required_soc = 'required'
          } else if (!isBetween(schedule.required_soc as number, 1, 100)) {
            fieldErrors.required_soc = 'errors.max'
          }

          if (!KitUtilData.isEmpty(fieldErrors)) {
            return false
          }

          const required = [
            'arrival_time',
            'departure_time',
            'arrival_time_downtime_secs',
            'departure_time_downtime_secs',
          ]

          if (schedule.scheduleType === ScheduleType.BY_TIME) {
            return (
              (required.filter((key) => hasValue(schedule[key as keyof FleetSchedule])).length >= required.length)
            )
          }

          return true
        },
      },
      {
        id       : 'summary',
        active   : true,
        label    : t('summary'),
        validate : () => config.steps.slice(0, 3).reduce((acc, step) => step.validate(state.sections[step.id]) && acc, true),
      },
    ],
  }

  return config
}
