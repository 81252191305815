import { type TFunction } from 'i18next'

import { getUserPreferences } from '@/common/utils/user'
import { type BlockAssignment } from '@/models/dispatchModel'
import { type ColumnDef } from '@/types/index'

export function getColumns(t: TFunction): ColumnDef[] {
  const pref          = getUserPreferences()
  const distanceUnits = t(`units.distance.${pref.length_units}.short`)
  return [
    {
      field : 'name',
      label : t('fleets.upcoming_blocks_table_columns.block_name'),
    },
    {
      field : 'required_distance_miles',
      label : t('fleets.upcoming_blocks_table_columns.distance', { distanceUnits }),
    },
    {
      field : 'departure_location',
      label : t('fleets.upcoming_blocks_table_columns.departure_location'),
    },
    {
      field : 'departure_time',
      label : t('fleets.upcoming_blocks_table_columns.departure'),
    },
    {
      field : 'arrival_location',
      label : t('fleets.upcoming_blocks_table_columns.arrival_location'),
    },
    {
      field : 'arrival_time',
      label : t('fleets.upcoming_blocks_table_columns.arrival'),
    },
  ]
}

export const processData = (data: BlockAssignment[]) => data.map((item) => {
  const arrival_time = item.arrival_date
    ? `${item.arrival_date} ${item.arrival_time}`
    : ''
  return {
    ...item,
    arrival_time,
    departure_time: `${item.departure_date} ${item.departure_time}`,
  }
})
