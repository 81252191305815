import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type FC } from 'react'

import { Surface } from 'recharts'
import styled from 'styled-components'

import { SymbolMap, renderSymbol } from '../common/helpers'
import { type ToolTipItem } from '../common/types'
import { toMultiDimensional } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { Spacer } from '@/components/Styled'

const { fontSize, fontWeight, spacing } = ThemeConstants

export interface CPChartTooltipOptions {
  opacity?: number;
}

export interface CPChartTooltipProps {
  items: ToolTipItem[];
  payload?: {
    color: string;
    payload: Record<string, number>;
  }[];
  formatTimeStamp: (row: Record<string, number>) => string;
  formatter: (key: string, value: number) => string;
  type?: string;
}

export interface CPChartTooltipItem {
  name?: string;
  key: string;
  label?: string;
  color?: string;
  value?: unknown;
  shape: string;
  strokeDasharray?: string;
  unit?: string;
}

const CustomTooltipWrapper = styled.div<{ opacity?: number }>`
  background: ${({ opacity }) => (opacity ? `rgba(0, 0, 0, ${opacity})` : 'rgba(0, 0, 0, 0.8)')};
  color: #fff;
  border-radius: ${spacing.absolute.xs}px;
  border: 1px solid ${ThemeColors.gray_40};
  padding: ${spacing.absolute.s}px;
  font-size: ${fontSize.text_12}rem;
  line-height: ${spacing.absolute.m}px;
  max-height: 550px;
`

const TooltipTitle = styled.div`
  margin: ${spacing.absolute.xs}px;
  margin-left: ${spacing.absolute.m + spacing.absolute.xs}px;
  margin-top: ${spacing.absolute.m}px;
  font-size: ${fontSize.text_12}rem;
  color: ${ThemeColors.gray_20};
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing.absolute.xxs}px;
    margin-bottom: ${spacing.absolute.xs}px;
  }
`

const ColWrapper = styled.div`
  display: flex;
`

const Col = styled.div`
  max-width: 250px;
  &:first-child {
    margin-right: ${spacing.absolute.xs}px;
  }
`

const TooltipSeriesSymbol = styled.span`
  cursor: pointer;
  margin-right: ${spacing.absolute.s}px;
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  display: inline-block;
  font-weight: ${fontWeight.bold};
  margin-right: ${spacing.absolute.xs}px;
  text-overflow: ellipsis;
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
`

const Value = styled.span`
  color: ${ThemeColors.gray_20};
  min-width: 60px;
  text-align: right;
`

function renderSeriesItem(
  {
    color,
    key,
    label,
    name,
    shape,
    strokeDasharray,
    unit,
    value,
  }: CPChartTooltipItem,
  formatter?: (key: string, val: number) => string,
) {
  const format = (): string => (formatter ? formatter(key, value as number) : `${value} ${unit}`)
  if (color) {
    const itemLabel = typeof label === 'string' ? label : name
    return (
      <>
        <LabelWrapper>
          <TooltipSeriesSymbol>
            <Surface
              width={10}
              height={10}
              viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
            >
              { renderSymbol({ color, shape, strokeDasharray }) }
            </Surface>
          </TooltipSeriesSymbol>
          <Label>{ itemLabel }:</Label>
        </LabelWrapper>
        <Value>{ format() }</Value>
      </>
    )
  }

  return (
    <>
      <span>
        <Spacer size={spacing.absolute.m + spacing.absolute.xxs} />
        <Label>{ label }:</Label>
      </span>
      <Value>{ format() }</Value>
    </>
  )
}

const CPChartTooltip: FC<CPChartTooltipProps> = (props) => {
  // columnBreakPoint may need to be adjusted, but we'll have to see how it works in large production sites
  const columnBreakPoint                                     = 20
  const { formatTimeStamp, formatter, items, payload, type } = props
  if (payload && payload.length) {
    const row                                = payload[0].payload as Record<string, number>
    const mappedFields: CPChartTooltipItem[] = items
      ?.filter((v) => hasValue(v))
      .map((item) => {
        const { key, shape } = item
        return {
          ...item,
          shape : shape ?? SymbolMap[type as keyof typeof SymbolMap],
          value : row[key],
        }
      })
      .filter((item) => hasValue(item.value))

    const grouped = toMultiDimensional(
      mappedFields,
      columnBreakPoint,
    ) as CPChartTooltipItem[][]
    return (
      <CustomTooltipWrapper>
        <ColWrapper>
          { grouped.map((grp, i) => (
            <Col key={`col-${i}`}>
              {
                <List>
                  { grp.map((field) => (
                    <li key={field.label}>
                      { renderSeriesItem(field, formatter) }
                    </li>
                  )) }
                </List>
              }
            </Col>
          )) }
        </ColWrapper>
        { formatTimeStamp && <TooltipTitle>{ formatTimeStamp(row) }</TooltipTitle> }
      </CustomTooltipWrapper>
    )
  }

  return null
}

export default CPChartTooltip
