/* eslint-disable @typescript-eslint/no-use-before-define */
import cloneDeep from 'clone-deep'

import {
  type VehicleIdentificationFieldDef,
  type VehicleIdentificationFieldProps,
  type VehicleIdentificationFormState,
} from './types'
import { getFieldID, getInitialValue } from './utils'
import { RequestType } from '@/common/constants'
import { has } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { type VehicleIdentificationResponse } from '@/models/vehicleModel'

export enum FormActions {
  UPDATE_FIELD = 'UPDATE_FIELD',
  ADD_FIELD = 'ADD_FIELD',
  DELETE_FIELD = 'DELETE_FIELD',
  REMOVE_FIELD = 'REMOVE_FIELD',
  INIT_FORM = 'INIT_FORM',
  SET_PROP = 'SET_PROP',
  VALIDATE = 'VALIDATE',
}

export interface PayloadAction<T> {
  type: string;
  payload: T;
}

export type UpdateFieldAction = {
  type: FormActions.UPDATE_FIELD;
  fieldID?: number | string;
  fieldName: string;
  fieldValue: unknown;
  requestType: RequestType;
};

export type VehicleIdentificationSettingsAction =
  | {
    type: FormActions.INIT_FORM;
    fields: VehicleIdentificationFieldDef[];
    response: VehicleIdentificationResponse;
  }
  | {
    type: FormActions.ADD_FIELD;
    field: VehicleIdentificationFieldDef;
    sortIndex?: number;
  }
  | {
    type: FormActions.DELETE_FIELD;
    fieldID: number | string;
    fieldName: string;
  }
  | {
    type: FormActions.REMOVE_FIELD;
    fieldID?: number | string;
    fieldName: string | string[];
  }
  | {
    type: FormActions.SET_PROP;
    prop: string;
    value: unknown;
  }
  | {
    type: FormActions.VALIDATE;
    errors: VehicleIdentificationFieldProps[];
  }
  | UpdateFieldAction;

export function formReducer(
  formState: VehicleIdentificationFormState,
  action: VehicleIdentificationSettingsAction,
): VehicleIdentificationFormState {
  switch (action.type) {
    case FormActions.INIT_FORM:
      return {
        ...formState,
        fields   : action.fields as VehicleIdentificationFieldDef[],
        response : action.response as VehicleIdentificationResponse,
      }
    case FormActions.UPDATE_FIELD:
      return {
        ...formState,
        fields: updateField(formState, action),
      }
    case FormActions.ADD_FIELD:
      return {
        ...formState,
        fields: addField(
          [...formState.fields],
          action.field as VehicleIdentificationFieldDef,
        ),
      }
    case FormActions.DELETE_FIELD:
      return {
        ...formState,
        fields: deleteField(
          action.fieldName as string,
          action.fieldID,
          formState,
        ),
      }
    case FormActions.REMOVE_FIELD:
      return {
        ...formState,
        fields: removeField(action.fieldName as string, formState.fields),
      }
    case FormActions.SET_PROP:
      return {
        ...formState,
        [action.prop as string]: action.value,
      }
    case FormActions.VALIDATE:
      return {
        ...formState,
        fields: validate(action.errors, formState.fields),
      }
    default:
      return formState
  }
}

function validateField(
  field: VehicleIdentificationFieldDef,
  errorFields: VehicleIdentificationFieldProps[],
) {
  const { id }   = field.props as VehicleIdentificationFieldProps
  const errField = errorFields.find((err) => err.id === id)
  return {
    ...field,
    props: {
      ...field.props,
      error    : errField?.error,
      infoText : errField?.infoMessage,
    },
  }
}

export function validate(
  errorFields: VehicleIdentificationFieldProps[],
  fields: VehicleIdentificationFieldDef[],
) {
  return fields.map((field) => validateField(field, errorFields))
}

export function updateField(
  formState: VehicleIdentificationFormState,
  action: UpdateFieldAction,
): VehicleIdentificationFieldDef[] {
  const fields     = formState.fields.concat()
  const matchIndex = fields.findIndex((f: VehicleIdentificationFieldDef) => (getFieldID(f.props) === action.fieldID ?? !f.props.id
    ? f.props.name === action.fieldName
    : null))

  const matchedField = fields[matchIndex]
  const requestType  = action.requestType ?? matchedField.requestType

  if (matchedField) {
    const updatedField = cloneDeep(matchedField)
    // if user sets field back to it's original value (or both fields have empty values), unset request type
    const initialValue = getInitialValue(
      action.fieldName as string,
      formState.response as VehicleIdentificationResponse,
      action.fieldID,
    )
    if (
      (requestType === RequestType.UPDATE
        && action.fieldValue === initialValue)
      || (!hasValue(action.fieldValue) && !hasValue(initialValue))
    ) {
      delete updatedField.requestType
    } else {
      updatedField.requestType = requestType
    }
    updatedField.props.value = action.fieldValue

    fields[matchIndex] = updatedField
  }

  return fields
}

export function addField(
  fields: VehicleIdentificationFieldDef[],
  addedField: VehicleIdentificationFieldDef,
): VehicleIdentificationFieldDef[] {
  if (has(addedField, 'sortIndex')) {
    fields.splice(addedField.sortIndex as number, 0, addedField)
    return fields
  }
  return fields.concat([addedField])
}

// delete field doesn't actually remove the field from the fields array, but instead sets it's visible flag to false
export function deleteField(
  fieldName: string,
  fieldID: string | number | undefined,
  state: VehicleIdentificationFormState,
): VehicleIdentificationFieldDef[] {
  const { fields } = state
  const result     = fields
    .map((f) => {
      if (getFieldID(f.props) === fieldID) {
        const isInitialField = getInitialValue(
          fieldName,
          state.response as VehicleIdentificationResponse,
          fieldID,
        )
        // if the field was not in the original response, then return undefined here so we can filter it out
        if (isInitialField) {
          return {
            ...f,
            requestType : RequestType.DELETE,
            visible     : f.visible === false,
          }
        }
        return undefined
      }
      return f
    })
    .filter((f) => hasValue(f))

  return result as VehicleIdentificationFieldDef[]
}

// instead of sending a delete request for a particular field, this will remove it from the fields array entirely
function removeField(
  fieldName: string | string[],
  fields: VehicleIdentificationFieldDef[],
) {
  const compareFields = typeof fieldName === 'string' ? [fieldName] : fieldName
  return fields.filter((f) => !compareFields.includes(f.props?.name as string))
}
