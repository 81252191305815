import {
  KitCard,
  type KitCardProps,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type ComponentType, type FC, type PropsWithChildren, type ReactNode } from 'react'

import styled, { type StyledComponent } from 'styled-components'

const { fontSize, fontWeight, spacing } = ThemeConstants

export interface HeaderProps {
  children: ReactNode;
  hasBadge?: boolean;
}

// TODO: We likely don't need all the custom card elements here, but changing anything will require a large
// refactor. Like many elements in Fleet, the cards could probably use a refresh
export type StyledFleetCardExtended = ComponentType<typeof KitCard> &
FC<StyledFleetCardProps> & {
  Body: StyledComponent<'div', PropsWithChildren>;
  Header: ComponentType<HeaderProps>;
  Label: StyledComponent<'div', PropsWithChildren>;
  List: StyledComponent<'ul', PropsWithChildren>;
  ListItem: StyledComponent<'li', PropsWithChildren>;
  Value: ComponentType<ValueProps>;
};

export interface StyledFleetCardProps extends KitCardProps {
  isActive?: boolean;
}

export interface ValueProps {
  bold?: boolean;
  children: ReactNode;
  size?: string;
}

const StyledFleetCard = styled(KitCard)<StyledFleetCardProps>`
  pointer-events: ${({ isActive }) => (isActive !== false ? 'auto' : 'none')};
  box-shadow: ${({ theme }) => theme.panel.shadow};
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
  margin-left: 0;
` as unknown as StyledFleetCardExtended

const StyledCardHeader = styled(KitCard.Header)<{ hasBadge?: boolean }>`
  color: ${ThemeColors.gray_90};
  background-color: ${({ theme }) => theme.card.header.bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ hasBadge }) => (hasBadge ? '40px' : 'auto')};
`

const StyledCardBody = styled(KitCard.Body)`
  background-color: #fff;
  padding: ${spacing.sm}rem;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const CardFieldList = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style-type: none;
`

export const CardListItemLabel = styled.div`
  color: ${ThemeColors.gray_70};
  margin-bottom: ${spacing.absolute.xs}px;
  word-break: break-all;
`

export const CardListItemValue = styled.div<ValueProps>`
  color: ${ThemeColors.gray_90};
  margin: -${spacing.absolute.xs}px 0 ${spacing.absolute.s}px;
  line-height: ${({ size }) => (size === 'large' ? fontSize.text_24 : fontSize.base)}rem;
  font-size: ${({ size }) => (size === 'large' ? fontSize.text_24 : fontSize.base)}rem;
  font-weight: ${({ bold }) => (bold ? fontWeight.bold : fontWeight.normal)};
  word-wrap: break-word;
`

const CardFieldListItem = styled.li<{ display: string }>`
  align-items: center;
  color: ${ThemeColors.gray_50};
  display: ${({ display }) => display ?? 'block'};
  font-size: ${fontSize.base}rem;
  padding: ${spacing.absolute.xs}px ${spacing.absolute.xxs}px;
  margin-bottom: ${spacing.absolute.s}px;
  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

StyledFleetCard.Body     = StyledCardBody
StyledFleetCard.Header   = StyledCardHeader
StyledFleetCard.List     = CardFieldList
StyledFleetCard.ListItem = CardFieldListItem
StyledFleetCard.Label    = CardListItemLabel
StyledFleetCard.Value    = CardListItemValue

export default StyledFleetCard
