import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import { getOrgId } from '@/common/utils/user'
import { type NOSContact, type Organization } from '@/models/commonModel'
import { type BaseServiceResponse } from '@/models/serviceModel'

const createService = (api: ServiceInterface) => {
  const baseURL = '/api'
  return {
    async getAdminContacts() {
      return wrapRequest<BaseServiceResponse & NOSContact[]>(
        api.get(`${baseURL}/admin-contacts/`),
      )
    },
    async getOrganization(): Promise<BaseServiceResponse & Organization> {
      const orgId = getOrgId()
      if (!orgId) {
        return new Promise((_, reject) => {
          reject(new Error('cannot get organizations because org_external_id is null'))
        })
      }
      return wrapRequest<BaseServiceResponse & Organization>(
        api.get(`${baseURL}/organizations/${orgId}/`),
      )
    },
  }
}

export default createService(AbstractService())
