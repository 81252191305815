/* eslint-disable max-len */
import type { TFunction } from 'i18next'

import { has, omit } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import type { EnergySettings, FleetOptimizationConfig } from '@/models/energyModel'
import type { Fleet } from '@/models/fleetModel'
import type { FleetOptimization } from '@/pages/Energy/interfaces'

export const getDefaultOptimization = (name: string): FleetOptimizationConfig => ({
  fleet_external_id : '-1',
  fleet_name        : name,
  priority          : 2,
  optimization_type : 'asap',
})

// creates optimization either from Fleet object, or default
export const createOptimization = (item: FleetOptimizationConfig, t: TFunction): FleetOptimization => {
  const optimizationType = item.optimization_type === 'asap' ? 'asap' : 'byDeparture'
  const target           = t(`energy_management.${optimizationType}`)
  const desc             = target

  // omit null values
  return omit({
    desc,
    fleet_external_id : item.fleet_external_id,
    name              : item.fleet_name ?? (item as unknown as Fleet).name,
    priority          : item.priority,
  }, (val) => !hasValue(val)) as unknown as FleetOptimization
}

export const getOptimizationListFromSettingsResponse = (settings: EnergySettings, t: TFunction) => {
  const defaultOptimizations = [
    createOptimization(getDefaultOptimization(t('energy_management.allOthers')), t),
  ]

  const list          = settings.fleet_optimization_config_list
    ? settings.fleet_optimization_config_list.sort((a, b) => (a.priority > b.priority ? -1 : 1))
    : []
  const ids           = list?.map((item) => item.fleet_external_id) ?? []
  const optimizations = list.length ? list.map((item) => createOptimization(item, t)) : defaultOptimizations

  return {
    optimizations,
    ids,
  }
}

export const updateFleetOptimizations = (
  fleetOptimizations: FleetOptimization[],
  currentValue: Partial<FleetOptimization>,
  previousValue: Partial<FleetOptimization> | undefined,
  t: TFunction,
) => {
  const { fleet_external_id } = currentValue
  const match                 = fleetOptimizations.find((opt) => [fleet_external_id].includes(opt.fleet_external_id))
  const result                = fleetOptimizations.filter((opt) => (previousValue && !match ? opt.fleet_external_id !== previousValue.fleet_external_id : true))

  if (!match) {
    result.push(createOptimization(currentValue, t))
    return result
  }

  return result.map((opt) => {
    if ([fleet_external_id].includes(opt.fleet_external_id)) {
      const base = { ...opt, ...currentValue }
      return base
    }
    return opt
  })
}

export const hasDefaultFleetOptimization = (
  optimizations: FleetOptimizationConfig[],
): boolean => Boolean(optimizations.find(({ fleet_external_id }) => parseInt(fleet_external_id) === -1))

export const hasFleetOptimization = (
  optimizations: FleetOptimization[],
): boolean => {
  const filtered = optimizations.filter(
    (optimization) => parseInt(optimization.fleet_external_id) !== -1 && !optimization.delete,
  )
  return Boolean(filtered.length)
}

export const buildEnergySettingsPayload = (fleetOptimizations: FleetOptimization[], energySettings: EnergySettings) => {
  let fleetOptimizationsToSave = fleetOptimizations.concat()
  const allOthersIndex         = fleetOptimizations.findIndex((opt) => opt.fleet_external_id === '-1')
  const [removed]              = fleetOptimizationsToSave.splice(allOthersIndex, 1)
  fleetOptimizationsToSave.push(removed)
  fleetOptimizationsToSave = fleetOptimizationsToSave.filter((opt) => opt !== undefined)

  const priorityCount = fleetOptimizationsToSave.filter((opt) => !has(opt, 'delete')).length

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const fleet_optimization_config_list = fleetOptimizationsToSave.map((item, index) => {
    if (item.delete) {
      return {
        fleet_external_id : item.fleet_external_id,
        delete            : true,
      }
    }
    const priority = priorityCount - index
    const result   = {
      priority,
      fleet_external_id : item.fleet_external_id,
      fleet_name        : item.name,
      ...(item.fleet_external_id === '-1' ? { optimization_type: 'asap' } : { }),
    }

    return result
  })
  if (energySettings.power_management_type === 'none' || !energySettings.power_management_type) {
    return { power_management_type: 'none' }
  }
  return {
    ...energySettings,
    fleet_optimization_config_list,
    sharing_algorithm: 'fleet_optimization',
  }
}
