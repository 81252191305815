import wretch from 'wretch'

import AnalyticsService from './AnalyticsService'
import { analyticEvents } from './AnalyticsService/AnalyticEvents'
import AbstractService, { type ServiceInterface, wrapRequest } from '.'
import { getCSRFTokenHeader } from '@/common/fetch_client'
import { paramsToQueryString } from '@/common/utils'
import {
  type BlockAssignment,
  type BlockAssignmentCRUDRequest,
  type BlockTemplate,
  type EditBlockAssignmentRequest,
} from '@/models/dispatchModel'
import {
  type BaseServiceResponse,
  type PagedServiceResponse,
  type ServicePayload,
  type ServiceResponse,
} from '@/models/serviceModel'

export const baseURL = '/api/schedule'

const createService = (api: ServiceInterface) => ({
  getCombinedBlockAssignments(query: Record<string, unknown>) {
    const uri = `/api/schedule/block-assignment-combined/${paramsToQueryString(
      query,
      true,
    )}`
    return wrapRequest<PagedServiceResponse<BlockAssignment>>(api.get(uri))
  },

  getBlockAssignments(query: Record<string, unknown>) {
    const uri = `/api/schedule/block-assignment/${paramsToQueryString(
      query,
      true,
    )}`
    return wrapRequest<PagedServiceResponse<BlockAssignment>>(api.get(uri))
  },

  downloadBlockAssignments(query: Record<string, unknown>) {
    document.location = `${baseURL}/block-assignment/download/${paramsToQueryString(
      query,
      true,
    )}`
  },
  downloadBlockTemplates(query: Record<string, unknown>) {
    document.location = `${baseURL}/block-template/download/${paramsToQueryString(
      query,
      true,
    )}`
  },

  // Note: This hits the same endpoint as the method below, but I wanted to name it a more explicit name
  // additionally, we will be deprecating the old UI in upcoming sprints.
  createBlockAssignmentFromTemplate(
    request: ServicePayload<BlockAssignmentCRUDRequest>,
  ) {
    return wrapRequest<BaseServiceResponse>(
      api.post(`${baseURL}/block-assignment/add`, request),
    )
  },

  updateBlockAssignment(request: EditBlockAssignmentRequest) {
    const { id, ...rest } = request
    return wrapRequest<BaseServiceResponse>(
      api.put(`${baseURL}/block-assignment/${id}`, rest),
    )
  },

  async addDepotAssignment(assignment: ServicePayload<BlockAssignment>) {
    return wrapRequest<boolean>(
      api.post(`${baseURL}/block-assignment/add`, assignment),
    )
  },

  async deleteDepotAssignment(id: string) {
    return wrapRequest<ServiceResponse<boolean>>(
      api.delete(`${baseURL}/block-assignment/${id}`),
    )
  },

  uploadBlockAssignments(formData: FormData) {
    AnalyticsService.getInstance().trackEvent(analyticEvents.blockAssignmentsUploaded)
    const client = wretch()
    return wrapRequest(
      client
        .headers({ ...getCSRFTokenHeader(), Accept: '*/*' })
        .body(formData)
        .url(`${baseURL}/upload/`)
        .post(),
    )
  },
  uploadBlocks(formData: FormData) {
    AnalyticsService.getInstance().trackEvent(analyticEvents.blockTemplatesUploaded)
    const client = wretch()
    return wrapRequest(
      client
        .headers({ ...getCSRFTokenHeader(), Accept: '*/*' })
        .body(formData)
        .url(`${baseURL}/upload/`)
        .post(),
    )
  },
  async addBlockTemplate(block: Partial<BlockTemplate>) {
    const { id, ...rest } = block
    return wrapRequest<BaseServiceResponse>(
      api.post(`${baseURL}/block-template/add`, rest),
    )
  },
  async updateBlockTemplate(block: Partial<BlockTemplate>) {
    const { id, ...rest } = block
    return wrapRequest<BaseServiceResponse>(
      api.put(`${baseURL}/block-template/${id}`, rest),
    )
  },
  async getBlockTemplates(query: Record<string, unknown>) {
    const uri = `${baseURL}/block-template/${paramsToQueryString(
      query,
      true,
    )}`
    return wrapRequest<PagedServiceResponse<BlockTemplate>>(api.get(uri))
  },
  async deleteBlockTemplate(id: string) {
    return wrapRequest<BaseServiceResponse>(
      api.delete(`${baseURL}/block-template/${id}`),
    )
  },
})

export default createService(AbstractService())
