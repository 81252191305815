export const safeJSONParse = (json: string): Record<string, unknown> => {
  let result = {}
  try {
    if (json) {
      result = JSON.parse(json)
    }
  } catch (err) {
    console.error(err)
  }

  return result
}
