import { ThemeConstants } from '@chargepoint/cp-toolkit'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { UILayout } from '@/common/constants'
import CommonHeader from '@/components/CommonHeader'
import { StyledKitPageTitle } from '@/components/Styled'

const { breakpoints } = ThemeConstants

const SettingsHeaderWrapper = styled.div`
  margin: 0 ${UILayout.hPageMargin}px ${ThemeConstants.spacing.m}rem;

  @media all and (max-width: ${breakpoints.md}px) {
    margin: 0 ${ThemeConstants.spacing.s}rem ${ThemeConstants.spacing.m}rem;
  }
`

const SettingsHeader = () => {
  const { t } = useTranslation()
  return (
    <CommonHeader>
      <SettingsHeaderWrapper>
        <StyledKitPageTitle title={t('fleet_settings.settings_page_title')} />
      </SettingsHeaderWrapper>
    </CommonHeader>
  )
}

export default SettingsHeader
