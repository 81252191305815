import {
  KitFlexRow,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type KeyboardEvent } from 'react'

import styled, { css } from 'styled-components'

import StyledFleetCard from '../../StyledFleetCard'

const { fontSize, fontWeight, spacing } = ThemeConstants

const vehicleCardFontSize = `${fontSize.text_14}rem`

export interface StyledVehicleCardProps {
  'data-id': string;
  tabIndex: number;
  margin?: string;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent<HTMLDivElement>) => void;
  selected?: boolean;
}

export const ContentContainer = styled(KitFlexRow)`
  align-items: start;
`

export const HeaderWithBadge = styled(StyledFleetCard.Header)`
  display: flex;
  justify-content: space-between;
  div {
    min-width: 100px;
  }
  .charging-badge-not_ready {
    opacity: 0.85;
  }

  .ready.isUnplugged {
    font-weight: ${fontWeight.bold};
    background: transparent;
    color: ${ThemeColors.green_50};
  }
`

export const IdentificationListItem = styled.li`
  padding: 0;
`

export const IdentificationListLabel = styled.div`
  color: ${ThemeColors.gray_70};
  font-size: ${fontSize.text_14}rem;
  margin-bottom: ${spacing.absolute.xs}px;
`

export const IdentificationListValue = styled.div<{ bold?: boolean }>`
  color: ${ThemeColors.black};
  margin: -${spacing.absolute.xs}px 0 ${spacing.absolute.xs}px;
  line-height: ${fontSize.text_14}rem;
  font-size: ${vehicleCardFontSize};
  font-weight: ${({ bold }) => (bold ? fontWeight.bold : fontWeight.normal)};
  word-break: break-word;
`

export const StyledVehicleCard = styled(
  StyledFleetCard,
)<StyledVehicleCardProps>`
  background: ${ThemeColors.white};
  min-height: 170px;
  height: 190px;

  ${({ selected }) => selected
    && css`
      outline: 2px solid ${ThemeColors.orange_50};
      box-shadow: 0px 0px 6px 1px ${ThemeColors.orange_50};
    `}
  && {
    min-width: 320px;
    margin-bottom: ${spacing.absolute.xs}px;
  }
`

export const VehicleCardFooter = styled(StyledFleetCard.Label)`
  font-size: ${vehicleCardFontSize};
  margin-top: ${spacing.absolute.s}px;
  position: absolute;
  bottom: ${spacing.absolute.xs}px;
  b {
    font-size: ${vehicleCardFontSize};
  }
`
