/* eslint-disable camelcase */
import { type TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EditScheduleForm from './ScheduleForm'
import { EditMode } from '@/common/constants'
import { FLEET_FLEET_UPDATE } from '@/common/permissions'
import { scheduleToUI } from '@/common/utils/scheduling'
import EditPanel from '@/components/EditPanel'
import ReadOnlySchedule from '@/components/scheduling/settings/ReadOnlySchedule'
import { type FleetSettingsResponse } from '@/models/fleetModel'
import type { ScheduleResponse } from '@/models/scheduleModel'
import scheduleStore from '@/store/fleetVehicleScheduleStore'

interface SchedulingPanelProps {
  fleetExternalID: string;
  schedule: ScheduleResponse;
  refreshSettings: (
    setting: string,
    updateScheduleResponse: Partial<FleetSettingsResponse>
  ) => void;
  t: TFunction;
}
export interface ScheduleProps {

  id?: number;
  t?: TFunction;
}

const SchedulingPanel: FC<SchedulingPanelProps> = ({ fleetExternalID, schedule }) => {
  const [mode, setMode]       = useState(EditMode.READ_ONLY)
  const [isReady, setIsReady] = useState(false)
  const { t }                 = useTranslation()

  const disableSubmit = false
  const { isLoading } = scheduleStore

  const handleSubmit = () => {
    if (scheduleStore.validate(t)) {
      scheduleStore.saveSchedule()
      setMode(EditMode.READ_ONLY)
    }
  }
  const handleCancel = () => {
    setMode(EditMode.READ_ONLY)
    scheduleStore.resetSchedule()
  }

  useEffect(() => {
    if (schedule) {
      scheduleStore.setScheduleResponse(schedule, schedule.schedule_type)
      setIsReady(true)
    }
    scheduleStore.fleetID = fleetExternalID
  }, [schedule])

  useEffect(() => {
    if (scheduleStore.schedule) {
      setIsReady(true)
    }
  }, [scheduleStore.schedule])

  return (
    <EditPanel
      id="scheduling"
      t={t}
      isLoading={isLoading}
      title={t('scheduling')}
      mode={mode}
      disableSubmit={disableSubmit || isLoading}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onToggle={() => {
        setMode(mode === EditMode.READ_ONLY ? EditMode.EDIT : EditMode.READ_ONLY)
      }}
      permissions={FLEET_FLEET_UPDATE}
    >
      { isReady && mode === EditMode.READ_ONLY && (
        <ReadOnlySchedule
          meta={{ showContext: false }}
          schedule={scheduleToUI(scheduleStore.schedule, scheduleStore.scheduleType)}
          scheduleContext={scheduleStore.scheduleContext}
          scheduleType={scheduleStore.scheduleType}
          t={t}
        />
      ) }
      { isReady && mode === EditMode.EDIT && <EditScheduleForm /> }
    </EditPanel>
  )
}

export default observer(SchedulingPanel)
