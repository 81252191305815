import {
  KitButton,
  KitButtonBar,
  KitModal,
  KitModalSize,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import styled from 'styled-components'

const { fontSize, spacing } = ThemeConstants

export interface DialogProps {
  children?: React.ReactNode;
  show: boolean;
  title?: string | null;
  message?: string | React.ReactNode;
  payload?:
  | {
    [key: string]: string;
  }
  | unknown;
  onAction: (actionType: string, payload?: unknown) => void;
  onClose: () => void;
  t: TFunction;
  type?: string;
  confirmText?: string | null;
  cancelText?: string | null;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: ${spacing.absolute.l}px;
`

const Message = styled.p`
  font-size: ${fontSize.base}rem;
  margin-bottom: ${spacing.absolute.l}px;
`

const StyledTitle = styled(KitModal.Title)`
  margin-bottom: ${spacing.absolute.l}px;
`

export const DialogTypes = {
  CONFIRM : 'confirm',
  OK      : 'ok',
}

const Dialog = ({
  cancelText = '',
  children,
  confirmText = '',
  message,
  onAction,
  onClose,
  payload,
  show,
  t,
  title,
  type,
}: DialogProps) => {
  const getButtonBar = (dialogType: string) => {
    if (dialogType === DialogTypes.CONFIRM) {
      return (
        <KitButtonBar
          className="dialog-button-bar"
          primary={
            <KitButton
              data-qa-id="dialog-confirm"
              onClick={() => onAction('ok', payload)}
            >
              { confirmText ?? t('ok') }
            </KitButton>
          }
          secondary={
            <KitButton
              data-qa-id="dialog-cancel"
              variant="secondary"
              onClick={onClose}
            >
              { cancelText ?? t('btn_cancel') }
            </KitButton>
          }
        />
      )
    }
    if (dialogType === DialogTypes.OK) {
      return (
        <KitButtonBar
          className="dialog-button-bar"
          primary={
            <KitButton
              data-qa-id="dialog-confirm"
              onClick={() => onAction('ok', payload)}
            >
              { confirmText ?? t('ok') }
            </KitButton>
          }
          secondary={null}
        />
      )
    }
  }

  return (
    <KitModal
      show={show}
      animation={false}
      size={KitModalSize.sm}
      position="center"
      closeOnClickOutside={false}
      dialogClassName={'fleet-modal-dialog'}
    >
      <Container>
        { title && <StyledTitle data-qa-id="dialog-title">{ title }</StyledTitle> }
        { message && <Message data-qa-id="dialog-message">{ message }</Message> }
        { children }
        { getButtonBar(type as string) }
      </Container>
    </KitModal>
  )
}

Dialog.OK     = 'ok'
Dialog.CANCEL = 'cancel'

Dialog.defaultProps = {
  children : null,
  payload  : null,
  title    : null,
  type     : DialogTypes.CONFIRM,
}

export default Dialog
