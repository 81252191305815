/* eslint-disable import/order */
import { type FC } from 'react'

import { type TFunction } from 'i18next'

import { getTransComponent } from '@/common/utils/componentHelpers'
import Dialog, { DialogTypes } from '@/components/Dialog'
import Notification, { NotificationLevel } from '@/components/Notification'
import { type Fleet } from '@/models/fleetModel'

export interface DeleteFleetModalProps {
  t: TFunction;
  fleet: Fleet;
  onClose: () => void;
  onDelete: () => void;
  serviceError?: string;
  show: boolean;
}

const DeleteFleetModal: FC<DeleteFleetModalProps> = ({
  fleet,
  onClose,
  onDelete,
  serviceError,
  show,
  t,
}) => {
  const vehicleCount  = fleet.vehicles_count
  const deleteEnabled = vehicleCount === 0

  const messageKey = deleteEnabled
    ? 'fleets.settings.dialog_delete_fleet_confirm_message'
    : 'fleets.settings.dialog_message_unable_to_delete_fleet_has_vehicles'

  const vehicleTextPlural = t('vehicle', { count: vehicleCount }).toLocaleLowerCase()
  const dialogMessage     = getTransComponent(messageKey, {
    fleetName    : fleet.name,
    vehicleCount : vehicleCount as unknown as string,
    vehicleTextPlural,
  })

  const handleDialogAction = () => {
    if (deleteEnabled) {
      return onDelete()
    }

    onClose()
  }

  return (
    <Dialog
      type={deleteEnabled ? DialogTypes.CONFIRM : DialogTypes.OK}
      confirmText={t('ok')}
      cancelText={t('btn_cancel')}
      message={dialogMessage}
      onClose={onClose}
      onAction={handleDialogAction}
      show={show}
      t={t}
      title={t('fleets.settings.dialog_delete_fleet_title')}
    >
      { serviceError && (
        <Notification type={NotificationLevel.ERROR} message={serviceError} />
      ) }
    </Dialog>
  )
}

export default DeleteFleetModal
