import { type TFunction } from 'i18next'

import { round } from '@/common/utils'
import { SortDir, numericSort } from '@/common/utils/data'
import { SeriesType } from '@/components/Charting/common/constants'
import { type ChartElementProps } from '@/components/Charting/common/types'
import { type TimeSeriesData, type TimeSeriesResponse } from '@/models/chartModel'

export interface SeriesProps {
  fieldName: string;
  extendValue?: boolean;
  props: Partial<ChartElementProps>;
}

// Note: this is not a 'pure' function, and modifies the chartData variable
export const mapApiResponseToChartSeries = (
  apiResponse: TimeSeriesResponse,
  seriesProps: SeriesProps,
  chartData: TimeSeriesData[],
  t: TFunction,
) => {
  const { extendValue, fieldName } = seriesProps
  let chartDataModfied             = false

  if (apiResponse.error) {
    return { chartData }
  }

  if (chartData?.length && apiResponse?.length) {
    apiResponse.forEach((item) => {
      const d = new Date(item.time)
      d.setMinutes(round(d.getMinutes(), 5))
      d.setSeconds(0)
      d.setMilliseconds(0)

      const ts               = d.getTime()
      let val: number | null = item.data[0]?.value
      const matchingRecord   = chartData.find((r) => r.timestamp === ts)

      // TimeStream db does not support null values, so they are setting 'nulls' to -1
      // We convert the -1 values to null in UI as that will produce expected behavior in the chart
      if (val === -1) {
        val = null
      }

      if (matchingRecord) {
        matchingRecord[fieldName] = val
      } else {
        chartDataModfied = true
        chartData.push({
          timestamp   : ts,
          [fieldName] : val,
        })
      }
    })

    if (extendValue) {
      if (chartDataModfied) {
        chartData.sort(numericSort('timestamp', SortDir.ASC))
      }
      // this extends the last value we find in the series to the end of the chart
      const endVal = apiResponse[apiResponse.length - 1].data?.[0].value
      // eslint-disable-next-line no-param-reassign
      chartData[chartData.length - 1][fieldName] = endVal
    }
  }

  const chartSeriesProps = {
    active        : true,
    connectNulls  : true,
    dataKey       : fieldName,
    dot           : false,
    strokeWidth   : 1,
    strokeOpacity : 1,
    seriesType    : SeriesType.Line,
    unit          : t('units.kilowatt.short'),
    ...(seriesProps?.props ?? {}),
  }

  return {
    chartData,
    series: chartSeriesProps,
  }
}
