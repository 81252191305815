import {
  KitConstants,
  KitFlexColCentered,
  KitFlexRowCentered,
  KitLink,
  KitUtilCommon,
} from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'
import { type Dispatch, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'

import fleetStore from '../../../store/fleetStore'
import { AppRoutes } from '@/common/constants'
import utils from '@/common/utils'
import { alphaNumericSort } from '@/common/utils/data'
import {
  type FleetWebSocketCallbackPayload,
  useWebSocket,
} from '@/common/websocket'
import NoResults from '@/components/NoResults'
import Page from '@/components/Page'
import { CardContainer, CardLayout, Container } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import VehicleCard from '@/components/Vehicle/VehicleCard'
import { type Vehicle } from '@/models/vehicleModel'
import { MessageEventType, type MessageEventTypeHandler } from '@/types/websocket'

const VehicleCards = (): JSX.Element => {
  const { t }                 = useTranslation()
  const navigate              = useNavigate()
  const [data, setData]       = useState<Vehicle[]>()
  const [refresh, setRefresh] = useState(false)

  const context  = useOutletContext<{ dispatch: Dispatch<unknown> }>()
  const dispatch = context?.dispatch

  const filteredData = utils.data
    .filterData(data as unknown[], fleetStore.filters)
    ?.sort(alphaNumericSort('name')) as Vehicle[]

  const selectVehicle = (vehicle: Partial<Vehicle>) => {
    const vehicleBasePath = AppRoutes.VehiclePropSheet.basePath
    const toPath          = `${vehicleBasePath}/${vehicle.external_id}`
    navigate(toPath, { state: { path: document.location.pathname } })
  }

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      KitUtilCommon.isKey(
        [KitConstants.KeyConstants.ENTER, KitConstants.KeyConstants.SPACE],
        ev as unknown as KeyboardEvent,
      )
    ) {
      selectVehicle({ id: ev.currentTarget.getAttribute('data-id') as string })
    }
  }

  const dataRef   = useRef<Vehicle[]>()
  dataRef.current = data as Vehicle[]

  const handleCreate  = (payload: Partial<Vehicle>) => fleetStore.addRecord(payload)
  const handleUpdate  = (payload: Partial<Vehicle>) => fleetStore.updateRecord(payload)
  const handleDelete  = (payload: Vehicle) => fleetStore.deleteRecord(payload)
  const handleRefresh = () => setRefresh(true)

  const eventHandlerMap: Record<
  MessageEventType,
  MessageEventTypeHandler<Vehicle>
  > = {
    [MessageEventType.UPDATE]  : handleUpdate,
    [MessageEventType.DELETE]  : handleDelete,
    [MessageEventType.CREATE]  : handleCreate,
    [MessageEventType.REFRESH] : handleRefresh,
    [MessageEventType.CLEAR]   : () => null,
  }

  useWebSocket('/ws/vehicleCard/', {
    onMessage: ({ content, event_type }: FleetWebSocketCallbackPayload) => {
      eventHandlerMap[event_type as MessageEventType](
        content as unknown as Vehicle,
      )
    },
  })

  useEffect(() => {
    if (!fleetStore.vehicles) {
      fleetStore.init()
    } else {
      setData(fleetStore.vehicles)
    }
  }, [fleetStore.vehicles, refresh])

  let content

  if (fleetStore.serviceError) {
    content = (
      <KitFlexRowCentered style={{ width: '100%' }}>
        <KitFlexColCentered>
          <NoResults message={t('errors.vehicles_request_error')} />
        </KitFlexColCentered>
      </KitFlexRowCentered>
    )
  }

  if (!fleetStore.isFetching) {
    if (!fleetStore.hasResults) {
      content = (
        <Container className="fade-in">
          <Trans
            i18nKey="fleets.get_started_vehicle"
            components={{
              btn: (
                <KitLink
                  onClick={() => dispatch({ type: 'SHOW_WIZARD', open: true })}
                />
              ),
            }}
          >
            { /* @ts-expect-error  - ts does not play nicely with Trans component when custom components are used */ }
            Get started by adding a <btn>vehicle</btn>.
          </Trans>
        </Container>
      )
    } else if (fleetStore.hasResults && filteredData?.length === 0) {
      content = t('no_results')
    } else if (filteredData?.length) {
      content = (
        <CardContainer data-qa-id="vehicle_cards_container">
          <CardLayout>
            { filteredData.map((item) => (
              <VehicleCard
                key={item.external_id}
                {...item}
                t={t}
                onKeyPress={handleKeyPress}
                onClick={() => selectVehicle(item)}
              />
            )) }
          </CardLayout>
        </CardContainer>
      )
    }
  } else {
    content = <StyledSpinner />
  }

  return <Page title={t('page_titles.vehicle_list')}>{ content }</Page>
}

export default observer(VehicleCards)
