/* Charting & DataViz utils */
import { ChartColors, SeriesType } from './constants'
import { type ChartElementProps } from './types'
import { cleanKey, groupBy } from '@/common/utils/data'
import { type TimeSeriesData } from '@/models/chartModel'

// returns the record with the closest timestamp to the one passed
export const findNearestByTimeStamp = (
  data: TimeSeriesData[],
  timestamp: number,
) => {
  if (!data || !timestamp) {
    return null
  }

  let nearest            = Infinity
  let result
  const timeStampToMatch = new Date(timestamp).getTime()
  data.forEach((o) => {
    if (Math.abs(timeStampToMatch - o.timestamp) < nearest) {
      nearest = o.timestamp
      result  = o
    }
  })

  return result
}

// returns all unique values in a dataset (based on the value of the field param)
export const getOrdinalRange = (data: unknown[], field: string): string[] => {
  const grouped = groupBy(data, field)
  return Object.keys(grouped)
}

// creates a unique data key for plotting based on the value of another field in the dataset
export const createSeriesDataKey = <T = Record<string, unknown>>(
  record: T,
  valueField: string,
  groupByField: string,
) => {
  const field = cleanKey(record[groupByField as keyof T] as string)
  const key   = `${valueField}_${field}`
  return key
}

export function getSeriesProps(
  series: ChartElementProps,
  hiddenSeries: string[],
) {
  let props = {}
  if (hiddenSeries.includes(series?.dataKey as string)) {
    props = [SeriesType.Bar].includes(series.seriesType as SeriesType)
      ? { fill: ChartColors.gray }
      : { stroke: ChartColors.gray }
  } else {
    props = [SeriesType.Bar].includes(series.seriesType as SeriesType)
      ? { fill: series.stroke }
      : { stroke: series.fill }
  }

  return props
}
