import { KitLink, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import styled from 'styled-components'

import { type WizardScreenProps } from '../config'
import { WizardContent } from '@/components/AddFleetWizard/styled'
import FleetIcon from '@/components/FleetIcon'
import { Description, FadeInContainer } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'

const ImgContainer = styled.div`
  margin-top: ${ThemeConstants.spacing.absolute.l}px;
`

const FleetGraphic = ({ t }: { t: TFunction }) => (
  <ImgContainer>
    <FleetIcon
      style={{ width: '150px', height: '75px' }}
      iconType="deliveryTruck"
      altText={t('vehicle_image_alt_text', { vehicle_type: t('delivery_truck') })}
    />
  </ImgContainer>
)

const NoFleetFound: FC<Partial<WizardScreenProps>> = ({
  externalAction,
  t,
}) => {
  const [isWaiting, setIsWaiting] = useState(true)

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setIsWaiting(false)
    }, 800)

    return () => window.clearTimeout(timeoutID)
  }, [])

  if (isWaiting) {
    return (
      <WizardContent>
        <StyledSpinner />
      </WizardContent>
    )
  }
  return (
    <Description>
      <FadeInContainer style={{ animationDelay: '1s' }}>
        <Trans
          i18nKey="fleets.add_fleet_before_vehicle"
          components={{ btn: <KitLink onClick={() => externalAction?.('ADD_FLEET')} /> }}
        >
          { /* @ts-expect-error -- figure out how to make Trans component happy */ }
          <btn>Add a fleet</btn> before adding a vehicle.
        </Trans>
        <br />
        <FleetGraphic t={t as TFunction} />
      </FadeInContainer>
    </Description>
  )
}

export default NoFleetFound
