import { KitUtilData } from '@chargepoint/cp-toolkit'
import { flow, makeAutoObservable } from 'mobx'

import utils from '@/common/utils'
import { mapAlertsToFleets } from '@/common/utils/alerts'
import { alphaNumericSort, has } from '@/common/utils/data'

import { type Alert } from '@/models/alertModel'
import { type Fleet } from '@/models/fleetModel'
import { type ServiceError } from '@/models/serviceModel'
import type { type Vehicle, VehicleCardWSEvent } from '@/models/vehicleModel'
import AlertService from '@/services/AlertService'
import FleetService from '@/services/FleetService'
import appStore from '@/store/AppStore'
import { type Filter } from '@/types/filters'

// FleetStore is used by the Fleet / Vehicle card list pages

class FleetStore {
  selectedFleetID?: string
  selectedFleet?: Fleet
  view = 'fleets'
  vehicles: Vehicle[] = []
  alerts: Alert[] = []
  fleets: Fleet[] = []
  filters: Filter[] = []
  isFetching = true
  serviceError?: ServiceError

  constructor() {
    makeAutoObservable(this)
  }

  // view: string
  init = flow(function* init(this: FleetStore) {
    let serviceError
    if (this.view === 'fleets') {
      if (KitUtilData.isEmpty(this.fleets)) {
        this.fetchFleets()
      }
    } else if (this.view === 'vehicles') {
      if (KitUtilData.isEmpty(this.vehicles)) {
        this.fetchVehicles()
      }
    }

    if (this.selectedFleetID) {
      const fleetResponse = yield FleetService.getFleet(this.selectedFleetID)
      if (fleetResponse.error) {
        serviceError = fleetResponse.error
        appStore.setErrors(serviceError)
        throw new Error(fleetResponse.error)
      } else {
        this.selectedFleet = fleetResponse
      }
    }

    this.serviceError = serviceError
  })

  fetchFleets = flow(function* fetchFleets(this: FleetStore) {
    this.isFetching          = true
    const { error, results } = yield FleetService.getFleets()

    // Todo: investigate using an AbortController to cancel this request if the view changes
    // This would require a refactor of the FleetService to return a Promise instead of a wrapped Promise
    if (this.view !== 'fleets') {
      return
    }

    if (results) {
      this.fleets = results
      this.fetchAlerts()
    }

    this.serviceError = error
    this.isFetching   = false
  })

  fetchVehicles = flow(function* fetchVehicles(this: FleetStore) {
    this.isFetching          = true
    const { error, results } = yield FleetService.getVehicles(
      this.selectedFleetID,
    )
    // Todo: investigate using an AbortController to cancel this request if the view changes
    if (this.view !== 'vehicles') {
      return
    }

    this.vehicles     = results
    this.serviceError = error
    this.isFetching   = false
  })

  refresh() {
    this.reset()
    this.init()
  }

  fetchAlerts = flow(function* fetchAlerts(this: FleetStore) {
    if (KitUtilData.isEmpty(this.alerts)) {
      const { error, results } = yield AlertService.getAlertList({ event_type: 'active' })
      if (results) {
        this.alerts = results
        this.fleets = mapAlertsToFleets(results, this.fleets, true)
      } else if (error) {
        this.serviceError = { message: 'errors.list_request_error' }
      }
    }
  })

  setActiveFilters(filters: Filter[]) {
    this.filters = filters
  }

  setView(view: string, fleetID?: string) {
    if (this.selectedFleetID !== fleetID) {
      this.vehicles = []
    }

    this.view            = view
    this.selectedFleetID = fleetID
    this.init()
  }

  reset() {
    this.fleets   = []
    this.vehicles = []
  }

  private get items() {
    if (this.view === 'fleets') {
      return this.fleets
    }
    return this.vehicles
  }

  private set items(data: (Vehicle | Fleet)[]) {
    if (this.view === 'fleets') {
      this.fleets = data as Fleet[]
    } else {
      this.vehicles = data as Vehicle[]
    }
  }

  addRecord(record: Partial<Fleet> | Partial<Vehicle>) {
    if (this.selectedFleetID && has(record, 'fleet_data') && this.selectedFleetID !== (record as VehicleCardWSEvent).fleet_data?.external_id) {
      // if we are on a fleet prop sheet page,
      // ignore addRecord calls for any vehicle that does not belong to the currently selected Fleet
      return
    }
    this.items = utils.data.addItem(this.items, record) as (Vehicle | Fleet)[]
    this.init()
  }

  deleteRecord(record: Partial<Fleet> | Partial<Vehicle>) {
    this.items = this.items.filter((item) => item.external_id !== record.external_id)
  }

  updateRecord(record: Partial<Fleet> | Partial<Vehicle>) {
    this.items = utils.data.updateItem(this.items, record, 'external_id') as (Vehicle | Fleet)[]
  }

  get hasResults() {
    if (this.view === 'fleets') {
      return this.fleets.length > 0
    } if (this.view === 'vehicles') {
      return this.vehicles.length > 0
    }
    return false
  }

  get filteredData() {
    if (this.view === 'fleets') {
      return utils.data
        .filterData(this.fleets, this.filters)
        ?.sort(alphaNumericSort('name'))
    }
    return utils.data.filterData(this.vehicles, this.filters)
  }

  get results() {
    return this.view === 'fleets' ? this.fleets : this.vehicles
  }
}

const fleetStore = new FleetStore()
export default fleetStore
