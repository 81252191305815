import { KitIcon, ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import classnames from 'classnames'
import styled, { keyframes } from 'styled-components'

import theme from '@/common/fleet-theme'

const { fontSize, iconSize, spacing } = ThemeConstants

export enum NotificationIconType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'warning',
  SUCCESS = 'check',
}

export enum NotificationLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

const fadeInAnim = keyframes`
  0% {
      opacity: 0
  }
  100% {
    opacity: 1
  }
`

const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${spacing.absolute.s}px 0;
  padding: ${spacing.absolute.m}px;

  color: ${ThemeColors.gray_50};
  border: 1px solid ${ThemeColors.gray_20};
  background: ${ThemeColors.gray_10};
  transition: all 1s;

  &.info {
    background: ${theme.colors.alert_info_bg};
    border: 1px solid ${theme.colors.alert_info_border};
  }

  &.warning {
    background: ${theme.colors.alert_warning_bg};
    border: 1px solid ${theme.colors.alert_warning_border};
  }

  &.error {
    background: ${theme.colors.alert_error_bg};
    border: 1px solid ${theme.colors.alert_error_border};
  }

  &.success {
    background: ${theme.colors.alert_success_background};
    border: 1px solid ${theme.colors.alert_success_border};
  }

  &.full-size {
    margin: 0px 0px;
  }

  &.small {
    padding: ${spacing.absolute.m}px;
    font-size: ${fontSize.text_14}rem;
  }

  &.transparent {
    background: none;
    border: 1px solid ${ThemeColors.gray_30};
  }

  &.fade-in {
    opacity: 0;
    animation: ${fadeInAnim} 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }
`

const IconWrapper = styled.div`
  width: 30px;
  display: inline-block;
  margin-right: ${spacing.absolute.xs}px;
  margin-top: ${spacing.xs}rem;
`

const Message = styled.div`
  line-height: ${fontSize.text_24}rem;
  color: ${({ theme }) => theme.page.body.headerText};
`

const Details = styled.div`
  line-height: ${fontSize.base}rem;
`

const TextWrapper = styled.div`
  display: inline-block;
`

type NotificationProps = {
  children?: React.ReactNode;
  className?: string;
  details?: string;
  fadeIn?: boolean;
  icon?: NotificationIconType;
  message?: string;
  mode?: 'transparent' | 'default';
  size?: string;
  style?: {
    [key: string]: string;
  };
  type:
  | NotificationLevel.INFO
  | NotificationLevel.ERROR
  | NotificationLevel.SUCCESS
  | NotificationLevel.WARNING;
};

const Notification = (props: NotificationProps) => {
  const {
    children,
    className,
    details,
    fadeIn,
    icon,
    message,
    size,
    style,
    type,
  } = props

  const containerSize = size ?? 'default'
  const { mode }      = props
  //  theme.colors[`alert_${props.type}_border`];
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const iconFill         = theme.colors[`alert_${type}_text`]
  const notificationIcon =    icon
    || {
      success : 'check-bold',
      info    : 'information-outline',
      warning : 'warning',
      error   : 'warning',
    }[type]
    || 'information-outline'

  return (
    <NotificationWrapper
      aria-live="polite"
      style={style ?? {}}
      className={classnames(type, className, {
        'full-size'      : size === 'full-size',
        'fade-in'        : fadeIn,
        [containerSize]  : containerSize,
        [mode as string] : mode,
      })}
    >
      { type && (
        <IconWrapper>
          <KitIcon
            fill={iconFill}
            size={`${iconSize.sm}rem`}
            icon={notificationIcon}
          />
        </IconWrapper>
      ) }
      { children && children }
      { !children && message && (
        <TextWrapper>
          <Message dangerouslySetInnerHTML={{ __html: message }} />
          { details && <Details dangerouslySetInnerHTML={{ __html: details }} /> }
        </TextWrapper>
      ) }
    </NotificationWrapper>
  )
}

Notification.defaultProps = {
  children : null,
  details  : null,
  icon     : null,
  fadeIn   : true,
  message  : null,
  mode     : 'default',
  size     : 'full-size',
  style    : {},
}

export default Notification
