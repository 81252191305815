import { type TFunction } from 'i18next'
import { observer } from 'mobx-react-lite'
import { type Dispatch, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { FilterActions } from '@/common/reducers'
import utils from '@/common/utils'
import FilterSearchBar from '@/components/FilterSearchBar'
import { IconButton } from '@/components/IconButton'
import { StyledKitPageTitle, SubHeaderContainer } from '@/components/Styled'
import TabNav, { type TabItemProps } from '@/components/TabNav'
import appStore from '@/store/AppStore'
import { type Filter } from '@/types/filters'

export interface FleetDetailsHeaderProps {
  dispatch: Dispatch<unknown>;
  filters: Filter[];
  filterComponents?: React.ReactNode;
  fleet?: {
    name?: string;
  };
  navigateBack: () => void;
  onSearch: (...args: unknown[]) => void;
  t: TFunction;
  numMatches: number;
  showAddVehicleWizard?: boolean;
  showFiltersBar: boolean;
  filtersPanelOpen?: boolean;
}

const FleetDetailsHeader = ({
  dispatch,
  filterComponents,
  filters,
  filtersPanelOpen,
  fleet,
  navigateBack,
  numMatches,
  onSearch,
  showAddVehicleWizard,
  showFiltersBar,
  t,
}: FleetDetailsHeaderProps): JSX.Element => {
  const tabs: (TabItemProps & { filters?: boolean })[] = [
    {
      id      : 'vehicles',
      qaId    : 'vehicles-tab',
      label   : t('vehicle', { count: 0 }),
      path    : '',
      filters : true,
    },
    {
      id    : 'settings',
      qaId  : 'settings-tab',
      label : t('settings'),
      path  : 'settings',
    },
  ]

  const searchPlaceHolder = t('fleets.vehicle_search_placeholder')

  const navigate = useNavigate()

  const match = useMatch('/fleets/:fleet_external_id/*')
  const view  = utils.getLastURLPath(window.location.href)
  const title = fleet ? fleet.name : t('unknown')

  const [selectedTab, setSelectedTab] = useState(
    tabs.find((f) => [f.path, match?.params.fleet_external_id].includes(view)) ?? tabs[0],
  )

  const handleTabChange = (tab: TabItemProps) => {
    setSelectedTab(tab)
    navigate(`${match?.pathnameBase}/${tab.path}`)
    appStore.clearErrors()
  }

  return (
    <SubHeaderContainer>
      <StyledKitPageTitle
        ariaLabelForBackButton={t('fleets.aria_back_to_fleet_list')}
        category={t('fleet', { count: 1 })}
        title={title as string}
        displayBack
        onClickBack={navigateBack}
      />

      <TabNav tabs={tabs} onChange={handleTabChange} />

      { showFiltersBar && selectedTab.filters && (
        <FilterSearchBar
          filters={filters}
          onSearch={onSearch}
          numMatches={numMatches}
          searchPlaceHolder={searchPlaceHolder}
          filtersPanelOpen={filtersPanelOpen as boolean}
          actionButton={
            showAddVehicleWizard && (
              <IconButton
                icon="plus"
                onClick={() => dispatch({ type: 'SHOW_WIZARD', open: true })}
              >
                { t('fleets.add_vehicle') }
              </IconButton>
            )
          }
          setFiltersPanelOpen={() => FilterActions.toggleFiltersOpen(dispatch)}
          filterComponent={filterComponents}
          t={t}
        />
      ) }
    </SubHeaderContainer>
  )
}

FleetDetailsHeader.defaultProps = {
  fleet                : null,
  filterComponents     : null,
  showAddVehicleWizard : false,
}

export default observer(FleetDetailsHeader)
