import { ThemeConstants } from '@chargepoint/cp-toolkit'
import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { List, ListItem } from '@/components/Styled'
import StyledFleetCard from '@/components/StyledFleetCard'

const { fontSize } = ThemeConstants

const StyledLabel = styled(StyledFleetCard.Label)<{ children: ReactNode }>`
  fontsize: ${fontSize.text_14}rem;
`

const StyledValue = styled(StyledFleetCard.Value)<{
  children: JSX.Element | JSX.Element[] | ReactNode;
  size?: string;
}>`
  margin-bottom: 0;
`

const StyledListItem = styled(ListItem)`
  padding-left: 0;
  padding-top: 0;
  &:last-child {
    padding-bottom: 0;
  }
`

function Detail(props: { header: string; number: number }) {
  return (
    <StyledListItem>
      <StyledLabel>{ props.header }</StyledLabel>
      <StyledValue
        size="large"
        data-qa-id={`${
          props.header.toLowerCase() === 'ports'
            ? 'port_count_value'
            : 'plugged_in_vehicle_count_value'
        }`}
      >
        { props.number }
      </StyledValue>
    </StyledListItem>
  )
}

function DepotCardDetails(props: {
  depot_is_active: boolean;
  vehicles_plugged_in: number;
  ports: number;
}) {
  const { t } = useTranslation()
  if (!props.depot_is_active) {
    return (
      <List listType="none">
        <Detail header={t('ports_planned')} number={props.ports} />
      </List>
    )
  }

  return (
    <List listType="none">
      { Object.entries(props)
        .filter(([key]) => !['depot_is_active', 'ports'].includes(key))
        .map(([key, val], index) => <Detail header={t(key)} number={val as number} key={index} />) }
      <Detail
        header={t('port_plural', { count: props.ports })}
        number={props.ports}
        key="ports"
      />
    </List>
  )
}

export default DepotCardDetails
