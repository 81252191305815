/**
 * TODO: The logic on this page could be cleaned up significantly,
 * there are too many nested hierarchies that are really messy to type.
 *
 * Before we can fix all the Typescript warnings/errors on this page, a refactor will be necessary
 */
import {
  KitButton,
  KitFlexRowSpaced,
  KitModal,
  KitModalSize,
  KitToast,
  KitToastTypeOptions,
  ThemeColors,
  ThemeConstants,
  useToast,
} from '@chargepoint/cp-toolkit'
import { format, parseISO } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { type FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components'

import { SitePowerFields } from '../../types'
import { getRange, parseRange } from './config'
import EnergyChartControls from './EnergyChartControls'
import EnergyDetailsGraph from './EnergyDetailsGraph'
import SiteGroupSettingsTable from './SiteGroupSettingsTable'
import SiteDetailsStore from './siteStore'
import { AppRoutes, ISO_DATE_TIME, Period } from '@/common/constants'
import { getError } from '@/common/utils'
import { sum } from '@/common/utils/data'
import { type DateRangeQuery, filterDateQuery } from '@/common/utils/date'
import { hasValue } from '@/common/utils/validations'
import { useWebSocket } from '@/common/websocket'
import ChartSizeToggleButton from '@/components/Charting/ChartComponents/ChartSizeToggle'
import TabbedChartContainer from '@/components/Charting/ChartComponents/TabbedChartContainer'
import ErrorBoundary from '@/components/ErrorBoundary'
import Notification, { NotificationLevel } from '@/components/Notification'
import Page from '@/components/Page'
import {
  PageContainer,
  StyledErrorMessage,
  StyledKitPageTitle,
  SubHeaderContainer,
} from '@/components/Styled'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { type EnergyChartQuery } from '@/models/chartModel'
import {
  type EnergySettings as EnergySettingsInterface,
  type PowerManagementType,
  type Site,
  type StationGroup,
} from '@/models/energyModel'
import {
  type ServicePayload,
  type ServiceResponse,
} from '@/models/serviceModel'
import EnergySettingsModal from '@/pages/Energy/routes/SitePropSheet/SiteEnergySettingsModal'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import EnergyManagementService from '@/services/EnergyManagementService'
import { type DateRange } from '@/types/index'
import { MessageEventType, type MessagePayload } from '@/types/websocket'

const { breakpoints, spacing } = ThemeConstants

const EnergyChartsWrapper = styled.div`
  background: ${ThemeColors.white};
  padding: ${spacing.absolute.m}px ${spacing.absolute.m}px 0;
  margin: ${spacing.absolute.s}px 0;
  box-shadow: 0px 2px 6px 0px rgb(51 50 49 / 30%);
`

const EnergyChartsContainer = styled.div`
  display: flex;
  gap: ${spacing.absolute.sm}px;
  > div {
    flex: 1;
    min-width: 48%;
  }
  @media all and (max-width: ${breakpoints.lg}px) {
    flex-direction: column;
  }
  position: relative;
`

const PaddedStyledKitPageTitle = styled(StyledKitPageTitle)`
  margin-bottom: ${spacing.m}rem;
`

const toRange = (
  query: DateRangeQuery,
  parse: boolean,
): DateRange | string[] => {
  if (parse) {
    return [parseISO(query.start_time), parseISO(query.end_time)]
  }
  return [query.start_time, query.end_time]
}

const rangeToQuery = (range: DateRange): DateRangeQuery => {
  const parsedRange = parseRange(range)
  return {
    start_time : format(parsedRange[0], ISO_DATE_TIME),
    end_time   : format(parsedRange[1], ISO_DATE_TIME),
  }
}

const EnergyDetailsView: FC<unknown> = () => {
  const defaultDuration                             = Period.LAST_24_HOURS
  const { t }                                       = useTranslation()
  const navigate                                    = useNavigate()
  const { site_id: siteID }                         = useParams()
  const [settingsVisibility, setSettingsVisibility] = useState(false)

  const [selectedRange, setSelectedRange] = useState(getRange(defaultDuration))
  const [duration, setDuration]           = useState<Period>(defaultDuration)

  const isLoading        = !SiteDetailsStore.initialized || SiteDetailsStore.isFetching
  const stationGroupData = SiteDetailsStore.stationGroups

  const [chartFilters, setChartFilters]   = useState<EnergyChartQuery>({
    site_ids   : [siteID as string],
    start_time : selectedRange[0] as string,
    end_time   : selectedRange[1] as string,
  })
  const [chartSettings, setChartSettings] = useState({
    group1: {
      selectedChart    : SitePowerFields.STATION_GROUP_NAME,
      selectChartIndex : 0,
      expanded         : false,
    },
    group2: {
      selectedChart    : SitePowerFields.FLEET_NAME,
      selectChartIndex : 0,
      expanded         : false,
    },
  })

  const { analyticsService } = useAnalyticsService()

  const powerManagementType = SiteDetailsStore.site?.power_management_type as PowerManagementType

  const chartTabs = {
    group1: [
      {
        label : t('energy_management.chart_controls.by_group'),
        field : SitePowerFields.STATION_GROUP_NAME,
      },
      {
        label : t('energy_management.chart_controls.by_port'),
        field : SitePowerFields.PORT_NAME,
      },
    ],
    group2: [
      {
        label : t('energy_management.chart_controls.by_fleet'),
        field : SitePowerFields.FLEET_NAME,
      },
      {
        label : t('energy_management.chart_controls.by_vehicle'),
        field : SitePowerFields.VEHICLE_NAME,
      },
    ],
  }

  const timezone: string = stationGroupData?.site?.timezone ?? ''

  const navigateBack = () => {
    navigate(AppRoutes.Energy.path)
  }

  const handleChartResize = (chartGroup: string) => {
    const grp = chartSettings[chartGroup as keyof typeof chartSettings]
    setChartSettings({
      ...chartSettings,
      [chartGroup]: {
        ...grp,
        expanded: !grp.expanded,
      },
    })

    setTimeout(() => {
      if (!grp.expanded) {
        window.scrollTo(0, 260)
      } else {
        window.scrollTo(0, 0)
      }
    }, 200)
  }

  const handleChartTabChange = (
    chartGroup: keyof typeof chartTabs,
    tabIndex: number,
  ) => {
    const selectedChart = chartTabs[chartGroup][tabIndex].field
    const grp           = chartSettings[chartGroup]
    setChartSettings({
      ...chartSettings,
      [chartGroup]: {
        ...grp,
        selectedChart,
        selectChartIndex: tabIndex,
      },
    })

    analyticsService.trackEvent(analyticEvents.siteEnergyChartQueryUpdated, { selectedChart })
  }

  const handleChartFilterChange = ({
    filterName,
    filterValue,
    range,
  }: {
    filterName: string;
    filterValue: Period | DateRange;
    range?: DateRange;
  }) => {
    let changedFilters = { ...chartFilters }
    const queryParam   = {
      fleet        : 'fleet_ids',
      stationGroup : 'site_ids',
      duration     : ['start_time', 'end_time'],
    }[filterName]

    const filterMap = {
      dateRange : () => rangeToQuery(filterValue as DateRange),
      duration  : () => {
        if (filterValue === Period.CUSTOM && range) {
          const query = rangeToQuery(range as DateRange)
          return filterDateQuery({
            period : filterValue,
            start  : query.start_time,
            end    : query.end_time,
          })
        }
        const normalizedRange =          range ?? (getRange(filterValue as Period) as DateRange)
        return {
          start_time : normalizedRange[0],
          end_time   : normalizedRange[1],
        }
      },
      fleet        : () => (hasValue(filterValue) ? { fleet_ids: [filterValue] } : {}),
      stationGroup : () => ({ site_ids: [filterValue] }),
    }

    const params = { ...filterMap[filterName as keyof typeof filterMap]() }

    if (!hasValue(filterValue)) {
      if (Array.isArray(queryParam)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(queryParam).forEach(([key, val]) => {
          delete changedFilters[val as keyof typeof changedFilters]
        })
      } else {
        delete changedFilters[queryParam as keyof typeof changedFilters]
      }
    } else {
      changedFilters = { ...changedFilters, ...params } as EnergyChartQuery
    }

    if (filterName === 'duration') {
      if (filterValue === Period.CUSTOM) {
        const parsedRange = range
          ? parseRange(range)
          : toRange(
            filterDateQuery({ period: defaultDuration }) as DateRangeQuery,
            true,
          )
        setSelectedRange(parsedRange)
      }
      setDuration(filterValue as Period)
      setSelectedRange(getRange(filterValue))
    } else if (filterName === 'dateRange') {
      setSelectedRange(parseRange(filterValue as DateRange))
    }

    setChartFilters(changedFilters)

    analyticsService.trackEvent(analyticEvents.siteEnergyChartQueryUpdated, { [filterName]: filterValue })
  }

  const handleCancelSettings = () => {
    setSettingsVisibility(false)
  }

  const saveSettings = async (
    siteId: string,
    settings: EnergySettingsInterface,
  ) => {
    const response = await EnergyManagementService.updateSettings(
      siteId,
      settings as unknown as ServicePayload<EnergySettingsInterface>,
    )
    if (response.error) {
      const errMessage =  getError(response as ServiceResponse<unknown>)
      useToast({
        t,
        toastType : KitToastTypeOptions.ERROR,
        message   : hasValue(errMessage) ? errMessage : t('errors.failed_to_save_settings', { error: '' }),
      })
    } else {
      SiteDetailsStore.updateSite({ power_management_type: settings.power_management_type })
      SiteDetailsStore.updateSiteSettings({ ...response })
      setSettingsVisibility(false)
      analyticsService.trackEvent(analyticEvents.siteEnergySettingsUpdated)
    }
  }

  const updateAggregatePower = ({
    aggregate_power,
    external_id,
  }: Pick<StationGroup, 'aggregate_power' | 'external_id'>) => {
    let siteAggregatePower

    if (SiteDetailsStore.stationGroups) {
      const powerValues = SiteDetailsStore.stationGroups
        .map((o) => o.aggregate_power)
        .filter((v) => !Number.isNaN(v))
      SiteDetailsStore.updateStationGroup(external_id, { aggregate_power })
      if (powerValues?.length) {
        siteAggregatePower = sum(powerValues)
      }
      if (SiteDetailsStore.siteID !== external_id) {
        SiteDetailsStore.updateStationGroup(external_id, { aggregate_power })
      }
    } else if (siteAggregatePower) {
      SiteDetailsStore.updateSite({ aggregate_power: siteAggregatePower })
    }
  }

  const openSiteSettings = async () => {
    setSettingsVisibility(true)
  }

  useEffect(() => {
    if (siteID) {
      SiteDetailsStore.loadInitialPageData(siteID)
    }
  }, [SiteDetailsStore.initialized, siteID])

  useWebSocket(`/energy/ws/site/${siteID}/station-groups/`, {
    onMessage: ({
      content,
      event_type,
    }: MessagePayload<MessageEventType.UPDATE, Partial<StationGroup>>) => {
      if (event_type === MessageEventType.UPDATE) {
        updateAggregatePower(content)
      }
    },
  })

  const customErrorContent = (
    <Notification type={NotificationLevel.ERROR}>
      <KitFlexRowSpaced style={{ width: '100%' }}>
        <StyledErrorMessage>
          { t('errors.unhandled_exception') }
        </StyledErrorMessage>
        <KitButton
          variant="secondary"
          onClick={() => setSettingsVisibility(false)}
        >
          { t('ok') }
        </KitButton>
      </KitFlexRowSpaced>
    </Notification>
  )

  return (
    <Page
      title={t('page_titles.site_overview', { name: SiteDetailsStore.site?.name })}
    >
      <KitToast position="top-right" duration={2000} reverseOrder />
      <SubHeaderContainer>
        <PaddedStyledKitPageTitle
          category={t('energy_management.site_one')}
          title={SiteDetailsStore.site?.name as string}
          displayBack
          onClickBack={navigateBack}
          ariaLabelForBackButton={t(
            'energy_management.back_to_energy_management',
          )}
        />
      </SubHeaderContainer>

      <PageContainer>
        <SiteGroupSettingsTable
          isLoading={isLoading}
          site={SiteDetailsStore.site as Site}
          stationGroups={SiteDetailsStore.stationGroups}
          t={t}
          openSiteSettings={openSiteSettings}
        />

        <EnergyChartsWrapper>
          <EnergyChartControls
            onFilterChange={handleChartFilterChange}
            site={SiteDetailsStore.site}
            stationGroups={SiteDetailsStore.stationGroups}
            selectedDuration={duration}
            selectedRange={selectedRange as DateRange}
          />
          <EnergyChartsContainer>
            <TabbedChartContainer
              key={`group-1-${chartSettings.group1.expanded}`}
              onTabChange={(index) => handleChartTabChange('group1', index)}
              selectedIndex={chartSettings.group1.selectChartIndex}
              tabs={chartTabs.group1}
              className={
                chartSettings.group1.expanded
                  ? 'expanded'
                  : chartSettings.group2.expanded
                    ? 'hidden'
                    : ''
              }
            >
              <ChartSizeToggleButton
                expanded={chartSettings.group1.expanded}
                onClick={() => handleChartResize('group1')}
                label={
                  chartSettings.group1.expanded
                    ? t('charting.collapse_chart')
                    : t('charting.expand_chart')
                }
              />
              { powerManagementType && (
                <ErrorBoundary>
                  <EnergyDetailsGraph
                    siteID={siteID}
                    timezone={timezone}
                    groupByField={chartSettings.group1.selectedChart}
                    chartGroupId={'group1'}
                    expanded={chartSettings.group1.expanded}
                    chartFilters={chartFilters}
                    duration={duration}
                    powerManagementType={powerManagementType}
                  />
                </ErrorBoundary>
              ) }
            </TabbedChartContainer>
            <TabbedChartContainer
              key={`group-2-${chartSettings.group2.expanded}`}
              onTabChange={(index) => handleChartTabChange('group2', index)}
              selectedIndex={chartSettings.group2.selectChartIndex}
              tabs={chartTabs.group2}
              className={
                chartSettings.group2.expanded
                  ? 'expanded'
                  : chartSettings.group1.expanded
                    ? 'hidden'
                    : ''
              }
            >
              <ChartSizeToggleButton
                expanded={chartSettings.group2.expanded}
                onClick={() => handleChartResize('group2')}
                label={'expand chart'}
              />
              { powerManagementType && siteID && (
                <ErrorBoundary>
                  <EnergyDetailsGraph
                    delay={2}
                    siteID={siteID}
                    timezone={timezone}
                    groupByField={chartSettings.group2.selectedChart}
                    expanded={chartSettings.group2.expanded}
                    chartGroupId={'group2'}
                    chartFilters={chartFilters}
                    duration={duration}
                    powerManagementType={powerManagementType}
                  />
                </ErrorBoundary>
              ) }
            </TabbedChartContainer>
          </EnergyChartsContainer>
        </EnergyChartsWrapper>
      </PageContainer>

      <KitModal
        show={settingsVisibility}
        size={KitModalSize.lg}
        closeOnClickOutside={false}
        customStyles={`
          min-height: 600px;
          margin-start: calc((100vh - 600px) / 2) auto;
        `}
      >
        <ErrorBoundary customContent={customErrorContent}>
          <EnergySettingsModal
            siteID={siteID as string}
            onApply={saveSettings}
            onCancel={handleCancelSettings}
          />
        </ErrorBoundary>
      </KitModal>
    </Page>
  )
}

export default observer(EnergyDetailsView)
