import { type TFunction } from 'i18next'
import { type FC } from 'react'

import { type AddFleetReducerState } from './config'
import { type CustomChangeEventHandler } from '@/models/formModel'
import type { Schedule, ScheduleType } from '@/models/scheduleModel'
import { FleetScheduleForm } from '@/pages/FleetPropSheet/routes/FleetSettings/FleetSettingsSchedulingPanel/ScheduleForm'

export interface SchedulingProps {
  state: AddFleetReducerState;
  onChange: CustomChangeEventHandler;
  values: {
    scheduleType: ScheduleType;
    schedule: Schedule;
  };
  t: TFunction;
}

const Scheduling: FC<SchedulingProps> = ({
  onChange,
  t,
  values,
}) => {
  const onFieldChange = (fieldName: string, fieldValue: unknown) => {
    onChange('schedule', fieldName, fieldValue)
  }

  const scheduleProps = { ...values }

  return (
    <FleetScheduleForm
      {...scheduleProps}
      scheduleType={scheduleProps?.scheduleType}
      setScheduleType={(val) => onFieldChange('scheduleType', val)}
      handleFieldChange={(fieldName, fieldValue) => onFieldChange(fieldName, fieldValue)}
      errors={{}}
      onChange={onFieldChange}
      t={t}
      {...scheduleProps}
    />
  )
}

export default Scheduling
