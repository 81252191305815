// Methods and utils used when building the Energy graphs
import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { format, isSameDay, isValid, parseISO, subDays } from 'date-fns'
import { type TFunction } from 'i18next'
import { type CartesianViewBox } from 'recharts/types/util/types'

import { SitePowerFields } from '../../types'
import { mapApiResponseToChartSeries } from './utils'
import {
  ISO_DATE_TIME,
  ISO_DATE_TIME_NO_YEAR,
  ISO_TIME,
  Period,
  PowerManagementTypes,
} from '@/common/constants'
import { SortDir, cleanKey, numericSort } from '@/common/utils/data'
import { filterDateQuery } from '@/common/utils/date'
import {
  ChartColors,
  InterpolationType,
  SeriesType,
} from '@/components/Charting/common/constants'
import { type ChartElementProps } from '@/components/Charting/common/types'
import { type TimeSeriesData, type TimeSeriesResponse } from '@/models/chartModel'
import { type PowerManagementType } from '@/models/energyModel'
import { type DateRange } from '@/types/index'

const interpolationType = InterpolationType.step

export interface EnergyChartConfig {
  [key: string]: {
    handleResponse: (
      args: [
        TimeSeriesResponse,
        TimeSeriesResponse,
        TimeSeriesResponse,
        TimeSeriesResponse,
      ]
    ) => {
      results: unknown[];
      labelMap: Record<string, string>;
      additionalSeries: ChartElementProps[];
    };
  };
}

export function getSetPointKey(powerManagementType: string): string {
  return powerManagementType === PowerManagementTypes.POWER_SHARING_SIMULATION
    ? 'setpoint_simulation'
    : 'setpoint'
}

export interface ReferenceLabelProps {
  viewBox: CartesianViewBox;
}

export function getReferenceLabel(
  props: { viewBox: CartesianViewBox },
  label: string,
) {
  const { viewBox } = props
  return (
    <text
      x={80}
      y={(viewBox.y as number) - ThemeConstants.spacing.absolute.s}
      style={{
        fontSize      : `${ThemeConstants.fontSize.text_12}rem`,
        fontWeight    : ThemeConstants.fontWeight.bold,
        fill          : ThemeColors.gray_70,
        textTransform : 'uppercase',
        textShadow    : `1px 1px 1px ${ThemeColors.gray_20}`,
      }}
    >
      { label }
    </text>
  )
}

// merges multiple timeseries datasets on the timestamp
export const mergeTimeSeries = (
  dataSet: TimeSeriesData[],
  seriesToAdd: TimeSeriesData[],
  dataKey: string,
) => {
  const result = dataSet.concat()
  if (dataSet?.length && seriesToAdd?.length) {
    const unMapped = seriesToAdd.filter((s) => !result.find((r) => r.timestamp === s.timestamp))
    return result.map((row) => {
      const matchingRecord = seriesToAdd.find(
        (s) => s.timestamp === row.timestamp,
      )
      if (matchingRecord) {
        row[dataKey] = matchingRecord[dataKey]
      }
      return row
    })
      .concat(unMapped)
      .sort(numericSort('timestamp', SortDir.ASC))
  }

  return dataSet
}

/**
 * transform function that takes an api response and converts to a chart friendly one
 */
export const processTimeSeriesResponse = (response: TimeSeriesResponse) => {
  const labelMap: Record<string, string> = {}

  let results: TimeSeriesData[] = []
  if (!response.error && response?.length) {
    results = response.map(({ data, time }) => {
      const row: TimeSeriesData = { timestamp: parseISO(time).getTime() }

      data.forEach((item) => {
        const dataKey     = cleanKey(item.name)
        labelMap[dataKey] = item.name
        row[dataKey]      = item.value
      })
      return row
    })
  }

  return {
    results,
    labelMap,
  }
}

// returns series and other configuration options for Energy Charts
export const getChartConfig = ({
  powerManagementType,
  t,
}: {
  t: TFunction;
  powerManagementType: string;
}): EnergyChartConfig => ({
  [SitePowerFields.STATION_GROUP_NAME]: {
    handleResponse: ([byGroup, setpoint, target, limit]: [
      TimeSeriesResponse,
      TimeSeriesResponse,
      TimeSeriesResponse,
      TimeSeriesResponse,
    ]) => {
      const additionalSeries: ChartElementProps[] = []
      const setPointKey                           = getSetPointKey(powerManagementType)
      const { labelMap, results }                 = processTimeSeriesResponse(byGroup)

      // build line series for first chart

      const lineSeries: {
        field: string;
        data: TimeSeriesResponse;
        color: string;
        extendValue?: boolean;
        props?: Record<string, unknown>;
        type?: string;
      }[] = [
        { field: setPointKey, data: setpoint, color: ChartColors.red },
        {
          field       : 'target_power',
          data        : target,
          color       : ThemeColors.gray_50,
          extendValue : true,
          type        : InterpolationType.stepAfter,
        },
        {
          field       : 'power_limit',
          data        : limit,
          color       : ThemeColors.gray_50,
          extendValue : true,
          type        : InterpolationType.stepAfter,
        },
      ]

      lineSeries.forEach((s) => {
        labelMap[s.field] = t(`charting.${s.field}`)
        const mapped      = mapApiResponseToChartSeries(
          s.data,
          {
            fieldName   : s.field,
            extendValue : s.extendValue,
            props       : {
              name   : labelMap[s.field],
              label  : labelMap[s.field],
              stroke : s.color,
              fill   : s.color,
              type   : s.type ?? interpolationType,
              ...(s.props ? s.props : {}),
            },
          },
          results,
          t,
        )

        if (mapped.series) {
          additionalSeries.push(mapped.series)
        }
      })

      results.sort(numericSort('timestamp', SortDir.ASC))

      return {
        results,
        labelMap,
        additionalSeries,
      }
    },
  },
})

export function buildCache(
  key: string,
  response: TimeSeriesResponse[],
  cache: Record<string, unknown[]>,
  setPointKey: string,
) {
  const cacheUpdates = { ...(cache || {}) }
  if (key === SitePowerFields.STATION_GROUP_NAME) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setpoint, target] = response
    cacheUpdates[setPointKey]   = processTimeSeriesResponse(setpoint).results
    cacheUpdates.target         = target
  } else {
    cacheUpdates[key] = response
  }

  return cacheUpdates
}

const durationFormatMap = {
  [Period.LAST_HOUR]     : ISO_TIME,
  [Period.LAST_12_HOURS] : ISO_TIME,
  [Period.LAST_24_HOURS] : ISO_TIME,
  [Period.TODAY]         : ISO_TIME,
  [Period.LAST_7_DAYS]   : ISO_DATE_TIME,
  [Period.LAST_30_DAYS]  : ISO_DATE_TIME,
  [Period.ALL_TIME]      : ISO_DATE_TIME,
}

export const formatXAxisLabel = (
  value: number,
  period: Period,
  domain: [number, number],
): string | null => {
  // does the chart duration span multiple days?
  const sameDay  = isSameDay(domain[0], domain[1])
  const showDate = domain ? !sameDay : true
  const fmtKey   = period as keyof typeof durationFormatMap
  let fmt        = ISO_DATE_TIME_NO_YEAR
  if (!showDate && durationFormatMap[fmtKey]) {
    fmt = durationFormatMap[fmtKey]
  }

  if (isValid(value)) {
    return format(value, fmt)
  }

  return null
}

export const getChartSeries = (
  ordinalRange: string[],
  colorDomain: (k: string) => string,
  labelMap: Record<string, string>,
  additionalSeries: ChartElementProps[] = [],
  t: TFunction,
) => {
  const dynamic: ChartElementProps[] = ordinalRange.map((dataKey) => {
    const fillColor   = colorDomain(dataKey)
    const strokeColor = colorDomain(dataKey)
    const name        = labelMap[dataKey]
    const stackId     = 'avg_power'

    return {
      name,
      seriesType        : SeriesType.Bar,
      connectNulls      : false,
      type              : interpolationType,
      dataKey,
      fill              : fillColor,
      stroke            : strokeColor,
      unit              : t('units.kilowatt.short'),
      stackId,
      isAnimationActive : true,
    }
  })

  if (additionalSeries) {
    return dynamic.concat(additionalSeries)
  }

  return dynamic
}

export interface ProcessedChartData {
  additionalSeries?: ChartElementProps[];
  labelMap: Record<string, string>;
  results: TimeSeriesData[];
  error?: unknown;
}

export const processChartData = (
  response: TimeSeriesResponse,
  groupByField: string,
  cached: Record<string, TimeSeriesData[]> | undefined,
  powerManagementType: PowerManagementType,
  t: TFunction,
): ProcessedChartData => {
  const cfg         = getChartConfig({ t, powerManagementType })
  const setPointKey = getSetPointKey(powerManagementType)
  if (cfg[groupByField]) {
    // @ts-expect-error -- revisit this typing, requires too deep a refactor at this time
    return cfg[groupByField].handleResponse(response)
  }

  if (response.error) {
    return { results: [], error: response.error, labelMap: {} }
  }

  const processed = processTimeSeriesResponse(response)

  if (cached?.[setPointKey]) {
    return {
      labelMap : { ...processed.labelMap },
      results  : mergeTimeSeries(
        processed.results,
        cached?.[setPointKey],
        setPointKey,
      ),
      additionalSeries: [
        {
          seriesType   : SeriesType.Line,
          type         : interpolationType,
          dataKey      : setPointKey,
          name         : t(`charting.${setPointKey}`),
          fill         : ChartColors.red,
          stroke       : ChartColors.red,
          strokeWidth  : 1,
          connectNulls : true,
          dot          : false,
          unit         : t('units.kilowatt.short'),
        },
      ],
    }
  }

  return processed
}

// if range contains strings, convert them to actual dates
export const parseRange = (range: DateRange | string[]): DateRange => {
  if (Array.isArray(range)) {
    return range.map((dte) => (typeof dte === 'string' ? parseISO(dte) : dte)) as DateRange
  }
  return range
}

export const getRange = (
  duration: Period,
  parse?: boolean,
): DateRange | string[] => {
  let results = filterDateQuery({ period: duration })
  const now   = new Date()

  if (duration === Period.LAST_7_DAYS) {
    const start = subDays(now, 6)
    results     = filterDateQuery({
      period : Period.CUSTOM,
      start  : start.toISOString(),
      end    : now.toISOString(),
    })
  }

  const { end_time, start_time } = results

  const range = [start_time, end_time]
  if (parse) {
    return parseRange(range)
  }
  return range
}
