import { type IEventProperties, analyticEvents } from './AnalyticEvents'

export const getAnalyticsScheduleType = <
  T extends Record<string, string | boolean | unknown>,
>(
  schedule: T,
) => {
  if (!schedule) {
    return 'none'
  }

  // used by Add Vehicle flow
  if (schedule.inherit_from_fleet) {
    return 'inherit from fleet'
  }

  return 'single departure time'
}

export interface AnalyticsEventProps {
  name: string;
  time: number;
  properties?: IEventProperties;
}

// only these events will be throttled
export const eventsToThrottle = [
  { name: analyticEvents.filtersApplied, wait: 10000 },
]

// This method is to prevent duplicate events from being sent to the analytics service within a certain amount of time
// For instance, when a user is searching for vehicles, we don't want the same 'filters-applied' event to be sent for each key stroke
export const skipEvent = (
  previousEvent: AnalyticsEventProps | null,
  newEvent: AnalyticsEventProps,
) => {
  const defaultMinElapsedTime = 2000

  if (!previousEvent) {
    return false
  }

  if (previousEvent.name === newEvent.name) {
    const throttleEvent = eventsToThrottle.find(
      (ev) => ev.name === newEvent.name,
    )
    if (throttleEvent) {
      const previousEventKeys = previousEvent.properties
        ? Object.keys(previousEvent.properties)
        : []
      const newEventKeys      = newEvent.properties
        ? Object.keys(newEvent.properties)
        : []
      if (
        (previousEventKeys.length === newEventKeys.length
          && newEvent.time - previousEvent.time <= throttleEvent?.wait)
        ?? defaultMinElapsedTime
      ) {
        return (
          JSON.stringify(previousEventKeys) === JSON.stringify(newEventKeys)
        )
      }
    }
  }

  return false
}

/**
 * Method to strip unique model ids from the url path so we can easily get total page views for a particular view.
 *
 * Discussion: When sending page-view events to MixPanel, it would be helpful to have a
 * variable other than the full path track detail pages. Detail page paths contain an id which is
 * unique for each customer, vehicle, fleet, etc.
 *
 * The full path is not very helpful to use when trying to understand how many
 * times a user viewed a details page across all vehicles, and customer, etc...
 *
 * Examples:
 * - '/depot/1234/settings' gets simplified to '/depot/settings'
 * - `/energy/sites/1111` get simplified to '/energy/site'
 *
 * Note: This is probably also possible using custom variables in Mixpanel (which support RegEx),
 * but we have more control over it if we construct it here.
 * @param path
 * @returns
 */
//
export const getConsolidatedPathID = (path: string) => {
  // TODO: remove this line after we implement the dashboard as the home page
  if (['/', '/depots'].includes(path)) {
    // both / and /depots routes render the home page, but we want to
    // track all page views as home page views
    return '/'
  }

  // matches /depot/1234, /depot/1234/settings, /energy/sites/1234
  const propSheetRegEx     = /\/((\d*\/)|(\d+))/
  const propSheetBaseRegEx = /\/\d+$/

  if (path.match(propSheetRegEx)) {
    if (path.match(propSheetBaseRegEx)) {
      return path.replace(propSheetBaseRegEx, '')
    }
    return path.replace(propSheetRegEx, '/')
  }

  return path
}
