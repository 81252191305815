import { ChargingStatus, ThemeConstants } from '@chargepoint/cp-toolkit'

import { type PaginationProps } from '@/types/index'

// URLS
// Note: This should really be app specific, but we don't have a fleet support page yet
export const SUPPORT_PAGE_URL = 'https://www.chargepoint.com/support/'
export const VCT_SUPPORT_PAGE_URL = 'https://viricitihelp.zendesk.com/'

// react-router-dom routes
export const PAGE_NOT_FOUND_PATH = '/page-not-found'

// these are NOS paths
export const USER_ACCOUNT_SETTINGS_PATH = '/r/admin/account/'
export const HELP_PATH = '/training_videos'

// local/sessionStorage keys
export const LOCAL_STORAGE_LOCALE_KEY = 'i18nextLng'
export const LOCAL_STORAGE_USER_PREFERENCES_KEY = 'user_preferences'
export const LOCAL_STORAGE_COBRAND_INFO_KEY = 'cobrand_info'

// date format strings
export const ISO_DATE = 'yyyy-MM-dd'
export const ISO_DATE_NO_YEAR = 'MM-dd'
export const ISO_DATE_TIME = 'yyyy-MM-dd HH:mm'
export const ISO_DATE_TIME_WITH_SECONDS = 'yyyy-MM-dd HH:mm:ss'
export const ISO_TIME = 'HH:mm'
export const ISO_DATE_TIME_NO_YEAR = 'MM-dd HH:mm'

export const EMPTY_VALUE_PLACEHOLDER = '--'

export const CURRENT_USER_KEY = 'user'
export const ORG_ID_KEY = 'org_external_id'

export const MIN_PAGE_SIZE = 10
export const DEFAULT_PAGE_SIZE = 50

export const defaultPaginationProps: PaginationProps = {
  currentPage  : 1,
  pageSize     : DEFAULT_PAGE_SIZE,
  totalPages   : 1,
  totalRecords : 0,
}

export const UILayout = {
  footerHeight : 4,
  hPageMargin  : ThemeConstants.spacing.absolute.m * 3,
  filters      : { panelZIndex: 110 },
  mobile       : {
    hPageMargin       : ThemeConstants.spacing.absolute.m,
    modalMarginSize   : ThemeConstants.spacing.absolute.xs,
    modalFooterHeight : 52,
  },
}

export enum DistanceUnits {
  MILES = 'miles',
  KILOMETERS = 'kilometers',
}

export enum EditMode {
  EDIT = 'edit',
  READ_ONLY = 'read-only',
}

export enum CRUDOperation {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum RequestType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export enum Period {
  LAST_HOUR = 'lastHour',
  LAST_12_HOURS = 'last12Hours',
  LAST_24_HOURS = 'last24Hours',
  LAST_7_DAYS = 'last7days',
  LAST_30_DAYS = 'last30days',
  TODAY = 'today',
  ALL_TIME = 'allTime',
  CUSTOM = 'custom',
}

export enum VehicleIdTypes {
  ID_TYPE_MAC_ADDRESS = 'mac_address',
  ID_TYPE_VIN = 'vin',
  ID_TYPE_USER_ID = 'user_id',
  ID_TYPE_RF_ID = 'rf_id',
  ID_TYPE_TELEMATICS = 'telematics_device_id',
  ID_TYPE_LICENSE_PLATE = 'license_plate',
  ID_TYPE_CREDENTIAL_ID = 'credential_id',
}

// used to compare the `depotDetailsRow.vehicle_status` field
export enum VehicleStatusOptions {
  CHARGING = 'CHARGING',
  NOT_DRAWING_POWER = 'NOT_DRAWING_POWER',
  // `not ready` means that the vehicle has no mac address of rfid
  NOT_READY = 'not_ready',
}

export const ChargingStatusOptions = {
  Charging     : 'charging',
  ChargingLate : 'charging_late',
  InDepot      : 'in_depot',
  LatePlugIn   : 'late_plug_in',
  NotCharging  : 'not_charging',
  OnTrip       : 'on_trip',
  PlugIn       : 'plug_in',
  PlugInLate   : 'plug_in_late',
  Ready        : 'ready',
  ReducedPower : 'reduced_power',
  StationFault : 'station_fault',
  VehicleFault : 'vehicle_fault',
  Unknown      : 'unknown',
  Unplugged    : 'unplugged',
}

export const chargingStatusMap: Record<string, number> = {
  [ChargingStatusOptions.Ready]        : ChargingStatus.Ready,
  [ChargingStatusOptions.Charging]     : ChargingStatus.Charging,
  [ChargingStatusOptions.ChargingLate] : ChargingStatus.Alert,
  [ChargingStatusOptions.InDepot]      : ChargingStatus.Inactive,
  [ChargingStatusOptions.LatePlugIn]   : ChargingStatus.Alert,
  [ChargingStatusOptions.NotCharging]  : ChargingStatus.Alert,
  [ChargingStatusOptions.OnTrip]       : ChargingStatus.Inactive,
  [ChargingStatusOptions.PlugIn]       : ChargingStatus.Charging,
  [ChargingStatusOptions.PlugInLate]   : ChargingStatus.Alert,
  [ChargingStatusOptions.ReducedPower] : ChargingStatus.Warning,
  [ChargingStatusOptions.StationFault] : ChargingStatus.Alert,
  [ChargingStatusOptions.VehicleFault] : ChargingStatus.Alert,
  [ChargingStatusOptions.Unknown]      : ChargingStatus.Warning,
  [ChargingStatusOptions.Unplugged]    : ChargingStatus.Inactive,
  [VehicleStatusOptions.NOT_READY]     : ChargingStatus.Inactive,
}

export enum ChargingSessionStatus {
  Ready = 'ready',
  Charging = 'charging',
  StationFault = 'station_fault',
  VehicleFault = 'vehicle_fault',
}

export enum AxisTypes {
  xAxis = 'xAxis',
  yAxis = 'yAxis',
}

export enum ErrorCodes {
  EnvironmentNotInitialized,
  NotAuthorized,
  NotFound,
  ProfileNotLoaded,
  ServerError,
}

export const scheduleRequiredFields = [
  { field: 'required_soc' },
  { field: 'arrival_time' },
  { field: 'arrival_time_downtime_secs' },
  { field: 'departure_time' },
  { field: 'departure_time_downtime_secs' },
]

export enum PowerManagementTypes {
  POWER_SHARING = 'power_sharing',
  POWER_SHARING_SIMULATION = 'power_sharing_simulation',
  NONE = 'none',
}

export const MIN_SITE_DEMAND_LIMIT = 1
export const MIN_TARGET_DEMAND_LIMIT = 1
export const STATIC_GOOGLE_MAPS_URL =  'https://maps.googleapis.com/maps/api/staticmap'
export const DEFAULT_DISTANCE_UNITS = DistanceUnits.MILES

export const AppRoutes = {
  Root             : { path: '/' },
  Account          : { path: '/account' },
  Energy           : { path: '/energy/sites' },
  Depots           : { path: '/depots' },
  Fleets           : { path: '/fleets' },
  DepotPropSheet   : { path: '/depot/:depot_external_id' },
  FleetPropSheet   : { path: '/fleets/:fleet_external_id/*' },
  VehiclePropSheet : { path: '/vehicle/:vehicle_external_id/*', basePath: '/vehicle' },
  Settings         : { path: '/settings' },
  PageNotFound     : { path: '/page-not-found' },
}

// wretch library expects cache flag of 'default' for caching response
export const CACHE_FETCH_OPTIONS = { cache: 'default' }
