/* eslint-disable camelcase */
import { KitForm, KitInput, KitSelect } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import ReactDOM, { unstable_batchedUpdates } from 'react-dom'

import { EditMode } from '@/common/constants'
import { FLEET_VEHICLE_UPDATE } from '@/common/permissions'
import { renderComponent } from '@/common/utils/componentHelpers'
import { toOptionsArray } from '@/common/utils/data'
import EditPanel from '@/components/EditPanel'
import ReadOnlySettings from '@/components/Settings/ReadOnlySettings'
import { PageContent, Spacer } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import VehicleImage from '@/components/VehicleImage'
import useAnalyticsService from '@/hooks/useAnlyticsService'
import { type Fleet } from '@/models/fleetModel'
import { type SelectChangeAction, type SelectChangeEvent } from '@/models/formModel'
import { type BaseServiceResponse, type ServicePayload } from '@/models/serviceModel'
import {
  type Vehicle,
  type VehicleMake,
  type VehicleModel,
  type VehicleSettingsAbout,
  type VehicleYear,
} from '@/models/vehicleModel'
import { analyticEvents } from '@/services/AnalyticsService/AnalyticEvents'
import FleetService from '@/services/FleetService'
import VehicleService from '@/services/VehicleService'
import { type Field } from '@/types/index'

const vehicleImageWrapperStyle = {
  height : '120px',
  margin : 'auto',
}

const componentMap = {
  text   : KitInput,
  select : KitSelect,
}

const submitForm = async (
  vehicleId: string,
  fields: ServicePayload,
  callback: (res: BaseServiceResponse) => void,
) => {
  const response = (await VehicleService.updateVehicleSettings(
    vehicleId,
    'about',
    fields,
  )) as BaseServiceResponse
  callback(response)
}

const prepareRequest = (fields: Partial<Vehicle>) => {
  if (!fields) {
    return {}
  }
  return Object.entries(fields).reduce((acc, [key, value]) => {
    if (['model_make', 'model_name', 'model_year'].includes(key)) {
      if (key === 'model_year') {
        acc.model_year_id = value as number
      }
    } else {
      acc[key] = value
    }
    return acc
  }, {} as Partial<Vehicle>)
}

const getSelectValue = ({ props }: Field) => props?.value ?? props?.defaultValue?.value

const getAboutValues = (
  fields: Field[],
  state: VehicleSettingsFormState,
): Partial<{
  image_url: string;
  model_make: number;
  model_name: string;
  model_year: number;
}> => {
  const modelYearID =    state.changes?.model_year
    ?? getSelectValue(
      fields.find((f) => f.field === 'model_year') as unknown as Field,
    )
  const year        = state.data.years?.find(
    (item) => item.id === parseInt(modelYearID as string),
  )
  const imageUrl    = year?.image_url

  return {
    ...state.changes,
    image_url: imageUrl as string,
  }
}

interface VehicleSettingsFormOptionsData {
  [key: string]: unknown;
  makes?: VehicleMake[];
  models?: VehicleModel[];
  years?: VehicleYear[];
  fleets?: Fleet[];
}

interface VehicleSettingsFormState {
  data: Partial<VehicleSettingsFormOptionsData>;
  changes: Record<string, unknown>;
}

export interface EditAboutSettingsProps {
  fields: Field[];
  state: VehicleSettingsFormState;
  onChange: (e: SelectChangeEvent) => void;
  t: TFunction;
}

const EditAboutSettings = ({
  fields,
  onChange,
  state,
  t,
}: EditAboutSettingsProps) => {
  const { image_url, model_make, model_name, model_year } = getAboutValues(
    fields,
    state,
  )

  return (
    <>
      { fields.map((field) => (
        <KitForm.Group key={`${field.props?.label}`}>
          <KitForm.Label
            htmlFor={field.field as string}
            text={field.props?.label}
          />
          { renderComponent(field, componentMap, { state, onChange }) }
        </KitForm.Group>
      )) }
      <KitForm.Group>
        { image_url && (
          <VehicleImage
            url={image_url}
            style={vehicleImageWrapperStyle}
            altText={t('vehicle_image_alt_text', { vehicle_type: `${model_make} ${model_name} ${model_year}` })}
            t={t}
          />
        ) }
      </KitForm.Group>
    </>
  )
}

const VehicleSettingsAboutPanel = (props: VehicleSettingsAbout) => {
  const { analyticsService }        = useAnalyticsService()
  const {
    fleet_name,
    id,
    image_url,
    model_make,
    model_name,
    model_year,
    refreshData,
    t,
  } = props
  const [inEditMode, setInEditMode] = useState(false)
  const [formState, setFormState]   = useState<VehicleSettingsFormState>({
    data    : {},
    changes : {},
  })
  const [isLoading, setIsLoading]   = useState(false)
  const handleCancel                = () => {
    setFormState({ data: {}, changes: {} })
    setInEditMode(false)
  }
  const handleSubmit                = () => {
    setIsLoading(true)
    const processedFields = prepareRequest(formState.changes)
    submitForm(id, processedFields, (response) => {
      unstable_batchedUpdates(() => {
        setIsLoading(false)
        setInEditMode(false)
        if (!response.error) {
          analyticsService.trackEvent(analyticEvents.vehicleSettingsUpdated, {
            section : 'about',
            fields  : Object.keys(processedFields),
          })
          refreshData('about', response as Record<string, unknown>)
        }
      })
    })
    // Resetting fields after an update has been made
    setFormState({ data: {}, changes: {} })
  }

  const handleFieldChange = (e) => {
    const { target: { name: fieldName, value } } = e

    const newFormState = {
      ...formState,
      changes: {
        ...formState.changes,
        [fieldName]: value,
      },
    }

    setFormState(newFormState)
  }

  const handleSelectChange = (
    e: SelectChangeEvent,
    action: { name: string },
  ) => {
    const newFormState = {
      ...formState,
      changes: {
        ...formState.changes,
        [action.name]: e.value,
      },
    }
    setFormState(newFormState)
  }

  const getValue = (fieldName: keyof typeof props) => {
    const fieldLookup = {
      model_make: () => formState.changes?.[fieldName]
        ?? formState.data.makes?.find((make) => make.name === props[fieldName])
          ?.id,
      model_name: () => formState.changes?.[fieldName]
        ?? formState.data.models?.find((model) => model.name === props[fieldName])
          ?.id,
      model_year: () => formState.changes?.[fieldName]
        ?? formState.data.years?.find((year) => year.year === props[fieldName])
          ?.id,
      fleet_external_id: () => (formState.changes?.[fieldName]
        ? parseInt(formState.changes?.[fieldName])
        : formState.data.fleets?.find(
          (fleet) => fleet.external_id === props[fieldName],
        )?.id),
      home_depot_name: () => {
        const fleet = formState.data.fleets?.find(
          (fleet) => fleet.external_id === formState.changes?.fleet_external_id ?? props.fleet_external_id,
        )
        return fleet?.home_depot_name
      },
    }

    if (fieldLookup[fieldName as keyof typeof fieldLookup]) {
      return (
        fieldLookup[fieldName as keyof typeof fieldLookup]()
        ?? props[fieldName === 'fleet_external_id' ? 'fleet_name' : fieldName]
      )
    }

    return formState.changes?.[fieldName] ?? props[fieldName]
  }

  const fields = [
    {
      field     : 'name',
      component : 'text',
      props     : {
        label    : t('fields.vehicle_name_label'),
        value    : getValue('name'),
        onChange : handleFieldChange,
      },
    },
    {
      field     : 'fleet_external_id',
      component : 'select',
      props     : {
        select         : true,
        readonly_value : getValue('fleet_name'),
        label          : t('fields.fleet_name_label'),
        defaultValue   : {
          value : getValue('fleet_external_id'),
          label : fleet_name,
        },
        onChange : (e: SelectChangeEvent, action: SelectChangeAction) => handleSelectChange(e, action),
        options  : {
          selector: (state: VehicleSettingsFormState) => toOptionsArray(state.data?.fleets as Fleet[], {
            labelField : 'name',
            valueField : 'external_id',
          }),
        },
      },
    },
    {
      field     : 'fleet_depot',
      component : 'text',
      props     : {
        value    : getValue('home_depot_name'),
        label    : t('depot'),
        onChange : handleFieldChange,
        disabled : true,
      },
    },
    {
      field     : 'model_make',
      component : 'select',
      props     : {
        label        : t('make'),
        onChange     : (e: SelectChangeEvent, action: SelectChangeAction) => handleSelectChange(e, action),
        defaultValue : {
          value : getValue('model_make'),
          label : model_make,
        },
        options: {
          selector: (state: VehicleSettingsFormState) => toOptionsArray(state.data?.makes as VehicleMake[], {
            labelField : 'name',
            valueField : 'id',
          }),
        },
        select: true,
      },
    },
    {
      field     : 'model_name',
      component : 'select',
      props     : {
        field        : 'model',
        label        : t('model'),
        defaultValue : {
          value : getValue('model_name'),
          label : model_name,
        },
        onChange : (e: SelectChangeEvent, action: SelectChangeAction) => handleSelectChange(e, action),
        options  : {
          selector: (state: VehicleSettingsFormState) => toOptionsArray(state.data?.models as VehicleModel[], {
            labelField : ['name', 'trim'],
            valueField : 'id',
          }),
        },
        select: true,
      },
    },
    {
      field     : 'model_year',
      component : 'select',
      props     : {
        defaultValue: {
          value : getValue('model_year'),
          label : model_year,
        },
        label    : t('year'),
        onChange : (e: SelectChangeEvent, action: SelectChangeAction) => handleSelectChange(e, action),
        options  : {
          selector: (state: VehicleSettingsFormState) => toOptionsArray(state.data?.years as VehicleYear[], {
            labelField : 'year',
            valueField : 'id',
          }),
        },
        select: true,
      },
    },
  ].map((f) => ({
    ...f,
    props: {
      ...f.props,
      name: f.field,
    },
  }))

  const readOnlyFields = fields
    .filter(
      (f) => !['model_make', 'model_name', 'model_year'].includes(f.field),
    )
    .map((f) => ({
      testId : f.field,
      label  : f.props.label,
      value  : f.props.readonly_value ?? f.props.value,
    }))

  useEffect(() => {
    async function fetchModelData(selectedMakeId: number) {
      const data = { ...formState.data }

      if (selectedMakeId) {
        VehicleService.getModels(selectedMakeId).then((modelsResponse) => {
          data.models = modelsResponse.results

          const selectedModelId = modelsResponse.results[0].id

          VehicleService.getYears(selectedModelId).then((yearsResponse) => {
            data.years = yearsResponse.results
            ReactDOM.unstable_batchedUpdates(() => {
              setFormState({
                data,
                changes: {
                  ...formState.changes,
                  model_name : selectedModelId,
                  model_year : yearsResponse.results[0].id,
                },
              })
              setIsLoading(false)
            })
          })
        })
      }
    }

    if (formState.changes?.model_make) {
      fetchModelData(formState.changes.model_make as number)
    }
  }, [formState.changes?.model_make])

  useEffect(() => {
    async function fetchModelData(selectedModelId: number) {
      const data = { ...formState.data }

      if (selectedModelId) {
        VehicleService.getYears(selectedModelId).then((yearsResponse) => {
          data.years = yearsResponse.results
          ReactDOM.unstable_batchedUpdates(() => {
            setFormState({
              data,
              changes: { ...formState.changes, model_year: data.years?.[0].id },
            })
            setIsLoading(false)
          })
        })
      }
    }

    if (formState.changes?.model_name) {
      fetchModelData(formState.changes.model_name as number)
    }
  }, [formState.changes?.model_name])

  useEffect(() => {
    if (inEditMode) {
      setIsLoading(true)
      // get fleets, makes, models, years, etc...
      Promise.all([FleetService.getFleets(), VehicleService.getMakes()]).then(
        ([fleetsResponse, makesResponse]) => {
          const { results: fleets } = fleetsResponse
          const { results: makes }  = makesResponse

          const data: Partial<VehicleSettingsFormOptionsData> = {
            fleets,
            makes,
          }

          setFormState({ data })

          // get selected make
          const selectedMakeId = makes.find((make) => make.name === model_make)
            ?.id as number
          if (selectedMakeId) {
            VehicleService.getModels(selectedMakeId).then((modelsResponse) => {
              data.models = modelsResponse.results

              const selectedModelId = modelsResponse.results.find(
                (model) => model.name === model_name,
              )?.id as number

              VehicleService.getYears(selectedModelId).then((yearsResponse) => {
                data.years = yearsResponse.results
                ReactDOM.unstable_batchedUpdates(() => {
                  setFormState({ data })
                  setIsLoading(false)
                })
              })
            })
          } else {
            setIsLoading(false)
          }
        },
      )
    }
  }, [inEditMode])

  return (
    <EditPanel
      id={'about'}
      title={t('about')}
      t={t}
      mode={inEditMode ? EditMode.EDIT : EditMode.READ_ONLY}
      onCancel={handleCancel}
      isLoading={isLoading}
      onToggle={() => {
        setInEditMode(!inEditMode)
      }}
      onSubmit={handleSubmit}
      permissions={FLEET_VEHICLE_UPDATE}
    >
      <KitForm noValidate>
        { !inEditMode && (
          <ReadOnlySettings fields={readOnlyFields} {...props} t={t}>
            <KitForm.Group>
              <KitForm.Label
                htmlFor="vehicle_details"
                text={`${t('make')}/${t('model')}/${t('year')}`}
              />
              <KitForm.Value
                dataQaId="make_model_year_value"
                id="vehicle_details"
              >{ `${model_make} ${model_name} ${model_year}` }</KitForm.Value>
            </KitForm.Group>
            { image_url && (
              <KitForm.Group>
                <VehicleImage
                  url={image_url}
                  style={vehicleImageWrapperStyle}
                  altText={t('vehicle_image_alt_text', { vehicle_type: `${model_make} ${model_name} ${model_year}` })}
                  t={t}
                />
              </KitForm.Group>
            ) }
          </ReadOnlySettings>
        ) }
        { inEditMode && isLoading && (
          <PageContent>
            <StyledSpinner />
            <Spacer orientation="vertical" size={50} />
          </PageContent>
        ) }
        { inEditMode && !isLoading && (
          <EditAboutSettings
            fields={fields}
            state={formState}
            onChange={handleFieldChange}
            t={t}
          />
        ) }
      </KitForm>
    </EditPanel>
  )
}

export default VehicleSettingsAboutPanel
