import { KitUtilData, ThemeColors } from '@chargepoint/cp-toolkit'
import {
  GoogleMap,
  Marker,
  type MarkerProps,
  Polygon,
} from '@react-google-maps/api'

import indexMarker from './index-marker.svg'
import { LoadScriptOnlyIfNeeded } from '@/common/mapUtils'
import utils from '@/common/utils'
import { avg } from '@/common/utils/data'
import { type Point } from '@/models/geolocationModel'

const defaultMapCenter = {
  lat : 37.26668,
  lng : -121.9573828,
}

export const getMapCenter = (polygons: Point[][] = []): Point => {
  if (!polygons.length) {
    return defaultMapCenter
  }
  const { lat, lng } = polygons.reduce(
    (acc: { lat: number[]; lng: number[] }, polygon: Point[]) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      polygon.forEach(({ lat, lng }: Point) => {
        acc.lat.push(lat as number)
        acc.lng.push(lng as number)
      })
      return acc
    },
    { lat: [], lng: [] },
  )

  return {
    lat : avg(lat),
    lng : avg(lng),
  }
}

type MiniMapProps = {
  polygons?: {
    lat?: number;
    lng?: number;
  }[][];
  markers?: MarkerProps[];
  mapCenter?: Point;
  zoom?: number;
  opts?: Record<string, unknown>;
};

const MiniMap = (props: MiniMapProps) => {
  const { markers, opts = {}, polygons, zoom } = props
  const GOOGLE_MAPS_API_KEY                    = utils.getConstant('GOOGLE_MAPS_API_KEY')
  const mapZoom                                = zoom ?? 14

  const containerStyle = opts.containerStyle ?? {
    width  : '238px',
    height : '230px',
  }

  const mapOptions = {
    mapTypeControl    : false,
    streetViewControl : false,
    fullscreenControl : false,
    zoomControl       : false,
    scaleControl      : false,
    draggable         : false,
    draggableCursor   : 'inherit',
    ...(opts.mapOptions ?? {}),
  }

  const polygonOptions = {
    fillColor     : 'lightblue',
    fillOpacity   : 0.2,
    strokeColor   : ThemeColors.blue_50,
    strokeOpacity : 1,
    strokeWeight  : 2,
    clickable     : false,
    draggable     : false,
    editable      : false,
    geodesic      : false,
    zIndex        : 1,
  }

  const mapCenter   = opts.mapCenter ?? getMapCenter(polygons)
  const markerProps = opts.markerProps ?? { icon: indexMarker }

  return (
    <LoadScriptOnlyIfNeeded
      googleMapsApiKey={GOOGLE_MAPS_API_KEY as string}
      preventGoogleFontsLoading
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={mapZoom}
        center={mapCenter as google.maps.LatLng}
        options={mapOptions}
      >
        { !KitUtilData.isEmpty(polygons)
          && polygons?.map((polygon: Point[], index: number) => (
            <Polygon
              key={index}
              paths={polygon as unknown as google.maps.MVCArray}
              options={polygonOptions}
              visible
            />
          )) }

        { markers
          && markers.length
          && markers.map(({ label, position }, index: number) => (
            <Marker
              key={index}
              position={position}
              label={label}
              {...markerProps}
            />
          )) }
      </GoogleMap>
    </LoadScriptOnlyIfNeeded>
  )
}

MiniMap.defaultProps = {
  zoom     : 14,
  polygons : [],
  markers  : [],
  opts     : {},
}

export default MiniMap
