import { KitForm, KitSelect } from '@chargepoint/cp-toolkit'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { filterLabelStyles } from '@/components/Styled/filterBarStyles'
import { type OptionListItem, type SelectChangeEvent } from '@/models/formModel'
import { type CustomInputChangeHandler } from '@/types/index'

const FormItemContainer = styled.div`
   div.kit-form-group {
      margin-bottom: 0;
   }
`

const FormItemLabel = styled.label`
  display: block;
  ${filterLabelStyles};
`

export interface MakeModelYearProps {
  label: string;
  labels: Record<string, string>;
  selectedMake?: string;
  selectedModel?: string;
  selectedYear?: string;
  makes: OptionListItem[];
  models?: OptionListItem[];
  years?: OptionListItem[];
  onChange: CustomInputChangeHandler;
}

const MakeModelYear = ({
  label,
  labels,
  makes,
  models,
  onChange,
  selectedMake,
  selectedModel,
  selectedYear,
  years,
}: MakeModelYearProps) => {
  const { t } = useTranslation()

  // Persist the values of previously selected values when the popover is closed
  const renderSelected = (selectedValue = '*') => ({
    selectedValue,
    label: selectedValue === '*' ? t('any') : selectedValue,
  })

  const fields = [
    { name: 'model_make', label: labels.make, options: makes },
    { name: 'model_name', label: labels.model, options: models },
    { name: 'model_year', label: labels.year, options: years },
  ]

  const handleOnChange = (fieldName: string, fieldValue: unknown) => {
    onChange(fieldName, fieldValue)
  }

  return (
    <FormItemContainer data-qa-id="make_model_year_filter_select_container">
      <FormItemLabel>{ label }</FormItemLabel>
      <KitForm>
        { fields.map((field) => {
          if (field.options) {
            return (
              <KitForm.Group key={`field_${field.name}`}>
                <KitSelect
                  menuShouldScrollIntoView={false}
                  data-qa-id={`${field.name}_filter_select_option`}
                  name={field.name}
                  aria-label={`field_${field.name}`}
                  key={field.name}
                  value={
                    field.name === 'model_make'
                      ? renderSelected(selectedMake)
                      : field.name === 'model_name'
                        ? renderSelected(selectedModel)
                        : renderSelected(selectedYear)
                  }
                  onChange={(e: unknown) => handleOnChange(field.name, (e as SelectChangeEvent).value)
                  }
                  options={field.options.map(({ label, value }) => ({
                    value,
                    label,
                  }))}
                />
              </KitForm.Group>
            )
          }
          return null
        }) }
      </KitForm>
    </FormItemContainer>
  )
}

MakeModelYear.defaultProps = {
  models : null,
  years  : null,
}

export default MakeModelYear
