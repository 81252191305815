/* eslint-disable camelcase */
import { KitFlexRowSpaced, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { KeyboardEvent } from 'react'

import styled from 'styled-components'

import { EMPTY_VALUE_PLACEHOLDER } from '@/common/constants'
import alertUtils from '@/common/utils/alerts'
import { hasValue } from '@/common/utils/validations'
import Badge from '@/components/Badge'
import FleetIcon from '@/components/FleetIcon'
import { EllipsisText } from '@/components/Styled'
import StyledFleetCard from '@/components/StyledFleetCard'
import { type Alert } from '@/models/alertModel'

const { fontSize, fontWeight, spacing } = ThemeConstants

const FleetCount = styled.div`
  position: relative;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 120px;
`

const Num = styled.div`
  text-align: center;
  width: 100px;
  font-size: ${fontSize.text_24}rem;
  font-weight: ${fontWeight.bold};
`

const HeaderWithBadge = styled(StyledFleetCard.Header)`
  display: flex;
  justify-content: space-between;
  max-height: 36px;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0 0 0 ${spacing.absolute.m}px;
  padding: 0;
  flex: 1;
`

const ListItem = styled.li`
  padding: 0 0 ${spacing.s}rem 0px;
`

export interface FleetCardProps {
  alerts?: Alert[];
  id: number;
  name: string;
  onClick: () => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  fleet_category?: string;
  vehicle_plugged_in: number;
  vehicles_count: number;
  t: TFunction;
}

const FleetCard = ({
  alerts,
  fleet_category,
  id,
  name,
  onClick,
  onKeyPress,
  t,
  vehicle_plugged_in,
  vehicles_count,
}: FleetCardProps) => {
  const iconMap = {
    DELIVERY_TRUCK : 'deliveryTruck',
    PASSENGER_CAR  : 'car',
    BUS            : 'bus',
  }

  const vehicleIcon            = iconMap[fleet_category as keyof typeof iconMap] ?? iconMap.DELIVERY_TRUCK
  const highSeverityAlertCount = alertUtils.numberOfHighSeverity(
    alerts as Alert[],
  )
  const numUnpluggedVehicles   = (() => {
    if (hasValue(vehicles_count) && hasValue(vehicle_plugged_in)) {
      return vehicles_count - vehicle_plugged_in
    }
    return EMPTY_VALUE_PLACEHOLDER
  })()

  return (
    <StyledFleetCard
      data-qa-id="fleet_card"
      tabIndex={0}
      data-id={id}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      <HeaderWithBadge>
        <EllipsisText
          data-qa-id="card_title"
          title={name}
          style={{ maxWidth: '240px' }}
        >
          { name }
        </EllipsisText>
        { highSeverityAlertCount > 0 && (
          <Badge shape="circle" variant="error">
            { highSeverityAlertCount }
          </Badge>
        ) }
      </HeaderWithBadge>
      <StyledFleetCard.Body className="card-body">
        <KitFlexRowSpaced>
          <FleetCount>
            <FleetIcon
              style={{
                height       : '50px',
                marginBottom : `${spacing.absolute.xs + 2}px`,
              }}
              iconType={vehicleIcon}
              useBg={false}
              altText={t('vehicle_image_alt_text', { vehicle_type: fleet_category })}
            />
            <Num data-qa-id="vehicle_count_value">
              { vehicles_count ?? EMPTY_VALUE_PLACEHOLDER }
            </Num>
          </FleetCount>
          <List>
            <ListItem>
              <StyledFleetCard.Label>{ t('plugged_in') }</StyledFleetCard.Label>
              <StyledFleetCard.Value
                data-qa-id="plugged_in_vehicle_count_value"
                size="large"
                bold
              >
                { vehicle_plugged_in ?? EMPTY_VALUE_PLACEHOLDER }
              </StyledFleetCard.Value>
            </ListItem>
            <ListItem>
              <StyledFleetCard.Label>
                { t('not_plugged_in') }
              </StyledFleetCard.Label>
              <StyledFleetCard.Value
                data-qa-id="not_plugged_in_vehicle_count_value"
                size="large"
                bold
              >
                { numUnpluggedVehicles }
              </StyledFleetCard.Value>
            </ListItem>
          </List>
        </KitFlexRowSpaced>
      </StyledFleetCard.Body>
    </StyledFleetCard>
  )
}

FleetCard.defaultProps = {
  alerts         : null,
  fleet_category : null,
}

export default FleetCard
