import { KitUtilData } from '@chargepoint/cp-toolkit'

import { hasValue } from './validations'
import { type ToOptionsArrayProps } from '@/models/dataModel'
import type { DepotDetailsRow } from '@/models/depotModel'
import { type Filter, type FilterFunc } from '@/types/filters'
import { type SimpleType, type SortFunc, type valueof } from '@/types/index'

const { isEmpty } = KitUtilData
type Datum<T = unknown> = Record<string, T>;

export const SortDir = {
  DESC : 0,
  ASC  : 1,
}

/**  Data utilities */

/**
 * Returns + when num1 is > num2
 * Returns - when num1 is < num2
 * Returns '' when they are equal or one or more of the values is empty
 * @param num1 (number to compare against num2)
 * @param num2
 * @returns
 */
export const getSign = (num1: number, num2: number): '+' | '-' | '' => {
  if (hasValue(num1) && hasValue(num2) && num1 !== num2) {
    return num1 > num2 ? '+' : '-'
  }
  return ''
}

// checks if the attributes in obj1 are equal in obj2
export const attributesEqual = <T>(obj1: T, obj2: T): boolean =>
  // eslint-disable-next-line implicit-arrow-linebreak
  Object.entries(obj1 as ArrayLike<unknown>).every(([key, value]) => (obj2 as Record<string, unknown>)[key] === (value as unknown))

export const omit = <T = ArrayLike<unknown>>(
  obj: T = {} as T,
  keysToOmit: string[] | ((val: unknown) => boolean),
) => {
  if (typeof keysToOmit === 'function') {
    return Object.fromEntries(
      Object.entries(obj as ArrayLike<unknown>).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_key, val]) => !keysToOmit(val),
      ),
    )
  }
  return Object.fromEntries(
    Object.entries(obj as ArrayLike<unknown>).filter(
      ([key]) => !keysToOmit.includes(key),
    ),
  )
}

// CRUD operations

export const addItem = <T>(data: T[], record: T): T[] => [...(data?.length ? data : []), record]

export function updateItem<T>(
  data: T[],
  record: T,
  key: string | number = 'id',
): T[] {
  const typedKey = key as keyof T
  // exit early if there is nothing to iterate or match on
  if (!data || !hasValue(record[typedKey])) {
    return data
  }

  // check if data is actually updated before we replace the entire array
  const match = data.find((item) => item[typedKey] === record[typedKey])
  if (match && !attributesEqual(record, match)) {
    return data.reduce((acc: T[], item: T) => {
      let updatedItem = item
      if (item[typedKey] === record[typedKey]) {
        updatedItem = { ...item, ...omit(record, (v) => !hasValue(v)) }
      }
      acc.push(updatedItem)
      return acc
    }, [])
  }

  return data
}

export const deleteItem = <T>(data: T[], opts: Partial<T>, key = 'id'): T[] => {
  const typedKey = key as keyof T
  return data?.filter((item) => item[typedKey] !== parseInt(opts[typedKey] as string))
}

// math helpers

export const avg = (array: number[]): number => array.reduce((a, b) => a + b) / array.length

export const sum = (array: number[]): number => array.reduce((a, b) => a + b)

export const min = (array: number[]): number => array.reduce((a, b) => Math.min(a, b), Infinity)

export const max = (array: number[]): number => array.reduce((a, b) => Math.max(a, b), -Infinity)

export const minBy = <T = Record<string, number>>(
  arr: T[],
  key: string,
): T[] => {
  const select = (a: T, b: T) => (a[key as keyof T] <= b[key as keyof T] ? a : b)
  return arr.reduce(select, {} as unknown as T) as T[]
}

// return item with earliest date
export const minByDate = <T = Record<string, string | Date>>(
  arr: T[],
  key: keyof T,
): T => {
  const select = (a: T, b: T) => {
    let aVal = a[key] as Date | string
    let bVal = b[key] as Date | string
    aVal     = typeof aVal === 'string' ? new Date(aVal) : (aVal as Date)
    bVal     = typeof bVal === 'string' ? new Date(bVal) : (aVal as Date)
    return aVal <= bVal ? a : b
  }
  return arr.reduce(select, {} as T)
}

// return item with latest date
export const maxByDate = <T = Record<string, string | Date>>(
  arr: T[],
  key: keyof T,
): T => {
  const select = (a: T, b: T) => {
    let aVal = a[key as keyof T] as Date
    let bVal = b[key as keyof T] as Date
    aVal     = typeof aVal === 'string' ? new Date(aVal) : aVal
    bVal     = typeof bVal === 'string' ? new Date(bVal) : aVal
    return aVal >= bVal ? a : b
  }
  return arr.reduce(select, {} as T)
}

// sort helpers

export const numericSort = (
  fieldName: string,
  dir = SortDir.DESC,
): SortFunc => (a: Datum | number, b: Datum | number) => {
  let aVal: number = a as number
  let bVal: number = b as number
  if (fieldName) {
    aVal = (a as Datum)[fieldName] as number
    bVal = (b as Datum)[fieldName] as number
  }
  if (aVal === null) {
    return 1
  }
  if (bVal === null) {
    return -1
  }
  return dir === SortDir.DESC ? bVal - aVal : aVal - bVal
}

export const alphaSort = (fieldName: string, dir = SortDir.ASC): SortFunc => {
  if (Number.isNaN(Number(dir))) {
    return () => 0
  }

  return (a: Datum | string, b: Datum | string) => {
    let aVal = a
    let bVal = b
    if (fieldName) {
      aVal = (a as Datum)[fieldName] as string
      bVal = (b as Datum)[fieldName] as string
    }

    if (aVal === bVal) {
      return 0
    } if (isEmpty(aVal)) {
      return 1
    } if (isEmpty(bVal)) {
      return -1
    }
    return dir === SortDir.ASC
      ? (aVal as string).localeCompare(bVal as string)
      : (bVal as string).localeCompare(aVal as string)
  }
}

// sorts alpha unless numeric values are found which are sorted before alpha chars
export const alphaNumericSort = (
  fieldName: string,
  dir = SortDir.ASC,
): SortFunc => (a: Datum | string, b: Datum | string) => {
  const aVal = a
    ? (fieldName
      ? ((a as Datum)[fieldName] as string)
      : (a as string)
    )?.toLowerCase()
    : ''
  const bVal = b
    ? (fieldName
      ? ((b as Datum)[fieldName] as string)
      : (b as string)
    )?.toLowerCase()
    : ''
  return dir === SortDir.ASC
    ? aVal?.localeCompare(bVal)
    : (bVal?.localeCompare(aVal) as number)
}

export const dateSort = <T = Record<string, string>>(
  fieldName: string,
  dir = SortDir.DESC,
): SortFunc => {
  type K = keyof T;
  return (a: T, b: T) => {
    let aVal: Date
    let bVal: Date
    if (fieldName) {
      aVal =        typeof a[fieldName as K] === 'string'
        ? new Date(a[fieldName as K] as string)
        : (a[fieldName as K] as Date)
      bVal =        typeof b[fieldName as K] === 'string'
        ? new Date(b[fieldName as K] as string)
        : (b[fieldName as K] as Date)
    } else {
      bVal = typeof a === 'string' ? new Date(a) : (a as Date)
      aVal = typeof b === 'string' ? new Date(b) : (b as Date)
    }
    if (aVal === bVal) {
      return 0
    } if (isEmpty(aVal)) {
      return 1
    } if (isEmpty(bVal)) {
      return -1
    } if (aVal.getTime() > bVal.getTime()) {
      return dir === SortDir.DESC ? -1 : 1
    } if (aVal.getTime() < bVal.getTime()) {
      return dir === SortDir.DESC ? 1 : -1
    }
    return 0
  }
}

/**
 * Sorts an array by a timestring field.
 * Usage:
 *    const sortFn = timeStringSort('ts');
 *    const sorted = [{ts: '10:37'}, {ts: '18:01'}].sort(sortFn);
 * @param fieldName
 * @param dir
 * @returns
 */
export const timeStringSort = (
  fieldName: string,
  dir = SortDir.DESC,
): SortFunc => (a: Record<string, string>, b: Record<string, string>) => {
  const aVal = a[fieldName]
  const bVal = b[fieldName]

  const [aHours, aMinutes] = aVal ? aVal.split(':') : ''
  const [bHours, bMinutes] = bVal ? bVal.split(':') : ''
  if (parseInt(aHours) === parseInt(bHours)) {
    return parseInt(aMinutes) - parseInt(bMinutes)
  }

  return dir === SortDir.DESC
    ? parseInt(aHours) - parseInt(bHours)
    : parseInt(bHours) - parseInt(aHours)
}

/**
 * Returns true if searchText is found in record or no searchText is passed
 * NOTE: This is currently a case-insensitive search, as that is most common desired behavior
 * @param {string} searchText
 * @param {Record<string, string> | string} record
 * @param {string[]} fieldsToSearch - if record is an object, this will search multiple fields for a match
 * @returns
 */
export function stringSearch<R = Record<string, unknown>>(
  searchText: string,
  record: R,
  fieldsToSearch: string[],
): boolean {
  if (searchText && searchText.length) {
    const normalizedSearchText = searchText.toLowerCase()
    if (fieldsToSearch) {
      return fieldsToSearch.some((fieldName) => {
        const field = record[fieldName as keyof R] as string
        const str   = field ? field.toLowerCase() : ''
        return str.includes(normalizedSearchText)
      })
    }
    return typeof record === 'string'
      ? (record as string).toLowerCase().includes(normalizedSearchText)
      : false
  }
  return true
}

/**
 * Filter {
 *   name: name of filter
 *   fn?: (compareValue, record)=> boolean Optional compare function that should return true or false
 *   value: the value that the actual data should be compared against
 * }
 */

/**
 * Applies multiple filters to a dataset then returns matches
 * @param {Record<string, unknown>[]} data
 * @param {Filter[]} filters
 * @param {object} fns -- map of functions used to filter data
 */
export const filterData = <T = unknown>(
  data: T[],
  filters: Filter[],
  fns?: { [key: string]: (val: unknown, record: T) => boolean },
) => {
  if (!data) {
    return data
  }
  const results = data.concat()
  if (filters && filters.length) {
    return results.filter((item) => filters.reduce((match: boolean, filt) => {
      if (filt.fn || fns?.[filt.name as keyof typeof fns]) {
        const fn = (filt.fn
            ?? fns?.[filt.name as keyof typeof fns]) as FilterFunc
        // if the filter.value is array and the array is empty, then return true
        // eslint-disable-next-line no-param-reassign
        match =            Array.isArray(filt.value) && !filt.value.length
          ? true
          : match
                && fn(filt.value as string, item as Record<string, unknown>)
      } else {
        // eslint-disable-next-line no-param-reassign
        match =            match && Array.isArray(item[filt.field as keyof T])
          ? (item[filt.field as keyof T] as T[]).includes(filt.value as T)
          : item[filt.field as keyof T] === filt.value
      }
      return match
    }, true))
  }

  return results
}

/*
 * Groups a single dimensional array into a multidimensional one
 * @param arr (unknown[])
 * @param innerSize (number) - size that the inner array should be
 */
export function toMultiDimensional<A extends unknown[]>(arr: A, innerSize = 1) {
  const numColumns =    arr.length > innerSize ? Math.ceil(arr.length / innerSize) : innerSize
  const cols       = [...Array(numColumns)]
  let nextIdx      = 0
  const result     = cols.map((_col, idx) => {
    nextIdx += innerSize
    return arr.slice(innerSize * idx, nextIdx)
  })

  return result
}

/**
 * Groups array by field
 * @param {*} arr
 * @param {*} groupByField
 * @returns object like
 *  {
 *     someValueOne: [...matches],
 *     someValueTwo: [...matches],
 *  }
 *
 */
export function groupBy<T = Record<string, unknown>>(
  arr: T[],
  groupByField: string,
): Record<string, T[]> {
  return arr
    ? arr.reduce<Record<string, T[]>>((acc, item) => {
      (
        acc[
          KitUtilData.getProp(
            item as Record<string, unknown>,
            groupByField,
          ) as string
        ]
          || (acc[
            KitUtilData.getProp(
              item as Record<string, unknown>,
              groupByField,
            ) as string
          ] = [])
      ).push(item)
      return acc
    }, {})
    : arr
}

const getLabel = (item: Record<string, string>, labelField: string | string[]) => {
  if (labelField) {
    if (Array.isArray(labelField)) {
      return labelField.reduce((acc, fld) => (item[fld] ? `${acc} ${item[fld]}` : acc), '')
    }
    return item[labelField]
  }
  return item
}

export function toOptionsArray<T = Record<string, unknown>>(
  arr: T[],
  opts: ToOptionsArrayProps = { labelField: '', valueField: '' },
) {
  const { initialOption, labelField, valueField } = opts
  const result: Record<string, unknown>[]         = []

  if (initialOption) {
    result.push(initialOption)
  }

  return arr
    ? result.concat(
      arr?.map((item) => ({
        id    : opts?.id,
        label : getLabel(item, labelField),
        value : valueField
          ? (item as Record<string, unknown>)[valueField]
          : item,
      })),
    )
    : []
}

export const has = <T = Record<string, unknown>>(
  obj: T,
  key: string,
): boolean => {
  const keyParts = key.split('.')
  return (
    !!obj
    && (keyParts.length > 1
      ? has(
        obj[key.split('.')[0] as keyof T] as Record<string, unknown>,
        keyParts.slice(1).join('.'),
      )
      : Object.prototype.hasOwnProperty.call(obj, key))
  )
}

export const pick = (
  object: Record<string, unknown>,
  keys: string[],
): Record<string, unknown> => keys.reduce((obj: Record<string, unknown>, key: string) => {
  if (object && has(object, key)) {
    obj[key] = object[key]
  }
  return obj
}, {})

export const uniqBy = <T = Record<string, unknown>>(
  arr: T[],
  uniqueField: string,
): unknown[] => {
  const matchedValues: unknown[] = []
  return arr.filter((item) => {
    if (!matchedValues.includes(item[uniqueField as keyof T] as T)) {
      matchedValues.push(item[uniqueField as keyof T] as T)
      return true
    }
    return false
  })
}

// returns the values from array a that are not present in array b
export const diff = (a1: unknown[], a2: unknown[]): unknown[] => {
  const arrays = [a1, a2]
  return arrays.reduce((a, b) => a.filter((c) => !b.includes(c)))
}

/**
 * Compares two objects to make sure they have the same keys and values
 * @param obj1
 * @param obj2
 * @returns boolean
 */
export const isEqual = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
): boolean => {
  if (!hasValue(obj1) && !hasValue(obj2)) {
    return true
  }
  if ((obj1 && !obj2) || (!obj1 && obj2)) {
    return false
  }

  const k1 = Object.keys(obj1).sort()
  const k2 = Object.keys(obj2).sort()
  const e1 = Object.entries(obj1)

  if (k1.length !== k2.length) {
    return false
  }
  const keysEqual = !diff(k2, k1).length
  return e1.reduce((acc, [k, v]) => {
    if (obj2[k] !== v) {
      return false
    }
    return acc
  }, keysEqual)
}

/**
 * Returns a property from a matched array item. Returns null when nothing is matched.
 * @param {*} arr
 * @param {*} matchKey
 * @param {*} matchValue
 * @param {*} returnPropKey
 * @returns SimpleType
 */
export function getPropIfMatched<T = Record<string, SimpleType>>(
  arr: T[],
  matchKey: string,
  matchValue: SimpleType,
  returnPropKey: string,
): SimpleType {
  return (arr?.find(
    (item) => item[matchKey as keyof typeof item] === matchValue,
  )?.[returnPropKey as keyof T] ?? null) as valueof<T>
}

export const normalizeStringValue = (val: string | null): string => {
  if (!val || (typeof val === 'string' && !val.length)) {
    return '--'
  }
  return val
}

export const randRange = (minVal: number, maxVal: number): number => Math.floor(Math.random() * (maxVal - minVal + 1) + minVal)

// remove spaces and special chars from string to be used as key
export const cleanKey = (s: string) => s?.replace(/[\s':+-.]/g, '_').toLocaleLowerCase()

// deep object clone
export const cloneDeep = (
  obj: Record<string, unknown> | Record<string, unknown>[],
) => JSON.parse(JSON.stringify(obj))

/**
 * Strip entries from result object if their values are null | undefined
 * */
export const stripNullProps = <T = Record<string, unknown>>(obj: T): T => omit(obj, (val) => [null, undefined].includes(val)) as T

export const ddvStatusSort =  (sortDir: number) => (a: DepotDetailsRow, b: DepotDetailsRow) => {
  const aVal = a.status_code
  const bVal = b.status_code

  if (aVal === bVal) {
    if (a.port_external_id && a.vehicle_external_id
      && (!b.port_external_id || !b.vehicle_external_id)) {
      return -1
    }
    if ((!a.port_external_id || !a.vehicle_external_id)
    && (b.port_external_id && b.vehicle_external_id)) {
      return 1
    }
    return 0
  } if (isEmpty(aVal)) {
    return 1
  } if (isEmpty(bVal)) {
    return -1
  }
  return sortDir === SortDir.ASC ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal)
}

export const isBetween = (num: number, minVal: number, maxVal: number): boolean => num >= minVal && num <= maxVal
