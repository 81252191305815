import {
  KitButton,
  KitButtonBar,
  KitForm,
  KitModal,
  KitModalSize,
  KitSpinner,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { FleetOptimization } from '../../../interfaces'
import SiteDetailsStore from '../siteStore'
import { ModalFooter } from './index.styles'
import { omit } from '@/common/utils/data'
import { hasValue } from '@/common/utils/validations'
import { SubHeadingBold } from '@/components/Styled'
import { type Fleet } from '@/models/fleetModel'
import { type NormalizedChangeEvent } from '@/types/index'

const { spacing } = ThemeConstants

const customStyles = `
      min-height: 200px;
      margin: calc((100vh - 250px) / 2) auto;
  `

interface FormInputErrors {
  [fieldName: string]: {
    message: string;
  };
}

export interface AddFleetOptimizationProps {
  omittedFleetIDs: string[];
  show: boolean;
  fleetOptimization: FleetOptimization | undefined;
  onSave: (currentValue: FleetOptimization, previousValue?: FleetOptimization) => void;
  onCancel: () => void;
}

const getSelectedFleetOption = (
  selectedFleetID: string | undefined,
  opt: FleetOptimization | Fleet,
  fleets: Fleet[],
): FleetOptimization => {
  if (['-1', undefined].includes(selectedFleetID)) {
    return opt as FleetOptimization
  }

  const fleet = fleets.find((f) => f.external_id === selectedFleetID)
  const id    = fleet?.external_id ?? (opt as FleetOptimization)?.fleet_external_id
  const label = fleet?.name ?? opt?.name

  const result = {
    ...(fleet ?? opt),
    ...{
      fleet_external_id : id,
      name              : label,
      label,
    },
  }

  return omit(result, (val: unknown) => !hasValue(val)) as unknown as FleetOptimization
}

const AddFleetOptimizationModal = (props: AddFleetOptimizationProps) => {
  const { t }                                                          = useTranslation()
  const { fleetOptimization, omittedFleetIDs, onCancel, onSave, show } = props

  const [selectedFleetID, setSelectedFleetID]         = useState<string>()
  const [selectedFleetOption, setSelectedFleetOption] = useState<FleetOptimization>()
  const [formErrors, setFormErrors]                   = useState<FormInputErrors>({})

  const showSOC      = selectedFleetID === '-1'
  const ready        = SiteDetailsStore.initialized
  const fleetOptions = SiteDetailsStore.fleets?.filter((f) => !omittedFleetIDs.includes(f.external_id)).map((fleet) => ({
    label : fleet.name,
    value : fleet.external_id,
  }))

  const handleSubmit = useCallback(() => {
    if (selectedFleetOption) {
      const fleet_external_id = selectedFleetOption.fleet_external_id ?? selectedFleetOption.external_id
      const payload           = {
        ...selectedFleetOption,
        fleet_external_id,
      }
      onSave(payload, fleetOptimization)
    }
  }, [selectedFleetOption])

  const onChange = (fieldName: string, val: string | number) => {
    if (fieldName === 'fleetId' && SiteDetailsStore.fleets) {
      setSelectedFleetID(val as string)
      setSelectedFleetOption(
        getSelectedFleetOption(val as string, undefined, SiteDetailsStore.fleets),
      )
    }
  }

  useEffect(() => {
    const { fleet_external_id } = fleetOptimization ?? {}
    setSelectedFleetID(fleet_external_id)

    if (fleet_external_id && SiteDetailsStore.fleets) {
      setSelectedFleetOption(
        getSelectedFleetOption(fleet_external_id, fleetOptimization as FleetOptimization, SiteDetailsStore.fleets),
      )
    } else if (!fleet_external_id) {
      setSelectedFleetOption(undefined)
    }
  }, [show, fleetOptimization, SiteDetailsStore.fleets])

  return (
    <KitModal
      show={show}
      size={KitModalSize.sm}
      closeOnClickOutside={false}
      customStyles={customStyles}
    >
      <KitModal.Body style={{ padding: `${spacing.absolute.l}px` }}>
        <SubHeadingBold>
          { selectedFleetID ? t('energy_management.editFleet') : t('energy_management.addFleet') }
        </SubHeadingBold>
        { !ready && (
          <KitSpinner size="s" align="middle" />
        ) }
        { ready && (
          <>
            <KitForm noValidate>
              <KitForm.Group>
                <KitForm.Label
                  htmlFor="fleetId"
                  text={t('energy_management.fleet')}
                  required
                />

                { !showSOC && (
                  <KitForm.Select
                    key={`fleet-${selectedFleetID}`}
                    id="fleetId"
                    name="fleetId"
                    data-qa-id="fleetId-input"
                    options={fleetOptions}
                    isError={hasValue(formErrors.fleetId)}
                    infoMessage={formErrors.fleetId?.message}
                    required={true}
                    value={selectedFleetOption}
                    onChange={(e: NormalizedChangeEvent) => onChange('fleetId', e.value)}
                  />
                ) }
              </KitForm.Group>
            </KitForm>
          </>
        ) }
      </KitModal.Body>
      <ModalFooter>
        <KitButtonBar
          className="buttonBar"
          primary={
            <KitButton
              variant="primary"
              onClick={handleSubmit}
              data-qa-id="save-add-fleet-optimization-btn"
            >
              { t('btn_save') }
            </KitButton>
          }
          secondary={
            <KitButton
              variant="secondary"
              onClick={onCancel}
              data-qa-id="cancel-add-fleet-optimization-btn"
            >
              { t('btn_cancel') }
            </KitButton>
          }
        />
      </ModalFooter>
    </KitModal>
  )
}

export default observer(AddFleetOptimizationModal)
