import {
  KitAddress,
  KitConstants,
  KitFlexRow,
  KitTips,
  KitUtilCommon,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { formatInTimeZone } from 'date-fns-tz'
import { type TFunction } from 'i18next'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import StyledFleetCard from '../../components/StyledFleetCard'
import DepotCardDetails from './DepotCardDetails'
import { getLocaleCode, parseLocale } from '@/common/lang'
import { getConstant } from '@/common/utils'
import Badge from '@/components/Badge'
import StaticMap from '@/components/MiniMap/StaticMap'
import { type Depot } from '@/models/depotModel'

const DEPOT_STATUS_ACTIVE = 'ACTIVE'

const { fontSize, fontWeight, spacing } = ThemeConstants

const DepotName = styled.span``

const StyledDepotCard = styled(StyledFleetCard)`
  max-height: 220px !important;
`

const MapCol = styled.div`
  width: 165px;
  margin-right: ${spacing.absolute.s}px;
`

const AdditionalInfo = styled.div`
  && {
    font-size: ${fontSize.text_12}rem;
    margin-top: ${spacing.absolute.xs}px;
    color: ${ThemeColors.gray_70};
    div {
      margin-left: 0;
      padding: 0;
      font-weight: ${fontWeight.normal};
      margin-bottom: ${spacing.xs}rem;
    }
  }
`

const TimeZoneInfo = styled.div`
  color: ${ThemeColors.gray_70};
  font-size: ${fontSize.text_12}rem;
  font-weight: 400;
`

const NotReadySpan = styled.div`
  color: ${ThemeColors.gray_70};
  font-size: ${fontSize.text_12}rem;
  font-weight: 400;
`

const TtipContent = styled.div`
  padding: ${spacing.s}rem;
`

type Props = {
  depot: Depot;
  alerts: number;
  t: TFunction;
};

function safelyGetDepotTimezone(timeZone: string): string {
  try {
    // this will throw uncaught exception if timeZone is not recognized
    return formatInTimeZone(new Date().getTime(), timeZone, 'HH:mm zz (O)')
  } catch (err) { /* empty */ }
  return ''
}

function DepotCard({ alerts, depot, t }: Props) {
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate(`/depot/${depot.external_id}`)
  }

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      KitUtilCommon.isKey(
        [KitConstants.KeyConstants.ENTER, KitConstants.KeyConstants.SPACE],
        ev as unknown as KeyboardEvent,
      )
    ) {
      handleOnClick()
    }
  }

  const isActive         = depot.status === 'ACTIVE'
  const parsedLocale     = parseLocale(getLocaleCode())
  const alertTooltipText = t('depots.cards.alerts_tooltip_text', {
    depotName  : depot.name,
    alertCount : alerts,
  })

  return (<KitTips
    arrow={false}
    className="data-ttip"
    placement="top-end"
    content={<TtipContent role="tooltip" aria-live="polite">{ alertTooltipText }</TtipContent>}
    offset={[4, 8]}
    disabled={true /* disabling this for now as UX has not been finalized */}
  >
    <StyledDepotCard
      data-qa-id="depot_card"
      onKeyPress={handleKeyPress}
      onClick={handleOnClick}
      isActive={isActive}
      aria-disabled={!isActive}
      tabIndex={0}
    >
      <StyledFleetCard.Header hasBadge>
        <DepotName data-qa-id="card_title">{ depot.name }</DepotName>
        { depot.status === DEPOT_STATUS_ACTIVE && alerts > 0
          && <Badge variant="error">{ alerts }</Badge>
        }
        { depot.status !== DEPOT_STATUS_ACTIVE && (
          <NotReadySpan>{ t('not_ready') }</NotReadySpan>
        ) }
      </StyledFleetCard.Header>
      <StyledFleetCard.Body>
        <KitFlexRow>
          <MapCol>
            <StaticMap
              altText={t('depots.cards.thumbnail_map_alt_text', { depotName: depot.name })}
              markers={[{ lat: depot.latitude, lng: depot.longitude }]}
              zoom={14}
              apiKey={getConstant('GOOGLE_MAPS_API_KEY') as string}
            />
          </MapCol>
          <DepotCardDetails
            depot_is_active={depot.status === DEPOT_STATUS_ACTIVE}
            vehicles_plugged_in={depot.vehicles_plugged_in_count as number}
            ports={depot.port_count as number}
          />
        </KitFlexRow>
        <AdditionalInfo>
          <KitAddress
            contactInfo={{
              address1    : depot.address_1 as string,
              address2    : depot.address_2 as string,
              city        : depot.city,
              countryCode : depot.state ? '' : depot.country_iso_code,
              stateCode   : depot.state,
              zipcode     : depot.zipcode,
            }}
            countryCode={parsedLocale.countryCode}
            options={{
              useDefaultAddressData : true,
              hideLabels            : true,
              hideEmptyFields       : true,
              showAddressAsString   : true,
              hiddenFields          : ['address1', 'address2', 'zipcode'],
            }}
          />
          <TimeZoneInfo>{ safelyGetDepotTimezone(depot.timezone) }</TimeZoneInfo>
        </AdditionalInfo>
      </StyledFleetCard.Body>
    </StyledDepotCard>
  </KitTips>)
}

export default DepotCard
