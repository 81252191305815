import { type FC } from 'react'

import Notification, { NotificationLevel } from '@/components/Notification'

export interface AddVehicleServiceErrorProps {
  message?: string;
}

const AddVehicleServiceError: FC<AddVehicleServiceErrorProps> = (
  props: AddVehicleServiceErrorProps,
) => (
  <Notification type={NotificationLevel.ERROR}>{ props.message }</Notification>
)

export default AddVehicleServiceError
