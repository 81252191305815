import { KitLink } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, type ReactNode } from 'react'

import { List, ListItemSmall } from '@/components/Styled'

export interface ErrorPageProps {
  children?: ReactNode;
  links?: Link[];
  t: TFunction;
}

export interface Link {
  label: string;
  url: string;
}

export type ErrorComponentDef = FC<ErrorPageProps>;

export const renderLinks = (
  links: Link[] | undefined,
  t: TFunction,
): ReactNode => (links ? (
  <List>
    { links?.map(({ label, url }) => (
      <ListItemSmall key={url}>
        <KitLink href={url} t={t}>
          { label }
        </KitLink>
      </ListItemSmall>
    )) }
  </List>
) : null)
