import {
  KitInput,
  KitUtilData,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import classnames from 'classnames'
import { type ChangeEvent, type FC } from 'react'

import styled from 'styled-components'

import { hasValue } from '@/common/utils/validations'
import { FormItem, InputGroup, Label } from '@/components/Styled'

const Container = styled.div`
  label {
    color: ${ThemeColors.gray_50};
    display: block;
    margin-bottom: ${ThemeConstants.spacing.absolute.s}px;
  }
`

const SmallInput = styled(KitInput)`
  width: 120px;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`

const MinutesInput = styled(KitInput)`
  width: 60px;
  max-width: 60px;
`

const SmallInputContainer = styled.div`
  display: inline-block;
  margin-right: ${ThemeConstants.spacing.absolute.s}px;
  width: 60px;
`

const InputUnits = styled.span``

export interface TimeInputProps {
  errors: Record<string, string>;
  name: string;
  labels: Record<string, string>;
  onChange: (fieldName: string, val: unknown) => void;
  required?: boolean;
  values: Record<string, unknown>;
}

const TimeInput: FC<TimeInputProps> = ({
  errors = {},
  labels,
  name,
  onChange,
  required,
  values = {},
}) => {
  const downTimeId        = `${name}_time_downtime_secs`
  const handleInputChange = (
    fieldName: string,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    let { target: { value } } = e
    if ([`${name}_time`].includes(fieldName)) {
      if (value) {
        const parts = value.split(':')
        value       = `${parts[0]}:${parts[1]}`
      }
    } else if (parseInt(value) > 999) {
      return
    }
    onChange(fieldName, value ?? 0)
  }

  return (
    <Container>
      <InputGroup>
        <FormItem>
          <Label className={classnames({ required })} htmlFor={`${name}-time`}>
            { labels?.time }
          </Label>
          <SmallInput
            isError={hasValue(errors[`${name}_time`])}
            infoMessage={errors[`${name}_time`] as string}
            id={`${name}-time`}
            defaultValue={
              (KitUtilData.getProp(values, `${name}_time`) as number) ?? 0
            }
            onChange={(e) => handleInputChange(`${name}_time`, e)}
            type="time"
            required={required}
          />
        </FormItem>

        <FormItem>
          <Label
            className={classnames({ required })}
            htmlFor={`${name}-downtime`}
          >
            { labels?.downtime }
          </Label>
          <SmallInputContainer>
            <MinutesInput
              min={0}
              max={60}
              defaultValue={
                (KitUtilData.getProp(values, downTimeId) as number) ?? 0
              }
              isError={hasValue(errors[`${name}_downtime`])}
              infoMessage={errors[`${name}_downtime`]}
              id={`${name}-downtime`}
              type="number"
              onChange={(e) => handleInputChange(downTimeId, e)}
              required={required}
            />
          </SmallInputContainer>
          <InputUnits>{ labels?.downtimeUnits }</InputUnits>
        </FormItem>
      </InputGroup>
    </Container>
  )
}

export default TimeInput
