import { ThemeConstants } from '@chargepoint/cp-toolkit'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom'

import styled from 'styled-components'

import i18n from '../../../i18n'
import {
  ErrorDisplayComponent,
  ErrorDisplayType,
} from '../../../models/serviceModel'
import EnergySiteCard from '../EnergySiteCard'
import { UILayout } from '@/common/constants'
import RouteError from '@/common/errors/RouteError'
import CommonHeader from '@/components/CommonHeader'
import Page from '@/components/Page'
import RouteErrorHandler from '@/components/RouteErrorHandler'
import { CardLayout, HeaderContainer, PageContent } from '@/components/Styled'
import StyledSpinner from '@/components/StyledSpinner'
import EnergyManagementService from '@/services/EnergyManagementService'

const { breakpoints } = ThemeConstants

const PageContentAligned = styled(PageContent)`
  padding-left: ${UILayout.hPageMargin}px;

  @media all and (max-width: ${breakpoints.md}px) {
    padding-left: ${UILayout.mobile.hPageMargin}px;
  }
`

const SiteList = () => {
  const { t }    = useTranslation()
  const navigate = useNavigate()
  const data     = useLoaderData()

  const siteChartEnabled = false

  const selectSite = (siteId: string) => {
    navigate(`${siteId}`)
  }

  return (
    <Page title={t('page_titles.site_list')}>
      <HeaderContainer bottomMargin="16px">
        <CommonHeader.Title>{ t('energy') }</CommonHeader.Title>
      </HeaderContainer>
      <PageContentAligned data-qa-id="energy_cards_container">
        <Suspense fallback={<StyledSpinner />}>
          <Await resolve={data.response} errorElement={<RouteErrorHandler />}>
            { (results) => (
              <CardLayout style={{ height: '100%' }}>
                { results?.map((site) => (
                  <EnergySiteCard
                    showChart={siteChartEnabled}
                    onClick={() => selectSite(site.external_id)}
                    key={site.external_id}
                    site={site}
                  />
                )) }
              </CardLayout>
            ) }
          </Await>
        </Suspense>
      </PageContentAligned>
    </Page>
  )
}

export const siteListLoader = async () => {
  const response = EnergyManagementService.getSites().then(
    ({ error, results }) => {
      if (results) {
        return results
      }

      throw new RouteError({
        message          : i18n.t('energy_management.sites_request_error'),
        code             : error?.status as number,
        displayComponent : ErrorDisplayComponent.SadCar,
        displayAs        : ErrorDisplayType.FullPage,
      })
    },
  )

  return defer({ response })
}

export default SiteList
