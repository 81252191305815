import format from 'date-fns/format'
import { PureComponent } from 'react'

import { ISO_DATE_TIME, type Period } from '@/common/constants'

export interface AxisTickProps {
  domain: [number, number];
  formatter?: (
    value: number,
    duration: Period,
    domain: [number, number]
  ) => string | null;
  duration?: Period;
  payload?: { value: number };
  x?: number;
  y?: number;
}

export default class CustomizedXAxisTick extends PureComponent<AxisTickProps> {
  render() {
    const { domain, duration, formatter, payload, x, y } = this.props
    if (!payload) {
      return null
    }
    const label =      formatter && duration
      ? formatter(payload.value, duration, domain)
      : format(payload.value, ISO_DATE_TIME)
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle">
          { label }
        </text>
      </g>
    )
  }
}
