import { KitUtilData } from '@chargepoint/cp-toolkit'

export enum StorageType {
  localStorage,
  sessionStorage,
}

interface StorageOpts {
  // timestamp
  expires?: number;
}

export interface StorageEntry {
  type?: string;
  value: number | string | boolean | Record<string, unknown> | unknown;
  expires?: number;
}

/**
 * Class that extends localStorage object and provides the following:
 *  - automatic serialization / deserialization of localStorage values
 *  - ability to set expiry time on value
 *
 */
class Storage {
  // used to break any old caches after deployments
  version = ''
  private store = window.localStorage

  //   constructor() {}

  exists(key: string): boolean {
    return !!this.get(key)
  }
  delete(key: string): void {
    this.store.removeItem(key)
  }

  get<T = unknown>(
    key: string,
    validateFunc?: (entry: StorageEntry) => boolean,
  ): T | boolean | number | undefined {
    const today = new Date()
    const item  = this.store.getItem(`${key}_${this.version}`)

    if (item) {
      const parsed: StorageEntry = JSON.parse(item)

      if (validateFunc) {
        if (!validateFunc(parsed)) {
          return undefined
        }
      }

      if (
        parsed.expires
        && new Date(parsed.expires).getTime() < today.getTime()
      ) {
        console.info(`${key} has expired; Returning null.                
                If you wish to inspect actual value, 
                you can just inspect actual localStorage:
                window.localStorage[${key}];
            `)
        return undefined
      }

      switch (parsed.type) {
        case 'number':
          return parseFloat(parsed.value as string)
        case 'boolean':
          return KitUtilData.toBoolean(parsed.value)
        default:
          return parsed.value as T
      }
    }

    return undefined
  }

  cleanUP(): void {
    Object.keys(this.store).forEach((key) => {
      this.store.removeItem(key)
    })
  }

  /**
   * set value
   */
  set(key: string, value: unknown, opts: StorageOpts = {}): void {
    // 2 week max local-storage
    const maxExpires: number = Date.now() + 1000 * 60 * 60 * 24 * 14
    const type: string       = typeof value
    const item: StorageEntry = {
      value,
      type,
      expires: opts.expires || maxExpires,
    }

    try {
      this.store.setItem(`${key}_${this.version}`, JSON.stringify(item))
    } catch (err) {
      console.error('Error: Storage could not set value', err)
      this.cleanUP()
    }
  }

  set storeType(type: StorageType) {
    if (type === StorageType.sessionStorage) {
      this.store = window.sessionStorage
    } else {
      this.store = window.localStorage
    }
  }
}

export default new Storage()
