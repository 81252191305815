import { KitForm } from '@chargepoint/cp-toolkit'
import { type FC, type ReactNode } from 'react'

import { obfuscate } from '@/common/utils/ui'
import { hasValue } from '@/common/utils/validations'
import { List, ListItemSmall } from '@/components/Styled'
import { type Field } from 'src/types'

interface ReadOnlySettingsProps {
  children?: ReactNode;
  emptyMessage?: string;
  fields: Partial<Field>[];
}
const ReadOnlySettings: FC<ReadOnlySettingsProps> = ({
  children,
  emptyMessage,
  fields,
}) => {
  if (!fields) {
    return null
  }

  const normalizedFields = fields
    .map((field) => ({
      testId : field.testId ?? field.name,
      label  : field.label ?? field.props?.label,
      value  : field.value ?? field.props?.value,
      type   : field.type,
    }))
    .filter((f) => hasValue(f.value))

  if (fields.length === 0 && emptyMessage) {
    return (
      <List>
        <ListItemSmall>{ emptyMessage }</ListItemSmall>
      </List>
    )
  }
  return (
    <>
      { normalizedFields.map(({ label, testId, type, value }) => (
        <KitForm.Group key={`${label}-${value}`}>
          <KitForm.Label htmlFor={label as string} text={label as string} />
          <KitForm.Value dataQaId={`${testId}_value`} className="read-only">
            { ['password'].includes(type as string)
              ? obfuscate(value as string)
              : (value as string) }
          </KitForm.Value>
        </KitForm.Group>
      )) }
      { children }
    </>
  )
}

export default ReadOnlySettings
