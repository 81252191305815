import { type ReactElement } from 'react'
import {
  type LazyRouteFunction,
  type NonIndexRouteObject,
  type RouteObject,
} from 'react-router-dom'

import { omit } from './utils/data'
import { getCurrentUser } from './utils/user'

export const FLEET_DASHBOARD_VIEW = 'fleet_dashboard_view' // Display the fleet dashboard
export const FLEET_DEPOT_VIEW = 'fleet_depot_view' // Display depot information
export const FLEET_DEPOT_ALERTS_VIEW = 'fleet_depot_alerts_view' // Display the alert (history) view
export const FLEET_DEPOT_DETAILS_VIEW = 'fleet_depot_details_view' // Display depot details (list and map) view
export const FLEET_DEPOT_SETTINGS_VIEW = 'fleet_depot_settings_view' // Display depot status/configuration
export const FLEET_DEPOT_STATION_VIEW = 'fleet_depot_station_view' // Display a depot's station details
export const FLEET_FLEET_VIEW = 'fleet_fleet_view' // Display fleet information
export const FLEET_FLEET_DETAIL_VIEW = 'fleet_fleet_detail_view' // Display fleet status/configuration
export const FLEET_HELP_VIEW = 'fleet_help_view' // Display help pages
export const FLEET_SITE_VIEW = 'fleet_site_view' // Display site status/configuration
export const FLEET_TARIFF_VIEW = 'fleet_tariff_view' // Display tariff definitions/assignments
export const FLEET_VEHICLE_VIEW = 'fleet_vehicle_view' // Display vehicle status/configuration
export const FLEET_VEHICLE_CHARGING_VIEW = 'vehicle_charging_view' // Display vehicle charging status and stats
export const FLEET_VEHICLE_TRIP_HISTORY_VIEW = 'vehicle_trip_history_view' // Display trip history
export const FLEET_ENERGY_MGMT_VIEW = 'fleet_energy_mgmt_view' // Display energy management
export const FLEET_ENERGY_MGMT_UPDATE = 'fleet_energy_mgmt_update' // Display energy management settings

export const FLEET_SCHEDULE_VIEW = 'fleet_schedule_view' // Display block scheduling / dispatch section
export const FLEET_SCHEDULE_UPDATE = 'fleet_schedule_update' // Allow user to upload schedules

// Update permissions (ability to change data)
export const FLEET_DEPOT_ALERTS_UPDATE = 'fleet_alerts_update' // Flag an alert as completed (inactive)
export const FLEET_DEPOT_CONTACTS_UPDATE = 'fleet_depot_contacts_update' // Add/Change depot contact information
export const FLEET_DEPOT_PLAN_UPDATE = 'fleet_depot_update_plan' // Change a charging plan’s setpoint
export const FLEET_DEPOT_TELEMATICS_UPDATE = 'fleet_depot_telematics_update' // Add/Change telematics configuration
export const FLEET_FLEET_UPDATE = 'fleet_fleet_update' // Add/Change fleet definitions
export const FLEET_PROFILE_UPDATE = 'fleet_profile_update' // Add/Change user profiles
export const FLEET_SITE_UPDATE = 'fleet_site_update' // Add/Change site definitions
export const FLEET_TARIFF_UPDATE = 'fleet_tariff_update' // Add/Change/Upload tariff definitions/assignments
export const FLEET_VEHICLE_UPDATE = 'fleet_vehicle_update' // Add/Change vehicle definitions and status

/**
 * Returns true/false if user has the passed permissions
 * @param requiredPermissions
 * @returns boolean
 */
export function hasPermission(requiredPermissions: string | string[]): boolean {
  const currentUser        = getCurrentUser()
  const allUserPermissions = currentUser?.permissions
  // Check to see if there are no required permissions or if permissions are not passed in then always allow
  if (!requiredPermissions) return true
  // Check to see if there are matches for users' permissions to required permissions.
  // If requiredPermissions is an array of permissionstrings,
  // then hasPermission will return true if the user as either one of the requiredPermissions.
  if (
    allUserPermissions?.some((permission: string) => requiredPermissions.includes(permission))
  ) {
    return true
  }
  return false
}

export function getEnabledRoutes(routes: FleetRouteObject[]) {
  const result = routes.reduce((acc, route) => {
    if (route.enabled === false) {
      return acc
    }

    const allowed = route.permissions ? hasPermission(route.permissions) : true
    if (allowed) {
      const allowedRoute = omit(route, ['permissions'])
      if (route.children) {
        allowedRoute.children = getEnabledRoutes(route.children)
      }
      acc.push(allowedRoute)
    }
    return acc
  }, [] as RouteObject[])

  return result
}

export interface FleetRouteObject {
  children?: FleetRouteObject[];
  element?: ReactElement;
  enabled?: boolean;
  errorElement?: ReactElement;
  index?: boolean;
  path?: string;
  lazy?: LazyRouteFunction<NonIndexRouteObject>;
  loader?: () => Promise<unknown>;
  permissions?: string[];
  shouldRevalidate?: () => boolean;
}
