/* eslint-disable no-unused-vars */
import { type Dispatch } from 'react'

import { RequestType } from './constants'
import { cloneDeep } from './utils/data'
import { toggleBodyScroll } from './utils/ui'
import { type Filter } from '@/types/filters'
import { type Field, type FieldProps, type SortOptions } from '@/types/index'

export type InitializeFilterAction = {
  type: 'INTIALIZE_FILTERS';
  payload: Filter[];
  sort?: SortOptions;
};

export type ShowWizardAction = { type: 'SHOW_WIZARD'; open: boolean };

export type SetActiveFilterAction = {
  type: 'SET_ACTIVE_FILTERS';
  payload: Filter[];
};

export type SetInterimFilterAction = {
  type: 'SET_FILTERS';
  payload: Filter[];
};

export type ToggleFiltersAction = {
  type: 'TOGGLE_FILTERS_OPEN';
  forceOpen: boolean;
  payload: boolean;
};

export type FilterAction =
  | SetActiveFilterAction
  | SetInterimFilterAction
  | InitializeFilterAction
  | ToggleFiltersAction
  | ShowWizardAction
  | {
    type: string;
    payload?: unknown | boolean | unknown[];
    [key: string]: unknown;
  };

export interface FilterReducerState {
  initialized?: boolean;
  filters: Filter[];
  activeFilters: Filter[];
  filterOptions?: Record<string, unknown>;
  filtersPanelOpen?: boolean;
  extraMenuOpen?: boolean;
  showWizard?: boolean;
  sort?: Partial<SortOptions>;
}

export const filterActions = {
  INTIALIZE_FILTERS      : 'INTIALIZE_FILTERS',
  SET_ACTIVE_FILTERS     : 'SET_ACTIVE_FILTERS',
  SET_FILTERS            : 'SET_FILTERS',
  SET_FILTER_OPTIONS     : 'SET_FILTER_OPTIONS',
  SET_SORT_OPTIONS       : 'SET_SORT_OPTIONS',
  SHOW_WIZARD            : 'SHOW_WIZARD',
  TOGGLE_FILTERS_OPEN    : 'TOGGLE_FILTERS_OPEN',
  TOGGLE_EXTRA_MENU_OPEN : 'TOGGLE_EXTRA_MENU_OPEN',
}

export const initialFilterState: FilterReducerState = {
  activeFilters    : [],
  extraMenuOpen    : false,
  filters          : [],
  filterOptions    : {},
  filtersPanelOpen : false,
  initialized      : false,
  showWizard       : false,
  sort             : {},
}

export function filtersReducer(
  state: FilterReducerState = initialFilterState,
  action: FilterAction,
): FilterReducerState {
  switch (action.type) {
    case 'INTIALIZE_FILTERS':
      return {
        ...state,
        activeFilters : (action.payload ?? []) as Filter[],
        filters       : (action.payload ?? []) as Filter[],
        sort          : (action as InitializeFilterAction).sort as SortOptions,
        initialized   : true,
      }
    case 'SET_ACTIVE_FILTERS':
      return {
        ...state,
        activeFilters    : action.payload as Filter[],
        filtersPanelOpen : false,
      }
    case 'SET_FILTERS':
      return { ...state, filters: action.payload as Filter[] }
    case 'SHOW_WIZARD':
      return { ...state, showWizard: action.open as boolean }
    case 'TOGGLE_FILTERS_OPEN':
      return {
        ...state,
        filtersPanelOpen: (action.payload
          ?? !state.filtersPanelOpen) as boolean,
      }
    case 'TOGGLE_EXTRA_MENU_OPEN':
      return {
        ...state,
        extraMenuOpen: (action.payload ?? !state.extraMenuOpen) as boolean,
      }
    case 'SET_FILTER_OPTIONS':
      return {
        ...state,
        filterOptions: action.payload as Record<string, unknown>,
      }
    case 'SET_SORT_OPTIONS':
      return { ...state, sort: action.payload as SortOptions }
    default:
      return state
  }
}

export enum FormActions {
  UPDATE_FIELD = 'UPDATE_FIELD',
  REMOVE_FIELD = 'REMOVE_FIELD',
  INIT_FORM = 'INIT_FORM',
  SET_PROP = 'SET_PROP',
}

/* useful when working for forms where fields are in an array, like when we have dynamically added fields */
export interface FormState {
  fields: Field[];
  initialData?: Field[];
}

export interface PayloadAction<T> {
  type: string;
  payload: T;
}

// TODO: convert to payload action, clean up typing
export interface FormAction {
  type: string;
  fieldID: unknown;
  fieldName?: string;
  fieldValue?: unknown;
  field?: Field;
  fields?: Field[];
  prop?: string;
  props?: Record<string, unknown>;
  value?: unknown;
}

export function formReducer(
  formState: FormState = { fields: [], initialData: [] },
  action: FormAction,
): FormState {
  switch (action.type) {
    case FormActions.INIT_FORM:
      return {
        ...formState,
        fields      : action.fields as Field[],
        initialData : cloneDeep(
          action.fields as unknown as Record<string, unknown>[],
        ),
      }
    case FormActions.UPDATE_FIELD:
      return updateField(formState, action)
    case FormActions.REMOVE_FIELD:
      return {
        ...formState,
        fields: removeField(action.fieldName as string, formState.fields),
      }
    case FormActions.SET_PROP:
      return {
        ...formState,
        [action.prop as string]: action.value,
      }
    default:
      return formState
  }
}

function updateField(formState: FormState, action: FormAction): FormState {
  const fields     = [...formState.fields]
  const matchIndex = fields.findIndex(
    (f: Field) => f.props?.name === action.field?.name,
  )
  if (fields[matchIndex]) {
    // @ts-expect-error -- TODO: action.action has been renamed to action.requestType in other filter reducers.
    // That needs to be done here too, but it adds some risk
    fields[matchIndex].action =      action.action ?? fields[matchIndex].action ?? RequestType.UPDATE
    if (fields[matchIndex].props) {
      (fields[matchIndex].props as FieldProps).value = action.field?.value
    }
  }
  return {
    ...formState,
    fields,
  }
}

// instead of sending a delete request for a particular field, this will remove it from the fields array entirely
function removeField(fieldName: string | string[], fields: Field[]) {
  const compareFields = typeof fieldName === 'string' ? [fieldName] : fieldName
  return fields.filter((f) => !compareFields.includes(f.props?.name as string))
}

export class FilterActions {
  static toggleFiltersOpen(dispatch: Dispatch<FilterAction>) {
    toggleBodyScroll()
    dispatch({ type: filterActions.TOGGLE_FILTERS_OPEN })
  }

  static setActiveFilters(dispatch: Dispatch<FilterAction>, payload: Filter[]) {
    toggleBodyScroll()
    dispatch({
      type: filterActions.SET_ACTIVE_FILTERS,
      payload,
    })
  }
}
