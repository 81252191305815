import { type TFunction } from 'i18next'
import { useEffect, useState } from 'react'

import { groupBy } from '@/common/utils/data'
import { type OptionListItem } from '@/models/formModel'
import { type Vehicle } from '@/models/vehicleModel'
import { type CustomInputChangeHandler } from '@/types/index'
import { KitUtilData } from '@chargepoint/cp-toolkit'

export interface MakeModelYearConfig {
  selectedMake?: string;
  selectedModel?: string;
  selectedYear?: string;
  makes?: OptionListItem[];
  models?: OptionListItem[];
  years?: OptionListItem[] | null;
  onChange?: CustomInputChangeHandler;
}

const useMakeModelYear = (
  vehicles: Partial<Vehicle>[],
  onChange: CustomInputChangeHandler,
  { t, values }: { values: Partial<Vehicle>; t: TFunction },
): MakeModelYearConfig => {
  const groupedByMake                          = groupBy(vehicles, 'model_make') ?? {}
  const [config, setConfig]                    = useState<MakeModelYearConfig>({})
  const { makes, models, selectedMake, years } = config
  const refreshKey                             = JSON.stringify(values)

  const getModelGroups = (make: string) => {
    if (groupedByMake[make]) {
      return groupBy(groupedByMake[make], 'model_name')
    }
    return null
  }

  const getMakes = (): OptionListItem[] => {
    const result = [{ label: t('any'), value: '*' }].concat(
      Object.keys(groupedByMake).map((key) => ({
        value    : key,
        label    : key,
        selected : values?.model_make === key,
      })),
    )

    return result
  }

  const getModels = (make: string): OptionListItem[] | undefined => {
    if (groupedByMake[make]) {
      const groups = getModelGroups(make) as Record<string, Vehicle[]>
      return [{ label: t('any'), value: '*' }].concat(
        Object.keys(groups).map((key) => ({
          value    : key,
          label    : key,
          selected : values?.model_name === key,
        })),
      )
    }
  }

  const getYears = (model: string): OptionListItem[] | undefined => {
    const modelGroups = getModelGroups(selectedMake as string)
    if (modelGroups && modelGroups[model]) {
      return [{ label: t('any'), value: '*' }].concat(
        Object.keys(groupBy(modelGroups[model], 'model_year')).map((key) => ({
          value    : key,
          label    : key,
          selected : values?.model_year === key,
        })),
      )
    }
  }

  function handleChange(fieldName: string, fieldValue: unknown): void {
    let remove: string[] = []
    const val            = fieldValue as string
    switch (fieldName) {
      case 'model_make':
        setConfig({
          ...config,
          selectedMake  : val,
          models        : getModels(val) as OptionListItem[],
          selectedModel : t('any'),
          years         : null,
        })
        remove = ['model_name', 'model_year']
        break
      case 'model_name':
        setConfig({
          ...config,
          selectedModel : val,
          selectedYear  : t('any'),
          years         : getYears(val),
        })
        remove = ['model_year']
        break
      case 'model_year':
        setConfig({
          ...config,
          selectedYear: val,
        })
        break
      default:
    }

    if (onChange) {
      onChange(fieldName, fieldValue, {
        remove,
        componentType: 'FILTER_TYPE_MAKEMODELYEAR',
      })
    }
  }

  useEffect(() => {
    const cfg = {
      ...config,
      selectedMake  : values.model_make,
      selectedModel : values.model_name,
      selectedYear  : values.model_year,
    }
    setConfig({
      ...cfg,
      makes  : getMakes(),
      models : getModels(values?.model_make as string),
      years  : getYears(values?.model_name as string),
    })
  }, [vehicles, refreshKey])

  return {
    selectedMake  : config.selectedMake,
    selectedModel : config.selectedModel,
    selectedYear  : config.selectedYear,
    makes,
    models,
    years,
    onChange      : handleChange,
  }
}

export default useMakeModelYear
