import { KitUtilData } from '@chargepoint/cp-toolkit'

import {
  type AddVehicleFlowData,
  type AddVehicleFlowSections,
  type AddVehicleFlowState,
} from './config'
import { type PayloadAction } from '@/common/reducers'
import { type WizardStep } from '@/components/Wizard'
import { type DynamicObject } from '@/types/index'

function unsetFields(
  sectionState: DynamicObject<unknown> = {},
  fields: string[],
) {
  const result = fields.reduce(
    (acc, fieldName) => {
      if (acc[fieldName]) {
        delete acc[fieldName]
      }
      return acc
    },
    { ...sectionState },
  )

  return result
}

/* eslint-disable no-unused-vars */
const addVehicleReducer = (
  state: Partial<AddVehicleFlowState> = {},
  action: PayloadAction<unknown>,
) => {
  const actions = {
    STEP: (payload: WizardStep) => ({
      ...state,
      serviceError : null,
      wizard       : { step: payload },
    }),
    RESET_FIELDS: (payload: {
      section: keyof AddVehicleFlowSections;
      fields: string[];
    }) => ({
      ...state,
      sections: {
        ...state.sections,
        [payload.section]: unsetFields(
          state.sections?.[payload.section],
          payload.fields,
        ),
      },
    }),
    RESET_STATE         : (payload: Partial<AddVehicleFlowState>) => ({ ...payload, wizard: { ...payload.wizard, reset: true } }),
    SERVICE_ERROR       : (payload: string) => ({ ...state, serviceError: payload }),
    CLEAR_SERVICE_ERROR : () => ({ ...state, serviceError: null }),
    IS_LOADING          : () => ({ ...state, isLoading: true }),
    DATA_LOADED         : (payload: {
      id: keyof typeof state.data;
      results: unknown;
    }) => ({
      ...state,
      isLoading : false,
      data      : {
        ...state.data,
        [payload.id]: {
          ...state.data[payload.id],
          ...payload.results,
        },
      },
    }),
    // todo
    UPDATE_DATA: (payload: DynamicObject<unknown>) => ({
      ...state,
      data: {
        ...state.data,
        [payload.section]: {
          ...state.data[payload.section as keyof AddVehicleFlowData],
          ...payload.results,
        },
      },
    }),
    UPDATE_SECTION: (payload: { section: keyof AddVehicleFlowSections }) => ({
      ...state,
      sections: {
        ...state.sections,
        [payload.section]: {
          ...(state.sections?.[payload.section] ?? {}),
          ...(payload.results
            ? payload.results
            : { [payload.field]: payload.value }),
        },
      },
    }),
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (actions[action.type]) {
    if (
      typeof action.payload === 'object'
      && !KitUtilData.isEmpty(action.payload)
    ) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return actions[action.type](action.payload)
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return actions[action.type](action.payload)
  }

  return state
}

export enum WizardActions {
  RESET_FIELDS = 'RESET_FIELDS',
  DATA_LOADED = 'DATA_LOADED',
  IS_LOADING = 'IS_LOADING',
  UPDATE_DATA = 'UPDATE_DATA',
  UPDATE_SECTION = 'UPDATE_SECTION',
  RESET_STATE = 'RESET_STATE',
  SERVICE_ERROR = 'SERVICE_ERROR',
  CLEAR_SERVICE_ERROR = 'CLEAR_SERVICE_ERROR',
  STEP = 'STEP',
}

export default addVehicleReducer
