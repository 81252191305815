import {
  KitButton,
  KitIcon,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type MouseEvent } from 'react'

import { useTranslation } from 'react-i18next'
import { DeleteButtonWrapper } from './styles'

interface DeleteButtonProps {
  id: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const DeleteButton = ({ id, onClick }: DeleteButtonProps) => {
  const { t } = useTranslation()

  return (
    <DeleteButtonWrapper>
      <KitButton
        data-id={id}
        variant="circle"
        onClick={onClick}
        aria-label={t(
          'energy_management.confirmFleetOptimizationDelete',
        )}
      >
        <KitIcon
          icon="close"
          size={`${ThemeConstants.iconSize.xs}rem`}
          fill={ThemeColors.red_50}
        />
      </KitButton>
    </DeleteButtonWrapper>
  )
}

export default DeleteButton
