import { type FC } from 'react'

import { type CartesianViewBox, type SymbolType } from 'recharts/types/util/types'

import Shape from './CPChartShape'

interface ReferenceLineIconProps {
  fill?: string;
  viewBox?: CartesianViewBox;
  type: SymbolType;
}

const CPChartReferenceLineIcon: FC<ReferenceLineIconProps> = (props) => {
  const { fill, type, viewBox } = props
  const x                       = (viewBox as CartesianViewBox)?.x
  const y                       = (viewBox as CartesianViewBox)?.y

  return (
    <g style={{ transform: `translate(${x}px, ${y}px` }}>
      <Shape fill={fill as string} type={type} />
    </g>
  )
}

export default CPChartReferenceLineIcon
