import {
  type CPChartRect,
  CPChartZoomOutButton,
  ChartConstants,
  type ChartEvent,
  ChartUtils,
} from '@chargepoint/cp-charts'
import {
  KitSpinner,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { extent } from 'd3-array'
import { format } from 'date-fns'
import {
  type ComponentType,
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { type AxisDomainItem } from 'recharts/types/util/types'
import styled from 'styled-components'

import { ChartWrapper } from '../styledChartElements'
import {
  getChargingStatusFillColor,
  getChargingStatusStrokeColor,
  prepTimeStreamData,
  tickFormatter,
  tooltipItemFormatter,
} from './utils'
import { type ChargingSessionStatus, ISO_TIME } from '@/common/constants'
import Poll, { PollEvents } from '@/common/utils/Poll'
import { hasValue } from '@/common/utils/validations'
// TODO: Replace these with components from cp-charts when cp-charts is ready.
import CPLegend from '@/components/Charting/ChartComponents/CPChartLegend'
import ReferenceLineIcon from '@/components/Charting/ChartComponents/CPChartReferenceLineIcon'
import CPChartTooltip from '@/components/Charting/ChartComponents/CPChartTooltip'
import {
  ChartColors,
  DEFAULT_ZOOM,
  InterpolationType,
  Palette,
  SeriesType,
  type YAxisProps,
} from '@/components/Charting/common/constants'
import { SymbolMap } from '@/components/Charting/common/helpers'
import {
  type ChartElementProps,
  type ToolTipItem,
} from '@/components/Charting/common/types'
import ErrorBoundary from '@/components/ErrorBoundary'
import NoResults from '@/components/NoResults'
import { useLayout } from '@/hooks/useLayout'
import { type LegendPayload, type TimeSeriesData } from '@/models/chartModel'
import { type DepotDetailsRow } from '@/models/depotModel'
import { type ActiveChargingSession, type ChargingHistory } from '@/models/vehicleModel'
import VehicleService from '@/services/VehicleService'

const { fontSize } = ThemeConstants
const chartMargin  = 30

type ComponenMapType = typeof Line | typeof Area | typeof ReferenceLine;

const componentMap: { [key: string]: ComponenMapType } = {
  line          : Line,
  area          : Area,
  referenceLine : ReferenceLine,
}

const Container = styled.div`
  position: relative;
  min-height: 200px;
  min-width: 300px;
  width: 100%;
`

const ValuesWrapper = styled.div`
  padding: 0;
`

const ZoomHelpText = styled.div`
  color: ${({ theme }) => theme.label};
  font-size: ${fontSize.text_14}rem;
  margin-top: 0 !important;
`

export interface Props extends PropsWithChildren {
  sessionid?: string;
  chargingStatus: ChargingSessionStatus;
  session: (DepotDetailsRow & ActiveChargingSession) | ChargingHistory;
  isHistorical?: boolean;
  onLoad?: (d: TimeSeriesData[]) => void;
  isAnimationActive?: boolean;
  depotid: string;
}

export interface State {
  autoUpdate: boolean;
  dataSize: number;
}

export function renderSeries(chartSeriesProps: ChartElementProps) {
  const ChartSeries = componentMap[
    chartSeriesProps.seriesType as keyof typeof componentMap
  ] as ComponentType<ChartElementProps>
  return <ChartSeries {...chartSeriesProps} />
}

const ChargingSessionGraph: FC<Props> = (props) => {
  const { t }                           = useTranslation()
  const {
    depotid,
    isAnimationActive = true,
    isHistorical = false,
    onLoad,
    session,
    sessionid,
  } = props
  const [serviceError, setServiceError] = useState<string | null>(null)
  const [dataSetKeys, setDataSetKeys]   = useState<string[]>([])
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([])
  const [isLoading, setIsLoading]       = useState<boolean>(false)

  const [isZooming, setIsZooming]   = useState(false)
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const [bounds, setBounds]         = useState<CPChartRect>(DEFAULT_ZOOM)
  const [highLight, setHighLight]   = useState<CPChartRect>(
    ChartConstants.DEFAULT_HIGHLIGHT_ZOOM,
  )
  const [data, setData]             = useState<TimeSeriesData[]>([])

  const { isTabletOrMobile } = useLayout()

  const pollRef               = useRef<Poll>()
  const dataRef               = useRef<TimeSeriesData[]>()
  const xDataKey              = 'timestamp'
  const lineInterpolationType = InterpolationType.monotone
  const axisFontSize          = 14
  const hasData               = data?.length
  const animationDuration     = 1000

  const socDomain = extent(data, (item) => item.soc)

  const getYAxisTicks = (axisID: string, min: number, max: number): number[] => {
    const ticks = [min, max]
    let val     = [...ticks]
    if (axisID === 'soc') {
      if (min > 8) {
        val = [0, ...ticks]
      }
      if (max < 92) {
        val = [...val, 100]
      }

      return val
    }

    return []
  }

  const labels = {
    power               : t('charting.power'),
    planned_setpoint    : t('charting.planned_power'),
    setpoint            : t('charting.setpoint'),
    setpoint_simulation : t('charting.setpoint_simulation'),
    goalSOC             : t('charting.goal_soc'),
    pullOutTime         : t('charting.pull_out_time'),
    soc                 : t('charting.soc'),
  }

  const chartContainerStyle = {
    maxWidth: isTabletOrMobile
      ? `${document.body.getBoundingClientRect().width}px`
      : `${document.body.getBoundingClientRect().width - 350}px`,
  }

  function handleMouseDown(e: unknown) {
    setHighLight(
      ChartUtils.ChartZoom.init(
        e as ChartEvent,
        highLight,
        xDataKey,
      ) as CPChartRect,
    )
    setIsZooming(true)
  }

  function handleMouseMove(e: unknown) {
    const x2 = ChartUtils.getEventPayloadValue(e as ChartEvent, xDataKey)
    if (isZooming) {
      setHighLight((prev) => ({ ...prev, x2 }))
    }
  }

  function handleMouseUp() {
    if (isZooming) {
      const { zoomBounds, zoomed } = ChartUtils.ChartZoom.getBounds(
        highLight,
        data,
        xDataKey,
        ['power', 'setpoint', 'planned_setpoint', 'setpoint_simulation', 'soc'],
        ChartConstants.MIN_ZOOM,
      )

      if (zoomed) {
        setIsZoomedIn(true)
        setHighLight(ChartConstants.DEFAULT_HIGHLIGHT_ZOOM)
        // adjust bounding box so there is a little padding above the top-most zoomed value on the y-axis
        if (zoomBounds) {
          zoomBounds.y1 = (zoomBounds.y1 as number) + 1
        }
        setBounds(zoomBounds as CPChartRect)
        setData(data.slice())
      }

      setIsZooming(false)
    }
  }
  function zoomOut() {
    setIsZoomedIn(false)
    setBounds(DEFAULT_ZOOM)
    setData(() => data.slice())
  }

  function formatTimeStampValue(row: Record<string, number>): string {
    return t('charting.tooltip_timestamp', { timestamp: format(row.timestamp, ISO_TIME) })
  }

  const formatAxisTimeStamp = (unixTime: number) => format(unixTime, 'HH:mm')

  function toggleSeriesVisibility(dataKey: string) {
    let hiddenSeriesChanges: string[] = hiddenSeries.concat()
    if (hiddenSeries.includes(dataKey)) {
      hiddenSeriesChanges = hiddenSeries.filter((k) => k !== dataKey)
    } else {
      hiddenSeriesChanges.push(dataKey)
    }
    setHiddenSeries(hiddenSeriesChanges)
  }

  function handleLegendItemClick(props: LegendPayload) {
    toggleSeriesVisibility(props.dataKey)
  }

  const updateChartData = useCallback(async () => {
    if (!hasValue(dataRef.current) || dataRef.current?.length === 0) {
      setIsLoading(true)
    }

    // don't try to make a request if we don't have a session id for some reason
    if (!sessionid) {
      return
    }

    let resultsError: string | undefined
    let serviceResults: TimeSeriesData[] | undefined

    try {
      const response           = await VehicleService.getChargingSessionPower(
        sessionid,
        depotid,
      )
      const { error, results } = prepTimeStreamData(
        response,
        session,
        isHistorical,
      )

      serviceResults = results
      resultsError   = error
        ? t('errors.list_request_error', { itemType: t('charting.power') })
        : undefined
    } catch (err) {
      resultsError = t('errors.list_request_error', { itemType: t('charting.power') })
    }

    if (resultsError) {
      setIsLoading(false)
    } else if (serviceResults) {
      setServiceError(null)
      setDataSetKeys(
        ChartUtils.getAllDataSetKeys(serviceResults, ['timestamp']),
      )
      setData(serviceResults)
      dataRef.current = serviceResults
      if (serviceResults?.length) {
        if (onLoad) {
          onLoad(serviceResults)
        }
      }
    }
    setIsLoading(false)
  }, [sessionid])

  useEffect(() => {
    if (!hasData) {
      if (sessionid) {
        updateChartData()
      }
    }
  }, [sessionid])

  // At present, we will poll the api every 60 seconds to refresh the chart data
  // in the future we may update the chart via websockets
  useEffect(() => {
    if (!isHistorical) {
      // refresh every 1 minute
      const timer = new Poll({ pollInterval: 1000 * 60 })
      timer.on(PollEvents.POLL, updateChartData)
      timer.start()
      pollRef.current = timer

      return () => {
        timer.stop()
      }
    }
  }, [isHistorical])

  if (isLoading) {
    return (
      <Container>
        <KitSpinner align="middle" withOverlay size="s" />
      </Container>
    )
  }

  if (serviceError) {
    return (
      <Container>
        <NoResults
          message={t('errors.list_request_error', { itemType: t('charging_session') })}
        />
      </Container>
    )
  }

  if (!isLoading && !hasData && !serviceError) {
    return (
      <Container>
        <NoResults message={t('charting.charging_session_started')} />
      </Container>
    )
  }

  const areaFill   = getChargingStatusFillColor(
    props.chargingStatus,
    isHistorical,
  )
  const areaStroke = getChargingStatusStrokeColor(
    props.chargingStatus,
    isHistorical,
  )

  enum SessionYAxisID {
    Power = 'power',
    SOC = 'soc',
  }

  const referenceLines: ChartElementProps[] = [
    {
      seriesType      : SeriesType.ReferenceLine,
      dataKey         : 'goalSOC',
      x               : new Date(session?.charge_completion_time).getTime(),
      stroke          : Palette.referenceLine,
      strokeWidth     : 2,
      strokeDasharray : '3 3',
      shape           : 'diamond',
      label           : <ReferenceLineIcon fill={ChartColors.gray} type="diamond" />,
      active          : hasValue(session?.charge_completion_time),
      yAxisId         : SessionYAxisID.Power,
    },
    {
      dataKey     : 'pullOutTime',
      seriesType  : SeriesType.ReferenceLine,
      x           : new Date((session as DepotDetailsRow)?.pull_out as string).getTime(),
      stroke      : Palette.referenceLine,
      strokeWidth : 2,
      label       : <ReferenceLineIcon fill={ChartColors.gray} type="square" />,
      shape       : 'square',
      active      : hasValue((session as DepotDetailsRow)?.pull_out),
      yAxisId     : SessionYAxisID.Power,
    },
  ].filter((r) => r.active)

  const chartSeries: Partial<ChartElementProps>[] = (
    [
      {
        seriesType      : SeriesType.Area,
        type            : lineInterpolationType,
        dataKey         : 'power',
        label           : t('charting.power'),
        fill            : areaFill,
        stroke          : areaStroke,
        connectNulls    : true,
        fillOpacity     : hiddenSeries.includes('power') ? 0 : 1,
        strokeOpacity   : hiddenSeries.includes('power') ? 0 : 1,
        animationEasing : 'ease-in-out',
        isAnimationActive,
        animationDuration,
        unit            : 'kW',
        yAxisId         : SessionYAxisID.Power,
      },
      {
        seriesType    : SeriesType.Line,
        type          : lineInterpolationType,
        dot           : false,
        dataKey       : 'soc',
        connectNulls  : true,
        label         : t('charting.soc'),
        strokeOpacity : hiddenSeries.includes('soc') ? 0 : 1,
        stroke        : Palette.soc,
        strokeWidth   : 3,
        isAnimationActive,
        unit          : 'kW',
        yAxisId       : SessionYAxisID.SOC,
        active        : dataSetKeys.includes('soc'),
      },
      {
        seriesType      : SeriesType.Area,
        type            : lineInterpolationType,
        connectNulls    : true,
        dataKey         : 'planned_setpoint',
        fill            : areaFill,
        label           : t('charting.planned_power'),
        fillOpacity     : hiddenSeries.includes('planned_setpoint') ? 0 : 0.3,
        strokeOpacity   : hiddenSeries.includes('planned_setpoint') ? 0 : 1,
        stroke          : areaStroke,
        strokeDasharray : '3 3',
        isAnimationActive,
        animationDuration,
        unit            : 'kW',
        yAxisId         : SessionYAxisID.Power,
        active          : dataSetKeys.includes('planned_setpoint'),
      },
      {
        seriesType    : SeriesType.Line,
        type          : lineInterpolationType,
        dot           : false,
        dataKey       : 'setpoint',
        connectNulls  : true,
        label         : t('charting.setpoint'),
        strokeOpacity : hiddenSeries.includes('setpoint') ? 0 : 1,
        stroke        : Palette.setpoint,
        isAnimationActive,
        unit          : 'kW',
        yAxisId       : SessionYAxisID.Power,
        active        : dataSetKeys.includes('setpoint'),
      },
      {
        seriesType    : SeriesType.Line,
        type          : lineInterpolationType,
        dot           : false,
        dataKey       : 'setpoint_simulation',
        connectNulls  : true,
        label         : t('charting.setpoint_simulation'),
        strokeOpacity : hiddenSeries.includes('setpoint_simulation') ? 0 : 1,
        stroke        : Palette.setpoint_simulation,
        isAnimationActive,
        unit          : 'kW',
        yAxisId       : SessionYAxisID.Power,
        active        : dataSetKeys.includes('setpoint_simulation'),
      },
    ] as ChartElementProps[]
  )
    .concat(referenceLines)
    .filter((el) => (hasValue(el.active) ? el.active : true))

  const getYAxisLabel = (labelProps: { label: string, position: 'insideTop', fill?: string }) => {
    const { fill = ThemeColors.gray_90, label, position } = labelProps
    const offset                                          = -42
    return { value: label, offset, position, fontWeight: 500, fill }
  }

  const yAxes: YAxisProps[] = [{
    yAxisId           : SessionYAxisID.Power,
    allowDataOverflow : true,
    dataKey           : 'power',
    domain            : [
      bounds.y2 as AxisDomainItem,
      bounds.y1 as AxisDomainItem,
    ],
    label       : getYAxisLabel({ label: t('charting.power'), position: 'insideTop', fill: areaStroke }),
    orientation : 'left',
    style       : { fontSize: `${axisFontSize}px` },
    stroke      : areaStroke,
    tickFormatter,
    unit        : ` ${t('units.kilowatt.short')}`,
  },
  {
    yAxisId           : SessionYAxisID.SOC,
    allowDataOverflow : true,
    dataKey           : 'soc',
    label             : getYAxisLabel({ label: t('charting.soc'), position: 'insideTop', fill: Palette.soc }),
    orientation       : 'right',
    padding           : { top: 4 },
    stroke            : Palette.soc,
    style             : { fontSize: `${axisFontSize}px` },
    tickFormatter,
    ticks             : getYAxisTicks('soc', Number(socDomain[0]), Number(socDomain[1])),
    unit              : '%',
  },
  ]

  // push SOC to top of list
  const sortTooltipItems = (a: ToolTipItem, b: ToolTipItem) => {
    if (a.key === 'soc') {
      return -1
    }
    if (b.key === 'soc') {
      return 1
    }

    return 0
  }

  const tooltipItems = chartSeries
    .filter((s) => s.seriesType !== SeriesType.ReferenceLine)
    .map(
      ({
        dataKey,
        fill,
        label,
        seriesType,
        shape,
        stroke,
        strokeDasharray,
        unit,
      }) => ({
        color  : fill ?? stroke,
        key    : dataKey,
        label,
        unit,
        active : true,
        seriesType,
        shape  : shape ?? SymbolMap[seriesType as keyof typeof SymbolMap],
        strokeDasharray,
      } as ToolTipItem),
    )
    .concat([
      { key: 'planned_soc', label: t('charting.planned_soc') },
      { key: 'target_power', label: t('charting.target_power') },
      { key: 'energy', label: t('charting.energy') },
      { key: 'elapsed', label: t('charting.elapsed_time') },
    ]).sort(sortTooltipItems) as ToolTipItem[]

  return (
    <Container>
      <ErrorBoundary>
        <ChartWrapper style={chartContainerStyle}>
          { isZoomedIn && (
            <CPChartZoomOutButton
              className="zoom-button"
              onClick={zoomOut}
              ariaLabel="zoom out"
            />
          ) }
          { !isZoomedIn && (
            <ZoomHelpText className="zoom-button">
              { t('charting.zoom_help_text') }
            </ZoomHelpText>
          ) }
          <ResponsiveContainer>
            <ComposedChart
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              data={data}
              margin={{
                top    : chartMargin + 15,
                right  : chartMargin,
                bottom : 0,
                left   : chartMargin,
              }}
            >
              { hasData && (
                <XAxis
                  allowDataOverflow
                  domain={[
                    bounds.x1 as AxisDomainItem,
                    bounds.x2 as AxisDomainItem,
                  ]}
                  dataKey="timestamp"
                  name="Time"
                  type="number"
                  tickCount={10}
                  style={{ fontSize: `${axisFontSize}px` }}
                  tickFormatter={formatAxisTimeStamp}
                  tickMargin={8}
                />
              ) }

              { yAxes.map((yAxisProps) => <YAxis key={yAxisProps.yAxisId} {...yAxisProps} />) }

              <Tooltip
                content={
                  <CPChartTooltip
                    items={tooltipItems as ToolTipItem[]}
                    formatter={(key: string, value: number) => tooltipItemFormatter(key, value, t) as string
                    }
                    formatTimeStamp={formatTimeStampValue}
                  />
                }
              />
              <Legend
                content={
                  <CPLegend
                    formatLabel={(series) => labels[series.dataKey as keyof typeof labels]
                    }
                    additionalItems={referenceLines as LegendPayload[]}
                    onClick={handleLegendItemClick}
                  />
                }
              />

              { chartSeries.map(renderSeries) }

              <CartesianGrid strokeDasharray="3 3" />

              { isZooming ? (
                <ReferenceArea
                  x1={highLight.x1}
                  x2={highLight.x2}
                  stroke={ThemeColors.gray_50}
                  strokeOpacity={0.6}
                  fill={ThemeColors.gray_70}
                  fillOpacity={0.2}
                  yAxisId={SessionYAxisID.Power}
                />
              ) : null }
            </ComposedChart>
          </ResponsiveContainer>
        </ChartWrapper>
        <ValuesWrapper>{ props.children }</ValuesWrapper>
      </ErrorBoundary>
    </Container>
  )
}

export default ChargingSessionGraph
