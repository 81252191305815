import { KitFlexCol, KitFlexRow, KitFlexWrapRow, KitTips, ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { KitDisplayNumber } from '@chargepoint/cp-toolkit/dist/src/components/KitDisplayNumbers'
import classNames from 'classnames'
import { type ReactElement } from 'react'
import styled from 'styled-components'
import { hasValue } from '@/common/utils/validations'

const Container = styled.div`
   .is-stale {
    &:hover, &:focus {
      background: ${ThemeColors.gray_10};
    }
   }

   .is-stale-value {
      font-style: italic;
      color: ${ThemeColors.gray_50};
   }
`

const SpacedCol = styled(KitFlexCol)`
  padding: ${ThemeConstants.spacing.absolute.xl}px;
`

export interface DisplayNumber {
  qaId: string;
  isStale?: boolean;
  title: string;
  value: string;
  unit?: string;
  ttipContent?: ReactElement;
}

const FleetDisplayNumbers = ({ displayItems }:{ displayItems: DisplayNumber[] }) => (
  <Container>
    <KitFlexWrapRow>
      { displayItems.map((item) => (
        <KitTips key={`${item.title}-${item.value}`} content={item.ttipContent} disabled={!hasValue(item.ttipContent)} >
          <SpacedCol {...(item.ttipContent ? { tabIndex: 0 } : {})} className={classNames({ 'is-stale': item.isStale })}>
            <KitDisplayNumber>
              <KitDisplayNumber.Title>{ item.title }</KitDisplayNumber.Title>
              <KitFlexRow data-qa-id={item.qaId}>
                <KitDisplayNumber.Value className={classNames({ 'is-stale-value': item.isStale })}>{ item.value as string }</KitDisplayNumber.Value>
                { item.unit && <KitDisplayNumber.Unit>{ item.unit }</KitDisplayNumber.Unit> }
              </KitFlexRow>
            </KitDisplayNumber>
          </SpacedCol>
        </KitTips>
      )) }
    </KitFlexWrapRow>
  </Container>
)

export default FleetDisplayNumbers
