import {
  KitForm,
  KitSelect,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import styled from 'styled-components'

import {
  WizardContent,
  WizardHeading,
} from '@/components/AddFleetWizard/styled'
import {
  Description,
  FormItemWrapperMaxWidth,
  List,
  ListItemSmall,
} from '@/components/Styled'
import { type SelectChangeEvent } from '@/models/formModel'
import { type DynamicObject } from '@/types/index'

const ChargingInstructions = styled.div`
  margin-top: ${ThemeConstants.spacing.absolute.m}px;
`

const StyledList = styled(List)`
  list-style-type: disc;
  margin: ${ThemeConstants.spacing.absolute.l}px;
`

const InstructionLabel = styled(KitForm.Label)`
  font-weight: ${ThemeConstants.fontWeight.bold};
  color: ${ThemeColors.gray_90};
`

export interface GettingStartedInstructionsProps {
  instructionsType: string;
  setInstructionsType: (s: string) => void;
  t: TFunction;
}

const GettingStartedInstructions: FC<GettingStartedInstructionsProps> = ({
  instructionsType = 'on_premise',
  setInstructionsType,
  t,
}: GettingStartedInstructionsProps) => {
  const keyPrefix                                           = 'vehicles.add_vehicle.getting_started'
  const instructions: Record<string, DynamicObject<string>> = {
    on_premise: {
      heading : t(`${keyPrefix}.on_premise_instruction_heading`),
      body    : t(`${keyPrefix}.on_premise_instruction_body`),
    },
    on_premise_and_road: {
      heading : t(`${keyPrefix}.on_premise_and_road_instruction_heading`),
      body    : t(`${keyPrefix}.on_premise_and_road_instruction_body`),
    },
  }

  const instructionOptions = [
    {
      value : 'on_premise',
      label : t(`${keyPrefix}.on_premise_option`),
    },
    {
      value : 'on_premise_and_road',
      label : t(`${keyPrefix}.on_premise_and_road_option`),
    },
  ]

  return (
    <WizardContent>
      <FormItemWrapperMaxWidth>
        <KitForm.Group>
          <InstructionLabel
            htmlFor="instructions_type"
            text={t(`${keyPrefix}.charging_instruction_type_label`)}
          />
          <KitSelect
            name="instructions_type"
            defaultValue={{
              label : t(`${keyPrefix}.${instructionsType}_option`),
              value : instructionsType,
            }}
            options={instructionOptions}
            onChange={(val: unknown) => setInstructionsType((val as SelectChangeEvent).value as string)
            }
          />
        </KitForm.Group>
      </FormItemWrapperMaxWidth>

      <ChargingInstructions>
        <WizardHeading>{ t(`${keyPrefix}.info_heading`) }</WizardHeading>

        <StyledList>
          <ListItemSmall>{ t('fields.vehicle_name_label') }</ListItemSmall>

          <ListItemSmall>{ t(`${keyPrefix}.required_info`) }</ListItemSmall>
        </StyledList>

        <WizardHeading>{ instructions[instructionsType].heading }</WizardHeading>

        <Description>{ instructions[instructionsType].body }</Description>
      </ChargingInstructions>
    </WizardContent>
  )
}

export default GettingStartedInstructions
