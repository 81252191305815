import {
  KitLink,
  KitModal,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import styled from 'styled-components'
import fleetTheme from '@/common/fleet-theme'

import { ModalWrapper } from '@/components/Styled'

const { breakpoints, fontSize, spacing } = ThemeConstants

export const KitModalBody = styled(KitModal.Body)`
  padding: ${spacing.absolute.xxs}px;
  flex-grow: 1;
`

export const Column = styled.div`
  width: 350px;
  padding-top: ${spacing.absolute.l}px;
  padding-bottom: ${spacing.absolute.l}px;

  @media all and (max-width: ${breakpoints.sm}px) {
    width: auto;
    max-width: calc(100vw - ${spacing.absolute.xl}px);
    padding-bottom: 0;
  }
`

export const EnergyModalWrapper = styled(ModalWrapper)`
  justify-content: space-between;
  overflow-y: auto;
  .energy-modal-body {
    padding: 0;
  }

  .kit-form-group {
    margin-bottom: ${spacing.absolute.m}px;
    input.disabled {
      color: ${({ theme }) => theme.input.disabled.text};
      cursor: not-allowed;
    }
  }

  .field-group {
     position: relative;
     .ttip-trigger {
       position: absolute;
       top: 0:
       right: -24px;
     }
  }
`

export const Heading = styled.h1`
  font-size: ${fontSize.text_20}rem;
  margin-bottom: 0;
`

export const SubHeading = styled.div`
  font-size: ${fontSize.text_14}rem;
  color: ${ThemeColors.gray_50};
`

export const SharingAlgorithmLabel = styled.div`
  font-size: ${fontSize.text_14}rem;
  color: ${ThemeColors.gray_50};
`

export const DraggableItem = styled.div`
  border-inline-start: 4px solid ${ThemeColors.gray_30};
  background-color: ${ThemeColors.gray_05};
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  verical-align: center;
  padding: 0 ${spacing.absolute.xs}px;
  gap: ${spacing.absolute.xxs}px;
  transition: all 0.3s;
  align-items: center;
`

export const AllOthersItem = styled(DraggableItem)`
  border-inline-start: none;
  background: ${ThemeColors.white};
  margin-block-end: ${ThemeConstants.spacing.m}rem;
  padding-block-start: ${ThemeConstants.spacing.s}rem;
  padding-inline-start: ${ThemeConstants.spacing.s}rem;
  cursor: pointer;
`

export const DraggableItemName = styled.div`
  max-width: 120px;
  font-size: ${fontSize.text_14}rem;
  vertical-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0.8;
`

export const DraggableItemDesc = styled.div`
  font-size: ${fontSize.text_14}rem;
`

export const ItemWrapper = styled.div`
  padding: ${spacing.absolute.xxs}px 0;
`

export const DragDropContainer = styled.div`
  margin: ${spacing.absolute.m}px 0;
  position: relative;
`

export const LinkStyled = styled(KitLink)`
  padding-top: ${spacing.absolute.xs}px;
  font-size: ${ThemeConstants.fontSize.text_14}rem;
  text-decoration: none;
  color: ${ThemeColors.blue_50};
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`

export const PriorityLabels = styled.span`
  color: ${ThemeColors.gray_50};
  font-size: ${fontSize.text_12}rem;
`

export const PriorityLabelsWrapper = styled.div`
  margin-bottom: ${spacing.absolute.m}px;
`

export const DeleteButtonWrapper = styled.div`
  max-width: 24px;
  margin-top: 3px;

  svg {
    margin-top: 0;
  }
`

export const TTipContent = styled.div`
  padding: ${spacing.s}rem;
  .tariff-active-badge {
      border: 1px solid #008F6C;
      background: ${fleetTheme.colors.alert_success_border};
      margin-block-end: ${spacing.xs}rem;
  }
`

export const TextLabel = styled.span`
  color: ${ThemeColors.gray_50};
  min-width: 60px;
  text-align: right;
`

export const Value = styled.span`
  color: ${ThemeColors.gray_70};
  margin-inline-start: ${spacing.xs}rem;
  min-width: 60px;
  text-align: right;
`
