import { ThemeConstants } from '@chargepoint/cp-toolkit'
import { type FC, type MouseEvent, type ReactNode } from 'react'

import styled from 'styled-components'

import { CloseButton } from '@/components/Styled'

const { spacing } = ThemeConstants

const PaddedCloseButton = styled(CloseButton)`
  padding-top: ${spacing.absolute.s}px;
`

const Container = styled.div`
  justify-content: flex-end;
  padding: ${spacing.m}px;
  button {
    float: right;
    margin-top: -44px;
  }
`

export interface DeletableFieldProps {
  children: ReactNode;
  id: unknown;
  name: string;
  onDelete: (field: string, id: unknown) => void;
}

const DeletableField: FC<DeletableFieldProps> = ({
  children,
  id,
  name,
  onDelete,
}) => {
  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete(name, id)
  }

  return (
    <Container>
      { children }
      <PaddedCloseButton onClick={handleDelete} />
    </Container>
  )
}

export default DeletableField
