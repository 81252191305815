import { KitTextSpacing, ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

const ReadOnlyContainer = styled.div`
   > div:last-child {
    margin-top: ${ThemeConstants.spacing.absolute.xs}px;
   }
`

export interface ReadOnlyProps {
  enabled: boolean;
  strings: { on: string, off: string, label: string }
}

export const ReadOnly = ({ enabled, strings }: ReadOnlyProps) => {
  const value:string = enabled ? strings.on : strings.off
  return <ReadOnlyContainer>
    <KitTextSpacing.Group>
      <KitTextSpacing.PrimaryText>
        { strings.label }
      </KitTextSpacing.PrimaryText>
    </KitTextSpacing.Group>
    <KitTextSpacing.Group>
      <KitTextSpacing.SecondaryText>
        { value }
      </KitTextSpacing.SecondaryText>
    </KitTextSpacing.Group>
  </ReadOnlyContainer>
}
