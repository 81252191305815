import { injectStores } from '@mobx-devtools/tools'
import { makeAutoObservable } from 'mobx'

import { isDebug } from '@/common/utils'
import { type ServiceError } from '@/models/serviceModel'
import { type CobrandInfo, type UserProfile } from '@/models/userModel'

const parseErrors = (errors: ServiceError[]) => errors.map((err) => ({
  // err text might be unparsed json.
  // figure out if we care about this
  message : err.text,
  code    : err.code ?? err.status,
}))

// store for app level state
class AppStore {
  initialized = false
  user?: UserProfile
  cobrandInfo?: CobrandInfo
  errors: ServiceError[] = []

  constructor() {
    makeAutoObservable(this)
  }

  init() {
    this.initialized = true
  }

  setCobrandInfo(props: CobrandInfo) {
    this.cobrandInfo = props
  }

  setUser(user: UserProfile) {
    this.user = user
  }

  clearErrors() {
    this.errors = []
  }

  setErrors(errors: ServiceError | ServiceError[]) {
    let result: ServiceError[] = []
    if (Array.isArray(errors)) {
      result = errors
    } else {
      result.push(errors)
    }
    this.errors = parseErrors(result)
  }
}

const appStore = new AppStore()
export default appStore

if (isDebug()) {
  // inject store so mobx devtools can inspect it
  injectStores({ appStore })
}
