import { ThemeConstants } from '@chargepoint/cp-toolkit'
import styled from 'styled-components'

const { breakpoints, fontSize, fontWeight, spacing } = ThemeConstants

export const ErrorPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.absolute.xxl}px;

  li {
    text-align: center;
  }

  a {
    font-size: ${fontSize.h4}rem;
  }

  @media all and (max-width: ${breakpoints.sm}px) {
    padding: ${spacing.absolute.xxl}px ${spacing.absolute.l}px;
  }
`

export const StyledGraphic = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 50vh;
  max-height: 180px;
  width: 80%;
  margin: ${spacing.absolute.xl}px 0 ${spacing.absolute.xl}px;
  @media all and (max-width: ${breakpoints.sm}px) {
    margin: ${spacing.absolute.l}px 0 0;
  }
`
export const ErrorPageHeader = styled.h2`
  font-size: ${fontSize.text_20}rem;
  font-weight: ${fontWeight.bold};
  text-align: center;
`

export const ErrorPageSubHeader = styled.h3`
  font-size: ${fontSize.h4}rem;
  font-weight: ${fontWeight.normal};
  text-align: center;
`
