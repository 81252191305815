import {
  KitUtilData,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { Component, type ReactNode } from 'react'

import styled from 'styled-components'

import Notification, { NotificationLevel } from '@/components/Notification'

const { spacing } = ThemeConstants

const ErrorInfoWrapper = styled.pre`
  border: 1px solid ${ThemeColors.orange_30}px;
  padding: ${spacing.absolute.m}px;
  margin-top: ${spacing.absolute.m}px;
`

export interface ErrorBoundaryProps {
  children?: ReactNode;
  className?: string;
  customContent?: React.ReactNode;
  message?: string;
  showErrorInfo?: boolean;
}

export interface ErrorInfo {
  componentStack: {
    toString: () => string;
  };
}

export interface State {
  hasError: boolean;
  error: unknown;
  errorInfo?: ErrorInfo;
}

/**
 * Error Boundary component that captures errors in children and prevents errors from crashing entire application
 * See: https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends Component<
ErrorBoundaryProps,
State
> {
  static defaultProps = {
    message       : 'Sorry, something went wrong.',
    children      : null,
    showErrorInfo : false,
    customContent : null,
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError : false,
      error    : null,
    }
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    const debug = KitUtilData.toBoolean(localStorage.getItem('debug'))
    return { hasError: debug === false && error, error }
  }

  componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
    console.error('error caught in component', { error, errorInfo })
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      error,
      errorInfo,
    })
  }

  render() {
    const { error, errorInfo, hasError }                                 = this.state
    const { children, className, customContent, message, showErrorInfo } =      this.props
    const errorString                                                    = errorInfo && error ? errorInfo.componentStack?.toString() : null
    if (hasError) {
      return (() => {
        if (customContent) {
          return customContent
        }
        return (
          <Notification
            className={className}
            type={NotificationLevel.ERROR}
            message={message}
          >
            { showErrorInfo && errorString && (
              <ErrorInfoWrapper>{ errorString }</ErrorInfoWrapper>
            ) }
          </Notification>
        )
      })()
    }

    return children
  }
}
