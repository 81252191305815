import wretch from 'wretch'

import { getSSORedirectURL } from './utils'
import { deleteCurrentUser } from '@/common/utils/user'

export interface HTTPHeader {
  // null value will cause endpoint to throw CSRF error, but it is possible
  [headerName: string]: string;
}

export interface CSRFTokenHeader extends HTTPHeader {
  'X-CSRFToken': string;
}

export const getCSRFTokenHeader = (): CSRFTokenHeader => {
  const name      = 'csrftoken'
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return { 'X-CSRFToken': cookieValue as string }
}

// This is a resusable wrapper around the browser's built-in fetch API.
// It takes care of cookies as well as redirecting to SSO when the frontend receives a 401
// from the backend.
const apiFetch = wretch()
  .accept('application/json')
  .content('application/json')
  .options({
    credentials : 'include',
    mode        : 'cors',
  })
  // if there's a 401, clear local user in storage
  .catcher(401, (err) => {
    console.error('not authenticated or session timed out')
    deleteCurrentUser()
    window.location.href = getSSORedirectURL()

    // return error object so downstream components have access to error
    return { error: err }
  })

export default apiFetch
