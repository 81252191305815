import {
  type Duration,
  endOfDay,
  format,
  isValid,
  parseISO,
  startOfDay,
  subDays,
  subHours,
} from 'date-fns'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

import { omit } from './data'
import { getUserPreferences } from './user'
import { hasValue } from './validations'
import { padNumber } from '.'
import {
  ISO_DATE,
  ISO_DATE_NO_YEAR,
  ISO_DATE_TIME,
  ISO_DATE_TIME_WITH_SECONDS,
  ISO_TIME,
  Period,
} from '@/common/constants'
import { getBrowserLocale, getLocaleCode } from '@/common/lang'
import { type WeekDayFormat } from '@/types/datetime'
import { type DateRange, type TypedRange } from '@/types/index'

/**
 * Convert date to UTC date
 * @param dte
 * @param midnight -- set to true if you want the UTC date to set to the beginning of the day
 * @returns
 */
export function toUTC(dte: Date, midnight = false): Date | undefined {
  if (dte) {
    const year    = dte.getUTCFullYear()
    const month   = dte.getUTCMonth()
    const date    = dte.getUTCDate()
    const hours   = midnight ? 0 : dte.getUTCHours()
    const minutes = midnight ? 0 : dte.getUTCMinutes()
    const seconds = midnight ? 0 : dte.getUTCSeconds()
    const ms      = midnight ? 0 : dte.getUTCMilliseconds()

    const time = Date.UTC(year, month, date, hours, minutes, seconds, ms)
    return new Date(time)
  }
  return undefined
}

export const getWeekdayName = (
  weekDay: number,
  fmt: WeekDayFormat = 'long',
): string => {
  const locale               = getLocaleCode()
  const opts                 = { weekday: fmt }
  const { format: formatFn } = new Intl.DateTimeFormat(locale, opts)
  // This uses a clever little hack to extract weekday name from Intl.DateTimeFormat
  // We are choosing January 2022 as the 1st falls on Saturday, so when we pass a weekday of `0`, we will get Sunday
  return formatFn(new Date(Date.UTC(2022, 1, weekDay, 0, 0, 0, 0)))
}

export const getWeekDayNames = (fmt: WeekDayFormat = 'long'): string[] => [...Array(7).keys()].map((day) => getWeekdayName(day, fmt))

// returns date range as ISO timestring
export const serializeDateRange = (range: DateRange) => range.map((dte) => dte.toISOString().slice(0, 10))

export const toISODateRange = (
  range: TypedRange<Date | string | undefined>,
): (string | undefined)[] => range.map((dte) => {
  if (isValid(dte)) {
    return format(dte as Date, ISO_DATE)
  } if (typeof dte === 'string') {
    return dte
  }
})

export const getLocalTimeZone = (): string => {
  const dateFormat   = Intl.DateTimeFormat()
  const { timeZone } = dateFormat.resolvedOptions()
  return timeZone
}

export const toISO = <T = Date | string | number>(
  dte: T,
  includeTime?: boolean,
): string => {
  if (!dte) {
    return ''
  }

  let d: unknown = dte

  if (typeof dte === 'string') {
    // d = parseISO(dte as string);
    // if dte is already a string, assume is is already in ISO format
    return dte
  } if (typeof dte === 'number') {
    d = toDate(dte)
  }

  if (isValid(d)) {
    return includeTime
      ? (d as Date).toISOString().substring(0, 19)
      : (d as Date).toISOString().substring(0, 10)
  }

  return d as string
}

/**
 * TODO: This function is too generic. -- It should be removed and date coercion from strings or numbers
 * should be safely determined prior to trying to coerce a date from unknown input.
 *
 * Safely returns a date object.
 * Converts date string or milliseconds to Date object if needed
 * @param {*} dte
 * @returns
 */
export function toDate(dte: Date | string | number): Date;
export function toDate(dte: undefined): undefined;
export function toDate(dte: Date | string | number | undefined) {
  if (typeof dte === 'string') {
    // assuming an ISO-8601 date string
    return parseISO(dte)
  } if (typeof dte === 'number') {
    return new Date(dte)
  }
  return dte
}

export const getLocalizedFormat = (
  dte: number | string | Date,
): string | undefined => {
  let normalizedDate = dte
  if (!normalizedDate) {
    return
  }
  if (typeof dte === 'string') {
    normalizedDate = new Date(normalizedDate)
  }
  const intl                = getBrowserLocale()
  const localizedDateFormat = Intl.DateTimeFormat(intl)
  return localizedDateFormat.format(normalizedDate as Date)
}

/**
 * Formats 24hr timestring as 12hr timestring
 * TODO: move this and other date format utils to cp-toolkit and address any remaining localization issues
 * @param timeStr
 * @param locale
 * @returns
 */
export const formatTimeString = (
  timeStr: string,
  locale?: string | undefined,
  showSeconds = false,
): string => {
  if (!timeStr) {
    return ''
  }

  const normalizedTimeString = timeStr
    .split(':')
    .slice(0, showSeconds ? 3 : 2)
    .join(':')
  const parts                = normalizedTimeString.split(':')
  const hours                = parseInt(parts[0])
  const minutes              = parts[1]
  const seconds              = showSeconds ? `:${parts[2]}` : ''

  if ([undefined, 'en-US', 'en-CA', 'en-AU', 'en-NZ'].includes(locale)) {
    if (hours <= 12) {
      if (hours === 0) {
        return `12:${minutes}${seconds} AM`
      }
      if (hours === 12) {
        return `${normalizedTimeString}${seconds} PM`
      }
      return `${normalizedTimeString}${seconds} AM`
    }
    return `${hours % 12}:${minutes}${seconds} PM`
  }

  return normalizedTimeString
}

export const secondsToMinutes = (secs: string | number): number | null => {
  if (hasValue(secs)) {
    return Math.round(parseInt(secs as string) / 60)
  }

  return null
}

export const minutesToSeconds = (min: string | number): number | null => {
  if (hasValue(min)) {
    return parseInt(min as string) * 60
  }
  return null
}

/**
 * Returns date-fns friendly duration properties from a timestring
 *
 * const a = getDurationFromTimeString("17:35");
 * a === {
 *    hours: 17,
 *    minutes: 35
 * }
 * @param timeString
 * @returns
 */
export function getDurationFromTimeString(
  timeString: string,
): Partial<Duration> {
  if (!timeString) {
    return {}
  }
  const [hours, minutes, seconds] = timeString.split(':')
  return omit(
    {
      hours   : Number(hours),
      minutes : Number(minutes),
      seconds : Number(seconds),
    },
    (val) => Number.isNaN(val),
  )
}

export const formatDurationAsHours = (duration: Duration): string => {
  if (!duration) {
    return ''
  }

  const { days, hours, minutes, seconds } = duration
  let completeHours                       = hours as number
  if (days) {
    completeHours += days * 24
  }
  return `${completeHours}:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(
    -2,
  )}`
}

export const formatDate = (
  date: Date | string,
  isoFormat?: string,
  options: { timeZone?: string; isUTC?: boolean } = { isUTC: true },
): string => {
  let dte = typeof date === 'string' ? parseISO(date) : date
  if (options.timeZone) {
    dte = utcToZonedTime(dte, options.timeZone)
  }

  try {
    return format(dte, isoFormat ?? ISO_DATE_TIME)
  } catch (err) {
    console.warn('could not format invalid date', date)
  }

  return date as string
}
// TODO: Move this into the co-located DepotDetail utils as
// this is a very specific method only used by the depot details table
// and not generally useful anywhere else
export const formatTimeInZone = (milliseconds: number): string | null => {
  if (Number.isNaN(milliseconds)) {
    return null
  }
  const days    = Math.floor(milliseconds / (24 * 60 * 60 * 1000))
  const daysms  = milliseconds % (24 * 60 * 60 * 1000)
  const hours   = Math.floor(daysms / (60 * 60 * 1000))
  const hoursms = milliseconds % (60 * 60 * 1000)
  const minutes = Math.floor(hoursms / (60 * 1000))
  return `${padNumber(days)}:${padNumber(hours)}:${padNumber(minutes)}`
}

export const isDifferentDate = (date: Date): boolean => {
  const now = new Date()
  return (
    formatDate(new Date(date).toISOString(), ISO_DATE)
    !== formatDate(new Date(now).toISOString(), ISO_DATE)
  )
}

export interface DateFilterQueryProps {
  period: Period;
  start?: string;
  end?: string;
}

export interface DateRangeQuery {
  start_time: string;
  end_time: string;
}

// TODO: Move this to the filter helper functions file
export const filterDateQuery = ({
  end,
  period,
  start,
}: DateFilterQueryProps): DateRangeQuery | Record<string, string> => {
  const queries: { [key: string]: DateRangeQuery } = {
    [Period.CUSTOM]: {
      start_time : start ? parseISO(start).toISOString() : '',
      end_time   : end ? parseISO(end).toISOString() : '',
    },
    [Period.LAST_HOUR]: {
      start_time : subHours(new Date(), 1).toISOString(),
      end_time   : new Date().toISOString(),
    },
    [Period.LAST_12_HOURS]: {
      start_time : subHours(new Date(), 12).toISOString(),
      end_time   : new Date().toISOString(),
    },
    [Period.LAST_24_HOURS]: {
      start_time : subHours(new Date(), 24).toISOString(),
      end_time   : new Date().toISOString(),
    },
    [Period.TODAY]: {
      start_time : startOfDay(new Date()).toISOString(),
      end_time   : endOfDay(new Date()).toISOString(),
    },
    [Period.LAST_7_DAYS]: {
      start_time : subDays(new Date(), 6).toISOString(),
      end_time   : new Date().toISOString(),
    },
    [Period.LAST_30_DAYS]: {
      start_time : subDays(new Date(), 31).toISOString(),
      end_time   : new Date().toISOString(),
    },
  }

  if (queries[period]) {
    return {
      period,
      ...queries[period],
    }
  }

  return {}
}

// Get localized date format strings

const isISOFormat = (formatString: string) => {
  // check format string against ISO format (YYYY-MM-DDDD)
  if (formatString.toUpperCase().includes(ISO_DATE.toUpperCase())) {
    return true
  }

  return false
}

export const getDateFormats = () => {
  const userDateFormatPref = getUserPreferences().date_format ?? ISO_DATE_TIME
  // US, GB, etc format pref from NOS: YYYY-MM-DD hh:mm
  // Format as required by date-fns is yyyy-MM-dd HH:mm
  const isISO = isISOFormat(userDateFormatPref)

  /*
   For now, we will continue to format strings using the ISO-8601 format with a 24 hour time format:
   yyyy-MM-dd HH:mm

   We have not yet established a way to get formats for other locales that actually works!

   The format string currently returned by NOS is not directly usable as it contains
   different format tokens for the various date parts than date-fns requires.
   date-fns throws an exception when you pass an incorrect format string.

   It has been discussed that we might parse to format string from NOS to
   derive one the works for the client, but that seems fragile to me and would require a fallback anyway.
  */
  if (true || isISO) {
    return {
      time                : ISO_TIME,
      dateTime            : ISO_DATE_TIME,
      dateTimeWithSeconds : ISO_DATE_TIME_WITH_SECONDS,
      dateTimeNoYear      : ISO_DATE_NO_YEAR,
      use24Format         : true,
    }
  }
}

/**
 * @param dte: Date
 * @param timeZone timezone string
 * @returns a formatted timezone from a date
 * @example America/Los_Angeles (Pacific Daylight Time)
 */
export const getFormattedTimeZone = (
  dte: Date,
  timeZoneOptions: Intl.DateTimeFormatOptions,
) => {
  const opts: Partial<Intl.DateTimeFormatOptions> = {
    day          : '2-digit',
    timeZoneName : 'long',
    timeZone     : timeZoneOptions.timeZone,
    ...timeZoneOptions,
  }

  if (timeZoneOptions) {
    const friendlyZoneName = dte
      .toLocaleDateString(getLocaleCode(), { ...opts })
      .slice(4)
    return `${timeZoneOptions.timeZone} (${friendlyZoneName ?? ''})`
  }

  return null
}

export const utcStringToZoned = (dateString: string, timeZone: string) => {
  const parsed = parseISO(dateString)
  return utcToZonedTime(parsed, timeZone)
}
