import styled from 'styled-components'

import { STATIC_GOOGLE_MAPS_URL } from '@/common/constants'
import { paramsToQueryString } from '@/common/utils'
import { type Point } from '@/models/geolocationModel'

const MapImage = styled.img`
  width: -moz-available;
  width: -webkit-fill-available;
`

export interface StaticMapProps {
  altText: string;
  apiKey: string;
  markers?: Point[];
  size?: string;
  zoom?: number;
}

export interface StaticMapAPIParams {
  key: string;
  markers?: string;
  scale: 1 | 2;
  size: string;
  zoom: number;
}

function parseSize(size: string) {
  const [width, height] = size.split('x')
  return {
    height,
    width,
  }
}

const StaticMap = ({
  altText,
  apiKey,
  markers,
  size,
  zoom,
}: StaticMapProps) => {
  const defaultSize    = '165x120'
  const defaultZoom    = 14
  const containerStyle = parseSize(size ?? defaultSize)

  const params: StaticMapAPIParams = {
    key   : apiKey,
    scale : 2, // scale of 2 returns a higher resolution image,
    size  : size ?? defaultSize,
    zoom  : zoom ?? defaultZoom,
  }

  if (markers) {
    params.markers = markers.reduce((acc, marker: Point, idx: number) => {
      const sep = idx < markers.length ? '|' : ''
      return `${acc}${marker.lat},${marker.lng}${sep}`
    }, '')
  }

  return (
    <div style={containerStyle}>
      <MapImage
        alt={altText}
        src={`${STATIC_GOOGLE_MAPS_URL}${paramsToQueryString(
          params as unknown as Record<string, string>,
        )}`}
      />
    </div>
  )
}

export default StaticMap
