import { type TFunction } from 'i18next'
import { type FC } from 'react'

import {
  type AddVehicleFlowState,
  type WizardScreenProps,
  getSummaryContent,
  getSummaryLabel,
} from '../config'
import { WizardContent } from '@/components/AddFleetWizard/styled'
import {
  Section,
  SubHeading,
  SubHeadingBold,
  SummaryContainer,
  SummaryField,
} from '@/components/Styled'
import { type Field } from '@/types/index'

const renderSectionContent = (
  key: string,
  section: { fields: Field[] },
  state: AddVehicleFlowState,
  t: TFunction,
) => section.fields.map((field) => (
  <SummaryField
    key={key}
    value={field.value as string | number}
    label={getSummaryLabel(field.label as string, section, t) as string}
  />
))

const MergeVehicleConfirm: FC<WizardScreenProps> = ({ state, t }) => {
  const summary = getSummaryContent(state, true, t)
  return (
    <WizardContent>
      <SubHeading>
        { t('vehicles.add_vehicle.confirm_merge_vehicle_caption') }
      </SubHeading>
      <br />
      <SummaryContainer>
        { summary?.map((section) => (
          <Section key={section.title}>
            <SubHeadingBold>{ section.title }</SubHeadingBold>
            { renderSectionContent(section.key, section, state, t) }
          </Section>
        )) }
      </SummaryContainer>
    </WizardContent>
  )
}

export default MergeVehicleConfirm
