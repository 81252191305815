import Rox from 'rox-browser'

import * as Permissions from '@/common/permissions'
import { getConstant, getFeatureContext } from '@/common/utils'

const FEATURE_FLAGS_API_KEY = 'CLOUDBEES_FEATURE_FLAGS_API_KEY'

export interface FeatureFlagContext {
  orgID: string;
  userID: string;
  email: string;
  instanceName: string;
  host: string;
}

export interface FlagOpts {
  [key: string]: unknown;
  requiredPermissions: string | string[];
  context?: FeatureFlagContext;
}

export interface FlagMap {
  [key: string]: Rox.Flag;
}

const flags: FlagMap = {
  tripHistoryTabEnabled         : new Rox.Flag(),
  csvDownloadButtonEnabled      : new Rox.Flag(),
  vehiclePreconditioningEnabled : new Rox.Flag(),
  vctHelpEnabled                : new Rox.Flag(),
  devUIFeaturesEnabled          : new Rox.Flag(),
}

export enum Features {
  devUIFeaturesEnabled = 'devUIFeaturesEnabled',
  tripHistoryTabEnabled = 'tripHistoryTabEnabled',
  csvDownloadButtonEnabled = 'csvDownloadButtonEnabled',
  vehiclePreconditioningEnabled = 'vehiclePreconditioningEnabled',
  vctHelpEnabled = 'vctHelpEnabled',
}

export default class FeatureService {
  static enabledFeatures: Map<Features, boolean> = new Map()

  static initialized: unknown

  static globalContext: FeatureFlagContext

  static async initialize() {
    const apiKey       = getConstant(FEATURE_FLAGS_API_KEY) as string
    this.globalContext = getFeatureContext() as unknown as FeatureFlagContext
    if (apiKey) {
      // Register the flags
      const options = {}
      Rox.register('', flags)
      Rox.setContext(this.globalContext)

      await Rox.setup(apiKey, options)

      Object.entries(flags).forEach(([key, flag]) => {
        FeatureService.enabledFeatures.set(
          key as unknown as Features,
          flag.isEnabled(),
        )
      })
      FeatureService.initialized = true
    } else {
      console.warn('FeatureService not initialized')
    }

    return FeatureService.initialized
  }

  static isEnabled(
    featureName: Features | Features[],
    opts?: FlagOpts,
  ): boolean {
    if (opts) {
      if (typeof opts.context === 'object') {
        const mergedContext = {
          ...this.globalContext,
          ...(opts.context as FeatureFlagContext),
        }
        if (flags[featureName as Features]) {
          // if call to isEnabled passes custom context, then we can call the original flags instead of the cached ones
          return flags[featureName as Features].isEnabled(mergedContext)
        }
      } else if (opts?.requiredPermissions) {
        return Array.isArray(featureName)
          ? featureName.every((feature) => FeatureService.enabledFeatures.get(feature))
          : !!FeatureService.enabledFeatures.get(featureName)
              && Permissions.hasPermission(opts?.requiredPermissions)
      }
    }

    return Array.isArray(featureName)
      ? featureName.every((feature) => FeatureService.enabledFeatures.get(feature))
      : (FeatureService.enabledFeatures.get(featureName) as boolean)
  }
}
