import { ThemeColors } from '@chargepoint/cp-toolkit'
import { type FC, type ReactNode } from 'react'

import styled from 'styled-components'

import { TableCell } from '@/components/Styled'
import { type BlockAssignment } from '@/models/dispatchModel'
import { type ColumnDef } from '@/types/index'

const TableRow = styled.tr`
  background: ${ThemeColors.white};
  td {
    span {
      color: ${ThemeColors.black};
    }
  }

  &:hover {
    td {
      background-color: ${ThemeColors.gray_10};
    }
  }
`

export interface UpcomingVehicleTableRowProps {
  columns: ColumnDef[];
  row: BlockAssignment;
}

const UpcomingVehicleTableRow: FC<UpcomingVehicleTableRowProps> = ({
  columns,
  row,
}) => (
  <TableRow>
    { columns.map((col) => (
      <TableCell key={col.field}>
        <span>{ row[col.field as keyof BlockAssignment] as ReactNode }</span>
      </TableCell>
    )) }
  </TableRow>
)

export default UpcomingVehicleTableRow
