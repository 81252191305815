/* eslint-disable @typescript-eslint/naming-convention */
import { KitUtilData } from '@chargepoint/cp-toolkit'
import { minutesToSeconds } from 'date-fns'
import type { TFunction } from 'i18next'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import i18n from '../i18n'
import { getError } from '@/common/utils'
import { has, isBetween } from '@/common/utils/data'
import { getPayload } from '@/common/utils/scheduling'
import { hasValue } from '@/common/utils/validations'
import { ScheduleContext, ScheduleType } from '@/models/scheduleModel'
import type { FleetScheduleResponse, Schedule, ScheduleResponse, VehicleScheduleResponse } from '@/models/scheduleModel'
import type { BaseServiceResponse, ServicePayload } from '@/models/serviceModel'
import FleetService from '@/services/FleetService'
import VehicleService from '@/services/VehicleService'

// Schedule Store used in Fleet and Vehicle Settings Scheduling Panels

const defaultSchedule: Schedule = {
  arrival_time                 : undefined,
  arrival_time_downtime_secs   : 0,
  departure_time               : undefined,
  departure_time_downtime_secs : 0,
  required_soc                 : 50,
}

class FleetVehicleScheduleStore {
  vehicleID?: string
  fleetID?: string
  fieldErrors?: Record<string, string>
  required_soc?: number
  schedule: Schedule = defaultSchedule
  scheduleType: ScheduleType = ScheduleType.ASAP
  scheduleContext: ScheduleContext = ScheduleContext.FLEET
  inheritFromFleet?: boolean = false
  serviceError?: string
  isLoading: boolean = false

  // used by Vehicle Settings Schedule when schedule value is 'inherit_from_fleet'
  fleetScheduleResponse?: ScheduleResponse

  initialScheduleResponse?: ScheduleResponse

  constructor() {
    makeObservable(this, {
      fleetScheduleResponse   : observable,
      fieldErrors             : observable,
      initialScheduleResponse : observable,
      schedule                : observable,
      serviceError            : observable,
      scheduleType            : observable,
      scheduleContext         : observable,
      inheritFromFleet        : observable,
      scheduleAnalyticsProps  : computed,
      setScheduleContext      : action.bound,
      setInheritFromFleet     : action.bound,
      setScheduleType         : action.bound,
      setScheduleField        : action.bound,
      setScheduleResponse     : action.bound,
      resetSchedule           : action.bound,
      clearSchedule           : action.bound,
    })
  }

  setFleetID(id: string) {
    this.fleetID = id
  }

  setVehicleID(id: string) {
    this.vehicleID = id
  }

  async fetchAdditional() {
    // when we are displaying a vehicle schedule that is inherited from Fleet, we have to
    // go fetch the schedule for that fleet.
    if (this.scheduleContext === ScheduleContext.FLEET && this.fleetID) {
      const fleetScheduleResponse = await FleetService.getFleetSchedule(this.fleetID)
      this.schedule               = fleetScheduleResponse.content
      this.scheduleType           = fleetScheduleResponse.schedule_type
      this.fleetScheduleResponse  = fleetScheduleResponse
    }
  }

  // maps api response to model properties
  setScheduleResponse(res: ScheduleResponse | FleetScheduleResponse | VehicleScheduleResponse) {
    this.initialScheduleResponse =  res
    this.schedule                = res.content
    this.scheduleType            = res.schedule_type

    if (has(res, 'inherit_from_fleet')) {
      this.vehicleID        = (res as VehicleScheduleResponse).vehicle_external_id
      this.scheduleContext  = res.inherit_from_fleet ? ScheduleContext.FLEET : ScheduleContext.VEHICLE
      this.inheritFromFleet = hasValue(this.vehicleID) && Boolean(res.inherit_from_fleet)
    } else {
      this.fleetID         = (res as FleetScheduleResponse).fleet_external_id
      this.vehicleID       = undefined
      this.scheduleContext = ScheduleContext.FLEET
    }
  }

  setScheduleContext(ctx: ScheduleContext) {
    this.scheduleContext  = ctx
    this.inheritFromFleet = ctx === ScheduleContext.FLEET && hasValue(this.vehicleID)
    this.fetchAdditional()
  }

  setInheritFromFleet(val: boolean) {
    this.inheritFromFleet = val
  }

  setScheduleType(type: ScheduleType) {
    this.scheduleType = type
    if (type === ScheduleType.BY_TIME) {
      const arrival_time_downtime_secs   = this.schedule.arrival_time_downtime_secs ?? 0
      const departure_time_downtime_secs = this.schedule.departure_time_downtime_secs ?? 0
      this.schedule                      = { ...this.schedule, arrival_time_downtime_secs, departure_time_downtime_secs }
    }
  }

  resetSchedule() {
    if (this.initialScheduleResponse) {
      this.setScheduleResponse(this.initialScheduleResponse)
      this.fieldErrors = {}
    }
  }

  validate(t: TFunction) {
    this.fieldErrors                     = {}
    const requiredScheduleFields         = ['required_soc'].concat(this.scheduleType === ScheduleType.BY_TIME
      ? ['arrival_time', 'departure_time', 'arrival_time_downtime_secs', 'departure_time_downtime_secs']
      : []) as (keyof Schedule)[]
    const errors: Record<string, string> = {}

    requiredScheduleFields.forEach((key) => {
      if (!hasValue(this.schedule[key])) {
        errors[key] =  t('required')
      } else if (key === 'required_soc' && !isBetween(Number(this.schedule[key]), 1, 100)) {
        errors[key] = t('errors.max')
      }
    })

    this.fieldErrors = errors
    return KitUtilData.isEmpty(errors)
  }

  clearSchedule() {
    this.schedule = {
      arrival_time                 : undefined,
      arrival_time_downtime_secs   : undefined,
      departure_time               : undefined,
      departure_time_downtime_secs : undefined,
    }
  }

  setScheduleField(key: string, val: string | number | unknown) {
    const normalizedValue = [
      'arrival_time_downtime_secs',
      'departure_time_downtime_secs',
    ].includes(key) ? minutesToSeconds(Number(val)) : val
    const updates         = { ...this.schedule, [key]: normalizedValue } as Schedule
    this.schedule         = updates
  }

  setSOC(soc?: number) {
    this.required_soc = soc
  }

  setServiceError(errString: string) {
    this.serviceError = errString
  }

  saveSchedule() {
    this.isLoading = true
    const payload  = toJS(getPayload(this.schedule, this.scheduleType, this.scheduleContext, this.inheritFromFleet) as ServicePayload)
    if (this.vehicleID) {
      this.updateVehicleSchedule(payload)
    } else {
      this.updateFleetSchedule(payload)
    }
  }

  // returns schedule props used in mixpanel
  get scheduleAnalyticsProps() {
    if (!this.initialScheduleResponse) {
      return {}
    }

    const { inherit_from_fleet, schedule_type }                          = this.initialScheduleResponse
    const props: { action: string, [key: string]: (string | number)[], } = { ...this.schedule, schedule_type }
    const prevScheduleContext                                            = inherit_from_fleet ? 'inherit' : 'custom'
    const changedScheduleContext                                         = this.inheritFromFleet ? 'inherit' : 'custom'

    if (prevScheduleContext !== changedScheduleContext || schedule_type !== this.scheduleType) {
      props.action = 'changed schedule'
      props.from   = [prevScheduleContext, schedule_type]
      props.to     = [changedScheduleContext, this.scheduleType]
    }

    return props
  }

  async updateFleetSchedule(payload: ServicePayload) {
    const response = await FleetService.updateFleetSettings('schedule', this.fleetID, payload)
    if (response.error) {
      this.serviceError = getError(response.error) ?? i18n.t('fleets.settings.scheduling.update_error')
    } else {
      this.setScheduleResponse(response)
    }
    this.isLoading = false
  }

  async updateVehicleSchedule(payload: ServicePayload) {
    if (this.vehicleID) {
      const response = await VehicleService.updateVehicleSettings(this.vehicleID, 'schedule', payload) as ScheduleResponse & BaseServiceResponse
      if (response.error) {
        this.serviceError = getError(response.error) ?? i18n.t('fleets.settings.scheduling.update_error')
      } else {
        this.setScheduleResponse(response)
      }
      this.isLoading = false
    }
  }
}

const scheduleStore = new FleetVehicleScheduleStore()
export default scheduleStore
