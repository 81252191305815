import { type FC } from 'react'

import { type ErrorPageProps } from './helpers'
import { ErrorPageHeader, ErrorPageSubHeader } from './styles'

const Http401: FC<ErrorPageProps> = ({ children, t }) => (
  <>
    <ErrorPageHeader>{ t('errors.401.title') }</ErrorPageHeader>
    <ErrorPageSubHeader>{ t('errors.401.subtitle') }</ErrorPageSubHeader>
    { children }
  </>
)

export default Http401
