import { KitUtilData } from '@chargepoint/cp-toolkit'
import { type ComponentType, type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncError, useRouteError } from 'react-router-dom'

import cpErrorPageGraphic from '../images/cp_errorpage_graphic.svg'
import AlternativePageHeader from './AlternativePageHeader'
import { type ErrorPageProps } from './errors/helpers'
import Http401 from './errors/Http401'
import Http403 from './errors/Http403'
import Http404 from './errors/Http404'
import Http500 from './errors/Http500'
import { ErrorPageContent, StyledGraphic } from './errors/styles'
import NoResults from './NoResults'
import { ErrorCodes } from '@/common/constants'
import type RouteError from '@/common/errors/RouteError'
import { getLogoutUrl } from '@/common/utils'
import { getCurrentUser } from '@/common/utils/user'
import { hasValue } from '@/common/utils/validations'
import { ErrorDisplayComponent, type ServiceError } from '@/models/serviceModel'

const errorComponentMap = {
  401 : Http401,
  403 : Http403,
  404 : Http404,
  500 : Http500,
}

const getDerivedErrorCode = (code: number) => {
  if (code === ErrorCodes.NotAuthorized) {
    return 403
  }

  return code
}

const getError = (errors?: ServiceError[]) => {
  if (errors?.length) {
    const errorCode = getDerivedErrorCode(errors[0].code as number)
    type componentKeyType = keyof typeof errorComponentMap;
    if (errorComponentMap[errorCode as componentKeyType]) {
      return errorComponentMap[errorCode as componentKeyType]
    }
  }

  return Http500
}

export interface ErrorHandlerProps extends PropsWithChildren {
  errors?: ServiceError[];
  showAlternativeHeader?: boolean;
}

const ErrorHandler: FC<ErrorHandlerProps> = ({
  children,
  errors,
  showAlternativeHeader,
}) => {
  const { t }       = useTranslation()
  const usr         = getCurrentUser()
  const isValidUser = !KitUtilData.isEmpty(usr) && hasValue(usr.id)
  const routeError  = (useRouteError() ?? useAsyncError()) as RouteError

  const links = isValidUser
    ? [{ url: '/', label: t('fleet_home') }, { url: getLogoutUrl(), label: t('logout') }]
    : [
      { url: getLogoutUrl(), label: t('logout') },
      { url: 'https://chargepoint.com/', label: t('chargepoint_home') },
    ]

  const message             = routeError ? routeError.message : null
  const normalizedErrors    = errors?.length ? errors : [routeError]
  const ErrorComponent      = getError(
    normalizedErrors,
  ) as unknown as ComponentType<ErrorPageProps>
  const errorComponentProps = {
    message,
    links,
  }

  const graphic =    routeError?.displayComponent === ErrorDisplayComponent.SadCar ? (
    <NoResults message={message} />
  ) : (
    <StyledGraphic
      style={{ backgroundImage: `url(${cpErrorPageGraphic})` }}
    />
  )

  return (
    <div>
      { showAlternativeHeader && <AlternativePageHeader t={t} /> }
      <ErrorPageContent aria-live="polite">
        { errors && <ErrorComponent {...errorComponentProps} t={t} /> }
        { graphic }
      </ErrorPageContent>
      { children }
    </div>
  )
}

export default ErrorHandler
