import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import { type BaseServiceResponse } from '@/models/serviceModel'
import { type FleetNotificationPayload, type FleetNotificationSettingsResponse } from '@/models/settingsModel'

const createService = (api: ServiceInterface) => {
  const baseURL = '/api'
  return {
    getNotificationSettings(): Promise<
    BaseServiceResponse & FleetNotificationSettingsResponse
    > {
      return wrapRequest(api.get(`${baseURL}/notifications/`)) as Promise<
      BaseServiceResponse & FleetNotificationSettingsResponse
      >
    },
    updateNotificationSettings(
      payload: FleetNotificationPayload,
    ): Promise<BaseServiceResponse> {
      return wrapRequest<BaseServiceResponse>(
        api.post(
          `${baseURL}/notifications/`,
          payload as unknown as Record<string, unknown>,
        ),
      )
    },
  }
}

export default createService(AbstractService())
