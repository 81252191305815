import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import { type TFunction } from 'i18next'
import { type FC, type ReactNode, useCallback, useEffect, useState } from 'react'

import styled from 'styled-components'

import FleetIcon from '@/components/FleetIcon'

const { spacing } = ThemeConstants

const VehicleImageContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '170px'};
  height: inherit;
  display: flex;
  align-items: center;
  margin-right: ${spacing.absolute.s}px;
`

const UnavailableMessage = styled.div`
  color: ${ThemeColors.gray_70};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.absolute.s}px;
  height: 90px;
  border: 1px solid ${ThemeColors.gray_10};
  text-align: center;
`

const ImageContainer = styled.div`
  img {
    height: inherit;
    width: auto;
  }
`

export interface VehicleImageProps {
  altText: string;
  children?: ReactNode;
  style?: Record<string, string>;
  url?: string;
  vehicleType?: string;
  t: TFunction;
  height?: string;
  width?: string;
}

const VehicleImage: FC<VehicleImageProps> = ({
  altText,
  children,
  height,
  style,
  t,
  url,
  vehicleType,
  width,
}) => {
  const defaultHeight                       = '90px'
  const defaultWidth                        = 'auto'
  const imgWidth                            = width ?? defaultWidth
  const imgHeight                           = height ?? defaultHeight
  const imgStyle                            = style ?? { width: imgWidth, height: imgHeight }
  const [imageLoadError, setImageLoadError] = useState(false)
  const imageUnavailableMessage             = t ? t('errors.image_unavailable') : ''

  const handleImageLoadError = useCallback(() => {
    setImageLoadError(true)
  }, [url])

  const handleImageLoadSuccess = useCallback(() => {
    setImageLoadError(false)
  }, [url])

  const getImage = (): ReactNode => {
    if (url) {
      return (
        <ImageContainer style={imgStyle}>
          { !imageLoadError && (
            <img
              src={url}
              alt={`${altText}`}
              onError={handleImageLoadError}
              onLoad={handleImageLoadSuccess}
            />
          ) }

          { imageLoadError && (
            <UnavailableMessage>{ imageUnavailableMessage }</UnavailableMessage>
          ) }
        </ImageContainer>
      )
    }

    return (
      <FleetIcon
        altText={altText}
        useBg={false}
        style={{
          height             : `${imgHeight}px`,
          marginTop          : `${spacing.absolute.s}px`,
          backgroundPosition : 'center',
        }}
        iconType={vehicleType ?? 'deliveryTruck'}
      />
    )
  }

  useEffect(() => {
    setImageLoadError(false)
  }, [url])

  return (
    <VehicleImageContainer width={width}>
      { getImage() }
      { children }
    </VehicleImageContainer>
  )
}

export default VehicleImage
