import {
  KitInput,
  type KitInputProps,
  KitSearchIcon,
  KitUtilUI,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type ForwardedRef, type Ref, forwardRef } from 'react'

import styled from 'styled-components'

const deviceInfo   = KitUtilUI.getDeviceInfo()
const { fontSize } = ThemeConstants

const Container = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
`

const IconWrapper = styled.span`
  position: absolute;
  right: ${ThemeConstants.spacing.absolute.m}px;
  top: ${ThemeConstants.spacing.absolute.s}px;
`

const StyledInput = styled(KitInput)<{ ref?: Ref<HTMLInputElement> }>`
  && {
    font-size: ${deviceInfo.isMobile
    ? fontSize.base
    : fontSize.text_14}rem !important;
    margin-bottom: 0;
    padding: ${ThemeConstants.spacing.s}rem;
    padding-bottom: 0;
  }
  border-bottom: 2px solid ${ThemeColors.gray_20};
`

const SearchBox = forwardRef(
  (inputProps: KitInputProps = {}, ref: ForwardedRef<HTMLInputElement>) => (
    <Container>
      <StyledInput ref={ref} {...inputProps} />
      <IconWrapper>
        <KitSearchIcon />
      </IconWrapper>
    </Container>
  ),
)

SearchBox.displayName = 'SearchBox'

export default SearchBox
