import AbstractService, { type ServiceInterface, wrapRequest } from './index'
import { type ServiceResponse } from '@/models/serviceModel'
import { type UserProfileResponse } from '@/models/userModel'

const createService = (api: ServiceInterface) => ({
  logOut() {
    return wrapRequest(api.get('/api/logout/'))
  },
  getProfile(): Promise<ServiceResponse<UserProfileResponse>> {
    return wrapRequest(api.get('/api/me/')) as Promise<
    ServiceResponse<UserProfileResponse>
    >
  },
})

export default createService(AbstractService())
