class Log {
  static write(console_func_name: keyof Console, ...args: any) {
    // eslint-disable-next-line no-console
    return (console[console_func_name] as (args: unknown[]) => void).apply(console, args)
  }

  static info(...args: unknown[]) {
    return Log.write('log', ...args)
  }

  static warn(...args: unknown[]) {
    return Log.write('warn', ...args)
  }

  static error(...args: unknown[]) {
    return Log.write('error', ...args)
  }

  static trace(...args: unknown[]) {
    return Log.write('trace', ...args)
  }
}

export default Log
