import { type TFunction } from 'i18next'

import {
  type FleetNotification,
  type FleetNotificationSettingsResponse,
  type NotifyPreferenceValue,
  type NotifyPreferences,
} from '@/models/settingsModel'

export const mapNotifications = (
  response: FleetNotificationSettingsResponse,
  t: TFunction,
): FleetNotification[] => {
  const mapNotification = ([key, value]: [
    string,
    NotifyPreferenceValue,
  ]): FleetNotification => ({
    key,
    name        : t(`fleet_settings.notifications.topics.${key}_name`),
    description : t(
      `fleet_settings.notifications.topics.${key}_description`,
    ),
    ...value,
  } as FleetNotification)

  return Object.entries(response.notifications).map(mapNotification)
}

export const createNotificationsPayload = (
  changes: NotifyPreferences,
  notifications: FleetNotification[],
) => {
  const payload = {
    notifications: notifications.reduce((acc, { email, key, sms }) => {
      if (changes[key]) {
        // overwrite existing with changes
        const existing = { sms, email }
        acc[key]       = { ...existing, ...changes[key] }
      } else {
        acc[key] = { sms, email }
      }
      return acc
    }, {} as Partial<NotifyPreferences>),
  }

  return payload
}
