import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit'
import styled, { css } from 'styled-components'

const { spacing } = ThemeConstants

const zoomButtonStyles = css`
  border-radius: 50%;
  position: absolute;
  margin-right: 0 !important;
  right: 120px;
  top: ${-spacing.absolute.sm}px;
  padding: ${spacing.absolute.xs}px;
  line-height: ${spacing.absolute.xl}px;
  height: ${spacing.absolute.xl}px;
  min-width: ${spacing.absolute.xl}px;
  z-index: 10;
  transition: all 0.1s linear;
  svg path {
    transition: all 0.3s;
    fill: ${ThemeColors.gray_70};
  }

  &:hover {
    background: ${ThemeColors.gray_10};
    transform: scale(1.2);
    svg path {
      fill: ${ThemeColors.gray_90};
    }
  }
`

export const ChartWrapper = styled.div`
  min-height: 200px;
  min-width: 300px;
  width: 100%;
  height: 360px;
  user-select: none;
  position: relative;
  user-select: none;
  width: 100%;

  .recharts-legend-item {
    cursor: pointer;
  }

  .zoom-button {
    ${zoomButtonStyles};
  }

  div.zoom-button {
    margin-top: ${-spacing.absolute.xs}px;
    &:hover {
      background: transparent;
      transform: none;
    }
  }
`
