import {
  KitConstants,
  KitSpinner,
  KitUtilCommon,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit'
import { type FC, type RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import VehicleCard from '../VehicleCard'
import SearchBox from '@/components/SearchBox'
import { CardLayout } from '@/components/Styled'
import { type PagedServiceResponse } from '@/models/serviceModel'
import { type Vehicle } from '@/models/vehicleModel'
import FleetService from '@/services/FleetService'

export interface ExistingVehiclesList {
  onSelected: (vehicle: Vehicle) => void;
  vehicleIdentifier?: string;
  selectedVehicleId?: number;
}

const Container = styled.div``

const SearchBoxWrapper = styled.div`
  margin-bottom: ${ThemeConstants.spacing.absolute.s}px;
  margin-top: ${ThemeConstants.spacing.absolute.s}px;
  padding-right: ${ThemeConstants.spacing.absolute.m}px;
  width: ${({ theme }) => theme.search.width};
  position: relative;
`

const ScrollableContainer = styled.div`
  overflow-y: auto;
  position: relative;
  padding: ${ThemeConstants.spacing.absolute.xs}px;
  &::before {
    content: '';
    display: block;
    height: 5px;
    position: sticky;
    z-index: 1;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    clip-path: polygon(0% 0%, 100% 0%, 100% 200%, 0% 200%);
    transform: translate(0, -10px);
    top: 0;
    margin-bottom: 5px;
  }
  &::after {
    content: '';
    display: block;
    height: 12px;
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0;
    background: ${ThemeColors.white};
  }
`

const FooterShadow = styled.div`
  bottom: 0;
  content: '';
  display: block;
  height: 5px;
  position: sticky;
  z-index: 1;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  clip-path: polygon(0% -200%, 100% -200%, 100% 100%, 0% 100%);
  transform: translate(0, 10px);
`

const FooterForeground = styled.div`
  position: relative;
  bottom: -10px;
  display: block;
  height: 12px;
  z-index: 2;
  width: 100%;
  background: ${ThemeColors.white};
`

const ExistingVehiclesList: FC<ExistingVehiclesList> = ({
  onSelected,
  selectedVehicleId,
}) => {
  const [vehicleList, setVehicleList]                     = useState<Vehicle[] | null>(null)
  const [filteredVehicleList, setFilteredVehicleList]     = useState<
  Vehicle[] | null
  >(null)
  const [showSpinner, setShowSpinner]                     = useState(false)
  const [scrollContainerHeight, setScrollContainerHeight] = useState('400px')
  const { t }                                             = useTranslation()
  const containerRef                                      = useRef<HTMLDivElement>()

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { currentTarget } = e
    if (
      KitUtilCommon.isKey(
        [KitConstants.KeyConstants.ENTER, KitConstants.KeyConstants.SPACE],
        e as unknown as KeyboardEvent,
      )
    ) {
      const vehicleId = parseInt(
        currentTarget.getAttribute('data-id') as string,
        10,
      )
      const vehicle   = vehicleList?.find((v) => v.id === vehicleId) as Vehicle
      onSelected(vehicle)
    }
  }

  const onSelect = (vehicleItem: Vehicle) => {
    if (onSelected) {
      onSelected(vehicleItem)
    }
  }

  const onSearch = (value: string) => {
    const filtered =      vehicleList?.filter(
      (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1,
    ) ?? []
    setFilteredVehicleList(filtered)
  }

  const updateLayout = () => {
    const containerHeight = window.innerHeight - 270
    setScrollContainerHeight(`${containerHeight}px`)
  }

  useEffect(() => {
    updateLayout()

    window.addEventListener('resize', updateLayout)
    return () => window.removeEventListener('resize', updateLayout)
  }, [vehicleList?.length])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowSpinner(true)
        const response: PagedServiceResponse<Vehicle> =          await FleetService.getVehicles(undefined, { cache: 'default' })
        if (!response.error) {
          setVehicleList(response.results)
          setFilteredVehicleList(response.results)
        }
      } finally {
        setShowSpinner(false)
      }
    }

    if (!vehicleList) fetchData()
  }, [])

  return (
    <Container ref={containerRef as RefObject<HTMLDivElement>}>
      <SearchBoxWrapper>
        <SearchBox
          onChange={({ target }) => onSearch(target.value)}
          placeholder={t('vehicle_search_placeholder_text')}
        />
      </SearchBoxWrapper>

      <ScrollableContainer style={{ height: scrollContainerHeight }}>
        { showSpinner && <KitSpinner align="middle" size={'s'} /> }
        <CardLayout>
          { filteredVehicleList?.map((vehicle) => (
            <VehicleCard
              selected={selectedVehicleId === vehicle.id}
              key={vehicle.id}
              {...vehicle}
              t={t}
              onKeyPress={handleKeyPress}
              onClick={() => onSelect(vehicle)}
            />
          )) }

          { filteredVehicleList?.length === 0 && <p>{ t('no_results') }</p> }
        </CardLayout>

        <FooterForeground />

        <FooterShadow />
      </ScrollableContainer>
    </Container>
  )
}

export default ExistingVehiclesList
